import axios from "axios";
import React, { FormEvent } from "react";
import logger from "../../utility/logger/logger";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import { CurrentPageAction, LoadingAction, ModalAction } from "../../redux/enums";
import { DataCyStrings } from "../../types/DataCyStrings";
import { $IconWrapper, $RequestSupportButton } from "./styles";
import ga from "../../utility/GAEmitter";
import { RequestSupportActions } from "../../types/ReturnistaGA";
import ReturnistaAnalytics from "../../utility/ReturnistaAnalytics";


const outOfBagsRequestError = (dispatch, returnistaLocationID, returnistaLocationOwnerName, currentPage) => {
  dispatch({
    type: ModalAction.Set,
    modalProps: {
      primaryMessage: "Something went wrong",
      subMessages: [
        "The page didn’t load correctly, please try again. If the issue persists, contact us at (877) 750-4888.",
      ],
      onRequestClose: () => dispatch({ type: ModalAction.Unset }),
      iconElement: (
        <$IconWrapper data-cy="request-out-of-bags-error-icon-modal">
          <ReturnistaFeedbackIcon status="error" />
        </$IconWrapper>
      ),
      button: (
        <$RequestSupportButton>
          <ReturnistaPrimaryButton className="returnistaTryAgainButton" onClick={confirmOutOfBags(dispatch, returnistaLocationID, returnistaLocationOwnerName, currentPage)} dataCyString={DataCyStrings.tryAgainOutOfBagsButton}>
            Try again
          </ReturnistaPrimaryButton>
        </$RequestSupportButton>
      ),
    },
  });
};

const confirmOutOfBags = (dispatch, returnistaLocationID, returnistaLocationOwnerName, currentPage) => () => {
  ga.pageEvent({ category: RequestSupportActions.ModalName, action: RequestSupportActions.RequestSupportClicked });

    const onRequestClose = () => {
      ga.pageEvent({ category: RequestSupportActions.ModalName, action: RequestSupportActions.ModalClosed });
      dispatch({ type: ModalAction.Unset });
    };

    ReturnistaAnalytics.outOfBagsButtonClickedAction(returnistaLocationID, returnistaLocationOwnerName, currentPage)

    const handleCancelOutOfBags = () => {
      dispatch({ type: ModalAction.Unset });
    };

    const handleConfirmOufOfBags = async (e: FormEvent) => {
      e.preventDefault();
      ReturnistaAnalytics.outOfBagsConfirmedAction(returnistaLocationID, returnistaLocationOwnerName, currentPage)
      dispatch({ type: LoadingAction.Set, message: "Submitting" });
      dispatch({ type: ModalAction.Unset });
      const requestType = "out_of_bags"

      try {
        const path = `/returns/requestSupport`;
        const body = { locationID: returnistaLocationID, requestType: requestType}
        const resp = await axios.post(path, body);
        ga.pageEvent({
          category: RequestSupportActions.ModalName,
          action: RequestSupportActions.SupportRequestSubmitted,
        });
        dispatch({ type: LoadingAction.Unset });
        dispatch({ type: CurrentPageAction.SupportConfirmation });
      } catch (e) {
        logger.Warning("Error submitting a support request", {
          err: e,
          locationID: returnistaLocationID,
          requestType: requestType
        });
        ga.pageEvent({ category: RequestSupportActions.ModalName, action: RequestSupportActions.SupportRequestFailed });
        dispatch({ type: LoadingAction.Unset });
        outOfBagsRequestError(dispatch, returnistaLocationID, returnistaLocationOwnerName, currentPage);
      } finally {
        dispatch({ type: LoadingAction.Unset});
      }
    };

    dispatch({
      type: ModalAction.Set,
      modalProps: {
        primaryMessage: "Order Happy Returns bags",
        subMessages: ["Do you need more Happy Returns bags? Before clicking yes, please check for supplies in the back and ask your store manager to confirm you're out of stock."],
        onRequestClose,
        iconElement: (
          <$IconWrapper data-cy="request-out-of-bags-info-icon-modal">
            <ReturnistaFeedbackIcon status="info" />
          </$IconWrapper>
        ),
        button: (
          <$RequestSupportButton>
            <ReturnistaPrimaryButton
                variant="outlined"
                onClick={onRequestClose}
                dataCyString={DataCyStrings.cancelOutOfBagsButton}
            >
              No
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton onClick={handleConfirmOufOfBags} dataCyString={DataCyStrings.confirmOutOfBagsButton}>
              Yes
            </ReturnistaPrimaryButton>
          </$RequestSupportButton>
        ),
      },
    });
  };

  export {confirmOutOfBags, outOfBagsRequestError};