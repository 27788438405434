import React from "react";
import { $RetailerLogoLink } from "./styles";
interface RetailerLogoProps {
  homeURL?: string,
  logoURL:  string
  alt:      string,
  onClick?: (e:any) => void
}

/**
 * Used to generate an accessible retailer logo
 * 
 * If a homeURL is provided, the logo will be wrapped
 * in an anchor tag that sends the user to homeURL
 */
const RetailerLogo = ({ homeURL, logoURL, alt, onClick }:RetailerLogoProps) => {
  if (homeURL) {
    return (
      <$RetailerLogoLink href={homeURL} target="_blank" onClick={onClick}>
        <img src={logoURL} alt={alt} />
        {/* used for accessibility purposes, does not show in UI */}
        <div className="helper-text">{alt}</div>
      </$RetailerLogoLink>
    )
  } else {
    return (
      <img src={logoURL} alt={alt} />
    )
  }
}

export default RetailerLogo;