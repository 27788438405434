import styled from "styled-components";
import $NavigationCard from "../../components/NavigationCard/styles";

interface IRefundOptionsProps{
  isActive: boolean
  shrinkToFit?: boolean;
}

export const $RefundOptions = styled.div<IRefundOptionsProps>`
  display: ${(props) => props.isActive ? "flex" : "none"};
  flex-direction: column;

  .refund-option {
    margin: 8px 0;
    min-height: 80px;
    width: 100%;
    display: flex;
    white-space: pre-line;
    .title {
      font-weight: normal;
    }
    .subtext {
      color: var(--medium-emphasis-black);
      line-height: 20px;
    }
    &.selected {
      border: none;
      ${$NavigationCard}{
        border: 1px solid var(--text-black);
        outline: 1px solid var(--text-black);
        outline-offset: -2px;
      }

    }
  }

  button.show-all-options {
    width: fit-content;
    height: 48px;
    padding: 0px 16px;
    margin-top: 8px;
    background: white;
    border: 1px solid var(--border-grey);
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;
    font-family: "sofia-pro", sans-serif;
    font-size: 15px;
    line-height: 16px;
    color: var(--text-black);
    align-self: center;
    &:hover {
      @media (min-width: 759px) {
        border: 1px solid var(--text-black);
      }
    }
  }
`;

// export default $RefundOptions;
