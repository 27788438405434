import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import {
  CurrentItemAction,
  CurrentPageAction,
  EventAction,
  FatalErrorAction,
  ModalAction,
  MultiStoreAction,
  NoMatchWarningAction,
  PuzzleAttemptsAction,
  RuntimeType,
  TerminalReason,
} from "../../redux/enums";
import Content from "../../components/ReturnistaContent";
import { DataCyStrings } from "../../types/DataCyStrings";
import {
  $SubheadingContainer,
  $ButtonGroupContainer,
  $WarningIconContainer,
  $WarningIconCircle,
  $ImageCardGroup,
} from "./styles";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import { RootReducer } from "../../redux/returnista-store";
import ReturnistaAnalytics from "../../utility/ReturnistaAnalytics";
import ReturnistaAlertMessage from "../../components/ReturnistaAlertMessage";
import ReturnistaImageManager from "../../utility/ReturnistaImageManager";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import ReturnistaToaster from "../../components/ReturnistaToaster";
import ga from "../../utility/GAEmitter";
import { ItemPuzzleActions } from "../../types/ReturnistaGA";
import { CurrentPage } from "../../redux/enums";
import { setReturnistaEvent } from "../../utility/returnistaEvents";
import { eventLabels } from "../../redux/returnistaReducers/events";

const ItemNotListedModalSpec = (dispatch, items, runtime, puzzleAttempts) => {
  const returnEmpty = items.allIds.length === 0;
  const onClose = () => {
    dispatch({ type: ModalAction.Unset });
    ga.pageEvent({ category: CurrentPage.ItemSelector, action: ItemPuzzleActions.ItemNotListedClose });
    ReturnistaAnalytics.itemNotListedAction("modal-closed", puzzleAttempts);
  };
  const goToReturnSummary = () => {
    dispatch({ type: ModalAction.Unset });
    ga.pageEvent({ category: CurrentPage.ItemSelector, action: ItemPuzzleActions.ItemNotListedAddItem });
    ReturnistaAnalytics.itemNotListedAction("return-summary", puzzleAttempts);
    dispatch({
      type: CurrentPageAction.BackToSummary,
    });
  };
  const cancelReturn = () => {
    dispatch({ type: ModalAction.Unset });
    ga.pageEvent({ category: CurrentPage.ItemSelector, action: ItemPuzzleActions.ItemNotListedCancelReturn });
    ReturnistaAnalytics.itemNotListedAction("return-cancelled", puzzleAttempts);
    if (runtime === RuntimeType.Partner) {
      dispatch({
        type: FatalErrorAction.Set,
        reason: TerminalReason.ReturnCancelled,
      });
      dispatch({
        type: CurrentPageAction.FatalError,
      });
      return;
    }
    dispatch({ type: MultiStoreAction.AppReset });
  };
  const addItem = () => {
    dispatch({ type: ModalAction.Unset });
    ReturnistaAnalytics.itemNotListedAction("new-item", puzzleAttempts);
    dispatch({ type: CurrentPageAction.NewItem });
  };

  return {
    type: ModalAction.Set,
    modalProps: {
      primaryMessage: "Item not listed",
      subMessages: [
        `Return the item to the shopper and let them know it's not part of their return. You can add another item or ${
          returnEmpty ? "cancel return" : "return to the summary page"
        } if shopper has no more items.`,
      ],
      onRequestClose: onClose,
      iconElement: (
        <$WarningIconContainer>
          <ReturnistaFeedbackIcon status="warning" />
        </$WarningIconContainer>
      ),
      button: (
        <$ButtonGroupContainer>
          <ReturnistaPrimaryButton
            variant="outlined"
            onClick={returnEmpty ? cancelReturn : goToReturnSummary}
            dataCyString={returnEmpty ? "cancelReturnButton" : "returnToSummaryButton"}
          >
            {returnEmpty ? "Cancel return" : "Return to summary"}
          </ReturnistaPrimaryButton>
          <ReturnistaPrimaryButton onClick={addItem} dataCyString="addAnotherItemButton">
            Add another item
          </ReturnistaPrimaryButton>
        </$ButtonGroupContainer>
      ),
    },
  };
};

type ImageItem = {
  id: string | null;
  key: string;
  image: string;
};

const ItemSelector = () => {
  const maxInvalidAttempts = 2;
  const dispatch = useDispatch();
  const { returnObject, fakeImages, items, noMatchWarning, puzzleAttempts, runtime, currentPage } = useSelector<
    RootReducer,
    RootReducer
  >((store) => store);
  const [imageCards, setImageCards] = useState<Array<ImageItem>>([]);
  const realImageItems: Array<ImageItem> = returnObject.returning
    .filter((item) => !items.allIds.includes(item.id) && !item.purchase.returnStarted)
    .map((item) => ({
      id: item.id,
      key: item.id,
      image: item?.purchase?.images?.[0] ?? item?.purchase?.thumbnail ?? "",
    }));
  const realImageURLs = returnObject.returning.map(
    (item) => item?.purchase?.images?.[0] ?? item?.purchase?.thumbnail ?? ""
  );
  const fakeImageItems = fakeImages
    .filter((fakeImage) => !realImageURLs.includes(fakeImage))
    .map((fakeImage) => ({
      id: null,
      key: fakeImage,
      image: fakeImage,
    }));

  useEffect(() => {
    if (fakeImageItems.length === 0) {
      ReturnistaAnalytics.fakeImagesNotSeen(puzzleAttempts);
    } else {
      ReturnistaAnalytics.fakeImagesSeen(puzzleAttempts);
    }
  
    randomizeImageCards(0);
  }, []);

  const openItemNotListedModal = () => {
    ga.pageEvent({ category: currentPage, action: ItemPuzzleActions.ItemNotListed });
    dispatch(ItemNotListedModalSpec(dispatch, items, runtime, puzzleAttempts));
  };

  const handleItemClick = (id: string | null, imgUrl: string) => {
    // If the item is in the return, set the current item and move to the next page
    if (id != null) {
      ga.pageEvent({ category: currentPage, action: ItemPuzzleActions.ValidItemSelected });
      ReturnistaAnalytics.validItemSelected(imgUrl);
      dispatch({ type: CurrentItemAction.Set, itemId: id });
      dispatch({ type: CurrentPageAction.Next });
      dispatch({ type: NoMatchWarningAction.Unset });
      return;
    }
    // Track the correct number of puzzle attempts as the value has already been captured in this closure
    // scope and updates from the dispatch call will not properly be reflected
    let attempts = puzzleAttempts;
    if (puzzleAttempts < maxInvalidAttempts) {
      dispatch({ type: PuzzleAttemptsAction.Increase });
      attempts += 1;
    }
    // If the item is not in the return, increment the puzzle attempts count show the failure modal
    const onClose = () => {
      ga.pageEvent({ category: currentPage, action: ItemPuzzleActions.NotInReturnClosed });
      randomizeImageCards(attempts);
      dispatch({ type: ModalAction.Unset });
    };
    const onTryAgain = () => {
      ga.pageEvent({ category: currentPage, action: ItemPuzzleActions.NotInReturnTryAgain });
      randomizeImageCards(attempts);
      dispatch({ type: ModalAction.Unset });
    };
    dispatch({
      type: EventAction.Add,
      event: setReturnistaEvent({
        returnID: returnObject.id,
        eventTypeLabel: eventLabels.ITEM_PUZZLE_INCORRECT_SELECTION,
      }),
    });
    ga.pageEvent({ category: currentPage, action: ItemPuzzleActions.InvalidItemSelected });
    ReturnistaAnalytics.invalidItemSelected(imgUrl, attempts === maxInvalidAttempts);
    dispatch({
      type: ModalAction.Set,
      modalProps: {
        iconElement: (
          <$WarningIconContainer>
            <ReturnistaFeedbackIcon status="warning" />
          </$WarningIconContainer>
        ),
        primaryMessage: "This item is not in the return",
        subMessages: ["Please choose the image that most closely matches the physical item."],
        button: (
          <ReturnistaPrimaryButton onClick={onTryAgain} dataCyString="">
            Try again
          </ReturnistaPrimaryButton>
        ),
        onRequestClose: onClose,
      },
    });
  };

  const shuffleItems = (items: Array<ImageItem>) => {
    const itemCopy = items.slice();
    const result: Array<ImageItem> = [];
    while (itemCopy.length > 0) {
      const num = Math.floor(Math.random() * itemCopy.length);
      result.push(...itemCopy.splice(num, num + 1));
    }
    return result;
  };

  const randomizeImageCards = (attempts: number) => {
    // If attempts is equal to `maxInvalidAttempts` or more then only show the real images
    if (attempts >= maxInvalidAttempts) {
      setImageCards(realImageItems);
      return;
    }

    if (realImageItems.length >= 9) {
      setImageCards(shuffleItems(realImageItems));
      return;
    }

    if (realImageItems.length >= 5) {
      const imageCards = realImageItems.concat(shuffleItems(fakeImageItems)).slice(0, 9);
      setImageCards(shuffleItems(imageCards));
      return;
    }

    if (realImageItems.length >= 3) {
      const imageCards = realImageItems.concat(shuffleItems(fakeImageItems)).slice(0, 6);
      setImageCards(shuffleItems(imageCards));
      return;
    }

    const imageCards = realImageItems.concat(shuffleItems(fakeImageItems)).slice(0, 4);
    setImageCards(shuffleItems(imageCards));
  };

  return (
    <>
      <Header left={<HeaderBackButton />} center="Find the item" />
      <Content contentStyle="overflow-y: auto" childStyle="max-width: 720px">
        <ReturnistaToaster
          show={noMatchWarning}
          message="The barcode doesn't have a match."
          type="error"
          data-cy={DataCyStrings.alertMessage}
        />

        <ReturnistaAlertMessage
          message="Some images are part of the return."
          dataCyString={DataCyStrings.onlySomeImagesArePartOfReturn}
        />

        <$SubheadingContainer>
          <div className="subheading">Find the matching image.</div>
          <div className="notListedLink" data-cy="not-listed-link" onClick={openItemNotListedModal}>
            Item not listed
          </div>
        </$SubheadingContainer>
        {imageCards.length !== 4 ? (
          <$ImageCardGroup>
            {imageCards.map(({ id, key, image }) => {
              return (
                <div
                  data-cy={`image-card-${key}`}
                  className="imageCard"
                  key={key}
                  data-image={image}
                  onClick={() => handleItemClick(id, image)}
                  style={{ backgroundImage: `url(${ReturnistaImageManager.getImage(image)})` }}
                />
              );
            })}
          </$ImageCardGroup>
        ) : (
          <>
            <$ImageCardGroup>
              {imageCards.slice(0, 2).map(({ id, key, image }) => {
                return (
                  <div
                    data-cy={`image-card-${key}`}
                    className="imageCard"
                    key={key}
                    data-image={image}
                    onClick={() => handleItemClick(id, image)}
                    style={{ backgroundImage: `url(${ReturnistaImageManager.getImage(image)})` }}
                  />
                );
              })}
            </$ImageCardGroup>
            <$ImageCardGroup>
              {imageCards.slice(2, 4).map(({ id, key, image }) => {
                return (
                  <div
                    data-cy={`image-card-${key}`}
                    className="imageCard"
                    key={key}
                    data-image={image}
                    onClick={() => handleItemClick(id, image)}
                    style={{ backgroundImage: `url(${ReturnistaImageManager.getImage(image)})` }}
                  />
                );
              })}
            </$ImageCardGroup>
          </>
        )}
      </Content>
    </>
  );
};

export default ItemSelector;
