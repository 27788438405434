import React from "react";
import { DataCyStrings } from "../../types/DataCyStrings";
import $ItemPresentCounter from "./styles";

const ItemsPresentCounter = ({ itemsPresentCount, itemsSelectedCount }:{
  itemsPresentCount?: number,
  itemsSelectedCount: number
}) => {
  const hideItemsPresentCount = itemsPresentCount === undefined;
  const containerClassName = hideItemsPresentCount ? "no-items-present-count" : undefined;
  const itemsSelectedClassName = `items-selected ${!hideItemsPresentCount && itemsSelectedCount > itemsPresentCount ?
    "too-many-items" :
    ""}`
  return (
    <$ItemPresentCounter className={containerClassName} data-cy={DataCyStrings.returnCountFooter}>
      {!hideItemsPresentCount &&
        <div className="items-present">
          <div className="count">
            {itemsPresentCount}
          </div>
          <div className="count-label">
            COUNTED
          </div>
        </div>
      }
      <div className={itemsSelectedClassName}>
        <div className="count" data-cy={DataCyStrings.returnCount}>
          {itemsSelectedCount}
        </div>
        <div className="count-label">
          SELECTED
        </div>
      </div>
    </$ItemPresentCounter>
  )
}

export default ItemsPresentCounter;