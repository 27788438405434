import React from "react";
import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 18px;
  width: 100%;
  background-color: var(--hr-yellow);
  position: fixed;

  @media (min-width: ${tabletOrLargerDevices}) {
    position: relative;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:first-child {
    justify-content: left;

    @media (min-width: ${tabletOrLargerDevices}) {
      padding-left: 24px;
    }
  }

  & > div:last-child {
    justify-content: right;

    @media (min-width: ${tabletOrLargerDevices}) {
      padding-right: 24px;
    }
  }
`;

export const $HeaderLogo = styled.div`
  & > svg {
    width: 124px;
    height: 32px;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 200px;
      height: 40px;
    }
  }
`;

export const $BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    margin-right: 12px;
  }

  & > svg > path {
    fill: var(--text-black);
  }

  span {
    display: none;
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    span {
      display: inline;
    }
  }
`;
