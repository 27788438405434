import React from "react";

const HRLogoHappyReturns = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="118" height="18" viewBox="0 0 118 18" fill="none">
    <path
      d="M6.30727 14.5308C4.0949 14.4604 1.96937 13.6533 0.267798 12.2376C0.200657 12.1837 0.145248 12.1166 0.104974 12.0405C0.0646993 11.9644 0.0404097 11.8808 0.0335967 11.795C0.0267837 11.7092 0.0375913 11.6228 0.0653557 11.5413C0.09312 11.4598 0.137255 11.3848 0.19505 11.321C0.252844 11.2572 0.323078 11.2058 0.401438 11.1701C0.479798 11.1344 0.56463 11.1151 0.650722 11.1134C0.736814 11.1117 0.82235 11.1276 0.902076 11.1601C0.981801 11.1927 1.05403 11.2411 1.11433 11.3026C1.31649 11.4795 6.05458 15.6617 11.2222 11.3026C11.3492 11.1957 11.5132 11.143 11.6787 11.156C11.8442 11.169 11.998 11.2466 12.1067 11.3721C12.2148 11.4956 12.2707 11.6562 12.2624 11.8201C12.2541 11.984 12.1824 12.1383 12.0625 12.2502C10.4858 13.6859 8.43937 14.4968 6.30727 14.5308Z"
      fill="black"
    />
    <path
      d="M0.925171 0H2.18866V3.53145C2.38106 3.22921 2.65196 2.98492 2.97242 2.82469C3.29288 2.66446 3.65085 2.59431 4.00808 2.62174C4.32368 2.5951 4.64084 2.64961 4.92943 2.78009C5.21803 2.91056 5.46846 3.11267 5.65694 3.3672C5.94267 3.86017 6.06848 4.42963 6.01703 4.9971V8.26953H4.75354V5.42037C4.72827 4.47907 4.48189 3.79047 3.53428 3.79047C2.58666 3.79047 2.20761 4.47907 2.20761 5.42037V8.26953H0.944123L0.925171 0Z"
      fill="black"
    />
    <path
      d="M10.4382 5.00897C10.6763 5.00895 10.9091 4.93849 11.1072 4.80646C11.3054 4.67442 11.46 4.48671 11.5517 4.26696C11.6434 4.04722 11.668 3.80525 11.6225 3.57154C11.5769 3.33783 11.4633 3.12282 11.2958 2.95357C11.1283 2.78433 10.9145 2.66842 10.6813 2.62045C10.448 2.57247 10.2058 2.59457 9.98513 2.68397C9.76444 2.77336 9.57513 2.92605 9.44103 3.12281C9.30694 3.31957 9.23406 3.55161 9.23157 3.7897C9.23157 4.10972 9.35869 4.41663 9.58498 4.64292C9.81127 4.86921 10.1182 4.99633 10.4382 4.99633"
      fill="black"
    />
    <path
      d="M26.8521 9.97526H22.5689V14.3596H20.8632V3.98633H22.5689V8.44644H26.8521V3.98633H28.5515V14.3596H26.8521V9.97526Z"
      fill="black"
    />
    <path
      d="M35.437 10.9037C35.4591 10.6239 35.423 10.3426 35.3312 10.0774C35.2393 9.81225 35.0935 9.56896 34.903 9.36285C34.7126 9.15675 34.4815 8.99228 34.2244 8.8798C33.9673 8.76732 33.6897 8.70925 33.4091 8.70925C33.1284 8.70925 32.8508 8.76732 32.5937 8.8798C32.3366 8.99228 32.1056 9.15675 31.9151 9.36285C31.7246 9.56896 31.5789 9.81225 31.487 10.0774C31.3951 10.3426 31.3591 10.6239 31.3812 10.9037C31.3591 11.1834 31.3951 11.4647 31.487 11.7299C31.5789 11.9951 31.7246 12.2384 31.9151 12.4445C32.1056 12.6506 32.3366 12.815 32.5937 12.9275C32.8508 13.04 33.1284 13.0981 33.4091 13.0981C33.6897 13.0981 33.9673 13.04 34.2244 12.9275C34.4815 12.815 34.7126 12.6506 34.903 12.4445C35.0935 12.2384 35.2393 11.9951 35.3312 11.7299C35.423 11.4647 35.4591 11.1834 35.437 10.9037ZM37.0542 7.45433V14.3593H35.437V13.4243C35.1953 13.7718 34.8718 14.0545 34.495 14.2473C34.1181 14.4401 33.6996 14.5372 33.2764 14.5299C32.8049 14.5301 32.338 14.4356 31.9036 14.2522C31.4692 14.0688 31.076 13.8 30.7473 13.4619C30.4186 13.1238 30.1611 12.7232 29.99 12.2837C29.8189 11.8443 29.7378 11.375 29.7513 10.9037C29.7368 10.4305 29.818 9.95923 29.9899 9.51814C30.1619 9.07706 30.421 8.67521 30.7519 8.33664C31.0828 7.99808 31.4786 7.72976 31.9157 7.54777C32.3527 7.36577 32.822 7.27383 33.2954 7.27745C33.716 7.27397 34.131 7.37348 34.5043 7.56729C34.8776 7.76109 35.1978 8.04331 35.437 8.38932V7.45433H37.0542Z"
      fill="black"
    />
    <path
      d="M40.58 10.9065C40.5526 11.1897 40.5848 11.4755 40.6744 11.7456C40.764 12.0156 40.9091 12.2639 41.1004 12.4746C41.2916 12.6852 41.5248 12.8536 41.785 12.9688C42.0452 13.0839 42.3266 13.1434 42.6111 13.1434C42.8956 13.1434 43.177 13.0839 43.4372 12.9688C43.6973 12.8536 43.9306 12.6852 44.1218 12.4746C44.3131 12.2639 44.4582 12.0156 44.5478 11.7456C44.6374 11.4755 44.6695 11.1897 44.6421 10.9065C44.6576 10.6302 44.6165 10.3537 44.5213 10.0939C44.4262 9.8341 44.2791 9.59642 44.089 9.3954C43.8989 9.19438 43.6698 9.03426 43.4157 8.92482C43.1615 8.81539 42.8878 8.75895 42.6111 8.75895C42.3344 8.75895 42.0606 8.81539 41.8065 8.92482C41.5524 9.03426 41.3233 9.19438 41.1332 9.3954C40.943 9.59642 40.7959 9.8341 40.7008 10.0939C40.6057 10.3537 40.5646 10.6302 40.58 10.9065ZM40.58 8.37951C40.819 8.03478 41.1382 7.75341 41.5103 7.55969C41.8823 7.36597 42.2959 7.26574 42.7153 7.26764C43.1882 7.26487 43.6568 7.35744 44.0931 7.53982C44.5294 7.7222 44.9244 7.99064 45.2546 8.32912C45.5848 8.6676 45.8435 9.06916 46.015 9.50982C46.1866 9.95048 46.2675 10.4212 46.2531 10.8939C46.2693 11.3675 46.1896 11.8396 46.0188 12.2818C45.848 12.7239 45.5897 13.127 45.2593 13.4668C44.9289 13.8067 44.5333 14.0763 44.0961 14.2594C43.6589 14.4426 43.1893 14.5355 42.7153 14.5327C42.2959 14.5346 41.8823 14.4344 41.5103 14.2406C41.1382 14.0469 40.819 13.7656 40.58 13.4208V17.843H38.9628V7.45716H40.58V8.37951Z"
      fill="black"
    />
    <path
      d="M48.8618 10.9065C48.8344 11.1897 48.8666 11.4755 48.9562 11.7456C49.0458 12.0156 49.1909 12.2639 49.3821 12.4746C49.5734 12.6852 49.8066 12.8536 50.0668 12.9688C50.3269 13.0839 50.6083 13.1434 50.8929 13.1434C51.1774 13.1434 51.4588 13.0839 51.719 12.9688C51.9791 12.8536 52.2123 12.6852 52.4036 12.4746C52.5949 12.2639 52.74 12.0156 52.8296 11.7456C52.9192 11.4755 52.9513 11.1897 52.9239 10.9065C52.9393 10.6302 52.8982 10.3537 52.8031 10.0939C52.708 9.8341 52.5609 9.59641 52.3708 9.3954C52.1807 9.19438 51.9516 9.03426 51.6974 8.92482C51.4433 8.81539 51.1695 8.75895 50.8929 8.75895C50.6162 8.75895 50.3424 8.81539 50.0883 8.92482C49.8342 9.03426 49.605 9.19438 49.4149 9.3954C49.2248 9.59641 49.0777 9.8341 48.9826 10.0939C48.8875 10.3537 48.8464 10.6302 48.8618 10.9065ZM48.8618 8.37951C49.1007 8.03478 49.42 7.75341 49.792 7.55969C50.1641 7.36597 50.5777 7.26574 50.9971 7.26764C51.4702 7.2649 51.9391 7.35744 52.3757 7.53975C52.8124 7.72205 53.2078 7.9904 53.5385 8.32878C53.8693 8.66716 54.1285 9.06866 54.3007 9.50935C54.473 9.95003 54.5548 10.4209 54.5412 10.8939C54.5565 11.3679 54.476 11.8401 54.3045 12.2822C54.133 12.7244 53.8741 13.1274 53.5432 13.4672C53.2123 13.8069 52.8163 14.0764 52.3788 14.2595C51.9413 14.4426 51.4713 14.5355 50.9971 14.5327C50.5777 14.5346 50.1641 14.4344 49.792 14.2406C49.42 14.0469 49.1007 13.7656 48.8618 13.4208V17.843H47.2509V7.45716H48.8618V8.37951Z"
      fill="black"
    />
    <path
      d="M58.9876 17.9594C58.1644 17.9958 57.354 17.7458 56.6944 17.2518C56.3862 16.99 56.1409 16.662 55.9768 16.2924C55.8128 15.9227 55.7342 15.5208 55.7468 15.1165H57.3767C57.3813 15.3224 57.4277 15.5251 57.5132 15.7124C57.5987 15.8997 57.7214 16.0677 57.8739 16.206C58.0264 16.3443 58.2055 16.4501 58.4002 16.517C58.595 16.5838 58.8013 16.6103 59.0066 16.5948C59.9984 16.5948 60.6934 16.241 60.6934 14.9523V13.3666C60.4587 13.7457 60.1256 14.054 59.7296 14.2588C59.3335 14.4635 58.8893 14.5569 58.4443 14.529C58.0471 14.5617 57.6481 14.493 57.2846 14.3295C56.9211 14.166 56.605 13.9129 56.3659 13.594C56.0115 12.9734 55.8571 12.2587 55.9237 11.5472V7.44083H57.5473V10.9912C57.5473 12.1789 57.8884 13.0823 59.0445 13.0823C60.2006 13.0823 60.706 12.1789 60.706 10.9912V7.42188H62.3233V14.9523C62.3537 15.3719 62.2926 15.7932 62.1443 16.187C61.9959 16.5807 61.7639 16.9376 61.4641 17.2329C60.7648 17.7815 59.887 18.0516 59.0003 17.991L58.9876 17.9594Z"
      fill="black"
    />
    <path
      d="M73.834 7.61856C73.8512 7.34974 73.8134 7.08025 73.723 6.8265C73.6327 6.57275 73.4916 6.34007 73.3083 6.14264C73.1251 5.94521 72.9035 5.78716 72.6572 5.67815C72.4109 5.56913 72.145 5.51142 71.8756 5.50853H69.8603V9.72227H71.8756C72.1444 9.71934 72.4098 9.66181 72.6556 9.55318C72.9015 9.44455 73.1227 9.28709 73.3059 9.09034C73.489 8.8936 73.6303 8.66169 73.7211 8.40868C73.8119 8.15568 73.8503 7.88687 73.834 7.61856ZM68.1483 3.98603H71.8693C72.3515 3.9713 72.8316 4.055 73.2804 4.23201C73.7292 4.40902 74.1372 4.67564 74.4795 5.01558C74.8218 5.35552 75.0912 5.76165 75.2713 6.20919C75.4514 6.65672 75.5385 7.13627 75.5271 7.61856C75.5403 8.33305 75.3391 9.03511 74.9495 9.63417C74.5599 10.2332 73.9997 10.7018 73.3413 10.9794L75.4007 14.3593H73.4613L71.5661 11.2448H69.8603V14.3593H68.1546L68.1483 3.98603Z"
      fill="black"
    />
    <path
      d="M77.7634 10.1977H81.2001C81.2166 9.9722 81.1849 9.74575 81.107 9.53346C81.0291 9.32117 80.9069 9.1279 80.7486 8.96652C80.5902 8.80514 80.3992 8.67935 80.1884 8.59753C79.9776 8.51571 79.7518 8.47974 79.526 8.49201C79.2941 8.47068 79.0603 8.49969 78.8406 8.57706C78.621 8.65444 78.4206 8.77834 78.2532 8.94031C78.0859 9.10227 77.9555 9.29847 77.8709 9.51549C77.7864 9.73251 77.7497 9.96522 77.7634 10.1977ZM77.7634 11.398C77.8393 12.3899 78.2688 13.1922 79.6145 13.1922C79.9254 13.2329 80.2408 13.1653 80.5077 13.0008C80.7747 12.8363 80.9768 12.5848 81.0801 12.2888H82.7163C82.5394 13.4702 81.4023 14.5315 79.6587 14.5315C77.3339 14.5315 76.2094 12.9332 76.2094 10.899C76.2094 8.86474 77.4349 7.27274 79.6145 7.27274C80.0308 7.26091 80.4454 7.33273 80.8335 7.48394C81.2216 7.63516 81.5755 7.86269 81.8741 8.15309C82.1728 8.44348 82.4101 8.79083 82.5722 9.17458C82.7342 9.55834 82.8176 9.97069 82.8174 10.3872C82.8229 10.7258 82.7975 11.0641 82.7416 11.398H77.7634Z"
      fill="black"
    />
    <path
      d="M88.5917 14.3593C88.1945 14.4194 87.7932 14.449 87.3914 14.4478C86.6081 14.4478 84.9276 14.3467 84.9276 12.0155V8.79997H83.7526V7.45436H84.9276V5.36328H86.766V7.45436H88.3517V8.79997H86.766V11.6491C86.766 12.8495 87.1387 12.9948 87.7768 12.9948C88.0494 12.9947 88.3217 12.9757 88.5917 12.9379V14.3593Z"
      fill="black"
    />
    <path
      d="M96.3999 7.45703V14.362H94.7827V13.4017C94.5449 13.7777 94.2095 14.082 93.8123 14.2822C93.4151 14.4825 92.971 14.5712 92.5274 14.5389C92.1309 14.5722 91.7326 14.5039 91.37 14.3403C91.0074 14.1767 90.6925 13.9232 90.4552 13.6039C90.101 12.9856 89.9465 12.2729 90.013 11.5634V7.45703H91.624V11.0264C91.624 12.2141 91.9651 13.1175 93.1212 13.1175C94.2773 13.1175 94.7827 12.2141 94.7827 11.0264V7.45703H96.3999Z"
      fill="black"
    />
    <path
      d="M102.522 8.95215C102.316 8.89646 102.103 8.87093 101.89 8.87634C100.513 8.87634 99.7546 9.6597 99.7546 11.4033V14.3851H98.1373V7.45491H99.7546V8.56678C99.9768 8.19959 100.29 7.89609 100.664 7.68577C101.039 7.47546 101.461 7.36546 101.89 7.36647C102.105 7.35809 102.32 7.39245 102.522 7.46755V8.95215Z"
      fill="black"
    />
    <path
      d="M103.786 7.45607H105.397V8.41632C105.635 8.04041 105.97 7.7361 106.367 7.53583C106.764 7.33557 107.208 7.24687 107.652 7.27918C108.048 7.24703 108.446 7.31593 108.809 7.47946C109.171 7.64298 109.486 7.89577 109.724 8.21417C110.08 8.83418 110.237 9.54886 110.173 10.261V14.3674H108.555V10.7917C108.555 9.604 108.214 8.70061 107.058 8.70061C105.902 8.70061 105.397 9.604 105.397 10.7917V14.361H103.786V7.45607Z"
      fill="black"
    />
    <path
      d="M117.008 12.433C117.008 13.8733 115.744 14.5367 114.253 14.5367C112.763 14.5367 111.436 13.6649 111.436 12.0097H113.009C113.009 12.9131 113.571 13.2353 114.342 13.2353C114.974 13.2353 115.466 13.0331 115.466 12.4393C115.466 11.8454 114.772 11.7444 113.319 11.1758C112.358 10.8094 111.676 10.4493 111.676 9.24898C111.676 7.94758 112.857 7.25266 114.266 7.25266C114.594 7.22868 114.923 7.27051 115.234 7.37567C115.546 7.48084 115.833 7.64722 116.079 7.86502C116.325 8.08283 116.525 8.34766 116.667 8.64392C116.809 8.94018 116.891 9.26189 116.907 9.59012H115.334C115.35 9.44208 115.332 9.2923 115.281 9.15233C115.23 9.01236 115.148 8.88593 115.04 8.78278C114.933 8.67964 114.803 8.60253 114.661 8.5574C114.52 8.51226 114.369 8.50032 114.222 8.52247C113.672 8.52247 113.23 8.79412 113.23 9.28056C113.23 9.8744 113.862 9.95021 115.125 10.4367C116.092 10.8094 117.021 11.1505 117.021 12.4077"
      fill="black"
    />
  </svg>
);

export default HRLogoHappyReturns;
