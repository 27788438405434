import React from "react";

/**
 * Play button icon
 */
const Play = (props) => (
  <>
    <svg {...props} width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.2187 12.9097C24.058 13.3943 24.058 14.6057 23.2187 15.0903L2.88838 26.828C2.0491 27.3125 1 26.7068 1 25.7377V2.26229C1 1.29317 2.0491 0.687474 2.88838 1.17203L23.2187 12.9097Z" stroke="#737373" strokeWidth="2"/>
    </svg>
  </>
);

export default Play;