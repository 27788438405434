import { MultiStoreAction, NoMatchWarningAction } from "../enums";

const noMatchWarningReducer = (state = { displayNoMatchWarning: false }, action) => {
  switch (action.type) {
    case NoMatchWarningAction.Set:
      return true;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case NoMatchWarningAction.Unset:
      return false;
    default:
      return state;
  }
};

export default noMatchWarningReducer;
