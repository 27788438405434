import axios, { AxiosInstance } from "axios";
import "regenerator-runtime/runtime";
import {
  connectionTimeoutMilliseconds,
  handleAxiosError,
  getExpressCodeFromURL, getReturnistaJWTClaims,
} from "../../utility";
import { Config } from "../../types/Config";
import {
  SET_TOKEN,
  SET_CONFIGURATION,
  GOTO_PAGE,
  ON_FATAL_ERROR,
  SET_RETURN_PORTAL_TIMEOUT,
  SET_ALERT_NAME,
  SET_LOADING_MESSAGE,
  FINALIZE,
  RESET_APP,
  SET_QUERIES,
  SET_LOCAL_ERROR_MESSAGE,
  SET_SCAN_PATH,
  SET_CONFIRMATION_PATH,
  SET_EXPIRED_TOKEN_RETURNISTA,
  PORTAL_PURCHASES_ERROR,
  SET_RETURN_STARTED,
  SET_LOCALE,
  SET_PLATFORM,
  SET_RETURN_SHOPPING_ENABLED,
  SET_LABEL_COUNT,
  Store
} from "./types";
import { FatalErrors, Alerts } from "../../types/LifeCycle";
import i18n, {localizationDisabled, defaultLocale} from "../../i18n";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import ga from "../../utility/GAEmitter";
import logger from "../../utility/logger/logger";
import { AppRuntimes } from "../../types/AppRuntimes";

//---------------------------------------------------------------------------
// ACTIONS ------------------------------------------------------------------
// https://redux.js.org/faq/actions#actions
// Actions are consumed by their respective reducer and
// alter store depending on the action type within the
// reducer's switch case

// token: same format as the state's token
export const setToken = token => ({
  type: SET_TOKEN,
  payload: token,
});

// shows a loading indicator
// message: string containing desired loading message
// if a loading indicator is already showing, this call will update the message
// of that indicator. (clear only needs to be called once in this case, not twice)
export const showLoadingIndicator = message => ({
  type: SET_LOADING_MESSAGE,
  payload: message,
});
// clears loading indicator
export const clearLoadingIndicator = () => ({
  type: SET_LOADING_MESSAGE,
  payload: "",
});

// triggers a fatal error -- which will end the application flow and show user
// an error message of the
export const onFatalError = (errorType: FatalErrors) => ({
  type: ON_FATAL_ERROR,
  payload: errorType,
});

// sets the timeout state for the return portal. this could be made more generic, but
// is only necessary for that runtime currently
export const setReturnPortalTimeout = (timeout: boolean) => ({
  type: SET_RETURN_PORTAL_TIMEOUT,
  payload: timeout,
});

// shows an alert modal of the specified name
// if an alert is already showing, this call will replace the old alert
// with the new one (clear only needs to be called once in this case, not twice)
export const showAlert = (alertName: Alerts) => ({
  type: SET_ALERT_NAME,
  payload: alertName,
});
// clear modal
export const clearAlert = () => ({
  type: SET_ALERT_NAME,
  payload: "",
})

export const setLocalErrorMessage = (message) => ({
  type: SET_LOCAL_ERROR_MESSAGE,
  payload: message,
})

// configuration: an object containing "runtime", "pages", and "initialPage" keys that
// match our state variables
export const setConfiguration = (configuration: Config | Store, expressCode?: string) => ({
  type: SET_CONFIGURATION,
  payload: {
    configuration,
    expressCode
  }

});

export const setLocale = (locale: string) => ({
  type: SET_LOCALE,
  payload: locale
})

// if name is the name of a page in our state's pages array, load that page,
// otherwise do nothing
export const goToPage = name => ({
  type: GOTO_PAGE,
  payload: name,
});

// set the path that we scanned from so instanceList page knows which path to go back
export const setScanPath = name => ({
  type: SET_SCAN_PATH,
  payload: name
});

export const setConfirmationPath = (name) => ({
  type: SET_CONFIRMATION_PATH,
  payload: name,
});

// wipe out this slice of the store entirely
export const reset = () => ({
  type: RESET_APP,
})

// mark our store to force re-initialization upon page reload
export const finalize = () => ({
  type: FINALIZE,
})

export const handleReturnistaExpiredToken = (message?: string) => ({
  type: SET_EXPIRED_TOKEN_RETURNISTA,
  payload: message
})

export const handlePortalPurchasesError = (errorMessage: string) => ({
  type: PORTAL_PURCHASES_ERROR,
  payload: errorMessage
})

export const setReturnStarted = (refreshedToken?) => ({
  type: SET_RETURN_STARTED,
  payload: refreshedToken
})

export function setQueries(queryObj) {
  return {
    type: SET_QUERIES,
    payload: queryObj
  }
}

export const setPlatform = (platformName: string) => ({
  type: SET_PLATFORM,
  payload: platformName
})

export const setReturnShoppingEnabled = (flag: boolean) => ({
  type: SET_RETURN_SHOPPING_ENABLED,
  payload: flag
})

export const setLabelCount = (count: number) => ({
  type: SET_LABEL_COUNT,
  payload: count
})
//---------------------------------------------------------------------------

//---------------------------------------------------------------------------
// ASYNC ACTIONS ------------------------------------------------------------
// (uses redux-think to send actions to the reducer asyncronously)
// https://github.com/reduxjs/redux-thunk

// pull down config from the server, if all good then it use it to load the first page of the app
export function
  getConfigurationAndInitialize(locale, handleLanguageError?) {
    return async function(dispatch) {
      try {
        dispatch(showLoadingIndicator(defaultLoadingSymbol));
        const loadLangPromise = new Promise((res, rej) => {

          // check if language is already loaded
          const langData = i18n.getDataByLanguage(locale)
          if (Object.keys(langData?.translation || {}).length > 0)
          {
            res(locale)
            return
          }

          // otherwise listen for load
          const loaded = lngs => {
            if (!lngs?.hasOwnProperty(locale))
              return;
            removeHandlers()
            res(locale)
          }
          const failedLoading = (lng, _, error) => {
            if (lng != locale)
              return;
            removeHandlers()
            rej(error)
          }
          const removeHandlers = () => {
            i18n.off('loaded', loaded)
            i18n.off('failedLoading', failedLoading)
          }
          i18n.on('loaded', loaded)
          i18n.on('failedLoading', failedLoading)
        })
        // load config and locale bundle concurrently for performance
        const [response] = await Promise.all([
          axios.get<Config>("/config", {
            params: {
              locale
            },
            headers: {
              "X-Hr-Config-Auth-Key": window.CONFIG_AUTH_HEADER_SECRET
            },
            timeout: connectionTimeoutMilliseconds
          }),
          loadLangPromise,
          i18n.loadLanguages(locale)
        ])

        // the server may reply with a different locale than the one we wanted
        // due to retailer locales that are enabled/disabled
        // so we only check language preloading result if the response locale equals the preloaded locale
        if (locale == response?.data?.locale){
          const langData = i18n.getDataByLanguage(locale)
          // Throw an exception if the translation object is empty
          if (Object.keys(langData?.translation || {}).length === 0) {
            logger.Error(`Couldn't fetch locale ${locale}`);
            throw new Error("Unable to load language");
          }
        }

        // if we are in admin mode or feature flagged
        if (localizationDisabled())
          response.data.enableLocalization = false

        const {runtime, analyticsEventsHost} = response.data;
        const expressCode = getExpressCodeFromURL();
        if (runtime) {
          ga.setAnalyticsHost(analyticsEventsHost);
          ga.initialize(runtime);
        }
        dispatch(setConfiguration(response.data, expressCode));

        let platform = '';

        // If the runtime is set to returnista check the underlying platform and send to datadog
        if (runtime === AppRuntimes.returnista) {
          if (/Android/.test(window.navigator.userAgent)) {
            platform = 'android';
            // Specifically if the request is coming from an android device set as app platform
            dispatch(setPlatform(platform));
          } else if (/iPhone/.test(window.navigator.userAgent)) {
            platform = 'ios';
          } else {
            platform = 'browser';
          }
        }

        window.DD_RUM?.setGlobalContext({
          platform,
          runtime,
        });
      }
      catch (error) {
        logger.Error("getConfigurationAndInitialize error", error)
        if (handleLanguageError) {
          // if we fail to load the language file, display the error
          handleLanguageError();
        } else if (locale == defaultLocale) {
          dispatch(onFatalError(FatalErrors.unknown))
        } else {
          // if we fail to load config, get the default config (en-US)
          dispatch(getConfigurationAndInitialize(defaultLocale));
        }
        handleAxiosError(error, dispatch);
      }
      finally {
      dispatch(clearLoadingIndicator());
    }
  }
}

export function refreshToken(catchCb: (e:Error|AxiosInstance) => void) {
  return async function(dispatch) {
    try {
      const resp = await axios.post("/startreturn");
      dispatch(setToken(resp.data));
    } catch(e) {
      catchCb(e);
    }
  }
}

/**
 * when a return is started, attempt to refresh
 * the user JWT, the backend will send a 401 if the
 * refresh window is met, so we log the user out
 * when an error occurs
 */
export function startReturnReturnista() {
  return async function(dispatch) {
    dispatch(refreshToken((e) => {
      console.error(e);
      dispatch(handleReturnistaExpiredToken("Your session has expired. Please log in to continue."));
    }));
    dispatch(setReturnStarted());
  }
}
