import React from "react";
import { SupportInfo } from "../../types/Config";

const templateString = "<support_contact>";
const supportContactLinkPrefixes = {
  email: "mailto:",
  phone: "tel:"
}

interface SupportCopyProps {
  message: string, 
  supportInfo: SupportInfo,
  supportCopyLowercaseFirstLetter?: boolean
}

/**
 * Generates a div containing the defined support copy given in the app
 * config. If a contact type is provided, the template within the copy
 * will be replaced with an a tag to allow direct contact to the support
 * contact.
 * 
 * Space character should be added manually between message and supportInfo
 */
const SupportCopy = ({ supportInfo, supportCopyLowercaseFirstLetter, message }: SupportCopyProps) => {
  // don't allow app to unmount if supportInfo is not defined or if the copy is an empty string
  if(!supportInfo) {
    console.error("Check runtime configuration to ensure that support info is being provided.");
    return null;
  } else if (supportInfo.copy.length === 0) {
    return null;
  }
  
  let className = "support";

  let supportCopy = supportInfo.copy;

  // if supportCopyLowercaseFirstLetter is true, modify existing copy.
  // Also check that the provided copy doesn't begin with template chars
  if (supportCopyLowercaseFirstLetter && supportCopy[0] != templateString[0]) {
    supportCopy = supportInfo.copy[0].toLowerCase() + supportInfo.copy.slice(1);
  }
  
  // if contact has a type, ensure that there's a template
  // in the string. If missing, log an error and render null
  if (supportInfo.supportContactType) {
    if (!supportCopy.includes(templateString)) {
      console.error("Invalid copy. Ensure that the provided copy includes the required template string.");
      return null;
    }
    className += (" " + supportInfo.supportContactType);
    
    // prepend generic message prop to support copy to ensure that
    // text doesn't get break to a new line between the message
    // and support copy
    const supportCopiesWithoutTemplate = (message + supportCopy).split(templateString);
    return (
      <div className={className}>
        {supportCopiesWithoutTemplate[0]}
        <a href={supportContactLinkPrefixes[supportInfo.supportContactType] + supportInfo.supportContact}>
          {supportInfo.supportContact}
        </a>
        {supportCopiesWithoutTemplate[1]}
      </div>
    )
  }
  return (
    <div className={className}>
      {message}
      {supportCopy}
    </div>
  )
}

export default SupportCopy;