import React from "react";
import Modal from "react-modal";
import { $Header, $HeaderTitle } from "../../../app/styles";
import { smallBrowserWidthMax } from "../../../globalConstants";
import useModalStyles from "../../../utility/useModalStyles";
import $ModalStyles from "./styles";

const ReturnistaModalTemplate = ({
  children,
  dataCy,
  onClose,
  headerText
}) => {
  const modalStyles = useModalStyles({
    mobileModalStyles: {
      content: {
        padding: "0"
      }
    },
    maxLowEndWidth: smallBrowserWidthMax
  });
  return (
    <Modal
      isOpen={true}      
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      onRequestClose={onClose}
      style={modalStyles}
    >
      <$ModalStyles data-cy={dataCy}>
        {modalStyles.display === "mobile" &&
          <$Header>
            <$HeaderTitle>
              {headerText}
            </$HeaderTitle>
          </$Header>
        }
        <main>
          {children}
        </main>
      </$ModalStyles>
    </Modal>
  )
}

export default ReturnistaModalTemplate;