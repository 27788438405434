import React from "react";

const ReturnistaBarCode = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_960_35375)">
        <path d="M20 7H22V2H17V4H20V7Z" fill={props.fill} />
        <path d="M22 17V22H17V20H20V17H22Z" fill={props.fill} />
        <path d="M7 22H2V17H4V20H7V22Z" fill={props.fill} />
        <path d="M2 7V2H7V4H4V7H2Z" fill={props.fill} />
        <line x1="5.5" y1="6" x2="5.5" y2="18" stroke={props.stroke} />
        <line x1="18.5" y1="6" x2="18.5" y2="18" stroke={props.stroke} />
        <line x1="10.5" y1="6" x2="10.5" y2="16" stroke={props.stroke} />
        <line x1="8" y1="6" x2="8" y2="16" stroke={props.stroke} strokeWidth="2" />
        <line x1="13.5" y1="6" x2="13.5" y2="16" stroke={props.stroke} strokeWidth="3" />
        <line x1="16.5" y1="6" x2="16.5" y2="16" stroke={props.stroke} />
        <rect x="7" y="17" width="1" height="1" fill={props.fill} />
        <rect x="11" y="17" width="1" height="1" fill={props.fill} />
        <rect x="9" y="17" width="1" height="1" fill={props.fill} />
        <rect x="13" y="17" width="1" height="1" fill={props.fill} />
        <rect x="15" y="17" width="1" height="1" fill={props.fill} />
      </g>
      <defs>
        <clipPath id="clip0_960_35375">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReturnistaBarCode;
