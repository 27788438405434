import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { cloneDeep } from 'lodash'
import logger from "../../utility/logger";
import { PageProps } from "../types";
import { isEmpty } from "lodash";
import {
  showLoadingIndicator,
  clearLoadingIndicator,
  onFatalError,
  handleReturnistaExpiredToken,
  goToPage
} from "../../redux/app/actions";
import { setNewReturnID } from "../../redux/customer/actions";
import useSelector from "../../utility/useTypedSelector";
import {
  getCustomerEmailFromReturn,
  getReturnistaJWTClaims,
  handleAxiosError,
} from "../../utility";
import { PageLifecycle } from "../"
import {
  $CompleteReturn,
} from "./styles";
import { ReturnTypes, Store as CustomerStore} from "../../redux/customer/types";
import { FatalErrors } from "../../types/LifeCycle";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import { AppRuntimes } from "../../types/AppRuntimes";

//==============================================================================
class CompleteReturnLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }

  satisfiesPreconditions() {
    if (!super.satisfiesPreconditions()) return false
    if (isEmpty(this.customer.itemsMarkedForCompletion)) return false

    return true
  }
}
//==============================================================================

//==============================================================================
const CompleteReturn = ({page}: PageProps) => {
  const dispatch = useDispatch();

  const { customer, app } = useSelector(store => store);
  const { returns,
          itemsMarkedForCompletion,
          query,
          newReturnEmail,
          returnType } = customer;
  const { runtime, token, platform } = app;

  const lifecycle = new CompleteReturnLifecycle(page, dispatch, app, customer);

  //----------------------------------------------------------------------------
  // HOOKS

  // on launch, check preconditions, if all's OK complete the return
  // to get our data ready for preview
  useEffect(() => {
    if (lifecycle.satisfiesPreconditions()) {
      completeReturn()
    }
    else {
      lifecycle.advanceToFatalError(FatalErrors.unknown)
    }
  },[]);
  //----------------------------------------------------------------------------

  // pull the preview object from the server based on the current returns
  // since the preview object is used only by this page, we are not
  // storing it in the redux store
  const completeReturn = async () => {
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));

      let newReturns = cloneDeep(returns);

      // because an express return already has instance IDs defined,
      // this array will be string[] for express and Instance[] for walkup
      if (itemsMarkedForCompletion.every(item => typeof item === "object")) {
        newReturns = {};
        newReturns.returning = itemsMarkedForCompletion;
          newReturns.customerIdentity = {
            email: newReturnEmail,
            order: query,
            identity: query,
            isGift: returnType === ReturnTypes.giftReturn
          }
      // express return
      } else {
        // XXX: we hope to soon update this so we can pass simply the list of
        //      IDs into this endpoint.  for now, we will do some mangling
        if (returns) {
          const newReturning = returns.returning.filter(returning => {
            if (returning.id) {
              return itemsMarkedForCompletion.includes(returning.id);
            }
            return false;
          });
          newReturns.returning = newReturning;
        }
      }


      const response = await axios.post("/receive",
        newReturns,
      );

      // XXX - axios is giving inconsistent results in regards
      // to when this endpoint resolves or rejects using the interceptor
      // so this should cover both cases
      if (response.status === 401) {
        dispatch(handleReturnistaExpiredToken());
        return;
      }

      // save return ID from recently completed return
      // in case the customer wants an email sent to
      // an alternate address
      // if we made it this far, we're done with this step
      if (returnType === ReturnTypes.giftReturn) {
        dispatch(setNewReturnID(response.data.id, response.data?.customerIdentity?.email));
      } else {
        // look for a customer email within the purchases
        const customerEmail = getCustomerEmailFromReturn(response.data);
        dispatch(setNewReturnID(response.data.id, customerEmail));
      }

      if (runtime === AppRuntimes.returnista) {
        const claims = getReturnistaJWTClaims(token);
        // if allowKeepInStore is true - reverse logistics is disabled
        // if false - reverse logistics is enabled
        const desiredConfirmationPage =
          claims?.returnsApp?.allowKeepInStore    ? "confirmationWithoutReverseLogistics"
          : platform                              ? "thankTheCustomer"
          : "confirmation";
        logger.Info(`Routing returnista user to desired confirmation page: ${desiredConfirmationPage}`)
        dispatch(goToPage(desiredConfirmationPage))
      } else {
        lifecycle.advance();
      }
    }
    catch (error) {
      // XXX we're using the backend timeout for this endpoint
      // to ensure that /receive has enough time for bigger returns

      // XXX - in case the interceptor doesn't fire, this
      // should ensure that the user is sent back to log in
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && runtime === AppRuntimes.returnista) {
          dispatch(handleReturnistaExpiredToken());
          return;
        } else if (error.request && !error.response) {
          dispatch(onFatalError(FatalErrors.connection))
        } else {
          handleAxiosError(error, dispatch)
        }
      } else {
        dispatch(onFatalError(FatalErrors.unknown));
      }
    }
    finally {
      dispatch(clearLoadingIndicator());
    }
  }

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <$CompleteReturn>
      {/* this page has no UI, it should automatically advance */ }
    </$CompleteReturn>
  )
  //----------------------------------------------------------------------------
}
//==============================================================================

export default CompleteReturn;
