import styled from "styled-components";
import { modalZIndex } from "../utility";

// language=SCSS prefix=*{ suffix=}
export const $MainContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: auto;
`;

export const $Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  width: 100%;
  background-color: var(--color-header);
  @media (max-width: 758px) {
    .hide-on-small-browser-width {
      display: none;
    }
    z-index: ${modalZIndex};
  }

  @media (min-width: 759px) {
    .hide-on-large-browser-width {
      display: none;
    }
  }

  .android & {
    padding: 16px 0;
  }
`;

export const $HeaderLeft = styled.div`
  display: flex;
  padding-left: 24px;
  flex: 1;
  svg {
    path {
      fill: #000000
    }
  }
`;

export const $HeaderLeftText = styled.div`
  cursor: pointer;
  .android & {
    @media (max-width: 640px) {
      display: none;
    }
  }
`;

// add a padding to the right, because text normally appears to the
// right of this element. use padding, not margin, so that both the
// element and its space are clickable
export const $HeaderLeftArrow = styled.div`
  margin-top: 2px;
  padding-right: 12px;
  cursor: pointer;
`;

export const $AdminModeBadge = styled.div`
  position: absolute;
  left: 24px;
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
  background-color: #FEE8E3;
  font-weight: bold;
  font-size: 11px;
  color: rgb(193, 13, 23);
  text-align: center;
  vertical-align: middle;
`;

export const $HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &, img {
    height: 40px;
    width: 200px;
  }
  img {
    object-fit: contain;
  }
  svg {
    height: 40px;
  }
`;

export const $HeaderTitle = styled.div`
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  .android & {
    font-weight: 400;
    font-size: 16px;
  }
`

// this empty component is here to help the flexbox center the logo
// while left-aligning the left link
export const $HeaderRight = styled.div`
  display: flex;
  padding-right: 24px;
  flex: 1;
`;

export const $HeaderRightArrow = styled.div`
  margin-top: 2px;
  padding-left: 12px;
  cursor: pointer;
  svg {
    transform: rotate(180deg);
  }
`
export const $HeaderRightText = styled.div`
  margin-left: auto;
  cursor: pointer;
`
