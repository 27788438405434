import React from "react";

import { $IconWrapper } from "./styles";
import { iconsType, ReturnistaFeedbackIconProps } from "./types";
import { SVG } from "../svg";

const icons: iconsType = {
  success: {
    name: "check",
    fill: "#004E22",
  },
  error: {
    name: "returnistaInfo",
    fill: "#9E0008",
  },
  warning: {
    name: "returnistaWarning",
    fill: "#433B03",
  },
  info: {
    name: "returnistaInfo",
    fill: "#2E6797",
  },
};

const ReturnistaFeedbackIcon = ({ status }: ReturnistaFeedbackIconProps) => {
  return (
    <$IconWrapper status={status}>
      <SVG name={icons[status].name} fill={icons[status].fill} />
    </$IconWrapper>
  );
};

export default ReturnistaFeedbackIcon;
