import { ReturningType } from "../redux/returnistaReducers/returnObject";
import ReturnistaAPIManager from "./ReturnistaAPIManager";

// A singleton intended to handle loading images for the Returnista app.
class ReturnistaImageManager {
  memo: Map<string, string>;
  apiManager: typeof ReturnistaAPIManager;

  constructor(apiManager: typeof ReturnistaAPIManager) {
    this.memo = new Map();
    this.apiManager = apiManager;
  }

  loadImages = (returnItems: Array<ReturningType>, retailerId: string, confirmationCode: string): Promise<Array<string>> => {
    const realImageList = returnItems.map((returnItem) => {
      return returnItem?.purchase?.images?.[0] ?? returnItem?.purchase?.thumbnail ?? "";
    });

    const realImageLoadList = realImageList.map((imgUrl) => {
      return this.loadImage(imgUrl);
    });

    const colorsToExclude = returnItems
      .map((returnItem) => {
        let color: string | null = null;
        (returnItem?.purchase?.display ?? []).forEach((displayElement) => {
          if ((displayElement?.label ?? "").toLowerCase() === "color") color = displayElement?.value;
        });
        return color;
      })
      .filter((color) => color != null)
      .join(",");

    return new Promise(async (resolve) => {
      try {
        const realImages = await Promise.all(realImageLoadList);

        // If there are null results then we don't want to populate fake images
        if (realImages.some((result) => result === null)) return Promise.resolve([]);

        // Try to get fake images; this is not a blocking feature, however, and it's perfectly fine if
        // this API does not return any images to use or even experienes an error
        const fakeImages = await this.apiManager.getFakeImages(retailerId, colorsToExclude, confirmationCode);

        // Filter out any fake images that are already in the real image list
        const fakeImageFilterList = fakeImages.filter((fakeImage) => {
          return !realImageList.includes(fakeImage);
        });

        const fakeImageLoadList = fakeImageFilterList.map((image) => {
          return this.loadImage(image);
        });

        // Load all fake images
        const fakeImagesResults = await Promise.all(fakeImageLoadList);

        // Filter out null results
        // @ts-ignore - this will return a non-null list of strings
        const filteredResults: Array<string> = fakeImagesResults.filter((result) => result != null);
        // Add all results to the memo
        filteredResults.forEach((result) => {
          if (result != null) this.memo.set(result, result);
        });
        // Return the filtered results
        resolve(filteredResults);
      } catch (error) {
        resolve([]);
      }
    });
  };

  // Load an image from the given URL.
  loadImage = (imageUrl: string): Promise<string | null> => {
    // If the image is already loaded, return it.
    const memoized = this.memo.get(imageUrl);
    if (memoized != null) return Promise.resolve(memoized);

    // Otherwise, load the image and memoize it.
    return this.apiManager
      .getProxyImage(imageUrl)
      .then((base64string) => {
        this.memo.set(imageUrl, base64string);
        return base64string;
      })
      .catch(() => {
        return Promise.reject(null);
      });
  };

  // Get an image from the memoized cache.
  getImage = (imageUrl: string): string => {
    const memoized = this.memo.get(imageUrl);
    if (memoized != null) return memoized;
    return imageUrl;
  };
}

export default new ReturnistaImageManager(ReturnistaAPIManager);
