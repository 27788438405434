import axios from "axios";
import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import $Email from "./styles";
import { ErrorAlertMessage, SuccessMessage } from "../AlertMessage";
import PrimaryButton from "../Button/PrimaryButton";
import TextInput from "../TextInput";
import { DataCyStrings } from "../../types/DataCyStrings";
import { isValidEmail, handleEnterKeyPressOnClickHandlers } from "../../utility";
import useSelector from "../../utility/useTypedSelector";

export const EMAIL_TO_BE_RESENT_PROMPT = "Does the customer need the email to be resent? ";
export const ENTER_EMAIL = "Enter Email";
export const HIDE_EMAIL = "Hide Email Form";

const ConfirmationEmail = ({ returnID }) => {
  const [email, setEmail] = useState("");
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const canSubmit = isValidEmail(email);
  const toggleEmailForm = () => {
    setShowEmailForm(!showEmailForm);
    setError("");
    setEmail("");
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.post("/email/confirmation", {
        returnID,
        email,
      });
      setSuccess(`A receipt has been sent to ${email}.`);
    } catch (e) {
      console.error(e);
      setError("Invalid email. Please try again.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setError("");
    setSuccess("");
  }, [email]);

  return (
    <$Email>
      <div className="caption">
        {EMAIL_TO_BE_RESENT_PROMPT}
        <div
          className="toggle-email"
          {...handleEnterKeyPressOnClickHandlers(toggleEmailForm)}
          data-cy={DataCyStrings.toggleEmail}
        >
          {showEmailForm ? HIDE_EMAIL : ENTER_EMAIL}
        </div>
      </div>
      {showEmailForm && (
        <div className="input-container">
          <TextInput
            label="Email Address"
            value={email}
            onChange={setEmail}
            dataCyString={DataCyStrings.emailAddressInput}
          />
          <PrimaryButton onButtonClick={onSubmit} disabled={!canSubmit} dataCyString={DataCyStrings.sendEmailButton}>
            <span className="email-submit-button-child">{isLoading ? <CircularProgress size={24} /> : <>Send</>}</span>
          </PrimaryButton>
        </div>
      )}
      {error && <ErrorAlertMessage message={error} />}
      {success && <SuccessMessage message={success} />}
    </$Email>
  );
};

export default ConfirmationEmail;
