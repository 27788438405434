import styled from "styled-components";

export const $ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  margin-top: 24px;

  .header {
    margin-top: 12px;
    font-size: 24px;
  }

  .message {
    margin-top: 12px;
    font-size: 16px;
    color: var(--medium-emphasis-black);
    text-align: center;
  }

  .button {
    margin-top: 12px;
    max-width: 300px;
  }
`;
