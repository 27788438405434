import styled from "styled-components";

const $Step = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  .prompt-title {
    display: flex;
    border-bottom: 
  }
`;

const $StepTextContainer = styled.div`
  .prompt2 {
    border-top: 1px solid var(--border-grey);
    margin-top: 16px;
    padding-top: 16px;
  }
  .prompt-2-text {
    margin-top: 0;
  }
  .use-coupon-banner {
    background: #FFDE0080;
    border-radius: 8px;
    padding: 10px;
    font-size: 13px;
  }
  .caption {
    font-size: 13px;
  }
  .input-container {
    margin-top: 8px;
    button {
      position: static;
      width: 100%;
      margin-top: 8px;
    }
  }
`;

const $Prompt = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 800;
  span: {
    text-decoration: underline;
    color: black;
    cursor: pointer;
  }
`;

const $Detail = styled.div`
  font-size: 13px;
  line-height: 14.04px;
  color: #454545;
  font-weight: 400;
  margin-top: 8px;
`;

export {
  $Step,
  $StepTextContainer,
  $Prompt,
  $Detail,
}