
import React from 'react';

/** check in a circle */
const Success = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#15332D"/>
      <path d="M5.71011 7.32668C5.32125 6.9345 4.68809 6.9318 4.29591 7.32066C3.90373 7.70952 3.90103 8.34268 4.28989 8.73486L5.71011 7.32668ZM6.95254 10L6.24243 10.7041C6.63084 11.0958 7.2631 11.099 7.65547 10.7113L6.95254 10ZM11.7029 6.71126C12.0957 6.32305 12.0995 5.6899 11.7113 5.29708C11.3231 4.90425 10.6899 4.90052 10.2971 5.28874L11.7029 6.71126ZM4.28989 8.73486L6.24243 10.7041L7.66265 9.29591L5.71011 7.32668L4.28989 8.73486ZM7.65547 10.7113L11.7029 6.71126L10.2971 5.28874L6.24962 9.28874L7.65547 10.7113Z" fill="#15332D"/>
    </svg>
  )
}

export default Success;


