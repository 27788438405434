

import React from "react";

const Arrow = (props) => (
  <>
    <svg {...props} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.960999 0.933779C1.48313 0.416627 2.3244 0.416627 2.84653 0.933779L10.5654 8.57903C11.3555 9.3616 11.3555 10.6384 10.5654 11.421L2.84653 19.0662C2.3244 19.5834 1.48313 19.5834 0.960999 19.0662C0.431749 18.542 0.431749 17.6867 0.960999 17.1625L8.19247 10L0.960999 2.83746C0.431748 2.31325 0.431749 1.45798 0.960999 0.933779Z" fill="#737373"/>
    </svg>
  </>
);

export default Arrow;