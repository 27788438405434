import React from "react";

type IconProps = {
  fill?: string;
};

const Check = ({ fill }: IconProps) => (
  <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10055_104779)">
        <mask id="mask0_10055_104779" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_10055_104779)">
          <path
            d="M12.7335 23.4332C12.5557 23.4332 12.389 23.4054 12.2335 23.3499C12.0779 23.2943 11.9335 23.1999 11.8001 23.0665L6.06679 17.3332C5.82234 17.0888 5.70568 16.7721 5.71679 16.3832C5.7279 15.9943 5.85568 15.6776 6.10012 15.4332C6.34457 15.1888 6.65568 15.0665 7.03346 15.0665C7.41123 15.0665 7.72234 15.1888 7.96679 15.4332L12.7335 20.1999L24.0335 8.89987C24.2779 8.65543 24.5946 8.5332 24.9835 8.5332C25.3723 8.5332 25.689 8.65543 25.9335 8.89987C26.1779 9.14431 26.3001 9.46098 26.3001 9.84987C26.3001 10.2388 26.1779 10.5554 25.9335 10.7999L13.6668 23.0665C13.5335 23.1999 13.389 23.2943 13.2335 23.3499C13.0779 23.4054 12.9112 23.4332 12.7335 23.4332Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10055_104779">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default Check;
