import React from 'react';
import { DataCyStrings } from '../../types/DataCyStrings';
import { SVG, SVGName } from "../svg"; 

import {
  $Step,
  $StepTextContainer,
  $Prompt,
  $Detail,
} from './styles';

interface StepProps {
  svgName: SVGName,
  prompt: any,
  prompt2?: any,
  detail?: string
}

function StepMobile({svgName, prompt, prompt2, detail}:StepProps) {
  return (
    <$Step>
      <div className="prompt-title">
        <SVG name={svgName}/>
        <$Prompt data-cy={DataCyStrings.stepPrompt}>{prompt}</$Prompt>
      </div>
      <$StepTextContainer data-cy={DataCyStrings.stepInstructions}>
        {prompt2 ? <div className="prompt2">{prompt2}</div> : null}
        <$Detail>{detail}</$Detail>
      </$StepTextContainer>
    </$Step>
  );
}

export default StepMobile;