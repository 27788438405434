import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { $LoginBackground, $LoginContainer, $LoginLogo } from "./styles";
import { getReturnistaJWTClaims, isReturnLoop, mapURLQueryStringToObject } from "../../utility";
import { ReturnistaURLQueries } from "../../types/JWTClaims";
import { SVG } from "../../components/svg";
import TextInput from "../../components/TextInput";
import ContactInfo from "../../components/ContactInfo";
import { CurrentPageAction, LoadingAction, MultiStoreAction, UserTokenAction } from "../../redux/enums";
import getTranslator from "../../utility/getTranslator";
import ReturnistaTimer from "../../utility/ReturnistaTimer";
import { DataCyStrings } from "../../types/DataCyStrings";
import { store } from "../../redux/returnista-store";
import ReturnistaAnalytics, { ReturnistaAnalyticsErrors } from "../../utility/ReturnistaAnalytics";
import logger from "../../utility/logger/logger";
import { updateLocalBundle } from "../../utility/ReturnistaBundle";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ga from "../../utility/GAEmitter";
import { LoginActions } from "../../types/ReturnistaGA";

const useTranslation = getTranslator("LogIn");

type LoginState = {
  username: string;
  password: string;
  loginError: null | string;
};

export type UseSSOResponse = {
  ssoEnabled: boolean;
  ssoRedirectURL: string;
};

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentPage } = store.getState();
  const [state, setState] = useState<LoginState>({
    username: "",
    password: "",
    loginError: ReturnistaTimer.experiencedTimeout()
      ? "Your session has ended. Please log in again to continue."
      : null,
  });

  const [ssoRedirectLink, setSSORedirectLink] = useState<string>("");
  const [fatalLoginError, setFatalLoginError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const urlQueries = mapURLQueryStringToObject() as ReturnistaURLQueries;
  const { locationID, versionID, androidVersionID } = urlQueries;
  const displayVersion = versionID ?? androidVersionID ?? "";

  if (versionID != null) {
    ReturnistaAnalytics.setAppVersion("ios", versionID);
  }

  if (androidVersionID != null) {
    ReturnistaAnalytics.setAppVersion("android", androidVersionID);
  }

  useEffect(() => {
    ReturnistaTimer.clear();

    //reload the app if the local javascript bundle is not updated
    updateLocalBundle(locationID);
  }, []);

  useEffect(() => {
    dispatch({ type: LoadingAction.Set });
    const { token, samlError, locationID } = urlQueries;
    if (token) {
      captureAuth(token);
      return;
    }
    if (samlError) {
      setFatalLoginError(true);
      setState((prevState) => ({
        ...prevState,
        loginError: "Unable to sign in with SSO. Please check SAML configurations and try again.",
      }));
      return;
    }
    if (locationID) {
      getUseSSO();
    } else {
      dispatch({ type: LoadingAction.Unset });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const setErrorForEmptyLocationID = () =>
      setState((prevState) => ({
        ...prevState,
        loginError: "Location ID is required to log in. Contact Happy Returns support if you’re not sure what to do.",
      }));

    if (locationID == null || locationID === "") {
      setErrorForEmptyLocationID();
    }
  }, [locationID]);

  async function handleSignIn(e) {
    e?.preventDefault();
    dispatch({ type: LoadingAction.Set });
    const { locationID } = urlQueries;
    const req: Record<string, any> = {
      login: state.username,
      password: state.password,
      locationID,
    };

    try {
      const { data: token } = await axios.post("/login", req);

      // We have successfully logged in, dispatch the relevant actions
      // to the redux store
      dispatch({ type: UserTokenAction.Set, token: token });
      dispatch({ type: LoadingAction.Unset });
      dispatch({ type: CurrentPageAction.Next });

      // Initialize the ReturnistaTimer
      ReturnistaTimer.init(token).then(() => {
        if (isReturnLoop(currentPage)) {
          ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedTimeout);
        }
        dispatch({ type: MultiStoreAction.AppLogout });
      });

      const { returnsApp } = getReturnistaJWTClaims(token);

      ga.setDimensions({
        user_properties: {
          location_id: returnsApp?.locationID,
          location_owner: returnsApp?.locationOwner,
        },
      });
      ga.pageEvent({ category: currentPage, action: LoginActions.LoginSuccessful });
      // Add an entry to the history state list; this is what we will
      // leverage to capture browser back events
      history.pushState(null, "");
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        setState((prevState) => ({
          ...prevState,
          password: "",
          loginError: t("weDontHaveARecord"),
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          password: "",
          loginError: t("anErrorOccurredWhile"),
        }));
      }
      dispatch({ type: LoadingAction.Unset });
      ga.pageEvent({ category: currentPage, action: LoginActions.LoginFailed });
    }
  }

  const getUseSSO = async () => {
    const { locationID } = urlQueries;
    try {
      const { ssoEnabled, ssoRedirectURL }: UseSSOResponse = (await axios.get("/use-sso", { params: { locationID } }))
        .data;
      // if sso is enabled and we have a url value, set the redirect url
      if (ssoEnabled && ssoRedirectURL) {
        setSSORedirectLink(ssoRedirectURL);
      }
    } catch (e) {
      if (axios.isAxiosError(e) && (e.response?.status == 400 || e.response?.status == 404)) {
        logger.Warning(`unable to get sso values: ${e.response.data}`);
      } else {
        logger.Error(`error getting sso values: ${e}`);
      }
    } finally {
      dispatch({ type: LoadingAction.Unset });
      setIsLoading(false);
    }
  };

  const captureAuth = async (token: string) => {
    const { returnsApp } = getReturnistaJWTClaims(token);

    ga.setDimensions({
      user_properties: {
        location_id: returnsApp?.locationID,
        location_owner: returnsApp?.locationOwner,
      },
    });

    try {
      await axios.get("/login/saml/capture-auth", { headers: { Authorization: `Bearer ${token}` } });
      ga.pageEvent({ category: currentPage, action: LoginActions.LoginSuccessful });
      dispatch({ type: UserTokenAction.Set, token: token });
      dispatch({ type: LoadingAction.Unset });
      dispatch({ type: CurrentPageAction.Next });
    } catch (e) {
      dispatch({ type: LoadingAction.Unset });
      setIsLoading(false);
      if (axios.isAxiosError(e) && e.response?.data.includes("expired")) {
        logger.Warning("sso login - expired happy returns auth token");
        setState((prevState) => ({
          ...prevState,
          loginError: "Your session has expired. Please sign in to continue.",
        }));
      } else {
        logger.Error("sso login - unable to capture auth: ", e.response?.data);
        setState((prevState) => ({
          ...prevState,
          loginError: "An error occurred while attempting to sign you in. Please try again.",
        }));
      }
      ga.pageEvent({ category: currentPage, action: LoginActions.LoginFailed });
      // give the ability to retry SSO
      getUseSSO();
    } finally {
      // we want to strip the token from the url so the token string doesn't linger and cause
      // unwanted side effects later on in the user flow
      stripTokenFromURL();
    }
  };

  const stripTokenFromURL = () => {
    // regexp that matches the token query param and the string that follows
    const tokenRegex = new RegExp(/&token=.+/);
    const newURL = window.location.href.replace(tokenRegex, "");
    // update the url, clearing the token string
    history.replaceState(null, "", newURL);
  };

  return (
    <$LoginBackground>
      {!isLoading && (
        <$LoginContainer>
          <$LoginLogo>
            <SVG name="hr-logo-paypal" alt="Happy Returns Logo" />
          </$LoginLogo>
          {state.loginError ? (
            <div className="error-banner">
              <div className="icon">
                <SVG name="exclamation" />
              </div>
              <div className="error-text">{state.loginError}</div>
            </div>
          ) : null}
          {!fatalLoginError && (
            <>
              {ssoRedirectLink ? (
                <a href={ssoRedirectLink} className="sso-link" data-cy={DataCyStrings.loginPageSSOLink}>
                  <PrimaryButton
                    width="100%"
                    label="Sign In With SSO"
                    bgColor="black"
                    dataCyString={DataCyStrings.loginPageSingleSignInButton}
                  />
                </a>
              ) : (
                <form className="login-form" onSubmit={handleSignIn}>
                  {locationID && (
                    <>
                      <TextInput
                        dataCyString={DataCyStrings.redesignUsernameInput}
                        label="Username"
                        value={state.username}
                        autocomplete="off"
                        onChange={(val) => {
                          setState((prevState) => ({
                            ...prevState,
                            loginError: null,
                            username: val,
                          }));
                        }}
                      />
                      <TextInput
                        dataCyString={DataCyStrings.redesignPasswordInput}
                        label="Password"
                        value={state.password}
                        onChange={(val) => {
                          setState((prevState) => ({
                            ...prevState,
                            loginError: null,
                            password: val,
                          }));
                        }}
                        type="password"
                      />
                    </>
                  )}
                  <input type="submit" value="Sign In" disabled={state.username === "" || state.password === ""} />
                </form>
              )}
            </>
          )}
        </$LoginContainer>
      )}
      <ContactInfo email={"returnbar@happyreturns.com"} phoneNumber={"(877) 750-4888"} versionID={displayVersion} />
    </$LoginBackground>
  );
};

export default Login;
