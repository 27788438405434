// third-party imports
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// repo imports
import useSelector from "../../utility/useTypedSelector";
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import { showLoadingIndicator, clearLoadingIndicator } from "../../redux/app/actions";
import { setSelectedInstanceForCompletion, setSelectedInstanceExchangeProperties, setRefundOptions } from "../../redux/customer/actions";
import { Refund, ExchangeProperties } from "../../types/Instance";
import NavigationCard from "../../components/NavigationCard";
import { ErrorAlertMessage } from "../../components/AlertMessage";

// local imports
import $ReturnOptions, { $ReturnOptionsModal } from  "./styles";
import { ReturnTypes } from "../../redux/customer/types";
import { SVGName } from "../../components/svg";
import PageModal from "../../components/Modal/PageModal";
import { getAdminMode, handleEnterKeyPressOnClickHandlers } from "../../utility";
import { AnalyticCategories, AnalyticsPageRoutes, ReturnOptionsModalActions } from "../../types/Analytics";
import getTranslator from "../../utility/getTranslator";

const useTranslator = getTranslator("ReturnOptions");

import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";
import { AppRuntimes } from "../../types/AppRuntimes";

const StoreCreditString = 'store-credit'
const RefundString = 'refund'
const ReturningString = 'returning'

class ReturnOptionsLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

interface ReturnOptionsState extends ExchangeProperties {
  refundOptions: Refund[]
  suggestion?:{
    action: string,
    refund: Refund
  }
}


/**
 * Allows user to choose how an item will be refunded
 */
const ReturnOptions = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const {t} = useTranslator();
  const { customer, app } = useSelector((store: RootReducer) => store);
  const { locale, copies } = useSelector((store) => store.app);
  const [isAdminMode, setIsAdminMode] = useState<boolean>(getAdminMode());
  const retailerName = copies?.retailerName
  // go back to return reason notes, if they are enabled
  if (app.enableCustomerReturnNotes && page.leftHeaderLink) {
    page = {...page, leftHeaderLink: {
      ...page.leftHeaderLink,
      destinationPage: "reasonNotes"
    }}
  } else {
    // go back to child return reasons, if the selected reason has child reasons
    const childReason = customer.selectedInstance?.childReason
    if (page.leftHeaderLink && childReason && childReason != '') {
      page = {...page, leftHeaderLink: {
        ...page.leftHeaderLink,
        destinationPage: "returnReasonsChildren"
      }}
    }
  }

  const lifecycle = new ReturnOptionsLifecycle(page, dispatch, app);
  const [returnOptions, setReturnOptions] = useState<ReturnOptionsState>({
    refundOptions: [],
    exchangeOptions: [],
    exchangeAttributes: [],
    suggestion: undefined
  });
  // show all options if the runtime is not return portal
  const [showAllOptions, setShowAllOptions] = useState(app.runtime != AppRuntimes.returnPortal);
  const [error, setError] = useState("");
  const suggestedOptionFound = returnOptions?.suggestion?.action && returnOptions?.suggestion?.refund;
  const optionIconMap = {
    "store-credit": "gift-card" as SVGName
  }
  // temp "refund" state which will be used when deciding on refund option later in the return shopping flow
  const returningRefund: Refund = {
    id: "returning",
    optionID: "returning"
  }
  const returnOptionsErrorCopies = {
    noOptions: t('noOptions')
  }
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    if (customer.selectedInstance != undefined) {
      getReturnOptions();
    }
  }, [customer.selectedInstance]);

  useEffect(() => {
    if (returnOptions.refundOptions?.length != 0 && !customer.refundOptions?.length) {
      // save refund options to redux store to be used later
      dispatch(setRefundOptions(returnOptions.refundOptions));
    }
  }, [returnOptions.refundOptions])

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    });
    ga.sendPageDetails(AnalyticsPageRoutes.ReturnOptions, AnalyticCategories.ReturnOptionsModal);
  }, []);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS

  const instanceHasReturnOptions = (resp) => {
    const hasExchangeOptions = Array.isArray(resp?.data?.exchangeOptions) && resp?.data?.exchangeOptions.length > 0;
    const hasRefundOptions = Array.isArray(resp?.data?.refundOptions) && resp?.data?.refundOptions.length > 0;
    const hasExchangeAttributes = resp.data.exchangeAttributes && Object.keys(resp.data.exchangeAttributes).length > 0;

    return (
      hasExchangeOptions ||
      hasExchangeAttributes ||
      resp.data.suggestion ||
      hasRefundOptions
    )
  }

  const getOptionIconName = (optionId:string):SVGName => {
    if (optionId in optionIconMap) {
      return optionIconMap[optionId]
    }
    return optionId as SVGName
  }

  const getReturnOptions = async () => {
    try{
      dispatch(showLoadingIndicator(defaultLoadingSymbol));

      const endpoint = `/returnoptions${customer.returnType === ReturnTypes.giftReturn ? "?gift=true" : ""}`;
      const resp = await axios.post<ReturnOptionsState>(endpoint, customer.selectedInstance);
      const returnOpts: ReturnOptionsState = resp.data

      // show all options if there is no suggestion OR if the runtime
      // is not return portal
      if (returnOpts.suggestion && app.runtime === AppRuntimes.returnPortal) {
        setShowAllOptions(false);
      } else {
        setShowAllOptions(true);
      }

      setReturnOptions(returnOpts);

      // send GA event if there are no exchange options and return shopping is enabled
      if (!returnOpts.exchangeAttributes?.length && !returnOpts.exchangeOptions?.length && app.returnShoppingEnabled) {
        ga.event({
          category: AnalyticCategories.ReturnOptionsModal,
          action: ReturnOptionsModalActions.ReturnOnlyOption,
        });
      }

      // if there are no return options, send the user to the NoReturnOptions page
      if (!instanceHasReturnOptions(resp)) {
        switch (app.runtime) {
          case AppRuntimes.returnPortal:
            setError(returnOptionsErrorCopies.noOptions);
            break;
          default:
            lifecycle.advance("noReturnOptions");
            break;
        }
      }
    } catch(e) {
      // Handle errors making the request
      switch (app.runtime) {
        case AppRuntimes.returnPortal:
          setError(returnOptionsErrorCopies.noOptions);
          break;
        default:
          alert(t("anIssueHasOccurred"));
      }
      console.error(e);
    } finally {
      dispatch(clearLoadingIndicator());
    }
  }

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const onReturnOptionClicked = (refund) => {
    // if an exchange object is present, set the ID
    // to exchange in order to display the correct
    // copy in the order list page
    if (refund.exchange) {
      refund.id = "exchange";
    }
    dispatch(setSelectedInstanceForCompletion(refund));
    if (refund.id === StoreCreditString) {
      ga.event({
        category: AnalyticCategories.ReturnOptionsModal,
        action: ReturnOptionsModalActions.StoreCredit,
      })
    } else if (refund.id === RefundString) {
      ga.event({
        category: AnalyticCategories.ReturnOptionsModal,
        action: ReturnOptionsModalActions.Refund,
      })
    } else if (refund.id === ReturningString) {
      ga.event({
        category: AnalyticCategories.ReturnOptionsModal,
        action: ReturnOptionsModalActions.ReturnShoppingReturnThisItem
      })
    }
    lifecycle.advance();
  }

  // Will be completed alongside the exchange page
  const onExchangeClicked = () => {
    dispatch(setSelectedInstanceExchangeProperties({
      exchangeAttributes: returnOptions.exchangeAttributes,
      exchangeOptions: returnOptions.exchangeOptions
    }));
    ga.event({
      category: AnalyticCategories.ReturnOptionsModal,
      action: ReturnOptionsModalActions.Exchange,
    });
    lifecycle.advance("exchangeClicked");
  }

  const showAllOptionsHandlers = handleEnterKeyPressOnClickHandlers(() => {
    setShowAllOptions(true);
    ga.event({
      category: AnalyticCategories.ReturnOptionsModal,
      action: ReturnOptionsModalActions.ShowMoreOptions
    })
  });
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING

  const BaseComponent = page.type === "modal" ?
    ReturnOptionsModalWrapper :
    $ReturnOptions

  const renderReturnOptions = () => {
    if (app.returnShoppingEnabled && returnOptions?.refundOptions.length) return (
      <NavigationCard
        key="return"
        title={t("returnThisItem")}
        iconName="return"
        onClick={() => onReturnOptionClicked(returningRefund)}
        dataCyString={DataCyStrings.returnButton}
      />
    );
    return returnOptions?.refundOptions.map(option => {
      return (
        <NavigationCard
          key={option.id}
          title={option.label}
          subtext={option.sublabel}
          iconName={getOptionIconName(option.id)}
          onClick={() => onReturnOptionClicked(option)}
          dataCyString={`${option.id}-button` as DataCyStrings}
        />
      )
    })
  }

  const renderExchange = () => {
    const shouldRenderExchangeButton = (
      returnOptions?.exchangeAttributes?.length > 0 &&
      returnOptions?.exchangeOptions?.length > 0
    );

    if (shouldRenderExchangeButton) {
      return (
        <NavigationCard
          key="exchange"
          title={t('exchangeOptionTitle')}
          iconName="exchange"
          onClick={() => onExchangeClicked()}
          dataCyString={DataCyStrings.exchangeCard}
        />
      )
    }
  }

  const renderSuggestion = () => {
    useEffect(() => {
      if (suggestedOptionFound) {
        ga.event({
          category: AnalyticCategories.ReturnOptionsModal,
          action: ReturnOptionsModalActions.OneClickExchangeDisplayed
        })
      }
    }, []);
    const onSuggestionClick = () => {
      ga.event({
        category: AnalyticCategories.ReturnOptionsModal,
        action: ReturnOptionsModalActions.OneClickExchange
      });
      onReturnOptionClicked(returnOptions?.suggestion?.refund);
    }
    if(suggestedOptionFound) {
      return (
        <NavigationCard
          key="suggestion"
          title={returnOptions?.suggestion?.action}
          iconName="suggestion"
          dataCyString={DataCyStrings.suggestion}
          onClick={onSuggestionClick}
        />
      )
    }
    return null;
  }

  return (
    <BaseComponent page={page}>
      {error &&
        <ErrorAlertMessage message={error} />
      }
      {renderSuggestion()}
      {(!showAllOptions && suggestedOptionFound) &&
        <button className="show-all-options" data-cy={DataCyStrings.showMoreOptions} {...showAllOptionsHandlers}>
          {t('showMoreOptions')}
        </button>}
      {showAllOptions && renderExchange()}
      {showAllOptions && renderReturnOptions()}
    </BaseComponent>
  );
  //----------------------------------------------------------------------------
}

const ReturnOptionsModalWrapper = ({ page, children }) => {
  return (
    <PageModal page={page} dataCyString={DataCyStrings.returnOptionsModal}>
      <$ReturnOptionsModal>
        {children}
      </$ReturnOptionsModal>
    </PageModal>
  )
}

export default ReturnOptions;
