import styled from "styled-components";
import { tabletOrLargerDevices } from "../../../globalConstants";

interface ImgPositionProps {
  imgPosition?: "bottom" | "top";
}

interface PrimaryMessageProps extends ImgPositionProps {
  closeIcon?: boolean;
}

const $Modal = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;

  div.error-banner {
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background-color: var(--warning-soft);
    line-height: 20px;
    margin-bottom: 24px;
    .icon {
      margin-right: 16px;
      &, svg {
        width: 16px;
        height: 16px;
      }
      & > svg > path {
          fill: var(--warning-bold);
      }
    }
    div.error-text {
      font-weight: 500;
      font-size: 13px;
      color: var(--warning-bold);
      text-align: left;
    }
  }
  div.success-banner {
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius 12px;
    background-color: var(--success-soft);
    line-height:20px;
    margin-bottom: 24px;
    .icon {
      margin-right: 16px;
      &, svg {
        width: 16px;
        height: 16px;
      }
      & > svg > path {
          fill: var(--success-bold);
      }
    }
    div.success-text {
      font-weight: 500;
      font-size: 13px;
      color: var(--success-bold);
      text-align: left;
    }
  }
`;

const $PrimaryMessage = styled.div<PrimaryMessageProps>`
  color: #0b0b0b;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: ${({ closeIcon }) => !closeIcon && "24px"};
  ${({ imgPosition }) => imgPosition === "bottom" && "margin-top: -15px"};

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 18px;
    font-weight: 800;
  }
`;

const $TextInput = styled.div`
  margin-bottom: 12px;

  label {
    display: flex;
  }
`;

const $ButtonGroup = styled.div`
  bottom: 24px;
`;

const $ExitButton = styled.div`
  cursor: pointer;
`;

const $ExitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15.59px;
`;

// I hate the way I had to change the submessage text spacing IF
// there is an image, but there was not an easy way to do this without rewriting a lot of stuff.
const $SubMessages = styled.div<ImgPositionProps>`
  display: flex;
  flex-direction: column;
  color: #737373;
  font-size: 17px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 32px;
  line-height: 24px;
  ${({ imgPosition }) => imgPosition === "bottom" && "padding-left: 70px; padding-right: 70px;"};
  .android & {
    padding: 0px;
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 15px;
    font-weight: 500;
  }
`;

// height and width are hardcoded. If we add any other additional types of image modals this should be moved out or passs props
const $Image = styled.img<ImgPositionProps>`
  ${({ imgPosition }) => imgPosition === "bottom" && "margin-top: -10px"};
  ${({ imgPosition }) => imgPosition === "top" && "margin-top: -10px; margin-bottom: 10px"};
  width: 408px;
  height: 254px;
  &.android {
    height: unset;
    width: 100%;
  }
`;

export { $Modal, $PrimaryMessage, $TextInput, $ButtonGroup, $SubMessages, $ExitButton, $ExitButtonWrapper, $Image };
