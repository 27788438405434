// Largely static footer shown at the base of the Return Portal

import styled from "styled-components";

const MOBILE_BREAKPOINT = 758;
const SMALL_DESKTOP_BREAKPOINT = 1200;

export const $PoweredByHappyReturns = styled.div`
  margin-right: 32px;
  text-align: center;

  a {
    align-content: flex-start;
    color: #221f1f;
    display: flex;
    font-family: "walsheim-pro", "sofia-pro", sans-serif;
    line-height: 14.83px;
    text-decoration: none;
  }

  svg {
    margin-right: 8px;
  }
`;

export const $PrivacyPolicy = styled.div`
  color: #616161;
  line-height: 13.8px;
  margin-left: 16px;

  a {
    color: #616161;
  }
`;

export const $DefaultPrivacyFooter = styled.footer<{ marginTop: string }>`
  align-items: center;
  align-self: center;
  background-color: white;
  border-top: solid;
  border-top-color: #dbdbdb;
  border-top-width: 1px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-size: 12px;
  height: 34px;
  justify-content: space-between;
  white-space: pre-wrap;
  width: 100vw;
  z-index: 9999;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    justify-content: space-between;
    height: 44px;

    margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  }

  ${$PoweredByHappyReturns} {
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-left: auto;
      margin-right: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  ${$PrivacyPolicy} {
    left: 50%;
    position: relative;
    transform: translateX(-60%);
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 10px;
      left: revert;
      margin-left: 16px;
      margin-top: 15px;
      margin-bottom: 15px;
      position: revert;
      transform: revert;
    }
  }
`;

export const $GermanPrivacyFooter = styled.footer<{ marginTop: string }>`
  align-items: center;
  align-self: center;
  background-color: white;
  border-top: solid;
  border-top-color: #dbdbdb;
  border-top-width: 1px;
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  height: 34px;
  justify-content: space-between;
  white-space: pre-wrap;
  width: 100vw;
  z-index: 9999;

  @media (max-width: ${SMALL_DESKTOP_BREAKPOINT}px) {
    justify-content: space-between;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    height: 60px;

    margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  }

  ${$PoweredByHappyReturns} {
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      padding-top: 12px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  ${$PrivacyPolicy} {
    display: flex;
    left: 50%;
    position: relative;
    transform: translateX(-56%);
    @media (max-width: ${SMALL_DESKTOP_BREAKPOINT}px) {
      left: revert;
      margin-left: 32px;
      position: revert;
      transform: revert;
    }
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 10px;
      left: revert;
      padding-top: 6px;
      position: revert;
      margin-left: auto;
      padding-bottom: 8px;
      margin-right: auto;
      transform: revert;
    }
  }
`;

export const $Impressum = styled.div`
  a {
    color: #616161;
  }
`;
