import styled from 'styled-components';


const $LanguagePicker = styled.div`
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  > .splitter {
    margin: 0 8px;
    color: #737373;
  }
  > a {
    &:not(.active){
      cursor: pointer;
      text-decoration: underline;
    }
    &.active {
      font-weight: 800;
      text-decoration: none;
    }

    display: inline;
  }
  > .globe {
    vertical-align: middle;
  }
`

export {
  $LanguagePicker
}
