import styled from "styled-components";

const $ConfirmationWithoutLogistics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  .header {
    font-size: 24px;
    line-height: 28px;
    color: #0B0B0B;
    margin-top: 28px;
    margin-bottom: 12px;
  }
  .customer-email {
    font-size: 16px;
    line-height: 24px;
    color: #737373;
    margin-bottom: 40px;
  }
  .divider {
    margin-bottom: 40px;
    width: 32px;
    height: 1px;
    background-color: black;
    outline: none;
    border: none;
  }
  .new-email {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    color: var(--text-black);
  }
  .input-container {
    position: relative;
    width: 552px;
    input {
      height: 56px;
      -webkit-appearance: none;
    }
    button {
      position: absolute;
      width: 82px;
      height: 40px;
      right: 8px;
      top: 8px;
    }
  }
  .alert {
    margin-top: 8px;
    & > div {
      width: 556px;
    }
    margin-bottom: auto;
  }
  .footer {
    width: 100vw;
    button {
      height: 55px;
    }
  }
`;

export default $ConfirmationWithoutLogistics