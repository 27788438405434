import styled from "styled-components";
import { returnistaRedesignBoxShadow, tabletOrLargerDevices } from "../../globalConstants";

export const $Container = styled.div`
  margin: 0 auto;
  overflow-y: auto;
  padding: 88px 24px 100px 24px;
  width: 100%;

  @media (min-width: ${tabletOrLargerDevices}) {
    padding: 24px 24px 100px 24px;
    max-width: 720px;
  }
`;

export const $ReturnCounter = styled.div`
  align-items: center;
  background: var(--primary-white);
  box-shadow: ${returnistaRedesignBoxShadow};
  bottom: 0;
  left: 0;
  padding: 16px;
  position: absolute;
  right: 0;

  .counter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;

    @media (min-width: ${tabletOrLargerDevices}) {
      padding: 0px 24px 0px 24px;
      max-width: 720px;
    }
  }

  .text {
    align-items: center;
    display: flex;
    margin-left: 8px;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-left: 0;
    }

    p {
      margin: 0 0 0 16px;
      max-width: 136px;

      @media (min-width: ${tabletOrLargerDevices}) {
        max-width: none;
        margin: 0 0 0 19px;
      }
    }
  }
  button {
    width: 114px;
    margin-right: 8px;
    padding: 12px;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-right: 0;
      padding: 16px;
      width: 162px;
    }
  }
`;

export const $NoItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;

  br {
    display: none;
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    margin-top: 140px;

    br {
      display: initial;
    }
  }

  .title {
    font-size: 21px;
    margin: 0 0 16px;
    text-align: center;
    width: 100%;

    @media (min-width: ${tabletOrLargerDevices}) {
      font-size: 27px;
    }
  }

  .subTitle {
    color: var(--medium-emphasis-black);
    font-size: 16px;
    margin: 0 0 24px;
    text-align: center;
    width: 100%;

    @media (min-width: ${tabletOrLargerDevices}) {
      font-size: 21px;
    }
  }

  div {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      margin-right: 24px;
      width: 255px;

      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

export const $IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const $ModalButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 100%;

  @media (min-width: ${tabletOrLargerDevices}) {
    flex-direction: row;
  }

  button {
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 190px;
      margin-bottom: 0;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
