import React from "react";
import { $ItemDetails } from "./styles";
import getTranslator from "../../utility/getTranslator";
import { DataCyStrings } from "../../types/DataCyStrings";
import { formatCurrencyString } from "../../utility/currencyExtraction";
import styled, { css } from "styled-components";

const useTranslator = getTranslator("ItemDetails");

interface ItemDetailsProps {
  item: any,
  runtime?: string,
  // when defined, priceDisplay dictates whether the item's value
  // shows up inline with the rest of an item's info, or to the far right
  // of an item row
  priceDisplay?: "inline" | "right", // don't display price when undefined
  showThumbnail?: boolean,
  showSku?: boolean,
  showVendor?: boolean,
  dataCyString?: DataCyStrings,
  //Allows thumbnails to be clickable
  onClickForThumbnail?: () => void
  customStyles?: any,
  hidePrice?: boolean,
}

// An item is sent back from the backend in 2 different forms depending if it's the
// item being returned vs an item being received via an exchange.
// in order to handle this without having to rewrite the component, we check both
// possibilities (the lookup order has no significance and can be swapped per check)
const ItemDetails = ({item, runtime, priceDisplay, showThumbnail, showSku, showVendor, dataCyString, onClickForThumbnail, customStyles, hidePrice}: ItemDetailsProps) => {
  const itemPriceString = item?.purchase?.price || item?.price;
  const itemPrice = formatCurrencyString(itemPriceString);
  const { t } = useTranslator();

  return (
    <$ItemDetails customStyles={customStyles} className={runtime} data-cy={DataCyStrings.itemDetails}>
      {showThumbnail &&
      <div className="thumbnail">
        {/* When rendering an exchange item, the thumbnail is found at the top level (item.thumbnail)
            for regular return items, thumbnail is part of the purchase (item.purchase.thumbnail).

            This is because exchange items actually are a purchase
            while regular return items are an instance, for which purchase is a property.
         */}
        {!onClickForThumbnail &&
          <img src={item.thumbnail || item.purchase?.thumbnail} alt=""/>
        }
        {!!onClickForThumbnail &&
          <img src={item.thumbnail || item.purchase?.thumbnail} alt="" onClick={onClickForThumbnail}/>
        }

      </div>
      }
      <div className="item-info" data-cy={dataCyString}>
        <div className="item-name-price">
          <div className="item-name" data-cy={DataCyStrings.itemName}>
            {item.name || item.purchase?.name}
            {/* only show item price if the item isn't part of an exchange
            only items being returned have this refund object so we check that first
            when an item does have the object, we still want to ignore it if it's an exchange
            */}
          </div>
          {!hidePrice && priceDisplay === "right" && itemPrice &&
          <div className="item-price">{itemPrice}</div>
          }
        </div>
        {showVendor && item?.purchase?.vendor && <div className="item-vendor">{t('brand', {brand: item?.purchase?.vendor})}</div>}
        <ul className="item-details" data-cy={DataCyStrings.instanceItemDetails}>
          {(item?.purchase?.display || item?.display).map((displayItem) => {
            if (displayItem.label == "Vendor") {
              return null;
            }
            return <li key={displayItem.label + ': ' + displayItem.value} title={displayItem.label + ': ' + displayItem.value}>{`${displayItem.value}`}</li>
          })}
        </ul>
        {showSku &&
        <div data-cy={DataCyStrings.itemSKU} className="item-sku">
          {item?.purchase?.details?.sku && `SKU: ${item?.purchase?.details?.sku}`}
          {/* If no SKU is found, attempt to use UPC as a fallback */}
          {!item?.purchase?.details?.sku && item?.purchase?.details?.upc && `UPC: ${item?.purchase?.details?.upc}`}
        </div>
        }
        {/* Show price within item details in return portal runtime for exchange items */}
        {!hidePrice && priceDisplay === "inline" && itemPrice &&
        <div data-cy={DataCyStrings.itemPrice} key="inline-price" className="inline-price">
          {itemPrice}
        </div>
        }
      </div>
    </$ItemDetails>
  );
}

export default ItemDetails;
