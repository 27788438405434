import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";

import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import { CurrentPageAction, EventAction, ModalAction, NoMatchWarningAction } from "../../redux/enums";
import { RootReducer } from "../../redux/returnista-store";

import { $Container, $Title, $FollowUp, $BarcodeHint, $IconWrapper, $NoBarCodeButtons } from "./styles";
import { HintImage } from "./types";
import Content from "../../components/ReturnistaContent";
import { isUPS } from "../../utility";
import ga from "../../utility/GAEmitter";
import { NoBarcodeFoundModalActions, ScanItemPromptActions } from "../../types/ReturnistaGA";
import { setReturnistaEvent } from "../../utility/returnistaEvents";
import { eventLabels } from "../../redux/returnistaReducers/events";

const hintImages: Array<HintImage> = [
  {
    url: "/public/img/clearPolyBag.jpg",
    name: "Poly bag",
  },
  {
    url: "/public/img/hangTag.jpg",
    name: "Hang tag",
  },
  {
    url: "/public/img/shoesBox.jpg",
    name: "Shoes box",
  },
  {
    url: "/public/img/sewnInLabel.jpg",
    name: "Sewn-in label",
  },
];

const ReturnistaSuccessBagScan = () => {
  const dispatch = useDispatch();
  const { currentPage, returnObject } = useSelector<RootReducer, RootReducer>((store) => store);

  const goToNextStep = () => {
    dispatch({ type: CurrentPageAction.Next });
  };

  const handleScanItemClick = (): void => {
    ga.pageEvent({ category: currentPage, action: ScanItemPromptActions.ScanItemBarcodeClicked });
    goToNextStep();
  };

  const confirmNobarCode = (): void => {
    ga.pageEvent({ category: currentPage, action: ScanItemPromptActions.ContinueWithoutBarcodeClicked });

    const modalName = "no-barcode-found-modal";
    const handleScanBarcode = (): void => {
      ga.pageEvent({ category: modalName, action: ScanItemPromptActions.ScanItemBarcodeClicked });
      dispatch({ type: ModalAction.Unset });
      goToNextStep();
    };
    const goToSelectItem = (): void => {
      dispatch({
        type: EventAction.Add,
        event: setReturnistaEvent({
          returnID: returnObject.id,
          eventTypeLabel: eventLabels.ITEM_NO_BARCODE_FOUND,
        }),
      });
      ga.pageEvent({ category: modalName, action: NoBarcodeFoundModalActions.ScanBarcodeClicked });
      dispatch({ type: CurrentPageAction.SelectItem });
      dispatch({ type: ModalAction.Unset });
      dispatch({ type: NoMatchWarningAction.Unset });
    };
    const closeModal = () => {
      ga.pageEvent({ category: modalName, action: NoBarcodeFoundModalActions.ModalClosed });
      dispatch({ type: ModalAction.Unset });
    };

    dispatch({
      type: ModalAction.Set,
      modalProps: {
        primaryMessage: "Have you searched for the barcode?",
        onRequestClose: closeModal,
        iconElement: (
          <$IconWrapper data-cy="barcode-warning-icon-modal">
            <ReturnistaFeedbackIcon status="warning" />
          </$IconWrapper>
        ),
        button: (
          <$NoBarCodeButtons>
            <ReturnistaPrimaryButton variant="outlined" onClick={handleScanBarcode} dataCyString="close-modal">
              Scan barcode
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton onClick={goToSelectItem} dataCyString="no-barcode-found">
              No barcode found
            </ReturnistaPrimaryButton>
          </$NoBarCodeButtons>
        ),
      },
    });
  };

  return (
    <>
      <Header left={!isUPS() ? <HeaderBackButton /> : undefined} center="Scan barcode on the item" />
      <Content>
        <$Container>
          {!isUPS() && (
            <>
              <ReturnistaFeedbackIcon status="success" />
              <$Title>
                <p data-cy="bag-code-success-title">Bag code scanned.</p>
              </$Title>
            </>
          )}
          <$FollowUp data-cy="bag-code-follow-up-text">Scan the barcode on each individual item.</$FollowUp>
          <div className="actions">
            <ReturnistaPrimaryButton onClick={handleScanItemClick} dataCyString="scan-item-button">
              Scan item barcode
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton
              onClick={confirmNobarCode}
              variant="outlined"
              className="continue-without-barcode"
              dataCyString="continue-without-barcode-button"
            >
              Continue without barcode
            </ReturnistaPrimaryButton>
          </div>
        </$Container>
        <$BarcodeHint>
          <p className="title">Places the item barcode could be</p>
          <ul>
            {hintImages.map((i) => {
              return (
                <li key={i.name}>
                  <div className="image-wrapper">
                    <img src={i.url} alt={i.name} />
                  </div>
                  <p>{i.name}</p>
                </li>
              );
            })}
          </ul>
        </$BarcodeHint>
      </Content>
    </>
  );
};

export default ReturnistaSuccessBagScan;
