// third-party imports
import React from "react";
import { useDispatch } from "react-redux";

// repo imports
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";

// local imports
import $ThankTheCustomer from "./styles";
import ConfirmationEmail from "../../components/ConfirmationEmail";
import { getReturnistaJWTClaims } from "../../utility";
import StepMobile from "../../components/StepMobile";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { $ReturnCountFooter } from "../../components/ReturnCountFooter/styles";
import { DataCyStrings } from "../../types/DataCyStrings";
import logger from "../../utility/logger";

class ThankTheCustomerLifecycle extends PageLifecycle {
  constructor(page, app, dispatch) {
    super(page, app, dispatch)
  }
}

/**
 * Thank the Customer page - used as part of a more mobile friendly user experience.
 * This separates the steps of the normal confirmation flow to separate pages:
 * 1. Thank the customer
 * 2. Scan bags
 * 
 * After Thank the Customer, the Returnista user advances to a modified version of
 * the confirmation page, which gives instruction to scan bags and then displays all
 * scanned bags associated with the return.
 */
const ThankTheCustomer = ({ page }: PageProps) => {
  // STATE
  const dispatch = useDispatch();
  const { customer, app } = useSelector((store: RootReducer) => store);
  const lifecycle = new ThankTheCustomerLifecycle(page, dispatch, app);
  const { token } = app;
  const { newReturnID } = customer;
  const claims = getReturnistaJWTClaims(token);

  const handleContinueButtonClick = () => {
    logger.Info('User advancing to confirmation page')
    lifecycle.advance()
  }
  
  return (
    <$ThankTheCustomer>
      <div className="main-container">
        <div className="card">
          <StepMobile
            svgName="hr-logo"
            prompt="Thank the Customer"
            prompt2={
              <>
                <b>Tell the customer:</b>
                <p className="prompt-2-text">
                  "Your return is complete and you’ll receive an email receipt from Happy Returns.{" "}
                  {!claims?.returnsApp?.couponEnabled && "You’re free to go"}"
                </p>
                {!!claims?.returnsApp?.couponEnabled && (
                  <div className="use-coupon-banner">
                    Remind the customer their receipt contains a coupon for your store.
                  </div>
                )}
                <ConfirmationEmail returnID={newReturnID} />
              </>
            }
          />
        </div>
      </div>
      <div className="footer">
        <$ReturnCountFooter>
          <PrimaryButton
            label="Continue to Process Return"
            width="unset"
            onButtonClick={handleContinueButtonClick}
            dataCyString={DataCyStrings.continueButton}
          />
        </$ReturnCountFooter>
      </div>
    </$ThankTheCustomer>
  );
  //----------------------------------------------------------------------------

}

export default ThankTheCustomer;