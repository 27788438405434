// Datadog-based implementation of `logClient`

import { datadogLogs } from "@datadog/browser-logs";
import { LogClient, LogFunction } from "./logClient";

const local = "local";
//We need to do this so we can use the webpack define plugin
declare let APPVERSION: string;
const environment = window.appConfig ? window.appConfig.HR_ENVIRONMENT : local;

datadogLogs.init({
  clientToken: "pub42b876fb3430d796067ca4bd42f3cad2", // safe to hard-code
  env: environment,
  forwardConsoleLogs: environment != local ? "all" : [],
  forwardErrorsToLogs: environment != local,
  service: "returns-app",
  version: APPVERSION,
  sessionSampleRate: 100,
  site: "datadoghq.com",
  telemetrySampleRate: 0,
});

class DatadogLogger implements LogClient {
  // Bind to set values regardless of how these functions are used
  constructor() {
    this.Debug = this.Debug.bind(this);
    this.Error = this.Error.bind(this);
    this.Info = this.Info.bind(this);
    this.Warning = this.Warning.bind(this);
    this.log = this.log.bind(this);
    this.SetGlobalContextProperty = this.SetGlobalContextProperty.bind(this);
  }

  /**
   * NOTE: the following functions pass `(m, mc) => datadogLogs.logger.*(m, mc)` as the first parameter to #log
   * this is intentional because we want to retain the value of `this` from whatever context the function was
   * called from. If we instead just passed `datadogLogs.logger.*` directly it would result in an error.
   *
   * for more information: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/this#callbacks
   */

  Debug(message: string, messageContext?: object): void {
    this.log((message, messageContext) => datadogLogs.logger.debug(message, messageContext), message, messageContext);
  }

  Error(message: string, messageContext?: object): void {
    this.log((message, messageContext) => datadogLogs.logger.error(message, messageContext), message, messageContext);
  }

  Info(message: string, messageContext?: object): void {
    this.log((message, messageContext) => datadogLogs.logger.info(message, messageContext), message, messageContext);
  }

  SetGlobalContextProperty(key: string, value: any) {
    datadogLogs.setGlobalContextProperty(key, value);
  }

  Warning(message: string, messageContext?: object): void {
    this.log((message, messageContext) => datadogLogs.logger.warn(message, messageContext), message, messageContext);
  }

  log = (logFunc: LogFunction, message: string, messageContext?: object) => {
    if (environment != local) {
      logFunc(message, messageContext);
    }
  };
}

const createDatadogLogClient = (): LogClient => {
  return new DatadogLogger();
};

export const DatadogLogClient: LogClient = createDatadogLogClient();
