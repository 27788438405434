import styled from "styled-components";
import $PageContentBox from "../../components/PageContentBox/styles";
import { $BarcodeWidget } from "../../components/BarcodeWidget/styles";

const $Confirmation = styled($PageContentBox as any)`
  display: flex;
    &.ios {
      padding: 0 24px;
      & > .card {
        border: 1px solid var(--border-card);
        border-radius: 12px;
        width: 100%;
        margin: 24px 0;
        padding: 24px 24px 0 24px;

        &.with-button {
          height: 609px;
          .step-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 40px;
          }
          .barcode-list {
            padding-left: 55px;
            ${$BarcodeWidget} {
              width: 584px;
              height: 56px;
            }
          }
        }
      }
    }
  .divider {
    margin-top: auto;
  }
  .footer {
    width: calc(100% + 48px);
    margin: 0 -24px;
    @media screen and (max-width: 768px) {
      & > div.ios {
        height: 88px;
      }
      width: 100vw;
    }
  }
  .use-coupon-banner {
    margin: 24px 0;
    background: #FFDE00;
    border-radius: 8px;
    padding: 20px;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
  }
  .android & {
    a {
      text-decoration: underline;
      color: var(--link-blue);
    }
    .card {
      margin: 0;
    }
    .see-example {
      margin: 4px 0 16px;
      font-size: 14px;
    }
    .subtext {
      margin-top: 16px;
      font-size: 12px;
      font-weight: 200;
      color: #737373;
    }
    .instructions {
      font-size: 14px;
    }
    .scanned-bags-container {
      width: 100%;
      overflow: scroll;
      .barcode {
        display: flex;
        align-items: center;
        padding: 16px;
        background: var(--disabled-grey);
        border-radius: 8px;
        margin-bottom: 16px;
        .check {
          margin-right: 8px;
        }
        .x {
          cursor: pointer;
          margin-left: auto;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      ::-webkit-scrollbar:vertical {
        width: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border: 2px solid #ffffff;
      }
      ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ffffff;
      }
    }
    .scan-another-bag {
      display: flex;
      align-items: center;
      align-self: start;
      width: fit-content;
      text-decoration: underline;
      cursor: pointer;
      padding-bottom: 16px;
      -webkit-tap-highlight-color: transparent;
      &:focus {
        outline: none;
      }
      .plus {
        margin-right: 12px;
      }
    }
    .footer {
      margin-top: auto;
    }
    .fullscreen-example-modal {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      .x {
        align-self: end;
        margin-bottom: 18px;
      }
      .title {
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
        margin-bottom: 8px;
      }
      .message {
        font-size: 15px;
        color: #737373;
        margin-bottom: 24px;
      }
      img {
        max-width: 100%;
      }
    }
  }
`;


export default $Confirmation;
