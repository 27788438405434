import React, { useState } from "react";
import axios from "axios";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import ReturnistaBagList from "../../components/ReturnistaBagList";
import Header from "../../components/ReturnistaHeader";
import { SVG } from "../../components/svg";
import {
  CurrentPageAction,
  EventAction,
  FatalErrorAction,
  LoadingAction,
  LocationObjectAction,
  ModalAction,
  MultiStoreAction,
  RuntimeType,
  TerminalReason,
} from "../../redux/enums";

import { $Container, $ReturnCounter, $NoItems, $IconWrapper, $ModalButtonWrapper } from "./styles";
import { ToasterFeedbackState } from "./types";
import ReturnistaToaster from "../../components/ReturnistaToaster";
import ReturnistaAlertMessage from "../../components/ReturnistaAlertMessage";
import ReturnistaAnalytics from "../../utility/ReturnistaAnalytics";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import { isUPS } from "../../utility";
import ga from "../../utility/GAEmitter";
import { ReturnSummaryActions } from "../../types/ReturnistaGA";
import logger from "../../utility/logger";

const ReturnistaReturnSummary = () => {
  const dispatch = useDispatch();
  const { items, returnObject, runtime, bags, currentPage, events } = useSelector((state: RootStateOrAny) => state);
  const [toasterFeedback, setToasterFeedback] = useState<ToasterFeedbackState>({
    message: "",
    show: false,
    type: "success",
  });

  const getReturningItems = (itemId: string) => {
    const returnItem = returnObject.returning.find((item) => item.id === itemId);
    const { label, confidence, matched } = items.byId[itemId];
    returnItem.scannedLabelReturnista = { label, confidence, matched };
    return returnItem;
  };

  const goToReturnConfirmation = async (): Promise<void> => {
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.SubmitReturnClicked });
    dispatch({ type: LoadingAction.Set, message: "Submitting" });
    const bagList = bags.allIds;
    try {
      const { data } = await axios.post("/receive", {
        ...returnObject,
        returning: items.allIds.map(getReturningItems),
      });

      const { location } = data;

      if (location) {
        dispatch({ type: LocationObjectAction.Set, location });
      }
      dispatch({ type: LoadingAction.Unset });
      dispatch({ type: CurrentPageAction.Next });
      axios.post("/returns/bagsList", { returnID: returnObject.id, barcodes: bagList });
      ReturnistaAnalytics.bagsWereAdded(bagList);
      if (events.length > 0) {
        axios.post("/returnistaevents", events).then(() => {
          dispatch({
            type: EventAction.Reset,
          });
        });
      }
    } catch (error) {
      // Given the reason for our call to add bags failing is due to poor network conditions this is largely a
      // "best effort" but realistically this may not be called as we may not be able to reach out to RUM if we
      // are in a poor network environment
      ReturnistaAnalytics.bagsMayNotBeAdded(bagList);
      dispatch({ type: LoadingAction.Unset });
      dispatch({
        type: ModalAction.Set,
        modalProps: {
          primaryMessage: "Something went wrong",
          subMessages: [
            "The page didn't load correctly, please try again.",
            "If the issue persists, contact us at (877) 750-4888.",
          ],
          onRequestClose: () => dispatch({ type: ModalAction.Unset }),
          button: (
            <ReturnistaPrimaryButton onClick={() => dispatch({ type: ModalAction.Unset })} dataCyString="close-modal">
              Try again
            </ReturnistaPrimaryButton>
          ),
          iconElement: (
            <$IconWrapper>
              <ReturnistaFeedbackIcon status="error" />
            </$IconWrapper>
          ),
        },
      });
    }
  };

  const submitReturn = () => {
    dispatch({ type: ModalAction.Unset });
    if (isUPS()) {
      goToBagCountPage();
      return;
    }
    goToReturnConfirmation();
  };

  const goToBagCountPage = () => {
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.ContinueClicked });
    dispatch({ type: CurrentPageAction.GoToBagCount });
  };

  const displayPartialReturnModal = () => {
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.PartialReturnModalDisplayed });

    const partialReturnModalName = "partial-return-modal";
    logger.Info("RETURNISTA_PARTIAL_RETURN_MODAL_OPENED");

    const onClose = () => {
      ga.pageEvent({ category: partialReturnModalName, action: ReturnSummaryActions.ModalClosed });
      logger.Info("RETURNISTA_PARTIAL_RETURN_MODAL_CLOSED");
      dispatch({ type: ModalAction.Unset });
    };
    const onAddItem = () => {
      ga.pageEvent({ category: partialReturnModalName, action: ReturnSummaryActions.AddItemClicked });
      logger.Info("RETURNISTA_PARTIAL_RETURN_MODAL_ADD_ITEM");
      dispatch({ type: ModalAction.Unset });
      dispatch({ type: CurrentPageAction.NewItem });
    };
    const onSubmitReturn = () => {
      ga.pageEvent({
        category: partialReturnModalName,
        action: isUPS() ? ReturnSummaryActions.SubmitReturnClicked : ReturnSummaryActions.ContinueClicked,
      });
      logger.Info("RETURNISTA_PARTIAL_RETURN_MODAL_SUBMIT_RETURN");
      submitReturn();
    };

    dispatch({
      type: ModalAction.Set,
      modalProps: {
        dataCyString: partialReturnModalName,
        iconElement: (
          <$IconWrapper>
            <ReturnistaFeedbackIcon status="warning" />
          </$IconWrapper>
        ),
        primaryMessage: "Is that all the items? We were expecting more items for this return.",
        subMessages: [
          `Please confirm with the shopper that they're only returning ${items.allIds.length} item${
            items.allIds.length > 1 ? "s" : ""
          }. ${isUPS() ? "" : "After submitting, the return cannot be modified."}`,
        ],
        onRequestClose: onClose,
        button: (
          <$ModalButtonWrapper>
            <ReturnistaPrimaryButton variant="outlined" onClick={onSubmitReturn} dataCyString="submit-partial-return">
              {isUPS() ? "Continue" : "Submit return"}
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton onClick={onAddItem} dataCyString="add-another-item-from-partial-warning">
              Add another item
            </ReturnistaPrimaryButton>
          </$ModalButtonWrapper>
        ),
      },
    });
  };

  const setToaster = ({ show, message, type }: ToasterFeedbackState) => {
    setToasterFeedback({ show, message, type });
  };

  // addNewItem is used when the return summary page is empty (ie the user removed all instances)
  const addNewItem = (): void => {
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.NoItemsAddItemClicked });
    isUPS() ? dispatch({ type: CurrentPageAction.NewItem }) : dispatch({ type: CurrentPageAction.NewBag });
  };

  const restart = (): void => {
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.NoItemsCancelReturnClicked });
    if (runtime === RuntimeType.Partner) {
      // Try to close the window; if that doesn't work send the user to an end screen
      window.close();
      dispatch({
        type: FatalErrorAction.Set,
        reason: TerminalReason.ReturnCancelled,
      });
      dispatch({ type: CurrentPageAction.FatalError });
      return;
    }
    dispatch({ type: MultiStoreAction.AppReset });
  };

  const allItemsAdded = items.allIds.length === returnObject.returning.length;

  return (
    <>
      <Header center="Return summary" />
      <$Container>
        <ReturnistaToaster
          message={toasterFeedback?.message}
          show={toasterFeedback?.show}
          type={toasterFeedback?.type}
        />
        {items.allIds.length === 0 && (
          <$NoItems>
            <p className="title">No items in return</p>
            <p className="subTitle">
              It looks like there are no items in return. Please add <br /> items to continue.
            </p>
            <div>
              <ReturnistaPrimaryButton onClick={restart} dataCyString="cancel-return" variant="outlined">
                Cancel return
              </ReturnistaPrimaryButton>
              <ReturnistaPrimaryButton onClick={addNewItem} dataCyString="add-item">
                Add item
              </ReturnistaPrimaryButton>
            </div>
          </$NoItems>
        )}
        {items.allIds.length > 0 && (
          <>
            <ReturnistaAlertMessage
              message="Keep all footwear in the original shoe box, when provided."
              dataCyString="return-summary-message"
            />
            <ReturnistaBagList setToasterFeedback={setToaster} />
            <$ReturnCounter>
              <div className="counter-container">
                <div className="text">
                  <SVG fill="#1C1B1F" name="returnistaReturnCounter" />
                  <p>
                    I'm accepting <strong>{items.allIds.length}</strong> {items.allIds.length > 1 ? "items" : "item"} in
                    the return
                  </p>
                </div>
                <ReturnistaPrimaryButton
                  variant={allItemsAdded ? "primary" : "outlined"}
                  onClick={allItemsAdded ? submitReturn : displayPartialReturnModal}
                  dataCyString="submit-return"
                >
                  {isUPS() ? "Continue" : "Submit return"}
                </ReturnistaPrimaryButton>
              </div>
            </$ReturnCounter>
          </>
        )}
      </$Container>
    </>
  );
};

export default ReturnistaReturnSummary;
