import React from "react";

const ReturnistaWarning = (props) => {
  return (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.44958 36C2.08291 36 1.74958 35.9083 1.44958 35.725C1.14958 35.5417 0.916246 35.3 0.749579 35C0.582913 34.7 0.491246 34.375 0.474579 34.025C0.457913 33.675 0.549579 33.3333 0.749579 33L19.2496 1C19.4496 0.666667 19.7079 0.416667 20.0246 0.25C20.3412 0.0833333 20.6662 0 20.9996 0C21.3329 0 21.6579 0.0833333 21.9746 0.25C22.2912 0.416667 22.5496 0.666667 22.7496 1L41.2496 33C41.4496 33.3333 41.5412 33.675 41.5246 34.025C41.5079 34.375 41.4162 34.7 41.2496 35C41.0829 35.3 40.8496 35.5417 40.5496 35.725C40.2496 35.9083 39.9162 36 39.5496 36H2.44958ZM5.89958 32H36.0996L20.9996 6L5.89958 32ZM20.9996 30C21.5662 30 22.0412 29.8083 22.4246 29.425C22.8079 29.0417 22.9996 28.5667 22.9996 28C22.9996 27.4333 22.8079 26.9583 22.4246 26.575C22.0412 26.1917 21.5662 26 20.9996 26C20.4329 26 19.9579 26.1917 19.5746 26.575C19.1912 26.9583 18.9996 27.4333 18.9996 28C18.9996 28.5667 19.1912 29.0417 19.5746 29.425C19.9579 29.8083 20.4329 30 20.9996 30ZM20.9996 24C21.5662 24 22.0412 23.8083 22.4246 23.425C22.8079 23.0417 22.9996 22.5667 22.9996 22V16C22.9996 15.4333 22.8079 14.9583 22.4246 14.575C22.0412 14.1917 21.5662 14 20.9996 14C20.4329 14 19.9579 14.1917 19.5746 14.575C19.1912 14.9583 18.9996 15.4333 18.9996 16V22C18.9996 22.5667 19.1912 23.0417 19.5746 23.425C19.9579 23.8083 20.4329 24 20.9996 24Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default ReturnistaWarning;
