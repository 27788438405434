import React from "react";

const Info = (props) => (
  <>
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00042 1.60001C11.5352 1.60001 14.4007 4.4655 14.4007 8.00033C14.4007 11.5345 11.5352 14.4 8.00042 14.4C4.46559 14.4 1.6001 11.5345 1.6001 8.00033C1.6001 4.4655 4.46559 1.60001 8.00042 1.60001ZM8.00042 2.55986C7.28104 2.55206 6.56726 2.68701 5.90039 2.9569C5.23352 3.2268 4.6268 3.62627 4.11534 4.13221C3.60389 4.63815 3.19785 5.2405 2.92075 5.90441C2.64364 6.56832 2.50095 7.28059 2.50095 8.00001C2.50095 8.71942 2.64364 9.4317 2.92075 10.0956C3.19785 10.7595 3.60389 11.3619 4.11534 11.8678C4.6268 12.3737 5.23352 12.7732 5.90039 13.0431C6.56726 13.313 7.28104 13.448 8.00042 13.4401C9.4287 13.4182 10.7911 12.8355 11.7934 11.8177C12.7956 10.7999 13.3574 9.42877 13.3574 8.00033C13.3574 6.57188 12.7956 5.20073 11.7934 4.18295C10.7911 3.16517 9.4287 2.58241 8.00042 2.5605V2.55986ZM7.99786 7.03919C8.11394 7.03904 8.22615 7.08097 8.31368 7.15721C8.40122 7.23346 8.45816 7.33884 8.47395 7.45385L8.47842 7.51912L8.48034 11.0399C8.48031 11.1615 8.43411 11.2785 8.3511 11.3674C8.26809 11.4562 8.15444 11.5102 8.03313 11.5185C7.91181 11.5268 7.79188 11.4888 7.69755 11.412C7.60322 11.3353 7.54154 11.2256 7.52497 11.1051L7.52049 11.0405L7.51857 7.51976C7.51857 7.39247 7.56913 7.2704 7.65914 7.1804C7.74914 7.09039 7.87121 7.03983 7.9985 7.03983L7.99786 7.03919ZM8.00042 4.80209C8.08603 4.79938 8.17131 4.8139 8.25119 4.84479C8.33108 4.87568 8.40394 4.92231 8.46546 4.98191C8.52698 5.04151 8.57589 5.11286 8.60929 5.19173C8.64269 5.2706 8.6599 5.35538 8.6599 5.44103C8.6599 5.52668 8.64269 5.61146 8.60929 5.69033C8.57589 5.7692 8.52698 5.84055 8.46546 5.90015C8.40394 5.95975 8.33108 6.00638 8.25119 6.03727C8.17131 6.06816 8.08603 6.08268 8.00042 6.07997C7.83446 6.07472 7.67705 6.0051 7.56152 5.88585C7.44598 5.7666 7.38138 5.60707 7.38138 5.44103C7.38138 5.27499 7.44598 5.11546 7.56152 4.99621C7.67705 4.87696 7.83446 4.80734 8.00042 4.80209Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Info;
