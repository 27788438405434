// A singleton intended to handle API calls for the Returnista app.
export default class ReturnistaAPIManager {
  static getProxyImage = (imageUrl: string): Promise<string> => {
    const url = imageUrl.startsWith("/instance_image")
      ? imageUrl
      : `/image_proxy?url=${ReturnistaAPIManager.formatUrl(imageUrl)}`;
    return fetch(url)
      .then((response) => {
        if (ReturnistaAPIManager.isSuccessfulResponse(response.status)) return response.blob();
        throw new Error("image not found");
      })
      .then((blob) => {
        return new Promise<string>((resolve, reject) => {
          // Convert the blob to a base64 string.
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          // When the reader is done, memoize the result and return it.
          reader.onloadend = () => {
            const result = reader.result;
            if (typeof result === "string") {
              resolve(result);
              return;
            }
            throw new Error("unable to decode result");
          };

          // If the reader errors, throw an error.
          reader.onerror = () => {
            throw new Error("unable to load image");
          };
        });
      });
  };

  static getFakeImages = (retailerId: string, colorsToExclude: string, confirmationCode: string): Promise<Array<string>> => {
    return fetch(`/retailer-excess-images?retailerID=${retailerId}&excludedColors=${colorsToExclude}&confirmationCode=${confirmationCode}`)
      .then((response) => {
        if (ReturnistaAPIManager.isSuccessfulResponse(response.status)) return response.json();
        throw new Error("unable to get fake images");
      })
      .then((response) => {
        return response?.productImages ?? [];
      });
  };

  static isSuccessfulResponse(status: number): boolean {
    return status >= 200 && status < 300;
  }

  static formatUrl = (url: string): string => {
    return url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schema, nonSchemaUrl) =>
      schema ? match : `https://${nonSchemaUrl}`
    );
  };
}
