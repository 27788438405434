import React from "react";

const DownloadSuccess = (props) => (
  <>
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_568_1012)">
        <path
          d="M5 18H19V20H5V18ZM9.6 15.3L5 10.7L7 8.8L9.6 11.4L17 4L19 6L9.6 15.3Z"
          fill="#138044"
        />
      </g>
      <defs>
        <clipPath id="clip0_568_1012">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default DownloadSuccess;
