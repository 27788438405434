import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CountItemsPresentState {
  itemsPresent: number;
}

export const initialState: CountItemsPresentState = {
  itemsPresent: 0,
};

export const countItemsPresentSliceName = "count-items-present";

const countItemsPresentSlice = createSlice({
  name: countItemsPresentSliceName,
  initialState,
  reducers: {
    increment(state) {
      if (state.itemsPresent < 99) {
        state.itemsPresent += 1;
      }
    },
    decrement(state) {
      state.itemsPresent -= 1;
      if (state.itemsPresent < 0) {
        state.itemsPresent = 0;
      }
    },
    setItemsPresent(state, action: PayloadAction<number>) {
      if (!action.payload) {
        state.itemsPresent = 0;
      }
      if (action.payload < 100) {
        state.itemsPresent = action.payload;
      }
    },
  },
});

export const actions = {
  ...countItemsPresentSlice.actions,
};

export const countItemsPresentReducer = countItemsPresentSlice.reducer;
