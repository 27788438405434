import React from "react";

const CircleCheckFilled = (props) => (
  <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="checkColor" d="M6.66667 9.33333H25.3333V26.6667H6.66667V9.33333Z" fill="white"/>
      <path id="backgroundCircleColor" d="M13.76 18.88L10.32 15.44C10.0267 15.1467 9.65333 15 9.2 15C8.74667 15 8.37333 15.1467 8.08 15.44C7.78667 15.7333 7.64 16.1067 7.64 16.56C7.64 17.0133 7.78667 17.3867 8.08 17.68L12.64 22.24C12.96 22.56 13.3333 22.72 13.76 22.72C14.1867 22.72 14.56 22.56 14.88 22.24L23.92 13.2C24.2133 12.9067 24.36 12.5333 24.36 12.08C24.36 11.6267 24.2133 11.2533 23.92 10.96C23.6267 10.6667 23.2533 10.52 22.8 10.52C22.3467 10.52 21.9733 10.6667 21.68 10.96L13.76 18.88ZM16 32C13.7867 32 11.7067 31.58 9.76 30.74C7.81333 29.9 6.12 28.76 4.68 27.32C3.24 25.88 2.1 24.1867 1.26 22.24C0.42 20.2933 0 18.2133 0 16C0 13.7867 0.42 11.7067 1.26 9.76C2.1 7.81333 3.24 6.12 4.68 4.68C6.12 3.24 7.81333 2.1 9.76 1.26C11.7067 0.42 13.7867 0 16 0C18.2133 0 20.2933 0.42 22.24 1.26C24.1867 2.1 25.88 3.24 27.32 4.68C28.76 6.12 29.9 7.81333 30.74 9.76C31.58 11.7067 32 13.7867 32 16C32 18.2133 31.58 20.2933 30.74 22.24C29.9 24.1867 28.76 25.88 27.32 27.32C25.88 28.76 24.1867 29.9 22.24 30.74C20.2933 31.58 18.2133 32 16 32Z" fill="#3AA5EC"/>
    </svg>
  </>
);

export default CircleCheckFilled;
