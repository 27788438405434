// third-party imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// repo imports
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import { SVG } from "../../components/svg";

// local imports
import $ConfirmationWithoutLogistics from "./styles";
import PrimaryButton from "../../components/Button/PrimaryButton";
import TextInput from "../../components/TextInput";
import ReturnCountFooter from "../../components/ReturnCountFooter";
import { handlePluralItemsReturnedText, isValidEmail } from "../../utility";
import { ErrorAlertMessage, SuccessMessage } from "../../components/AlertMessage";

class ConfirmationWithoutLogisticsLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

/**
 * User is sent here when a retailer location has reverse logistics disbaled
 */
const ConfirmationWithoutLogistics = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { customer, app } = useSelector((store: RootReducer) => store);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const canSubmit = isValidEmail(email);
  const lifecycle = new ConfirmationWithoutLogisticsLifecycle(page, dispatch, app);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    setError("");
    setSuccessMessage("");
  }, [email]);
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const onSubmit = async () => {
    try {
      const resp = await axios.post("/email/confirmation", {
        returnID: customer.newReturnID,
        email
      });
      setSuccessMessage(`A receipt has been sent to ${email}.`);
    } catch (e) {
      console.error(e);
      setEmail("");
      setError("Invalid email. Please try again.");
    }
  }

  const onEmailChange = (email) => {
    setError("");
    setSuccessMessage("");
    setEmail(email);
  }
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <$ConfirmationWithoutLogistics>
      <SVG name="checkCircle" />
      <div className="header">
        Return Successfully Processed
      </div>
      <div className="customer-email">
        An email receipt has been sent to {customer.newReturnEmail}.
      </div>
      <div className="divider" />
      <div className="new-email">
        RESEND RECEIPT TO ANOTHER EMAIL
      </div>
      <div className="input-container">
        <TextInput type="email" value={email} onChange={onEmailChange} />
        <PrimaryButton label="Send" onButtonClick={() => onSubmit()} disabled={!canSubmit} />
      </div>
      <div className="alert">
        {error &&
          <ErrorAlertMessage
            message={error}
          />
        }{successMessage &&
          <SuccessMessage
            message={successMessage}
          />
        }
      </div>
      <div className="footer">
        <ReturnCountFooter runtime={app.runtime} count={customer.itemsMarkedForCompletion.length}>
          <PrimaryButton width="195px" label="Return to Home Screen" onButtonClick={() => lifecycle.advance()} />
        </ReturnCountFooter>
      </div>
    </$ConfirmationWithoutLogistics>
  );
  //----------------------------------------------------------------------------
}

export default ConfirmationWithoutLogistics;
