import styled from 'styled-components';

const $FinalBasePage = styled.div`
  display: flex;
  justify-content: center;
`;

const $FinalBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 16px;
  box-sizing: border-box;
`;

const $IconWrapper = styled.div`
  margin-top: 64px;
  height: 56px;
  width: 56px;
`;

const $PrimaryMessage = styled.div`
  margin-top: 27px;
  margin-bottom: 12px;
  color: #0B0B0B;
  font-size: 24px;
`;

const $SubMessages = styled.div`
  text-align: center;
  color: #737373;
  font-size: 16px;
  line-height: 24px;
`;
const $SubMessage = styled.div``;

const $RuntimeItem = styled.div`
  margin-top: 12px;
  button {
    width: 240px;
    height: 40px;
  }
`;

export {
  $FinalBasePage,
  $FinalBase,
  $IconWrapper,
  $PrimaryMessage,
  $SubMessages,
  $SubMessage,
  $RuntimeItem,
}