import React from "react";
import PrimaryButton from "../../components/Button/PrimaryButton"
import ReturnistaModalTemplate from "../../components/Modal/ReturnistaModalTemplate"
import { DataCyStrings } from "../../types/DataCyStrings"

/*
  Modals used exclusively in the count items present page
*/
export const TooManyItemsModal = ({ onClose, headerText }) => {
  return (
    <ReturnistaModalTemplate
      dataCy={DataCyStrings.countItemsPresentModal}
      onClose={onClose}
      headerText={headerText}
    >
      <h1 data-cy={DataCyStrings.countItemsPresentModalHeader}>
        The number of items you entered is more than we have for this return.
      </h1>
      <p>
        When counting, please consider:
      </p>
      <ul>
        <li>
          If the customer has multiple QR codes, process each separately
        </li>
        <li>
          A pair of shoes is considered 1 item
        </li>
        <li>
          An item could be a set of two products (ex. towel sets)
        </li>
      </ul>
      <PrimaryButton
        label="Close & Recount"
        onButtonClick={onClose}
      />
    </ReturnistaModalTemplate>
  )
}

export const InfoModal = ({ onClose, headerText }) => {
  return (
    <ReturnistaModalTemplate
      onClose={onClose}
      dataCy={DataCyStrings.countItemsPresentModal}
      headerText={headerText}
    >
      <h1 data-cy={DataCyStrings.countItemsPresentModalHeader}>
        When counting, please consider:
      </h1>
      <ul>
        <li>
          If the customer has multiple QR codes, process each seperately
        </li>
        <li>
          A pair of shoes is considered 1 item
        </li>
        <li>
          An item could be a set of two products (ex. towel sets)
        </li>
      </ul>
      <PrimaryButton label="Close" onButtonClick={onClose} />
    </ReturnistaModalTemplate>
  )
}

export const LessItemsCountedThanInReturnModal = ({ onClose, advance, headerText }) => {
  return (
    <ReturnistaModalTemplate
      onClose={onClose}
      dataCy={DataCyStrings.countItemsPresentModal}
      headerText={headerText}
    >
      <h1 data-cy={DataCyStrings.countItemsPresentModalHeader}>
        Missing Items?
      </h1>
      <p className="text-center">
        The number you entered is less than we expected for this return. Please ask the customer if this total is correct.
      </p>
      <PrimaryButton
        label="Yes, they have fewer items"
        onButtonClick={advance}
      />
      <button className="close" onClick={onClose} data-cy={DataCyStrings.closeModalButton}>
        COUNT AGAIN
      </button>
    </ReturnistaModalTemplate>
  );
}