import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import produce from "immer";

import useSelector from "../../utility/useTypedSelector";
import { PageProps, PartnerScanBagsPageConfiguration } from "../types";
import { Alerts } from "../../types/LifeCycle"
import { BarcodeTextField } from "../../components/ScanBagsTextField";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ModalContainer from "../../components/Modal/ModalContainer";
import ReturnCountFooter from "../../components/ReturnCountFooter";
import ConfirmationEmail from "../../components/ConfirmationEmail";
import Step from "../../components/Step";
import { BarcodeRow } from "../../components/BarcodeWidget";
import { PageLifecycle } from "../";
import { showAlert, showLoadingIndicator, clearLoadingIndicator, refreshToken } from "../../redux/app/actions";
import {
  getLocalStorageJSON,
  getReturnistaJWTClaims,
  removeSpecialCharacter,
  setLocalStorageJSON
} from "../../utility";
import { BarcodeColumn } from "../../types/Config";
import { Store as CustomerStore } from "../../redux/customer/types";

import {
  $PartnerConfirmation,
  $Title,
  $Directions,
  $ScanInputContainer,
  $BarcodeSection,
  $BarcodeList,
  $Body,
  $StepBorder,
} from "./styles";
import {
  PublishedBarcodeRows,
  BarcodeRow as IBarcodeRow,
  BarcodePrompt,
  initBarcodeRow,
  barcodeCombinationExists,
  barcodeDeleteByRuntime,
  barcodePostByRuntime,
  barcodeValidatorsByKey
} from "./helpers";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import { t } from "i18next";
import { DataCyStrings } from "../../types/DataCyStrings";
import logger from "../../utility/logger";

//==============================================================================
class PartnerConfirmationLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }

  satisfiesPreconditions() {
    if (!super.satisfiesPreconditions()) return false;
    if (!this.customer.returns || !this.customer.returns.returning) return false;
    logger.Info("Preconditions satisfied. Rendering Partner Confirmation...");
    return true;
  }
}
//==============================================================================


/**
 * Generic scan-bag/confirmation page for browser partner runtimes
 */
const PartnerConfirmation = ({page}: PageProps<PartnerScanBagsPageConfiguration>) => {
  const dispatch = useDispatch();

  const barcodeColumns = page.pageSettings?.barcodeColumns;

  const { app, customer } = useSelector(store => store);
  const { newReturnID } = customer;
  const { runtime, loadingMessage, token } = app;
  const claims = getReturnistaJWTClaims(token);

  const lifecycle = new PartnerConfirmationLifecycle(page, dispatch, app, customer);

  // barcodes keeps track of all barcode combinations that have been saved in the DB for the current return
  const [barcodes, setBarcodes] = useState<PublishedBarcodeRows>(getLocalStorageJSON("barcodes") || []);

  // barcodeRow is used to keep track of a barcode combination to be sent to the DB
  const [barcodeRow, setBarcodeRow] = useState<IBarcodeRow>(initBarcodeRow(barcodeColumns));

  const barcodeKeys = barcodeColumns?.map(column => column.barcodeKey);

  // modal states
  // renders the example modal for a given barcode type when not null
  const [selectedBarcodeExample, setSelectedBarcodeExample] = useState<BarcodeColumn | null>(null);
  const [displayWrongCodeModal, setDisplayWrongCodeModal] = useState(false);

  const [barcodeInputFocusIdx, setBarcodeInputFocusIdx] = useState(0);

  useEffect(() => {
    setLocalStorageJSON("barcodes", barcodes);
  }, [barcodes]);

  useEffect(() => {
    dispatch(clearLoadingIndicator());
  },[])

  function addBarcodeRowToState (barcodeRow: IBarcodeRow) {
    setBarcodes(produce(barcodes, draft => {
      draft.push(barcodeRow);
      return draft;
    }));
  }

  function removeBarcodeRowFromState(rowIdx: number) {
    setBarcodes(produce(barcodes, draft => {
      draft.splice(rowIdx, 1);
      return draft;
    }))
  }

  function setBarcodeRowByIdx(value: string, rowIdx: number) {
    setBarcodeRow(produce(barcodeRow, draft => {
      draft[rowIdx] = value;
      return draft;
    }))
  }

  function handleAddBarcodeClick() {
    // force the user's browser to focus on the nearest blank input
    for (let rowIdx = 0; rowIdx < barcodeRow.length; rowIdx += 1) {
      if (barcodeRow[rowIdx] === "") {
        setBarcodeInputFocusIdx(rowIdx);
        return;
      }
      // search for duplicates if not allowed by config
      if (!barcodeColumns[rowIdx].allowDuplicates) {
        const duplicateIdx = barcodes.map(row => row[rowIdx].toUpperCase())
          .findIndex(barcode => barcode === barcodeRow[rowIdx].toUpperCase());

        if (duplicateIdx != -1) {
          setBarcodeRow(initBarcodeRow(barcodeColumns));
          dispatch(showAlert(Alerts.bagAlreadyScanned));
          return;
        }
      }
    }
    // don't allow this function to be called
    // again while a request is pending
    if (loadingMessage) {
      return;
    } else {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
    }

    let trimmedBarcodes = barcodeRow.map(barcode => {
      return barcode.trim();
    });

    // validate trimmed barcode column only if the associated key has a validator function
    for (let barcodeIdx = 0; barcodeIdx < trimmedBarcodes.length; barcodeIdx += 1) {
      const barcodeKey = barcodeKeys[barcodeIdx];
      const barcodeValidator = barcodeValidatorsByKey[barcodeKey];
      if (!barcodeValidator) {
        continue;
      } else if (barcodeValidator && barcodeValidator(trimmedBarcodes[barcodeIdx])) {
        continue;
      } else {
        setBarcodeInputFocusIdx(barcodeIdx);
        setDisplayWrongCodeModal(true);
        dispatch(clearLoadingIndicator());
        return;
      }
    }

    // search for the barcode combination in our array of saved rows
    if (barcodeCombinationExists(trimmedBarcodes, barcodes)) {
      dispatch(showAlert(Alerts.bagAlreadyScanned));
      setBarcodeRow(initBarcodeRow(barcodeColumns));
      dispatch(clearLoadingIndicator());
      return;
    }

    const postBarcode = barcodePostByRuntime[runtime];
    if (!postBarcode) {
      console.error("POST handler not found. Ensure that the method is defined for the given runtime");
      dispatch(clearLoadingIndicator());
      return;
    }

    // notify backend.
    // If no issues occur, update state to include the new row
    // and refocus on the first barcode input
    postBarcode(trimmedBarcodes, barcodeKeys, newReturnID, () => {
      addBarcodeRowToState(trimmedBarcodes);
      setBarcodeInputFocusIdx(0);
      setBarcodeRow(initBarcodeRow(barcodeColumns));
      dispatch(clearLoadingIndicator());
    }, () => {
      dispatch(clearLoadingIndicator());
    }, dispatch);
  }

  function handleBarcodeRowDeleteClicked(barcodeRowIdx: number) {
    const deleteBarcode = barcodeDeleteByRuntime[runtime];
    dispatch(showLoadingIndicator(defaultLoadingSymbol));

    if (!deleteBarcode) {
      dispatch(clearLoadingIndicator());
      console.error("DELETE handler not found. Ensure that the method is defined for the given runtime");
      return;
    }

    deleteBarcode(barcodes[barcodeRowIdx], barcodeKeys, newReturnID,
      () => {
        removeBarcodeRowFromState(barcodeRowIdx);
        dispatch(clearLoadingIndicator());
      },
      () => {
        dispatch(clearLoadingIndicator());
      }, dispatch)
  }

  function finishReturnIsDisabled() {
    return barcodes.length <= 0;
  }

  function keepTokenAlive() {
    const intervalID = setInterval(() => {
      // XXX - Every second, check if token is within one minute of expiring
      // attempt to refresh said token to ensure user can continue scanning bags
      // for a return
      const currentTimeWithExtraMinute = Math.floor((Date.now()/1000)) + 60;
      if (claims?.exp && currentTimeWithExtraMinute > parseInt(claims.exp)) {
        dispatch(refreshToken((e) => {
          console.error(e);
        }));
      }
    }, 1000);

    return intervalID;
  }

  // HOOKS
  useEffect(() => {
    const intervalID = keepTokenAlive();
    return () => clearInterval(intervalID);
  }, [token]);


  //----------------------------------------------------------------------------
  // RENDERING
  function renderBarcodeRows() {
    if (barcodes.length < 1) {
      return
    } else {
      return barcodes.map((barcodeRow, idx) => {
        return <BarcodeRow key={idx} barcodeRow={barcodeRow} onDeleteRow={() => handleBarcodeRowDeleteClicked(idx)}/>
      })
    }
  }

  const renderStepOne = () => (
      <Step
        imgName={"one"}
        prompt={<>
          <b>Tell the customer:</b> "Your return is complete and you’ll receive an email receipt from Happy Returns. You’re free to go"
          {!!claims?.returnsApp?.couponEnabled && <div className="use-coupon-banner">Remind the customer their receipt contains a coupon for your store.</div>}
          <ConfirmationEmail returnID={newReturnID} />
        </>}
      />
    );

  const renderStepTwoPrompt = () => {
    return barcodeColumns?.map((column, idx) => {
      return (
        <BarcodePrompt
          key={"prompt" + column.barcodeKey}
          barcodeIdx={idx}
          barcodeCaption={column.barcodeCaption}
          barcodeCaptionExample={column.barcodeCaptionExample}
          barcodeCopy={column.barcodeHeader}
          barcodeShowExampleCopy={column.barcodeExampleCopy}
          showLetter={page?.pageSettings?.barcodeColumns.length > 1}
          onShowExampleClicked={() => { setSelectedBarcodeExample(produce(selectedBarcodeExample, draft => {
            draft = {...column}
            return draft;
          })) }}
        />
      )
    })
  }

  const renderStepTwo = () => {
    return (
      <Step
        imgName={"two"}
        prompt={renderStepTwoPrompt()}
      />
    )
  }

  function renderExampleModal() {
    if (selectedBarcodeExample === null) return null;
    return (
      <ModalContainer
        isOpen={selectedBarcodeExample != null}
        onRequestClose={() => setSelectedBarcodeExample(null)}
        primaryMessage={selectedBarcodeExample.barcodeExampleModalHeader}
        subMessages={[selectedBarcodeExample.barcodeExampleModalCopy]}
        img={selectedBarcodeExample.barcodeExampleImage}
        closeIcon={true}
        height={"448px"}
        imgPosition={"bottom"}
      />
    )
  }

  function renderWrongCodeModal() {
    return (
      <ModalContainer
        isOpen={displayWrongCodeModal}
        onRequestClose={() => setDisplayWrongCodeModal(false)}
        primaryMessage={"Wrong Code Scanned"}
        subMessages={["Please ensure you are using a valid code and try again."]}
        img="/public/img/wrongCodeScanned.png"
        imgPosition={"bottom"}
        closeIcon={true}
        height={"442px"}
        width={"456px"}
      />
    )
  }

  function renderInputs() {
    return page?.pageSettings.barcodeColumns.map((column, idx) => (
      <BarcodeTextField
        key={column.barcodeKey}
        value={barcodeRow[idx]}
        placeholder={column.barcodeInputPlaceholder}
        shouldFocus={barcodeInputFocusIdx === idx}
        onChange={(val) => setBarcodeRowByIdx( removeSpecialCharacter(val), idx)}
        onSubmit={() => handleAddBarcodeClick()}
        onFocus={() => setBarcodeInputFocusIdx(idx)}
        dataCyString={DataCyStrings.scanBagCodeInput}
      />
    ))
  }

  return (
    <$PartnerConfirmation>
    {renderExampleModal()}
    {renderWrongCodeModal()}
      <$Title data-cy={DataCyStrings.partnerConfirmationTitle}>Finish the return</$Title>
      <$Directions>
      <$StepBorder>
        {renderStepOne()}
      </$StepBorder>
      <$StepBorder>
        {renderStepTwo()}
        <div className="scan-container">
        <$ScanInputContainer barcodeColumns={page?.pageSettings?.barcodeColumns}>
          {renderInputs()}
        </$ScanInputContainer>
      <$BarcodeSection>
        <$BarcodeList barcodeColumns={page?.pageSettings?.barcodeColumns}>
          {renderBarcodeRows()}
        </$BarcodeList>
      </$BarcodeSection>
      </div>
        </$StepBorder>
      </$Directions>
      <$Body>

      </$Body>
      <ReturnCountFooter
        count={barcodes.length}
        itemType="bag"
        runtime={runtime}
      >
        <PrimaryButton
          onButtonClick={() => {lifecycle.advance()}}
          label="Finish Return"
          width="121px"
          disabled={finishReturnIsDisabled()}
          dataCyString={DataCyStrings.finishReturnButton}
        />
      </ReturnCountFooter>
    </$PartnerConfirmation>
  )
  //----------------------------------------------------------------------------
}
//==============================================================================

export default PartnerConfirmation;
