import styled from "styled-components";

/**
 * Base page container
 */
const $PageContentBox = styled.div`
  @media screen and (max-width: 768px){
    &.ios {
      width: 100%;
      margin: 24px 0 0 0;
      border: none;
      box-shadow: none;
      .header {
        display: none;
      }
      .list {
        .return-info {
          .retailer-name, .express-code {
            font-size: 16px;
          }
        }
      }
    }
  }
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 24px 81px;
  max-width: 776px;
  border-radius: 12px;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  @media screen and (max-width: 768px){
    margin: 0;
    border: none;
    box-shadow: none;
    &.ios {
      width: 100%;
      margin: 24px 0 0 0;
      border: none;
      box-shadow: none;
    }
  }
`;

export default $PageContentBox;