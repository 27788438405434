import styled from "styled-components";
import { modalZIndex } from "../../../utility";

export const $InstanceDetails = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 22px 24px 24px;
  overflow: scroll;
  overflow-x: hidden;
  @media (max-width: 480px) {
    overflow: unset;
  }
  .header {
    display: flex;
    position: sticky;
    top: 0;
    padding-right: 4px;
    padding-top: 24px;
    margin-bottom: 24px;
    background: white;
    z-index: ${modalZIndex};
    padding-bottom: 2px;
    svg {
      margin-left: auto;
      cursor: pointer;
    }
  }
  p.carousel-status, ul.control-dots, button.control-arrow {
    display: none;
  }
  .image-container {
    width: 408px;
    height: 408px;
    img {
      width: 408px;
      height: 408px;
      object-fit: contain;
    }
  }
  ul.thumbs {
    &, * {
      transform: none !important;
      transition-duration: 0s !important;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    width: 100%;
    li {
      flex-shrink: 0;
      margin-bottom: 8px;
    }
    & > *:focus-visible {
      outline: 2px solid blue;
    }
  }
  ul.slider {
    color: green;
    li.selected {
      display: flex;
      align-items: center;
    }
  }
  .carousel-slider {
    @media (max-width: 480px) {
      width: 100% !important;
    }
  }
  div.thumbs-wrapper {
    overflow: unset;
    li, li.thumb.selected {
      height: 40px;
    }
    li, li.thumb, li.thumb.selected {
      width: 40px !important;
    }
    li.thumb {
      border: 1px solid #D8D8D8;
      border-radius: 4px;
      padding: 0;
      overflow: hidden;
    }
    li.thumb.selected {
       border: 2px solid #221F1F;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .purchase-header {
    margin: 4px 0 8px 0;
    .order-number {
      font-weight: 800;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-black);
    }
    .order-date {
      font-size: 14px;
      line-height: 20px;
      color: var(--header-grey);
    }
  }

  .purchase-name, .purchase-brand {
    font-size: 15px;
    line-height: 22px;
  }

  .apart {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .col {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 20px;
    color: var(--header-grey);
    margin-top: 8px;
  }
  .carousel-root {
    max-width: 408px;
    width: 100%;
    margin: 0 auto;
    &:focus, &:focus-within {
      outline: 2px solid blue;
    }
  }
  @media (max-width: 768px) {
    padding: 0% 3% 0% 0%;
    overflow-y: auto;
    .image-container {
      width: 343px;
      height: 343px;
      img {
        width: 343px;
        height: 343px;
        object-fit: contain;
      }
    }
  }
`;
