import { RuntimeAction, RuntimeType } from "../enums";

const runtimeReducer = (state = RuntimeType.Returnista, action) => {
  switch (action.type) {
    case RuntimeAction.Set:
      return action.runtime;
    default:
      return state;
  }
};

export default runtimeReducer;
