import React from "react";

const Truck = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <mask
      id="mask0_4120_7986"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4120_7986)">
      <path
        d="M5.84386 19.5576C5.06333 19.5576 4.40063 19.2847 3.85576 18.7387C3.31089 18.1928 3.03846 17.5299 3.03846 16.75H1.34616V6.3077C1.34616 5.80257 1.52116 5.375 1.87116 5.025C2.22116 4.675 2.64872 4.5 3.15384 4.5H16.7692V8.3077H19.423L22.6538 12.6346V16.75H20.8461C20.8461 17.5299 20.5729 18.1928 20.0266 18.7387C19.4802 19.2847 18.8167 19.5576 18.0362 19.5576C17.2557 19.5576 16.593 19.2847 16.0481 18.7387C15.5032 18.1928 15.2308 17.5299 15.2308 16.75H8.65381C8.65381 17.532 8.38062 18.1955 7.83424 18.7403C7.28787 19.2852 6.62441 19.5576 5.84386 19.5576ZM5.84614 18.0577C6.2128 18.0577 6.52242 17.9314 6.77499 17.6788C7.02755 17.4263 7.15384 17.1166 7.15384 16.75C7.15384 16.3833 7.02755 16.0737 6.77499 15.8211C6.52242 15.5685 6.2128 15.4423 5.84614 15.4423C5.47945 15.4423 5.16983 15.5685 4.91726 15.8211C4.66469 16.0737 4.53841 16.3833 4.53841 16.75C4.53841 17.1166 4.66469 17.4263 4.91726 17.6788C5.16983 17.9314 5.47945 18.0577 5.84614 18.0577ZM2.84614 15.25H3.56921C3.78201 14.8795 4.08938 14.5689 4.49131 14.3183C4.89324 14.0676 5.34485 13.9423 5.84614 13.9423C6.3346 13.9423 6.783 14.066 7.19134 14.3135C7.59967 14.5609 7.91024 14.8731 8.12306 15.25H15.2692V5.99998H3.15384C3.07692 5.99998 3.0064 6.03203 2.94229 6.09613C2.87819 6.16024 2.84614 6.23077 2.84614 6.3077V15.25ZM18.0385 18.0577C18.4051 18.0577 18.7147 17.9314 18.9673 17.6788C19.2199 17.4263 19.3462 17.1166 19.3462 16.75C19.3462 16.3833 19.2199 16.0737 18.9673 15.8211C18.7147 15.5685 18.4051 15.4423 18.0385 15.4423C17.6718 15.4423 17.3622 15.5685 17.1096 15.8211C16.857 16.0737 16.7307 16.3833 16.7307 16.75C16.7307 17.1166 16.857 17.4263 17.1096 17.6788C17.3622 17.9314 17.6718 18.0577 18.0385 18.0577ZM16.7692 13.25H21.25L18.6538 9.80765H16.7692V13.25Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Truck;
