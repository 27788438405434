import React from "react";

const Leaf = (props) => (
  <>
    <svg
      {...props}
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5796 1.92531C11.5267 1.73277 11.4358 1.55274 11.3123 1.39579C11.1888 1.23884 11.0353 1.10813 10.8606 1.01131C10.6859 0.914498 10.4937 0.853533 10.2952 0.831993C10.0967 0.810454 9.89582 0.828773 9.70446 0.885877L5.26957 2.20979C3.74518 2.66469 2.46231 3.70355 1.70042 5.10005C0.938533 6.49656 0.759359 8.13756 1.20193 9.66557L1.20631 9.67829C1.59579 11.0422 2.45773 12.2232 3.638 13.0099L2.91418 14.3157C2.84989 14.4317 2.8343 14.5685 2.87085 14.6959C2.90741 14.8234 2.9931 14.9311 3.10908 14.9954C3.22506 15.0597 3.36183 15.0753 3.4893 15.0388C3.61678 15.0022 3.72451 14.9165 3.7888 14.8005L4.51165 13.4965C5.80499 14.0805 7.26396 14.1852 8.62746 13.7919L8.64018 13.7875L8.65465 13.7841C10.1813 13.3465 11.4737 12.3243 12.2511 10.9395C13.0286 9.5547 13.2283 7.9191 12.807 6.38788L11.5792 1.92618L11.5796 1.92531ZM5.0047 12.607C4.69787 12.4746 4.40491 12.3122 4.13008 12.1222L6.79362 7.31701C6.85791 7.20103 6.96564 7.11534 7.09311 7.07879C7.22058 7.04223 7.35736 7.05782 7.47334 7.12211C7.58932 7.1864 7.67501 7.29413 7.71156 7.4216C7.74812 7.54907 7.73253 7.68584 7.66824 7.80182L5.0047 12.607Z"
        fill="#51BA3C"
      />
    </svg>
  </>
);

export default Leaf;
