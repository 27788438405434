// TODO organize imports
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { ExpressCodeApplePassData } from "../../types/ExpressCodeApplePassData";
import passImage from "./images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg";
import $ExpressCodeApplePassButton from "./styles";
import logger from "../../utility/logger/logger";
import { SVG } from "../svg";
import ga from "../../utility/GAEmitter";
import { AnalyticCategories, ReturnStartedAndReturnStatusPageActions } from "../../types/Analytics";
import { DataCyStrings } from "../../types/DataCyStrings";

const ExpressCodeApplePassButton = ({
  confirmationCode,
  expirationDate,
  retailerName,
  returnID,
  numItemsInReturn,
  changeDropoffMethodReturnID,
}: ExpressCodeApplePassData) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loggedRender, setLoggedRender] = useState(false);

  const passGenerationErrorMessage = "There was an issue generating your Apple Wallet pass.";

  // HOOKS
  useEffect(() => {
    if (!loggedRender) {
      ga.event({
        category: AnalyticCategories.ReturnStartedPage,
        action: ReturnStartedAndReturnStatusPageActions.AddToWalletButtonLoad
      });
      setLoggedRender(true);
    }
  }, []);

  const downloadPass = () => {
    if (changeDropoffMethodReturnID) {
      axios
        .get(
          `/expresscodeapplepass/${changeDropoffMethodReturnID}`,
          { responseType: "blob" }
        )
        .then((response) => {
          ga.event({
            category: AnalyticCategories.ReturnStartedPage,
            action: ReturnStartedAndReturnStatusPageActions.AddToWalletButtonClick
          });
          const url = URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = "pass.pkpass";
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          setErrorMessage(passGenerationErrorMessage);
          logger.Error("error generating apple pass: " + error.toString());
          ga.event({
            category: AnalyticCategories.ReturnStartedPage,
            action: ReturnStartedAndReturnStatusPageActions.AddToWalletButtonFailure
          });
        });
    } else {
      axios
        .post(
          "/expresscodeapplepass",
          {
            Data: {
              ConfirmationCode: confirmationCode,
              ExpirationDate: expirationDate,
              RetailerName: retailerName,
              ReturnID: returnID,
              NumItemsInReturn: numItemsInReturn,
            },
          },
          { responseType: "blob" }
        )
        .then((response) => {
          ga.event({
            category: AnalyticCategories.ReturnStartedPage,
            action: ReturnStartedAndReturnStatusPageActions.AddToWalletButtonClick
          });
          const url = URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = "pass.pkpass";
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          setErrorMessage(passGenerationErrorMessage);
          logger.Error("error generating apple pass: " + error.toString());
          ga.event({
            category: AnalyticCategories.ReturnStartedPage,
            action: ReturnStartedAndReturnStatusPageActions.AddToWalletButtonFailure
          });
        });
    }
  };

  return (
    <$ExpressCodeApplePassButton>
      <>
        <div className="apple-pass-button-container" data-cy={DataCyStrings.nextStepsAppleWalletButton}>
          <input
            type="image"
            className="apple-pass-image"
            src={passImage}
            onClick={downloadPass}
            alt="Add your pass to the Apple Wallet app where you can store and access the pass."
          />
        </div>
        {errorMessage && (
          <div className="error-banner">
            <div className="icon">
              <SVG name="exclamation" />
            </div>
            <div className="error-text">{errorMessage}</div>
          </div>
        )}
      </>
    </$ExpressCodeApplePassButton>
  );
};

export default ExpressCodeApplePassButton;
