// third-party imports
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch} from "react-redux";

// repo imports
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import { fetchOrders, resetCustomerOrders } from '../../redux/customer/actions';
import { ReturnTypes, Store as CustomerStore } from "../../redux/customer/types";
import { ErrorAlertMessage } from '../../components/AlertMessage';
import { AnalyticCategories } from "../../types/Analytics";
import SearchField from "../../components/SearchField";
import logger from "../../utility/logger";

// local imports
import $OrderSearch from  "./styles";
import { DataCyStrings } from "../../types/DataCyStrings";


class OrderSearchLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }

  satisfiesPreconditions() {
    return this.customer.returnType != ReturnTypes.expressReturn;
  }
}

const copies = {
  [ReturnTypes.giftReturn]: "Search by order number",
  [ReturnTypes.newReturn]: "Search by email or order number",
}

const fetchOrdersCopies = {
  "404-orderNumber": "No items were found for this order number. Please review the order number types along with the order number the customer provided and then try again.",
  "404-email": "No orders associated with this email. Please check the spelling or try using a different email address.",
  "500": "An error occurred while searching. Please try searching again. If the issue persists, please contact customer support at (877) 750-4888.",
  "emailNotAllowed": "Emails are not allowed for gift returns. Please enter an order number.",
  "invalidEmail": "Please enter a valid email"
}


/**
 * This page is used to lookup order numbers or emails relative to the retailer that a location is associated with.
 */
const OrderSearch = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { customer, app } = useSelector((store: RootReducer) => store);
  const { returnType } = customer;
  const [query, setQuery] = useState("");
  const queryType = (query.includes("@") || query.includes(".")) ? "email" : "orderNumber";

  const [localErrorMessage, setLocalErrorMessage] = useState("");

  const inputRef = useRef<any>(null);
  const lifecycle = new OrderSearchLifecycle(page, dispatch, app, customer);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS

  useEffect(() => {
    dispatch(resetCustomerOrders());
  }, [])

  useEffect(() => {
    setLocalErrorMessage("");
  }, [query])

  //---------------------------------------------------------------------------

  // HANDLERS
  const handleSubmit = () => {
    if (query != "") {
      // if the query type is email, notify the user that emails are not
      // allowed for gift returns
      if (queryType === "email" && returnType === ReturnTypes.giftReturn) {
        setLocalErrorMessage(fetchOrdersCopies.emailNotAllowed);
        return;
      // if the query contains only one of the characters @ or .
      // notify the user that the email is invalid. If neither are
      // in the query, assume the queryType is an order number
      } else if (queryType === "email" && !(query.includes("@") && query.includes("."))) {
        setLocalErrorMessage("Invalid email. Please try again.");
        return;
      }
      dispatch(fetchOrders({
        query,
        onSuccess: (response, dispatch) => {
            lifecycle.advance();
        },
        onError: (error, dispatch) => {
          const status  = error?.response?.status;
          let copy = "";
          //if the status is 404, use the copy according to the query type
          if (status === 404) {
            copy = fetchOrdersCopies[`${status}-${queryType}`];
          } else {
            if (status) {
              copy = fetchOrdersCopies[status];
            }
          }

          // only update the error banner if a valid copy was defined
          if (copy) {
            setLocalErrorMessage(copy);
          }
          inputRef?.current?.select();
        }
      }))
    }
  }
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <$OrderSearch>
      <SearchField
        value={query}
        onChange={(val) => { setLocalErrorMessage(""); setQuery(val); }}
        onClear={() => { setLocalErrorMessage(""); setQuery(""); }}
        onSubmit={handleSubmit}
        placeholder={copies[returnType]}
        inputRef={inputRef}
        inputDataCyString={DataCyStrings.orderSearchInput}
      />
      {localErrorMessage != "" &&
        <div className="error-message-container">
          <ErrorAlertMessage message={localErrorMessage} />
        </div>
      }
    </$OrderSearch>
  );
  //----------------------------------------------------------------------------
}

export default OrderSearch;
