import React from "react";

import { SVG, SVGName } from "../../components/svg";
import { DataCyStrings } from "../../types/DataCyStrings";
import { handleEnterKeyPressOnClickHandlers } from "../../utility";

import $NavigationCard from "./styles";

const requiredImagesLength = 3;

const hasProductImages = (imageUrls) => {
  return imageUrls && Array.isArray(imageUrls) && imageUrls.length == requiredImagesLength;
}

interface NavigationCardProps {
  title?: string,
  subtext?: string,
  iconName: SVGName,
  showArrow?: boolean,
  onClick: () => void,
  dataCyString?: DataCyStrings
  imageUrls?: string[],
}

const NavigationCard = ({ title, subtext, iconName, showArrow, onClick, dataCyString, imageUrls }: NavigationCardProps) => {
  const navigationCardHandlers = handleEnterKeyPressOnClickHandlers(() => onClick());
  return (
    <$NavigationCard data-cy={dataCyString} imageUrls={imageUrls} {...navigationCardHandlers}>
      <div className="icon">
        <SVG name={iconName} alt=""/>
      </div>
      <div className="title" data-cy={dataCyString + "-title"}>
        {title}
        {subtext &&
          <div className="subtext">
            {subtext}
          </div>
        }
        {
          imageUrls && hasProductImages(imageUrls) &&
          <div className="subtext product-image-container" data-cy="product-images">
            {
              imageUrls.map(function (imageUrl){
                return <div className="product-image" >
                  <img src={imageUrl} alt={imageUrl}/>
                </div>
              })}
          </div>
        }
      </div>
      {showArrow &&
        <div className="arrow">
          <SVG name="arrow"/>
        </div>
      }
    </$NavigationCard>
  )
}

export default NavigationCard;
