import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "../utility/logger/logger";
import rootReducer from './reducers';

const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composedEnhancers = composeWithDevTools(middlewareEnhancer);

const store = createStore(
  rootReducer,
  undefined, 
  composedEnhancers
);

const persistor = persistStore(store);

// for debugging, to manually purge the persisted store in the console call this function
declare global {
  interface Window {
    ENV:"local" | "development";
    cleanitup:any;
  }
}
if (window.ENV === "development") {
	window.cleanitup = () => {
		persistor.flush()
		persistor.purge()
	}
}

export { store, persistor};