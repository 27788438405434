import styled from "styled-components";
import $OptionCard from "../../components/OptionCard/styles";

const $ReturnReasonsChildren = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;

  & > div {
    margin-bottom: 16px;
  }
`;

export const $ReturnReasonsChildrenModal = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 0 -24px 16px;
    margin: 16px -16px;
    & > .selected-instance {
      padding: 0px 24px 16px !important;
    }
  }
  .selected-instance {
    margin: 16px -24px;
    padding: 0 24px 16px;
    display: flex;
    border-bottom: 8px solid var(--disabled-grey);
    .purchase-thumbnail {
      margin-right: 8px;
      &, img {
        width: 64px;
        height: 64px;
      }
      img {
        object-fit: contain;
      }
    }
    .purchase-info {
      display: flex;
      flex-direction: column;
      .purchase-details {
        font-size: 12px;
        line-height: 18px;
        color: var(--header-grey);
      }
    }
  }
  ${$OptionCard} {
    height: auto;
    margin: 8px 0;
    padding: 16px 24px;
    .title {
      font-size: 15px;
      line-height: 22px;
    }
    svg {
      width: 6.5px;
      height: 12px;
    }
  }
`;

export default $ReturnReasonsChildren
