import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import useSelector from "../../utility/useTypedSelector";
import logger from "../../utility/logger";
import { PageLifecycle } from "..";
import { RootReducer } from "../../redux/reducers";
import { PageProps } from "../types";
import {
  markItemForReturnCompletion,
  unmarkItemForReturnCompletion
} from "../../redux/customer/actions";
import {
  actions as globalGoogleAnalyticsActions
} from "../../redux/slices/globalGoogleAnalytics";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ReturnCountFooter from "../../components/ReturnCountFooter";
import InstanceButton from "../../components/InstanceButton";

import {
  $ItemListContentBox,
} from "./styles";
import { Store as CustomerStore } from "../../redux/customer/types";
import { Instance } from "../../types/Instance";
import InstanceDetailsModal from "../../components/Modal/InstanceDetailsModal";
import { DataCyStrings } from "../../types/DataCyStrings";
import { AppRuntimes } from "../../types/AppRuntimes";
import { ModalTemplate } from "./modals";

type modalKeys = "" | "too-many-items-selected" | "not-enough-items-selected";
const gaCountItemsPresentEventContent = {
  errorOccurred: "User encountered one or more errors while counting items"
}
class InstanceListLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(pageConfig, dispatch, app, customer ) {
    super(pageConfig, dispatch, app);
    this.customer = customer;
  }

  satisfiesPreconditions() {
    if (!super.satisfiesPreconditions()) return false;
    if (!this?.customer?.returns?.returning) return false;
    return true;
  }

  satisfiesPostconditions() {
    if (isEmpty(this.customer.itemsMarkedForCompletion)) return false;
    return true;
  }
}
const InstanceList = ({page}: PageProps) => {
  const dispatch = useDispatch();
  const { app, customer } = useSelector((store: RootReducer) => store);
  const { returns, itemsMarkedForCompletion, itemsPresent } = customer;
  const { runtime, platform } = app;
  const [instanceDetailsModalInstance, setInstanceDetailsModalInstance] = useState<Instance | undefined>(undefined);
  const [modalKey, setModalKey] = useState<modalKeys>("");
  const isDropship = !!customer.returns?.retailer?.multiVendorReturns;
  const lifecycle = new InstanceListLifecycle(page, dispatch, app, customer);

  const onSubmitItems = () => {
    if (itemsMarkedForCompletion.length > itemsPresent) {
      setModalKey("too-many-items-selected");
      dispatch(globalGoogleAnalyticsActions.incrementCountItemsPresentInstanceListError());
      return;
    } else if (itemsMarkedForCompletion.length < itemsPresent) {
      setModalKey("not-enough-items-selected");
      dispatch(globalGoogleAnalyticsActions.incrementCountItemsPresentInstanceListError());
      return;
    }

    lifecycle.advance();
  }

  //----------------------------------------------------------------------------
  // RENDERING
  const renderReturnItems = () => {
    return returns?.returning?.map((item) => {
      if (item.id == undefined) {
          return;
      }
        const isItemMarkedForCompletion = itemsMarkedForCompletion.includes(item.id);
        return (
          <InstanceButton
            key={item.id}
            item={item}
            onClick={() => { if (!isItemMarkedForCompletion) dispatch(markItemForReturnCompletion(item.id)) }}
            onDeselect={() => dispatch(unmarkItemForReturnCompletion(item.id))}
            isSelected={isItemMarkedForCompletion}
            onZoom={() => setInstanceDetailsModalInstance(item)}
            runtime={runtime}
            currentBrand={customer?.currentBrand}
            isDropship={isDropship}
            dataCyString={DataCyStrings.instanceButton}
          />
        )
      }
    );
  }

  const showReturnCountFooter = itemsMarkedForCompletion.length >= 1 || itemsPresent != undefined;
  return (
    <>
    <$ItemListContentBox className={runtime}>
    <div className="header" data-cy={DataCyStrings.instanceListHeader}>{runtime != AppRuntimes.returnista && "Which items are present?"}</div>
      <div className="list">
        <div className="return-info">
        <div className="retailer-name" data-cy={DataCyStrings.instanceListRetailerName}>
            {`Retailer: ${returns?.retailer?.name}`}
          </div>
          <div className="express-code" data-cy={DataCyStrings.instanceListExpressCode}>
            {`Express Code: ${returns?.confirmationCode}`}
          </div>
        </div>
        {renderReturnItems()}
      </div>
      {showReturnCountFooter && (
        <ReturnCountFooter
          count={itemsMarkedForCompletion.length}
          runtime={runtime}
          itemsPresentCount={itemsPresent}
          itemType={"count-items-present"}
        >
          <PrimaryButton
            disabled={isEmpty(itemsMarkedForCompletion)}
            onButtonClick={onSubmitItems}
            // if android, set button label to be "Next Step"
            width={`${platform ? '96px': '209px'}`}
            label={`${platform ? 'Next Step': 'Verify Items Match Return'}`}
            dataCyString={DataCyStrings.previewReturnButton}
          />
        </ReturnCountFooter>
      )}
    </$ItemListContentBox>
    <ModalSwitch modalKey={modalKey} />
    {instanceDetailsModalInstance != undefined &&
      <InstanceDetailsModal
        instance={instanceDetailsModalInstance}
        setInstance={setInstanceDetailsModalInstance}
        isDropship={isDropship}
      />
    }
    </>
  );
  //----------------------------------------------------------------------------
}
//==============================================================================

const ModalSwitch = ({ modalKey }:{
  modalKey: modalKeys,
}) => {
  switch(modalKey) {
    case "too-many-items-selected":
      return <ModalTemplate quantityKeyword="more" />
    case "not-enough-items-selected":
      return <ModalTemplate quantityKeyword="fewer" />
    default:
      return null;
  }
}

export default InstanceList;
