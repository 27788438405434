import styled from "styled-components";

const $ConfirmationScanTote = styled.div`
  --bold: 500; // normal bold (700) is TOO bold

  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;

  font-family: "sofia-pro", sans-serif;
  font-weight: 100;

  // rules pulled from PartnerConfirmation
  a {
    color: var(--link-blue);
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const $DeleteBagButton = styled.span`
  align-items: center;
  background-color: var(--background-white);
  border-radius: 8px;
  color: var(--high-emphasis-black);
  display: flex;
  height: 44px;
  margin-left: auto;
  opacity: 100%;
  width: 300px;

  span.bag-barcode-text {
    font-size: 0.9rem;
    margin: 2.5px auto 0 auto;
    opacity: 100%;
  }

  svg {
    opacity: 100%;
  }

  svg.green-check-icon {
    height: 14px;
    margin: 0 auto;
    opacity: 100%;
    width: 14px;
  }

  svg.x {
    cursor: pointer;
    height: 18px;
    margin: 0 auto;
    opacity: 90%;
    width: 18px;
  }
`;

export const $Divider = styled.div`
  border: 1px solid var(--border-grey);
`;

export const $Footer = styled.div`
  margin-top: auto;
  width: calc(100% + 48px);

  a {
    color: var(--text-black);
    margin-left: calc(100% - 600px);
  }
`;

export const $Prompt = styled.div`
  margin-left: 48px;
  margin-top: 24px;
`;

export const $ScanBagCard = styled.div<{ isActive: boolean }>`
  border: ${(props) => (props.isActive ? "2px solid var(--neutral-grey)" : "1px solid var(--border-card)")};
  border-radius: 12px;
  margin: 24px 0;
  padding: ${(props) => (props.isActive ? "24px" : "16px")} 24px;
  width: 95vw;
`;

export const $ScanBagCardHeader = styled.div<{ isActive: boolean }>`
  display: flex;
  margin-bottom: ${(props) => (props.isActive ? "18px" : "0px")};

  div {
    align-self: center;
    // align-self: center should vertically center the text, but we have issues w/ our current font
    // TODO re-evaluate rules during/after completion of RF-425
    margin-top: 5.5px;
    margin-left: 16px;
    opacity: ${(props) => (props.isActive ? "100%" : "10%")};

    font-weight: var(--bold);
  }

  svg {
    align-self: center;
    height: 32px;
    opacity: ${(props) => (props.isActive ? "100%" : "10%")};
    width: 32px;
  }

  // for the button, the value of isActive is used purely for styling, not functionality
  button {
    align-items: center;
    background-color: var(--high-emphasis-black);
    color: var(--primary-white);
    margin-left: auto;

    font-size: 0.9rem;
    font-weight: 100;
  }
`;

export const $ScanToteCard = styled.div<{ isActive: boolean }>`
  border: ${(props) => (props.isActive ? "2px solid var(--neutral-grey)" : "1px solid var(--border-card)")};
  border-radius: 12px;
  opacity: ${(props) => (props.isActive ? "100%" : "50%")};
  padding: ${(props) => (props.isActive ? "24px" : "16px")} 24px;
  width: 95vw;
`;

export const $ScanToteCardHeader = styled.div<{ isActive: boolean }>`
  display: flex;
  margin-bottom: 18px;
  opacity: 100%;

  div {
    align-self: center;
    // align-self: center should vertically center the text, but we have issues w/ our current font
    // TODO re-evaluate rules during/after completion of RF-425
    margin-top: 5.5px;
    margin-left: 16px;

    font-weight: var(--bold);
  }

  svg {
    align-self: center;
    height: 32px;
    width: 32px;
  }

  // for the button, the value of isActive is used purely for styling, not functionality
  button {
    align-items: center;
    background-color: ${(props) => (props.isActive ? "var(--high-emphasis-black)" : "var(--disabled-surface-white)")};
    color: ${(props) => (props.isActive ? "var(--primary-white)" : "var(--high-emphasis-black)")};
    margin-left: auto;
    opacity: 100%;

    font-size: 0.9rem;
    font-weight: 100;
  }
`;

export const $SubPrompt = styled.div`
  color: var(--medium-emphasis-black);
  margin: 10px 0;

  font-size: 0.9rem;

  a {
    color: var(--medium-emphasis-black);
  }
`;

export default $ConfirmationScanTote;
