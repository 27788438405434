import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import { PageProps } from "../types";
import useSelector from "../../utility/useTypedSelector";
import Step from "../../components/Step";
import BarcodeWidget from "../../components/BarcodeWidget";
import PrimaryButton from "../../components/Button/PrimaryButton";;
import ReturnCountFooter from "../../components/ReturnCountFooter";
import { addReturnBagBarcode } from "../../redux/customer/actions";
import { PageLifecycle } from "../"
import { goToPage, showAlert } from "../../redux/app/actions";
import {
  getReturnistaJWTClaims,
  handleEnterKeyPressOnClickHandlers,
  handlePluralItemText,
  openSwiftCameraScanner,
  validateBagBarcode,
  removeSpecialCharacter
} from "../../utility";
import logger from "../../utility/logger";


import $Confirmation from "./styles";
import ConfirmationEmail from "../../components/ConfirmationEmail";
import { removeReturnBagBarcode } from "../../redux/customer/actions";
import { Alerts } from "../../types/LifeCycle";
import { Store as CustomerStore } from "../../redux/customer/types";
import { DataCyStrings } from "../../types/DataCyStrings";
import StepMobile from "../../components/StepMobile";
import ModalContainer from "../../components/Modal/ModalContainer";
import { SVG } from "../../components/svg";

//==============================================================================
class ConfirmationLifecycle extends PageLifecycle {
  customer: CustomerStore

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }

  satisfiesPreconditions() {
    if (!super.satisfiesPreconditions()) return false
    if (!this.customer.returns || !this.customer.returns.returning) return false
    logger.Info("Preconditions satisfied. Rendering Confirmation...");
    return true
  }
}
//==============================================================================

/**
 * Scan bag page used exclusively with returnista
 *
 * Displays a list of bags related to a return, this page will either
 * advance to the return complete page or the scan bag page
 */
const Confirmation = ({page}: PageProps) => {
  const dispatch = useDispatch();

  const { customer, app } = useSelector(store => store);

  const { barcodes, newReturnID } = customer;
  const { runtime, currentPageName, token, platform } = app;
  const claims = getReturnistaJWTClaims(token);

  const [ barcode, setBarcode ] = useState("");
  const [isExampleModalOpen, setIsExampleModalOpen] = useState<boolean>(false);
  
  const openExampleModalHandlers = handleEnterKeyPressOnClickHandlers(() => setIsExampleModalOpen(true));
  const closeModalHandlers = handleEnterKeyPressOnClickHandlers(() => setIsExampleModalOpen(false));
  const openCameraHandlers = handleEnterKeyPressOnClickHandlers(() => openSwiftCameraScanner("bagBarcode", window.enterBarcodeManually));


  const lifecycle = new ConfirmationLifecycle(page, dispatch, app, customer);


  useEffect(() => {
    if (barcode === "") {
      return;
    }
    // if bag doesn't pass validation show invalid bag barcode
    if (!validateBagBarcode(barcode)) {
      dispatch(showAlert(Alerts.invalidBagBarcode));
      setBarcode("");
      return;
    }
    // if we found duplicate show already bag scanned
    if (barcodes.find(x => x === barcode)) {
      dispatch(showAlert(Alerts.bagAlreadyScanned));
      setBarcode("");
      return;
    }
    // attempt to add barcode to return
    dispatch(addReturnBagBarcode(barcode, runtime, currentPageName))
    setBarcode("")
  }, [barcode])

  useEffect(() => {
    window.setBagBarcode = (barcode) => {
      if (barcode) {
        setBarcode(
          removeSpecialCharacter(
            barcode.toUpperCase()
          )
        );
      }
    }

    window.enterBarcodeManually = () => {
      dispatch(goToPage("typeBagBarcode"));
    }

    window.goBack = () => {
      setBarcode("")
    }
  }, [])

  const finishReturn = () => {
    lifecycle.advance();
  }

  const renderBarcodes = () => {
    return barcodes.map(barcode => {
      return (
        <BarcodeWidget
          barcodes={barcodes}
          barcode={barcode}
          removeReturnBagBarcode={(barcode) => dispatch(removeReturnBagBarcode(barcode))}
        />
      )
    })
  }

  const renderFullscreenExampleModal = () => (
    isExampleModalOpen ?
      (<div className="fullscreen-example-modal">
        <div className="x" {...closeModalHandlers} data-cy={DataCyStrings.closeModalButton}>
          <SVG name="x"></SVG>
        </div>
        <div className="title">Happy Returns Polybag</div>
        <div className="message">Happy Returns bags may be staged behind the counter.</div>
        <img
          src="/public/img/scanBagExample.png"
          alt="Scan bag example"
          data-cy={DataCyStrings.scanBarcodeExampleImage}
        />
      </div>) : null
  )

  const renderConfirmationPageStepOne = () => {
    return (
      <$Confirmation className={runtime}>
        {renderFullscreenExampleModal() ||
          <>
            <div className="card">
              <StepMobile
                svgName="one"
                prompt="Bag All Items and Scan"
                prompt2={
                  <>
                    <div className="instructions">Place item(s) into a Happy Returns bag and scan the label.</div>
                    <div className="see-example">
                      <a {...openExampleModalHandlers} data-cy={DataCyStrings.seeExampleBagBarcodeLink}>
                        See Example of Label
                      </a>
                    </div>
                    <PrimaryButton
                      label={barcodes.length === 0 ? "Scan Bag" : "Scan Another Bag"}   
                      onButtonClick={() => openSwiftCameraScanner("bagBarcode", window.enterBarcodeManually)}
                      width="100%"
                      dataCyString={DataCyStrings.scanBarcodeButton}
                    />
                    <div className="subtext">Use multiple bags, if necessary.</div>
                  </>
                }
              />
            </div>
            <div className="barcode-list">{renderBarcodes()}</div>
            <div className="divider" />
            <div className="footer">
              <ReturnCountFooter count={barcodes.length} itemType="bag-android" runtime={runtime}>
                <PrimaryButton
                  label="Finish Return"
                  disabled={barcodes.length <= 0}
                  onButtonClick={finishReturn}
                  width="121px"
                  dataCyString={DataCyStrings.finishReturnButton}
                />
              </ReturnCountFooter>
            </div>
        </>
        }

      </$Confirmation>
    )
  }

  const renderScannedBags = () => (
    barcodes.map((barcode) => {
      const removeBarcodeHandlers = handleEnterKeyPressOnClickHandlers(() => dispatch(removeReturnBagBarcode(barcode)));
      return (
        <div className="barcode" key={barcode} data-cy={DataCyStrings.bagBarcode}>
          <SVG name="green-check-icon" className="check" />
          {`Bag ${barcode} Added`}
          <div className="x" {...removeBarcodeHandlers} data-cy={DataCyStrings.deleteBag}>
            <SVG name="x" role="application" />
          </div>
        </div>
      );
    })
  )

  const renderScannedBagsPage = () => {
    return (
      <$Confirmation className={runtime}>
        <div className="scanned-bags-container">
          {renderScannedBags()}
        </div>
        <div
          className="scan-another-bag"
          data-cy={DataCyStrings.scanAnotherBagButton}
          {...openCameraHandlers}
        >
        <SVG name="plus" className="plus" />
          Scan Another Bag
        </div>
        <div className="footer">
          <ReturnCountFooter count={barcodes.length} itemType="bag-android" runtime={runtime}>
            <PrimaryButton
              label="Finish Return"
              disabled={barcodes.length <= 0}
              onButtonClick={finishReturn}
              width="121px"
              dataCyString={DataCyStrings.finishReturnButton}
            />
          </ReturnCountFooter>
        </div>
      </$Confirmation>
    );
  }

  // android confirmation page
  if (platform) {
    // if no barcodes have been scanned, render the view that shows step 1 to add items to bags
    // if barcodes have been scanned, render the view with all the bags that have been scanned
    return barcodes.length ?  renderScannedBagsPage() : renderConfirmationPageStepOne()
  }

  return (
    <$Confirmation className={runtime}>
      <div className="card">
        <Step
          imgName="one"
          prompt={<>
            <b>Tell the customer:</b> "Your return is complete and you’ll receive an email receipt from Happy Returns. {!claims?.returnsApp?.couponEnabled && "You’re free to go"}
            {!!claims?.returnsApp?.couponEnabled && <div className="use-coupon-banner">Remind the customer their receipt contains a coupon for your store.</div>}
            <ConfirmationEmail returnID={newReturnID} />
          </>}
        />
      </div>
      <div className="card with-button">
        <div className="step-container">
          <Step
            imgName={"two"}
            prompt={<>Place item(s) into a <b>Happy Returns bag</b> and scan the label.</>}
            detail={"Use multiple bags, if necessary."}
          />
          <PrimaryButton
            label={barcodes.length === 0 ? "Scan Bag" : "Scan Another Bag"}
            onButtonClick={() => openSwiftCameraScanner("bagBarcode", window.enterBarcodeManually)}
            width="165px"
            dataCyString={DataCyStrings.scanBarcodeButton}
          />
        </div>
        <div className="barcode-list">
          {renderBarcodes()}
        </div>
      </div>
      <div className="divider"/>
      <div className="footer">
      <ReturnCountFooter
        count={barcodes.length}
        itemType='bag'
        runtime={runtime}
      >
          <PrimaryButton
            label="Finish Return"
            disabled={barcodes.length <= 0}
            onButtonClick={finishReturn}
            width="121px"
            dataCyString={DataCyStrings.finishReturnButton}
          />
        </ReturnCountFooter>
      </div>
    </$Confirmation>
  )

}

export default Confirmation;
