import React from "react";
import Content from "../../components/ReturnistaContent";
import Header, { HeaderLogo } from "../../components/ReturnistaHeader";
import { useSelector } from "react-redux";
import { RootReducer } from "../../redux/returnista-store";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import { $ContentWrapper } from "./styles";
import { RuntimeType, TerminalReason } from "../../redux/enums";
import * as messages from "../../messages";

type TerminalMessage = {
  primaryMessage: string;
  subMessage: string;
};

export const getTerminalMessage = (reason: TerminalReason): TerminalMessage => {
  switch (reason) {
    case TerminalReason.ReturnComplete:
      return { primaryMessage: messages.returnComplete, subMessage: messages.youMayCloseThisTab };
    case TerminalReason.ReturnCancelled:
      return { primaryMessage: messages.returnCancelled, subMessage: messages.youMayCloseThisTab };
    case TerminalReason.SessionTimeout:
      return { primaryMessage: messages.sessionExpiredTitle, subMessage: messages.youMayCloseThisTab };
    case TerminalReason.NotAssociated:
      return {
        primaryMessage: messages.codeNotAssociatedHappyReturns,
        subMessage: messages.tryAnotherCodeOrNewBeginReturn,
      };
    case TerminalReason.RmaPartialRefund:
      return {
        primaryMessage: messages.codeNotEligibleForBoxFreeReturn,
        subMessage: messages.startNewReturnOrContactRetailer,
      };
    case TerminalReason.RmaExpiredReturn:
      return {
        primaryMessage: messages.codeNotEligibleForBoxFreeReturnTitle,
        subMessage: messages.startNewReturnOrContactRetailer,
      };
    case TerminalReason.AlreadyComplete:
      return { primaryMessage: messages.returnAlreadyComplete, subMessage: messages.allItemsReturnedApproved };
    case TerminalReason.TemporarilyUnavailable:
      return { primaryMessage: messages.happyReturnsUnvailable, subMessage: messages.issueLoadingHappyReturns };
    case TerminalReason.InactiveLocation:
      return { primaryMessage: messages.inactiveLocationTitle, subMessage: messages.inactiveLocationMessage };
    case TerminalReason.RmaInvalid:
      return {
        primaryMessage: messages.boxFreeReturnNotAllowedTitle,
        subMessage: messages.boxFreeReturnNotAllowedMessage,
      };
    case TerminalReason.ReturnBarIneligible:
      return {
        primaryMessage: messages.codeNotEligibleForReturnBarReturn,
        subMessage: messages.boxFreeReturnNotAllowedMessage,
      }
    case TerminalReason.GenericError:
    default:
      return { primaryMessage: messages.genericErrorTitle, subMessage: messages.genericErrorMessage };
  }
};

const ReturnistaCodeScan = () => {
  const { fatalError, runtime } = useSelector<RootReducer, RootReducer>((store) => store);

  const terminalMessage = getTerminalMessage(fatalError);
  const showButton = runtime === RuntimeType.Partner;

  const handleCloseButton = () => {
    window.close();
  };

  return (
    <>
      <Header center={<HeaderLogo />} />
      <Content>
        <$ContentWrapper>
          <ReturnistaFeedbackIcon status="error" />
          <div data-cy="fatal-error-header" className="header">
            {terminalMessage.primaryMessage}
          </div>
          <div data-cy="fatal-error-message" className="message">
            {terminalMessage.subMessage}
          </div>
          {showButton && (
            <ReturnistaPrimaryButton onClick={handleCloseButton} className="button" dataCyString="fatalErrorCloseTab">
              Close Tab
            </ReturnistaPrimaryButton>
          )}
        </$ContentWrapper>
      </Content>
    </>
  );
};

export default ReturnistaCodeScan;
