import React, { ReactNode } from "react";
import Button from "../";
import { DataCyStrings } from "../../../types/DataCyStrings";
import { getTextColorFromBackgroundBrightness } from "../../../utility";
import useSelector from "../../../utility/useTypedSelector";

// a button used for primary (main) actions on a page or other component
type PrimaryButtonTypes = {
  // text that appears on button
  label?: string,

  // provide this to override default width
  width?: string,

  // callback, triggered when button pressed
  onButtonClick?: Function,

  // if set to true, button will not be clickable
  disabled?: boolean,

  bgColor?: string,

  children?: ReactNode,

  dataCyString?: DataCyStrings
};
const PrimaryButton = ({
  label,
  width,
  onButtonClick,
  disabled,
  bgColor,
  children,
  dataCyString=DataCyStrings.primaryButton
}: PrimaryButtonTypes) => {
  const { app } = useSelector(store => store);

  const colors = app?.colors

  const textColor = colors?.primaryColor ? getTextColorFromBackgroundBrightness(colors.primaryColor) : "white"
  return (
    <Button
      onButtonClick={() => {
        onButtonClick?.()
      }}
      text={label}
      width={width || "408px"}
      height="44px"
      bgColor={bgColor || "var(--color-primary)"}
      fontColor={textColor}
      borderColor={bgColor || "var(--color-primary)"}
      hoverBgColor="#333333"
      hoverBorderColor="#333333"
      fontSize="16px"
      disabled={disabled}
      borderRadius="8px"
      dataCyString={dataCyString}
    >{children}</Button>
)};

export default PrimaryButton;
