import styled from "styled-components";
import { $Background, $Container } from "../../components/LoadingIndicator/styles";

const $ShippingLabelRequestForm = styled.div`
  max-width: 550px;
  margin-top: 96px;
  padding: 0px 12px;
  h1 {
    line-height: 40px;
    font-weight: 500;
    font-size: 34px;
    text-align: center;
    margin: 0 0 8px;
  }
  h2 {
    font-size: 20px;
    font-weight: 200;
    line-height: 24px;
    color: var(--medium-emphasis-black);
    text-align: center;
    margin: 0px auto 32px;
  }
  &.error {
    .counter-container {
      border: 1px solid var(--warning-bold);
    }
    .subtext {
      color: var(--warning-bold);
    }
    #counter {
      color: var(--warning-bold);
    }
  }

  // override loading indicator styles
  .MuiCircularProgress-colorPrimary {
    color: var(--high-emphasis-black) !important;
  }
  ${$Background} {
    background-color: transparent;
    justify-content: unset;
    margin-top: 192px !important;
  }
  ${$Container} {
    color: var(--high-emphasis-black);
    font-weight: 400;
  }
`;

export default $ShippingLabelRequestForm;
