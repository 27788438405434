import { useEffect, useState } from "react";

export const useMediaQuery = (query: string) => {
  const media = window.matchMedia(query);
  const [matches, setMatches] = useState<boolean>(media.matches);

  useEffect(() => {
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  });

  return matches;
};
