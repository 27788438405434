import React from "react";

const InfoBlack = (props) => (
  <>
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00029 0.599976C10.5351 0.599976 13.4006 3.46547 13.4006 7.0003C13.4006 10.5345 10.5351 13.4 7.00029 13.4C3.46547 13.4 0.599976 10.5345 0.599976 7.0003C0.599976 3.46547 3.46547 0.599976 7.00029 0.599976ZM7.00029 1.55983C6.28092 1.55203 5.56714 1.68698 4.90027 1.95687C4.2334 2.22677 3.62668 2.62624 3.11522 3.13218C2.60377 3.63812 2.19773 4.24047 1.92062 4.90438C1.64351 5.56828 1.50083 6.28056 1.50083 6.99998C1.50083 7.71939 1.64351 8.43166 1.92062 9.09557C2.19773 9.75948 2.60377 10.3618 3.11522 10.8678C3.62668 11.3737 4.2334 11.7732 4.90027 12.0431C5.56714 12.313 6.28092 12.4479 7.00029 12.4401C8.42857 12.4182 9.79094 11.8355 10.7932 10.8177C11.7955 9.79989 12.3573 8.42874 12.3573 7.0003C12.3573 5.57185 11.7955 4.2007 10.7932 3.18292C9.79094 2.16514 8.42857 1.58238 7.00029 1.56047V1.55983ZM6.99774 6.03916C7.11382 6.03901 7.22602 6.08094 7.31356 6.15718C7.4011 6.23342 7.45804 6.33881 7.47382 6.45382L7.4783 6.51909L7.48022 10.0398C7.48019 10.1614 7.43399 10.2785 7.35098 10.3673C7.26797 10.4562 7.15432 10.5102 7.03301 10.5185C6.91169 10.5268 6.79175 10.4887 6.69743 10.412C6.6031 10.3353 6.54142 10.2256 6.52485 10.1051L6.52037 10.0405L6.51845 6.51973C6.51845 6.39244 6.56901 6.27037 6.65901 6.18037C6.74902 6.09036 6.87109 6.0398 6.99837 6.0398L6.99774 6.03916ZM7.00029 3.80205C7.0859 3.79935 7.17118 3.81387 7.25107 3.84476C7.33096 3.87565 7.40382 3.92228 7.46534 3.98188C7.52685 4.04148 7.57576 4.11283 7.60917 4.1917C7.64257 4.27057 7.65978 4.35535 7.65978 4.441C7.65978 4.52665 7.64257 4.61143 7.60917 4.6903C7.57576 4.76917 7.52685 4.84052 7.46534 4.90012C7.40382 4.95972 7.33096 5.00635 7.25107 5.03724C7.17118 5.06813 7.0859 5.08265 7.00029 5.07994C6.83434 5.07469 6.67693 5.00507 6.5614 4.88582C6.44586 4.76657 6.38125 4.60704 6.38125 4.441C6.38125 4.27496 6.44586 4.11543 6.5614 3.99618C6.67693 3.87693 6.83434 3.80731 7.00029 3.80205Z"
        fill="#221F1F"
      />
    </svg>
  </>
);

export default InfoBlack;
