import styled from "styled-components";
import $PageContentBox from "../PageContentBox/styles";

const $InstanceListBase = styled($PageContentBox as any)`
  @media screen and (max-width: 768px){
    &.ios {
      *:focus {
        outline: 0;
        outline: none;
      }
      .header {
        display: none;
      }
      .list {
        .return-info, .order-info {
          .retailer-name, .express-code, .query {
            font-size: 16px;
          }
        }
      }
    }
  }
  .header {
    font-size: 18px;
    font-weight: 800;
    line-height: 18.02px;
    padding: 23px 0px 23px 0px;
    margin-bottom: 24px;
    text-align: center;
    border-bottom: 1px solid #E7E7E7;
    color: #221F1F;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list {
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 0 32px 12px 32px;
    .return-info, .order-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;
      .retailer-name, .order-number {
        font-weight: bold;
        color: #221F1F;
      }
      .express-code, .order-date {
        font-weight: 500;
        color: #737373;
        font-size: 14px;
      }
     }
  }
  .header:empty {
    height: 0;
    padding: 0;
    border: none;
  }
  .reminder {
    font-size: 21px;
    line-height: 33px;
    margin-bottom: 32px;
    text-align: center;
  }
  
  .android & {
    .list {
      padding-bottom: 90px;
      .return-info {
        line-height: 24px;
        flex-direction: column;
        .retailer-name {
          font-size: 14px;
          font-weight: 400;
        }
        .express-code {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .reminder {
      font-size: 13px;
      line-height: 16px;
      margin: 0 24px 16px
    }
  }
`;

export default $InstanceListBase;