import React, { useEffect } from "react";
import Header from "../../components/ReturnistaHeader";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { $Container, $Title, $FollowUp, $IconWrapper } from "./styles";
import Content from "../../components/ReturnistaContent";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import { printLabels, validatePrintStatus } from "../../utility";
import { CurrentPageAction, LoadingAction, ModalAction } from "../../redux/enums";
import logger from "../../utility/logger/logger";
import ga from "../../utility/GAEmitter";
import { PrintBagLabelsActions } from "../../types/ReturnistaGA";

const ReturnistaPrintBagLabels = () => {
  const { bags, currentPage } = useSelector((state: RootStateOrAny) => state);
  const dispatch = useDispatch();

  const initCefSharp = async () => {
    // instantiate ishipshell
    if ("CefSharp" in window) {
      await CefSharp.BindObjectAsync("ishipshell", "bound");
    } else {
      console.log("not in cefsharp");
    }
  };

  const renderErrorModal = () => {
    dispatch({
      type: ModalAction.Set,
      modalProps: {
        primaryMessage: "Failed to print",
        subMessages: ["We're having trouble printing labels. Please check the printer and try again."],
        onRequestClose: () => dispatch({ type: ModalAction.Unset }),
        button: (
          <ReturnistaPrimaryButton onClick={() => dispatch({ type: ModalAction.Unset })} dataCyString="close-modal">
            Try again
          </ReturnistaPrimaryButton>
        ),
        iconElement: (
          <$IconWrapper>
            <ReturnistaFeedbackIcon status="error" />
          </$IconWrapper>
        ),
      },
    });
  };

  const onPrintSuccess = () => {
    dispatch({ type: LoadingAction.Unset });
    dispatch({ type: CurrentPageAction.Next });
  };

  /**
   * onHrBagLabelsPrintStatus is a function designed to be available at the top level of the DOM
   * so it can be called by UPS's CMS shell window. it should call the onPrintSuccess function
   * when the print is successful.
   *
   * @param printStatusJson {string}
   */
  const onHrBagLabelsPrintStatus = (printStatusJson) => {
    validatePrintStatus(printStatusJson);
    onPrintSuccess();
  };

  const handlePrint = async () => {
    dispatch({ type: LoadingAction.Set, message: "Printing" });
    ga.pageEvent({ category: currentPage, actions: PrintBagLabelsActions.PrintBagLabelsClicked });

    const eventHandlerName = "onHrBagLabelsPrintStatus";

    // expose the onHrBagLabelsPrintStatus callback globally, to be used by UPS's shell window
    Object.defineProperty(window, eventHandlerName, {
      value: onHrBagLabelsPrintStatus,
    });

    try {
      // the onHrBagLabelsPrintStatus will handle a successful print
      await printLabels(bags, eventHandlerName, onPrintSuccess);
    } catch (e) {
      dispatch({ type: LoadingAction.Unset });

      logger.Info(`Print Labels Error: ${e}`);
      console.log(e); // also being logged because the partner are not using our logger

      renderErrorModal();
    }
  };

  useEffect(() => {
    initCefSharp();
  }, []);

  return (
    <>
      <Header center="Print bag labels" />
      <Content>
        <$Container>
          <ReturnistaFeedbackIcon status="success" />
          <$Title>
            <p>Return submitted</p>
          </$Title>
          <$FollowUp>
            A separate label will be printed for <br /> each bag used.
          </$FollowUp>
          <ReturnistaPrimaryButton onClick={handlePrint} dataCyString="print-labels-button">
            Print bag labels
          </ReturnistaPrimaryButton>
        </$Container>
      </Content>
    </>
  );
};

export default ReturnistaPrintBagLabels;
