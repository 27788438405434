import { CurrentPage, CurrentPageAction, MultiStoreAction } from "../enums";

const initializerReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.GoToLogin:
      return CurrentPage.Login;
    case CurrentPageAction.GoToBagScan:
      return CurrentPage.BagCodeScan;
    case CurrentPageAction.GoToBagCodeComplete:
      return CurrentPage.BagCodeComplete;
    default:
      return state;
  }
};

const loginPageReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.StartReturn;
    default:
      return state;
  }
};

const startReturnReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ReturnCodeScan;
    case CurrentPageAction.Back:
      return CurrentPage.Login;
    default:
      return state;
  }
};

const returnCodeScanReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ReturnCodeComplete;
    case CurrentPageAction.Back:
      return CurrentPage.StartReturn;
    default:
      return state;
  }
};

const returnCodeCompleteReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.BagCodeScan;
    case CurrentPageAction.Back:
      return CurrentPage.ReturnCodeScan;
    case CurrentPageAction.SupportConfirmation:
      return CurrentPage.SupportConfirmation;
    default:
      return state;
  }
};

const bagCodeScanReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.BagCodeComplete;
    case CurrentPageAction.Back:
      return CurrentPage.ReturnCodeComplete;
    case CurrentPageAction.BackToSummary:
      return CurrentPage.ReturnSummary;
    case CurrentPageAction.SupportConfirmation:
      return CurrentPage.SupportConfirmation;
    default:
      return state;
  }
};

const bagCodeCompleteReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ItemCodeScan;
    case CurrentPageAction.Back:
      return CurrentPage.BagCodeScan;
    case CurrentPageAction.SelectItem:
      return CurrentPage.ItemSelector;
    default:
      return state;
  }
};

const itemCodeScanReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ReturnSummary;
    case CurrentPageAction.Back:
      return CurrentPage.BagCodeComplete;
    case CurrentPageAction.BackToSummary:
      return CurrentPage.ReturnSummary;
    case CurrentPageAction.SelectItem:
      return CurrentPage.ItemSelector;
    default:
      return state;
  }
};

const itemSelectorReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ItemDetails;
    case CurrentPageAction.Back:
      return CurrentPage.ItemCodeScan;
    case CurrentPageAction.NewItem:
      return CurrentPage.BagCodeComplete;
    case CurrentPageAction.BackToSummary:
      return CurrentPage.ReturnSummary;
    default:
      return state;
  }
};

const itemDetailsReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ReturnSummary;
    case CurrentPageAction.Back:
      return CurrentPage.ItemSelector;
    default:
      return state;
  }
};

const returnSummaryReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ReturnAccepted;
    case CurrentPageAction.NewBag:
      return CurrentPage.BagCodeScan;
    case CurrentPageAction.NewItem:
      return CurrentPage.ItemCodeScan;
    case CurrentPageAction.GoToBagCount:
      return CurrentPage.BagCount;
    default:
      return state;
  }
};

const bagCountReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Back:
      return CurrentPage.ReturnSummary;
    case CurrentPageAction.Next:
      return CurrentPage.PrintBagLabels;
    default:
      return state;
  }
};

const printBagLabelsReducer = (state, action) => {
  switch (action.type) {
    case CurrentPageAction.Next:
      return CurrentPage.ReturnAccepted;
    default:
      return state;
  }
};

const returnAcceptedReducer = (state, action) => {
  switch (action.type) {
    // Neither of these actions should return to start page
    // but should instead close the page or similar
    case CurrentPageAction.Back:
      return CurrentPage.StartReturn;
    case CurrentPageAction.Restart:
      return CurrentPage.StartReturn;
    default:
      return state;
  }
};

const supportConfirmationReducer = (state, action) => {
  switch (action.type) {
    // Neither of these actions should return to start page
    // but should instead close the page or similar
    case CurrentPageAction.Back:
      return CurrentPage.StartReturn;
    case CurrentPageAction.Restart:
      return CurrentPage.StartReturn;
    default:
      return state;
  }
};

const currentPageReducer = (state = CurrentPage.Initializer, action) => {
  // Top-level override to enable logging out regardless of the current page
  if (action.type === MultiStoreAction.AppLogout) {
    return CurrentPage.Login;
  }
  // Top-level override to enable returning to the start page
  if (action.type === MultiStoreAction.AppReset) {
    return CurrentPage.StartReturn;
  }
  // Top-level override for fatal errors
  if (action.type === CurrentPageAction.FatalError) {
    return CurrentPage.FatalError;
  }

  switch (state) {
    case CurrentPage.Initializer:
      return initializerReducer(state, action);
    case CurrentPage.Login:
      return loginPageReducer(state, action);
    case CurrentPage.StartReturn:
      return startReturnReducer(state, action);
    case CurrentPage.ReturnCodeScan:
      return returnCodeScanReducer(state, action);
    case CurrentPage.ReturnCodeComplete:
      return returnCodeCompleteReducer(state, action);
    case CurrentPage.BagCodeScan:
      return bagCodeScanReducer(state, action);
    case CurrentPage.BagCodeComplete:
      return bagCodeCompleteReducer(state, action);
    case CurrentPage.ItemCodeScan:
      return itemCodeScanReducer(state, action);
    case CurrentPage.ItemSelector:
      return itemSelectorReducer(state, action);
    case CurrentPage.ItemDetails:
      return itemDetailsReducer(state, action);
    case CurrentPage.ReturnSummary:
      return returnSummaryReducer(state, action);
    case CurrentPage.BagCount:
      return bagCountReducer(state, action);
    case CurrentPage.PrintBagLabels:
      return printBagLabelsReducer(state, action);
    case CurrentPage.ReturnAccepted:
      return returnAcceptedReducer(state, action);
    case CurrentPage.SupportConfirmation:
      return supportConfirmationReducer(state, action);
    default:
      return state;
  }
};

export default currentPageReducer;
