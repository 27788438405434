import { combineReducers, createStore } from "redux";
import { useSelector as rrUseSelector, TypedUseSelectorHook } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { ModalContainerProps } from "../components/Modal/ModalContainer/type";
import bagsReducer, { type ReducerState as BagsReducerState } from "./returnistaReducers/bags";
import currentPageReducer from "./returnistaReducers/currentPage";
import returnObjectReducer, { type ReducerState as ReturnObjectState } from "./returnistaReducers/returnObject";
import LocationObjectReducer, { LocationObjectState } from "./returnistaReducers/locationObject";
import currentItemReducer from "./returnistaReducers/currentItem";
import currentBagReducer from "./returnistaReducers/currentBag";
import fakeImagesReducer from "./returnistaReducers/fakeImages";
import userTokenReducer from "./returnistaReducers/userToken";
import loadingReducer from "./returnistaReducers/loading";
import runtimeReducer from "./returnistaReducers/runtime";
import modalReducer from "./returnistaReducers/modal";
import fatalErrorReducer from "./returnistaReducers/fatalError";
import invalidItemCodesReducer from "./returnistaReducers/invalidItemCodes";
import itemsReducer, { type ReducerState as ItemsReducerState } from "./returnistaReducers/items";
import { CurrentPage, TerminalReason } from "./enums";
import barcodeReducer from "./returnistaReducers/barcode";
import noMatchWarningReducer from "./returnistaReducers/noMatchWarning";
import puzzleAttemptsReducer from "./returnistaReducers/puzzleAttempts";
import eventsReducer, { eventType } from "./returnistaReducers/events";
import featureFlagsReducer from "./returnistaReducers/featureFlags";

type LoadingState = {
  isLoading: boolean;
  message: string;
};

export interface RootReducer {
  puzzleAttempts: number;
  barcode: string;
  currentPage: CurrentPage;
  currentBag: string;
  currentItem: string;
  items: ItemsReducerState;
  bags: BagsReducerState;
  returnObject: ReturnObjectState;
  locationObject: LocationObjectState;
  fakeImages: Array<string>;
  userToken: null | string;
  loading: LoadingState;
  runtime: string;
  modal: ModalContainerProps;
  fatalError: TerminalReason;
  noMatchWarning: boolean;
  invalidItemCodes: Array<string>;
  events: Array<eventType>;
  featureFlags: { [key: string]: boolean };
}

const reducers = combineReducers({
  puzzleAttempts: puzzleAttemptsReducer,
  noMatchWarning: noMatchWarningReducer,
  barcode: barcodeReducer,
  currentPage: currentPageReducer,
  currentBag: currentBagReducer,
  currentItem: currentItemReducer,
  items: itemsReducer,
  bags: bagsReducer,
  returnObject: returnObjectReducer,
  locationObject: LocationObjectReducer,
  fakeImages: fakeImagesReducer,
  userToken: userTokenReducer,
  loading: loadingReducer,
  runtime: runtimeReducer,
  modal: modalReducer,
  fatalError: fatalErrorReducer,
  invalidItemCodes: invalidItemCodesReducer,
  events: eventsReducer,
  featureFlags: featureFlagsReducer,
});

const rootReducer = (state, action) => {
  return reducers(state, action);
};

const store = createStore(rootReducer, composeWithDevTools());
const useSelector: TypedUseSelectorHook<RootReducer> = rrUseSelector;

export { store, useSelector };
