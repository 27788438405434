import styled from 'styled-components';

export const $Home = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;

  .error {
    width: 720px;
    margin-top: 24px;
    & > div {
      width: 100%;
    }
  }

  &, .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-info {
    width: 300px;
    margin-top: 144px;
    button {
      height: 56px;
    }
    svg {
      margin-bottom: 24px;
    }
    .name {
      font-weight: 500;
      font-size: 32px;
      /* TODO - if this color is reused, set it to a var */
      color: #221F1F;
      margin-bottom: 8px;
    }
    .info {
      width: 250px;
      font-size: 21px;
      line-height: 32px;
      text-align: center;
      color: var(--header-grey);
      margin-bottom: 40px;
    }
  }

  .android & {
    padding: 12px 24px 24px;
    .error {
      max-width: 720px;
      width: 100%;
      margin-top: 0;
      & > div {
        font-weight: 400;
        font-size: 12px;
      }
    }
    .user-info {
      margin-top: 24px;
      max-width: 300px;
      width: 100%;
      .name {
        font-weight: 400;
        font-size: 22px;
      }
      .info {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 1.5em;
      }
    }
  }
`;