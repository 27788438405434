import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../redux/returnista-store";
import Content from "../../components/ReturnistaContent";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import {
  $HeaderText,
  $ItemDetailsCard,
  $ActiveImage,
  $ImageThumbnailGroup,
  $NavigateOffsetContainer,
  $NavigateOffsetButton,
  $ImageThumbnail,
  $ThumbnailPlaceholder,
  $DetailsPanel,
} from "./styles";
import { SVG } from "../../components/svg";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import { CurrentPageAction, EventAction, MultiStoreAction } from "../../redux/enums";
import ReturnistaImageManager from "../../utility/ReturnistaImageManager";
import ga from "../../utility/GAEmitter";
import { ItemDetailsActions } from "../../types/ReturnistaGA";
import { eventLabels } from "../../redux/returnistaReducers/events";
import { setReturnistaEvent } from "../../utility/returnistaEvents";

const ItemDetails = () => {
  const dispatch = useDispatch();
  const { currentBag, currentItem, returnObject, barcode, currentPage } = useSelector<RootReducer, RootReducer>(
    (store) => store
  );
  const item = returnObject.returning.find((i) => i.id === currentItem);

  // ActiveImage management
  const imageList =
    item?.purchase?.images != null
      ? item.purchase.images
      : item?.purchase?.thumbnail != null
      ? [item.purchase.thumbnail]
      : [];
  const [activeImageNumber, setActiveImageNumber] = useState(0);

  // ImageThumbnailGroup management
  const [thumbnailOffset, setThumbnailOffset] = useState(0);

  const getImageThumbnails = () => {
    // If we are on the first thumbnail offset and we only have 1 or 2 images it's okay to just show
    // those centered without needing to pad nulls to get placeholders
    if (thumbnailOffset === 0) return imageList.slice(0, 3);
    const imageCarouselLength = 3;
    const result = imageList.slice(thumbnailOffset * imageCarouselLength, thumbnailOffset + 3);
    while (result.length < imageCarouselLength) {
      result.push(null);
    }
    return result;
  };

  const renderImageThumbnails = () => {
    return getImageThumbnails().map((imageURI, i) => {
      if (imageURI == null) return <$ThumbnailPlaceholder key={i} />;
      const thumbnailIndex = thumbnailOffset * 3 + i;
      return (
        <$ImageThumbnail
          key={thumbnailIndex}
          imageURI={ReturnistaImageManager.getImage(imageURI)}
          isActive={activeImageNumber === thumbnailIndex}
          onClick={() => setActiveImageNumber(thumbnailIndex)}
        />
      );
    });
  };

  const handleItemConfirmation = () => {
    ga.pageEvent({ category: currentPage, action: ItemDetailsActions.YesClicked });
    dispatch({
      type: EventAction.Add,
      event: setReturnistaEvent({
        returnID: returnObject.id,
        eventTypeLabel: eventLabels.ITEM_PUZZLE_CORRECT_SELECTION,
        eventData: {
          instanceID: currentItem,
        },
      }),
    });
    // set up item in current bag
    dispatch({
      type: MultiStoreAction.AddItem,
      bag: currentBag,
      item: { data: item, label: barcode, confidence: 0, matched: null },
    });
    dispatch({ type: CurrentPageAction.Next });
  };

  const handleItemDenial = () => {
    dispatch({
      type: EventAction.Add,
      event: setReturnistaEvent({
        returnID: returnObject.id,
        eventTypeLabel: eventLabels.ITEM_CONFIRMATION_NO,
        eventData: {
          instanceID: currentItem,
        },
      }),
    });
    // store confirmation no event
    ga.pageEvent({ category: currentPage, action: ItemDetailsActions.NoClicked });
    dispatch({ type: CurrentPageAction.Back });
  };

  const showThumbnailBackButton = thumbnailOffset !== 0;
  const showThumbnailForwardButton = imageList[thumbnailOffset * 3 + 4] != null;

  return (
    <>
      <Header left={<HeaderBackButton />} center="Find the item" />
      <Content contentStyle="overflow-y: auto" childStyle="max-width: 720px">
        <$HeaderText>Is this the correct item?</$HeaderText>
        <$ItemDetailsCard>
          <$ActiveImage imageURI={ReturnistaImageManager.getImage(imageList[activeImageNumber])}>
            <$ImageThumbnailGroup>
              <$NavigateOffsetContainer>
                {showThumbnailBackButton ? (
                  <$NavigateOffsetButton onClick={() => setThumbnailOffset(thumbnailOffset - 1)}>
                    <SVG className={"navigate-offset"} name="backArrow" />
                  </$NavigateOffsetButton>
                ) : null}
              </$NavigateOffsetContainer>
              {renderImageThumbnails()}
              <$NavigateOffsetContainer>
                {showThumbnailForwardButton ? (
                  <$NavigateOffsetButton flipped={true} onClick={() => setThumbnailOffset(thumbnailOffset + 1)}>
                    <SVG className={"navigate-offset"} name="backArrow" />
                  </$NavigateOffsetButton>
                ) : null}
              </$NavigateOffsetContainer>
            </$ImageThumbnailGroup>
          </$ActiveImage>
          <$DetailsPanel>
            <div data-cy="vendor-name" className="vendor-name">
              {item?.purchase?.vendor ?? returnObject?.retailer?.name}
            </div>
            <div data-cy="item-name" className="item-name">
              {item?.purchase?.name ?? ""}
            </div>
            <div className="detail-tab-container">
              {(item?.purchase?.display ?? [])
                .filter((display) => display.label !== "Vendor")
                .map((display) => {
                  return (
                    <span key={display.label} className="detail-tab">
                      {display.value}
                    </span>
                  );
                })}
            </div>
            <div className="button-container">
              <ReturnistaPrimaryButton
                variant="outlined"
                className="button"
                onClick={handleItemDenial}
                dataCyString="deny-item"
              >
                No, it isn't
              </ReturnistaPrimaryButton>
              <ReturnistaPrimaryButton className="button" onClick={handleItemConfirmation} dataCyString="confirm-item">
                Yes, it is
              </ReturnistaPrimaryButton>
            </div>
          </$DetailsPanel>
        </$ItemDetailsCard>
      </Content>
    </>
  );
};

export default ItemDetails;
