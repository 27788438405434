import { cloneDeep } from "lodash";
import logger from "./logger/logger";
import { onFatalError } from "../redux/app/actions";
import { reset } from "../redux/customer/actions";

import { createBrowserHistory } from "history";

import { ReturnistaJWTClaims } from "../types/JWTClaims";
import { Purchase, Return } from "../types/Instance";
import { FatalErrors } from "../types/LifeCycle";
import { ReturnTypes, Returns, ReturnShoppingItem } from "../redux/customer/types";
import { Instance } from "../types/Instance";
import { DropoffMethod } from "../pages/DropoffMethods";

import { CurrentPage, CurrentPageAction, LoadingAction, ModalAction, ScannerType } from "../redux/enums";
import AlertModalBuilder from "../components/Modal/ReturnistaModalBuilders/Alert";
import { type ReturningType } from "../redux/returnistaReducers/returnObject";
import { label } from "./formatZPLCode";
import { Dispatch } from "redux";
import { useViewport } from "./useViewport";
import { MOBILE_BREAKPOINT } from "../globalConstants";
import { AppRuntimes } from "../types/AppRuntimes";

export const chatbotZIndex = 1999999999; // ensure button appears behind recaptcha challenge
export const modalZIndex = 9999;

export const customerSupportPhoneNumber = "1-800-555-1212";

export const prod = "prod";

export const googleAnalyticsKeys = {
  [AppRuntimes.returnPortal]: window.GA4_ACCOUNT_ID
};

export const changeDropoffReturnIDQueryKey = "changeDropoffReturnID";
export const returnStatusReturnIDQueryKey = "returnStatusReturnID";

// pattern: {wildcard}@{wildcard}.
export const isValidEmail = (email: string) => {
  return email.match(/.*@.*\..*/) !== null;
};

// this is a generic error handler for error objects that are caught when
// we call axios.get and axios.post
// a variety of common error scenarios are handled
export function handleAxiosError(error, dispatch) {
  // a non-2xx response was received (https://github.com/axios/axios#handling-errors)
  // presence of a response obj indicates that axios threw the non 2XX response as an error
  if (error.response) {
    // check for time out
    if (error.code === "ECONNABORTED") {
      // for now, every time we throw a manual error we should also manually reset
      // the customer store (in the future we could link our stores)
      dispatch(onFatalError(FatalErrors.connection));
      dispatch(reset());
    }
    // we're not sure what went wrong -- show a generic error
    else {
      // for now, every time we throw a manual error we should also manually reset
      // the customer store (in the future we could link our stores)
      dispatch(onFatalError(FatalErrors.unknown));
      dispatch(reset());
    }
  }
  // error isn't from axios, let's go ahead and log it
  else {
    logger.Error("Initialization error", error);
  }
}

// used for api calls
export const connectionTimeoutMilliseconds = 30000;

// for some variants of this product, the express code is in the url
// for such variants, this method will return the express code as a string
// if it can't be found then undefined will be returned
export const getExpressCodeFromURL = () => {
  if (window.location.pathname.startsWith("/rma/")) {
    const expressCode = window.location.pathname.slice(5);
    return expressCode;
  }
  return undefined;
};

// As we transition retailers from ORES to Return Portal, there will be
// customers with valid emails with links to the old URL scheme. This utility
// method, will detect those URL's and redirect to new expected URL.
const changeDropoffMethodURLPrefix = "/h/changeReturnMethod/";
export const changeDropoffMethodBackwardsCompatability = () => {
  if (window.location.pathname.startsWith(changeDropoffMethodURLPrefix)) {
    const returnID = window.location.pathname.slice(changeDropoffMethodURLPrefix.length);
    logger.Debug("backwards compatability changeDropoffMethod detected, ReturnID", { returnID });
    // Silently rewrite the URL to the new format and do not add to the browser history.
    window.location.replace(`/?changeDropoffReturnID=${returnID}`);
  }
};

// given a 'returning' that is a part of our returns data structure, return true
// if it has been returned already
export const hasBeenReturned = (returning) => {
  // the existence of a refundObject means an item has been returned already
  if (returning.refundObject) {
    return true;
  }
  return false;
};

export const filterReturnedItems = (returns: Array<ReturningType>): Array<ReturningType> => {
  return returns.filter((returnItem) => !hasBeenReturned(returnItem));
};

// if there are no queries, empty object is returned
export const mapURLQueryStringToObject = () => {
  const history = createBrowserHistory();
  const queryString = history?.location?.search;
  // if the string is empty, no point in doing the work so we return empty obj
  if (!queryString) return {};

  // first split the string from "?{key1}=val&{key2}=val" to ["key1=val", "key2=val"]
  let urlQueries = queryString.slice(1).split("&") || [];

  // iterate over each array element and turn them into ordered pairs [["key1", "val"],...]
  urlQueries.forEach((query, idx) => {
    urlQueries[idx] = query.split("=");
  });

  // manually iterate over the ordered pairs as IE doesn't support Object.fromEntries
  return urlQueries.reduce((collection, query) => {
    collection[query[0]] = query[1];
    return collection;
  }, {});
};

const handlePluralString = (subject: string, count: number) => `${subject}${count != 1 ? "s" : ""}`;

/**
 *
 * @param length - the amount of items in the context of the subject
 * @param subject - string used to determine if the output is "subject" or "subjects"
 */
export const handlePluralItemText = (length, subject = "item") => {
  return `${handlePluralString(subject, length)} added to return`;
};

export const handlePluralItemsReturnedText = (length) => {
  return `${handlePluralString("item", length)} returned`;
};

export const handlePluralItemsInReturnText = (length) => {
  return `${handlePluralString("item", length)} in return`;
};

export const handleEnterKeyPress = (e, callback) => {
  e?.preventDefault();
  e?.stopPropagation();
  if (e?.key === "Enter") {
    callback();
  }
};

/**
 * easy way to add both an onClick and enter keypress handler
 * spread the return value into component props
 */
export const handleEnterKeyPressOnClickHandlers = (callback: Function) => {
  return {
    onClick: (e) => callback(e),
    onKeyUp: (e) => handleEnterKeyPress(e, callback),
    tabIndex: 0,
    role: "button",
  };
};

/**
 * takes the right side of a JWT and decodes the b64 characters to reveal the claims within the JWT
 *
 * if an error occurs while parsing the token, log the error and return a barebones
 * version of the claims object to prevent app unmounting from this function specifically
 */
export const getJWTClaims = (token: string) => {
  const tokenParts = token?.split(".") || [];
  if (tokenParts.length > 1) {
    try {
      // TODO: Add JWT Signature Verification
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      console.error(e);
    }
  }
  return { returnsApp: "" };
};

/**
 * we don't want the app to unmount when an issue occurs
 * parsing the token so we log the error and return an object
 * in the structure of our claims
 */
export const getReturnistaJWTClaims = (token?: string): ReturnistaJWTClaims => {
  try {
    if (!token) return {};
    const claims = getJWTClaims(token);

    if (claims.Identity) {
      claims.Identity = JSON.parse(claims.Identity);
    }

    if (claims.returnsApp) {
      claims.returnsApp = JSON.parse(claims.returnsApp);
    }

    const parsedClaims = claims as ReturnistaJWTClaims;

    return parsedClaims;
  } catch (e) {
    console.error(e);
    return {
      returnsApp: {
        returnProcessingIssue: "",
        locationID: "",
        retailerID: "",
        allowGiftReturns: false,
        allowKeepInStore: false,
        couponEnabled: false,
        adminMode: false,
        representative: {
          login: "",
          name: "",
        },
      },
    };
  }
};

export const jwtClaimsByRuntime = {
  ios: getReturnistaJWTClaims,
  fedex: getJWTClaims,
  staples: getJWTClaims,
  "return-portal": getReturnistaJWTClaims,
};

type webkitMessage = "bagBarcode" | "confirmationCode" | "toteBarcode";
interface WebkitPostMessageObject {
  message: webkitMessage;
  allowSkip: boolean;
}

/**
 * This function is used to send messages to the returnista-app thin client via webkit, which is injected
 * Into the JS instance within the thinclient and accessible in global scope (window)
 * Currently the only use for this is to open up the camera natively in order to scan QR codes
 * @param type       - determines what is being scanned in order to diplay the right copies in the thin client
 * @param fallbackFn - when webkit isn't available, this function will be called
 * @param allowSkip  - if true, the thin client will display on the right of the scanner header
 *                     a new button that calls window.skipScan() when clicked
 */
export const openSwiftCameraScanner = (
  type: webkitMessage = "confirmationCode",
  fallbackFn?: () => void,
  allowSkip = false
): void => {
  // XXX we use a JSON object for the webkit message to allow more
  // for extra options when communicating between the thin client and web client
  const postObj: WebkitPostMessageObject = {
    message: type,
    allowSkip: allowSkip,
  };

  if (window?.webkit?.messageHandlers?.toggleMessageHandler) {
    window.webkit.messageHandlers.toggleMessageHandler.postMessage({
      message: JSON.stringify(postObj),
    });
  } else if (window?.Android?.postMessage) {
    window.Android.postMessage(JSON.stringify(postObj));
  } else {
    if (fallbackFn) {
      fallbackFn();
    }
  }
};

export function removeSpecialCharacter(str: string): string {
  const regex = /[^a-zA-Z0-9]/g;
  return str.replace(regex, "");
}

// validate HR bagBarcode
// barcode needs to start with an HR2 or HR3 and be 12 letters in length
export function validateBagBarcode(barcode) {
  if (barcode.charAt(0).toUpperCase() == "H" && barcode.charAt(1).toUpperCase() == "R" && barcode.length == 12) {
    if (barcode.charAt(2) === "2" || barcode.charAt(2) === "3") {
      return true;
    }
  }
  return false;
}

const oneOrMoreAlphanumericCharactersRegex = /^[a-z0-9]+$/i;

export const isInvalidToteBarcode = (barcode: string) => !oneOrMoreAlphanumericCharactersRegex.test(barcode);

export const isInvalidStoreNumber = (storeNumber: string) => !oneOrMoreAlphanumericCharactersRegex.test(storeNumber);

// takes in a timestamp string and outputs a formatted
// date string with options to change the format based on locale
export function getReadableTimestamp(timestamp: string, locale = "en-US") {
  const date = new Date(timestamp);
  const dateOptions: Intl.DateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" };
  try {
    return new Intl.DateTimeFormat(locale, dateOptions).format(date);
  } catch (e) {
    console.warn(e);
  }
  // fallback to Date.toLocaleDateString
  try {
    return date.toLocaleDateString(locale, dateOptions);
  } catch (e) {
    console.warn(e);
  }
  // final attempt to return a valid date
  return date.toDateString();
}

export function getCustomerEmailFromReturn(returnObj: Return | undefined): string | null {
  if (returnObj == undefined) {
    return null;
  }

  // look in customer identity
  if (returnObj?.customerIdentity?.email) {
    return returnObj.customerIdentity.email;
  }

  // look in each instance purchase
  if (returnObj.returning) {
    for (const instance of returnObj.returning) {
      if (instance.purchase?.email) {
        return instance.purchase.email;
      }
    }
  }

  return null;
}

type LocalStorageKeys = "barcodes";

/**
 * simple wrapper that gets JSON from local storage
 */
export const getLocalStorageJSON = (key: LocalStorageKeys) => {
  try {
    const item = localStorage.getItem(key);
    if (item != null) {
      return JSON.parse(item);
    }
    return null;
    // don't allow app to unmount if a bad call is made
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * simple wrapper that will stringify valid JSON and set to local storage
 */
export const setLocalStorageJSON = (key: LocalStorageKeys, val) => {
  try {
    localStorage.setItem(key, JSON.stringify(val));
  } catch (e) {
    // don't allow up to unmount if a bad call is made
    console.error(e);
  }
};

/**
 * used to reset all non redux localstorage items
 */
export const initLocalStorage = () => {
  setLocalStorageJSON("barcodes", []);
};

/**
 * Used to ensure that a user defined color is able to contrast
 * the text color for elements such as labeled buttons
 *
 * @param color : background color to be parsed to calculate brightness;
 * @returns text color that contrasts color input
 */
export const getTextColorFromBackgroundBrightness = (color: string) => {
  if (color.length === 7 && color[0] === "#") {
    // only attempt if the hexcode is 6 characters.
    const redVal = parseInt(color.substr(1, 2), 16);
    const greenVal = parseInt(color.substr(3, 2), 16);
    const blueVal = parseInt(color.substr(5, 2), 16);

    // calculating luminance https://en.wikipedia.org/wiki/Relative_luminance
    const luminance = .2126 * redVal / 255 + .7152 * greenVal / 255 + .0722 * blueVal / 255;
    return luminance > .5 ? "black" : "white";
  }
  // default to black if an invalid color is given
  return "black";
};

/**
 * @param purchase - purchase obj to be parsed for attr value
 * @param label    - attribute label to find in the purchase
 * @returns        - display value or null
 */
export function getAttributeValue(purchase: Purchase, label: string): string | null {
  let attr =
    purchase &&
    (purchase.display || []).find((displayAttr) => {
      return displayAttr.label === label;
    });
  return attr ? attr.value : null;
}

export function prepareReturn(
  returns: Returns | undefined,
  returnType: ReturnTypes,
  itemsMarkedForCompletion: (string | Instance)[],
  query: string,
  newReturnEmail: string | undefined,
  dropoffMethod: DropoffMethod | null,
  purchasing: any,
  returnSessionID?: string,
  rbLocations?: string[]
): Returns {
  let newReturns = cloneDeep(returns);

  // if items marked for completion were built from scratch:
  if (itemsMarkedForCompletion.every((item) => typeof item === "object")) {
    newReturns = {};
    newReturns.returning = itemsMarkedForCompletion;

    // build the customer identity if gift return
    if (returnType === ReturnTypes.giftReturn) {
      newReturns.customerIdentity = {
        email: newReturnEmail,
        order: query,
        identity: query,
        isGift: true,
      };
    }
  } else {
    //IDs into this endpoint.  for now, we will do some mangling
    const newReturning = returns?.returning.filter((returning) => {
      return returning.id != undefined && itemsMarkedForCompletion.includes(returning.id);
    });

    newReturns.returning = newReturning;
  }

  if (dropoffMethod) {
    newReturns.dropoffMethod = { methodID: dropoffMethod.id };
    if (dropoffMethod.selectedServiceLevel) newReturns.dropoffMethod.serviceLevel = dropoffMethod.selectedServiceLevel;
    if (rbLocations) {
      newReturns.dropoffMethod.locationsDisplayed = [...rbLocations]; //add all rbLocations to locationsDisplayed array to send back
    }
  }

  if (purchasing && returnSessionID) {
    const items: ReturnShoppingItem[] = purchasing.items.map(
      ({
        variant_id: variantID,
        price,
        quantity,
        image,
        sku,
        product_id: productID,
        title: name,
        product_description: description,
      }) =>
      ({
        variantID,
        price: price.toString(),
        quantity,
        image,
        sku,
        productID,
        name,
        description,
      } as ReturnShoppingItem)
    );
    newReturns.purchasing = { items };
    newReturns.returnSessionID = returnSessionID;
  }

  return newReturns;
}

const adminTokenQueryKey = "admin_token";
export const getAdminMode = () => {
  const queries = mapURLQueryStringToObject();
  if (!queries[adminTokenQueryKey]) {
    return false;
  }

  let claims = getJWTClaims(queries[adminTokenQueryKey]);
  let adminTokenClaims = getReturnistaJWTClaims(queries[adminTokenQueryKey]);

  // Date.now() returns a unix timestamp with ms - the claims exp is unic timestamp without ms
  let now = Math.floor(Date.now() / 1000);

  if (claims !== undefined && now > claims.exp) {
    return false;
  }

  const adminModeEnabled = Boolean(adminTokenClaims.returnsApp?.adminMode);
  return adminModeEnabled;
};

// return shopping: check if an item is selected for return or exchange
export const isSelectedForReturn = (instance: Instance) => {
  return instance.refund.id !== "exchange";
};

// returns true if any item is currently selected for return
export const containsReturn = (itemsMarkedForCompletion: any[]) => {
  return itemsMarkedForCompletion.some((item) => isSelectedForReturn(item));
};

export const getSavedRBLocations = () => {
  let rbLocations = localStorage.getItem("rbLocations");
  if (rbLocations) {
    return JSON.parse(rbLocations);
  }
  return;
};

// A more restrictive version of the existing openSwiftCameraScanner that restricts
// calls specifically to the ScannerType, intended for the Returnista Redesign
export const openScanner = (scanner: ScannerType) => {
  const message = JSON.stringify({ message: scanner, allowSkip: false, redesign: true });
  if (window?.webkit?.messageHandlers?.toggleMessageHandler) {
    window.webkit.messageHandlers.toggleMessageHandler.postMessage({
      message,
    });
  } else if (window?.Android?.postMessage) {
    window.Android.postMessage(message);
  }
};

// Detects if an input code looks similar to an HR Confirmation Code
export const isConfirmationCodeLike = (code: string) => {
  return code.startsWith("HR") && code.length === 8;
};

// Detects if an input code looks similar to an HR Bag Code
export const isBagCodeLike = (code: string) => {
  return (code.startsWith("HR2") || code.startsWith("HR3")) && code.length === 12;
};

export const isShippingLabelCodeLike = (code: string) => {
  return code.startsWith("1Z") && code.length === 18;
};

export const isUrlCodeLike = (code: string) => {
  return code.toLowerCase().startsWith("http://") || code.toLowerCase().startsWith("https://");
};

export const isReturnistaApp = (hostname) => {
  return hostname.includes("returnista") || hostname.startsWith("192.168");
};

type errorTextOrModalInput = {
  primaryMessage: string;
  subMessage?: string;
  useModal?: boolean;
  scannerType?: ScannerType;
  dispatch?: (object) => any;
  errorSetter?: (string) => any;
  usingModalSetter?: (boolean) => any;
};

export const errorTextOrModal = ({
  primaryMessage,
  subMessage,
  useModal,
  scannerType,
  dispatch,
  errorSetter,
  usingModalSetter,
}: errorTextOrModalInput) => {
  if (primaryMessage === "") {
    errorSetter?.("");
    dispatch?.({ type: ModalAction.Unset });
    return;
  }
  if (!useModal) {
    errorSetter?.(`${primaryMessage} ${subMessage}`);
    return;
  }
  if (usingModalSetter) {
    usingModalSetter(true);
  }
  const closeModal = () => {
    if (usingModalSetter) {
      usingModalSetter(false);
    }
    dispatch?.({ type: ModalAction.Unset });
  };
  dispatch?.({
    type: ModalAction.Set,
    modalProps: AlertModalBuilder({
      primaryMessage,
      subMessage: subMessage ?? "",
      buttonText: "Try again",
      closeHandler: closeModal,
      buttonHandler: closeModal,
      scannerType: scannerType,
    }),
  });
};

export const removeSpecialCharacters = (val: string): string => val.replace(/[^a-zA-Z0-9 ]/g, "");

export const removeLeadingZeroes = (val: string): string => val.replace(/^0+/g, "");

export const normalizeMatchingString = (val: string): string =>
  removeLeadingZeroes(removeSpecialCharacter(val)).toUpperCase();

export const isReturnLoop = (page: CurrentPage) => {
  const nonReturnLoop = [
    CurrentPage.Initializer,
    CurrentPage.Login,
    CurrentPage.StartReturn,
    CurrentPage.ReturnAccepted,
    CurrentPage.FatalError,
  ];
  return !nonReturnLoop.includes(page);
};

type textHandler = {
  usingScanner: boolean;
  headerMessages: Array<string>;
};

export const headerTextHandler = ({ usingScanner, headerMessages }: textHandler) => {
  return usingScanner ? headerMessages[0] : headerMessages[1];
};

export const isUPS = () => window.location.hostname.includes("tupssus");

// This function needs to be called in a scope that initiates the CefSharp method
// CefSharp.BindObjectAsync("ishipshell", "bound");
export const printLabels = async (bags, eventHandlerName: string, onSuccess: () => void) => {
  // Safeguards application crashes in case of CefSharp not being available
  if (!("CefSharp" in window)) {
    const err = "CefSharp is undefined in window";
    logger.Info(err);
    console.log(err); // also being logged because the partner are not using our logger
    console.log("Attempting to print labels:", bags.labels);

    // hardcoded outcomes for if we're not in a UPS window
    // throws error if we submit 4 bags
    if (bags.labels.length === 4) {
      throw new Error(err);
    }
    // any other value 1-9 is successful
    onSuccess?.();
    return;
  }

  try {
    // set each bag ID and its ZPL data to shell storage
    bags.labels.forEach(async (l: label) => {
      await ishipshell.setShellStorage(l.bagId, l.label);
    });

    // send an array of all the bag ids to notify the shell that we are ready to print
    // the shell will loop through this array and print the ZPL associated with the ID
    const bagIDs = bags.labels.map((l) => l.bagId);

    // notify cms shell that we are ready to print
    await top.ishipshell.notifyShellEvent("hrbaglabelsready", JSON.stringify(bagIDs));

    // subscribe to the print status event. when the shell receives "hrbaglabelsprintstatus",
    // the shell window will call the onHrBagLabelsPrintStatus callback
    await ishipshell.subscribeShellEvent("hrbaglabelsprintstatus", eventHandlerName);
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * validatePrintStatus loops through the printStatusJson object and throws an error
 * if one of the bag id's has a status of ERROR
 *
 * @param printStatusJson {string}
 */
export const validatePrintStatus = (printStatusJson) => {
  const printStatus = JSON.parse(printStatusJson);
  for (const id in printStatus) {
    if (printStatus[id] === "ERROR") {
      throw new Error(`Unable to print label [${id}]`);
    }
  }
};

export const isMobile = () => {
  const { width } = useViewport();
  return width < MOBILE_BREAKPOINT;
};

export const containsDigit = (inputString: string) => {
  // Use a regular expression to check for the presence of a digit
  const digitRegex = /\d/;
  return digitRegex.test(inputString);
}

export const checkIfItemHasValidPrice = (item) => {
  const itemPriceString = item?.purchase?.price || item?.price;
  return containsDigit(itemPriceString);
}
