import styled from 'styled-components';

const $SearchField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-grey);
  .search-icon {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
  & > form {
    display: flex;
    align-items: center;
    flex-grow: 1;
    input {
      width: 100%;
      font-size: 18px;
      font-family: "sofia-pro",sans-serif;
      border: none;
      outline: none;
      padding: 0;
      .android & {
        font-size: 16px;
      }
    }
  }
  .clear-search-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export default $SearchField;