// third-party imports
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { ErrorAlertMessage, InfoMessage } from "../../components/AlertMessage";
import type { AlertMessageContents } from "../../components/AlertMessage";
import {
  hideOnLargeBrowserWidthClass,
  hideOnSmallBrowserWidthClass,
  smallBrowserWidthMax,
} from "../../globalConstants";
import useSelector from "../../utility/useTypedSelector";
import { resetItemsMarkedForCompletion } from "../../redux/customer/actions";
import { actions } from "../../redux/slices/countItemsPresent";

// local imports
import { InfoModal, LessItemsCountedThanInReturnModal, TooManyItemsModal } from "./modals";
import $CountItemsPresent from "./styles";
import CounterInput from "../../components/CounterInput";
import { DataCyStrings } from "../../types/DataCyStrings";

class CountItemsPresentLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

enum errorStateKeys {
  tooManyItems = "too-many-items",
}

type modalKeys = "" | "info" | "too-many-items" | "warning" | "less-items-than-return";

const errorStates: { [key in errorStateKeys]: AlertMessageContents } = {
  [errorStateKeys.tooManyItems]: {
    message: "The number of items you entered is more than we have for this return. \nWhen counting, please consider:",
    bulletList: [
      "If the customer has multiple QR codes, process each separately",
      "A pair of shoes is considered 1 item",
      "An item could be a set of two products (ex. towel sets)",
    ],
  },
};

const suggestionMessage = {
  message: "When counting, please consider",
  bulletList: [
    "If the customer has multiple QR codes, process each separately",
    "A pair of shoes is considered 1 item",
    "An item could be a set of two products (ex. towel sets)",
  ],
};

const gaEventContent = {
  noErrors: "User submitted item count without errors",
  errorOccurred: "User encountered one or more errors while counting items",
};

const countItemsPresentFormID = "items-counted";

const CountItemsPresent = ({ page }: PageProps) => {
  const dispatch = useDispatch();
  const { app, customer } = useSelector((store) => store);
  const { returns, itemsPresent } = customer;
  const [errorState, setErrorState] = useState<errorStateKeys | "">("");
  const [errorCount, setErrorCount] = useState(0);
  const [modalState, setModalState] = useState<modalKeys>("");
  const lifecycle = new CountItemsPresentLifecycle(page, dispatch, app);
  const inputRef = useRef<HTMLInputElement>(null);

  // abstractions
  const showErrorMessage = errorState != "";
  const errorStateObj = errorStates[errorState];
  const countItemsPresentButtonDisabled = itemsPresent == 0;
  const className = showErrorMessage ? "error" : undefined;

  useEffect(() => {
    inputRef?.current?.focus?.();
    dispatch(resetItemsMarkedForCompletion());
  }, []);

  const onSubmit = () => {
    const returnCount = returns?.returning.length || 0;

    if (itemsPresent === 0) return;

    if (itemsPresent > returnCount) {
      setErrorCount(errorCount + 1);
      setErrorState(errorStateKeys.tooManyItems);

      const { innerWidth: width } = window;
      const isSmallBrowser = width <= parseFloat(smallBrowserWidthMax);

      if (isSmallBrowser) {
        setModalState("too-many-items");
      }

      return;
    }

    if (itemsPresent < returnCount) {
      setModalState("less-items-than-return");
      return;
    }

    advanceToNextPage();
  };

  const advanceToNextPage = () => {
    lifecycle.advance();
  };

  const handleFormSubmit = (e: FormEvent) => {
    onSubmit();
    e?.preventDefault();
  };

  return (
    <$CountItemsPresent className={className}>
      <header>
        <h1>How many items are present?</h1>
        <h2 className={hideOnSmallBrowserWidthClass}>Take items from the customer to count the total</h2>
      </header>
      <CounterInput
        value={itemsPresent}
        id={countItemsPresentFormID}
        onSubmit={handleFormSubmit}
        setInputValue={(val: number) => dispatch(actions.setItemsPresent(val))}
        increment={() => dispatch(actions.increment())}
        decrement={() => dispatch(actions.decrement())}
        resetErrorState={() => setErrorState("")}
        inputRef={inputRef}
        dataCy={DataCyStrings.countItemsPresentInput}
        disabled={countItemsPresentButtonDisabled}
        buttonText="Confirm Items Present"
        submitDataCy={DataCyStrings.countItemsPresentSubmitButton}
        item="items"
      />
      <footer>
        <MessageDisplay
          showErrorMessage={showErrorMessage}
          setModalState={setModalState}
          errorStateObj={errorStateObj}
        />
      </footer>
      <ModalSwitch
        modalKey={modalState}
        onClose={() => setModalState("")}
        advance={advanceToNextPage}
        headerText="Count the items"
      />
    </$CountItemsPresent>
  );
};

const MessageDisplay = ({ showErrorMessage, setModalState, errorStateObj }) => {
  return !showErrorMessage ? (
    <>
      <div className={hideOnSmallBrowserWidthClass}>
        <InfoMessage {...suggestionMessage} />
      </div>
      <div className={hideOnLargeBrowserWidthClass}>
        <InfoMessage
          message="Click here for counting tips."
          onMessageClick={() => {
            setModalState("info");
          }}
        />
      </div>
    </>
  ) : (
    <>
      <div className={hideOnSmallBrowserWidthClass}>
        <ErrorAlertMessage {...errorStateObj} />
      </div>
      <div className={hideOnLargeBrowserWidthClass}>
        <ErrorAlertMessage
          message="Too many counted. Troubleshoot here"
          onMessageClick={() => {
            setModalState("too-many-items");
          }}
        />
      </div>
    </>
  );
};

const ModalSwitch = ({
  modalKey,
  onClose,
  advance,
  headerText,
}: {
  modalKey: modalKeys;
  onClose: () => void;
  advance: () => void;
  headerText: string;
}) => {
  const props = { onClose, advance, headerText };
  switch (modalKey) {
    case "too-many-items":
      return <TooManyItemsModal {...props} />;
    case "info":
      return <InfoModal {...props} />;
    case "less-items-than-return":
      return <LessItemsCountedThanInReturnModal {...props} />;
    default:
      return null;
  }
};

export default CountItemsPresent;
