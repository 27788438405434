import styled from "styled-components";

const $ScanHistory = styled.div`
  --bold: 500; // normal bold (700) is TOO bold

  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;

  font-family: "sofia-pro", sans-serif;
  font-weight: 100;

  a {
    color: var(--text-black);
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const $Card = styled.div`
  border: 1px solid var(--border-card);
  border-radius: 12px;
  margin: 24px 0;
  padding: 24px;
  width: 95vw;

  span {
    align-items: center;
    background-color: var(--background-white);
    border-radius: 8px;
    color: var(--high-emphasis-black);
    display: flex;
    margin-top: 20px;
    width: 100%;

    font-size: 0.9rem;

    .row-number {
      margin-left: 24px;

      font-weight: var(--bold);
    }

    span.barcode-text {
      margin-top: 2.5px;
    }

    svg.green-check-icon {
      height: 44px;
      margin-left: 32px;
      margin-right: 8px;
      width: 44px;
    }

    svg.x {
      cursor: pointer;
      height: 44px;
      margin-left: auto;
      margin-right: 24px;
      width: 44px;
    }
  }
`;

export const $CardHeader = styled.div`
  align-self: center;
  // align-self: center should vertically center the text, but we have issues w/ our current font
  // TODO re-evaluate rules during/after completion of RF-425
  margin-bottom: 18px;

  font-weight: var(--bold);
`;

export const $Divider = styled.div`
  border: 1px solid var(--border-grey);
`;

export const $Footer = styled.div`
  margin-top: auto;
  width: calc(100% + 48px);

  a {
    color: var(--text-black);
  }
`;

export const $ScanAnotherBagButton = styled.div`
  align-self: start;
  display: flex;
  cursor: pointer;
  flex-direction: row;

  a {
    margin-top: 5.5px;
    margin-left: 12px;

    font-weight: var(--bold);
    font-size: 0.95rem;
  }
`;

export default $ScanHistory;
