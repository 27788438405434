import React, { useMemo, useCallback, useState } from "react";
import useSelector from "../../utility/useTypedSelector";
import { useDispatch } from "react-redux";
import { getConfigurationAndInitialize } from "../../redux/app/actions";
import { supportedCountryLocales, supportedLocaleMappings } from "../../i18n";
import { handleEnterKeyPressOnClickHandlers } from "../../utility";
import { AnalyticCategories, LoginPageActions } from "../../types/Analytics"
import getTranslator from "../../utility/getTranslator";
import ga from "../../utility/GAEmitter";

import {
  $LanguagePicker
} from "./styles"
import { SVG } from "../svg";
import LanguageModal from "../Modal/LanguageModal";
import { DataCyStrings } from "../../types/DataCyStrings";



const useTranslation = getTranslator("LanguagePicker")

const getDisplayedLocales = (currentLocale, sortedEnabledLocales) => {
  const topDisplayedLocales = sortedEnabledLocales.slice(0, 3)
  return [
    [currentLocale, supportedLocaleMappings[currentLocale]],
     ...topDisplayedLocales.filter(([locale]) => locale != currentLocale).slice(0,2)
  ]
}


const LanguageItem = ({ locale, isActive, pickLocale }) => {
  return (<>
    <a
      {...isActive ? { className: 'active', "aria-disabled": true } : handleEnterKeyPressOnClickHandlers(() => pickLocale(locale))}
      role="button"
      tabIndex={0}
      data-cy={`${locale}-button`}
    >
      {supportedCountryLocales[locale]}
    </a>
    <span className='splitter'>|</span>
  </>)
}

const LanguagePicker = ({handleLanguageError}) => {
  const { t } = useTranslation()
  const [globalModalIsOpen, setGlobalModalIsOpen] = useState(false)
  const { locale: currentLocale, loadingMessage, enabledLocales={} } = useSelector(store => store.app)

  const dispatch = useDispatch()

  const sortedEnabledLocales = useMemo(() => {
    const localesWithNames = Object.keys(enabledLocales).map(code => [
      code, supportedCountryLocales[code]
    ])
    return localesWithNames.sort(([,lang1],[,lang2]) =>
      lang1.localeCompare(
        lang2, undefined, { usage: "sort", sensitivity: "base" }
      )
    )}, [enabledLocales])

  const displayedLocales = useMemo(() =>
    getDisplayedLocales(currentLocale, sortedEnabledLocales)
    , [currentLocale, sortedEnabledLocales])

  const pickLocale = useCallback(locale => {
    // prevent double clicking
    // no-op if locale is already the current one
    if (loadingMessage || locale == currentLocale)
      return

    setGlobalModalIsOpen(false)
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: `Changed language to ${locale}`
    });
    dispatch(getConfigurationAndInitialize(locale, handleLanguageError))
  }, [loadingMessage]);

  const languageItems = useMemo(() =>
  displayedLocales.map(([locale]) =>
    <LanguageItem key={locale} isActive={locale == currentLocale} locale={locale} pickLocale={pickLocale} />
  ), [currentLocale, pickLocale])

  return (<>
    <$LanguagePicker data-cy={DataCyStrings.languagePicker}>
      {languageItems}
      <a
        {...handleEnterKeyPressOnClickHandlers(() => {
          ga.event({
            category: AnalyticCategories.LoginPage,
            action: LoginPageActions.TranslationGlobe
          });
          setGlobalModalIsOpen(true)
        })}
        role="button"
        className="globe"
        tabIndex={0}
        aria-label={t('globalLanguageSelection')}
        data-cy={DataCyStrings.globeIcon}
      >
        <SVG name="globe"></SVG>
      </a>
    </$LanguagePicker>
    <LanguageModal locales={sortedEnabledLocales} isOpen={globalModalIsOpen} onClose={() => setGlobalModalIsOpen(false)} onPick={pickLocale} />
  </>
  );
}

export default LanguagePicker;
