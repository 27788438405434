import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $Container = styled.div`
  border: 1px solid var(--border-card);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 24px;
  padding: 16px;
  width: 100%;
  max-width: 534px;

  @media (min-width: ${tabletOrLargerDevices}) {
    padding: 40px 24px;
  }

  .actions {
    button {
      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }
`;

export const $Title = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
  @media (min-width: ${tabletOrLargerDevices}) {
    margin-bottom: 48px;
  }

  p {
    color: var(--dark-green);
    font: normal 400 21px "sofia-pro";
    line-height: 32px;
    margin: 16px 0;
    text-align: center;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin: 48px 0;
    }
  }
  span {
    color: var(--soft-black);
    font: normal 400 16px "sofia-pro";
    line-height: 24px;
    text-align: center;
  }

  &:after {
    background-color: var(--medium-grey);
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto;
    width: 80px;
  }
`;

export const $FollowUp = styled.p`
  font-size: 21px;
  margin: 0 auto 16px;
  text-align: center;

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 27px;
    margin: 0 auto 40px;
  }
`;

export const $BarcodeHint = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 534px;

  .title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: normal 400 16px "sofia-pro";
    font-smooth: never;
    line-height: normal;
    margin-top: 0;

    @media (min-width: ${tabletOrLargerDevices}) {
      font: normal 400 21px "sofia-pro";
      line-height: 32px;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 160px;

      p {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--medium-emphasis-black);
        font: normal 400 12px "sofia-pro";
        font-smooth: never;

        @media (min-width: ${tabletOrLargerDevices}) {
          font: normal 400 16px "sofia-pro";
        }
      }

      .image-wrapper {
        border-radius: 50%;
        height: 64px;
        overflow: hidden;
        width: 64px;

        img {
          width: 100%;
        }

        @media (min-width: ${tabletOrLargerDevices}) {
          width: 112px;
          height: 112px;
        }
      }
    }
  }
`;

export const $IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const $NoBarCodeButtons = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${tabletOrLargerDevices}) {
    flex-direction: row;
  }

  button {
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 190px;
      margin-bottom: 0;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
