import React from "react";

const Megaphone = (props) => (
  <>
    <svg {...props} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 8.99994C16.7167 8.99994 16.4792 8.9041 16.2875 8.71244C16.0958 8.52077 16 8.28327 16 7.99994C16 7.7166 16.0958 7.4791 16.2875 7.28744C16.4792 7.09577 16.7167 6.99994 17 6.99994H19C19.2833 6.99994 19.5208 7.09577 19.7125 7.28744C19.9042 7.4791 20 7.7166 20 7.99994C20 8.28327 19.9042 8.52077 19.7125 8.71244C19.5208 8.9041 19.2833 8.99994 19 8.99994H17ZM16.4 15.3999L14.8 14.1999C14.5667 14.0333 14.4333 13.8166 14.4 13.5499C14.3667 13.2833 14.4333 13.0333 14.6 12.7999C14.7667 12.5666 14.9833 12.4333 15.25 12.3999C15.5167 12.3666 15.7667 12.4333 16 12.5999L17.6 13.7999C17.8333 13.9666 17.9667 14.1833 18 14.4499C18.0333 14.7166 17.9667 14.9666 17.8 15.1999C17.6333 15.4333 17.4167 15.5666 17.15 15.5999C16.8833 15.6333 16.6333 15.5666 16.4 15.3999ZM16 3.39994C15.7667 3.5666 15.5167 3.63327 15.25 3.59993C14.9833 3.5666 14.7667 3.43327 14.6 3.19993C14.4333 2.9666 14.3667 2.7166 14.4 2.44993C14.4333 2.18327 14.5667 1.9666 14.8 1.79993L16.4 0.599935C16.6333 0.433268 16.8833 0.366602 17.15 0.399935C17.4167 0.433268 17.6333 0.566602 17.8 0.799935C17.9667 1.03327 18.0333 1.28327 18 1.54993C17.9667 1.8166 17.8333 2.03327 17.6 2.19993L16 3.39994ZM3 14.9999V10.9999H2C1.45 10.9999 0.979167 10.8041 0.5875 10.4124C0.195833 10.0208 0 9.54994 0 8.99994V6.99994C0 6.44993 0.195833 5.9791 0.5875 5.58743C0.979167 5.19577 1.45 4.99993 2 4.99993H6L9.475 2.89994C9.80833 2.69993 10.1458 2.69993 10.4875 2.89994C10.8292 3.09994 11 3.3916 11 3.77493V12.2249C11 12.6083 10.8292 12.8999 10.4875 13.0999C10.1458 13.2999 9.80833 13.2999 9.475 13.0999L6 10.9999H5V14.9999H3ZM12 11.3499V4.64993C12.45 5.04993 12.8125 5.53744 13.0875 6.11243C13.3625 6.68743 13.5 7.3166 13.5 7.99994C13.5 8.68327 13.3625 9.31244 13.0875 9.88743C12.8125 10.4624 12.45 10.9499 12 11.3499ZM9 10.4499V5.54993L6.55 6.99994H2V8.99994H6.55L9 10.4499Z"
        fill="#221F1F"
      />
    </svg>
  </>
);

export default Megaphone;
