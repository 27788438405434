

import React from "react";

const CheckCircle = (props) => (
  <>
    <svg {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="27" stroke="#737373" strokeWidth="2"/>
      <path d="M16.7101 27.419C16.3213 27.0268 15.6881 27.0241 15.2959 27.413C14.9037 27.8018 14.901 28.435 15.2899 28.8272L16.7101 27.419ZM23.8102 36L23.1001 36.7041C23.4885 37.0958 24.1207 37.099 24.5131 36.7113L23.8102 36ZM40.7029 20.7113C41.0957 20.323 41.0995 19.6899 40.7113 19.2971C40.323 18.9043 39.6899 18.9005 39.2971 19.2887L40.7029 20.7113ZM15.2899 28.8272L23.1001 36.7041L24.5203 35.2959L16.7101 27.419L15.2899 28.8272ZM24.5131 36.7113L40.7029 20.7113L39.2971 19.2887L23.1072 35.2887L24.5131 36.7113Z" fill="#737373"/>
    </svg>
  </>
)

export default CheckCircle;