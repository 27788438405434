// third-party imports
import { isEmpty, last } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import ModalContainer from "../../components/Modal/ModalContainer";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ReturnCountFooter from "../../components/ReturnCountFooter";
import { SVG } from "../../components/svg";
import { goToPage, setConfirmationPath, showAlert } from "../../redux/app/actions";
import {
  addBagToteBarcodePairToDatabase,
  addReturnBagBarcode,
  deleteBagToteBarcodePair,
  removeReturnBagBarcode,
  updateBagToteBarcodePair,
} from "../../redux/customer/actions";
import { Store as CustomerStore } from "../../redux/customer/types";
import { RootReducer } from "../../redux/reducers";
import { BagToteBarcodePair } from "../../types/BagToteBarcodePair";
import { DataCyStrings } from "../../types/DataCyStrings";
import { Alerts } from "../../types/LifeCycle";
import {
  openSwiftCameraScanner,
  validateBagBarcode,
  removeSpecialCharacter,
  isInvalidToteBarcode,
  handleEnterKeyPressOnClickHandlers,
} from "../../utility";
import useSelector from "../../utility/useTypedSelector";
import logger from "../../utility/logger"

// local imports
import $ConfirmationScanTote, {
  $Divider,
  $Footer,
  $Prompt,
  $DeleteBagButton,
  $ScanBagCard,
  $ScanBagCardHeader,
  $ScanToteCard,
  $ScanToteCardHeader,
  $SubPrompt,
} from "./styles";

class ConfirmationScanToteLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }

  satisfiesPreconditions(): boolean {
    if (!super.satisfiesPreconditions()) return false;
    if (!this.customer.returns || !this.customer.returns.returning) return false;

    logger.Info("Preconditions satisfied. Rendering ConfirmationScanTote...");
    return true;
  }
}

// static copy
export const BAG_ALL_ITEMS = "Bag All Items and Scan";
export const BAGS_STAGED_BEHIND_COUNTER = "Happy Returns bags may be staged behind the counter.";
export const CONSOLIDATION_TOTE = "Consolidation Tote";
export const ENTER_STORE_NUMBER = "Manually enter store number";
export const IF_ALL_TOTES_FULL = " if all totes are full or none are available.";
export const PLACE_BAG = "Place bag into a Happy Returns tote and scan the tote. ";
export const PLACE_ITEMS = "Place item(s) into a Happy Returns bag and scan the label. ";
export const POLYBAG = "Happy Returns Polybag";
export const SCAN_TOTE = "Scan Tote";
export const SEE_EXAMPLE_BAG_LABEL = "See Example of Label";
export const SEE_EXAMPLE_TOTE_LABEL = "See Example of Scanning Tote";
export const TOTES_STAGED_BEHIND_COUNTER = "Happy Returns branded tote may be staged behind the counter.";
export const USE_MULTIPLE_BAGS = "Use multiple bags, if necessary.";

// dynamic copy
export const BAG_X_ADDED = (barcode: string) => `Bag ${barcode} Added`;

// valid states
enum PageState {
  "INITIAL",
  "BAG_SCANNED",
}

/**
 * Scan bag, scan tote page used exclusively with Returnista.
 *
 * Similar to our vanilla Confirmation page, but includes an experimental workflow involving tote-scanning on top of
 * the default bag-scanning functionality.
 */
const ConfirmationScanTote = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();

  const { app, customer } = useSelector((store: RootReducer) => store);
  const { currentPageName, runtime } = app;
  const { bagToteBarcodePairs, barcodes, returns } = customer;

  const lifecycle = new ConfirmationScanToteLifecycle(page, dispatch, app, customer);

  const [pageState, setPageState] = useState<PageState>(PageState.INITIAL);

  const [bagBarcode, setBagBarcode] = useState<string>("");
  const [toteBarcode, setToteBarcode] = useState<string>("");
  const [currentPair, setCurrentPair] = useState<BagToteBarcodePair>({ bagBarcode: "", toteBarcode: "" });

  const [showBagLabelExampleModal, setShowBagLabelExampleModal] = useState<boolean>(false);
  const [showToteLabelExampleModal, setShowToteLabelExampleModal] = useState<boolean>(false);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    // functions to be invoked by the thin client
    window.setBagBarcode = (barcode) => {
      if (barcode) {
        setBagBarcode(
          removeSpecialCharacter(barcode.toUpperCase())
        );
      }
    };

    window.setToteBarcode = (barcode) => {
      if (barcode) {
        setToteBarcode(barcode.toUpperCase());
      }
    };

    window.goBack = () => {
      // Add cleanup code here as necessary. This function definition needs to remain in order to prevent
      // error noise in the thin client, but leaving this as a no-op is acceptable.
    };

    window.enterBarcodeManually = () => {
      dispatch(goToPage("typeBagBarcode"));
    };

    window.enterToteBarcodeManually = () => {
      dispatch(goToPage("typeToteBarcode"));
    };

    // used to determine which page to return to from TypeBagBarcode page
    dispatch(setConfirmationPath(currentPageName));
  }, []);

  useEffect(() => {
    if (bagBarcode === "") return;

    if (!validateBagBarcode(bagBarcode)) {
      dispatch(showAlert(Alerts.invalidBagBarcode));
      setBagBarcode("");
      return;
    }
    if (barcodes.find((x) => x === bagBarcode)) {
      dispatch(showAlert(Alerts.bagAlreadyScanned));
      setBagBarcode("");
      return;
    }

    dispatch(addReturnBagBarcode(bagBarcode, runtime, currentPageName));

    setBagBarcode("");
    setPageState(PageState.BAG_SCANNED);
  }, [bagBarcode]);

  useEffect(() => {
    if (toteBarcode === "") return;
    if (!currentPair) return;

    if (isInvalidToteBarcode(toteBarcode)) {
      dispatch(showAlert(Alerts.invalidToteBarcode));
      setToteBarcode("");
      return;
    }

    const updatedPair = { bagBarcode: currentPair.bagBarcode, toteBarcode };

    // do not persist bag, tote scans in DB if using test order
    if (returns?.confirmationCode === "HRTESTER") {
      dispatch(updateBagToteBarcodePair(updatedPair));
    } else {
      dispatch(addBagToteBarcodePairToDatabase(updatedPair));
    }

    setToteBarcode("");
    lifecycle.advance("scanHistory");
  }, [toteBarcode]);

  // bagToteBarcodePairs is the source of truth. the above useEffects are necessary for updating
  // the source of truth when bag/tote barcodes are input via camera and not keyboard.
  useEffect(() => {
    if (isEmpty(bagToteBarcodePairs)) return;

    const currentPair = last(bagToteBarcodePairs);

    if (currentPair.bagBarcode && !currentPair.toteBarcode) {
      setCurrentPair(currentPair);
      setPageState(PageState.BAG_SCANNED);
    }
  }, [bagToteBarcodePairs]);

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS
  /**
   * Gets the length of bagToteBarcodePairs that have both a bag AND tote barcode.
   */
  const getFullBagToteBarcodePairsLength = () => {
    if (isEmpty(bagToteBarcodePairs)) return 0;

    return last(bagToteBarcodePairs).toteBarcode ? bagToteBarcodePairs.length : bagToteBarcodePairs.length - 1;
  };

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const handleScanBagButtonClick = () => {
    openSwiftCameraScanner("bagBarcode", () => lifecycle.advance("typeBagBarcode"));
  };

  const deleteBagButtonHandlers = handleEnterKeyPressOnClickHandlers(() => {
    dispatch(removeReturnBagBarcode(currentPair?.bagBarcode));

    // this handler is invoked only when the current pair has a bag barcode but no tote barcode.
    // we only add a pair to the DB when the pair has both a bag barcode and a tote barcode.
    // hence, when we delete a bag, we only need to delete the corresponding bag-tote pair from the
    // store, not the store and DB.
    dispatch(deleteBagToteBarcodePair(currentPair));

    setPageState(PageState.INITIAL);
  });

  const handleScanToteButtonClick = () => {
    openSwiftCameraScanner("toteBarcode", () => lifecycle.advance("typeToteBarcode"));
  };

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------

  // RENDERING

  const renderScanBagButton = () => {
    if (pageState === PageState.BAG_SCANNED) {
      return (
        <$DeleteBagButton data-cy={DataCyStrings.scanBarcodeButton}>
          <SVG name={"green-check-icon"} className={"green-check-icon"}></SVG>
          <span className={"bag-barcode-text"}>{BAG_X_ADDED(currentPair?.bagBarcode)}</span>
          <SVG
            name={"x"}
            className={"x"}
            data-cy={DataCyStrings.deleteBagBarcodeButton}
            {...deleteBagButtonHandlers}
          ></SVG>
        </$DeleteBagButton>
      );
    }

    return (
      <PrimaryButton
        dataCyString={DataCyStrings.scanBarcodeButton}
        onButtonClick={handleScanBagButtonClick}
        label="Scan Bag"
        width="300px"
      />
    );
  };

  const renderScanBagCard = () => {
    if (pageState === PageState.BAG_SCANNED) {
      return (
        <$ScanBagCard data-cy={DataCyStrings.bagAllItemsAndScanCard} isActive={false}>
          <$ScanBagCardHeader isActive={false}>
            <SVG name={"one"}></SVG>
            <div>{BAG_ALL_ITEMS}</div>
            {renderScanBagButton()}
          </$ScanBagCardHeader>
        </$ScanBagCard>
      );
    }

    return (
      <$ScanBagCard data-cy={DataCyStrings.bagAllItemsAndScanCard} isActive={true}>
        <$ScanBagCardHeader isActive={true}>
          <SVG name={"one"}></SVG>
          <div>{BAG_ALL_ITEMS}</div>
          {renderScanBagButton()}
        </$ScanBagCardHeader>
        <$Divider />
        <$Prompt>
          {PLACE_ITEMS}
          <a data-cy={DataCyStrings.seeExampleBagBarcodeLink} onClick={() => setShowBagLabelExampleModal(true)}>
            {SEE_EXAMPLE_BAG_LABEL}
          </a>
          <ModalContainer
            isOpen={showBagLabelExampleModal}
            img={"/public/img/scanBagExample.png"}
            imgPosition={"bottom"}
            onRequestClose={() => setShowBagLabelExampleModal(false)}
            primaryMessage={POLYBAG}
            subMessages={[BAGS_STAGED_BEHIND_COUNTER]}
          />
          <$SubPrompt>{USE_MULTIPLE_BAGS}</$SubPrompt>
        </$Prompt>
      </$ScanBagCard>
    );
  };

  const renderScanToteButton = () => {
    return (
      <PrimaryButton
        disabled={pageState === PageState.INITIAL}
        dataCyString={DataCyStrings.scanToteButton}
        onButtonClick={handleScanToteButtonClick}
        label="Scan Tote"
        width="300px"
      />
    );
  };

  const renderScanToteCard = () => {
    return (
      <$ScanToteCard data-cy={DataCyStrings.scanToteCard} isActive={pageState === PageState.BAG_SCANNED}>
        <$ScanToteCardHeader isActive={pageState === PageState.BAG_SCANNED}>
          <SVG name={"two"} />
          <div>{SCAN_TOTE}</div>
          {renderScanToteButton()}
        </$ScanToteCardHeader>
        <$Divider />
        <$Prompt>
          {PLACE_BAG}
          <a
            data-cy={DataCyStrings.seeExampleToteBarcodeLink}
            onClick={() => {
              pageState === PageState.BAG_SCANNED ? setShowToteLabelExampleModal(true) : undefined;
            }}
          >
            {SEE_EXAMPLE_TOTE_LABEL}
          </a>
          <ModalContainer
            isOpen={showToteLabelExampleModal}
            img={"public/img/tote-example.png"}
            imgPosition={"bottom"}
            onRequestClose={() => setShowToteLabelExampleModal(false)}
            primaryMessage={CONSOLIDATION_TOTE}
            subMessages={[TOTES_STAGED_BEHIND_COUNTER]}
          />
          <$SubPrompt>
            <div>
              <a
                data-cy={DataCyStrings.typeStoreNumberLink}
                onClick={() => {
                  pageState === PageState.BAG_SCANNED ? lifecycle.advance("typeStoreNumber") : undefined;
                }}
              >
                {ENTER_STORE_NUMBER}
              </a>
              {IF_ALL_TOTES_FULL}
            </div>
          </$SubPrompt>
        </$Prompt>
      </$ScanToteCard>
    );
  };

  const renderCancelLink = () => {
    if (bagToteBarcodePairs.length > 0 && pageState === PageState.INITIAL) {
      return (
        <a data-cy={DataCyStrings.cancelAndSeeScanHistoryLink} onClick={() => lifecycle.advance("scanHistory")}>
          Cancel and See Scan History
        </a>
      );
    }

    return;
  };

  const renderFooter = () => {
    return (
      <$Footer>
        <ReturnCountFooter count={getFullBagToteBarcodePairsLength()} itemType={"bag-scan-to-tote"} runtime={runtime}>
          {renderCancelLink()}
          <PrimaryButton
            disabled={true}
            dataCyString={DataCyStrings.finishReturnButton}
            label="Finish Return"
            width={"130px"}
          />
        </ReturnCountFooter>
      </$Footer>
    );
  };

  return (
    <$ConfirmationScanTote className={runtime ? runtime : undefined}>
      {renderScanBagCard()}
      {renderScanToteCard()}
      {renderFooter()}
    </$ConfirmationScanTote>
  );
  //----------------------------------------------------------------------------
};

export default ConfirmationScanTote;
