interface ZPLCodeGen {
  barCodes: Array<string>;
}

export type label = {
  bagId: string;
  label: string;
};

const generateZPLCode = ({ barCodes }: ZPLCodeGen): Array<label> => {
  /*
      ^XA         // Start of label format
      ^CF0,70     // Set font attributes: Default font (0) with font size of 70 points
      ^FO,300     //  Set Field Origin for barcode: Starting position for Happy Returns at coordinates (125,550)
      ^BY2,,300   // Set barcode field parameters: Barcode height: 2x narrow bar width, Total width: 300 dots
      ^FO125,550  // Set Field Origin for barcode: Starting position for barcode at coordinates (125,550)
      ^BC         // Begin barcode field
      ^FD${c}^FS  // Field Data for barcode: Placeholder ${c} will be replaced with the barcode value from the barCodes.map() function
      ^XZ         // End of label format
    */
  return barCodes.map((bagId) => {
    return {
      bagId,
      label: `^XA
        ^CF0,70
        ^FO,300^FB820,1,0,C^FDHappy Returns\\&^FS
        ^BY2,,300
        ^FO125,550^BC,,Y,,,^FD${bagId}^FS
        ^XZ`,
    };
  });
};

export default generateZPLCode;
