import logger from "../../utility/logger";
import { LocationOpeningHours } from "./locationOpeningHours";

export interface ParsedHours {
  status?: string,
  schedule?: string[],
}

interface Schedule {
  dayOfWeek: Days,
  openingTime: Date,
  closingTime: Date,
}

// Not ISO-8601 day of week, but matches what is in the db.
enum Days {
  Mon = 1,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
  Sun,
}

const DayNames = new Map<Days, string>([
  [Days.Mon, 'Mon'],
  [Days.Tue, 'Tue'],
  [Days.Wed, 'Wed'],
  [Days.Thu, 'Thu'],
  [Days.Fri, 'Fri'],
  [Days.Sat, 'Sat'],
  [Days.Sun, 'Sun'],
]);

export const formatLocationHours = (openingHours: LocationOpeningHours[], timeZone: string, now?: Date): ParsedHours => {
  if (now == undefined) {
    now = new Date();
  }
  const schedule = convertHours(openingHours);

  return {
    status: getStatus(schedule, now, timeZone),
    schedule: formatHours(schedule, timeZone),
  };
}

const dayFromDate = (now: Date): Days => {
  // 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
  const utcDay = now.getDay();
  // Sunday needs to be changed from 0 to 7.
  if (utcDay == 0) {
    return Days.Sun;
  }
  // The db value is 1-7, Monday will already match up.
  return utcDay as Days;
}

/**
 * Converts a date object to a formatted time string.
 * @param time
 * @param timeZone
 */
const formatTime = (time: Date, timeZone: string): string => {
  const options: Intl.DateTimeFormatOptions = { timeZone: timeZone, hour: 'numeric', minute: '2-digit' };

  // Format example: 10:00 AM, 1:00 PM
  let formatted = time.toLocaleTimeString(undefined, options);

  // Remove the minute if it is 00.
  // Remove the space between the time and AM/PM
  return formatted.replace(':00', '').replace(' ', '');
}

/**
 * Determines the open status of the location.
 * @param schedule
 * @param now
 * @param timeZone
 */
export const getStatus = (schedule: Schedule[], now: Date, timeZone: string): string => {
  if (!schedule?.length) {
    return '';
  }

  const currentDay = dayFromDate(now);
  const currentHour = now.getHours();

  const formatOpenTime = (hours: Schedule): string => {
    const openingHour = hours.openingTime.getHours();
    const closingHour = hours.closingTime.getHours();
    if (hours.dayOfWeek === currentDay && currentHour >= openingHour && currentHour < closingHour) {
      return `Open today until ${formatTime(hours.closingTime, timeZone)}`;
    }

    let day = 'today';
    if (hours.dayOfWeek !== currentDay) {
      day = DayNames.get(hours.dayOfWeek) as string;
    }

    return `Closed | Opens ${formatTime(hours.openingTime, timeZone)} ${day}`
  }

  for (let d = currentDay; d < currentDay + 7; d++) {
    const lookupDay = d % 7;
    const daySchedule = schedule.find((hours) => hours.dayOfWeek === lookupDay);
    if (!daySchedule) {
      continue;
    }

    if (daySchedule.closingTime.getHours() < currentHour) {
      // Location is closed, keep looking for next opening.
      continue;
    }

    return formatOpenTime(daySchedule);
  }

  // Somehow didn't find an open time.
  return '';
}

/**
 * Parses the string times from the api to date objects.
 * @param openingHours
 */
export const convertHours = (openingHours: LocationOpeningHours[]): Schedule[] => {
  const schedule: Schedule[] = [];
  if (!openingHours?.length) {
    return schedule;
  }

  for (const hours of openingHours) {
    try {
      schedule.push({
        dayOfWeek: hours.dayOfWeek,
        openingTime: new Date(hours.openingTime),
        closingTime: new Date(hours.closingTime),
      });
    } catch (e) {
      logger.Warning('Failed to parse hours', hours);
    }
  }

  return schedule;
}

/**
 * Formats the parsed open times to string.
 * Groups consecutive days with the same schedule.
 * If any day is missing marks it as closed.
 * ex: Mon: 9AM - 5PM, Tue - Fri: 10AM - 6PM, Sat - Sun: CLOSED
 * @param schedule
 * @param timeZone
 */
export const formatHours = (schedule: Schedule[], timeZone: string): string[] => {
  if (!schedule?.length) {
    return [];
  }

  const scheduleMap = new Map<number, Schedule>()
  schedule.forEach((hours) => scheduleMap.set(hours.dayOfWeek, hours))

  const formattedHours: { day: string, hours: string }[] = [];
  for (let i = 1; i <= 7; i++) {
    const hours = scheduleMap.get(i);
    const dayOfWeek = DayNames.get(i) as string;
    if (!hours) {
      formattedHours.push({day: dayOfWeek as string, hours: 'CLOSED'});
      continue;
    }

    formattedHours.push({day: dayOfWeek, hours: `${formatTime(hours.openingTime, timeZone)} - ${formatTime(hours.closingTime, timeZone)}`});
  }

  // Group consecutive days which have the same hours.
  const groupedDays: { day: string, hours: string }[] = [];
  for (let i = 0; i < formattedHours.length; i++) {
    let hours = formattedHours[i];

    // Look forward to the last consecutive day with matching hours, if any exist.
    let endDay;
    let j = i + 1;
    for (; j < formattedHours.length; j++) {
      let nextHours = formattedHours[j];
      if (hours.hours != nextHours.hours) {
        j--;
        break
      }
      endDay = nextHours.day
    }

    // No matches, leave the record as-is.
    if (!endDay) {
      groupedDays.push(hours);
      continue;
    }

    // Group the matching days and skip past the last day.
    hours.day = `${hours.day} - ${endDay}`;
    i = j;
    groupedDays.push(hours);
  }

  return groupedDays.map((hours) => `${hours.day}: ${hours.hours}`);
}
