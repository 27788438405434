// third-party imports
import { isEmpty, last } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { ErrorAlertMessage } from "../../components/AlertMessage";
import SearchBar from "../../components/SearchField";
import { setLocalErrorMessage } from "../../redux/app/actions";
import { addBagToteBarcodePairToDatabase, updateBagToteBarcodePair } from "../../redux/customer/actions";
import { Store as CustomerStore } from "../../redux/customer/types";
import { RootReducer } from "../../redux/reducers";
import { DataCyStrings } from "../../types/DataCyStrings";
import { isInvalidStoreNumber } from "../../utility";
import useSelector from "../../utility/useTypedSelector";

// local imports
import $TypeStoreNumber from "./styles";
import logger from "../../utility/logger";

class TypeStoreNumberLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, app, dispatch, customer) {
    super(page, app, dispatch);
    this.customer = customer;
  }

  satisfiesPreconditions(): boolean {
    if (!super.satisfiesPreconditions()) return false;
    if (!this.customer?.returns?.returning) return false;
    if (!this.customer?.bagToteBarcodePairs?.[0]?.bagBarcode) return false;

    logger.Info("Preconditions satisfied. Rendering TypeStoreNumber...");
    return true;
  }
}

export const TypeStoreNumberErrorMessage = "Store number must be at least one alphanumeric character.";

/**
 *
 */
const TypeStoreNumber = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { app, customer } = useSelector((store: RootReducer) => store);

  const { bagToteBarcodePairs, returns } = customer;

  const { loadingMessage, localErrorMessage } = app;

  const lifecycle = new TypeStoreNumberLifecycle(page, dispatch, app, customer);

  const [storeNumber, setStoreNumber] = useState("");

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    dispatch(setLocalErrorMessage(""));

    window.goBack = () => {
      lifecycle.advance("confirmationScanTote");
    };
  }, []);

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const handleChange = (value) => {
    dispatch(setLocalErrorMessage(""));
    setStoreNumber(value.toUpperCase());
  };

  const handleClear = () => {
    dispatch(setLocalErrorMessage(""));
    setStoreNumber("");
  };

  const handleSubmit = () => {
    if (loadingMessage) return;

    if (isInvalidStoreNumber(storeNumber)) {
      dispatch(setLocalErrorMessage(TypeStoreNumberErrorMessage));
      return;
    }

    if (isEmpty(bagToteBarcodePairs)) return;

    const currentPair = last(bagToteBarcodePairs);

    const updatedPair = {
      bagBarcode: currentPair.bagBarcode,
      toteBarcode: storeNumber,
    };

    // do not persist bag, tote scans in DB if using test order
    if (returns?.confirmationCode === "HRTESTER") {
      dispatch(updateBagToteBarcodePair(updatedPair));
    } else {
      dispatch(addBagToteBarcodePairToDatabase(updatedPair));
    }

    lifecycle.advance();
  };

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  const renderErrorMessage = () => {
    if (!localErrorMessage) return;

    return (
      <div className={"error-message-container"}>
        <ErrorAlertMessage message={localErrorMessage}></ErrorAlertMessage>
      </div>
    );
  };

  return (
    <$TypeStoreNumber>
      <SearchBar
        inputDataCyString={DataCyStrings.storeNumberInput}
        onChange={handleChange}
        onClear={handleClear}
        onSubmit={handleSubmit}
        placeholder={"Enter your store number"}
        value={storeNumber}
      />
      {renderErrorMessage()}
    </$TypeStoreNumber>
  );
  //----------------------------------------------------------------------------
};

export default TypeStoreNumber;
