import React from "react";
import PrimaryButton from "../Button/PrimaryButton";
import { SVG } from "../svg";
import $BlockedMessage from "./styles";
import getTranslator from "../../utility/getTranslator";
import { DataCyStrings } from "../../types/DataCyStrings";
import { reset } from "../../redux/customer/actions";
import { goToPage, setToken } from "../../redux/app/actions";
import { useDispatch } from "react-redux";

const useTranslation = getTranslator("ReturnExpired");


export const BlockedMessage = ({expressCode, errorMessage, subMessage, blockedErrorIcon}) => {
  const {t} = useTranslation()
  const header = t('expressCode', {code: expressCode})
  const dispatch = useDispatch();
  const onStartNewReturn = () => {
    //Update the URL to the homepage
    window.history.replaceState(null, document.title, window.location.pathname);
    dispatch(reset());
    dispatch(setToken(''))
    dispatch(goToPage("loginReturnPortal"));
  }
  return (
    <$BlockedMessage >
      <div className="blocked-message-container">
        {!!blockedErrorIcon && blockedErrorIcon === 'error' &&
          <div><SVG name="circleError" data-cy={DataCyStrings.errorIcon} /></div>
        }
        <div className="express-code" data-cy={DataCyStrings.expressCode}>{header}</div>
        <div className="message" data-cy={DataCyStrings.errorMessage}>
          <span className="addBreak">{errorMessage}</span> <span >{subMessage}</span>
        </div>
        <PrimaryButton
          onButtonClick={onStartNewReturn}
          label={t('startNewReturnBtn')}
          width="157"
          dataCyString={DataCyStrings.submitNewReturnButton}
        />
      </div>
    </$BlockedMessage>
  )
}

export default BlockedMessage;
