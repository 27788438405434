// third-party imports
import React, { useState } from "react";
import { useSelector, useDispatch} from "react-redux";

// repo imports
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import { SVG } from "../../components/svg";

// local imports
import $MissingReturnEmail from  "./styles";
import TextInput from "../../components/TextInput";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { setNewReturnEmail } from "../../redux/customer/actions";
import { isValidEmail } from "../../utility";

class MissingReturnEmailLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

/**
 * Used when a return has no associated email.
 */
const MissingReturnEmail = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { app } = useSelector((store: RootReducer) => store);
  const lifecycle = new MissingReturnEmailLifecycle(page, dispatch, app);
  const [email, setEmail] = useState("");
  const canSubmit = isValidEmail(email);
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const onEmailSubmit = (e) => {
    e?.preventDefault();
    dispatch(setNewReturnEmail(email));
    lifecycle.advance();
  }
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <$MissingReturnEmail>
      <SVG name="email" />
      <div className="header">
        Enter customer email
      </div>
      <div className="description">
        We don’t have an email for this return. Please ask the customer for their email address.
      </div>
      <form onSubmit={(e) => onEmailSubmit(e)}>
        <TextInput value={email} onChange={(val) => setEmail(val)} placeholder="customer@email.com" type="email"/>
        <PrimaryButton disabled={!canSubmit} label="Continue to Confirmation" />
      </form>
    </$MissingReturnEmail>
  );
  //----------------------------------------------------------------------------
}

export default MissingReturnEmail;
