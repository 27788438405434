import { Retailer } from "./Retailer";
import { StringMap } from "./StringMap";

export interface Address {
  country?: string;
  latitude?: number;
  longitude?: number;
}

export interface Instance {
  id?: string;
  // for frontend rendering purposes only
  purchaseID?: string;
  return?: Return;
  purchase: Purchase;
  reason?: string;
  reasonInfo?: Reason;
  childReason?: string;
  childReasonInfo?: Reason;
  reasonNote?: string;
  paymentMethod?: string;
  refund: Refund;
  createdAt?: string;
  condition?: string;
  label?: object;
  refundObject?: object;
  name?: string;
  thumbnail?: string;
  overridden?: boolean;
  display: Array<Display>;
}

export interface Return {
  id: string;
  when?: string;
  returning?: Array<Instance>;
  subtotal?: string;
  total?: string;
  adjustments?: Array<StringMap>;
  distributions?: Array<StringMap>;
  reason: string;
  reasonInfo: Reason;
  customerIdentity: CustomerIdentity;
  confirmationCode: string;
  dropoffMethod?: ReturnDropoffMethod;
  channel?: ReturnChannel;
  returnlessRefund?: boolean;
  invoiceURL: string;
  retailer: Retailer;
}

export interface ReturnExpiration {
  returnID: string;
  createdAt: string;
  expiredAt?: string;
  expiryDate?: string;
}

export interface ReturnChannel {
  id: string;
}

export interface ReturnDropoffMethod {
  methodID: string;
}

export interface Reason {
  id: string;
  label: string;
  type?: string;
  position: number;
  retailer_category?: string;
  pkey: number;
  child_reasons: Reason[];
}

export interface Purchase {
  id: string;
  retailerID?: string;
  name?: string;
  returnable?: number;
  returnability?: string;
  returnabilityReason?: string;
  exchangeable?: boolean;
  mustBeManual?: boolean;
  mailOnly?: boolean;
  thumbnail?: string;
  images?: Array<string>;
  when?: string;
  fulfillmentDate?: string;
  details: StringMap;
  price?: string;
  display: Array<Display>;
  email?: string;
  returnGroup?: string;
  sku?: string;
  upc?: string;
  returnlessRefund?: boolean;
  vendor?: string;
  orderNumber: string;
  shippingAddress?: Address;
}

export interface Refund {
  id: string;
  instances?: Array<Instance>;
  subtotal?: string;
  total?: string;
  adjustments?: Array<StringMap>;
  distributions?: Array<StringMap>;
  processingFeeStatus?: string;
  optionID: string;
  label?: string;
  sublabel?: string;
  exchange?: Purchase;
}

export interface Order {
  orderNumber: string | number;
  when: string;
  purchases: Array<Purchase | Instance>;
}

export interface ExchangeProperties {
  exchangeOptions: ExchangeOption[];
  exchangeAttributes: ExchangeAttribute[];
}

export interface ExchangeOption {
  attributes: StringMap;
  purchase: Purchase;
  quantity: Number;
}

export interface ExchangeAttribute {
  name: string;
  values: string[];
}

export interface CustomerIdentity {
  authType?: string;
  query: string;
  orderNumber?: string;
  zipCode?: string;
  email?: string;
  isGift?: boolean;
  locale?: string;
}

export interface Display {
  label: string;
  value?: string;
}

export enum PurchaseReturnability {
  FinalSale = "FINAL SALE",
  ReturnStarted = "RETURN STARTED",
  AlreadyReturned = "ALREADY RETURNED",
  ContactCustomerSupport = "CONTACT CUSTOMER SUPPORT",
  PastReturnWindow = "PAST RETURN WINDOW",
  NotYetShipped = "NOT YET SHIPPED",
  ReturnabilityPendingDeliveryStatusUpdate = "PENDING DELIVERY STATUS UPDATE",
  MailOnly = "Mail Only - This item not returnable at Return Bars",
}
