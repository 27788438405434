import styled from "styled-components";
import PrimaryButton from "../../components/Button/PrimaryButton";
import $FooterBase from "../../components/FooterBase/styles";
import { $Button } from "../../components/Button/styles";
import { $TextArea } from "../../components/TextArea/styles";

export const $ReasonNotesNonModal = styled.div`
  width: 100%;
  padding: 24px;
`;

export const $ReasonNotesModal = styled.div`
  text-align: center;

  .submsg {
    text-align: left;
    margin-bottom: 8px;
    font-size: 14px;
  }


  ${$TextArea} {
    font-size: 16px;
  }

  ${$Button} {
    margin-top: 12px;
    width: 100%;
  }

  padding: 24px;
`

export const $ReturnReasonFooter = styled<any>($FooterBase)`
  margin-top: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 768px){
    &.ios {
      margin-bottom: 0px;
    }
  }
  .return-reason {
    display: flex;
    flex-direction: column;
    .selected-return-reason {
      color: var(--header-grey);
    }
    .reason-label {
      color: var(--text-black);
    }
  }
  button {
    width: 105px;
  }
`