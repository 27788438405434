import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

const $ContactInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: auto;
  text-align: center;
  padding-top: 24px;

  .version-id {
    margin-top: 8px;
    font-size: 13px;
    color: var(--version-id-grey);

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-top: 24px;
      font-size: 18px;
    }
  }
  .android & {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0px;
    padding-top: 12px;
    .need-help {
      font-size: 14px;
      font-weight: 700;
    }
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 18px;
    line-height: 27px;
    padding-top: 0;
    margin-bottom: 40px;
  }
`;

export default $ContactInfo;
