import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../redux/returnista-store";
import { CurrentPage, FeatureFlagsAction } from "../redux/enums";
import Login from "../pages/ReturnistaLogin";
import StartReturn from "../pages/ReturnistaStartReturn";
import { $AppContent } from "./styles";
import ReturnistaSuccessCodeScan from "../pages/ReturnistaSuccessCodeScan";
import ReturnistaInitializer from "../pages/ReturnistaInitializer";
import { LoadingIndicator } from "../components/LoadingIndicator";
import ReturnistaCodeScan from "../pages/ReturnistaCodeScan";
import ReturnistaSuccessBagScan from "../pages/ReturnistaSuccessBagScan";
import ReturnistaItemSelector from "../pages/ReturnistaItemSelector";
import ReturnistaBagCodeScan from "../pages/ReturnistaBagCodeScan";
import { onBackHandler } from "../components/ReturnistaHeader";
import ReturnistaItemCodeScan from "../pages/ReturnistaItemCodeScan";
import ItemDetails from "../pages/ReturnistaItemDetails";
import ReturnistaReturnSummary from "../pages/ReturnistaReturnSummary";
import ReturnistaReturnAccepted from "../pages/ReturnistaReturnAccepted";
import ReturnistaFatalError from "../pages/ReturnistaFatalError";
import ReturnistaTimer from "../utility/ReturnistaTimer";
import ReturnistaAnalytics, { ReturnistaAnalyticsErrors } from "../utility/ReturnistaAnalytics";
import { RenderReturnistaProps } from "./types";
import ReturnistaModalContainer from "../components/Modal/ReturnistaModalContainer";
import ReturnistaBagCount from "../pages/ReturnistaBagCount";
import ReturnistaPrintBagLabels from "../pages/ReturnistaPrintBagLabels";
import ReturnistaRequestSupportConfirmation from "../pages/ReturnistaRequestSupportConfirmation";
import ga from "../utility/GAEmitter";

const App = ({ featureFlags }: RenderReturnistaProps) => {
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const { currentPage, loading, modal } = store;

  useEffect(() => {
    if (featureFlags) {
      dispatch({ type: FeatureFlagsAction.Set, payload: featureFlags });
    }
  }, [featureFlags]);

  useEffect(() => {
    // Set handlers to report error if called unexpectedlsy
    window.enterCode = () => {
      ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedEnterCode);
    };
    window.noBarcode = () => {
      ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedNoBarcode);
    };

    // Handle back event emitted from app wrappers
    window.goBack = () => {
      onBackHandler(dispatch, true);
    };

    // Handle native back event from browser back button
    window.addEventListener("popstate", (event) => {
      event.preventDefault();
      event.stopPropagation();
      onBackHandler(dispatch, true);
    });
  }, []);

  if (currentPage === CurrentPage.Login) {
    // Clear inactivity timing if it's the login page
    ReturnistaTimer.clear();
  }

  // Analytics
  ReturnistaAnalytics.startView(currentPage);

  // Any time there is a change in the current page record a new entry in Google Analytics
  useEffect(() => {
    ga.sendPageView(currentPage);
  }, [currentPage]);

  const Page = (() => {
    switch (currentPage) {
      case CurrentPage.FatalError:
        return ReturnistaFatalError;
      case CurrentPage.Initializer:
        return ReturnistaInitializer;
      case CurrentPage.Login:
        return Login;
      case CurrentPage.StartReturn:
        return StartReturn;
      case CurrentPage.ReturnCodeScan:
        return ReturnistaCodeScan;
      case CurrentPage.ReturnCodeComplete:
        return ReturnistaSuccessCodeScan;
      case CurrentPage.BagCodeScan:
        return ReturnistaBagCodeScan;
      case CurrentPage.BagCodeComplete:
        return ReturnistaSuccessBagScan;
      case CurrentPage.ItemCodeScan:
        return ReturnistaItemCodeScan;
      case CurrentPage.ItemSelector:
        return ReturnistaItemSelector;
      case CurrentPage.ItemDetails:
        return ItemDetails;
      case CurrentPage.ReturnSummary:
        return ReturnistaReturnSummary;
      case CurrentPage.BagCount:
        return ReturnistaBagCount;
      case CurrentPage.PrintBagLabels:
        return ReturnistaPrintBagLabels;
      case CurrentPage.ReturnAccepted:
        return ReturnistaReturnAccepted;
      case CurrentPage.SupportConfirmation:
        return ReturnistaRequestSupportConfirmation;
      default:
        return Login;
    }
  })();

  return (
    <$AppContent>
      {loading.isLoading ? <LoadingIndicator message={loading.message} /> : null}
      <ReturnistaModalContainer {...modal} />
      <Page />
    </$AppContent>
  );
};

export default App;
