import React, { useEffect, useState } from "react";

import { SVG } from "../../../components/svg";
import {
  AnalyticCategories,
  AnalyticsPageRoutes,
  ReturnStartedAndReturnStatusPageActions,
} from "../../../types/Analytics";
import { DataCyStrings } from "../../../types/DataCyStrings";
import { ReturnStatus } from "../../../types/ReturnStatus";
import { getAdminMode, isMobile } from "../../../utility";
import ga from "../../../utility/GAEmitter";
import getTranslator from "../../../utility/getTranslator";
import useSelector from "../../../utility/useTypedSelector";

import { ReturnStatusComposite } from "../index";

import { StatusBar } from "./StatusBar";
import { $ReturnStatusCard } from "./styles";

export const ReturnStatusCard = ({
  dropoffMethodID,
  departureDate,
  deliveryDate,
  expressCode,
  returnStatus,
}: ReturnStatusComposite) => {
  const { copies, locale } = useSelector((store) => store.app);
  const retailerName = copies?.retailerName;
  const [hasSentPageVisitGA, setHasSentPageVisitGA] = useState<boolean>(false);

  const { t } = getTranslator("ReturnStatusCard")();

  const isApprovedState = () => {
    return (
      returnStatus === ReturnStatus.complete ||
      returnStatus === ReturnStatus.partial ||
      returnStatus === ReturnStatus.keptInStore
    );
  };

  const getReturnStatusMessage = () => {
    if (returnStatus === ReturnStatus.expired) {
      return t("headerExpired");
    }

    if (isApprovedState()) {
      return t("headerApproved");
    }

    if (deliveryDate) {
      return t("headerDelivered");
    }

    if (departureDate) {
      return t("headerHasDeparted");
    }

    return t("headerStarted");
  };

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: getAdminMode(),
        retailer_name: retailerName,
        locale: locale,
        return_status: returnStatus,
        dropoff_method: dropoffMethodID,
        // TODO: add status bar state
      },
    });
    ga.sendPageDetails(AnalyticsPageRoutes.ReturnStatus, AnalyticCategories.ReturnStatusPage);
    if (!hasSentPageVisitGA) {
      ga.event({
        category: AnalyticCategories.ReturnStatusPage,
        action: ReturnStartedAndReturnStatusPageActions.ReturnStatusCardViewed,
      });
      setHasSentPageVisitGA(true);
    }
  }, []);

  return (
    <$ReturnStatusCard>
      <div className="express-code" data-cy={DataCyStrings.returnStatusExpressCode}>
        {t("subheaderExpressCode", { expressCode })}
      </div>
      <div
        className={isMobile() ? "return-status-message-mobile" : "return-status-message"}
        data-cy={DataCyStrings.returnStatusHeader}
      >
        {isApprovedState() && <SVG name="circleCheckFilled" />}
        {getReturnStatusMessage()}
      </div>
      <StatusBar
        deliveryDate={deliveryDate}
        departureDate={departureDate}
        dropoffMethodID={dropoffMethodID}
        returnStatus={returnStatus}
      />
    </$ReturnStatusCard>
  );
};
