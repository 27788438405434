/**
 * Shareable styled components between pages
 */
import styled from "styled-components";

export const $SearchPageTemplate = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  .error-message-container {
    width: 100%;
    padding: 24px;
  }
`;