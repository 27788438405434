import axios, { AxiosError, AxiosResponse } from "axios";
import { createAction } from "@reduxjs/toolkit";
import { isEmpty } from 'lodash';
import logger from "../../utility/logger/logger";
import "regenerator-runtime/runtime";
import {
  connectionTimeoutMilliseconds,
} from "../../utility";
import { GetState } from "../reducers";
import {
  showLoadingIndicator,
  clearLoadingIndicator,
  setLocalErrorMessage,
  goToPage,
  showAlert
} from "../../redux/app/actions";
import {
  Returns,
  ReturnTypes,
  Store,
  SET_RETURNS,
  MARK_ITEM_COMPLETE,
  UNMARK_ITEM_COMPLETE,
  SET_EXPRESS_CODE,
  RESET_CUSTOMER,
  ADD_BARCODE,
  DELETE_BARCODE,
  ADD_BAG_TOTE_BARCODE_PAIR,
  DELETE_BAG_TOTE_BARCODE_PAIR,
  UPDATE_BAG_TOTE_BARCODE_PAIR,
  SET_RETURN_TYPE,
  SET_CUSTOMER_ORDERS,
  SET_SELECTED_INSTANCE,
  DESELECT_COMPLETED_INSTANCE_WITH_ID,
  SET_QUERY,
  SET_RETURN_REASON_SELECTED_INSTANCE,
  SET_RETURN_REASON_CHILD_SELECTED_INSTANCE,
  RESET_RETURN_REASON_CHILD_SELECTED_INSTANCE,
  SET_RETURN_NOTES_SELECTED_INSTANCE,
  SET_SELECTED_INSTANCE_FOR_COMPLETION,
  SET_SELECTED_INSTANCE_EXCHANGE_PROPERTIES,
  SET_SELECTED_INSTANCE_EXCHANGE,
  SET_NEW_RETURN_ID,
  SET_GIFT_RETURN_EMAIL,
  RESET_CUSTOMER_ORDERS,
  SET_NEW_RETURN_EMAIL,
  SET_CHANGE_DROPOFF_RETURN_ID,
  SET_SELECTED_DROPOFF_METHOD,
  RESET_ITEMS_MARKED_FOR_COMPLETION,
  SET_REFUND_OPTIONS,
  SET_SELECTED_REFUND,
  SET_CUSTOMER_STORE,
  SET_PURCHASING,
  SET_RETURN_SESSION_ID,
  ADD_FEATURE_STUDY_ID,
  SET_RETURN_STATUS_RETURN_ID,
  SET_RETAILER,
  SET_SHIPPING_ADDRESS_COORDINATES,
} from "./types";
import { ExchangeProperties, Instance, Order, Reason, Refund, Purchase } from "../../types/Instance";
import { Alerts } from "../../types/LifeCycle";
import { DropoffMethod, ReturnBarDropoffMethod } from "../../pages/DropoffMethods";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import { AppRuntimes } from "../../types/AppRuntimes";
import { BagToteBarcodePair } from "../../types/BagToteBarcodePair";
import { Retailer } from "../../types/Retailer";

//---------------------------------------------------------------------------
// ACTIONS ------------------------------------------------------------------
// https://redux.js.org/faq/actions#actions
// Actions are consumed by their respective reducer and
// alter store depending on the action type within the
// reducer's switch case

export const setReturns = returns => ({
  type: SET_RETURNS,
  payload: returns,
});

// takes as input an item's ID (technically, the ID of a "returning" from this store's returns object)
// updates itemsMarkedAsComplete array in the store to say that item's return is supposed to be completed
export const markItemForReturnCompletion = id => ({
  type: MARK_ITEM_COMPLETE,
  payload: id,
})

// similar to markItemForReturnCompletion but removes instead
export const unmarkItemForReturnCompletion = id => ({
  type: UNMARK_ITEM_COMPLETE,
  payload: id
})

// expressCode: string representing an HR express code
export const setExpressCode = expressCode => ({
  type: SET_EXPRESS_CODE,
  payload: expressCode,
});

// wipe out this slice of the store entirely
export const reset = () => ({
  type: RESET_CUSTOMER,
})

export const addBarcode = (barcode) => ({
  type: ADD_BARCODE,
  payload: barcode,
})

export const deleteBarcode = (barcode) => ({
  type: DELETE_BARCODE,
  payload: barcode,
})

export const addBagToteBarcodePair = (pair: BagToteBarcodePair) => ({
  type: ADD_BAG_TOTE_BARCODE_PAIR,
  payload: pair,
});

export const deleteBagToteBarcodePair = (pair: BagToteBarcodePair) => ({
  type: DELETE_BAG_TOTE_BARCODE_PAIR,
  payload: pair,
});

export const updateBagToteBarcodePair = (pair: BagToteBarcodePair) => ({
  type: UPDATE_BAG_TOTE_BARCODE_PAIR,
  payload: pair,
});

export const setReturnType = (returnType: ReturnTypes) => ({
  type: SET_RETURN_TYPE,
  payload: returnType
})

export const setCustomerOrders = (orders: Order[], query: string) => ({
  type: SET_CUSTOMER_ORDERS,
  payload: {
    orders,
    query
  }
})

export const resetCustomerOrders = () => ({
  type: RESET_CUSTOMER_ORDERS
})

export const setSelectedInstance = (instance?: Instance) => ({
  type: SET_SELECTED_INSTANCE,
  payload: instance,
})

export const deselectInstanceWithID = (id?: string) => ({
  type: DESELECT_COMPLETED_INSTANCE_WITH_ID,
  payload: id
})

export const setQuery = (query: string) => ({
  type: SET_QUERY,
  payload: query
})

export const setReturnReasonSelectedInstance = (reasonInfo: Reason) => ({
  type: SET_RETURN_REASON_SELECTED_INSTANCE,
  payload: reasonInfo
})

export const setReturnReasonChildSelectedInstance = (reasonChild: Reason) => ({
  type: SET_RETURN_REASON_CHILD_SELECTED_INSTANCE,
  payload: reasonChild
})

export const resetReturnReasonChildSelectedInstance = () => ({
  type: RESET_RETURN_REASON_CHILD_SELECTED_INSTANCE,
})

export const setReturnNotesSelectedInstance = (notes) => ({
  type: SET_RETURN_NOTES_SELECTED_INSTANCE,
  payload: notes
})

//TODO: Create interface so, email is not required
export const setNewReturnID = (id, email) => ({
  type: SET_NEW_RETURN_ID,
  payload: {
    id,
    email
  }
})

export const setGiftReturnEmail = (email) => ({
  type: SET_GIFT_RETURN_EMAIL,
  payload: email
})

/**
 * Saves the return option (refund) to the instance
 * and marks it for completion
 * */
export const setSelectedInstanceForCompletion = (refund: Refund) => ({
  type: SET_SELECTED_INSTANCE_FOR_COMPLETION,
  payload: refund
})

export const setSelectedInstanceExchangeProperties = (exchangeProperties: ExchangeProperties) => ({
  type: SET_SELECTED_INSTANCE_EXCHANGE_PROPERTIES,
  payload: exchangeProperties
})

export const setSelectedInstanceExchange = (purchase: Purchase) => ({
  type: SET_SELECTED_INSTANCE_EXCHANGE,
  payload: purchase
})

export const setNewReturnEmail = (email: string) => ({
  type: SET_NEW_RETURN_EMAIL,
  payload: email
})

export const setChangeDropoffReturnID = (returnID: string) => ({
  type: SET_CHANGE_DROPOFF_RETURN_ID,
  payload: returnID
})

export const setReturnStatusReturnID = (returnID: string) => ({
  type: SET_RETURN_STATUS_RETURN_ID,
  payload: returnID
})

//TODO: Fix typing
export const setSelectedDropoffMethod = (dropoffMethod: DropoffMethod | ReturnBarDropoffMethod | null) => ({
  type: SET_SELECTED_DROPOFF_METHOD,
  payload: dropoffMethod
})

export const setShippingAddressCoordinates = (coordinates: { latitude: number; longitude: number }) => ({
  type: SET_SHIPPING_ADDRESS_COORDINATES,
  payload: coordinates,
});

export const resetItemsMarkedForCompletion = createAction(RESET_ITEMS_MARKED_FOR_COMPLETION);
export const TypeBagBarcodeBagReusedErrorMessage = "Use a new bag for every return, don't add these items to a previously used bag.";

export const setRefundOptions = (refundOptions: Refund[]) => ({
  type: SET_REFUND_OPTIONS,
  payload: refundOptions
})

export const setSelectedRefund = (refund: Refund) => ({
  type: SET_SELECTED_REFUND,
  payload: refund
})

export const setCustomerStore = (store: Store) => ({
  type: SET_CUSTOMER_STORE,
  payload: store
})

export const setPurchasing = (cartData: any) => ({
  type: SET_PURCHASING,
  payload: cartData
})

export const setReturnSessionID = (id: string) => ({
  type: SET_RETURN_SESSION_ID,
  payload: id
})

export const addFeatureStudyID = (featureStudyID: string) => ({
  type: ADD_FEATURE_STUDY_ID,
  payload: featureStudyID
})

export const setRetailer = (retailer: Retailer) => ({
  type: SET_RETAILER,
  payload: retailer
})

//---------------------------------------------------------------------------

//---------------------------------------------------------------------------
// ASYNC ACTIONS ------------------------------------------------------------
// (uses redux-thunk to send actions to the reducer asynchronously)
// https://github.com/reduxjs/redux-thunk
/**
 * pull down returns from the server, and save in store upon success
 * nextPage is needed here as in certain runtimes (currently returnista)
 * a user needs to be able to go between the scan/type QR page and the
 * InstanceList page
 * @config query           - Optional query param for runtimes that don't parse
 *                           the token when fetching a return via express code
 * @config nextPage        - If defined, the user will be directed to nextPage
 *                           if the response is a 200
 * @config onSuccess       - If defined, this function runs after the user is
 *                           able to receive a 200. If this function returns
 *                           TRUE, allow the function to continue, if FALSE,
 *                           end the fetchReturns call and prevent the response
 *                           from being stored in our redux store
 * @config onError         - If defined, when any error occurs including network
 *                           errors, this function will run using the caught error
 */
export function fetchReturns(config:{
  query?: string,
  nextPage?: string,
  onError?: (error: AxiosError | Error, dispatch: Function) => any,
  onSuccess?: (response: AxiosResponse<Returns>, dispatch: Function) => boolean
}) {
  return async function(dispatch) {
    const { query, nextPage, onError, onSuccess } = config;
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      let url = "/returns";
      // if a query is defined and isn't empty, append it to the url var
      if (query && query != "") {
        url += `?confirmationCode=${query}`
      }
      const response = await axios.get<Returns>(url);

      if (response?.data && isEmpty(response.data)) {
        throw "empty response to /returns"
      }

      // before we send the data to the store, we remove all completed items
      response.data.returning = response.data.returning.filter((item) => {
        return !item.refundObject;
      })

      // call onSuccess and check if returns should be saved
      const shouldSetReturns = onSuccess && onSuccess(response, dispatch);
      if (shouldSetReturns) {
        dispatch(setReturns(response.data));
        nextPage && dispatch(goToPage(nextPage));
      }

    } catch (error) {
      error && onError && onError(error, dispatch);
    } finally {
      dispatch(clearLoadingIndicator());
    }
  }
}

// scan barcode tell back-end that a return bag barcode was added
// and save to store on success
export const addReturnBagBarcode = (barcode, runtime?: string, currentPageName?: string, previousPageName = "") => {
  return async function (dispatch, getState) {
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      const { customer } = getState();
      const response = await axios.post("/returns/bags", {
          returnID: customer.newReturnID ? customer.newReturnID : customer?.returns?.id,
          barcode: barcode,
        },
        {timeout: connectionTimeoutMilliseconds}
      );
      dispatch(clearLoadingIndicator());
      dispatch(addBarcode(barcode));
      dispatch(addBagToteBarcodePair({ bagBarcode: barcode, toteBarcode: "" }));

      logger.Info(`Add return bag barcode via the /returns/bags endpoint`);
      logger.Debug("response", response);

      // this is to ensure we go back to the confirmation page after adding a bag
      // perhaps it could be cleaned up by taking advantage of the config
      if (runtime === AppRuntimes.returnista && currentPageName === "typeBagBarcode") {
        if (previousPageName) {
          dispatch(goToPage(previousPageName))
        } else {
          dispatch(goToPage("confirmation"))
        }
      }
    }
    catch (error) {
      dispatch(clearLoadingIndicator());
      console.error(error);
      if (error?.response?.status === 409 && runtime === AppRuntimes.returnista) {
        if (currentPageName === "confirmation") {
          dispatch(showAlert(Alerts.barcodeAlreadyUsed))
        } else if (currentPageName === "typeBagBarcode") {
          dispatch(setLocalErrorMessage(TypeBagBarcodeBagReusedErrorMessage))
        }
      }
      logger.Warning("got error adding return bag barcode", error)
    }
    finally {
      dispatch(clearLoadingIndicator());
    }
  }
}

// tell back-end that a return bag barcode was added
// the style is "call and abandon".  we launch this call, but don't follow up on the results
// axios.delete requires passing data in the data object
export const removeReturnBagBarcode = (barcode) => {
  return async function(dispatch, getState: GetState) {
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      const { customer } = getState();
      const returnID = customer?.returns?.id ?? customer?.newReturnID;
      if (returnID == null) {
        console.error('removeReturnBagBarcode: unable to find returnID');
      } else {
        const response = await axios.delete("/returns/bags", { data: {
            returnID,
            barcode: barcode,
          }}
        )
        dispatch(deleteBarcode(barcode))
        logger.Info(`Delete return bag barcode via the /returns/bags endpoint`);
        logger.Debug("response", response);
      }
    }
    catch (error) {
      logger.Warning("got error removing return bag barcode", error)
    }
    finally {
      dispatch(clearLoadingIndicator());
    }
  }
}

/**
 * Adds bag-tote barcode pair to the database, updating the store if the database transaction succeeds.
 * At least one bag barcode must be scanned and saved to the database prior to calling this function.
 * @param pair {BagToteBarcodePair} The bag-tote barcode pair to be added to the database.
 */
export const addBagToteBarcodePairToDatabase = (pair: BagToteBarcodePair) => {
  return async (dispatch) => {
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));

      await axios.post("/shipping-totes/bags", {
        bagBarcode: pair.bagBarcode,
        shippingToteBarcode: pair.toteBarcode,
      });

      dispatch(updateBagToteBarcodePair(pair));
    } catch (error) {
      logger.Warning("got error adding bag-tote barcode pair", error);
    }

    dispatch(clearLoadingIndicator());
  };
};

export const deleteBagToteBarcodePairFromDatabase = (pair: BagToteBarcodePair) => {
  return async (dispatch) => {
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));

      await axios.delete("/shipping-totes/bags", {
        data: {
          bagBarcode: pair.bagBarcode,
          shippingToteBarcode: pair.toteBarcode,
        },
      });

      dispatch(deleteBagToteBarcodePair(pair));
      dispatch(removeReturnBagBarcode(pair.bagBarcode));
    } catch (error) {
      logger.Warning("got error deleting bag-tote barcode pair", error);
    }
  };
};

/**
 * Used when an order number or email is queried in place of an express code
 */
export const fetchOrders = (config:{
  query?: string
  onError?: (error: AxiosError, dispatch: Function) => any,
  onSuccess?: (response: AxiosResponse<Order[]>, dispatch: Function) => any
}) => {
  const { query, onError, onSuccess } = config;
  return async function(dispatch, getState: GetState) {
    const { customer } = getState();
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      const response = await axios.get<Order[]>(`/purchases${query ? "?q="+encodeURIComponent(query) : ""}`, {
      });
      // map purchases to instances
      response.data.forEach(order => {
        order.purchases = order.purchases.map((purchase: Purchase) => {
          // XXX - this case is not our desired solution as we
          // are relying on the client to send the correct
          // information when completing a gift return. When
          // returns-app ORES is implemented, this logic will
          // live in the backend.
          if (customer.newReturnEmail && customer.returnType === ReturnTypes.giftReturn) {
            purchase.email = customer.newReturnEmail;
          }

          // XXX - there are occasions where we receive display objects from morphs where
          // a key is given but no value is provided. As we have no use case to support this
          // we simply remove any of these objects from the purchase display array
          purchase.display = purchase?.display?.filter(d => {
            return d.label && d.value;
          });

          return {
            purchaseID: purchase.id,
            purchase,
            refund: {},
          } as Instance;
        })
      });



      dispatch(setCustomerOrders(response.data, query));
      onSuccess && onSuccess(response, dispatch);
    } catch (error) {
      error && onError && onError(error, dispatch);
      console.error(error);
      logger.Warning("An issue has occurred while querying purchases.", error);
    } finally {
      dispatch(clearLoadingIndicator());
    }
  }
}
//---------------------------------------------------------------------------
