import React from "react";
import Modal from "react-modal";
import ModalCard from "../ModalCard";
import { ModalContainerProps } from "./type";
import { modalZIndex } from "../../../utility";

function ModalContainer({
  height,
  width,
  isOpen,
  onRequestClose,
  primaryMessage,
  subMessages,
  successMessage,
  closeIcon,
  contentLabel,
  textInput,
  textInputError,
  button,
  img,
  imgPosition,
  iconElement,
  runtime,
  platform,
  styles,
  dataCyString,
}: ModalContainerProps) {
  const overlayStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.48)",
    zIndex: modalZIndex,
  };
  const contentStyle = {
    position: "fixed",
    top: 96,
    margin: "0 auto",
    height: height,
    width: width || "456px",
    overflow: "hidden",
    padding: "0",
    borderRadius: "12px",
    bottom: "1",
  };
  if (platform === "android") {
    overlayStyle["padding"] = "80px 24px";

    contentStyle.position = "static";
    delete contentStyle.top;
    contentStyle.width = "100%";
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={
        styles || {
          overlay: overlayStyle,
          content: contentStyle,
        }
      }
    >
      <ModalCard
        height={height || "456px"}
        onClose={onRequestClose}
        primaryMessage={primaryMessage}
        subMessages={subMessages}
        successMessage={successMessage}
        closeIcon={closeIcon}
        textInput={textInput}
        textInputError={textInputError}
        button={button}
        img={img}
        imgPosition={imgPosition}
        iconElement={iconElement}
        runtime={runtime}
        dataCyString={dataCyString}
      />
    </Modal>
  );
}

export default ModalContainer;
