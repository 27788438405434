import styled from "styled-components";

const $MissingReturnEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;

  .header {
    font-size: 24px;
    line-height: 28px;
    color: #0B0B0B;
    margin: 37px 0 12px 0;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #737373;
    width: 432px;
  }

  form {
    text-align: center;
  }
  input {
    margin: 16px 0 40px 0;
    width: 448px;
    height: 56px;
    -webkit-appearance: none;
  }

  button {
    width: 300px;
    height: 56px;
  }
`;

export default $MissingReturnEmail