import { FeatureFlagsAction } from "../enums";

const featureFlagsReducer = (state = null, action) => {
  switch (action.type) {
    case FeatureFlagsAction.Set:
      return action.payload;
    default:
      return state;
  }
};

export default featureFlagsReducer;
