import React from "react";
import Modal from "react-modal";
import ModalCard from "../ModalCard";
import { ModalContainerProps } from "./type";
import { modalZIndex } from "../../../utility";

import { tabletOrLargerDevices } from "../../../globalConstants";
import { useMediaQuery } from "../../../utility/useMediaQuery";

function ReturnistaModalContainer({
  height,
  isOpen,
  onRequestClose,
  primaryMessage,
  subMessages,
  successMessage,
  closeIcon,
  contentLabel,
  textInput,
  textInputError,
  button,
  img,
  imgPosition,
  iconElement,
  runtime,
  styles,
  dataCyString,
}: ModalContainerProps) {
  const tabletOrLarger = useMediaQuery(`(min-width:${tabletOrLargerDevices})`);

  const overlayStyle = {
    backgroundColor: tabletOrLarger ? "rgba(0, 0, 0, 0.48)" : "none",
    height: "100%",
    top: !tabletOrLarger && "72px",
    zIndex: modalZIndex,
  };
  const contentStyle = {
    position: "fixed",
    top: tabletOrLarger ? "96px" : "72px",
    margin: "0 auto",
    height: height,
    width: tabletOrLarger && "456px",
    overflow: "hidden",
    padding: "0",
    borderRadius: tabletOrLarger && "12px",
    border: tabletOrLarger ? "1px solid rgb(204, 204, 204)" : "none",
    bottom: tabletOrLarger ? "1" : 0,
    left: tabletOrLarger ? "inherit" : 0,
    right: tabletOrLarger ? "inherit" : 0,
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={
        styles || {
          overlay: overlayStyle,
          content: contentStyle,
        }
      }
    >
      <ModalCard
        height={height || "456px"}
        onClose={onRequestClose}
        primaryMessage={primaryMessage}
        subMessages={subMessages}
        successMessage={successMessage}
        closeIcon={closeIcon}
        textInput={textInput}
        textInputError={textInputError}
        button={button}
        img={img}
        imgPosition={imgPosition}
        iconElement={iconElement}
        runtime={runtime}
        dataCyString={dataCyString}
      />
    </Modal>
  );
}

export default ReturnistaModalContainer;
