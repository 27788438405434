import styled from "styled-components";

const FlexCenter = `
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
`;


export const $Background = styled.div`
  ${FlexCenter}
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.48);
  /* safest way to make sure the background doesn't go past 100vw/vh */
  margin: 0 !important;
  z-index: 99999;
  @media (max-width: 768px) {
    height: 100%
  }
`;

export const $Container = styled.div`
  ${FlexCenter}
  z-index: 1;
  width: 456px;
  cursor: auto;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  color: white;
  margin-top: 24px;
`;