import styled from "styled-components";
import { $Button } from "../../Button/styles";
import { $Header } from "../../../app/styles";

const $ModalStyles = styled.div`
  ${$Header} {
    justify-content: center;
    margin-bottom: 20px;
  }
  & > main {
    padding: 24px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: min(5vw, 24px);
      line-height: min(7.22vw, 29px);
      margin: 0 0 8px 0;
      max-width: 512px;
    }
    p {
      font-size: min(4.1667vw, 15px);
      width: min(88.667vw, 408px);
      color: var(--medium-emphasis-black);
      margin: 0;
    }
    p.text-center {
      text-align: center;
    }
    ul {
      padding-left: 22px;
      margin: 0 0 24px 0;
      li {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: var(--medium-emphasis-black);
      }
    }
    ${$Button} {
      margin-top: min(6.6667vw, 32px);
      height: min(13.333vw, 55px);
    }
    button.close {
      margin-top: 34px;
      margin-bottom: 10px;
      font-family: "sofia-pro",sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      text-align: center;
      text-decoration-line: underline;
      text-transform: uppercase;
      border: none;
      background: none;
      color: var(--high-emphasis-black);
      cursor: pointer;
    }
  }
`;

export default $ModalStyles;
