import { BarcodeColumn, Page } from '../types/Config';


//-----------------------------------------------------------------------------
// pages
//
// a set of functional components that display full pages in our application
// 
// they all follow a uniform convention, documented in this file along
// with various data structures they use together
//-----------------------------------------------------------------------------


// Each one of our pages takes in the same type of props, defined here
export type PageProps<T=Page> = {
  page: T,
}

export interface PartnerScanBagsPageConfiguration extends Page {
  pageSettings: {
    barcodeColumns: Array<BarcodeColumn>
  }
}

export interface PartnerSuccessPageConfiguration extends Page {
  allowCloseTab?: boolean
}

export enum LoginOfferings {
  orderNumberZip   = "order-number-zip-code",
  orderNumberEmail = "order-number-email",
  email            = "email",
  giftReturn       = "gift-return"
}
export interface ReturnPortalLoginConfiguration extends Page {
  pageSettings: {
    loginOfferings: {
      default: LoginOfferings,
      allLoginOfferings: LoginOfferings[]
    }
  }
}
export interface ReturnPortalDropoffMethodConfiguration extends Page {
  pageSettings: {
    expandAllDropoffMethods: boolean
  }
}