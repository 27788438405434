import { MultiStoreAction, PuzzleAttemptsAction } from "../enums";

const puzzleAttemptsReducer = (state = 0, action) => {
  switch (action.type) {
    case PuzzleAttemptsAction.Increase:
      return state + 1;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case PuzzleAttemptsAction.Reset:
      return 0;
    default:
      return state;
  }
};

export default puzzleAttemptsReducer;
