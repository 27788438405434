export enum AnalyticCategories {
  UserInteraction = "User Interaction",
  User = "User",

  /*New GA4 categories */
  LoginPage = "login page",
  ItemListPage = "item list page",
  ReturnOptionsModal = "return options modal",
  DropoffMethodsPage = "drop off methods page",
  RefundOptionsPage = "return shopping - refund options page",
  ExchangeModal = "exchange modal",
  ReturnReasonsModal = "return reasons modal",
  ReturnReasonNoteModal = "return reason note modal",
  PreviewPage = "preview page",
  ReturnStartedPage = "return started page",
  ReturnStatusPage = "return status page",
}

export interface BaseEventProps {
  category: string;
  action: string;
  label?: string;
  dimensions?: {
    [key: string]: string;
  };
  pagePath?: string;
}

export enum CommonPageActions {
  MerchantLogo = "merchant logo clicked",
  PrivacyPolicy = "privacy policy link clicked",
  PrivacyPolicyFooter = "privacy policy link in footer clicked",
  PoweredByHappyReturnsLogo = "powered by Happy Returns clicked",
  Impressum = "impressum link clicked",
  BrowserBackButtonRefresh = "browser back button clicked or page refreshed",
  NextStep = "next step clicked",
  BackArrow = "back arrow clicked",
  CloseModal = "close modal clicked",
  OutsideModal = "outside the modal clicked",
}

export enum LoginPageActions {
  StartWithEmailClickFromOrderZipPage = "start with an email clicked from order/zip page",
  StartHereForGiftFromOrderZipPage = "start here for gift clicked from order/zip page",
  StartYourReturnFromOrderZipPage = "start your return clicked from order/zip page",
  StartWithOrderIDFromEmailPage = "start with an order id clicked from email page",
  StartHereForGiftFromEmailPage = "start here for gift clicked from email page",
  EmailLinkStartYourReturnFromEmailPage = "email link to start your return clicked from email page",
  StartHereForNotAGiftFromGiftReturnPage = "start here for not a gift return clicked from gift return page",
  StartYourGiftReturnFromGiftReturnPage = "start your gift return clicked from gift return page",
  StartYourReturnFromOrderEmailPage = "start your return clicked from order/email page",
  TranslationGlobe = "translation globe clicked",
  TranslationGlobeModalClose = "translation globe modal close clicked",
  CustomLinkLogin = "link in customizable login text clicked",
}

export enum ItemListPageActions {
  Item = "item clicked",
  MagnifyingGlass = "magnifying glass clicked",
  CarouselNavigation = "carousel navigation clicked",
  CloseItemModal = "item modal close clicked",
  DeselectItem = "deselected item from return",
  GreyItem = "greyed out item clicked",
  ItemCounter = "item counter clicked at bottom",
}

export enum ReturnOptionsModalActions {
  OneClickExchange = "one click exchange clicked",
  ShowMoreOptions = "show more options clicked",
  Exchange = "exchange clicked",
  StoreCredit = "store credit clicked",
  Refund = "refund clicked",
  ReturnShoppingReturnThisItem = "RS - return this item clicked", //Return Shopping only
  //Existing actions below migrated to GA4
  ReturnOnlyOption = "return is the only option",
  OneClickExchangeDisplayed = "one-click exchange displayed to user",
}

export enum DropoffMethodsActions {
  ShowOtherOptions = "show other options clicked",
  DropoffOption = "drop off option clicked",
  SeeHow = "see how clicked",
  SeeAllReturnBarLocations = "see all return bars locations clicked",
  ShowMore = "show more clicked",
  ShowLess = "show less clicked",
  Icon = "icon clicked",
  StoreCoupon = "store coupon clicked",
  StoreAddress = "store address clicked",
  StoreMileage = "store mileage link clicked",
  StoreGeneralArea = "store general area clicked",
  SeeAllStoreLocations = "see all store locations clicked",
  ChangeDropoffMethod = "change dropoff method page loaded",
  ReturnStatusAction = "return status page loaded",
  NoDropoffMethodsAvailable = "no dropoff methods available"
}

export enum ReturnStatusPageActions {
  ReturnStatusLoaded = "return status page loaded",
  returnDetailsProductImageClicked = "return details product image clicked",
  returnDetailsInfoMessageClicked = "return details info message clicked",
  returnDetailsGettingSectionShowMoreLessClicked = "return details getting section show more less button clicked",
  returnDetailsInYourReturnSectionShowMoreLessClicked = "return details in your return section show more less button clicked"
}

export enum RefundOptionsPageActions {
  ShowMoreOptions = "show more options clicked",
  StoreCreditClicked = "store credit option clicked",
  RefundClicked = "refund option clicked",
  ReturnShoppingClicked = "RS - return shopping option clicked", //Return Shopping only
  ReturnShoppingCheckout = "RS - visiting return shopping checkout",
  DropoffMethodPage = "RS - visiting dropoff method page"
}

export enum ExchangeModalActions {
  Option = "option clicked",
  GreyedOutOption = "greyed out option clicked",
  SelectButton = "select button clicked",
  GreyedOutSelectButton = "greyed out select button clicked",
  StoreCreditButton = "store credit button clicked",
  SkippedReturnReasonNotes = "Skipped return reason notes",
}

export enum ReturnReasonNoteModalActions {
  SaveNotesClicked = "user saved return reason notes",
  ReasonNotesTextBoxClicked = "user clicked on return reason note text box",
  SkipNotesClicked = "user skipped adding return reason notes",
}

export enum PreviewPageActions {
  ReturnShoppingShowMoreInYourReturn = "RS - show more in your return clicked",
  ReturnShoppingShowLessInYourReturn = "RS - show less in your return clicked",
  ReturnShoppingShowMoreInYourCart = "RS - show more in your cart clicked",
  ReturnShoppingShowLessInYourCart = "RS - show less in your cart clicked",
  ReturnShoppingInfoIcon = "RS - info icon clicked",
  ReturnShoppingSubmitReturnGoToCheckout = "RS - submit return and go to checkout clicked",
  SubmitReturn = "submit return clicked",
  CustomText = "custom text clicked",
  ReturnMethod = "return method clicked",
  ReturnShoppingReturnMethod = "RS - return method clicked",
  ProductImage = "product image clicked",
  ReturnShoppingProductImage = "RS - product image clicked",
  ReturnShoppingDiscount = "RS - discount clicked",
  ReturnShoppingMoreIcon = "RS - more icon clicked",
}

export enum ReturnStartedAndReturnStatusPageActions {
  ReturnShoppingCheckoutNow = "RS - checkout now clicked",
  SeeAllLocations = "see all locations clicked",
  DownloadShippingLabel = "download shipping label clicked",
  DownloadPackingSlip = "download packing slip clicked",
  SendToAnotherEmail = "send to another email clicked",
  SendToAnotherEmailSubmit = "send to another email submit clicked",
  AddToWalletButtonLoad = "add to wallet button loaded",
  AddToWalletButtonClick = "add to wallet button clicked",
  AddToWalletButtonFailure = "failed add to wallet button download",
  ReturnShoppingWaiveReturnProcessingFee = "RS - waive return processing fee clicked",
  ReturnShoppingDiscount = "RS - discount clicked",
  ReturnShoppingProductImage = "RS - product image clicked",
  ReturnShoppingMoreIcon = "RS - more icon clicked",
  TrackReturn = "track return button clicked",
  StartANewReturn = "start a new return button clicked",
  ReturnStatusCardViewed = "return status card viewed",
  ChangeDropoffMethod = "change dropoff method link clicked",
}

export enum AnalyticsPage {
  LoginReturnPortal = "loginReturnPortal",
  OrderList = "orderList",
  ReturnOptions = "returnOptions",
  DropoffMethods = "dropoffMethods",
  RefundOptions = "refundOptions",
  ReturnReasons = "returnReasons",
  Exchange = "exchange",
  ReasonNotes = "reasonNotes",
  Preview = "preview",
  ReturnStarted = "returnStarted",
}

export const PageToCategoryMap = new Map<string, AnalyticCategories>([
  [AnalyticsPage.LoginReturnPortal, AnalyticCategories.LoginPage],
  [AnalyticsPage.OrderList, AnalyticCategories.ItemListPage],
  [AnalyticsPage.ReturnOptions, AnalyticCategories.ReturnOptionsModal],
  [AnalyticsPage.ReturnReasons, AnalyticCategories.ReturnReasonsModal],
  [AnalyticsPage.RefundOptions, AnalyticCategories.RefundOptionsPage],
  [AnalyticsPage.DropoffMethods, AnalyticCategories.DropoffMethodsPage],
  [AnalyticsPage.Exchange, AnalyticCategories.ExchangeModal],
  [AnalyticsPage.ReasonNotes, AnalyticCategories.ReturnReasonNoteModal],
  [AnalyticsPage.Preview, AnalyticCategories.PreviewPage],
  [AnalyticsPage.ReturnStarted, AnalyticCategories.ReturnStartedPage],
]);

export const getCategoryFromPageName = (pageName) => {
  let category = PageToCategoryMap.get(pageName);
  let categoryAsString = "";
  if (!category) {
    categoryAsString = "unable to find category for page - " + pageName;
  } else {
    categoryAsString = category.toString();
  }
  return categoryAsString;
};

export const getCategoryFromPageOrModalName = (pageName, modalName) => {
  const category = getCategoryFromPageName(modalName);
  if (category.indexOf("unable to find") > -1) {
    return getCategoryFromPageName(pageName);
  }
  return category;
};

export enum AnalyticsPageRoutes {
  Exchange = "/exchange",
  DropoffMethods = "/dropoffMethods",
  ItemList = "/itemlist",
  RefundOptions = "/refundOptions",
  Login = "/login",
  ReturnOptions = "/returnOptions",
  ReturnReasons = "/returnReasons",
  ReturnReasonNote = "/returnReasonNote",
  Preview = "/preview",
  ReturnStarted = "/returnStarted",
  ReturnStatus = "/returnStatus",
}

export const CategoryToRouteMap = new Map<AnalyticCategories, AnalyticsPageRoutes>([
  [AnalyticCategories.LoginPage, AnalyticsPageRoutes.Login],
  [AnalyticCategories.ItemListPage, AnalyticsPageRoutes.ItemList],
  [AnalyticCategories.ReturnOptionsModal, AnalyticsPageRoutes.ReturnOptions],
  [AnalyticCategories.ReturnReasonsModal, AnalyticsPageRoutes.ReturnReasons],
  [AnalyticCategories.RefundOptionsPage, AnalyticsPageRoutes.RefundOptions],
  [AnalyticCategories.DropoffMethodsPage, AnalyticsPageRoutes.DropoffMethods],
  [AnalyticCategories.ExchangeModal, AnalyticsPageRoutes.Exchange],
  [AnalyticCategories.PreviewPage, AnalyticsPageRoutes.Preview],
  [AnalyticCategories.ReturnStartedPage, AnalyticsPageRoutes.ReturnStarted],
]);

export const getPathFromCategory = (category) => {
  const path = CategoryToRouteMap.get(category as AnalyticCategories);
  if (!path) {
    return "/unknown";
  }
  return path.toString();
};
