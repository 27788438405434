import styled from 'styled-components';

const $TextInput = styled.div`
  label {
    font-weight: 500;
    font-size: 14px;
    color: #221F1F;
    margin-bottom: 4px;
  }
  input {
    -webkit-appearance: none;
    padding-left: 16px;
    height: 44px;
    width: 100%;
    border: 2px solid #DBDBDB;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    &:focus {
      outline: none;
      border: 2px solid white;
      box-shadow: 0 0 0 2px #221F1F;
    }
  }
`;

export {
  $TextInput,
}
