import React from "react";

const GreenCheckIcon = (props) => (
  <>
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM10.2483 4.18945C10.5579 4.46464 10.5858 4.93869 10.3106 5.24828L6.31059 9.74828C6.16374 9.91348 5.95152 10.0055 5.73057 9.99976C5.50962 9.99402 5.30245 9.89112 5.16438 9.71853L3.16438 7.21853C2.90562 6.89508 2.95806 6.42311 3.28151 6.16436C3.60495 5.9056 4.07692 5.95804 4.33568 6.28149L5.78021 8.08715L9.18947 4.25174C9.46466 3.94215 9.93871 3.91426 10.2483 4.18945Z"
        fill="#51BA3C"
      />
    </svg>
  </>
);

export default GreenCheckIcon;
