import React from 'react';
import { DataCyStrings } from '../../types/DataCyStrings';
import { SVG, SVGName } from "../svg";
import { WrapIf } from '../Wrap';

import { $AlertMessage, $Message } from "./styles";

export interface AlertMessageContents {
    // explanatory message
    message: string,
    // allows clickability on a message and underlines
    // said string when present
    onMessageClick?: () => void,
    // an array of strings used to display a bullet list
    bulletList?: Array<string>,
    // optional background color
    backgroundColor?: string,
    // optional text and icon color
    color?: string
}

interface AlertMessage extends AlertMessageContents {
  // name of SVG icon to be displayed
  icon: SVGName,
  // color of background
  backgroundColor: string,
  // color of text and icon
  color: string,
  // string to be passed to data-cy attribute
  // for selection purposes in Cypress
  dataCyString?: DataCyStrings
}

const AlertMessage = ({
  icon,
  message,
  bulletList,
  backgroundColor,
  color,
  dataCyString,
  onMessageClick
}:AlertMessage) => {
  const shouldRenderBulletList = Array.isArray(bulletList) && bulletList.length > 0
  const bulletListComponents = shouldRenderBulletList ? bulletList.map(bulletStr => {
    return (
      <li>{bulletStr}</li>
    )
  }) : null
  return (
    <$AlertMessage backgroundColor={backgroundColor} color={color} data-cy={dataCyString}>
      <div className="icon">
        <SVG name={icon} stroke={color} />
      </div>
        <$Message color={color}>
          <WrapIf
            condition={!!onMessageClick}
            wrapIf={(children) => (
            <a onClick={onMessageClick}>
              {children}
            </a>
            )}
            wrapElse={(children) => (
              <>{children}</>
            )}
          >
            {message}
          </WrapIf>
          {shouldRenderBulletList &&
            <ul>
              {bulletListComponents}
            </ul>
          }
        </$Message>
    </$AlertMessage>
  )
}

export const ErrorAlertMessage = ({ message, bulletList, onMessageClick }:AlertMessageContents) => {
  return (
    <AlertMessage
      icon="exclamation"
      message={message}
      bulletList={bulletList}
      color="var(--warning-bold)"
      backgroundColor="var(--warning-soft)"
      dataCyString={DataCyStrings.alertMessage}
      onMessageClick={onMessageClick}
    />
  )
}

export const SuccessMessage = ({ message }: { message: string }) => {
  return (
    <AlertMessage
      icon="success"
      message={message}
      color="#15332D"
      backgroundColor="#EAF5E6"
      dataCyString={DataCyStrings.successMessage}
    />
  );
}

export const NeutralMessage = ({ message }) => {
  return (
    <AlertMessage
      icon="lock"
      message={message}
      color="var(--neutral-grey)"
      backgroundColor="var(--disabled-grey)"
      dataCyString={DataCyStrings.neutralMessage}
    />
  );
}

export const InfoMessage = ({ message, bulletList, onMessageClick, backgroundColor, color }:AlertMessageContents) => {
    return (
      <AlertMessage
        icon="info"
        message={message}
        bulletList={bulletList}
        backgroundColor={backgroundColor ? backgroundColor : "var(--disabled-grey)"}
        color={color ? color : "var(--neutral-grey)"}
        dataCyString={DataCyStrings.infoMessage}
        onMessageClick={onMessageClick}
      />
    );
}

export default AlertMessage;
