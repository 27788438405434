import produce from "immer";
import logger from "../../utility/logger/logger";
import axios from "axios";
import { defaultLocale } from "../../i18n";
import { cloneDeep } from "lodash";

import {
  Store,
  SET_TOKEN,
  SET_CONFIGURATION,
  GOTO_PAGE,
  ON_FATAL_ERROR,
  SET_RETURN_PORTAL_TIMEOUT,
  SET_LOADING_MESSAGE,
  SET_ALERT_NAME,
  SET_QUERIES,
  SET_USER,
  SET_LOCAL_ERROR_MESSAGE,
  RESET_APP,
  FINALIZE,
  SET_SCAN_PATH,
  SET_CONFIRMATION_PATH,
  SET_EXPIRED_TOKEN_RETURNISTA,
  PORTAL_PURCHASES_ERROR,
  SET_RETURN_STARTED,
  SET_LOCALE,
  SET_PLATFORM,
  SET_RETURN_SHOPPING_ENABLED,
  SET_LABEL_COUNT
} from "./types";
import {
  initialState as globalGoogleAnalyticsInitialState,
  GlobalGoogleAnalyticsState,
  globalGoogleAnalyticsReducer
} from "../slices/globalGoogleAnalytics";
import reduceReducers from "reduce-reducers";


const initialState: Store = {
  isInitialized: false,
  token: "",
  runtime: "",
  pages: {},
  queries: {},
  initialPageName: "",
  currentPageName: "",
  currentModalPageName: "",
  fatalErrorName: "",
  returnPortalTimeout: false,
  loadingMessage: "",
  localErrorMessage: "",
  alertName: "",
  user: {},
  scanPath: "",
  copies: {},
  supportInfo: {
    copy: "",
    supportContact: ""
  },
  isWhiteLabel: false,
  recaptchaToken: "",
  returnStarted: new Date(),
  disableHeaderLinks: false,
  locale: "en-US",
  platform: "",
  confirmationPath: ""
};

export const appReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    // first, update draft with any needed changes
    switch (action.type) {
      case SET_TOKEN:
        axios.defaults.headers.common['Authorization'] = "Bearer " + action.payload;
        draft.token = action.payload
        return draft;
      case ON_FATAL_ERROR:
        // if there's already a fatal error, prioritize the first error to prevent rerenders
        draft.fatalErrorName = draft.fatalErrorName ? draft.fatalErrorName : action.payload;

        // when a fatal error happens, we don't want the app to resume normal activity
        // conveniently, the spec on isInitialized is that it's required to be true for
        // this store to be valid (and not require initialization). so lowering this flag
        // should provide the refresh we desire
        draft.isInitialized = false;
        return draft;
      case SET_RETURN_PORTAL_TIMEOUT:
        draft.returnPortalTimeout = action.payload
        return draft;
      case SET_LOADING_MESSAGE:
        draft.loadingMessage = action.payload
        return draft;
      // app should reinitialize when configuration is updated
      case SET_CONFIGURATION:
        const { configuration } = action.payload;
        draft.runtime = configuration.runtime;
        draft.pages = configuration.pages;
        draft.initialPageName = configuration.initialPage;
        // page shouldn't change if we are already initialized
        if (!draft.isInitialized) {
          draft.currentPageName = configuration.initialPage;
        }
        draft.copies = configuration.copies;
        draft.supportInfo = configuration.supportInfo;
        draft.disableHeaderLinks = configuration.disableHeaderLinks;
        draft.colors = configuration.colors;
        draft.links = configuration.links;
        draft.isWhiteLabel = configuration.isWhiteLabel;
        draft.recaptchaToken = configuration.recaptchaToken;
        // once the configuration is in place, we are initialized
        draft.isInitialized = true;
        draft.enableLocalization = !!configuration?.enableLocalization
        const enabledLocales = { ...configuration?.enabledLocales, [defaultLocale]: true }
        draft.enabledLocales = enabledLocales
        if (Object.keys(enabledLocales).length == 1 && enabledLocales['en-US'])
          draft.enableLocalization = false
        draft.enableCustomerReturnNotes = !!configuration?.enableCustomerReturnNotes
        draft.singleOrderReturns = !!configuration?.singleOrderReturns
        draft.locale = configuration?.locale || defaultLocale
        draft.returnShoppingEnabled = !!configuration?.returnShoppingEnabled;
        draft.returnShoppingFeeWaiver = !!configuration?.returnShoppingFeeWaiver;
        draft.returnShoppingDiscount = configuration.returnShoppingDiscount;
        draft.returnShoppingURL = configuration.returnShoppingURL;
        draft.expandMoreRefundOptions = configuration.expandMoreRefundOptions;
        return draft;

      case SET_LOCALE:
        draft.locale = action.payload
        return draft

      case GOTO_PAGE:
        const nextPage = draft.pages[action.payload];
        // only set t he page if we found it, otherwise no side effect
        if (nextPage) {
          if (nextPage.type === "modal") {
            draft.currentModalPageName = action.payload;
          } else {
            draft.currentModalPageName = "";
            draft.currentPageName = action.payload;
          }
        }
        else {
          console.error("Invalid page name. Ensure the runtime config json includes the given page", action.payload)
          logger.Info("trying to navigate to a page that doesn't exist", action.payload)
        }
        return draft;

      case SET_SCAN_PATH:
        draft.scanPath = action.payload
        return draft;

      case SET_CONFIRMATION_PATH:
        draft.confirmationPath = action.payload;
        return draft;

      case SET_ALERT_NAME:
        draft.alertName = action.payload
        return draft;

      case SET_QUERIES:
        draft.queries = action.payload
        return draft;

      case RESET_APP:
        draft = cloneDeep(appFlattenedStore)
        return draft;

      case FINALIZE:
        // we finalize simply by lowering this flag.  that means that any new piece of code that
        // wants to the store needs to re-initialize it
        draft.isInitialized = false
        return draft;

      case SET_USER:
        draft.user = action.payload;
        return draft;

      case SET_LOCAL_ERROR_MESSAGE:
        draft.localErrorMessage = action.payload;
        return draft;

      case PORTAL_PURCHASES_ERROR:
        // In ReturnPortal we load purchases on the OrderList page, but this
        // may result in 4xx or 5xx error responses and we need to reset state
        // and return to login.
        draft.token = "";
        draft.currentPageName = "loginReturnPortal";
        draft.localErrorMessage = action.payload;
        // Clear out query params to avoid a infinite loop.
        window.history.replaceState({}, "", "/");
        return draft

      case SET_EXPIRED_TOKEN_RETURNISTA:
        if (action.payload) {
          draft.localErrorMessage = action.payload;
        }
        draft.token = "";
        draft.currentPageName = "logIn";
        draft.fatalErrorName = "";
        window.location.reload()
        return draft;
      case SET_RETURN_STARTED:
        draft.returnStarted = new Date();
        // only update the token if a valid
        // payload is given
        if (action.payload) {
          draft.token = action.payload;
        }
        return draft;
      case SET_PLATFORM:
        draft.platform = action.payload;
        return draft;

      case SET_RETURN_SHOPPING_ENABLED:
        draft.returnShoppingEnabled = action.payload;
        return draft;

      case SET_LABEL_COUNT:
        draft.labelCount = action.payload;
    }
  });
};

export interface AppFlattenedStore extends Store, GlobalGoogleAnalyticsState { }
const appFlattenedStore: AppFlattenedStore = {
  ...initialState,
  ...globalGoogleAnalyticsInitialState
}

const appFlattenedReducers = [
  globalGoogleAnalyticsReducer,
  appReducer
]

export default reduceReducers<any>(appFlattenedStore, ...appFlattenedReducers);
