import styled from 'styled-components';

const $TextFieldWithButton = styled.form`
  height: 44px;
  display: flex;

  input {
    width: 362px;
    margin-right: 8px;
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width: 362px;
    font-size: 16px;
    color: #000000;
  }
  input::placeholder {
    font-size: 14px;
    color: #737373;
    input:hover {
      color: #000000;
    }
  }

  /* ie11 workaround
     XXX strangely I was only able to get the border to show up in ie11
     by making a separate query for the focus pseudo class instead of just
     being able to stack it with focus-within
   */
   /* adjusting the padding is the simplest way to fix the shifting 
      text in the input box
    */
  input:focus {
    outline: none;
    border: 2px solid #000000 !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  input:hover {
    border: 2px solid #CECECE;
    padding-left: 15px;
    padding-right: 15px;
  }

  input:focus-within {
    border: 2px solid #000000 !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  input[type="search"] {
    -webkit-appearance: none;
  }
`;

export {
  $TextFieldWithButton,
}