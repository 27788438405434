import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "./reducers";

export interface GlobalGoogleAnalyticsState {
  instanceListCountItemsPresentErrorCount: number,
  previewPageHasBeenVisited: boolean
}

export const initialState: GlobalGoogleAnalyticsState = {
  instanceListCountItemsPresentErrorCount: 0,
  previewPageHasBeenVisited: false
}

export const globalGoogleAnalyticsSliceName = "global-google-analytics";

/**
 * This slice is used to keep track of Google Analytics event flags
 * that require us to know if certain flags have been met before we can fire an event
 * to ensure that duplicate calls to GA are prevented if the user happens to not follow
 * the linear path of a runtime's flow
 */
const globalGoogleAnalyticsSlice = createSlice({
  name: globalGoogleAnalyticsSliceName,
  initialState,
  reducers
})

export const actions = {
  ...globalGoogleAnalyticsSlice.actions
}

export const globalGoogleAnalyticsReducer = globalGoogleAnalyticsSlice.reducer;