import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useSelector from "../../utility/useTypedSelector";
import NavigationCard from "../../components/NavigationCard";
import { setGiftReturnEmail, setReturnType } from "../../redux/customer/actions";
import { goToPage } from "../../redux/app/actions";
import { getReturnistaJWTClaims, isValidEmail } from "../../utility";
import { PageProps } from "../types";
import { PageLifecycle } from "../";
import { ReturnTypes, Store as CustomerStore } from "../../redux/customer/types";
import TextInput from "../../components/TextInput";
import $ReturnType from "./styles";
import { DataCyStrings } from "../../types/DataCyStrings";
import logger from "../../utility/logger";

class ReturnTypeLifecyle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }
  satisfiesPreconditions() {
    // express code should be empty when this page loads
    return !this.customer.expressCode;
  }
}

const ReturnType = ({ page }: PageProps) => {
  //---------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { app, customer } = useSelector(store => store);

  const [email, setEmail] = useState("");
  const [showEmailForm, setShowEmailForm] = useState(false);
  const emailIsValid = isValidEmail(email);
  const claims = getReturnistaJWTClaims(app.token);

  const lifecycle = new ReturnTypeLifecyle(page, dispatch, app, customer);
  //---------------------------------------------------------------------------


  //---------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    // if gift returns aren't allowed, default to newReturn
    // and redirect user to the order search page
    if (!claims?.returnsApp?.allowGiftReturns) {
      // if the returnType was already defined as express return,
      // the user is coming from the OrdesrSearch page, direct them
      // one more step back to the scan page if gift returns are disabled
      if (customer.returnType === ReturnTypes.newReturn) {
        dispatch(goToPage("scanExpressCode"));
        dispatch(setReturnType(ReturnTypes.expressReturn));
      // if this is not the case, the user is coming fron the
      // scan page, meaning that we select the new return type for the user
      // and direct them to the OrderSearch page
      } else {
        startNewReturn();
      }
    }
  }, [])

  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  /**
   * These two handlers set the returnType to either gift or new return,
   * sends this info to google analytics, then finally starts a new return
   * by advancing the user to the order search page
   */
  const startNewReturn = () => {
    dispatch(setReturnType(ReturnTypes.newReturn));
    lifecycle.advance();
  }

  const startGiftReturn = () => {
    if (!emailIsValid) {
      return;
    }
    dispatch(setGiftReturnEmail(email));
    lifecycle.advance();
  }

  const onGiftReturnClicked = () => {
    setShowEmailForm(true);
  }
  //---------------------------------------------------------------------------


  //---------------------------------------------------------------------------
  // RENDERING
  return (
    <$ReturnType>
      {claims?.returnsApp?.allowGiftReturns &&
        <>
          <NavigationCard key="new" iconName="play" title="New Return" onClick={startNewReturn} dataCyString={DataCyStrings.newReturnNavigationCard} showArrow />
          <NavigationCard key="gift" iconName="gift" title="Gift Return" onClick={onGiftReturnClicked} dataCyString={DataCyStrings.giftReturnNavigationCard} showArrow />
        </>
      }{showEmailForm &&
        <div className="email-form-container">
          <div className="form-header">Where should we send the gift card?</div>
          <form onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            startGiftReturn();
          }}>
            <TextInput type="text" value={email} onChange={setEmail} placeholder="Enter email address" dataCyString={DataCyStrings.returnTypeEmailInput}/>
            <button disabled={!emailIsValid}>Continue</button>
          </form>
        </div>
      }
    </$ReturnType>
  );
  //---------------------------------------------------------------------------

}

export default ReturnType;
