import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";

import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import { CurrentPageAction } from "../../redux/enums";
import { RootReducer } from "../../redux/returnista-store";

import { $Container, $Title, $FollowUp, $BottomButton } from "./styles";
import Content from "../../components/ReturnistaContent";

import ga from "../../utility/GAEmitter";
import { ScanBagPromptActions } from "../../types/ReturnistaGA";

import { getReturnistaJWTClaims, isUPS, mapURLQueryStringToObject } from "../../utility";
import { DataCyStrings } from "../../types/DataCyStrings";
import { confirmOutOfBags } from "../RequestSupportModals";

const ReturnistaSuccessCodeScan = () => {
  const dispatch = useDispatch();
  const { returnObject, currentPage, userToken } = useSelector<RootReducer, RootReducer>((store) => store);

  const claims = getReturnistaJWTClaims(userToken ?? "");

  const { authorization } = mapURLQueryStringToObject();
  const { returnsApp } = getReturnistaJWTClaims(authorization);
  const returnistaLocationOwnerName = returnsApp?.locationOwner || claims?.returnsApp?.locationOwner || "";
  const returnistaLocationID = returnsApp?.locationID || claims?.returnsApp?.locationID || "";


  const goToNextStep = () => {
    ga.pageEvent({category: currentPage, action: ScanBagPromptActions.ProceedToScanBag});
    dispatch({ type: CurrentPageAction.Next });
  };

  return (
    <>
      <Header left={<HeaderBackButton />} center="Scan customer's QR code" />
      <Content>
        <$Container>
          <ReturnistaFeedbackIcon status="success" />
          <$Title>
            <p data-cy="qr-code-success-title">QR code scanned. </p>
            {returnObject?.confirmationCode != null ? (
              <span data-cy="qr-code-success-code">Code: {returnObject.confirmationCode}</span>
            ) : null}
          </$Title>
          <$FollowUp>
            Next, scan the code on the <br /> Happy Returns bag.
          </$FollowUp>
          <ReturnistaPrimaryButton onClick={goToNextStep} dataCyString="scan-bag-button">
            Scan bag code
          </ReturnistaPrimaryButton>
        </$Container>
        <$BottomButton>
            {!isUPS() && (
              <ReturnistaPrimaryButton dataCyString={DataCyStrings.requestOutOfBags} onClick={confirmOutOfBags(dispatch, returnistaLocationID, returnistaLocationOwnerName, currentPage)} variant="link">
                {"Out of Happy Returns bags"}
              </ReturnistaPrimaryButton>
            )}
          </$BottomButton>
      </Content>
    </>
  );
};

export default ReturnistaSuccessCodeScan;
