export enum BackendErrorPrefixes {
  rmaUnassociated    = "ERROR_RMA_UNASSOCIATED",
  rmaInvalid         = "ERROR_RMA_INVALID",
  rmaExpired         = "ERROR_RMA_EXPIRED",
  locationNotAllowed = "ERROR_LOCATION_NOT_ALLOWED",
  rmaPartialRefund   = "ERROR_RMA_PARTIAL_REFUND",
  rmaExpiredReturn   = "ERROR_RMA_RETURN_EXPIRED",
  locationInactive   = "ERROR_LOCATION_INACTIVE",
  returnBarIneligible = "ERROR_RETURN_BAR_INELIGIBLE",
}
