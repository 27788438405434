// third-party imports
import React from "react";
import { useDispatch } from "react-redux";

// private imports
// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { DataCyStrings } from "../../types/DataCyStrings";
import { RootReducer } from "../../redux/reducers";
import { getReturnistaJWTClaims } from "../../utility";
import useSelector from "../../utility/useTypedSelector";

// local imports
import $ThankTheCustomerScanTote, {
  $Card,
  $CardHeader,
  $CouponReminder,
  $Divider,
  $FinishReturnButtonWrapper,
  $Prompt,
  $Reminder,
  $WorkflowIllustration,
} from "./styles";
import ConfirmationEmail from "../../components/ConfirmationEmail";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { SVG } from "../../components/svg";

class ThankTheCustomerScanToteLifecycle extends PageLifecycle {
  constructor(page, app, dispatch) {
    super(page, app, dispatch);
  }
}

// static copy
export const THANK_THE_CUSTOMER = "Thank the Customer";
export const TELL_THE_CUSTOMER = "Tell the customer: ";
export const RETURN_COMPLETE_PROMPT =
  "“Your return is complete and you’ll receive an email receipt from Happy Returns. You’re free to go.”";
export const BAG_ITEMS_FINISH_RETURN = "Bag Items and Finish Return";

/**
 * Page exclusive to Returnista to be shown after receiving a return but before ConfirmationScanTote.
 */
const ThankTheCustomerScanTote = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { customer, app } = useSelector((store: RootReducer) => store);

  const { newReturnID } = customer;
  const { token } = app;
  const claims = getReturnistaJWTClaims(token);

  const lifecycle = new ThankTheCustomerScanToteLifecycle(page, dispatch, app);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  function handleContinueButtonClick() {
    lifecycle.advance();
  }

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  const renderCouponReminder = () => {
    if (claims?.returnsApp?.couponEnabled) {
      return <$CouponReminder>Remind the customer their receipt contains a coupon for your store.</$CouponReminder>;
    }
  };

  // RENDERING
  return (
    <$ThankTheCustomerScanTote>
      <$Card data-cy={DataCyStrings.thankTheCustomerCard}>
        <$CardHeader>
          <SVG name={"hr-logo"}></SVG>
          <div>{THANK_THE_CUSTOMER}</div>
        </$CardHeader>
        <$Divider />
        <$Prompt>
          <span>{TELL_THE_CUSTOMER}</span>
          {RETURN_COMPLETE_PROMPT}
          {renderCouponReminder()}
          <ConfirmationEmail returnID={newReturnID} />
        </$Prompt>
      </$Card>
      <$Reminder>You’re almost done!</$Reminder>
      <$WorkflowIllustration>
        <SVG name={"shirt"}></SVG>
        <SVG name={"rightArrow"}></SVG>
        <SVG name={"polybag"}></SVG>
        <SVG name={"rightArrow"}></SVG>
        <SVG name={"tote"}></SVG>
      </$WorkflowIllustration>
      <$FinishReturnButtonWrapper>
        <PrimaryButton
          dataCyString={DataCyStrings.bagItemsFinishReturnButton}
          onButtonClick={handleContinueButtonClick}
          label={BAG_ITEMS_FINISH_RETURN}
          width={"300px"}
        ></PrimaryButton>
      </$FinishReturnButtonWrapper>
    </$ThankTheCustomerScanTote>
  );
  //----------------------------------------------------------------------------
};

export default ThankTheCustomerScanTote;
