
/**
 * reformats a price to follow the pattern of amount + country ISO code. Ex: $25.00 => 25.00 USD
 * @param  {string} currencyString - the instance where price will be extracted from
 */
export const formatCurrencyString = (currencyString?: string) => {
  if (!currencyString) {
    return currencyString;
  }

  const currencyRegex = /([0-9.,]+)\s*([A-Z]{3})?/;
  const matches = currencyRegex.exec(currencyString);

  // if invalid input, return the same string
  if (!matches || matches.length < 3) {
    return currencyString
  }

  // purposefully not trying to verify the format of the amount
  const amount = matches[1];
  const currencyCode = matches[2] || "USD";

  return `${amount} ${currencyCode}`;
}
