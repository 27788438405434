import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../../globalConstants";

export const $ReturnStatusCard = styled.div`
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  height: fit-content;
  margin-bottom: 16px;
  padding: 24px;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-bottom: 1px solid var(--border-card);
    border-radius: revert;
    border-top: 1px solid var(--border-card);
    margin-bottom: 8px;
  }

  .express-code {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    position: relative;
    color: #616161;
  }

  .return-status-message {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    position: relative;
    margin-top: 8px;

    svg {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      position: relative;
    }
  }

  #backgroundCircleColor {
    fill: var(--color-primary);
  }
  .return-status-message-mobile {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    position: relative;
    margin-top: 8px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      position: relative;
    }
  }
`;
