import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $ErrorAlertContainer = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
`;

export const $SubheadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: ${tabletOrLargerDevices}) {
    margin-bottom: 32px;
  }

  & > .subheading {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 8px;

    @media (min-width: ${tabletOrLargerDevices}) {
      font-size: 27px;
      line-height: 40px;
      margin-bottom: 0;
    }
  }

  & > .notListedLink {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const $ImageCardGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  gap: 16px;

  @media (min-width: ${tabletOrLargerDevices}) {
    gap: 2%;
    margin-bottom: 0;
  }

  & > .imageCard {
    background-position: center;
    background-color: var(--disabled-grey);
    background-size: cover;
    border: solid 1px var(--border-card);
    border-radius: 8px;
    padding-top: 32%;
    width: 47%;
    height: 148px;
    cursor: pointer;
    margin: 0;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 31%;
      height: 229px;
      margin: 8px 0;
    }
  }
`;

export const $WarningIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const $WarningIconCircle = styled.div`
  background-color: #fff5b3;
  border-radius: 50%;
  width: 72px;
`;

export const $ErrorIconContainer = styled.div``;

export const $ButtonGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (min-width: ${tabletOrLargerDevices}) {
    flex-direction: row;
  }

  & > * {
    max-width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-bottom: 0px;
      max-width: 49%;
    }
  }
`;
