let MarkdownIt = require('markdown-it');

const getHtmlFromMarkdown = (markdownString) => {
  // Init markdown-it with presets and options
  // https://github.com/markdown-it/markdown-it#init-with-presets-and-options
  let md = new MarkdownIt({
    html:         false,        // Enable HTML tags in source
    xhtmlOut:     false,        // Use '/' to close single tags (<br />).
                                // This is only for full CommonMark compatibility.
    breaks:       false,        // Convert '\n' in paragraphs into <br>
    linkify:      false,        // Auto convert URL-like text to links

    // Enable some language-neutral replacement + quotes beautification
    // For the full list of replacements, see https://github.com/markdown-it/markdown-it/blob/master/lib/rules_core/replacements.js
    typographer:  false,
  });

  return md.render(markdownString)
}

export default getHtmlFromMarkdown
