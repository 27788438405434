import React from "react";

const ShippingLabel = (props) => (
  <>
    <svg
      {...props}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.3703 20.1668V1.8335H17.6666V20.1668H4.3703ZM2.8703 1.3335C2.8703 0.781213 3.31802 0.333496 3.8703 0.333496H18.1666C18.7189 0.333496 19.1666 0.781211 19.1666 1.3335V20.6668C19.1666 21.2191 18.7189 21.6668 18.1666 21.6668H17.1295V22.6668C17.1295 23.2191 16.6818 23.6668 16.1295 23.6668H1.83325C1.28097 23.6668 0.833252 23.2191 0.833252 22.6668V3.33348C0.833252 2.7812 1.28097 2.33348 1.83325 2.33348H2.8703V1.3335ZM2.8703 3.83348V20.6668C2.8703 21.2191 3.31802 21.6668 3.8703 21.6668H15.6295V22.1668H2.33325V3.83348H2.8703ZM5.58633 4.27619C5.58633 3.97416 5.83117 3.72931 6.1332 3.72931H15.9036C16.2057 3.72931 16.4505 3.97416 16.4505 4.27619C16.4505 4.57822 16.2057 4.82306 15.9036 4.82306H6.1332C5.83117 4.82306 5.58633 4.57822 5.58633 4.27619ZM6.1332 6.45852C5.83117 6.45852 5.58633 6.70336 5.58633 7.00539C5.58633 7.30742 5.83117 7.55227 6.1332 7.55227H15.9036C16.2057 7.55227 16.4505 7.30742 16.4505 7.00539C16.4505 6.70336 16.2057 6.45852 15.9036 6.45852H6.1332ZM5.58633 9.73454C5.58633 9.43251 5.83117 9.18767 6.1332 9.18767H15.9036C16.2057 9.18767 16.4505 9.43251 16.4505 9.73454C16.4505 10.0366 16.2057 10.2814 15.9036 10.2814H6.1332C5.83117 10.2814 5.58633 10.0366 5.58633 9.73454ZM14.7345 16.3335C14.1822 16.3335 13.7345 16.7812 13.7345 17.3335V18.0002C13.7345 18.5524 14.1822 19.0002 14.7345 19.0002H15.4505C16.0028 19.0002 16.4505 18.5524 16.4505 18.0002V17.3335C16.4505 16.7812 16.0028 16.3335 15.4505 16.3335H14.7345Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default ShippingLabel;
