import styled from "styled-components";

export const $BagCountContainer = styled.div`
  width: 720px;
  align-self: center;
  padding: 24px;

  h1 {
    line-height: 40px;
    font-weight: 500;
    font-size: 27px;
    margin: 0 0 8px;
  }

  h2 {
    font-size: 16px;
    font-weight: 200;
    line-height: 20px;
    color: var(--medium-emphasis-black);
    margin: 0px auto 32px;
  }
`;
