import React, { FormEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import {
  CurrentPageAction,
  FakeImagesAction,
  LoadingAction,
  PuzzleAttemptsAction,
  ReturnObjectAction,
  ScannerType,
  UserTokenAction,
} from "../../redux/enums";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import { SVG } from "../../components/svg";

import { $ManualCodeLinkWrapper, $ManualInput, $Input } from "./styles";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import * as messages from "../../messages";
import { errorTextOrModal, filterReturnedItems, headerTextHandler, isBagCodeLike, openScanner } from "../../utility";
import ReturnistaTimer from "../../utility/ReturnistaTimer";
import ReturnistaAnalytics, { ReturnistaAnalyticsErrors } from "../../utility/ReturnistaAnalytics";
import { BackendErrorPrefixes } from "../../types/API";
import ReturnistaImageManager from "../../utility/ReturnistaImageManager";
import Content from "../../components/ReturnistaContent";
import ga from "../../utility/GAEmitter";
import { QrCodeScanActions } from "../../types/ReturnistaGA";
import { store } from "../../redux/returnista-store";

const ReturnistaCodeScan = () => {
  const dispatch = useDispatch();
  const { currentPage } = store.getState();
  const [error, setError] = useState<string>("");
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [isModalError, setIsModalError] = useState<boolean>(false);

  // GA consts
  const scan = "qr code scan";
  const entry = "qr code entry";

  const inputRef = useRef<string>("");

  useEffect(() => {
    // Handle code event emitted from app wrappers
    window.enterCode = (code: string) => {
      getConfirmationCode(code, true);
    };
    // Open the scanning screen for this page in thin client
    openScanner(ScannerType.ConfirmationCode);
    return () => {
      // Reset handler to report error if called after unmount
      window.enterCode = () => {
        ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedEnterCode);
      };
    };
  }, []);

  const handleChange = (e: FormEvent<HTMLInputElement>): void => {
    setError("");
    setDisableBtn(false);
    if (e.currentTarget.value.length === 0) {
      setDisableBtn(true);
    }
    inputRef.current = e.currentTarget.value.toUpperCase();
  };

  const getConfirmationCode = async (qrCode: string, usingScanner = false): Promise<void> => {
    dispatch({ type: LoadingAction.Set });
    const invalidCredentials = [messages.unauthorizedTitle, messages.unauthorizedMessage, usingScanner];
    const notAssociatedError = [
      messages.codeNotAssociatedHappyReturns,
      messages.tryAnotherCodeOrNewBeginReturn,
      usingScanner,
    ];
    const rmaPartialRefundError = [
      usingScanner ? messages.codeNotEligibleForBoxFreeReturnTitle : messages.codeNotEligibleForBoxFreeReturn,
      messages.startNewReturnOrContactRetailer,
      usingScanner,
    ];
    const rmaExpiredReturnError = [
      usingScanner ? messages.codeNotEligibleForBoxFreeReturnTitle : messages.codeNotEligibleForBoxFreeReturn,
      messages.startNewReturnOrContactRetailer,
      usingScanner,
    ];
    const alreadyCompleteError = [messages.returnAlreadyComplete, messages.allItemsReturnedApproved, usingScanner];
    const scannedBagBarcode = [messages.scanCustomerQRCode, messages.scannedHappyReturnsBag, usingScanner];
    const genericError = [messages.genericErrorTitle, messages.genericErrorMessage, usingScanner];
    const rmaInvalidNotEligibleForReturn = [
      messages.boxFreeReturnNotAllowedTitle,
      messages.boxFreeReturnNotAllowedMessage,
    ];
    const notEligibleForReturnBarReturn = [messages.codeNotEligibleForReturnBarReturn, messages.boxFreeReturnNotAllowedMessage, usingScanner];

    try {
      const { data: returnObject } = await axios.get(`/returns?confirmationCode=${qrCode}`);
      dispatch({ type: LoadingAction.Unset });
      const retailerId = returnObject?.retailerID ?? returnObject?.retailer?.id;
      if (returnObject.id === "" || retailerId == null) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.NotAssociated}`,
        });
        displayError.apply(this, notAssociatedError);
        return;
      }
      const filteredReturning = filterReturnedItems(returnObject?.returning ?? []);
      if (filteredReturning.length === 0) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.AlreadyComplete}`,
        });
        displayError.apply(this, alreadyCompleteError);
        return;
      }
      if (isBagCodeLike(qrCode)) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.Bag}`,
        });
        displayError.apply(this, scannedBagBarcode);
        return;
      }
      axios.post("/startreturn").then((response) => {
        const token = response.data;
        dispatch({ type: UserTokenAction.Set, token });
        ReturnistaAnalytics.setLocationIdFromToken(token);
        ReturnistaTimer.refresh(token);
      });
      returnObject.returning = filteredReturning;

      ReturnistaImageManager.loadImages(filteredReturning, retailerId, qrCode).then((fakeImages) => {
        if (fakeImages.length === 0) {
          ReturnistaAnalytics.fakeImagesNotLoaded();
          return;
        }
        ReturnistaAnalytics.fakeImagesLoaded(fakeImages.length);
        fakeImages.forEach((image) => dispatch({ type: FakeImagesAction.Add, fakeImage: image }));
      });

      // Start tracking the time-in-app
      ReturnistaAnalytics.setReturnObject(returnObject);
      ReturnistaAnalytics.codeEntered(usingScanner, "confirmation");
      ga.event({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${QrCodeScanActions.Successful}`,
      });
      dispatch({ type: CurrentPageAction.Next });
      dispatch({ type: ReturnObjectAction.Set, returnObject });
    } catch (error) {
      dispatch({ type: LoadingAction.Unset });
      if (isBagCodeLike(qrCode)) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.Bag}`,
        });
        displayError.apply(this, scannedBagBarcode);
        return;
      }

      // Order is important here so 400s aren't bucketed into not associated error
      if (error?.response?.data.includes(BackendErrorPrefixes.rmaExpiredReturn)) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.ExpiredReturn}`,
        });
        displayError.apply(this, rmaExpiredReturnError);
        return;
      }
      if (error?.response?.status === 400) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.NotAssociated}`,
        });
        displayError.apply(this, notAssociatedError);
        return;
      }
      if (error?.response?.status === 401) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.Unauthorized}`,
        });
        displayError.apply(this, invalidCredentials);
        return;
      }
      if (error?.response?.status === messages.returnBarDropoffIneligibleCode) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.ReturnBarDropoffIneligible}`,
        });
        displayError.apply(this, notEligibleForReturnBarReturn);
        return;
      }
      if (error?.response?.status === messages.partialRefundUnacceptableStatusCode) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.PartialRefund}`,
        });
        displayError.apply(this, rmaPartialRefundError);
        return;
      }
      if (error?.response?.data.includes("ERROR_LOCATION_NOT_ALLOWED")) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.LocationNotAllowed}`,
        });
        displayError.apply(this, alreadyCompleteError);
        return;
      }
      if (error?.response?.data.includes(BackendErrorPrefixes.rmaInvalid)) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${QrCodeScanActions.RmaInvalid}`,
        });
        displayError.apply(this, rmaInvalidNotEligibleForReturn);
        return;
      }
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${QrCodeScanActions.Failed}`,
      });
      displayError.apply(this, genericError);
    }
  };

  const displayError = (primaryMessage: string, subMessage = "", useModal = false) => {
    errorTextOrModal({
      primaryMessage,
      subMessage,
      useModal,
      scannerType: ScannerType.ConfirmationCode,
      dispatch,
      errorSetter: setError,
      usingModalSetter: setIsModalError,
    });
  };

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
    dispatch({ type: PuzzleAttemptsAction.Reset });
    getConfirmationCode(inputRef.current);
  };

  useEffect(() => {
    if (error.length > 0) {
      setDisableBtn(true);
    }
  }, [error]);

  const headerMessages = ["Scan customer's QR code", "Enter customer's QR code"];

  return (
    <>
      <Header left={<HeaderBackButton />} center={headerTextHandler({ usingScanner: isModalError, headerMessages })} />
      <Content>
        <$ManualCodeLinkWrapper>
          <$ManualInput>
            <div className="icon">
              <SVG fill="#221F1F" name="returnistaQrScan" />
            </div>
            <p data-cy="code-scan-title" className="title">
              Enter customer's QR code <br />
              <span data-cy="code-scan-subtitle">An 8 character alphanumeric value starting with "HR"</span>
            </p>
            <form onSubmit={handleSubmit} action="">
              <$Input
                data-cy="qr-code-input"
                title="qrCode manual input"
                autoFocus
                hasError={error.length > 0}
                onChange={handleChange}
                type="text"
              />
              {error && (
                <p className="error" data-cy="invalid-code-error">
                  {error}
                </p>
              )}
              <ReturnistaPrimaryButton
                disabled={disableBtn}
                className="submitQrCodeButton"
                dataCyString="submitQrCodeButton"
                type="submit"
              >
                Continue
              </ReturnistaPrimaryButton>
            </form>
          </$ManualInput>
        </$ManualCodeLinkWrapper>
      </Content>
    </>
  );
};

export default ReturnistaCodeScan;
