import i18n from "i18next";
import { memoize } from "lodash";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import { getAdminMode, mapURLQueryStringToObject } from "../utility";
import locales from "./locales.json";
// for now we don't use LanguageDetector, we rolled our own
// potentially useful if we want to cache detected language and do other types of things
// import LanguageDetector from 'i18next-browser-languagedetector';

export const supportedCountryLocales: Record<string, string> = locales;

export const supportedLocaleMappings: Record<string, string> = (() => {
  const langMap = {
    en: "en-US",
    de: "de-DE",
    es: "es-ES",
    fr: "fr-FR",
    it: "it-IT",
    nl: "nl-NL",
    da: "da-DK",
    fi: "fi-FI",
    no: "no-NO",
    sv: "sv-SE",
  };
  for (const l of Object.keys(supportedCountryLocales)) langMap[l] = l;

  return langMap;
})();

export const defaultLocale = "en-US";

// admin mode overrides localization support
export const localizationDisabled = memoize((): boolean => {
  return getAdminMode();
});

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: "/public/locale/{{lng}}.json",
    },
    // load only the current language (no preloading of other languages)
    load: "currentOnly",

    supportedLngs: Object.keys(supportedCountryLocales),
    preload: [defaultLocale],
    fallbackLng: defaultLocale,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transKeepBasicHtmlNodesFor: ["div"],
      useSuspense: false,
    },
  });

export const navigatorLocales = (): string[] => {
  const found: string[] = [];

  if (typeof navigator !== "undefined") {
    if (navigator.languages) {
      // chrome only; not an array, so can't use .push.apply instead of iterating
      for (let i = 0; i < navigator.languages.length; i++) {
        found.push(navigator.languages[i]);
      }
    }
    if (navigator["userLanguage"]) {
      found.push(navigator["userLanguage"]);
    }
    if (navigator.language) {
      found.push(navigator.language);
    }
  }

  return found;
};

export const bestSupportedLocale = (desiredLocales: string[]): string => {
  for (const [i, locale] of desiredLocales.entries()) {
    // check if the full locale (ie. en-US, en-GB, etc.) is supported
    {
      const mappedLocale = supportedLocaleMappings[locale];
      if (mappedLocale) return mappedLocale;
    }
    // check if the language portion of the locale (ie. en, fr, nl, etc.) is supported
    {
      const [langCode] = locale.split("-");
      const nextCode = desiredLocales?.[i + 1]?.split("-")?.[0];

      // we should only default to the short code
      // if we have no more long codes xx-XX for this language
      if (langCode == nextCode) continue;
      // we lower case the language code just incase the user's browser is quirky
      const mappedLocale = supportedLocaleMappings[langCode.toLowerCase()];
      if (mappedLocale) return mappedLocale;
    }
  }

  // if no user languages are supported
  // the default language is always en-US (American English)
  return defaultLocale;
};

export const detectLocale = (): string => {
  // TODO feature flag to be removed when we want to enable localization on prod
  if (localizationDisabled()) return defaultLocale;
  const desiredLocales = navigatorLocales();
  const urlQueries = mapURLQueryStringToObject();
  const overrideLocale = urlQueries.locale;
  if (overrideLocale) desiredLocales.unshift(overrideLocale);
  return bestSupportedLocale(desiredLocales);
};

// filters a [key,value] array to only entries with value containing the needle
export const localeInsensitiveSearch = (arr: string[][], needle: string) => {
  // trim for user friendly-ness
  needle = needle.trim();

  return arr.filter(([_, localeName]) => {
    const endIdx = localeName.length - needle.length;
    // loop through each chunk of size needle
    // starting from beginning of string
    for (let i = 0; i <= endIdx; ++i) {
      const haystack = localeName.substring(i, i + needle.length);
      if (haystack.localeCompare(needle, undefined, { usage: "search", sensitivity: "base" }) === 0) return true;
    }
    return false;
  });
};

export default i18n;
