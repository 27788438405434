import React from "react";

const Expired = (props) => (
  <>
    <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_679_1469)">
        <path
          d="M13.1908 3C8.2208 3 4.1908 7.03 4.1908 12H1.1908L5.1908 15.99L9.1908 12H6.1908C6.1908 8.13 9.3208 5 13.1908 5C17.0608 5 20.1908 8.13 20.1908 12C20.1908 15.87 17.0608 19 13.1908 19C11.2608 19 9.5108 18.21 8.2508 16.94L6.8308 18.36C8.4608 19.99 10.7008 21 13.1908 21C18.1608 21 22.1908 16.97 22.1908 12C22.1908 7.03 18.1608 3 13.1908 3ZM12.1908 8V13L16.4408 15.52L17.2108 14.24L13.6908 12.15V8H12.1908Z"
          fill="#C10D17"
        />
      </g>
      <defs>
        <clipPath id="clip0_679_1469">
          <rect width="24" height="24" fill="white" transform="translate(0.190796)" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default Expired;
