import React from 'react';

const Search = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.80464 0C3.49426 0 0 3.49426 0 7.80464C0 12.115 3.49426 15.6093 7.80464 15.6093C9.59937 15.6093 11.2526 15.0035 12.571 13.9852L18.2886 19.7028C18.6792 20.0934 19.3123 20.0934 19.7028 19.7028C20.0934 19.3123 20.0934 18.6792 19.7028 18.2886L13.9852 12.571C15.0035 11.2526 15.6093 9.59937 15.6093 7.80464C15.6093 3.49426 12.115 0 7.80464 0ZM2 7.80464C2 4.59883 4.59883 2 7.80464 2C11.0105 2 13.6093 4.59883 13.6093 7.80464C13.6093 11.0105 11.0105 13.6093 7.80464 13.6093C4.59883 13.6093 2 11.0105 2 7.80464Z" fill="#454545"/>
    </svg>
  )
}

export default Search;