// third-party imports
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// private imports
// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";

// local imports
import PageModal from "../../components/Modal/PageModal";
import PrimaryButton from "../../components/Button/PrimaryButton";
import $DropoffMethods, { $DropoffMethodsContents } from "./styles";
import { ReturnPortalBaseWithPage } from "../../components/ReturnPortalBase";
import { clearLoadingIndicator, goToPage, setToken, showLoadingIndicator } from "../../redux/app/actions";
import { SVG, SVGName } from "../../components/svg";
import {
  containsReturn,
  getAdminMode,
  getCustomerEmailFromReturn,
  handleEnterKeyPressOnClickHandlers,
  jwtClaimsByRuntime,
} from "../../utility";
import { DropoffMethodIDs } from "../../redux/customer/types";
import {
  addFeatureStudyID,
  reset,
  setNewReturnEmail,
  setReturns,
  setSelectedDropoffMethod,
  setShippingAddressCoordinates,
} from "../../redux/customer/actions";
import { formatLocationHours } from "./helpers";
import { ErrorAlertMessage } from "../../components/AlertMessage";
import { Return } from "../../types/Instance";
import { Location } from "./location";
import {
  AnalyticCategories,
  AnalyticsPageRoutes,
  CommonPageActions,
  DropoffMethodsActions,
} from "../../types/Analytics";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import getTranslator from "../../utility/getTranslator";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";
import { FeatureStudyIDs } from "../../types/FeatureStudyIDs";
import { statsigClient, statsigExperiment, StatsigExperimentData, statsigEvents } from "../../utility/useStatsigClient";

const useTranslation = getTranslator("DropoffMethods");
// concurrent A/B tests will be conducted. Split ratios are hardcoded here for the duration of the tests.
// split ratio for the A/B test, sort locations by default vs experiment owner ranking
const CONFIGURED_VS_EXPERIMENT_SPLIT = 0.1;
// split ratio for a second A/B test, location performance filter on vs off
const PERFORMANCE_ON_VS_OFF_SPLIT = 0.5;

const PREFERRED_SORT_METHOD = "preferred";
const EXPERIMENT_SORT_METHOD = "experiment";
const LOCATION_PERFORMANCE_SORT = "performance";

class DropoffMethodsLifecycle extends PageLifecycle {
  constructor(page, app, dispatch) {
    super(page, app, dispatch);
  }
}

export interface CarrierServiceLevel {
  name: "QR Code" | "Printed";
  labelType: string;
  serviceLevel: string;
}

export interface DropoffCarrierOption {
  name: string;
  serviceLevels: CarrierServiceLevel[];
}

export interface DropoffMethod {
  id: DropoffMethodIDs;
  badge: {
    color: string;
    text: string;
  };
  text: string;
  info: string;
  header: string;
  iconURL: string;
  url: string;
  locationLink?: {
    url: string;
    text: string;
  };
  fee?: {
    amount: string;
    currencyCode: string;
  };
  // original index before being broken off to 2 arrays
  priorityIdx?: number;
  carrierOptions?: DropoffCarrierOption[];
  selectedCarrierName?: string;
  selectedLabelOption?: string;
  selectedServiceLevel?: string;
  displayFreeTextReturnBar?: string;
  displayFreeTextRetailerStore?: string;
  locationsDisplayed?: string[];
  methodID?: string;
}

export interface ReturnBarDropoffMethod extends DropoffMethod {
  locations?: Location[];
  id: DropoffMethodIDs.returnBar;
}

export interface RetailerStoreDropoffMethod extends DropoffMethod {
  locations?: Location[];
  id: DropoffMethodIDs.retailerStore;
}

export interface ChangeDropoffMethodPayload {
  methodID?: string;
  serviceLevel?: string;
}

export interface DisplayElement {
  label: string;
  value: string;
}

export interface Address {
  id: number;
  type: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  countryCode: string;
  openTime: string;
  closeTime: string;
}

type DropoffMethodState = (ReturnBarDropoffMethod | RetailerStoreDropoffMethod | DropoffMethod)[];

interface ChangeDropoffMethodState {
  return: Return;
  dropoffMethods: DropoffMethodState;
  blockDropoff: boolean;
  preferredEmail: string;
}

interface DropoffMethodCardProps {
  dropoffMethod: DropoffMethod;
  selected: boolean;
  onClick: (e?) => void;
  children?: any;
  idx: number;
  locationsToggleState: {};
  setLocationsToggleState: ({}) => void;
}

interface DropoffMethodsModalState {
  error?: string;
  message?: string;
  header?: string;
  setDropoffMethodsModalState?: Function;
}

const dropoffMethodIconsByID = {
  [DropoffMethodIDs.returnBar]: "hr-logo",
  [DropoffMethodIDs.retailerStore]: "shop",
  [DropoffMethodIDs.mail]: "cardboard-box",
  [DropoffMethodIDs.mailShopperProvided]: "shipping-label",
  [DropoffMethodIDs.mailNoBoxNoLabel]:"ups-logo",
};

// TODO CF-4121 rename sustainable -> recommended
const sustainableDropoffMethods = new Set([DropoffMethodIDs.returnBar, DropoffMethodIDs.mailNoBoxNoLabel, DropoffMethodIDs.retailerStore]);

/**
 * used in return portal to allow the user to determine how they will return their items
 */
const DropoffMethods = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customer, app } = useSelector((store: RootReducer) => store);
  const { copies, locale } = useSelector((store) => store.app);
  const { selectedDropoffMethod, changeDropoffReturnID, itemsMarkedForCompletion } = customer;
  const { returnShoppingEnabled } = app;
  const [isAdminMode, setIsAdminMode] = useState<boolean>(getAdminMode());
  const lifecycle = new DropoffMethodsLifecycle(page, dispatch, app);

  const [dropoffMethods, setDropoffMethods] = useState<DropoffMethodState>([]);
  const [expandAllDropoffMethods, setExpandAllDropoffMethods] = useState(false);
  const [dropoffMethodsModalState, setDropoffMethodsModalState] = useState<DropoffMethodsModalState>({
    error: "",
    message: "",
    header: "",
  });
  const [locationsToggleState, setLocationsToggleState] = useState({});
  const retailerName = copies?.retailerName;
  const [experimentData, setExperimentData] = useState<StatsigExperimentData | null>(null);

  // omit header to block navigation when user is changing the dropoff method of an existing return
  if (changeDropoffReturnID) {
    const pageWithoutLeftHeader = { ...page };
    pageWithoutLeftHeader.leftHeaderLink = undefined;
    page = pageWithoutLeftHeader;
  }

  if (returnShoppingEnabled && containsReturn(itemsMarkedForCompletion) && page.leftHeaderLink) {
    page = {
      ...page,
      leftHeaderLink: {
        ...page.leftHeaderLink,
        destinationPage: "refundOptions",
      },
    };
  }

  // reformat response to differentiate sustainable vs non-sustainable methods.
  // useMemo allows us to ensure that unrelated state changes do not cause these
  // values to be recomputed
  const { sustainableOptions, nonSustainableOptions } = useMemo(() => {
    const sustainableOptions: DropoffMethodState = [];
    const nonSustainableOptions: DropoffMethodState = [];

    dropoffMethods.forEach((d, idx) => {
      d.priorityIdx = idx;
      if (sustainableDropoffMethods.has(d.id)) {
        // Experiment - if the dropoff method is a return bar, we want to display the experiment label (if available)
        if (d.id === DropoffMethodIDs.returnBar) {
          d.info = experimentData?.label || d.info;
        }
        sustainableOptions.push(d);
      } else {
        nonSustainableOptions.push(d);
      }
    });

    return {
      sustainableOptions,
      nonSustainableOptions,
    };
  }, [dropoffMethods]);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    if (statsigClient.isStatsigInitialized && experimentData === null)
      loadExperimentData();

    getDropoffMethods();
    setExpandAllDropoffMethods(!!page?.pageSettings?.expandAllDropoffMethods);
  }, []);

  useEffect(() => {
    if (!expandAllDropoffMethods) {
      if (sustainableOptions.length === 0 && nonSustainableOptions.length != 0) {
        setExpandAllDropoffMethods(true);
      }
    }
  }, [sustainableOptions, nonSustainableOptions]);

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        //DO NOT SEND change_dropoff as we want to track if the customer came after clicking on change dropoff (should be set in StartReturnPortal)
      },
    });
    ga.sendPageDetails(AnalyticsPageRoutes.DropoffMethods, AnalyticCategories.DropoffMethodsPage);
  }, []);

  // HELPERS
  const getDropoffMethods = async () => {
    dispatch(showLoadingIndicator(defaultLoadingSymbol));
    try {
      if (changeDropoffReturnID) {
        // when this page is hit, a token is not generated, so we use the locale query param
        const resp = await axios.get<ChangeDropoffMethodState>(
          `return/${changeDropoffReturnID}/dropoffmethods?locale=${app.locale}`
        );
        dispatch(setReturns(resp.data.return));
        if (resp.data.blockDropoff) {
          //if the changing dropoff is blocked -- for expired returns or return that has already departed
          dispatch(goToPage("expiredReturn"));
        } else {
          const customerEmailFromReturn = resp.data.preferredEmail || getCustomerEmailFromReturn(resp.data.return);
          if (customerEmailFromReturn) {
            dispatch(setNewReturnEmail(customerEmailFromReturn));
          }
          await initializeStatsigClient(customerEmailFromReturn || "");
          const dropoffMethods = resp.data.dropoffMethods || [];
          setDropoffMethods(dropoffMethods);
          saveReturnBarLocationsInLocalStorage(dropoffMethods);
          populateShowFreeStatus(dropoffMethods);
          hideChangeLaterIfSingleDropOffMethod(dropoffMethods);
          let selectedDropoff = dropoffMethods[0]; // Default to first method.
          for (const method of dropoffMethods) {
            if (method.id == DropoffMethodIDs.returnBar) {
              selectedDropoff = method;
              break;
            }
          }
          selectedDropoff = hydrateSelectedDropoffMethodIfNecessary(selectedDropoff);
          dispatch(setSelectedDropoffMethod(selectedDropoff));
        }
      } else {
        const resp = await axios.post<DropoffMethodState>("dropoffmethods", {
          returning: customer.itemsMarkedForCompletion,
          sortMethod: getLocationDisplaySortMethod(),
        });
        setDropoffMethods(resp.data);
        saveReturnBarLocationsInLocalStorage(resp.data);
        populateShowFreeStatus(resp.data);
        hideChangeLaterIfSingleDropOffMethod(resp.data);
        const selectedDropoff = hydrateSelectedDropoffMethodIfNecessary(resp.data[0]);
        dispatch(setSelectedDropoffMethod(selectedDropoff));
        const returnBarLink = resp.data.find((obj) => obj.id === DropoffMethodIDs.returnBar)?.locationLink?.url;
        const coordinates = getCoordinatesFromLink(returnBarLink);
        if (coordinates) {
          dispatch(setShippingAddressCoordinates(coordinates));
        }
      }
    } catch (e) {
      setDropoffMethodsModalState({
        header: t("returnMethodsUnavailable"),
        error: t("weCouldNotFindAny"),
      });
      //Send GA4 event to not that dropoff method is not available
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: DropoffMethodsActions.NoDropoffMethodsAvailable,
      });
    }
    dispatch(clearLoadingIndicator());
  };

  // getLocationDisplaySortMethod function will return the locations display sorting method
  // based on A/B testing. Also makes sure that, within a session, the sorting method is preserved
  const getLocationDisplaySortMethod = (): string => {
    if (customer.featureStudyIDs?.has(FeatureStudyIDs.locationDisplayByConfiguration)) {
      return PREFERRED_SORT_METHOD;
    }

    if (customer.featureStudyIDs?.has(FeatureStudyIDs.locationDisplayByExperiment)) {
      return EXPERIMENT_SORT_METHOD;
    }

    if (customer.featureStudyIDs?.has(FeatureStudyIDs.locationDisplayPerformanceFilterOff)) {
      return PREFERRED_SORT_METHOD;
    }

    if (customer.featureStudyIDs?.has(FeatureStudyIDs.locationDisplayPerformanceFilterOn)) {
      return LOCATION_PERFORMANCE_SORT;
    }

    if (Math.random() < CONFIGURED_VS_EXPERIMENT_SPLIT) {
      // 10% - ordered by ranking (experiment)
      try {
        dispatch(addFeatureStudyID(FeatureStudyIDs.locationDisplayByExperiment));
      } catch (error) {
        console.error("failed to add feature study ID", error);
      }
      return EXPERIMENT_SORT_METHOD;
    } else {
      // 90% - ordered by configuration (default, and same as preferred)
      try {
        dispatch(addFeatureStudyID(FeatureStudyIDs.locationDisplayByConfiguration));
      } catch (error) {
        console.error("failed to add feature study ID", error);
      }
      // The performance filter On vs Off test uses the population from this 90% segment
      // The overlapping portion of both tests will have 2 feature study tags
      if (Math.random() < PERFORMANCE_ON_VS_OFF_SPLIT) {
        // 50% - location performance filter on
        try {
          dispatch(addFeatureStudyID(FeatureStudyIDs.locationDisplayPerformanceFilterOn));
        } catch (error) {
          console.error("failed to add feature study ID", error);
        }
        return LOCATION_PERFORMANCE_SORT;
      } else {
        // 50% - location performance filter off
        try {
          dispatch(addFeatureStudyID(FeatureStudyIDs.locationDisplayPerformanceFilterOff));
        } catch (error) {
          console.error("failed to add feature study ID", error);
        }
      }
      return PREFERRED_SORT_METHOD;
    }
  };

  // if there is only 1 dropoffmethod then hide you can change the method later message
  const hideChangeLaterIfSingleDropOffMethod = (dropoffMethods) => {
    const subtitle = document.getElementById("middle-subtitle-dropoffMethods");
    if (dropoffMethods.length < 2 && subtitle) {
      subtitle.style.display = "none";
    }
  };

  const populateShowFreeStatus = (dropoffMethods) => {
    let hasNonZeroFee = false;
    let hasZeroFeeReturnBar = false;
    let hasZeroFeeRetailerStore = false;
    dropoffMethods.forEach((d) => {
      if (d.fee?.amount > 0) {
        hasNonZeroFee = true;
      }
      if (d.id === DropoffMethodIDs.returnBar && (!d.fee || d.fee?.amount == 0)) {
        hasZeroFeeReturnBar = true;
      }
      if (d.id === DropoffMethodIDs.retailerStore && (!d.fee || d.fee?.amount == 0)) {
        hasZeroFeeRetailerStore = true;
      }
    });
    if (hasNonZeroFee) {
      if (hasZeroFeeReturnBar && hasZeroFeeRetailerStore) {
        dropoffMethods.forEach((d) => {
          d.displayFreeTextReturnBar = true;
          d.displayFreeTextRetailerStore = true;
        });
      } else if (hasZeroFeeReturnBar) {
        dropoffMethods.forEach((d) => {
          d.displayFreeTextReturnBar = true;
        });
      } else if (hasZeroFeeRetailerStore) {
        dropoffMethods.forEach((d) => {
          d.displayFreeTextRetailerStore = true;
        });
      }
    }
  };

  const saveReturnBarLocationsInLocalStorage = (dropoffMethods) => {
    const rbLocations: any[] = [];
    dropoffMethods.forEach((d) => {
      if (d.id === DropoffMethodIDs.returnBar) {
        d.locations?.forEach((loc) => {
          rbLocations.push(loc.id);
        });
      }
    });
    localStorage.setItem("rbLocations", JSON.stringify(rbLocations));
  };

  const hydrateSelectedDropoffMethodIfNecessary = (currentDropoffMethod: DropoffMethod) => {
    if (currentDropoffMethod.carrierOptions) {
      const { name, serviceLevels } = currentDropoffMethod.carrierOptions[0];
      return {
        ...currentDropoffMethod,
        header: t("shipWithName", { name: name }),
        selectedCarrierName: name,
        selectedLabelOption: name,
        selectedServiceLevel: serviceLevels[0].serviceLevel,
      };
    } else {
      return currentDropoffMethod;
    }
  };

  const getCoordinatesFromLink = (link: string | undefined) => {
    if (!link) return;
    const url = new URL(link);
    const latitude = url.searchParams.get("latitude");
    const longitude = url.searchParams.get("longitude");
    if (latitude && longitude) {
      return { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
    }
  };

  const initializeStatsigClient = async (email: string) => {
    const claims = (app.runtime && app.token) ? jwtClaimsByRuntime[app.runtime]?.(app.token) : {returnsApp: {}};
    await statsigClient.initialize({
      email: email,
      retailerID: retailerName,
      environment: window?.appConfig?.HR_ENVIRONMENT ?? "local",
      locale: app.locale,
      returnSessionID: "",
    });
    if (statsigClient.isStatsigInitialized && experimentData === null)
      await loadExperimentData();
  }

  const loadExperimentData = async () => {
    const experiment = await statsigClient.getExperiment(statsigExperiment.return_bar_offering_copy)
    setExperimentData(statsigClient.getExperimentData(experiment));
  }

  const logExperimentEvent = () => {
    if (!experimentData?.group == null || experimentData?.group == "") return;

    let eventTitle = (changeDropoffReturnID) ? statsigEvents.return_bar_offering_copy_experiment_changed_dropoff_method : statsigEvents.return_bar_offering_copy_experiment;
    var dmIDs: string[] = dropoffMethods.map(dm => dm.id);
    statsigClient.logEvent(eventTitle, experimentData?.group || "No Group Assigned", {
      "blockedBy": experimentData?.blockedBy || "",
      "dropoffMethodsList": dmIDs.join(),
      "group": experimentData?.group != null ? experimentData?.group : "No Group Assigned",
      "isAllExpandMethodsConfigEnabled": expandAllDropoffMethods.toString(),
      "label": experimentData?.label || "",
      "previousDropoffMethodID": customer?.returns?.dropoffMethod?.methodID ?? "",
      "selectedDropoffMethod": selectedDropoffMethod?.id || "",
      "wasShownReturnBarAndInStoreOfferings": ((dmIDs.includes(DropoffMethodIDs.returnBar) && dmIDs.includes(DropoffMethodIDs.retailerStore)) ?? false).toString(),
    });


    if (!changeDropoffReturnID) statsigClient.shouldLogSubmitReturn = true;
  }
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const handleExpandAllDropoffMethods = (e) => {
    setExpandAllDropoffMethods(true);
  };
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  const dropoffMethodCardComponentsByID = {
    [DropoffMethodIDs.returnBar]: LocationDropoffMethodCard,
    [DropoffMethodIDs.retailerStore]: LocationDropoffMethodCard,
  };

  const renderDropoffMethodCards = (dropoffMethods: DropoffMethodState) => {
    return dropoffMethods.map((dropoffMethod) => {
      // attempt to find a component that matches the dropoff method id
      const Component = dropoffMethodCardComponentsByID[dropoffMethod.id];
      // assign DropoffComponent to be LocationDropoffMethodCard if Component evaluates to truthy, otherwise
      // if carrierOptions exists on the dropoffMethod object, assign it to BleckmannDropoffMethodCard.
      // default to DropoffMethodCard
      const DropoffComponent =
        Component || (dropoffMethod.carrierOptions ? BleckmannDropoffMethodCard : DropoffMethodCard);
      return (
        <DropoffComponent
          key={dropoffMethod.id}
          idx={dropoffMethod.priorityIdx}
          dropoffMethod={dropoffMethod}
          selected={dropoffMethod.id === selectedDropoffMethod?.id && !selectedDropoffMethod?.selectedServiceLevel}
          onClick={(e) => {
            if (e.target.closest(`[data-cy^=${DataCyStrings.location}]`)) {
              //if user clicked in a return-bar location area, find the location index
              const curLocation = e.target.closest(`[data-cy^=${DataCyStrings.location}]`);
              const locationIndex = curLocation.getAttribute("data-index");
              ga.event({
                category: AnalyticCategories.DropoffMethodsPage,
                action: DropoffMethodsActions.StoreGeneralArea,
                label: locationIndex,
              });
            } else {
              const newDropoffCard = e.target.closest(".dropoff-card");
              //Only send new event if the dropoff card was not selected before
              if (newDropoffCard.className.indexOf("selected") < 0) {
                const dropoffSelected = newDropoffCard.id.substring(newDropoffCard.id.indexOf("_") + 1);
                ga.event({
                  category: AnalyticCategories.DropoffMethodsPage,
                  action: DropoffMethodsActions.DropoffOption,
                  label: dropoffSelected,
                });
              }
            }
            dispatch(setSelectedDropoffMethod(dropoffMethod));
          }}
          locationsToggleState={locationsToggleState}
          setLocationsToggleState={setLocationsToggleState}
          runtime={app.runtime}
        />
      );
    });
  };

  const submitChangeDropoffMethod = async () => {
    ga.event({
      category: AnalyticCategories.DropoffMethodsPage,
      action: CommonPageActions.NextStep,
    });
    logExperimentEvent();
    dispatch(showLoadingIndicator(defaultLoadingSymbol));
    const payload: ChangeDropoffMethodPayload = { methodID: selectedDropoffMethod?.id };
    if (selectedDropoffMethod?.selectedServiceLevel) payload.serviceLevel = selectedDropoffMethod.selectedServiceLevel;
    try {
      const resp = await axios.post(`return/${changeDropoffReturnID}/dropoffmethod`, payload);
      openReturnStatusPage(changeDropoffReturnID, resp.data.returnStatusToken, locale);
    } catch (e) {
      dispatch(clearLoadingIndicator());
      setDropoffMethodsModalState({
        header: t("unableToUpdateDropoff"),
        error: t("sorryThereWasAnIssueFullMessage"),
      });
    }
  };

  const openReturnStatusPage = (returnID, returnStatusToken, locale) => {
    window.open(`/?returnStatusReturnID=${returnID}&locale=${locale}&authorization=${returnStatusToken}`, "_self");
  };

  const footer = changeDropoffReturnID ? "dropoff-method" : "return-count";
  const advance = changeDropoffReturnID
    ? () => submitChangeDropoffMethod()
    : () => {
        ga.event({
          category: AnalyticCategories.DropoffMethodsPage,
          action: CommonPageActions.NextStep,
        });
        logExperimentEvent();
        lifecycle.advance();
      };

  return (
    <ReturnPortalBaseWithPage
      footer={footer}
      page={page}
      advance={advance}
      isFooterDisabledCallback={() => selectedDropoffMethod === null}
    >
      <>
        <$DropoffMethods>
          {sustainableOptions.length > 0 && (
            <>
              <div className="sustainable-options">
                <div className="header" data-cy={DataCyStrings.recommendedOptionsHeader}>
                  {t("recommendedOptions")}
                </div>
              </div>
              {renderDropoffMethodCards(sustainableOptions)}
              {expandAllDropoffMethods && nonSustainableOptions.length > 0 && (
                <div className="other-options-header">{t("otherOptions")}</div>
              )}
              {!expandAllDropoffMethods && nonSustainableOptions.length > 0 && (
                <button
                  className="expand-options"
                  data-cy={DataCyStrings.showMoreOptions}
                  onClick={(e) => {
                    handleExpandAllDropoffMethods(e);
                    ga.event({
                      category: AnalyticCategories.DropoffMethodsPage,
                      action: DropoffMethodsActions.ShowOtherOptions,
                    });
                  }}
                >
                  {t("showOtherOptions")}
                </button>
              )}
            </>
          )}
          {nonSustainableOptions.length > 0 &&
            expandAllDropoffMethods &&
            renderDropoffMethodCards(nonSustainableOptions)}
        </$DropoffMethods>
        {dropoffMethodsModalState.header != "" &&
          (dropoffMethodsModalState.error != "" || dropoffMethodsModalState.message != "") && (
            <DropoffMethodsModal
              {...dropoffMethodsModalState}
              setDropoffMethodsModalState={setDropoffMethodsModalState}
            />
          )}
      </>
    </ReturnPortalBaseWithPage>
  );
  //----------------------------------------------------------------------------
};

export const DropoffMethodCard = ({ children, dropoffMethod, selected, onClick }: DropoffMethodCardProps) => {
  const { id, fee, header, info, locationLink, displayFreeTextReturnBar, displayFreeTextRetailerStore } = dropoffMethod;
  const { t } = useTranslation();
  const dropoffCardHandlers = handleEnterKeyPressOnClickHandlers(onClick);
  const radioID = `is-${id}-selected`;
  const dropoffCardClassname = `dropoff-card${selected ? " selected" : ""}`;
  const hasFee = fee?.amount && +fee?.amount != 0;
  const storeLocationLinkID = `storeLocations_${id}`;
  const dropoffCardID = `dropoffcard_${id}`;
  const onSeeAllStoreLocationsClick = (e) => {
    if (e.target.id.indexOf("return-bar") > -1) {
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: DropoffMethodsActions.SeeAllReturnBarLocations,
      });
    } else if (e.target.id.indexOf("retailer-store") > -1) {
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: DropoffMethodsActions.SeeAllStoreLocations,
      });
    }
  };

  const onIconClick = () => {
    ga.event({
      category: AnalyticCategories.DropoffMethodsPage,
      action: DropoffMethodsActions.Icon,
    });
  };
  return (
    <div id={dropoffCardID} className={dropoffCardClassname} data-cy={id + "-dropoff-card"} {...dropoffCardHandlers}>
      <div className="label">
        <div className="dropoff-icon" onClick={onIconClick}>
          <SVG name={dropoffMethodIconsByID[id] as SVGName} />
        </div>
        <div className="dropoff-content">
          <div className="content-main">
            <div className="dropoff-header" data-cy={DataCyStrings.dropoffHeader}>
              {header}
            </div>
            <div className="dropoff-price" data-cy={DataCyStrings.dropoffPrice}>
              {(id === DropoffMethodIDs.returnBar && displayFreeTextReturnBar) ||
              (id === DropoffMethodIDs.retailerStore && displayFreeTextRetailerStore)
                ? t("free")
                : hasFee && `${fee?.amount} ${fee?.currencyCode}`}
            </div>
            <div className="dropoff-radio">
              <label htmlFor={radioID}>{selected ? t("methodIsSelected") : t("methodIsNotSelected")}</label>
              <input id={radioID} name="is-method-selected" type="radio" checked={selected} tabIndex={-1} readOnly />
            </div>
          </div>
          {selected && info && <div className="dropoff-text">{info}</div>}
        </div>
      </div>
      {selected && (
        <div className="dropoff-content">
          <div className="dropoff-locations">{children}</div>
          {locationLink?.url && locationLink.text && (
            <div className="dropoff-link">
              <a
                id={storeLocationLinkID}
                href={locationLink?.url}
                target="_blank"
                onClick={onSeeAllStoreLocationsClick}
                rel="noreferrer"
              >
                {locationLink?.text}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const BleckmannDropoffMethodCard = ({ dropoffMethod, selected }: DropoffMethodCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { app, customer } = useSelector((store: RootReducer) => store);
  const { selectedDropoffMethod } = customer;
  const { fee, carrierOptions } = dropoffMethod;
  // replace/remove if copy string becomes dynamic
  const bleckmannDropoffMethodText = {
    qrCode: {
      header: t("mobileQrCode"),
      info: t("packUpYourItemsAnd"),
    },
    printed: {
      header: t("printLabel"),
      info: t("printShippingLabel"),
    },
  };

  const renderServiceLevelOptionRadios = (option: DropoffCarrierOption) => {
    const { serviceLevels } = option;
    return serviceLevels.map((serviceLevelOption) => {
      const { name, serviceLevel, labelType } = serviceLevelOption;
      const carrierName = option.name;
      const dropoffText = name === "QR Code" ? bleckmannDropoffMethodText.qrCode : bleckmannDropoffMethodText.printed;
      const isSelectedServiceLevel =
        selected && selectedDropoffMethod != null && selectedDropoffMethod.selectedServiceLevel?.includes(labelType);
      const carrierOptionClassName = `carrier-option${isSelectedServiceLevel ? " selected" : ""}`;
      // each radio needs a unique id so labels can be assigned properly
      // split and join via "-" to consistently hyphenate the id attribute
      const radioId = `is-${carrierName.toLowerCase().split(" ").join("-")}-${labelType.toLowerCase()}-selected`;
      const radioOnClick = (e) => {
        dispatch(
          setSelectedDropoffMethod({
            ...dropoffMethod,
            header: t("shipWithName", { name: option.name }),
            selectedCarrierName: option.name,
            selectedLabelOption: name,
            selectedServiceLevel: serviceLevel,
          })
        );
        e.stopPropagation();
      };
      const onIconClick = () => {
        ga.event({
          category: AnalyticCategories.DropoffMethodsPage,
          action: DropoffMethodsActions.Icon,
        });
      };
      const onQRCodeClick = () => {
        ga.event({
          category: AnalyticCategories.DropoffMethodsPage,
          action: DropoffMethodsActions.DropoffOption,
          label: `${option.name + dropoffText.header}`,
        });
      };
      return (
        <div
          key={name}
          className={carrierOptionClassName}
          data-cy={`${carrierName.toLowerCase().split(" ").join("-")}-${labelType.toLowerCase()}-carrier-option`}
          {...handleEnterKeyPressOnClickHandlers(radioOnClick)}
        >
          <div className="dropoff-icon" onClick={onIconClick}>
            <SVG name={name === "QR Code" ? "qrCode" : "printer"} />
          </div>
          <div className="option-content">
            <div className="option-content-main">
              <div className="option-header">{dropoffText.header}</div>
              <div className="dropoff-radio">
                <label htmlFor={radioId}>
                  {isSelectedServiceLevel ? t("methodIsSelected") : t("methodIsNotSelected")}
                </label>
                <input
                  id={radioId}
                  name="is-method-selected"
                  type="radio"
                  checked={isSelectedServiceLevel}
                  tabIndex={-1}
                  readOnly
                  onClick={onQRCodeClick}
                />
              </div>
            </div>
            {isSelectedServiceLevel && dropoffText && <div className="dropoff-text">{dropoffText.info}</div>}
          </div>
        </div>
      );
    });
  };

  return carrierOptions?.map((option) => {
    const { name, serviceLevels } = option;
    const header = t("shipWithName", { name });
    selected = selectedDropoffMethod?.selectedCarrierName === name;
    const dropoffCardClassname = `dropoff-card${selected ? " selected" : ""}`;
    const cardOnClick = () => {
      dispatch(
        setSelectedDropoffMethod({
          ...dropoffMethod,
          header,
          selectedCarrierName: name,
          selectedLabelOption: selectedDropoffMethod?.selectedLabelOption || serviceLevels[0].name,
          selectedServiceLevel: selectedDropoffMethod?.selectedServiceLevel || serviceLevels[0].serviceLevel,
        })
      );
      //temporary GA4 event. We may need to remove as cardOnClick() does not appear to be getting called
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: `CardOnClick called`,
      });
    };
    const hasFee = fee?.amount && +fee?.amount != 0;
    return (
      <div key={name} className={dropoffCardClassname} {...handleEnterKeyPressOnClickHandlers(cardOnClick)}>
        <div className="label">
          <div className="dropoff-content">
            <div className="content-main">
              <div className="dropoff-header">{header}</div>
              <div className="dropoff-price">{hasFee && `${fee?.amount} ${fee?.currencyCode}`}</div>
            </div>
            {renderServiceLevelOptionRadios(option)}
          </div>
        </div>
      </div>
    );
  });
};

const RenderLocationAddress = (address: Address) => {
  return [address.address, address.city, address.state, address.zipcode].filter((s) => s && s.length).join(", ");
};

const RenderLocationURL = (address: Address) => {
  return "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(RenderLocationAddress(address));
};

const DetailsToggle = ({ active, onClick }) => {
  const { t } = useTranslation();
  return (
    <span className="toggle-details" onClick={onClick}>
      {active ? t("showLess") : t("showMore")}
      <SVG name={active ? "up" : "down"} />
    </span>
  );
};

// LocationDropoffMethodCard will drive both return-bar and retailer-store dropoff cards
const LocationDropoffMethodCard = ({
  dropoffMethod,
  selected,
  onClick,
  idx,
  locationsToggleState,
  setLocationsToggleState,
}: DropoffMethodCardProps) => {
  const { locations } = dropoffMethod as ReturnBarDropoffMethod;
  const { t } = useTranslation();
  const _state = locationsToggleState;

  const parsedLocations = locations?.map((location, index) => {
    const dataCy = `${DataCyStrings.location}_${index}`;
    const onLocationURLClicked = (e) => {
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: DropoffMethodsActions.StoreMileage,
      });
      e.stopPropagation();
    };
    const parsedHours = formatLocationHours(location.openingHours, location.timeZone);
    const toggleState = (e) => {
      const nextState = { ..._state };
      nextState[index] = !(_state[index] || false);
      if (nextState[index]) {
        ga.event({
          category: AnalyticCategories.DropoffMethodsPage,
          action: DropoffMethodsActions.ShowMore,
          label: `${index}`,
        });
      } else {
        ga.event({
          category: AnalyticCategories.DropoffMethodsPage,
          action: DropoffMethodsActions.ShowLess,
          label: `${index}`,
        });
      }
      setLocationsToggleState(nextState);
      e.stopPropagation();
    };
    const onCouponClick = (e) => {
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: DropoffMethodsActions.StoreCoupon,
      });
      e.stopPropagation();
    };
    const onAddressClick = (e) => {
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: DropoffMethodsActions.StoreAddress,
      });
      e.stopPropagation();
    };
    return (
      <div key={index} className="location" data-cy={dataCy} data-index={index}>
        <div className="location-main">
          <div className="name">{location.name}</div>
          <div className="distance">
            <a
              target="_blank"
              onClick={onLocationURLClicked}
              href={RenderLocationURL(location.address)}
              rel="noreferrer"
            >
              {location.distance?.toFixed(1)} mi
            </a>
          </div>
        </div>
        <div className="location-status">
          <span>{parsedHours.status}</span>
          <DetailsToggle active={_state[index]} onClick={toggleState} />
        </div>
        {!!_state[index] && !!parsedHours.schedule?.length && (
          <div className="location-details">
            <div className="icon-column">
              <SVG name={"clock"} />
            </div>
            <div className="display">
              {parsedHours.schedule.map((row, i) => (
                <div key={i}>{row}</div>
              ))}
              {location.hoursMayVary ? <div>{t("hoursMayVaryDuring")}</div> : null}
            </div>
          </div>
        )}
        {!!_state[index] && location.address && (
          <div className="location-details">
            <div className="icon-column">
              <SVG name={"location"} />
            </div>
            <div className="display address">
              <a target="_blank" onClick={onAddressClick} href={RenderLocationURL(location.address)} rel="noreferrer">
                {RenderLocationAddress(location.address)}
              </a>
            </div>
          </div>
        )}
        {location.display &&
          location.display?.map((row, index) => {
            if (row.label != "Promotions") return <></>;
            return (
              <div key={index} className="location-promotion">
                <div className="icon-column">
                  <SVG name={"tag"} />
                </div>
                <div className="display promotion" onClick={onCouponClick}>
                  {row.value}
                </div>
              </div>
            );
          })}
      </div>
    );
  });
  return (
    <DropoffMethodCard
      idx={idx}
      key={dropoffMethod.id}
      dropoffMethod={dropoffMethod}
      selected={selected}
      onClick={onClick}
      locationsToggleState={{}}
      setLocationsToggleState={() => {}}
    >
      <>{parsedLocations}</>
    </DropoffMethodCard>
  );
};

const DropoffMethodsModal = ({ header, message, error, setDropoffMethodsModalState }: DropoffMethodsModalState) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const signUserOut = (e?) => {
    dispatch(goToPage("loginReturnPortal"));
    dispatch(reset());
    dispatch(setToken(""));
  };

  const closeModal = () => {
    error
      ? signUserOut()
      : setDropoffMethodsModalState?.({
          error: "",
          header: "",
          message: "",
        });
  };

  if (header && (message || error)) {
    return (
      <PageModal
        page={{
          type: "modal",
        }}
        onClose={closeModal}
        noHeader={true}
      >
        <$DropoffMethodsContents>
          <div className="header">{header}</div>
          {message && <div className="message">{message}</div>}
          {error && (
            <div className="banner">
              <ErrorAlertMessage message={error} />
            </div>
          )}
          {error && <PrimaryButton onButtonClick={closeModal} label={t("goToLoginPage")} />}
        </$DropoffMethodsContents>
      </PageModal>
    );
  }
  useEffect(() => {
    return () => {
      if (error) {
        signUserOut();
      }
    };
  }, []);
  return null;
};

export default DropoffMethods;
