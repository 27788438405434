import React from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ReturnistaModalTemplate from "../../components/Modal/ReturnistaModalTemplate";
import { goToPage } from "../../redux/app/actions";
import { DataCyStrings } from "../../types/DataCyStrings";

/*
  Modals use exclusively in the instance list page
*/

export const ModalTemplate = ({
  quantityKeyword
}:{
  quantityKeyword: "more" | "fewer"
}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(goToPage("countItemsPresent"));
  return (
    <ReturnistaModalTemplate
      dataCy={DataCyStrings.countItemsPresentModal}
      headerText="Select which items are present"
      onClose={() => {}} // force user to click on primary button to exit
    >
      <h1>
        Need to update the return?
      </h1>
      <p>
        You selected {quantityKeyword} items than were counted for this return. Go back to submit the correct number of items.
      </p>
      <PrimaryButton 
        label="Enter New Item Count"
        onButtonClick={onClose}
        dataCyString={DataCyStrings.countItemsPresentUpdateItemCount}
      />
    </ReturnistaModalTemplate>
  )
}