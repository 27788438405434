// third-party imports
import { isEmpty, last } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// repo imports
import $TypeToteBarcode from "./styles";
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { ErrorAlertMessage } from "../../components/AlertMessage";
import SearchBar from "../../components/SearchField";
import { setLocalErrorMessage } from "../../redux/app/actions";
import { addBagToteBarcodePairToDatabase, updateBagToteBarcodePair } from "../../redux/customer/actions";
import { Store as CustomerStore } from "../../redux/customer/types";
import { RootReducer } from "../../redux/reducers";
import { DataCyStrings } from "../../types/DataCyStrings";
import { isInvalidToteBarcode } from "../../utility";
import useSelector from "../../utility/useTypedSelector";
import logger from "../../utility/logger";

class TypeToteBarcodeLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, app, dispatch) {
    super(page, app, dispatch);
  }

  satisfiesPreconditions(): boolean {
    if (!super.satisfiesPreconditions()) return false;
    if (isEmpty(this.customer?.bagToteBarcodePairs)) return false;

    logger.Info("Preconditions satisfied. Rendering TypeToteBarcode...");
    return true;
  }
}

export const TypeToteBarcodeErrorMessage = "Tote barcode ID must be at least one alphanumeric character.";

/**
 *
 */
const TypeToteBarcode = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();

  const { app, customer } = useSelector((store: RootReducer) => store);

  const { loadingMessage, localErrorMessage } = app;
  const { bagToteBarcodePairs, returns } = customer;

  const lifecycle = new TypeToteBarcodeLifecycle(page, dispatch, app);

  const [toteBarcode, setToteBarcode] = useState("");
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    dispatch(setLocalErrorMessage(""));
  }, []);

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const handleChange = (value) => {
    dispatch(setLocalErrorMessage(""));
    setToteBarcode(value.toUpperCase());
  };

  const handleClear = () => {
    dispatch(setLocalErrorMessage(""));
    setToteBarcode("");
  };

  const handleSubmit = () => {
    if (loadingMessage) return;

    if (isInvalidToteBarcode(toteBarcode)) {
      dispatch(setLocalErrorMessage(TypeToteBarcodeErrorMessage));
      return;
    }

    const currentPair = last(bagToteBarcodePairs);
    const updatedPair = { bagBarcode: currentPair.bagBarcode, toteBarcode };

    // do not persist bag, tote scans in DB if using test order
    if (returns?.confirmationCode === "HRTESTER") {
      dispatch(updateBagToteBarcodePair(updatedPair));
    } else {
      dispatch(addBagToteBarcodePairToDatabase(updatedPair));
    }

    lifecycle.advance();
  };

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------

  // RENDERING
  const renderErrorMessage = () => {
    if (!localErrorMessage) return;

    return (
      <div className="error-message-container">
        <ErrorAlertMessage message={localErrorMessage} />
      </div>
    );
  };

  return (
    <$TypeToteBarcode>
      <SearchBar
        inputDataCyString={DataCyStrings.scanToteCodeInput}
        onChange={handleChange}
        onClear={handleClear}
        onSubmit={handleSubmit}
        placeholder={"Enter barcode/tote ID number"}
        value={toteBarcode}
      />
      {renderErrorMessage()}
    </$TypeToteBarcode>
  );
  //----------------------------------------------------------------------------
};

export default TypeToteBarcode;
