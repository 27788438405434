import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import logger from "../utility/logger/logger";
import useSelector from "../utility/useTypedSelector";
import { reset } from "../redux/customer/actions";
import { Page } from "../types/Config"
import {
  goToPage,
  onFatalError,
} from "../redux/app/actions";
import { FatalErrors } from "../types/LifeCycle";
import { appReducer } from "../redux/app/reducers";
import { Store as AppStore } from "../redux/app/types";

// this class controls the lifecycle of a page
// pages can override various methods if they need to customize their lifecycle behavior
//
// each of our page functional components should carry their own variant around, and use it
export class PageLifecycle {  

  // for convenience, we store the configuration as a member variable
  configuration: Page;
  dispatch: Function;
  app: AppStore;

  // pass in page configuration to help guide the flow
  constructor(configuration: Page, dispatch: Function, app: AppStore) {
    this.configuration = configuration
    this.dispatch      = dispatch
    this.app           = app
  }

  // each child should override this
  // if the contents of the store are not as needed to initialize this page, return false
  satisfiesPreconditions() {
    if (!this.configuration || !this.configuration.nextPages) return false
    if (!this.configuration?.nextPages?.default) return false
    return true;
  }

  // each child should override this
  // if the contents of the store are not as needed to complete this page, return false
  satisfiesPostconditions() {
    return true;
  }

  // a convenience method, throws a fatal error if preconditions aren't satisfied
  validatePreconditions() {
    if (!this.satisfiesPreconditions()) {
      logger.Info("Pre conditions not met, rendering error...")
      this.advanceToFatalError(FatalErrors.unknown)
    }
  }

  // when a user is done with a page, call this to advance to the next page
  // override this to implement a branching flow
  advance(pageName?: string) {
    if (this.satisfiesPostconditions()) {
      pageName ? 
        this.dispatch(goToPage(this.configuration?.nextPages[pageName])) : 
        this.dispatch(goToPage(this.configuration?.nextPages?.default))
    }
    else {
     this.advanceToFatalError(FatalErrors.unknown)
    }
  }

  // if a fatal error occcurs on a page, call this to tell the app to 
  // show that error and leave the app in a terminal state
  advanceToFatalError(errorName: FatalErrors) {
    if (this.app.fatalErrorName != errorName) 
      // for now, every time we throw a manual error we should also manually reset
      // the customer store (in the future we could link our stores)
      this.dispatch(onFatalError(errorName))
      this.dispatch(reset())
    }
  }