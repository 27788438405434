import React from "react";
import { useDispatch } from "react-redux";

import { PageProps } from "../types";
import { PageLifecycle } from "../";

import { SVG } from "../../components/svg";
import PrimaryButton  from "../../components/Button/PrimaryButton";
import useSelector from "../../utility/useTypedSelector";

import $ReturnComplete from "./styles";
import { DataCyStrings } from "../../types/DataCyStrings";



class ReturnCompleteLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

/**
 * Last step of the Returnista flow
 */
const ReturnComplete = ({ page }: PageProps) => {
  const dispatch = useDispatch();

  const { app } = useSelector(store => store);

  const lifecycle = new ReturnCompleteLifecycle(page, dispatch, app);

  return (
    <$ReturnComplete>
      <div className="icon">
        <SVG name="checkCircle" />
      </div>
      <div className="title" data-cy={DataCyStrings.returnCompleteHeader}>
        Return Successfully Processed
      </div>
      <div className="prompt">
        Please place the bagged items into a Happy Returns box.
      </div>
      <div className="home">
        <PrimaryButton dataCyString={DataCyStrings.goToHomeScreenButton} width="300px" label="Return to Home Screen" onButtonClick={() => lifecycle.advance()} />
      </div>
    </$ReturnComplete>
  );
}

export default ReturnComplete;