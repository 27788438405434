import produce from "immer";
import reduceReducers from "reduce-reducers";
import logger from "../../utility/logger/logger";
import { cloneDeep } from "lodash";
import { Instance } from "../../types/Instance";
import {
  Store,
  ReturnTypes,
  SET_RETURNS,
  MARK_ITEM_COMPLETE,
  UNMARK_ITEM_COMPLETE,
  SET_EXPRESS_CODE,
  RESET_CUSTOMER,
  ADD_BARCODE,
  DELETE_BARCODE,
  ADD_BAG_TOTE_BARCODE_PAIR,
  DELETE_BAG_TOTE_BARCODE_PAIR,
  UPDATE_BAG_TOTE_BARCODE_PAIR,
  SET_RETURN_TYPE,
  SET_CUSTOMER_ORDERS,
  SET_SELECTED_INSTANCE,
  DESELECT_COMPLETED_INSTANCE_WITH_ID,
  SET_QUERY,
  SET_RETURN_REASON_SELECTED_INSTANCE,
  SET_RETURN_REASON_CHILD_SELECTED_INSTANCE,
  RESET_RETURN_REASON_CHILD_SELECTED_INSTANCE,
  SET_RETURN_NOTES_SELECTED_INSTANCE,
  SET_SELECTED_INSTANCE_FOR_COMPLETION,
  SET_SELECTED_INSTANCE_EXCHANGE_PROPERTIES,
  SET_SELECTED_INSTANCE_EXCHANGE,
  SET_NEW_RETURN_ID,
  SET_GIFT_RETURN_EMAIL,
  RESET_CUSTOMER_ORDERS,
  SET_NEW_RETURN_EMAIL,
  SET_CHANGE_DROPOFF_RETURN_ID,
  SET_SELECTED_DROPOFF_METHOD,
  RESET_ITEMS_MARKED_FOR_COMPLETION,
  SET_REFUND_OPTIONS,
  SET_SELECTED_REFUND,
  SET_CUSTOMER_STORE,
  SET_PURCHASING,
  SET_RETURN_SESSION_ID,
  ADD_FEATURE_STUDY_ID,
  SET_RETURN_STATUS_RETURN_ID,
  SET_RETAILER,
  SET_SHIPPING_ADDRESS_COORDINATES
} from "./types";
import { SET_CONFIGURATION, ON_FATAL_ERROR } from "../app/types";
import { isSelectedForReturn } from "../../utility";


// CUSTOMER SLICES
import {
  initialState as countItemsPresentInitialState,
  countItemsPresentReducer
} from "../slices/countItemsPresent";
import type {
  CountItemsPresentState
} from "../slices/countItemsPresent";
//////////////////

export const initialState: Store = {
  returns: undefined,
  expressCode: "",
  query: "",
  currentBrand: "",
  currentOrder: undefined,
  itemsMarkedForCompletion: [],
  barcodes: [],
  bagToteBarcodePairs: [],
  orders: [],
  returnType: ReturnTypes.expressReturn,
  selectedInstance: undefined,
  selectedInstanceExchangeProperties: {
    exchangeOptions:    [],
    exchangeAttributes: []
  },
  selectedDropoffMethod: null,
  newReturnID: "",
  newReturnEmail: "",
  featureStudyIDs: new Set<string>(),
};

export const customerReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_RETURNS:
        draft.returns = action.payload;
        draft.returnType = ReturnTypes.expressReturn;
        // for safety, clear out any items that have been marked for completion
        // (we could be a bit safer by preserving any items that matched the new
        //  returns, but that's not a use case we actually see in our app, so
        //  let's not bother with that for now - ss)
        draft.itemsMarkedForCompletion = []
        return draft

      case MARK_ITEM_COMPLETE:
        // only add if it's not already in array
        if (action.payload && draft.itemsMarkedForCompletion.indexOf(action.payload) === -1) {
          draft.itemsMarkedForCompletion.push(action.payload)
        }
        return draft

      case UNMARK_ITEM_COMPLETE:
        const itemIdx = draft.itemsMarkedForCompletion.indexOf(action.payload)
        if (itemIdx != -1) {
          draft.itemsMarkedForCompletion.splice(itemIdx, 1)
        }
        return draft

      case SET_EXPRESS_CODE:
        draft.expressCode = action.payload
        return draft

      case ADD_BARCODE:
        draft.barcodes.push(action.payload);
        return draft;

      case DELETE_BARCODE:
        const bagIdx = draft.barcodes.indexOf(action.payload);
        bagIdx >= 0 && draft.barcodes.splice(bagIdx, 1);
        return draft;

      case ADD_BAG_TOTE_BARCODE_PAIR:
        draft.bagToteBarcodePairs.push(action.payload);
        return draft;

      case DELETE_BAG_TOTE_BARCODE_PAIR: {
        const pairToDelete = action.payload;

        const pairToDeleteIndex = draft.bagToteBarcodePairs.findIndex(
          (pair) => pair.bagBarcode === pairToDelete.bagBarcode && pair.toteBarcode === pairToDelete.toteBarcode
        );

        if (pairToDeleteIndex !== -1) {
          draft.bagToteBarcodePairs.splice(pairToDeleteIndex, 1);
        }

        return draft;
      }

      case UPDATE_BAG_TOTE_BARCODE_PAIR: {
        const updatedPair = action.payload;
        const pairToUpdate = draft.bagToteBarcodePairs.find((pair) => pair.bagBarcode === updatedPair.bagBarcode);

        if (pairToUpdate) {
          Object.assign(pairToUpdate, action.payload);
        }

        return draft;
      }

      case SET_RETURN_TYPE:
        draft.returnType = action.payload;
        return draft;

      case SET_CUSTOMER_ORDERS:
        draft.orders = action.payload.orders;
        draft.query = action.payload.query;
        return draft;

      case SET_SELECTED_INSTANCE:
        draft.selectedInstance = action.payload;
        return draft;

      case DESELECT_COMPLETED_INSTANCE_WITH_ID:
        const newCompletedInstances = draft.itemsMarkedForCompletion;
        // look through the completed instances and try to match the id,
        // if an ID is found, remove it from the array of completed instances
        const idxToDelete = newCompletedInstances.findIndex((instance: Instance) => {
          return instance.purchase?.id == action.payload;
        });
        if (idxToDelete != -1) {
          newCompletedInstances.splice(idxToDelete, 1);
        }
        if (newCompletedInstances.length === 0) {
          draft.currentBrand = "";
          draft.currentOrder = undefined;
        }
        return draft;

      case SET_QUERY:
        draft.query = action.payload;
        return draft;

      case SET_RETURN_REASON_SELECTED_INSTANCE:
        if (draft.selectedInstance != undefined) {
          draft.selectedInstance.reasonInfo = action.payload;
          draft.selectedInstance.reason = action.payload.id;
        }
        return draft;

      case SET_RETURN_NOTES_SELECTED_INSTANCE:
        if (draft.selectedInstance != undefined) {
          draft.selectedInstance.reasonNote = action.payload;
        }
        return draft;

      case SET_RETURN_REASON_CHILD_SELECTED_INSTANCE:
        if (draft.selectedInstance != undefined) {
          draft.selectedInstance.childReasonInfo = action.payload;
          draft.selectedInstance.childReason = action.payload.id;
        }
        return draft;

      case RESET_RETURN_REASON_CHILD_SELECTED_INSTANCE:
        if (draft.selectedInstance != undefined) {
          draft.selectedInstance.childReasonInfo = undefined;
          draft.selectedInstance.childReason = undefined;
        }
        return draft;

      // set the refund object, mark the instance
      // for completion, and clear selectedInstance;
      case SET_SELECTED_INSTANCE_FOR_COMPLETION:
        if (draft.selectedInstance != undefined) {
          draft.selectedInstance.refund = {
            ...action.payload
          }

          if (draft.selectedInstance.refund != undefined) {
            draft.selectedInstance.refund.optionID = action.payload?.id;
          }
          // mark the selected instance as complete
          draft.itemsMarkedForCompletion.push(draft.selectedInstance);

          // Update brand name in case retailer is dropship enabled to ensure that
          // a return does not contain more than one brand. This field has no effect
          // on non dropship retailers.
          // Note: "default" is a special case, where retailer isDropship but some
          // purchases do not have vendor set.
          draft.currentBrand = draft?.selectedInstance?.purchase?.vendor || "default";

          // Update the order number to ensure the return is limited to one order.
          draft.currentOrder = draft?.selectedInstance?.purchase?.orderNumber;

          // The instance now lives in the items marked for completion array so it's safe to remove this
          // reference to the selected instance
          draft.selectedInstance = undefined;
        }
        return draft;

      case SET_SELECTED_INSTANCE_EXCHANGE_PROPERTIES:
        if (draft.selectedInstance != undefined) {
          draft.selectedInstanceExchangeProperties = action.payload;
        }
        return draft;

      case SET_SELECTED_INSTANCE_EXCHANGE:
        if (draft.selectedInstance != undefined) {
          draft.selectedInstance.refund = {
            id: "exchange",
            optionID: "exchange",
            exchange: action.payload
          }
          // also mark the instance as completed as the exchange
          // is a valid return option
          draft.itemsMarkedForCompletion.push(draft.selectedInstance);
          draft.currentBrand = draft?.selectedInstance?.purchase?.vendor || "default";
          draft.currentOrder = draft?.selectedInstance?.purchase?.orderNumber;
          draft.selectedInstance = undefined;
        }
        return draft;

      case SET_NEW_RETURN_ID:
        draft.newReturnID = action.payload.id;
        if (action.payload.email) {
          draft.newReturnEmail = action.payload.email;
        }
        return draft;

      case SET_GIFT_RETURN_EMAIL:
        draft.newReturnEmail = action.payload;
        draft.returnType = ReturnTypes.giftReturn;
        return draft;

      case SET_NEW_RETURN_EMAIL:
        draft.newReturnEmail = action.payload;
        for (const instance of draft.itemsMarkedForCompletion) {
          if (isInstance(instance)) {
            instance.purchase.email = action.payload;
          }
        }
        return draft;
      case SET_CHANGE_DROPOFF_RETURN_ID:
        draft.changeDropoffReturnID = action.payload;
        return draft;
      case SET_RETURN_STATUS_RETURN_ID:
        draft.returnStatusReturnID = action.payload;
        return draft;
      case SET_SELECTED_DROPOFF_METHOD:
        draft.selectedDropoffMethod = action.payload;
        return draft;
      case SET_SHIPPING_ADDRESS_COORDINATES:
        draft.itemsMarkedForCompletion.forEach((item: Instance) => {
          item.purchase.shippingAddress = {
            ...item.purchase.shippingAddress,
            latitude: action.payload.latitude,
            longitude: action.payload.longitude,
          };
        });
        return draft;
      case RESET_CUSTOMER_ORDERS:
        draft.itemsMarkedForCompletion = [];
        draft.orders = [];
        return draft;
      case SET_CONFIGURATION:
        const { expressCode } = action.payload;
        draft.expressCode = expressCode;
        return draft;
      case SET_REFUND_OPTIONS:
        draft.refundOptions = action.payload;
        return draft;
      case SET_SELECTED_REFUND:
        draft.itemsMarkedForCompletion.forEach((instance: Instance) => {
          if (isSelectedForReturn(instance)) {
            instance.refund = action.payload;
          }
        })
        return draft;
      case SET_CUSTOMER_STORE:
        draft = action.payload;
        return draft;
      case SET_PURCHASING:
        draft.purchasing = action.payload;
        return draft;
      case SET_RETURN_SESSION_ID:
        draft.returnSessionID = action.payload;
        return draft;
      case ADD_FEATURE_STUDY_ID:
        draft.featureStudyIDs?.add(action.payload);
        return draft;
      case SET_RETAILER:
        draft.retailer = action.payload;
        return draft;
      case RESET_ITEMS_MARKED_FOR_COMPLETION:
        draft.itemsMarkedForCompletion = []
        return draft;
      case ON_FATAL_ERROR:
      case RESET_CUSTOMER:
        draft = action.payload || cloneDeep(customerFlattenedStore);
        return draft;
    }
  });
};


function isInstance(val: any): val is Instance {
  return !!val &&
     typeof val === "object" &&
     typeof val.id === "string" &&
     typeof val.purchase === "object";
}

// Used for reducers/slices for data displayed and altered
// by the user such as instances to be returned, notes, etc.
export interface CustomerFlattenedStore extends Store, CountItemsPresentState {}
const customerFlattenedStore:CustomerFlattenedStore = {
  ...initialState,
  ...countItemsPresentInitialState
}
const customerFlattenedReducers = [
  countItemsPresentReducer,
  customerReducer,
]

export default reduceReducers<any>(customerFlattenedStore, ...customerFlattenedReducers);
