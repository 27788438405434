import styled from "styled-components";
import { ReturnistaPrimaryButtonProps } from "./types";
import { tabletOrLargerDevices } from "../../../globalConstants";

export const $Button = styled.button<Pick<ReturnistaPrimaryButtonProps, "disabled" | "variant">>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: ${({ disabled }) => (disabled ? "var(--border-grey)" : "var(--text-black)")};
  border: none;
  border-radius: 8px;
  color: ${({ disabled }) => (disabled ? "var(--medium-emphasis-black)" : "var(--primary-white)")};
  font: normal 500 14px "sofia-pro";
  font-smooth: never;
  height: 48px;
  padding: 16px;
  width: 100%;

  ${({ variant }) => {
    if (variant === "outlined") {
      return `
        background: var(--primary-white);
        border: solid 1px var(--text-black);
        color: var(--text-black);
      `;
    }
  }}

  ${({ variant }) => {
    if (variant === "link") {
      return `
        background: none;
        border: none;
        color: var(--text-black);
        text-decoration: underline;
      `;
    }
  }}

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    font: normal 500 16px "sofia-pro";
    height: 56px;
  }
`;
