import styled from "styled-components";

// language=SCSS prefix=*{ suffix=}
const $SVGButton = styled.button`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: 0;
  border: 0;
  &:disabled {
    opacity: 20%;
    cursor: unset;
  }
`;

export default $SVGButton;
