import React from "react";
import $SearchBar from "./styles";
import { SVG } from "../../components/svg";
import { MutableRefObject } from "react";
import { handleEnterKeyPressOnClickHandlers } from "../../utility";
import getTranslator from "../../utility/getTranslator";
import { DataCyStrings } from "../../types/DataCyStrings";
import useSelector from "../../utility/useTypedSelector";
const useTranslation = getTranslator("SearchField")

interface SearchBarProps {
  value: string,
  onChange: (val: string) => void,
  onSubmit: () => void,
  onClear: () => void,
  placeholder?: string,
  inputRef?: MutableRefObject<any>,
  inputDataCyString?: DataCyStrings
}



const SearchBar = ({ value, onChange, onSubmit, onClear, placeholder, inputRef, inputDataCyString }: SearchBarProps) => {
  const { t } = useTranslation();
  return (
    <$SearchBar>
      <div aria-label={t('search')} className="search-icon">
        <SVG name="search" />
      </div>
      <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
        <input 
          aria-label={t('searchInput')} 
          alt={placeholder} ref={inputRef} 
          placeholder={placeholder} 
          value={value} 
          onChange={(e) => onChange(e.target.value)} 
          data-cy={inputDataCyString}
        />
      </form>
      <div aria-label={t('clear')} className="clear-search-icon" {...handleEnterKeyPressOnClickHandlers(onClear)} data-cy={DataCyStrings.clearSearchIcon}>
        <SVG name="clearSearch" />
      </div>
    </$SearchBar>
  )
}

export default SearchBar;