import React from "react";

const WideLogo = (props) => (
  <>
    <svg {...props} width="174" height="24" viewBox="0 0 174 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.362 24C4.60793 24 0.404723 20.2772 0.342253 20.2201C-0.0854375 19.8328 -0.116451 19.1727 0.272694 18.7471C0.661249 18.3217 1.32198 18.2902 1.74953 18.6761C2.07975 18.9736 9.94372 25.8765 18.5284 18.652C18.9698 18.2802 19.6303 18.3365 20.003 18.7759C20.3759 19.2163 20.3204 19.8743 19.8785 20.2458C16.5338 23.0605 13.2523 24 10.362 24Z" fill="#221F1F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.42969 -0.000244141H3.56533V5.83783C4.13362 4.86143 5.19133 4.33438 6.54322 4.33438C7.85597 4.33438 8.77663 4.803 9.28599 5.56451C9.73657 6.24773 9.87392 7.00924 9.87392 8.2588V13.6674H7.73827V8.96189C7.73827 7.39971 7.28769 6.20887 5.75946 6.20887C4.27052 6.20887 3.56533 7.39971 3.56533 8.96189V13.6674H1.42969V-0.000244141Z" fill="#221F1F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.2122 8.27635C18.3177 8.27635 19.2136 7.38326 19.2136 6.28146C19.2136 5.18011 18.3177 4.28687 17.2122 4.28687C16.1068 4.28687 15.2109 5.18011 15.2109 6.28146C15.2109 7.38326 16.1068 8.27635 17.2122 8.27635Z" fill="#221F1F"/>
      <path d="M41.3022 12.5503H35.1858V18.8148H32.752V4H35.1858V10.3704H41.3022V4H43.7361V18.8148H41.3022V12.5503Z" fill="#0B0B0B"/>
      <path d="M56.4988 8.95238V18.8148H54.1919V17.4815C53.8815 17.9753 53.4371 18.3633 52.8586 18.6455C52.2942 18.9277 51.7228 19.0688 51.1443 19.0688C50.3965 19.0688 49.7122 18.9347 49.0914 18.6667C48.4706 18.3845 47.9344 18.0035 47.4829 17.5238C47.0455 17.0441 46.6999 16.4938 46.4459 15.873C46.206 15.2522 46.0861 14.5891 46.0861 13.8836C46.0861 13.1922 46.206 12.5362 46.4459 11.9153C46.6999 11.2804 47.0455 10.7231 47.4829 10.2434C47.9344 9.76367 48.4706 9.38977 49.0914 9.12169C49.7122 8.83951 50.3965 8.69841 51.1443 8.69841C51.7228 8.69841 52.2942 8.83951 52.8586 9.12169C53.4371 9.40388 53.8815 9.79189 54.1919 10.2857V8.95238H56.4988ZM51.2713 17.0159C51.7087 17.0159 52.1038 16.9312 52.4565 16.7619C52.8233 16.5926 53.1337 16.3668 53.3877 16.0847C53.6417 15.8025 53.8392 15.4709 53.9803 15.0899C54.1214 14.709 54.1919 14.3069 54.1919 13.8836C54.1919 13.4603 54.1214 13.0582 53.9803 12.6772C53.8392 12.2963 53.6417 11.9647 53.3877 11.6825C53.1337 11.4004 52.8233 11.1746 52.4565 11.0053C52.1038 10.836 51.7087 10.7513 51.2713 10.7513C50.8339 10.7513 50.4388 10.836 50.0861 11.0053C49.7334 11.1746 49.43 11.4004 49.1761 11.6825C48.9221 11.9647 48.7246 12.2963 48.5835 12.6772C48.4565 13.0582 48.393 13.4603 48.393 13.8836C48.393 14.3069 48.4565 14.709 48.5835 15.0899C48.7246 15.4709 48.9221 15.8025 49.1761 16.0847C49.43 16.3668 49.7334 16.5926 50.0861 16.7619C50.4388 16.9312 50.8339 17.0159 51.2713 17.0159Z" fill="#0B0B0B"/>
      <path d="M61.8859 10.2857C62.1963 9.79189 62.6337 9.40388 63.1981 9.12169C63.7765 8.83951 64.355 8.69841 64.9335 8.69841C65.6813 8.69841 66.3656 8.83951 66.9864 9.12169C67.6072 9.38977 68.1363 9.76367 68.5737 10.2434C69.0252 10.7231 69.3709 11.2804 69.6107 11.9153C69.8647 12.5362 69.9917 13.1922 69.9917 13.8836C69.9917 14.5891 69.8647 15.2522 69.6107 15.873C69.3709 16.4938 69.0252 17.0441 68.5737 17.5238C68.1363 18.0035 67.6072 18.3845 66.9864 18.6667C66.3656 18.9347 65.6813 19.0688 64.9335 19.0688C64.355 19.0688 63.7765 18.9277 63.1981 18.6455C62.6337 18.3633 62.1963 17.9753 61.8859 17.4815V23.7672H59.579V8.95238H61.8859V10.2857ZM64.8065 10.7513C64.355 10.7513 63.9458 10.836 63.579 11.0053C63.2263 11.1746 62.9229 11.4004 62.669 11.6825C62.415 11.9647 62.2175 12.2963 62.0764 12.6772C61.9494 13.0582 61.8859 13.4603 61.8859 13.8836C61.8859 14.3069 61.9494 14.709 62.0764 15.0899C62.2175 15.4709 62.415 15.8025 62.669 16.0847C62.9229 16.3668 63.2263 16.5926 63.579 16.7619C63.9458 16.9312 64.355 17.0159 64.8065 17.0159C65.2439 17.0159 65.639 16.9312 65.9917 16.7619C66.3444 16.5926 66.6478 16.3668 66.9018 16.0847C67.1557 15.8025 67.3462 15.4709 67.4732 15.0899C67.6143 14.709 67.6848 14.3069 67.6848 13.8836C67.6848 13.4603 67.6143 13.0582 67.4732 12.6772C67.3462 12.2963 67.1557 11.9647 66.9018 11.6825C66.6478 11.4004 66.3444 11.1746 65.9917 11.0053C65.639 10.836 65.2439 10.7513 64.8065 10.7513Z" fill="#0B0B0B"/>
      <path d="M74.7 10.2857C75.0104 9.79189 75.4478 9.40388 76.0122 9.12169C76.5907 8.83951 77.1692 8.69841 77.7477 8.69841C78.4954 8.69841 79.1798 8.83951 79.8006 9.12169C80.4214 9.38977 80.9505 9.76367 81.3879 10.2434C81.8394 10.7231 82.185 11.2804 82.4249 11.9153C82.6789 12.5362 82.8059 13.1922 82.8059 13.8836C82.8059 14.5891 82.6789 15.2522 82.4249 15.873C82.185 16.4938 81.8394 17.0441 81.3879 17.5238C80.9505 18.0035 80.4214 18.3845 79.8006 18.6667C79.1798 18.9347 78.4954 19.0688 77.7477 19.0688C77.1692 19.0688 76.5907 18.9277 76.0122 18.6455C75.4478 18.3633 75.0104 17.9753 74.7 17.4815V23.7672H72.3932V8.95238H74.7V10.2857ZM77.6207 10.7513C77.1692 10.7513 76.76 10.836 76.3932 11.0053C76.0404 11.1746 75.7371 11.4004 75.4831 11.6825C75.2291 11.9647 75.0316 12.2963 74.8905 12.6772C74.7635 13.0582 74.7 13.4603 74.7 13.8836C74.7 14.3069 74.7635 14.709 74.8905 15.0899C75.0316 15.4709 75.2291 15.8025 75.4831 16.0847C75.7371 16.3668 76.0404 16.5926 76.3932 16.7619C76.76 16.9312 77.1692 17.0159 77.6207 17.0159C78.0581 17.0159 78.4531 16.9312 78.8059 16.7619C79.1586 16.5926 79.4619 16.3668 79.7159 16.0847C79.9699 15.8025 80.1604 15.4709 80.2873 15.0899C80.4284 14.709 80.499 14.3069 80.499 13.8836C80.499 13.4603 80.4284 13.0582 80.2873 12.6772C80.1604 12.2963 79.9699 11.9647 79.7159 11.6825C79.4619 11.4004 79.1586 11.1746 78.8059 11.0053C78.4531 10.836 78.0581 10.7513 77.6207 10.7513Z" fill="#0B0B0B"/>
      <path d="M89.6518 23.9577C88.9886 23.9577 88.3749 23.873 87.8105 23.7037C87.2602 23.5344 86.7805 23.2804 86.3713 22.9418C85.9622 22.6032 85.6376 22.1799 85.3978 21.672C85.1579 21.164 85.0309 20.5714 85.0168 19.8942H87.3449C87.4013 20.6138 87.6482 21.1429 88.0856 21.4815C88.5371 21.8342 89.0662 22.0106 89.6729 22.0106C90.0257 22.0106 90.3502 21.9753 90.6465 21.9048C90.9428 21.8342 91.1967 21.7072 91.4084 21.5238C91.62 21.3404 91.7823 21.1005 91.8951 20.8042C92.0221 20.5079 92.0856 20.127 92.0856 19.6614V17.3968C91.7752 17.933 91.3449 18.3422 90.7946 18.6243C90.2443 18.8924 89.6024 19.0265 88.8687 19.0265C88.1491 19.0265 87.5424 18.9065 87.0486 18.6667C86.5689 18.4268 86.1879 18.1023 85.9057 17.6931C85.6659 17.3263 85.4966 16.9171 85.3978 16.4656C85.3131 16.0141 85.2708 15.4497 85.2708 14.7725V8.91005H87.5777V14.0106C87.5777 14.4339 87.613 14.8289 87.6835 15.1958C87.754 15.5626 87.8669 15.8801 88.0221 16.1481C88.1914 16.4021 88.4101 16.6067 88.6782 16.7619C88.9604 16.9171 89.3061 16.9947 89.7152 16.9947C90.5195 16.9947 91.1121 16.7125 91.493 16.1481C91.8881 15.5697 92.0856 14.8571 92.0856 14.0106V8.91005H94.3925V19.6614C94.3925 21.1005 93.9833 22.187 93.165 22.9206C92.3608 23.6402 91.1897 24 89.6518 24V23.9577Z" fill="#0B0B0B"/>
      <path d="M102.734 4H108.046C108.836 4 109.549 4.14109 110.184 4.42328C110.833 4.69136 111.383 5.06526 111.834 5.54497C112.3 6.02469 112.653 6.58201 112.893 7.21693C113.146 7.83774 113.273 8.49383 113.273 9.18519C113.273 9.72134 113.203 10.2363 113.062 10.7302C112.921 11.2099 112.709 11.6614 112.427 12.0847C112.159 12.4938 111.827 12.8677 111.432 13.2063C111.051 13.5309 110.621 13.7919 110.141 13.9894L113.083 18.8148H110.31L107.601 14.3704H105.168V18.8148H102.734V4ZM108.046 12.1905C108.469 12.1905 108.85 12.1129 109.189 11.9577C109.542 11.7884 109.838 11.5697 110.078 11.3016C110.318 11.0194 110.501 10.7019 110.628 10.3492C110.769 9.98236 110.84 9.59436 110.84 9.18519C110.84 8.77601 110.769 8.39506 110.628 8.04233C110.501 7.67549 110.318 7.35803 110.078 7.08995C109.838 6.80776 109.542 6.58907 109.189 6.43386C108.85 6.26455 108.469 6.1799 108.046 6.1799H105.168V12.1905H108.046Z" fill="#0B0B0B"/>
      <path d="M119.199 10.4339C118.479 10.4339 117.88 10.6384 117.4 11.0476C116.92 11.4568 116.681 12.0635 116.681 12.8677H121.591C121.591 12.1058 121.365 11.5132 120.913 11.0899C120.476 10.6526 119.905 10.4339 119.199 10.4339ZM116.681 14.582C116.709 14.9347 116.772 15.2663 116.871 15.5767C116.97 15.8871 117.118 16.1623 117.315 16.4021C117.527 16.6279 117.795 16.8113 118.12 16.9524C118.444 17.0794 118.846 17.1429 119.326 17.1429C119.989 17.1429 120.469 17.0159 120.765 16.7619C121.061 16.5079 121.28 16.2046 121.421 15.8519H123.749C123.679 16.2751 123.524 16.6843 123.284 17.0794C123.058 17.4603 122.755 17.7989 122.374 18.0952C121.993 18.3915 121.548 18.6314 121.04 18.8148C120.532 18.9841 119.961 19.0688 119.326 19.0688C118.494 19.0688 117.767 18.9347 117.146 18.6667C116.539 18.3986 116.031 18.0317 115.622 17.5661C115.213 17.1005 114.903 16.5503 114.691 15.9153C114.494 15.2804 114.395 14.6032 114.395 13.8836C114.395 13.1499 114.501 12.4656 114.712 11.8307C114.938 11.1958 115.255 10.6455 115.665 10.1799C116.074 9.71429 116.582 9.3545 117.188 9.10053C117.795 8.83245 118.487 8.69841 119.263 8.69841C119.897 8.69841 120.49 8.81129 121.04 9.03704C121.605 9.26279 122.091 9.58025 122.501 9.98942C122.924 10.3845 123.248 10.8501 123.474 11.3862C123.714 11.9224 123.834 12.5079 123.834 13.1429C123.834 13.3122 123.827 13.5168 123.813 13.7566C123.813 13.9824 123.785 14.2575 123.728 14.582H116.681Z" fill="#0B0B0B"/>
      <path d="M131.797 18.8148C131.614 18.843 131.367 18.8713 131.056 18.8995C130.746 18.9277 130.421 18.9418 130.083 18.9418C129.8 18.9418 129.476 18.9136 129.109 18.8571C128.742 18.8148 128.39 18.6808 128.051 18.455C127.726 18.2293 127.451 17.8836 127.226 17.418C127 16.9524 126.887 16.3034 126.887 15.4709V10.8783H125.215V8.95238H126.887V5.96825H129.194V8.95238H131.458V10.8783H129.194V14.9418C129.194 15.3651 129.222 15.7037 129.278 15.9577C129.349 16.2116 129.448 16.4092 129.575 16.5503C129.702 16.6772 129.85 16.7619 130.019 16.8042C130.203 16.8466 130.407 16.8677 130.633 16.8677C130.816 16.8677 131.028 16.8607 131.268 16.8466C131.508 16.8325 131.684 16.8113 131.797 16.7831V18.8148Z" fill="#0B0B0B"/>
      <path d="M142.709 8.95238V18.8148H140.402V17.4392C140.092 17.9753 139.661 18.3845 139.111 18.6667C138.561 18.9347 137.919 19.0688 137.185 19.0688C136.465 19.0688 135.859 18.9489 135.365 18.709C134.885 18.4691 134.504 18.1446 134.222 17.7355C133.982 17.3686 133.813 16.9594 133.714 16.5079C133.629 16.0564 133.587 15.4921 133.587 14.8148V8.95238H135.894V14.0529C135.894 14.4762 135.929 14.8713 136 15.2381C136.07 15.6049 136.183 15.9224 136.338 16.1905C136.508 16.4444 136.726 16.649 136.995 16.8042C137.277 16.9594 137.622 17.037 138.032 17.037C138.836 17.037 139.428 16.7549 139.809 16.1905C140.204 15.612 140.402 14.8995 140.402 14.0529V8.95238H142.709Z" fill="#0B0B0B"/>
      <path d="M152.07 11.0899C151.887 11.0476 151.732 11.0194 151.605 11.0053C151.492 10.9912 151.344 10.9841 151.16 10.9841C150.173 10.9841 149.418 11.2734 148.896 11.8519C148.374 12.4162 148.113 13.3192 148.113 14.5608V18.8148H145.806V8.95238H148.113V10.5397C148.226 10.3139 148.381 10.1023 148.578 9.90476C148.79 9.69312 149.03 9.5097 149.298 9.3545C149.58 9.18519 149.876 9.0582 150.187 8.97355C150.511 8.87478 150.836 8.8254 151.16 8.8254C151.527 8.8254 151.831 8.87478 152.07 8.97355V11.0899Z" fill="#0B0B0B"/>
      <path d="M153.804 8.95238H156.111V10.328C156.422 9.79189 156.852 9.38977 157.402 9.12169C157.953 8.83951 158.594 8.69841 159.328 8.69841C160.048 8.69841 160.647 8.81834 161.127 9.0582C161.621 9.29806 162.009 9.62258 162.291 10.0317C162.531 10.3986 162.693 10.8078 162.778 11.2593C162.877 11.7108 162.926 12.2751 162.926 12.9524V18.8148H160.619V13.7143C160.619 13.291 160.584 12.8959 160.513 12.5291C160.443 12.1623 160.323 11.8519 160.154 11.5979C159.998 11.3298 159.78 11.1182 159.497 10.963C159.229 10.8078 158.891 10.7302 158.482 10.7302C157.677 10.7302 157.078 11.0194 156.683 11.5979C156.302 12.1623 156.111 12.8677 156.111 13.7143V18.8148H153.804V8.95238Z" fill="#0B0B0B"/>
      <path d="M170.341 13.2487C170.679 13.3757 171.011 13.5097 171.335 13.6508C171.66 13.7919 171.949 13.9683 172.203 14.1799C172.457 14.3915 172.655 14.6455 172.796 14.9418C172.951 15.2381 173.028 15.612 173.028 16.0635C173.028 16.5714 172.923 17.0159 172.711 17.3968C172.513 17.7778 172.231 18.0952 171.864 18.3492C171.512 18.5891 171.095 18.7654 170.616 18.8783C170.136 19.0053 169.628 19.0688 169.092 19.0688C168.556 19.0688 168.048 18.9912 167.568 18.836C167.088 18.6808 166.658 18.455 166.277 18.1587C165.91 17.8483 165.614 17.4744 165.388 17.037C165.177 16.5855 165.071 16.0705 165.071 15.4921H167.314C167.314 16.1411 167.49 16.5996 167.843 16.8677C168.21 17.1217 168.669 17.2487 169.219 17.2487C169.67 17.2487 170.051 17.164 170.362 16.9947C170.672 16.8254 170.827 16.5291 170.827 16.1058C170.827 15.8801 170.764 15.6966 170.637 15.5556C170.51 15.4145 170.319 15.2875 170.065 15.1746C169.811 15.0476 169.494 14.9206 169.113 14.7937C168.732 14.6667 168.281 14.5044 167.759 14.3069C167.42 14.1799 167.102 14.0459 166.806 13.9048C166.524 13.7496 166.277 13.5732 166.065 13.3757C165.854 13.1781 165.692 12.9312 165.579 12.6349C165.466 12.3386 165.409 11.9788 165.409 11.5556C165.409 11.0899 165.508 10.6808 165.706 10.328C165.903 9.97531 166.171 9.67901 166.51 9.43915C166.849 9.18519 167.237 9.00176 167.674 8.88889C168.125 8.76191 168.605 8.69841 169.113 8.69841C169.635 8.69841 170.122 8.78307 170.573 8.95238C171.039 9.12169 171.441 9.3545 171.78 9.65079C172.118 9.94709 172.386 10.2998 172.584 10.709C172.781 11.1182 172.88 11.5626 172.88 12.0423H170.637C170.637 11.5767 170.51 11.2099 170.256 10.9418C170.016 10.6596 169.614 10.5185 169.05 10.5185C168.655 10.5185 168.316 10.6102 168.034 10.7937C167.766 10.9771 167.632 11.2452 167.632 11.5979C167.632 11.8095 167.688 11.9859 167.801 12.127C167.914 12.254 168.083 12.3739 168.309 12.4868C168.535 12.5855 168.817 12.6984 169.155 12.8254C169.494 12.9383 169.889 13.0794 170.341 13.2487Z" fill="#0B0B0B"/>
    </svg>
  </>
)

export default WideLogo;