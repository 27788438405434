import styled from "styled-components";

// language=SCSS prefix=*{ suffix=}
export const $ItemDetails  = styled.div<{ customStyles? }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 16px 0;

  @media screen and (max-width: 768px){
    &.ios {
      .thumbnail {
        width: 64px;
        height: 64px;
        img {
          width: 64px;
          height: 64px;
        }
      }
      padding-bottom: 24px;
      margin-bottom: 0;
    }
  }

  .thumbnail {
    width: 56px;
    height: 56px;
    margin-right: 16px;
    flex-shrink: 0;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    img {
      width: 56px;
      height: 56px;
      object-fit: contain;
      position: absolute;
      transform: translate(-1px, -1px);
      /* IE11 polyfill */
      font-family: 'object-fit: contain;';
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    color: #737373;
    width: 100%;


    .item-name-price {
      display: flex;
      .item-price {
        font-size: 16px;
        color: var(--text-black)
      }
    }

    div {
      font-size: 14px;
      line-height: 17px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.item-name {
        margin-top: 0;
        font-size: 15px;
        line-height: 20px;
        color: #221F1F;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .item-details, .inline-price {
      font-weight: 400;
      font-size: 14px;
      padding-left: 0;
    }

    .item-details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;

      li {
        display: inline-block;
        white-space: break-spaces;

        &:not(:last-child)::after {
          content: '•';
          margin-left: 4px;
          margin-right: 4px;
        }
      }
    }
  }

  .item-price {
    margin-left: auto;
    padding-left: 8px;
    flex-shrink: 0;
    font-size: 16px;
    line-height: 16px;
  }

  .android & {
    padding-bottom: 0px;
    margin: 6px 0;
    align-items: center;
    .item-info {
      .item-name {
        font-size: 14px;
        white-space: break-spaces;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-details, .item-sku {
        font-size: 12px;
        font-weight: 200;
        line-height: 16px;
        white-space: break-spaces;
      }
    }
  }

  ${({ customStyles }) => customStyles}
`;
