import styled from "styled-components";

const $Email = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--border-grey);
  .caption {
    font-size: 14px;
    line-height: 22px;
    color: var(--caption-grey);
    .toggle-email {
      color: black;
      display: inline;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .subtext {
    font-weight: 500;
    font-size: 14px;
    color: var(--header-grey);
    margin-bottom: 24px;
  }
  .input-container {
    position: relative;
    button {
      width: 86px;
      position: absolute;
      height: 40px;
      top: 35px;
      right: 8px;
    }
    input {
      height: 56px;
      -webkit-appearance: none;
    }
    margin-bottom: 8px;
  }
  .email-submit-button-child {
    font-size: 15px;
    line-height: 15px;
  }
  .android & {
    margin-top: 20px;
    padding-top: 20px;
    overflow-wrap: anywhere;
  }
`;

export default $Email;