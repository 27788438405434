import { eventLabels } from "../redux/returnistaReducers/events";

interface returnistaEvent {
  returnID: string;
  eventTypeLabel: eventLabels;
  eventData?: any;
}

export const setReturnistaEvent = ({ returnID, eventTypeLabel, eventData }: returnistaEvent) => {
  return {
    returnID,
    eventTypeLabel,
    eventData,
    eventTimestamp: new Date().toISOString(),
  };
};
