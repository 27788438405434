import React, { ReactNode } from "react";
import Button from "../";
import { DataCyStrings } from "../../../types/DataCyStrings";

// a button used for secondary actions on a page or other component
type SecondaryButtonTypes = {
  // text that appears on button
  label?: string,

  // provide this to override default width
  width?: string,

  // provide this to override default height
  height?: string,

  // callback, triggered when button pressed
  onButtonClick?: Function,

  // if set to true, button will not be clickable
  disabled?: boolean,

  children?: ReactNode,

  dataCyString?: DataCyStrings
};
const SecondaryButton = ({
  label,
  width,
  height,
  onButtonClick,
  disabled,
  children,
  dataCyString=DataCyStrings.secondaryButton
}: SecondaryButtonTypes) => {
  const bgColor = !disabled ? "#FFFFFF" : "#F1F1F1"
  const textColor = !disabled ? "#221F1F" : "#616161"
  const borderRadius = !disabled ? "8px" : ""
  const border = !disabled ? "1px solid #DBDBDB" : ""
  return (
    <Button
      onButtonClick={() => {
        onButtonClick?.()
      }}
      text={label}
      width={width || "408px"}
      height={height || "44px"}
      bgColor={bgColor}
      hoverFontColor="#221F1F"
      fontColor={textColor}
      borderColor="#A2A2A2"
      hoverBgColor="#F9F9F9"
      hoverBorderColor="#DBDBDB"
      activeBorder="#DBDBDB"
      fontSize="16px"
      disabled={disabled}
      borderRadius={borderRadius}
      dataCyString={dataCyString}
      border={border}
    >{children}</Button>
)};

export default SecondaryButton;
