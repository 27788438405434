import React from "react";

const EnvelopeCheck = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask
      id="mask0_4163_9450"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4163_9450)">
      <path
        d="M10.633 14.666L7.79967 11.8327L8.73301 10.8993L10.633 12.7993L14.3997 9.03268L15.333 9.96602L10.633 14.666ZM7.99967 7.33268L13.333 3.99935H2.66634L7.99967 7.33268ZM7.99967 8.66602L2.66634 5.33268V11.9993H6.09967L7.43301 13.3327H2.66634C2.29967 13.3327 1.98579 13.2021 1.72467 12.941C1.46356 12.6799 1.33301 12.366 1.33301 11.9993V3.99935C1.33301 3.63268 1.46356 3.31879 1.72467 3.05768C1.98579 2.79657 2.29967 2.66602 2.66634 2.66602H13.333C13.6997 2.66602 14.0136 2.79657 14.2747 3.05768C14.5358 3.31879 14.6663 3.63268 14.6663 3.99935V6.89935L13.333 8.23268V5.33268L7.99967 8.66602Z"
        fill="#616161"
      />
    </g>
  </svg>
);

export default EnvelopeCheck;
