import React, { FormEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  currentBagAction,
  CurrentPageAction,
  FatalErrorAction,
  LoadingAction,
  ModalAction,
  RuntimeType,
  ScannerType,
  TerminalReason,
} from "../../redux/enums";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import { SVG } from "../../components/svg";

import { $ManualCodeLinkWrapper, $ManualInput, $Input, $RecordingMessageWrapper, $BottomButton } from "./styles";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import {
  errorTextOrModal,
  isConfirmationCodeLike,
  openScanner,
  getReturnistaJWTClaims,
  headerTextHandler,
  isUPS,
  mapURLQueryStringToObject,
} from "../../utility";
import ga from "../../utility/GAEmitter";
import ReturnistaAnalytics, { ReturnistaAnalyticsErrors } from "../../utility/ReturnistaAnalytics";
import ReturnistaAlertMessage from "../../components/ReturnistaAlertMessage";
import Content from "../../components/ReturnistaContent";
import { DataCyStrings } from "../../types/DataCyStrings";
import { confirmOutOfBags } from "../RequestSupportModals";
import { BagScanActions } from "../../types/ReturnistaGA";

const BAG_ALREADY_SCANNED = "BAG_ALREADY_SCANNED";
const INVALID_CODE_SCAN = "INVALID_CODE_SCAN";

// GA consts
const scan = "bag code scan";
const entry = "bag code entry";

const ReturnistaBagCodeScan = () => {
  const [isModalError, setIsModalError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { runtime, returnObject, bags, loading, userToken, currentPage } =
    useSelector(
    (store: RootStateOrAny) => store
  );
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const claims = getReturnistaJWTClaims(userToken ?? "");

  const { authorization } = mapURLQueryStringToObject();
  const { returnsApp } = getReturnistaJWTClaims(authorization);
  const returnistaLocationOwnerName = returnsApp?.locationOwner || claims?.returnsApp?.locationOwner || "";
  const returnistaLocationID = returnsApp?.locationID || claims?.returnsApp?.locationID || "";

  useEffect(() => {
    // Handle code event emitted from app wrappers
    window.enterCode = (code: string) => {
      postBarCode(code, true);
    };
    // Open the scanning screen for this page in thin client
    openScanner(ScannerType.BagCode);
    return () => {
      // Reset handler to report error if called after unmount
      window.enterCode = () => {
        ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedEnterCode);
      };
    };
  }, []);

  const postBarCode = async (barCode: string, usingScanner = false): Promise<void> => {
    const bagAlreadyScannedThisReturnError = [
      usingScanner ? "Bag already scanned" : "This bag has already been scanned and is associated with this return.",
      usingScanner ? "This bag has already been scanned and is associated with this return." : "",
      usingScanner,
    ];

    const bagAlreadyScannedError = [
      usingScanner
        ? "Bag already scanned"
        : "This bag is already associated with a return. Please try to scan a different bag.",
      usingScanner ? "This bag is already associated with a return. Please try to scan a different bag." : "",
      usingScanner,
    ];
    const scannedConfirmationError = [
      usingScanner ? "Scan the bag" : "It looks like you scanned the customer's QR code. Please scan a bag.",
      usingScanner ? "It looks like you scanned the customer's QR code. Please scan a bag." : "",
      usingScanner,
    ];
    const noMatchingError = [
      usingScanner ? "Scan the bag" : "Scan the bag. It looks like you scanned an item's barcode.",
      usingScanner ? "It looks like you scanned an item's barcode. Please scan a bag." : "",
      usingScanner,
    ];

    if (bags.allIds.includes(barCode)) {
      displayError.apply(this, bagAlreadyScannedThisReturnError);
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${BagScanActions.SameReturnBagCodeAlreadyScanned}`,
      });
      return;
    }
    dispatch({ type: LoadingAction.Set });
    try {
      // Try getting an existing bag; if this bag already exists at the location we should throw an error
      const claims = getReturnistaJWTClaims(userToken);
      const locationID = claims?.returnsApp?.locationID ?? claims?.locID ?? "unknown";
      const result = await fetch(`/returns/bags?barcode=${barCode}&locationID=${locationID}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      // If the result is a 200 then the bag has already been scanned and is not viable
      // for continued use in a new return or this return
      if (result.status === 200) {
        throw new Error(BAG_ALREADY_SCANNED);
      }

      // If the result is *NOT* a 404 then we have received some other error or invalid
      // bag state and should let the `catch` block handle the result
      if (result.status !== 404) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${BagScanActions.InvalidCode}`,
        });

        throw new Error(INVALID_CODE_SCAN);
      }

      // Bag is unique within the location, proceed
      ReturnistaAnalytics.codeEntered(usingScanner, "bag");
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${BagScanActions.ScanSuccessful}`,
      });
      dispatch({ type: LoadingAction.Unset });
      dispatch({ type: CurrentPageAction.Next });
      dispatch({ type: currentBagAction.Set, bag: barCode });
    } catch (error) {
      dispatch({ type: LoadingAction.Unset });
      if (isConfirmationCodeLike(barCode)) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${BagScanActions.ConfirmationCodeScanned}`,
        });

        displayError.apply(this, scannedConfirmationError);
        return;
      }
      if (error?.message?.includes(BAG_ALREADY_SCANNED)) {
        ga.pageEvent({
          category: currentPage,
          action: `${usingScanner ? scan : entry} ${BagScanActions.BagCodeAlreadyScanned}`,
        });

        displayError.apply(this, bagAlreadyScannedError);
        return;
      }
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${BagScanActions.ScanFailed}`,
      });
      displayError.apply(this, noMatchingError);
      throw new Error(error);
    }
  };

  const removeSpecialCharacters = (val: string): string => val.replace(/[^a-zA-Z0-9 ]/g, "");

  const handleChange = (e: FormEvent<HTMLInputElement>): void => {
    setError("");
    setDisableBtn(false);
    if (removeSpecialCharacters(e.currentTarget.value).length === 0) {
      setDisableBtn(true);
    }

    if (removeSpecialCharacters(e.currentTarget.value).length === 12) {
      postBarCode(removeSpecialCharacters(e.currentTarget.value).toUpperCase());
      return;
    }

    inputRef.current!.value = removeSpecialCharacters(e.currentTarget.value.toUpperCase());
  };

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
    if (returnObject.confirmationCode === inputRef.current!.value) {
      ga.pageEvent({
        category: currentPage,
        action: BagScanActions.ConfirmationCodeScanned,
      });

      setError("It looks like you scanned the customer's QR code. Please enter a bag's QR code to continue.");
      return;
    }
    postBarCode(inputRef.current!.value);
  };

  const displayError = (primaryMessage: string, subMessage = "", useModal = false) => {
    errorTextOrModal({
      primaryMessage,
      subMessage,
      useModal,
      scannerType: ScannerType.BagCode,
      dispatch,
      errorSetter: setError,
      usingModalSetter: setIsModalError,
    });
  };

  const headerMessages = ["Scan the Happy Returns bag code", "Enter the Happy Returns bag code"];

  const handleButtonClick = () => {
    if (runtime === RuntimeType.Returnista) {
      dispatch({ type: CurrentPageAction.Back });
    }
    if (runtime === RuntimeType.Partner) {
      const buttonClick = () => {
        dispatch({ type: ModalAction.Unset });
        dispatch({
          type: FatalErrorAction.Set,
          reason: TerminalReason.ReturnCancelled,
        });
        dispatch({
          type: CurrentPageAction.FatalError,
        });
        return;
      };
      dispatch({
        type: ModalAction.Set,
        modalProps: {
          primaryMessage: "Incomplete Return",
          subMessages: ["Are you sure you want to end the current return?"],
          onRequestClose: () => dispatch({ type: ModalAction.Unset }),
          button: (
            <ReturnistaPrimaryButton onClick={() => buttonClick()} dataCyString="end-return-button">
              End Return
            </ReturnistaPrimaryButton>
          ),
        },
      });
    }
  };

  let leftButton = <HeaderBackButton />;
  if (runtime === RuntimeType.Partner && bags.allIds.length === 0) {
    leftButton = (
      <span style={{ cursor: "pointer" }} onClick={handleButtonClick}>
        Cancel
      </span>
    );
  }

  useEffect(() => {
    if (error.length > 0) {
      setDisableBtn(true);
    }
  }, [error]);

  return (
    <>
      <Header left={leftButton} center={headerTextHandler({ usingScanner: isModalError, headerMessages })} />
      <Content>
        <$ManualCodeLinkWrapper>
          {runtime === RuntimeType.Partner && (
            <$RecordingMessageWrapper>
              <ReturnistaAlertMessage
                message="Your session might be recorded for monitoring and training purposes."
                dataCyString="recording-message"
              />
            </$RecordingMessageWrapper>
          )}
          <$ManualInput>
            <div className="icon">
              <SVG fill="#221F1F" name="returnistaQrScan" />
            </div>
            <p data-cy="bag-scan-title" className="title">
              Enter the Happy Returns bag QR code <br />
              <span data-cy="bag-scan-subtitle">A 12 character alphanumeric value starting with "HR"</span>
            </p>
            <form onSubmit={handleSubmit} action="">
              <$Input
                data-cy="bag-code-input"
                title="bag manual input"
                autoFocus
                hasError={error.length > 0}
                onChange={handleChange}
                ref={inputRef}
                type="text"
                disabled={loading.isLoading}
              />
              {error && (
                <p className="error" data-cy="bag-input-error">
                  {error}
                </p>
              )}
              <ReturnistaPrimaryButton
                disabled={disableBtn || loading.isLoading}
                className="submitQrCodeButton"
                dataCyString="submitBagCodeButton"
                type="submit"
              >
                Continue
              </ReturnistaPrimaryButton>
            </form>
          </$ManualInput>
        </$ManualCodeLinkWrapper>
        <$BottomButton>
          {!isUPS() && (
            <ReturnistaPrimaryButton dataCyString={DataCyStrings.requestOutOfBags} onClick={confirmOutOfBags(dispatch, returnistaLocationID, returnistaLocationOwnerName, currentPage)} variant="link">
              {"Out of Happy Returns bags"}
            </ReturnistaPrimaryButton>
          )}
        </$BottomButton>
      </Content>
    </>
  );
};

export default ReturnistaBagCodeScan;
