import React from 'react';

import ModalContainer from "../ModalContainer";
import PrimaryButton from "../../Button/PrimaryButton";

// simple modal with only one button and an X button in the corner,
// both of which trigger the same callback
type PendingChangesModalTypes = {
  keepChanges: () => void,
  discardChanges: () => void,
  runtime: string
};
const PendingChangesModal = ({
  discardChanges,
  keepChanges,
  runtime
}: PendingChangesModalTypes) => {

  return (
    <ModalContainer
      isOpen={true}
      onRequestClose={keepChanges}
      contentLabel="pending-changes"
      primaryMessage="You Have Unsaved Changes"
      subMessages={["Do you want to save your changes before navigating to another page?"]}
      closeIcon
      runtime={runtime}
      button={
        <PrimaryButton
          onButtonClick={discardChanges}
          label="DISCARD CHANGES"
        />
      }
    />
  )
}

export default PendingChangesModal;
