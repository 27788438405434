import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $StartReturn = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;

  &,
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-info {
    width: 300px;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-top: 144px;
    }

    svg {
      margin-bottom: 16px;

      @media (min-width: ${tabletOrLargerDevices}) {
        margin-bottom: 24px;
      }
    }
    .name {
      font-weight: 400;
      font-size: 21px;
      /* TODO - if this color is reused, set it to a var */
      color: #221f1f;
      margin-bottom: 8px;

      @media (min-width: ${tabletOrLargerDevices}) {
        font-size: 32px;
        font-weight: 500;
      }
    }
    .info {
      width: 250px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: var(--header-grey);
      margin-bottom: 32px;

      p {
        margin: 0;
      }

      @media (min-width: ${tabletOrLargerDevices}) {
        font-size: 21px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 40px;
      }
    }
  }
`;

export const $SignOutButton = styled.div`
  cursor: pointer;
  font-size: 14px;

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 16px;
  }
`;
