import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React, { useEffect } from 'react';
import { Instance } from '../../../types/Instance';
import { Carousel } from 'react-responsive-carousel';
import Modal from "react-modal";
import { getReadableTimestamp, handleEnterKeyPressOnClickHandlers } from "../../../utility";
import { $InstanceDetails } from "./styles";
import { SVG } from "../../svg";
import useSelector from "../../../utility/useTypedSelector";
import useModalStyles from "../../../utility/useModalStyles";
import getTranslator from "../../../utility/getTranslator";
import ga from "../../../utility/GAEmitter";
import { formatCurrencyString } from "../../../utility/currencyExtraction";
import { AnalyticCategories, ItemListPageActions } from "../../../types/Analytics";

const useTranslation = getTranslator('InstanceDetailsModal');
interface InstanceDetailsModalProps {
  instance: Instance,
  setInstance: (instance?: Instance) => void
  isDropship?: boolean
}

const InstanceDetailsModal = ({ instance, setInstance, isDropship }: InstanceDetailsModalProps) => {
  const { app, customer } = useSelector(store => store);
  const { runtime, locale } = app;
  const modalStyles = useModalStyles({width: "auto"});

  const {t} = useTranslation();

  const closeModal = () => {
    ga.event({
      category: AnalyticCategories.ItemListPage,
      action: ItemListPageActions.CloseItemModal
    })
    setInstance(undefined);
  }

  const instanceBrand = instance?.purchase?.display?.find(item => item?.label === "Vendor")?.value || instance?.purchase?.vendor;
  const itemSKU = instance?.purchase?.details?.sku;
  const itemUPC = instance?.purchase?.details?.upc;
  const detailsWithoutVendor = instance?.purchase?.display?.filter(item => item?.label != "Vendor");
  const purchaseDate = instance?.purchase?.when ? getReadableTimestamp(instance.purchase.when, locale) : "";
  const purchaseName = instance?.purchase?.name;
  const purchaseOrderNumber = instance?.purchase?.orderNumber?.replace(/^#+/, "");
  const purchasePrice = formatCurrencyString(instance?.purchase?.price);
  // return portal or in-store walk-up returns always show price
  const showPrice = runtime === "return-portal" || customer.returnType != "express-return";

  const thumbImageClicked = () => {
    ga.event({
      category: AnalyticCategories.ItemListPage,
      action: ItemListPageActions.CarouselNavigation
    })
  }

  const Images = instance?.purchase?.images?.map(image => {
    return (
      <div className="image-container" key={image}>
        <img src={image} alt="" />
      </div>
    )
  });

  const closeModalHandlers = handleEnterKeyPressOnClickHandlers(closeModal);
  return (
    <Modal
      isOpen={!!instance}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={modalStyles}>
      <$InstanceDetails>
        <div className="header">
          <SVG name="x" aria-label={t('closeModal')} {...closeModalHandlers}/>
        </div>
        <Carousel width="408px" dynamicHeight useKeyboardArrows autoFocus onClickThumb={thumbImageClicked}>
        {Images}
        </Carousel>
        <div className="purchase-header apart">
          <div className="order-number">
            {purchaseOrderNumber ? t('orderNumber', {orderNumber: purchaseOrderNumber}) : ""}
          </div>
          <div className="order-date">
            {purchaseDate || ""}
          </div>
        </div>
        <div className="purchase-name">
          {purchaseName || ""}
        </div>
        <div className="purchase-brand">
          {(instanceBrand && isDropship) ? t('brand', {brand: instanceBrand}) : ""}
        </div>
        <div className="apart">
          <div className="col">
            {detailsWithoutVendor.map(detail => {
              return <div>{detail.label}: {detail.value}</div>
            })}
          </div>
          <div className="col">
              {itemSKU && <div>SKU: {itemSKU}</div>}
              {!itemSKU && itemUPC && <div>UPC: {itemUPC}</div>}
              {(purchasePrice && showPrice) ? t('price', {price: purchasePrice}) : ""}
          </div>
        </div>
      </$InstanceDetails>
    </Modal>
  );
}

export default InstanceDetailsModal;
