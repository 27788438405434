import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PageProps, PartnerSuccessPageConfiguration } from "../types"
import { finalize } from "../../redux/app/actions";
import { reset } from "../../redux/customer/actions";
import { FinalSuccess } from "../../components/Final";
import { $Success } from "./styles";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { DataCyStrings } from "../../types/DataCyStrings";


const Success = ({page}: PageProps<PartnerSuccessPageConfiguration>) => {
  const dispatch = useDispatch();

  //----------------------------------------------------------------------------
  // HOOKS
  // 
  // on launch, clear store entirely
  useEffect(() => {
    dispatch(reset())
  },[]);

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <$Success>
      <div className="title" data-cy={DataCyStrings.confirmationPageTitle}>
        Place Bag(s) Into a Happy Returns Consolidation Tote
      </div>
      <img alt="" src="/public/img/tote.jpeg" />
      <div className="detail">
        Thank you for another happy return!
        <br/>
        You may close this tab.
      </div>
      {page.allowCloseTab &&
        <PrimaryButton label="Close Tab" onButtonClick={() => window.close()} />
      }
    </$Success>
  )
}

export default Success;