import { DropoffMethod, ReturnBarDropoffMethod } from "../../pages/DropoffMethods";
import { Instance, Order, ExchangeProperties, CustomerIdentity, ReturnChannel, Refund } from "../../types/Instance";
import { Retailer } from "../../types/Retailer";
import { BagToteBarcodePair } from "../../types/BagToteBarcodePair";
//-----------------------------------------------------------------------------
// customer
//
// this slice of the store stores information that is related to a particular
// customer, such as their order number, purchases, returns, etc
//-----------------------------------------------------------------------------
export interface Store {
  // a returns object (the output of our /returns endpoint), represents
  // a return that has been initiated (think of this as a single return
  // with multiple items.... this is plural for historical reasons, perhaps
  // because "return" is a reserved word)
  returns?: Returns,

  // items that are marked to be returned
  // when returnType is express - each entry is the ID of an element in returns' returning
  // when returnType is new/gift - each entry is an instance to be processed
  itemsMarkedForCompletion: Array<string | Instance>,

  // currentBrand is overridden by a marked item's Vendor field for each item marked for completion
  // Once defined, this will restrict the return of multiple brands if the retailer is a dropship
  // store. For non-dropship stores, this string will be a trivial value
  currentBrand: string,

  // currentOrder is overridden by a marked item's name field for each item marked for completion
  // Once defined, this will restrict the return of multiple order numbers.
  // This is to restrict returns to a single order.
  currentOrder?: string,

  // the list of active barcodes, the user adds and removes items on this list
  // as they interact with this page.  we try to keep this in parity with the
  // contents on the backend by calling endpoints whenever things are added/removed
  barcodes: string[],

  // the list of active bag AND tote barcodes. exclusive to the Returnista scan-to-tote workflow
  // gated behind a location owner feature flag. similar to barcodes, in that we sync this state
  // with the database as soon as bags/totes are added/removed.
  bagToteBarcodePairs: BagToteBarcodePair[],

  // one of our customer-facing HR express codes
  // if set, then the returns were loaded with that express code
  expressCode: string,

  // when a user is starting a new return, this query value is used
  // when hitting the /purchases endpoint this query can be an order
  // number, or an email for gift returns
  query: string,

  // used to keep track of the return type
  returnType: ReturnTypes,

  // used when returnType is set to new return or gift return.
  // when an order#/email is searched, this array will be populated by
  // the GET /purchases endpoint on 200
  orders: Order[],

  // during a new/gift return, when an instance is in the proccess
  // of being returned, this var is used to make changes to return reason/method
  selectedInstance?: Instance,

  selectedInstanceExchangeProperties: ExchangeProperties,

  selectedDropoffMethod: DropoffMethod | ReturnBarDropoffMethod | null,

  // used to keep track of the order#/email for a walkup return
  newReturnID?: string,

  newReturnEmail?: string,

  changeDropoffReturnID?: string,

  returnStatusReturnID?: string,

  refundOptions?: Refund[],

  purchasing?: any,

  returnSessionID?: string,

  featureStudyIDs?: Set<string>,

  retailer?: Retailer,
}

// TODO, TS types/interfaces should be moved to a sharable package similar
// to gomodels

export interface Returns {
  id: string,
  returning: Instance[],
  subtotal: string,
  total: string,
  distributions: object,
  distributionsLocalized?: Record<string, {
    key: string,
    value: string
  }>,
  adjustments: object,
  adjustmentsLocalized?: Record<string, {
    key: string,
    value: string
  }>,
  processingFeeStatus: string,
  retailer: Retailer,
  retailerID?: string,
  confirmationCode?: string,
  dropoffMethod?: DropoffMethod,
  customerIdentity?: CustomerIdentity,
  channel?: ReturnChannel,
  returnlessRefund?: boolean,
  invoiceURL: string,
  purchasing?: any
}

export interface ReturnShoppingItem {
  variantID: number,
  price: string,
  quantity: number,
  image: string,
  sku?: string,
  productID?: number,
  name?: string,
  description?: string,
  title?: string
}

export enum ReturnTypes {
  giftReturn = "gift-return",
  newReturn = "new-return",
  expressReturn = "express-return"
}

export enum DropoffMethodIDs {
  returnBar        = "return-bar",
  retailerStore    = "retailer-store",
  mail             = "mail",
  returnless      = "returnless",
  mailShopperProvided = "mail-shopper-provided",
  mailNoBoxNoLabel = "mail-nobox-nolabel"
}

export const SET_RETURNS = "SET_RETURNS";
export const MARK_ITEM_COMPLETE = "MARK_ITEM_COMPLETE";
export const UNMARK_ITEM_COMPLETE = "UNMARK_ITEM_COMPLETE";
export const SET_EXPRESS_CODE = "SET_EXPRESS_CODE";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const ADD_BARCODE = "ADD_BARCODE";
export const DELETE_BARCODE = "DELETE_BARCODE";
export const ADD_BAG_TOTE_BARCODE_PAIR = "ADD_BAG_TOTE_BARCODE_PAIR";
export const DELETE_BAG_TOTE_BARCODE_PAIR = "DELETE_BAG_TOTE_BARCODE_PAIR";
export const UPDATE_BAG_TOTE_BARCODE_PAIR = "UPDATE_BAG_TOTE_BARCODE_PAIR";
export const SET_RETURN_TYPE = "SET_RETURN_TYPE";
export const SET_CUSTOMER_ORDERS = "SET_CUSTOMER_ORDERS";
export const SET_SELECTED_INSTANCE = "SET_SELECTED_INSTANCE";
export const DESELECT_COMPLETED_INSTANCE_WITH_ID = "DESELECT_COMPLETED_INSTANCE_WITH_ID";
export const SET_QUERY = "SET_QUERY";
export const SET_RETURN_REASON_SELECTED_INSTANCE = "SET_RETURN_REASON_SELECTED_INSTANCE";
export const SET_RETURN_REASON_CHILD_SELECTED_INSTANCE = "SET_RETURN_REASON_CHILD_SELECTED_INSTANCE";
export const RESET_RETURN_REASON_CHILD_SELECTED_INSTANCE = "RESET_RETURN_REASON_CHILD_SELECTED_INSTANCE";
export const SET_RETURN_NOTES_SELECTED_INSTANCE = "SET_RETURN_NOTES_SELECTED_INSTANCE";
export const SET_SELECTED_INSTANCE_FOR_COMPLETION = "SET_SELECTED_INSTANCE_FOR_COMPLETION";
export const SET_SELECTED_INSTANCE_EXCHANGE_PROPERTIES = "SET_SELECTED_INSTANCE_EXCHANGE_PROPERTIES";
export const SET_SELECTED_INSTANCE_EXCHANGE = "SET_SELECTED_INSTANCE_EXCHANGE";
export const SET_NEW_RETURN_ID = "SET_NEW_RETURN_ID";
export const SET_GIFT_RETURN_EMAIL = "SET_GIFT_RETURN_EMAIL";
export const SET_NEW_RETURN_EMAIL = "SET_NEW_RETURN_EMAIL";
export const RESET_CUSTOMER_ORDERS = "RESET_CUSTOMER_ORDERS";
export const SET_CHANGE_DROPOFF_RETURN_ID = "SET_CHANGE_DROPOFF_RETURN_ID";
export const SET_RETURN_STATUS_RETURN_ID = "SET_RETURN_STATUS_RETURN_ID";
export const SET_SELECTED_DROPOFF_METHOD = "SET_SELECTED_DROPOFF_METHOD";
export const SET_SHIPPING_ADDRESS_COORDINATES = "SET_SHIPPING_ADDRESS_COORDINATES";
export const RESET_ITEMS_MARKED_FOR_COMPLETION = "RESET_ITEMS_MARKED_FOR_COMPLETION";
export const SET_REFUND_OPTIONS = "SET_REFUND_OPTIONS";
export const SET_SELECTED_REFUND = "SET_SELECTED_REFUND";
export const SET_CUSTOMER_STORE = "SET_CUSTOMER_STORE";
export const SET_PURCHASING = "SET_PURCHASING";
export const SET_RETURN_SESSION_ID = "SET_RETURN_SESSION_ID";
export const ADD_FEATURE_STUDY_ID = "ADD_FEATURE_STUDY_ID";
export const SET_RETAILER = "SET_RETAILER";
