import {
  Configuration,
  EventsApi,
  EventsApiInterface as EventsInterface
} from '@happyreturns/analytics-events-client';
import ReactGA from "react-ga4";
import { googleAnalyticsKeys } from ".";
import logger from "./logger/logger";
import { BaseEventProps, getPathFromCategory } from "../types/Analytics";

/**
 * This class is a wrapper around ReactGA that abstracts
 * the process of initializing and executing GA code within
 * the context of our application architecture to simplify the
 * context needed by new engineers to contribute to GA
 */
export class GAEmitter {
  private analyticsClient: EventsInterface
  private analyticsToken: string
  private analyticsHost: string;

  #runtime: string;
  /**
   * Initializes the Google Analytics 4
   */
  initialize(runtime: string) {
    const gaKey = googleAnalyticsKeys[runtime];
    if (gaKey) {
      if (this.getCountry() === 'US') {
        this.#runtime = runtime;
        const options = {
          gtagOptions: {
            send_page_view: false,
          },
        };
        ReactGA.initialize(gaKey, options);
      }
    }
  }

  setAnalyticsHost(host: string) {
    this.analyticsHost = host;
  }

  setAnalyticsToken(token: string) {
    this.analyticsToken = token;
    if (token && this.analyticsHost) {
      const config = new Configuration({
        basePath: this.analyticsHost
      });
      this.analyticsClient = new EventsApi(config);
    }
  }

  event({ category, action, label, dimensions }: BaseEventProps) {
    if (dimensions) {
      ReactGA.set(dimensions);
    } else {
      //Manually set the page title otherwise, page title comes from the <title> tag which we don't want
      ReactGA.set({
        page_title: category,
        page_path: getPathFromCategory(category),
      });
    }
    ReactGA.event({ category, action, label });

    if (this.analyticsClient) {
      this.analyticsClient.logEvent({
        eventRequest: {
          tokenId: this.analyticsToken,
          eventType: action,
          payload: {
            category: category,
            label: label,
            dimensions: dimensions,
          }
        }
      })
    }
  }

  pageEvent(eventProps) {
    this.event({ ...eventProps, category: this.titleFromName(eventProps.category) });
  }

  // set the value of custom dimensions
  setDimensions(dimensions) {
    ReactGA.set(dimensions);
  }

  sendPageDetails(path, title) {
    ReactGA.ga("send", "pageview", path, { title });
  }

  sendPageView(pageName) {
    ReactGA.ga("send", "pageview", this.urlFromName(pageName), { title: this.titleFromName(pageName) });
  }

  // Convert the current page into a url-like string
  // i.e. "return-code-scan" => "/return-code-scan"
  urlFromName(name: string): string {
    return `/${name}`;
  }

  // Convert the current page into a human readable title
  // i.e. "return-code-scan" => "Return Code Scan"
  titleFromName(name: string): string {
    return Array.from(name).reduce((acc, val, i, arr) => {
      if (val.toLowerCase() === val.toUpperCase()) return acc + " ";
      if (arr[i - 1] === "-") return acc + val.toUpperCase();
      if (i === 0) return acc + val.toUpperCase();
      return acc + val;
    }, "");
  }

  getCountry(){
    let country = '';
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const america = "America";
    const americaTimezones = new Set([`${america}/Adak`, `${america}/Anchorage`, `${america}/Boise`,
      `${america}/Chicago`, `${america}/Denver`, `${america}/Detroit`, `${america}/Indianapolis`,
      `${america}/Indiana/Knox`, `${america}/Indiana/Marengo`, `${america}/Indiana/Petersburg`,
      `${america}/Indiana/Tell_City`, `${america}/Indiana/Vevay`, `${america}/Indiana/Vincennes`,
      `${america}/Indiana/Winamac`,  `${america}/Juneau`,`${america}/Louisville`, `${america}/Kentucky/Louisville`,
      `${america}/Kentucky/Monticello`, `${america}/Los_Angeles`,
      `${america}/Menominee`, `${america}/Metlakatla`, `${america}/New_York`, `${america}/Nome`,
      `${america}/North_Dakota/Beulah`, `${america}/North_Dakota/Center`, `${america}/North_Dakota/New_Salem`,
      `${america}/Phoenix`, `${america}/Sitka`, `${america}/Yakutat`, `Pacific/Honolulu`]);
    if (americaTimezones.has(timezone)) {
      country = 'US';
    }
    return country;
  }
}

export default new GAEmitter();
