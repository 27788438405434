import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { 
  $Background, 
  $Container,
} from "./styles";
import getTranslator from "../../utility/getTranslator";

const useTranslation = getTranslator("LoadingIndicator")

/**
 * unique symbol that is a signifier for default loading string across the application
 */
const defaultLoadingStr = "Loading..."
export const defaultLoadingSymbol = Symbol(defaultLoadingStr)

type LoadingIndicatorProps = {
  message: string | symbol
};

export const LoadingIndicator = ({
	message
}: LoadingIndicatorProps) => {
  const {t} = useTranslation()
  return(
    <$Background onClick={(e)=>e.stopPropagation()}> 
      <CircularProgress size={72}/>
      <$Container>
    		{ message === defaultLoadingSymbol ? t('loading', defaultLoadingStr) : message }
      </$Container>
    </$Background>
  );
};
