import React from "react";

const Location = (props) => (
  <>
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="3 2 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 9C13 9.79565 12.6839 10.5587 12.1213 11.1213C11.5587 11.6839 10.7956 12 10 12C9.20435 12 8.44129 11.6839 7.87868 11.1213C7.31607 10.5587 7 9.79565 7 9C7 8.20435 7.31607 7.44129 7.87868 6.87868C8.44129 6.31607 9.20435 6 10 6C10.7956 6 11.5587 6.31607 12.1213 6.87868C12.6839 7.44129 13 8.20435 13 9ZM12 9C12 8.46957 11.7893 7.96086 11.4142 7.58579C11.0391 7.21071 10.5304 7 10 7C9.46957 7 8.96086 7.21071 8.58579 7.58579C8.21071 7.96086 8 8.46957 8 9C8 9.53043 8.21071 10.0391 8.58579 10.4142C8.96086 10.7893 9.46957 11 10 11C10.5304 11 11.0391 10.7893 11.4142 10.4142C11.7893 10.0391 12 9.53043 12 9Z"
        fill="#737373"
      />
      <path
        d="M14.9499 13.9548C16.2629 12.6413 17.0004 10.8601 17.0004 9.00285C17.0004 7.14563 16.2629 5.36443 14.9499 4.05085C14.3001 3.40052 13.5284 2.88463 12.6791 2.53265C11.8297 2.18068 10.9193 1.99951 9.99995 1.99951C9.08056 1.99951 8.17018 2.18068 7.32084 2.53265C6.47149 2.88463 5.69983 3.40052 5.04995 4.05085C3.73704 5.36443 2.99951 7.14563 2.99951 9.00285C2.99951 10.8601 3.73704 12.6413 5.04995 13.9548L6.57095 15.4538L8.61395 17.4388L8.74695 17.5568C9.52195 18.1848 10.6569 18.1448 11.3869 17.4388L13.8219 15.0688L14.9499 13.9548ZM5.75495 4.75585C6.31232 4.19823 6.97409 3.75588 7.70246 3.45409C8.43083 3.15229 9.21153 2.99696 9.99995 2.99696C10.7884 2.99696 11.5691 3.15229 12.2974 3.45409C13.0258 3.75588 13.6876 4.19823 14.2449 4.75585C15.3397 5.8513 15.9691 7.32716 16.0019 8.8755C16.0347 10.4238 15.4683 11.925 14.4209 13.0658L14.2449 13.2498L12.9239 14.5538L10.6939 16.7218L10.5999 16.8018C10.427 16.9321 10.2164 17.0026 9.99995 17.0026C9.78345 17.0026 9.57284 16.9321 9.39995 16.8018L9.30695 16.7218L6.32695 13.8168L5.75495 13.2498L5.57895 13.0668C4.53159 11.926 3.96523 10.4248 3.99802 8.8765C4.03081 7.32816 4.66023 5.8523 5.75495 4.75685V4.75585Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Location;
