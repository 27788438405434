export enum TopLevelActions {
  BackButton = "back button pressed",
}

export enum LoginActions {
  LoginSuccessful = "login successful",
  LoginFailed = "login failed",
}

export enum StartReturnActions {
  SignedOut = "signed out",
  StartedReturn = "started return",
  ItemScanner = "Item Scanner",
}

export enum QrCodeScanActions {
  Successful = "successful",
  Bag = "invalid - bag entered",
  ExpiredReturn = "invalid - expired return",
  NotAssociated = "invalid - not associated",
  Unauthorized = "invalid - unauthorized",
  ReturnBarDropoffIneligible = "invalid - not eligible for return bar",
  PartialRefund = "invalid - partial refund",
  LocationNotAllowed = "invalid - location not allowed",
  RmaInvalid = "invalid - rma invalid",
  AlreadyComplete = "invalid - return already complete",
  NotEligibleForReturn = "invalid - not eligible for return",
  Failed = "failed",
}

export enum ScanBagPromptActions {
  ProceedToScanBag = "proceed to scan bag",
}

export enum ScanItemPromptActions {
  ScanItemBarcodeClicked = "scan item barcode clicked",
  ContinueWithoutBarcodeClicked = "continue without barcode clicked",
}

export enum NoBarcodeFoundModalActions {
  ScanBarcodeClicked = "scan barcode clicked",
  NoBarcodeFoundClicked = "no barcode found clicked",
  ModalClosed = "modal closed",
}

export enum ItemPuzzleActions {
  NotInReturnTryAgain = "try again clicked",
  NotInReturnClosed = "closed not in return modal",
  ValidItemSelected = "clicked a valid item",
  InvalidItemSelected = "clicked an invalid item",
  ItemNotListed = "clicked item not listed",
  ItemNotListedAddItem = "clicked add item in item not listed modal",
  ItemNotListedCancelReturn = "clicked cancel return in item not listed modal",
  ItemNotListedClose = "clicked dismiss modal in item not listed modal",
}

export enum ItemScanActions {
  ScanSuccessful = "successful",
  ScanFailed = "failed",
  InvalidCode = "failed: invalid code",
  BagCodeScanned = "failed: bag code scanned",
  ConfirmationCodeScanned = "failed: confirmation code scanned",
  ShippingLabelScanned = "failed: shipping label scanned",
  ItemCodeAlreadyScanned = "failed: item code already scanned",
  WebsiteUrlScanned = "failed: website url scanned",
  NotAMatch = "failed: item puzzle ",
  ContinueWithoutBarcode = "Click on: continue without barcode",
  BackButton = "Click on: back button",
}

export enum ItemDetailsActions {
  YesClicked = "yes it is - clicked",
  NoClicked = "no it isn't - clicked",
}

export enum ReturnSummaryActions {
  AddItemClicked = "add item clicked",
  AddBagClicked = "add bag clicked",
  RemoveItemClicked = "remove item clicked",
  RemoveBagClicked = "remove bag clicked",
  SubmitReturnClicked = "submit return clicked",
  ContinueClicked = "continue clicked",
  ConfirmClicked = "confirm button clicked",
  ModalClosed = "modal closed",
  NoItemsCancelReturnClicked = "no items in return - cancel return clicked",
  NoItemsAddItemClicked = "no items in return - add item clicked",
  PartialReturnModalDisplayed = "partial return window displayed",
}

export enum BagScanActions {
  ScanSuccessful = "successful",
  ScanFailed = "failed",
  InvalidCode = "failed: invalid code",
  BagCodeAlreadyScanned = "failed: bag code already scanned",
  SameReturnBagCodeAlreadyScanned = "failed: bag code already scanned (same return)",
  ConfirmationCodeScanned = "failed: confirmation code scanned",
}

export enum ReturnAcceptedActions {
  StartAnotherReturnClicked = "start another return clicked",
  CloseHappyReturnsClicked = "close happy returns clicked",
  PrintAgainClicked = "print again clicked",
}
export enum BagCountActions {
  SubmitReturnClicked = "submit return clicked",
}

export enum PrintBagLabelsActions {
  PrintBagLabelsClicked = "print bag labels clicked",
}

export enum RequestSupportActions {
  ModalName = "request-support-modal",
  RequestSupportClicked = "request support clicked",
  SupportRequestSubmitted = "support request submitted",
  SupportRequestFailed = "support request failed",
  ModalClosed = "modal closed"
}
