import styled from "styled-components";
import { AlertProps } from "./types";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $Alert = styled.div<AlertProps>`
  align-items: center;
  background: ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  margin-bottom: 16px;
  padding: 12px 16px;

  p {
    color: ${({ textColor }) => textColor};
    font-size: 14px;
    margin: 0 0 0 16px;
    width: calc(100% - 32px);
  }

  .newline {
    white-space: pre-wrap;
  }

  .icon {
    svg {
      width: 16px;
      height: 16px;

      path {
        fill: var(--warning-bold);
      }
    }
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    margin-bottom: 24px;
    p {
      font-size: 16px;
    }
  }
`;
