import styled from "styled-components";

import { ReturnistaToasterProps } from "./types";

const colors = {
  success: "var(--success-soft)",
  error: "var(--warning-soft)",
};

const textColors = {
  success: "var(--dark-green)",
  error: "var(--input-error)",
};

export const $Wrapper = styled.div<Pick<ReturnistaToasterProps, "type">>`
  align-items: center;
  background: ${({ type }) => (type ? colors[type] : "none")};
  border-radius: 8px;
  color: ${({ type }) => (type ? textColors[type] : "none")};
  display: flex;
  margin-bottom: 16px;
  padding: 12px 16px;
  font-size: 14px;

  div {
    margin-left: 16px;
    display: flex;
    flex: 1;
  }
`;
