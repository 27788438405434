import React from "react";

const HRLogo = (props) => (
  <>
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFDE00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5142 10H14.3519V15.0361C14.6211 14.6115 14.9995 14.2671 15.4474 14.0388C15.8953 13.8106 16.3963 13.7068 16.898 13.7385C17.3501 13.7016 17.804 13.7801 18.2175 13.9666C18.6309 14.153 18.9902 14.4414 19.2618 14.8046C19.6649 15.5082 19.8412 16.3189 19.7668 17.1263V21.7976H17.9431V17.7155C17.9431 16.3688 17.5574 15.3377 16.2387 15.3377C14.9201 15.3377 14.3519 16.3898 14.3519 17.7155V21.7766H12.5142V10ZM20.2032 30.7117C17.0466 30.6156 14.0126 29.4677 11.5829 27.4502C11.4876 27.373 11.409 27.277 11.3522 27.1683C11.2953 27.0596 11.2614 26.9404 11.2524 26.818C11.2434 26.6956 11.2596 26.5727 11.3 26.4569C11.3404 26.341 11.4041 26.2347 11.4872 26.1444C11.5702 26.0541 11.6709 25.9818 11.783 25.932C11.8952 25.8821 12.0163 25.8558 12.139 25.8546C12.2617 25.8534 12.3833 25.8773 12.4964 25.9249C12.6094 25.9726 12.7115 26.0429 12.7964 26.1315C13.0769 26.391 19.8104 32.346 27.2243 26.1315C27.3164 26.0551 27.4227 25.9975 27.537 25.9621C27.6514 25.9267 27.7716 25.9142 27.8908 25.9253C28.01 25.9364 28.1258 25.9708 28.2317 26.0266C28.3376 26.0825 28.4314 26.1586 28.5079 26.2507C28.5843 26.3429 28.6419 26.4491 28.6772 26.5635C28.7126 26.6778 28.7251 26.798 28.7141 26.9172C28.703 27.0364 28.6686 27.1523 28.6127 27.2581C28.5569 27.364 28.4807 27.4579 28.3886 27.5343C26.1427 29.5703 23.2341 30.7218 20.2032 30.7748V30.7117ZM27.0559 16.8496C26.772 17.0395 26.4381 17.1409 26.0965 17.1409C25.8703 17.1418 25.646 17.0981 25.4367 17.0121C25.2274 16.9262 25.0372 16.7997 24.8769 16.6401C24.7165 16.4804 24.5893 16.2907 24.5025 16.0817C24.4157 15.8728 24.3711 15.6487 24.3711 15.4225C24.3697 15.0809 24.4697 14.7466 24.6584 14.462C24.8472 14.1773 25.1162 13.955 25.4313 13.8234C25.7465 13.6917 26.0937 13.6565 26.4288 13.7223C26.764 13.7881 27.0721 13.9519 27.3141 14.1929C27.5561 14.4339 27.7211 14.7413 27.7883 15.0762C27.8554 15.4111 27.8217 15.7584 27.6913 16.0741C27.5609 16.3898 27.3398 16.6597 27.0559 16.8496Z"
        fill="#231F20"
      />
    </svg>
  </>
);

export default HRLogo;
