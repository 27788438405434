import React from "react";

const Tag = (props) => (
  <>
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7C14.2652 7 14.5196 6.89464 14.7071 6.70711C14.8946 6.51957 15 6.26522 15 6C15 5.73478 14.8946 5.48043 14.7071 5.29289C14.5196 5.10536 14.2652 5 14 5C13.7348 5 13.4804 5.10536 13.2929 5.29289C13.1054 5.48043 13 5.73478 13 6C13 6.26522 13.1054 6.51957 13.2929 6.70711C13.4804 6.89464 13.7348 7 14 7Z"
        fill="#A2A2A2"
      />
      <path
        d="M11.13 2.00005C10.8655 1.99826 10.6033 2.04895 10.3585 2.1492C10.1137 2.24944 9.89129 2.39724 9.70402 2.58405L3.02202 9.24905C2.83594 9.43466 2.68826 9.65513 2.58744 9.89786C2.48662 10.1406 2.43463 10.4008 2.43445 10.6636C2.43426 10.9265 2.48588 11.1868 2.58636 11.4296C2.68684 11.6725 2.8342 11.8932 3.02002 12.079L7.96902 17.027C8.34408 17.402 8.85269 17.6126 9.38302 17.6126C9.91335 17.6126 10.422 17.402 10.797 17.027L17.428 10.397C17.6142 10.2109 17.7618 9.98981 17.8624 9.74647C17.963 9.50314 18.0145 9.24234 18.014 8.97905L18.006 4.02905C18.0052 3.50155 17.7961 2.99572 17.4241 2.62169C17.0522 2.24767 16.5475 2.03574 16.02 2.03205L11.13 2.00005ZM10.41 3.29205C10.5037 3.19864 10.6149 3.12474 10.7373 3.07462C10.8597 3.0245 10.9908 2.99915 11.123 3.00005L16.013 3.03205C16.2769 3.03389 16.5293 3.13993 16.7153 3.32705C16.9013 3.51417 17.0058 3.76721 17.006 4.03105L17.014 8.98105C17.0141 9.11253 16.9883 9.24274 16.938 9.36422C16.8877 9.48571 16.814 9.59609 16.721 9.68905L10.091 16.32C9.99815 16.413 9.88786 16.4868 9.76646 16.5371C9.64506 16.5874 9.51494 16.6133 9.38352 16.6133C9.25211 16.6133 9.12198 16.5874 9.00058 16.5371C8.87918 16.4868 8.7689 16.413 8.67602 16.32L3.72702 11.372C3.63411 11.2791 3.56043 11.1688 3.51019 11.0473C3.45995 10.9259 3.43414 10.7958 3.43424 10.6643C3.43433 10.5329 3.46032 10.4028 3.51073 10.2815C3.56114 10.1601 3.63498 10.0499 3.72802 9.95705L10.41 3.29205Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Tag;
