import React from "react";

const PoweredByText = (props) => (
  <svg {...props} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2069_257" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="11" width="15" height="4">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.651367 11.5038H14.2912V15.0001H0.651367V11.5038Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2069_257)">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.63091 15.0001C3.75515 15.0001 0.923978 12.6733 0.8819 12.6376C0.593819 12.3956 0.572929 11.9831 0.835047 11.717C1.09677 11.4512 1.54182 11.4315 1.8298 11.6727C2.05223 11.8586 7.3492 16.1729 13.1316 11.6576C13.4289 11.4252 13.8738 11.4604 14.1249 11.7351C14.376 12.0103 14.3386 12.4216 14.041 12.6537C11.7881 14.4129 9.57775 15.0001 7.63091 15.0001Z" fill="white" />
    </g>
    <mask id="mask1_2069_257" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="7" height="9">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.61475 0H7.30255V8.54225H1.61475V0Z" fill="white" />
    </mask>
    <g mask="url(#mask1_2069_257)">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.61475 -0.000366211H3.05326V3.64843C3.43604 3.03818 4.14849 2.70878 5.05908 2.70878C5.94332 2.70878 6.56345 3.00166 6.90654 3.47761C7.21004 3.90462 7.30255 4.38057 7.30255 5.16155V8.54192H5.86404V5.60097C5.86404 4.62461 5.56054 3.88034 4.53117 3.88034C3.52826 3.88034 3.05326 4.62461 3.05326 5.60097V8.54192H1.61475V-0.000366211Z" fill="white" />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2445 5.17269C12.9892 5.17269 13.5926 4.61451 13.5926 3.92588C13.5926 3.23754 12.9892 2.67926 12.2445 2.67926C11.4999 2.67926 10.8965 3.23754 10.8965 3.92588C10.8965 4.61451 11.4999 5.17269 12.2445 5.17269Z" fill="white" />
  </svg>);

export default PoweredByText;
