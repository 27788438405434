// third-party imports
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ReturnCountFooter from "../../components/ReturnCountFooter";
import { SVG } from "../../components/svg";
import {
  deleteBagToteBarcodePair,
  deleteBagToteBarcodePairFromDatabase,
  deleteBarcode,
} from "../../redux/customer/actions";
import { Store as CustomerStore } from "../../redux/customer/types";
import { RootReducer } from "../../redux/reducers";
import { handleEnterKeyPressOnClickHandlers } from "../../utility";
import useSelector from "../../utility/useTypedSelector";
import logger from "../../utility/logger";

// local imports
import $ScanHistory, { $Card, $CardHeader, $Divider, $Footer, $ScanAnotherBagButton } from "./styles";
import { DataCyStrings } from "../../types/DataCyStrings";

class ScanHistoryLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, app, dispatch, customer) {
    super(page, app, dispatch);
    this.customer = customer;
  }

  satisfiesPreconditions(): boolean {
    if (!super.satisfiesPreconditions()) return false;
    if (!this.customer?.returns?.returning) return false;
    if (!this.customer?.bagToteBarcodePairs?.length) return false;

    logger.Info("Preconditions satisfied. Rendering ScanHistoryConfirmationScanTote...");
    return true;
  }
}

// static copy
export const SCAN_HISTORY = "Scan History";

// dynamic copy
export const BAG_X_ADDED = (barcode: string) => `Bag ${barcode} Added`;
export const TOTE_X_ADDED = (barcode: string) => `Tote ${barcode} Added`;

/**
 * Page displaying history of scanned bags and totes used exclusively within Returnista.
 */
const ScanHistory = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { app, customer } = useSelector((store: RootReducer) => store);

  const { runtime } = app;
  const { bagToteBarcodePairs, returns } = customer;

  const lifecycle = new ScanHistoryLifecycle(page, dispatch, app, customer);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  //----------------------------------------------------------------------------
  useEffect(() => {
    if (isEmpty(bagToteBarcodePairs)) {
      lifecycle.advance("confirmationScanTote");
    }
  }, [bagToteBarcodePairs]);

  //----------------------------------------------------------------------------
  // HELPERS

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const scanAnotherBagButtonHandlers = handleEnterKeyPressOnClickHandlers(() => {
    lifecycle.advance("confirmationScanTote");
  });

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  const renderBagToteBarcodePairButton = (pair, index) => {
    return (
      <span data-cy={DataCyStrings.bagToteBarcodePairButton} key={pair.bagBarcode}>
        <div className={"row-number"}>{index + 1}</div>
        <SVG name={"green-check-icon"} className={"green-check-icon"} />
        <span className={"barcode-text"}>{BAG_X_ADDED(pair.bagBarcode)}</span>
        <SVG name={"green-check-icon"} className={"green-check-icon"} />
        <span className={"barcode-text"}>{TOTE_X_ADDED(pair.toteBarcode)}</span>
        <SVG
          name={"x"}
          className={"x"}
          data-cy={DataCyStrings.deleteBagToteBarcodeButton}
          {...handleEnterKeyPressOnClickHandlers(() => {
            // do not attempt to delete from DB if using test order (pair does not exist in DB, will hang)
            if (returns?.confirmationCode === "HRTESTER") {
              dispatch(deleteBarcode(pair.bagBarcode));
              dispatch(deleteBagToteBarcodePair(pair));
            } else {
              dispatch(deleteBagToteBarcodePairFromDatabase(pair));
            }
          })}
        />
      </span>
    );
  };

  const renderBagToteBarcodePairButtons = () => {
    return bagToteBarcodePairs.map((pair, index) => {
      return renderBagToteBarcodePairButton(pair, index);
    });
  };

  const renderFooter = () => {
    return (
      <$Footer>
        <ReturnCountFooter
          count={bagToteBarcodePairs.length}
          data-cy={DataCyStrings.returnCountFooter}
          itemType={"bag-scan-to-tote"}
          runtime={runtime}
        >
          <PrimaryButton
            dataCyString={DataCyStrings.finishReturnButton}
            label="Finish Return"
            onButtonClick={() => lifecycle.advance()}
            width={"120px"}
          />
        </ReturnCountFooter>
      </$Footer>
    );
  };

  return (
    <$ScanHistory>
      <$Card data-cy={DataCyStrings.scanHistoryCard}>
        <$CardHeader>{SCAN_HISTORY}</$CardHeader>
        <$Divider />
        {renderBagToteBarcodePairButtons()}
      </$Card>
      <$ScanAnotherBagButton data-cy={DataCyStrings.scanAnotherBagButton} {...scanAnotherBagButtonHandlers}>
        <SVG name="plus" />
        <a>Scan Another Bag</a>
      </$ScanAnotherBagButton>
      {renderFooter()}
    </$ScanHistory>
  );
  //----------------------------------------------------------------------------
};

export default ScanHistory;
