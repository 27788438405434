import styled from "styled-components";
import $InstanceListBase from "../../components/InstanceListBase/styles";

export const $ItemListContentBox = styled($InstanceListBase as any)`
  .deselect {
    position: static !important;
    margin: 4px 4px 0 0;
  }
  @media screen and (min-width: 481px) {
    .deselect {
      margin: 12px 12px 0 0;
    }
  }
`;