import React from "react";

import { $Button } from "./styles";
import { ReturnistaPrimaryButtonProps } from "./types";

const ReturnistaPrimaryButton = ({
  disabled,
  className,
  type = "button",
  children,
  onClick,
  dataCyString,
  variant = "primary",
}: ReturnistaPrimaryButtonProps) => {
  return (
    <$Button
      variant={variant}
      disabled={disabled}
      className={className}
      type={type}
      data-cy={dataCyString}
      onClick={onClick}
    >
      {children}
    </$Button>
  );
};

export default ReturnistaPrimaryButton;
