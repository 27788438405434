import React from "react";
import { useDispatch } from "react-redux";

import { SVG } from "../../svg";

import { handleReturnistaExpiredToken } from "../../../redux/app/actions";
import PrimaryButton from "../.././../components/Button/PrimaryButton";
import SupportCopy from "../../SupportCopy";
import useSelector from "../../../utility/useTypedSelector";

import {
  FinalBaseProps
} from "./type";
import {
  $FinalBase,
  $FinalBasePage,
  $IconWrapper,
  $PrimaryMessage,
  $SubMessages,
  $SubMessage,
  $RuntimeItem,
} from './styles';
import { FatalError, FatalErrors } from "../../../types/LifeCycle";

//FinalBase takes an array of submessages
function FinalBase({
  iconName,
  primaryMessage,
  subMessages,
  hideButton,
  supportCopyLowercaseFirstLetter,
  showSupportCopy }: FinalBaseProps) {
  //---------------------------------------------------------------------------
  // STATE
  const { runtime, supportInfo, fatalErrorName } = useSelector(store => store.app);
  const dispatch = useDispatch();


  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // HANDLERS
  const runtimeActions: {
    [key: string]: {
      action: Function,
      label: string,
      fatalErrorsToIgnore: Array<FatalError>
    }
  } = {
    ios: {
      action: (e) => {
        dispatch(handleReturnistaExpiredToken())
      },
      label: "Return to login",
      fatalErrorsToIgnore: [FatalErrors.location]
    },
    staples: {
      action: (e) => {
        window.close()
      },
      label: "Close Tab",
      fatalErrorsToIgnore: []
    }
  }

  // some errors should lock the user in place
  // ex: don't allow user to "return to login" in Returnista
  // if we can't find a record for the given locationID in app settings
  const shouldRenderRuntimeAction = runtimeActions[runtime] &&
    !hideButton &&
    !runtimeActions[runtime].fatalErrorsToIgnore.includes(fatalErrorName)
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // RENDERING
  return (
    <$FinalBasePage>
      <$FinalBase>
        <$IconWrapper>
          {iconName &&
            <SVG name={iconName} />
          }
        </$IconWrapper>
        <$PrimaryMessage data-cy="final-base-primaryMessage">{primaryMessage}</$PrimaryMessage>
        <$SubMessages data-cy="final-base-subMessages">
          {showSupportCopy && supportInfo.copy.length ? (
            <SupportCopy message={subMessages.join(" ")} supportCopyLowercaseFirstLetter={supportCopyLowercaseFirstLetter} supportInfo={supportInfo} />
          ) : (
            renderSubMessages(subMessages)
          )}
        </$SubMessages>
        {shouldRenderRuntimeAction ? (
          <$RuntimeItem>
            <PrimaryButton
              width="200px"
              label={runtimeActions[runtime].label}
              onButtonClick={runtimeActions[runtime].action}
            />
          </$RuntimeItem>
        ) : null}
      </$FinalBase>
    </$FinalBasePage>
  );
  //---------------------------------------------------------------------------
}

// TODO: move to utility area because it's used by multiple things
function renderSubMessages(subMessages) {
  //make sure to handle gracefully and not break if we're not passed an array
  if (!Array.isArray(subMessages)) {
    return;
  }
  return subMessages.map((subMessage) => {
    return <$SubMessage key={subMessage}>{subMessage}</$SubMessage>
  });
}

export default FinalBase
export { renderSubMessages }
