import React from "react";

const Error = (props) => (
  <>
    <svg {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.19092 13H11.1909V15H9.19092V13ZM9.19092 5H11.1909V11H9.19092V5ZM10.1809 0C4.66092 0 0.190918 4.48 0.190918 10C0.190918 15.52 4.66092 20 10.1809 20C15.7109 20 20.1909 15.52 20.1909 10C20.1909 4.48 15.7109 0 10.1809 0ZM10.1909 18C5.77092 18 2.19092 14.42 2.19092 10C2.19092 5.58 5.77092 2 10.1909 2C14.6109 2 18.1909 5.58 18.1909 10C18.1909 14.42 14.6109 18 10.1909 18Z"
        fill="#C10D17"
      />
    </svg>
  </>
);

export default Error;
