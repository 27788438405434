import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

type ContentProps = {
  contentStyle: string;
  childStyle: string;
};

export const $Content = styled.div<ContentProps>`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 16px 24px 24px;
    flex: 1 1 0%;
    margin: 72px auto 0;
    max-width: 720px;
    width: 100%;
    ${({ contentStyle }) => contentStyle}
  }

  & > * {
    max-width: 800px;
    width: 100%;
    ${({ childStyle }) => childStyle}
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    & {
      padding: 24px;
      margin: 0 auto;
    }
  }
`;
