import React, { FormEvent, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import Header, { HeaderLogo } from "../../components/ReturnistaHeader";
import {
  CurrentPageAction,
  FatalErrorAction,
  LoadingAction,
  ModalAction,
  RuntimeType,
  TerminalReason,
} from "../../redux/enums";

import { $Container, $Content, $BottomButton, $RequestSupportButton, $IconWrapper, $Banner } from "./styles";

import ReturnistaAnalytics from "../../utility/ReturnistaAnalytics";
import {
  getReturnistaJWTClaims,
  isUPS,
  mapURLQueryStringToObject,
  printLabels,
  validatePrintStatus,
} from "../../utility";
import { DataCyStrings } from "../../types/DataCyStrings";
import axios from "axios";
import logger from "../../utility/logger/logger";
import ReturnistaAlertMessage from "../../components/ReturnistaAlertMessage";
import ga from "../../utility/GAEmitter";
import { RequestSupportActions, ReturnAcceptedActions } from "../../types/ReturnistaGA";

const ReturnistaReturnAccepted = () => {
  const dispatch = useDispatch();
  const { runtime, locationObject, bags, currentPage, userToken } = useSelector((store: RootStateOrAny) => store);
  const { owner } = locationObject;
  const { couponEnabled, promoImageURL, promoText } = owner || {};
  const [requestSupportError, setRequestSupportError] = useState(false);
  const [requestSupportSuccess, setRequestSupportSuccess] = useState(false);

  const claims = getReturnistaJWTClaims(userToken ?? "");

  const { authorization } = mapURLQueryStringToObject();
  const { returnsApp } = getReturnistaJWTClaims(authorization);
  const returnistaLocationOwnerName = returnsApp?.locationOwner || claims?.returnsApp?.locationOwner || "";
  const returnistaLocationID = returnsApp?.locationID || claims?.returnsApp?.locationID || "";
  const returnistaLocationOwnerID = owner?.id || "";

  const handleStartAnotherReturn = () => {
    ReturnistaAnalytics.finishReturn();
    if (runtime === RuntimeType.Partner) {
      ga.pageEvent({ category: currentPage, action: ReturnAcceptedActions.CloseHappyReturnsClicked });
      // Try to close the window; if that doesn't work send the user to an end screen
      window.close();
      dispatch({
        type: FatalErrorAction.Set,
        reason: TerminalReason.ReturnComplete,
      });
      dispatch({ type: CurrentPageAction.FatalError });
      return;
    }
    ga.pageEvent({ category: currentPage, action: ReturnAcceptedActions.StartAnotherReturnClicked });
    dispatch({ type: CurrentPageAction.Restart });
  };

  const onPrintAgainSuccess = () => {
    dispatch({ type: LoadingAction.Unset });
  };

  /**
   * onHrBagLabelsPrintAgainStatus is a function designed to be available at the top level of the DOM
   * so it can be called by UPS's CMS shell window. it should call the onPrintSuccess function when
   * the print is successful.
   *
   * @param printStatusJson {string}
   */
  const onHrBagLabelsPrintAgainStatus = (printStatusJson) => {
    validatePrintStatus(printStatusJson);
    onPrintAgainSuccess();
  };

  const handlePrintAgain = async () => {
    dispatch({ type: LoadingAction.Set, message: "Printing" });
    ga.pageEvent({ category: currentPage, action: ReturnAcceptedActions.PrintAgainClicked });

    const eventHandlerName = "onHrBagLabelsPrintAgainStatus";

    // expose the onHrBagLabelsPrintAgainStatus callback globally, to be used by UPS's shell window
    Object.defineProperty(window, eventHandlerName, {
      value: onHrBagLabelsPrintAgainStatus,
    });

    try {
      // the onHrBagLabelsPrintAgainStatus will handle a successful print
      await printLabels(bags, eventHandlerName, onPrintAgainSuccess);
    } catch (e) {
      dispatch({ type: LoadingAction.Unset });
      logger.Info(`Print Labels Error: ${e}`);
      console.log(e); // also being logged because the partner are not using our logger
      renderErrorModal();
    }
  };

  const renderErrorModal = () => {
    dispatch({
      type: ModalAction.Set,
      modalProps: {
        primaryMessage: "Failed to print",
        subMessages: ["We're having trouble printing labels. Please check the printer and try again."],
        onRequestClose: () => dispatch({ type: ModalAction.Unset }),
        button: (
          <ReturnistaPrimaryButton onClick={() => dispatch({ type: ModalAction.Unset })} dataCyString="close-modal">
            Try again
          </ReturnistaPrimaryButton>
        ),
        iconElement: (
          <$IconWrapper>
            <ReturnistaFeedbackIcon status="error" />
          </$IconWrapper>
        ),
      },
    });
  };

  const confirmRequestSupport = (): void => {
    ga.pageEvent({ category: RequestSupportActions.ModalName, action: RequestSupportActions.RequestSupportClicked });
    ReturnistaAnalytics.requestTotesButtonClickedAction(returnistaLocationID, returnistaLocationOwnerID, returnistaLocationOwnerName)
    const handleCancelRequestSupport = (): void => {
      dispatch({ type: ModalAction.Unset });
    };

    const handleConfirmRequestSupport = async (e: FormEvent) => {
      e.preventDefault();
      ReturnistaAnalytics.requestTotesConfirmedAction(returnistaLocationID, returnistaLocationOwnerID, returnistaLocationOwnerName)
      dispatch({ type: LoadingAction.Set, message: "Submitting" });
      dispatch({ type: ModalAction.Unset });
      const requestType = "out_of_totes";

      try {
        const path = `/returns/requestSupport`;
        const body = { locationID: returnistaLocationID, requestType: requestType };
        const resp = await axios.post(path, body);
        ga.pageEvent({
          category: RequestSupportActions.ModalName,
          action: RequestSupportActions.SupportRequestSubmitted,
        });
        dispatch({ type: LoadingAction.Unset });
        setRequestSupportSuccess(true);
        setRequestSupportError(false);
      } catch (e) {
        logger.Warning("Error submitting a support request", {
          err: e,
          locationID: returnistaLocationID,
          requestType: requestType,
        });
        ga.pageEvent({
          category: RequestSupportActions.ModalName,
          action: RequestSupportActions.SupportRequestFailed,
        });
        dispatch({ type: LoadingAction.Unset });
        setRequestSupportError(true);
        setRequestSupportSuccess(false);
      }
    };

    const onRequestClose = () => {
      ga.pageEvent({ category: RequestSupportActions.ModalName, action: RequestSupportActions.ModalClosed });
      dispatch({ type: ModalAction.Unset });
    };

    dispatch({
      type: ModalAction.Set,
      modalProps: {
        primaryMessage: "After submitting, Happy Returns Support Team will reach out to the store manager.",
        onRequestClose,
        iconElement: (
          <$IconWrapper data-cy="request-support-info-icon-modal">
            <ReturnistaFeedbackIcon status="info" />
          </$IconWrapper>
        ),
        button: (
          <$RequestSupportButton>
            <ReturnistaPrimaryButton
              variant="outlined"
              onClick={onRequestClose}
              dataCyString={DataCyStrings.cancelRequestSupportButton}
            >
              Cancel
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton
              onClick={handleConfirmRequestSupport}
              dataCyString={DataCyStrings.confirmRequestSupportButton}
            >
              Submit
            </ReturnistaPrimaryButton>
          </$RequestSupportButton>
        ),
      },
    });
  };

  return (
    <>
      <Header center={<HeaderLogo />} />
      <$Container data-cy="return-success-feedback">
        <$Banner>
          {requestSupportSuccess ? (
            <ReturnistaAlertMessage
              type="success"
              message="Your request has been placed."
              dataCyString={DataCyStrings.requestSupportSuccessMessage}
              className="banner"
            />
          ) : null}
          {requestSupportError ? (
            <ReturnistaAlertMessage
              type="error"
              message="Failed to submit request, please try again."
              dataCyString={DataCyStrings.requestSupportErrorMessage}
              className="banner"
            />
          ) : null}
        </$Banner>
        <$Content>
          <div className="feedback">
            <ReturnistaFeedbackIcon status="success" />
            <p data-cy="return-success-feedback-title">{isUPS() ? "Return finished" : "Return accepted"}</p>
          </div>
          <div className="hint">
            <p>
              <span>Tell the customer:</span> <br />
              “Your return is complete and you’ll receive an email receipt from Happy Returns. You’re free to go.”
            </p>
          </div>
          {couponEnabled && (
            <>
              <div className="coupon-reminder" data-cy="coupon-reminder">
                <span>Remind the customer their receipt contains a coupon for your store.</span>
              </div>
              {promoImageURL && <img alt={promoText} src={promoImageURL} data-cy="coupon-reminder-image" />}
            </>
          )}
        </$Content>
        <$BottomButton>
          <ReturnistaPrimaryButton onClick={handleStartAnotherReturn} dataCyString="start-return">
            {runtime === RuntimeType.Partner ? "Close Happy Returns" : "Start another return"}
          </ReturnistaPrimaryButton>
          {isUPS() && (
            <div className="subtext">
              Problem with the bag labels?{" "}
              <a className="print-again" data-cy={DataCyStrings.printAgain} onClick={handlePrintAgain}>
                Print again
              </a>
            </div>
          )}
          {!isUPS() && (
            <ReturnistaPrimaryButton
              dataCyString={DataCyStrings.requestSupport}
              onClick={confirmRequestSupport}
              variant="link"
            >
              {"Request Happy Returns Totes"}
            </ReturnistaPrimaryButton>
          )}
        </$BottomButton>
      </$Container>
    </>
  );
};

export default ReturnistaReturnAccepted;
