import React from "react";

/**
 * Gift box with bowtie
 */
const Gift = (props) => {
  return (
    <svg {...props} width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1 7.81414H8.42979C7.11701 7.17952 5.7955 6.3886 5.33644 5.61272C4.31844 3.89213 4.85941 1.63018 6.54474 0.560518C8.23006 -0.509149 10.4216 0.0185243 11.4396 1.73911C11.5706 1.96058 11.6708 2.24746 11.7459 2.57923C11.821 2.24746 11.9212 1.96058 12.0522 1.73911C13.0702 0.0185269 15.2617 -0.509147 16.9471 0.56052C18.6324 1.63019 19.1733 3.89213 18.1553 5.61272C17.6963 6.3886 16.3748 7.17952 15.062 7.81414H23C23.5523 7.81414 24 8.26186 24 8.81414V27C24 27.5523 23.5523 28 23 28H1C0.447716 28 0 27.5523 0 27V8.81414C0 8.26186 0.447715 7.81414 1 7.81414ZM10.2299 6.79018C10.2299 6.79018 10.5515 3.78571 9.99609 2.84696C9.44064 1.90821 8.30703 1.58088 7.4641 2.11586C6.62117 2.65083 6.38813 3.84552 6.94358 4.78427C7.49903 5.72302 10.2299 6.79018 10.2299 6.79018ZM22.2222 9.64922H12.8889V16.9895H22.2222V9.64922ZM22.2222 18.8246H12.8889V26.1649H22.2222V18.8246ZM11.1111 16.9895V9.64922H1.77778V16.9895H11.1111ZM1.77778 18.8246V26.1649H11.1111V18.8246H1.77778ZM16.5482 4.78427C15.9928 5.72302 13.2619 6.79018 13.2619 6.79018C13.2619 6.79018 12.9402 3.78571 13.4957 2.84696C14.0511 1.90821 15.1848 1.58088 16.0277 2.11586C16.8706 2.65083 17.1037 3.84552 16.5482 4.78427Z" fill="#737373"/>
    </svg>
  )
}

export default Gift;