// third-party imports
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";

// local imports
import {$RefundOptions } from "./styles";
import { Store as CustomerStore } from "../../redux/customer/types";
import { ReturnPortalBaseWithPage } from "../../components/ReturnPortalBase";
import NavigationCard from "../../components/NavigationCard";
import { SVGName } from "../../components/svg";
import { DataCyStrings } from "../../types/DataCyStrings";
import ga from "../../utility/GAEmitter";
import { AnalyticCategories, AnalyticsPageRoutes, RefundOptionsPageActions } from "../../types/Analytics";
import { getAdminMode, handleEnterKeyPressOnClickHandlers } from "../../utility";
import getTranslator from "../../utility/getTranslator";
import { Refund } from "../../types/Instance";
import { setPurchasing, setSelectedRefund } from "../../redux/customer/actions";
import { clearLoadingIndicator, showLoadingIndicator } from "../../redux/app/actions";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import axios from "axios";


const StoreCreditString = 'store-credit'
const RefundString = 'refund'
const RefundOption = 'refund-option'
const ShopOnOurSiteToFind = 'shopOnOurSiteToFind'

interface ProductImagesResponse {
  productImageUrls: string[]
  imageType: string
}

const useTranslation = getTranslator("RefundOptions");
class RefundOptionsLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, app, dispatch) {
    super(page, app, dispatch);
  }
}

/**
 * A page in the Return Shopping flow where the user will select how they would like
 * to receive their refund: shop for more, refund to original payment, or store credit.
 */
const RefundOptions = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [returnShoppingButtonClicked, setReturnShoppingButtonClicked] = useState(false);
  const [retailerProductImages, setRetailerProductImages] = useState<ProductImagesResponse>({
    imageType: "",
    productImageUrls: [],
  })
  const [loadedRetailerProductImages, setLoadedRetailerProductImages] = useState(false);
  const { customer, app } = useSelector((store: RootReducer) => store);
  const { copies, locale, returnShoppingFeeWaiver, returnShoppingDiscount, returnShoppingURL } = app;
  const { refundOptions, itemsMarkedForCompletion } = customer;
  const [isAdminMode] = useState<boolean>(getAdminMode());
  const retailerName = copies?.retailerName
  const lifecycle = new RefundOptionsLifecycle(page, dispatch, app);
  const { expandMoreRefundOptions } = useSelector((store) => store.app);
  const [selectedButton, setSelectedButton] = useState('');
  const [refundOption, setRefundOption] = useState(null);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  const { t } = useTranslation();

  // if itemsMarkedForCompletion is updated as a result of the return shopping button
  // being clicked, send POST request to /returnsession
  useEffect(() => {
    if (!loadedRetailerProductImages) {
      getRetailerProductImages();
    }
  }, [itemsMarkedForCompletion, returnShoppingButtonClicked, selectedButton]);

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    });
    ga.sendPageDetails(AnalyticsPageRoutes.RefundOptions, AnalyticCategories.RefundOptionsPage);
  }, []);

  useEffect(() => {
    //load all refund options if expandMoreRefundOptions for the retailer is true
    if (expandMoreRefundOptions) {
      setShowAllOptions(true);
    }
  }, [expandMoreRefundOptions]);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS
  const generateSubtext = (strings: Array<string | undefined>): string => {
    // create a Set using the passed in strings array, filter out undefined, join with newline
    return [...new Set(strings)].filter((x) => !!x).join("\n");
  };


  const getSelectedButton = (buttonClicked) => {
    if (selectedButton.length > 0 && selectedButton === buttonClicked) {
      return `${RefundOption} selected`
    }
    return RefundOption
  }

  const redirectRetailer = () => {
    try {
        axios
          .post<{ sessionID: string; token: string }>("/returnsession", {
            sessionData: { app, customer },
          })
          .then((resp) => {
            const { sessionID, token } = resp.data;
            const payload = JSON.stringify({
              id: sessionID,
              jwt: token,
              url: window.location.host,
              env: (window as any).appConfig.HR_ENVIRONMENT,
            });
            const url = `https://${returnShoppingURL}/#hr_rsz=${payload}`;
            window.location.href = url;
          });
      } catch (e) {
        console.error(e);
        setReturnShoppingButtonClicked(false);
        dispatch(clearLoadingIndicator());
        alert("Unable to connect to retailer store. Please select a different return option.");
      }
  }
  const selectedItem = (option) => {
   setSelectedButton(option.id)
   setRefundOption(option)

  }

  const refundOptionFunction = (option) => {
    handleRefundOptionClick({ ...option, optionID: option.id })
  }
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS

  const handleNextStep = (() => {
    if (selectedButton === ShopOnOurSiteToFind) {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      redirectRetailer()
    } else {
      refundOptionFunction(refundOption)
    }
  })

  const showAllOptionsHandlers = handleEnterKeyPressOnClickHandlers(() => {
    setShowAllOptions(true);
    ga.event({
      category: AnalyticCategories.RefundOptionsPage,
      action: RefundOptionsPageActions.ShowMoreOptions
    });
  });

  const handleRefundOptionClick = (option: Refund) => {
    if (option.id === StoreCreditString) {
      ga.event({
        category: AnalyticCategories.RefundOptionsPage,
        action: RefundOptionsPageActions.StoreCreditClicked
      });
    } else if (option.id === RefundString) {
      ga.event({
        category: AnalyticCategories.RefundOptionsPage,
        action: RefundOptionsPageActions.RefundClicked
      });
    }

    dispatch(setSelectedRefund(option));
    // clear the purchasing data if user selects refund or store credit
    dispatch(setPurchasing(undefined));
    lifecycle.advance();
  };

  const handleReturnShoppingClick = async () => {
    ga.event({
      category: AnalyticCategories.RefundOptionsPage,
      action: RefundOptionsPageActions.ReturnShoppingClicked
    });
    const refund = { ...refundOptions?.find((option) => option.id === "refund"), optionID: "refund" } as Refund;
    setSelectedButton(ShopOnOurSiteToFind);
    dispatch(setSelectedRefund(refund));
    setReturnShoppingButtonClicked(true);
  };

  const getRetailerProductImages = async () => {
    try{
      if (app.returnShoppingEnabled) {
        dispatch(showLoadingIndicator(defaultLoadingSymbol));
        const endpoint = `/retailerproductimages`;
        const resp = await axios.get<ProductImagesResponse>(endpoint);
        const productImages: ProductImagesResponse = resp.data

        setRetailerProductImages(productImages);
      }
    } catch (e) {
    } finally {
      setLoadedRetailerProductImages(true);
      dispatch(clearLoadingIndicator());
    }
  }

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING

  const renderRefundOptions = () =>
    refundOptions?.map((option) => (
      <div className={getSelectedButton(option.id)} key={option.id}>
        <NavigationCard
          key={option.id}
          title={option.label}
          subtext={option.sublabel}
          iconName={option.id as SVGName}
          onClick={() => selectedItem(option)}
          dataCyString={`${option.id}-button` as DataCyStrings}
        />
      </div>
    ))

  return (

    <ReturnPortalBaseWithPage page={page} footer="return-shopping" advance={handleNextStep} isFooterDisabledCallback={() => selectedButton.length > 0} refundOptionSelected={getSelectedButton}>
      <>
        <$RefundOptions isActive={loadedRetailerProductImages}>
          <div className={getSelectedButton(ShopOnOurSiteToFind)} >
            <NavigationCard
              title={t(ShopOnOurSiteToFind)}
              onClick={() => {handleReturnShoppingClick()}}
              iconName="shopping-cart"
              subtext={generateSubtext([
                returnShoppingFeeWaiver ? t("processingFeeWaived") : undefined,
                returnShoppingDiscount ? t("discountPreApplied", { discount: returnShoppingDiscount }) : undefined,
                copies?.returnShoppingCustomPromotion ? copies.returnShoppingCustomPromotion : undefined,
              ])}
              dataCyString={DataCyStrings.returnShoppingButton}
              imageUrls={retailerProductImages.productImageUrls}
            />
          </div>
          {!showAllOptions && (
            <button className="show-all-options" data-cy={DataCyStrings.showMoreOptions} {...showAllOptionsHandlers}>
              {t("showMoreOptions")}
            </button>
          )}
          {showAllOptions && renderRefundOptions()}
        </$RefundOptions>
      </>
    </ReturnPortalBaseWithPage>
  );
  //----------------------------------------------------------------------------
};

export default RefundOptions;
