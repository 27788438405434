import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $Bag = styled.div`
  border: solid 1px var(--border-card);
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;

  .header {
    align-items: center;
    background: var(--disabled-grey);
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 12px 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-bottom: 0;
      padding: 8px 24px;
    }

    div {
      display: flex;
    }

    p {
      font-size: 16px;
      margin: 0 8px 0 0;
    }

    span {
      background: var(--bag-count);
      border-radius: 12px;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 8px 6px;
    }

    button {
      background: none;
      border: none;
    }
  }

  .itemAction {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 24px;
    width: 100%;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin: 24px 0 0;
    }

    & > :last-child {
      margin-bottom:24px;
    }

    &:after {
      border-bottom: solid 1px var(--border-card);
      content: "";
      display: block;
      margin: 0 24px;
      width: 100%;
    }

    > button {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      width: 90%;

      @media (min-width: ${tabletOrLargerDevices}) {
        width: 348px;
      }

      .text {
        margin: 3px 0 0 12px;
      }
    }
  }
`;

export const $BagItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px 24px;

  @media (min-width: ${tabletOrLargerDevices}) {
    padding: 24px;
  }

  .image {
    margin-right: 12px;
    width: 104px;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-right: 24px;
      width: 120px;
    }

    img {
      width: 100%;
    }
  }

  .product-info {
    display: flex;
    flex-wrap: wrap;
    width: 160px;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 455px;
    }

    button {
      align-self: flex-end;
      padding-left: 0;
      width: auto;
    }

    .name {
      font-size: 15px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 455px;
    }

    .display {
      color: var(--medium-emphasis-black);
      display: inline-flex;
      font-size: 15px;
      width: 100%;

      p {
        display: flex;
        line-height: 5px;
        margin: 0;

        &:last-of-type {
          &::after {
            display: none;
          }
        }

        &::after {
          background: var(--medium-emphasis-black);
          content: "";
          display: inline-block;
          height: 15px;
          margin: -3px 8px 0;
          width: 1px;
        }
      }
    }
  }
`;

export const $NewBagLink = styled.div`
  margin: 0 auto;
  width: 340px;

  button {
    margin-bottom: 8px;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin-bottom: 0;
    }
  }
`;

export const $IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const $ModalButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 100%;

  @media (min-width: ${tabletOrLargerDevices}) {
    flex-direction: row;
  }

  button {
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 190px;
      margin-bottom: 0;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
