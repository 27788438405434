import React from "react";

const Printer = (props) => (
  <>
    <svg
      {...props}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1.5H6.00004C5.7239 1.5 5.50004 1.72386 5.50004 2V3.83333H14.5V2C14.5 1.72386 14.2762 1.5 14 1.5ZM6.00004 0C4.89547 0 4.00004 0.895432 4.00004 2V3.83333V4.66667H2C0.895431 4.66667 0 5.5621 0 6.66667V13.3333C0 14.4379 0.895431 15.3333 2 15.3333H4V17.5C4 18.0523 4.44771 18.5 5 18.5H15C15.5523 18.5 16 18.0523 16 17.5V15.3333H18C19.1046 15.3333 20 14.4379 20 13.3333V6.66667C20 5.5621 19.1046 4.66667 18 4.66667H16V3.83333V2C16 0.895431 15.1046 0 14 0H6.00004ZM16 13.8333H18C18.2761 13.8333 18.5 13.6095 18.5 13.3333V6.66667C18.5 6.39052 18.2761 6.16667 18 6.16667H2C1.72386 6.16667 1.5 6.39052 1.5 6.66667V13.3333C1.5 13.6095 1.72386 13.8333 2 13.8333H4V11.5C4 10.9477 4.44772 10.5 5 10.5H15C15.5523 10.5 16 10.9477 16 11.5V13.8333ZM5.5 12H14.5V17H5.5V12ZM15.75 9.5C16.1642 9.5 16.5 9.16421 16.5 8.75C16.5 8.33579 16.1642 8 15.75 8C15.3358 8 15 8.33579 15 8.75C15 9.16421 15.3358 9.5 15.75 9.5Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Printer;
