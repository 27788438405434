import React from "react";

const Polybag = (props) => (
  <svg {...props} width="52.61" height="50" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.0799 7.88023L45.0699 1.49023L38.7099 2.44023H7.48988L1.12988 1.49023L2.06988 7.60023V37.1402L1.12988 43.2502L6.34988 42.4702H39.8499L45.0699 43.2502L44.0799 36.8602V7.88023Z"
      fill="white"
      stroke="#221F1F"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M13.1362 22.4007C9.74625 22.4407 7.24625 20.1507 7.20625 20.1107C6.95625 19.8707 6.92625 19.4607 7.15625 19.1907C7.38625 18.9207 7.77625 18.9007 8.02625 19.1407C8.22625 19.3207 12.9062 23.5807 17.9162 19.0007C18.1762 18.7607 18.5662 18.7907 18.7862 19.0707C19.0062 19.3407 18.9762 19.7507 18.7262 19.9907C16.7762 21.7707 14.8462 22.3807 13.1462 22.4007H13.1362Z"
      fill="#221F1F"
    />
    <path
      d="M7.71582 7.4702L8.97582 7.4502L9.01582 11.1002C9.34582 10.4902 9.96582 10.1502 10.7558 10.1402C11.5458 10.1302 12.0758 10.4202 12.3758 10.8902C12.6458 11.3102 12.7358 11.7902 12.7358 12.5702L12.7758 15.9502L11.5158 15.9702L11.4858 13.0302C11.4758 12.0502 11.2058 11.3102 10.3058 11.3202C9.40582 11.3302 9.01582 12.0802 9.03582 13.0602L9.06583 16.0002L7.80582 16.0202L7.71582 7.4802V7.4702Z"
      fill="#221F1F"
    />
    <path
      d="M17.066 12.5302C17.716 12.5302 18.236 11.9602 18.236 11.2702C18.236 10.5802 17.696 10.0302 17.046 10.0402C16.396 10.0502 15.876 10.6102 15.876 11.3002C15.876 11.9902 16.416 12.5402 17.066 12.5302Z"
      fill="#221F1F"
    />
    <path d="M35.1471 15.29H32.8271V17.54H35.1471V15.29Z" fill="#221F1F" />
    <path d="M31.1471 15.29H28.8271V17.54H31.1471V15.29Z" fill="#221F1F" />
    <path d="M33.447 17.5605H31.127V19.8105H33.447V17.5605Z" fill="#221F1F" />
    <path d="M37.4167 17.5605H35.0967V19.8105H37.4167V17.5605Z" fill="#221F1F" />
    <path d="M29.4362 17.5605H27.1162V19.8105H29.4362V17.5605Z" fill="#221F1F" />
    <path
      d="M38.7367 7.2002H25.5967V21.3502H38.7367V7.2002Z"
      stroke="#221F1F"
      strokeWidth="0.75"
      strokeMiterlimit="10"
    />
    <path d="M37.3366 8.82031H35.0166V11.0703H37.3366V8.82031Z" fill="#221F1F" />
    <path d="M29.5768 8.82031H27.2568V11.0703H29.5768V8.82031Z" fill="#221F1F" />
    <path d="M33.0671 13.1104H30.7471V15.3604H33.0671V13.1104Z" fill="#221F1F" />
    <path d="M35.277 11.0098H32.957V13.2598H35.277V11.0098Z" fill="#221F1F" />
    <path d="M31.2262 10.96H28.9062V13.21H31.2262V10.96Z" fill="#221F1F" />
    <path d="M37.5065 13.1104H35.1865V15.3604H37.5065V13.1104Z" fill="#221F1F" />
    <path d="M33.3669 8.82031H31.0469V11.0703H33.3669V8.82031Z" fill="#221F1F" />
    <path d="M29.4665 13.1104H27.1465V15.3604H29.4665V13.1104Z" fill="#221F1F" />
  </svg>
);

export default Polybag;
