import React, { useState } from 'react';
import { DataCyStrings } from '../../types/DataCyStrings';
import { $TextInput } from "./styles";

type TextInputTypes = {
  // label for input bar
  label?: string;

  // value of state to be passed
  value: string;

  // determines html input type
  type?: "password" | "text" | "email";

  // copy that appears on a blank input field
  placeholder?: string;

  // hint for autocomplete
  autocomplete?: string;

  // call back on text change
  onChange: (e: string) => void;
  onSubmit?: () => void;

  // used to force document view back to the top
  // of the web view when input onfocuses
  forceTopScrollOnBlur?: boolean;

  // string to be passed to data-cy attribute
  // for selection purposes in Cypress
  dataCyString?: DataCyStrings

  // used for enabling and disabling input into the text field
  disabledState?: boolean;
};

const TextInput = ({label, value, forceTopScrollOnBlur, type="text", placeholder, autocomplete, onChange, onSubmit, dataCyString, disabledState}: TextInputTypes) => {
  const onBlur = (e) => {
    if (forceTopScrollOnBlur) {
      // TODO: Discover why this is needed and an appropriate new fix.
      // Currently, if the window.scrollTo() is called vi onBlur while iOS keyboard is
      // open, the clicks to buttons/links will not register until after keyboard
      // closes.
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: "smooth"
      // });
    }
  }
  return (
    <$TextInput onBlur={onBlur}>
      {label &&
        <label htmlFor={`input-${label}`} data-cy={`${dataCyString}-label`}>
          {label}
        </label>
      }
      <input
        id={label ? `input-${label}`: ""}
        type={type}
        placeholder={placeholder} value={value}
        autoComplete={autocomplete}
        onChange={ (e) => onChange(e.target.value)} 
        onKeyDown={ (e) => {
          if (e.key == "Enter")
            onSubmit?.()
        }}
        data-cy={dataCyString}
        disabled={disabledState}
      />
    </$TextInput>
  )
}

export default TextInput
