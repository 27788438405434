import { datadogRum } from "@datadog/browser-rum";
import { CurrentPage } from "../redux/enums";
import { type ReducerState as ReturnObjectState } from "../redux/returnistaReducers/returnObject";
import { getReturnistaJWTClaims } from ".";

export enum ReturnistaAnalyticsErrors {
  UnexpectedEnterCode = "Unexpected call to enterCode",
  UnexpectedNoBarcode = "Unexpected call to noBarcode",
  UnexpectedTimeout = "Unexpected timout during return loop",
  ReusedInvalidScan = "Invalid item scan input was entered again",
}

class ReturnistaAnalytics {
  returnStartedAt: number | null;
  returnObject: ReturnObjectState | null;
  environment: string;
  mostRecentPage: CurrentPage | null;

  constructor() {
    this.returnStartedAt = null;
    this.returnObject = null;
    this.environment = window?.appConfig?.HR_ENVIRONMENT ?? "local";
    this.mostRecentPage = null;

    datadogRum.init({
      applicationId: "9995196a-edd2-43c2-923e-3314d61bb083",
      clientToken: "pub6183594faf2ecfaf08cf3398d87ec553",
      site: "datadoghq.com",
      service: "returnista",
      env: this.environment,
      trackViewsManually: true,
      sessionSampleRate: 100,
    });
  }

  collectError(err: ReturnistaAnalyticsErrors) {
    datadogRum.addError({
      error: err,
    });
  }

  startView(name: CurrentPage) {
    if ([CurrentPage.Initializer].includes(name)) return;
    if (this.mostRecentPage === name) return;
    this.mostRecentPage = name;
    datadogRum.startView({
      name,
    });
  }

  startReturn() {
    if (this.returnStartedAt != null) {
      datadogRum.stopSessionReplayRecording();
    }
    if (this.environment === "prod") {
      datadogRum.startSessionReplayRecording();
    }
    this.returnStartedAt = Date.now();
  }

  finishReturn() {
    if (this.returnStartedAt == null || this.returnObject == null) return;
    datadogRum.stopSessionReplayRecording();
    datadogRum.removeGlobalContextProperty("confirmationCode");
    datadogRum.removeGlobalContextProperty("numberOfItems");
    datadogRum.removeGlobalContextProperty("locationId");

    const { id, confirmationCode, returning } = this.returnObject;
    datadogRum.addAction("time-in-app", {
      id,
      confirmationCode,
      duration: Date.now() - this.returnStartedAt,
      numberOfItems: returning.length,
    });
    this.returnStartedAt = null;
  }

  setAppVersion(appPlatform: string, appVersion: string) {
    datadogRum.setGlobalContextProperty("appPlatform", appPlatform);
    datadogRum.setGlobalContextProperty("appVersion", appVersion);
  }

  setReturnObject(returnObject: ReturnObjectState) {
    this.returnObject = returnObject;
    datadogRum.setGlobalContextProperty("confirmationCode", returnObject.confirmationCode);
    datadogRum.setGlobalContextProperty("numberOfItems", returnObject.returning.length);
  }

  setLocationIdFromToken(token: string) {
    const claims = getReturnistaJWTClaims(token);
    const locationID = claims?.returnsApp?.locationID ?? claims?.locID ?? "unknown";
    datadogRum.setGlobalContextProperty("locationID", locationID);
  }

  validItemSelected(imgUrl: string) {
    if (this.returnObject == null) return;
    const { id, confirmationCode, returning } = this.returnObject;
    datadogRum.addAction("valid-item-selected", {
      id,
      confirmationCode,
      numberOfItems: returning.length,
      imgUrl,
    });
  }

  invalidItemSelected(imgUrl: string, maxAttemptsReached) {
    if (this.returnObject == null) return;
    const { id, confirmationCode, returning } = this.returnObject;
    datadogRum.addAction("invalid-item-selected", {
      id,
      confirmationCode,
      numberOfItems: returning.length,
      imgUrl,
    });
    if (maxAttemptsReached) {
      datadogRum.addAction("max-invalid-attempts", {
        id,
        confirmationCode,
        numberOfItems: returning.length,
      });
    }
  }

  invalidItemsRemoved() {
    if (this.returnObject == null) return;
    const { id, confirmationCode, returning } = this.returnObject;
    datadogRum.addAction("invalid-item-selected", {
      id,
      confirmationCode,
      numberOfItems: returning.length,
    });
  }

  codeEntered(codeScanned: boolean, codeType: "confirmation" | "bag" | "item") {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("code-entered", {
      id,
      confirmationCode,
      codeScanned,
      codeType,
    });
  }

  summaryRemoval(removalType: "item" | "bag") {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("summary-removal", {
      id,
      confirmationCode,
      removalType,
    });
  }

  bagsWereAdded(bags: Array<String>) {
    if (this.returnObject == null || bags.length === 0) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("bags-added", {
      id,
      confirmationCode,
      bags,
    });
  }

  bagsMayNotBeAdded(bags: Array<String>) {
    if (this.returnObject == null || bags.length === 0) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("bags-uncertain", {
      id,
      confirmationCode,
      bags,
    });
  }

  partialReturnModalOpened(numberOfSentItems) {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("partial-return-modal-opened", {
      id,
      confirmationCode,
      numberOfSentItems,
    });
  }

  itemNotListedAction(action: string, numberOfTries: number) {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("item-not-listed", {
      id,
      confirmationCode,
      action,
      numberOfTries,
    });
  }

  requestTotesButtonClickedAction(locationID: string, locationOwnerID: string, locationOwnerName: string) {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("request-totes-button-clicked", {
      id,
      confirmationCode,
      locationID,
      locationOwnerID,
      locationOwnerName,
    });
  }

  requestTotesConfirmedAction(locationID: string, locationOwnerID: string, locationOwnerName: string) {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("request-totes-confirmed", {
      id,
      confirmationCode,
      locationID,
      locationOwnerID,
      locationOwnerName,
    });
  }

  outOfBagsButtonClickedAction(locationID: string, locationOwnerName: string, screen: string) {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("out-of-bags-button-clicked", {
      id,
      confirmationCode,
      locationID,
      locationOwnerName,
      screen,
    });
  }

  outOfBagsConfirmedAction(locationID: string, locationOwnerName: string, screen: string) {
    if (this.returnObject == null) return;
    const { id, confirmationCode } = this.returnObject;
    datadogRum.addAction("out-of-bags-request-confirmed", {
      id,
      confirmationCode,
      locationID,
      locationOwnerName,
      screen
    });
  }

  fakeImagesLoaded(fakeImageNum: number) {
    if (this.returnObject == null) return;
    const {id, confirmationCode} = this.returnObject;
    datadogRum.addAction("fake-images-loaded", {
      id,
      confirmationCode,
      fakeImageNum,
    });
  }

  fakeImagesNotLoaded() {
    if (this.returnObject == null) return;
    const {id, confirmationCode} = this.returnObject;
    datadogRum.addAction("fake-images-not-loaded", {
      id,
      confirmationCode,
    });
  }

  fakeImagesSeen(puzzleAttempts: number) {
    if (this.returnObject == null) return;
    const {id, confirmationCode} = this.returnObject;
    datadogRum.addAction("fake-images-seen", {
      id,
      confirmationCode,
      puzzleAttempts,
    });
  }

  fakeImagesNotSeen(puzzleAttempts: number) {
    if (this.returnObject == null) return;
    const {id, confirmationCode} = this.returnObject;
    datadogRum.addAction("fake-images-not-seen", {
      id,
      confirmationCode,
      puzzleAttempts
    });
  }
}

export default new ReturnistaAnalytics();
