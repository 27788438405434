import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../../globalConstants";

import { $Button } from "../../../components/Button/styles";
import { getTextColorFromBackgroundBrightness } from "../../../utility";

function getTextColorForNextStepsButton(isButtonFocused: boolean, primaryColor) {
  if (!isButtonFocused) {
    return "var(--text-black)";
  }

  if (!primaryColor) {
    return "var(--primary-white)";
  }

  return getTextColorFromBackgroundBrightness(primaryColor);
}

export const $ButtonContents = styled.div``;

export const $NextStepsCard = styled.div<{ isButtonFocused: boolean, primaryColor }>`
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  height: fit-content;
  margin-bottom: 16px;
  padding: 24px;
  width: 100%;

  font-family: "sofia-pro", sans-serif;

  ${$Button} {
    background-color: ${(props) => (props.isButtonFocused ? "" : "var(--disabled-surface-white)")};
    border: ${(props) => (props.isButtonFocused ? "" : "1px solid var(--white-500-border, #DBDBDB)")};
    border-radius: 12px;
    color: ${(props) => (getTextColorForNextStepsButton(props.isButtonFocused, props.primaryColor))};
    display: flex;
    height: unset;
    flex-direction: row;
    margin-top: 16px;

    font-family: "sofia-pro", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */

    ${$ButtonContents} {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 16px;

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    svg {
      margin-right: 8px;
      position: relative;

      path {
        fill: ${(props) => (getTextColorForNextStepsButton(props.isButtonFocused, props.primaryColor))};
      }
    }

    div + svg {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  ${$Button}:hover {
    background-color: ${(props) => (props.isButtonFocused ? "": "#f9f9f9")};
    color: ${(props) => (props.isButtonFocused ? "var(--primary-white)":"var(--text-black)")};

    svg {
      path {
        fill: ${(props) => (props.isButtonFocused ? "var(--primary-white)":"var(--text-black)")};
      }
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-bottom: 1px solid var(--border-card);
    border-radius: revert;
    border-top: 1px solid var(--border-card);
    margin-bottom: 8px;
  }
`;

export const $Body = styled.div`
  color: var(--black-700-input-label, #616161);
  font-family: "sofia-pro", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */

  ul {
    margin-block-end: 0;
    margin-block-start: 0;
    padding-inline-start: 20px;

    li {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;

export const $ConfirmationDetails = styled.div`
  color: var(--soft-black, #616161);
  font-family: "sofia-pro", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */

  border-top: 1px solid #dbdbdb;
  margin-top: 24px;
`;

export const $ConfirmationDetailsHeader = styled.div`
  margin-top: 16px;
  overflow-wrap: anywhere;
`;

export const $ConfirmationDetailsLink = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    position: relative;
    margin-right: 8px;
    margin-top: 4px;
  }
`;

export const $DisclaimerLocationMilesThreshold = styled.div`
  color: #737373;
  font-size: 12px;
  line-height: 18px;
  margin: 15px 0 0 0;
`;

export const $HappyReturnsBanner = styled.div`
  background-color: var(--brand-yellow, #ffde00);
  border-radius: 12px 12px 0 0;
  display: flex;
  height: 34px;
  width: 100%;
`;

export const $HappyReturnsLogo = styled.div`
  margin: 0 auto;
  padding-top: 8px;
`;

export const $UPSBanner = styled.div`
  border-radius: 12px 12px 0 0;
  display: flex;
  height: 34px;
  width: 100%;
`;

export const $UPSLogo = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const $Header = styled.div`
  color: var(--black-900-primary, #221f1f);
  font-family: "sofia-pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 15px;
  }
`;

export const $PrimaryDetails = styled.div`
  display: flex;
  flex-direction: row;
`;

export const $PrimaryDetailsLeftColumn = styled.div`
  width: auto;
`;

export const $PrimaryDetailsSingleColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const $QRCard = styled.div`
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--black-900-primary, #221f1f);
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  margin-left: 16px;
  max-width: 205px;
  min-width: 205px;

  // TODO use styled-components
  .apple-pass-button-container {
    margin-bottom: 16px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 16px;

    margin-bottom: 16px;
    margin-left: 0;
    max-width: revert;
    width: 100%;
  }
`;

export const $QRCardText = styled.div`
  margin-bottom: 16px;

  color: var(--black-700-input-label, #616161);
  text-align: center;
  font-family: "sofia-pro", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const $QRCode = styled.img`
  max-width: 90%;
`;
