import styled from "styled-components";

interface InstanceButtonProps {
  selected?: boolean,
  onClick: () => void,
  imageURL?: string,
  shouldDisplayItemStatus: boolean,
  shouldRenderDisabled: boolean
}

// language=SCSS prefix=*{ suffix=}
const $InstanceButton = styled.div<InstanceButtonProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 128px;
  margin-bottom: 16px;
  user-select: none;
  border-radius: 12px;
  -webkit-tap-highlight-color: transparent;
  cursor: ${({ shouldRenderDisabled }) => shouldRenderDisabled ? "default" : "pointer"};
  border: solid ${props => props.selected ? " 2px black" : "1px #DBDBDB"};
  padding-left: ${props => props.selected ? " 6px" : "7px"};

  @media screen and (max-width: 480px){
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: ${({ shouldDisplayItemStatus }) => shouldDisplayItemStatus ? "24px" : "8px"};
    min-height: ${({ shouldDisplayItemStatus }) => shouldDisplayItemStatus ? "136px" : "112px"};
    height: unset;
    outline: none;
  }

  .thumbnail {
    background-image: url(${(props) => props.imageURL});
    width: 112px;
    height: 112px;
    margin-right: 16px;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

    @media screen and (max-width: 480px){
      width: 96px;
      height: 96px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      transform: translate(-1px, -1px);
      /* IE11 polyfill */
      font-family: 'object-fit: contain;';
      opacity: ${({ shouldRenderDisabled }) => shouldRenderDisabled ? "0.5" : "1"};
    }
    svg[name="zoom"] {
      position: absolute;
      bottom: 4px;
      right: 4px;
      cursor: zoom-in;
      opacity: ${({ shouldRenderDisabled }) => shouldRenderDisabled ? "0.5" : "1"};
    }
  }

  /* Adjust the size of the item details, flex-shrink didn't work. */
  .item-details-container {
    max-width: 70%;
    min-width: 50%;

    @media (max-width: 480px) {
      min-width: 40%;
    }

    @media (max-width: 375px) {
      min-width: 30%;
    }

    @media (max-width: 300px) {
      min-width: 10%;
    }
  }

  .item-info {
    color: ${({ shouldRenderDisabled }) => shouldRenderDisabled ? "#DBDBDB" : "#737373"};

    div {
      &.item-name {
        color: ${({ shouldRenderDisabled }) => shouldRenderDisabled ? "#737373" : "#221F1F"};
      }
    }
  }

  /* includes checkbox and returning for X text */
  .item-status {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    margin-bottom: auto;
    justify-content: flex-start;
    align-items: flex-end;
    /* checkmark container */
    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin: auto 16px;
      background-color: ${props => props.selected ? "black" : "white"};
      border: 1px solid ${props => props.selected ? "black" : "#CECECE"};
      svg {
        opacity: ${props => props.selected ? 1 : 0};
      }
    }
    .deselect {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 0;
    }
    .returning-for, .option-disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -1px;
      right: -1px;
      height: 32px;
      border-radius: 12px 0 8px 0;
      background-color: black;
      color: white;
      padding: 0 16px;
      white-space: nowrap;
      font-size: 11px;
      font-weight: 800;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      &.option-disabled {
        background-color: var(--disabled-grey);
        color: var(--header-grey);
        overflow: hidden;
        & > div {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      @media (max-width: 480px) {
        width: 100%;
        right: 0px;
        bottom: 0px;
        border-radius: 0 0 8px 8px;
        height: 24px;
        text-align: center;
        max-width: unset !important;
        padding: 0 8px !important;
      }
    }
  }

  .android & {
    .item-details-container {
      padding: 16px 0;
    }
  }
`;

export {
  $InstanceButton,
}
