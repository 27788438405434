import React, { useEffect } from "react";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import useSelector from "../../utility/useTypedSelector";
import { PageProps } from "../types";
import { PageLifecycle } from "..";
import {
  reset as customerReset,
  fetchReturns,
} from "../../redux/customer/actions";
import { Alerts } from "../../types/LifeCycle";
import { BackendErrorPrefixes } from "../../types/API";
import { goToPage, showAlert, setScanPath } from "../../redux/app/actions";
import { openSwiftCameraScanner, getReturnistaJWTClaims, hasBeenReturned } from "../../utility";
import logger from "../../utility/logger";

import $ScanExpressCode from "./styles";

class ScanExpressCodeLifeCycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

const ScanExpressCode = ({ page }: PageProps) => {
  const dispatch = useDispatch();

  const { app, customer } = useSelector(store => store);

  const { token, currentPageName } = app;
  const { returns } = customer;

  const lifecycle = new ScanExpressCodeLifeCycle(page, dispatch, app);
  const claims = getReturnistaJWTClaims(token);

  // listening for swift thin client
  useEffect(() => {
    window.skipCodeScan = () => {
      lifecycle.advance("startWalkup");
    };

    window.setConfirmationCode = (confirmationCode) => {
      dispatch(fetchReturns({
        query: confirmationCode,
        onSuccess: (response, dispatch) => {
          if (response.data.returning.every(hasBeenReturned)) {
            dispatch(showAlert(Alerts.returnComplete));
            return false;
          }
          dispatch(setScanPath(currentPageName));
          return true;
        },
        onError: (error: AxiosError, dispatch) => {
          if (error?.response?.data?.includes(BackendErrorPrefixes.rmaInvalid) ||
          error?.response?.data?.includes(BackendErrorPrefixes.rmaUnassociated)) {
            dispatch(showAlert(Alerts.invalidRMA));
          } else if (error?.response?.data?.includes(BackendErrorPrefixes.locationNotAllowed)) {
            dispatch(showAlert(Alerts.locationNotAllowed));
          } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaPartialRefund)) {
            dispatch(showAlert(Alerts.partialRefundRMA));
          } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaExpiredReturn)) {
            dispatch(showAlert(Alerts.expiredReturnRMA));
          } else if (error?.response?.data?.includes(BackendErrorPrefixes.returnBarIneligible)) {
            dispatch(showAlert(Alerts.returnBarIneligible));
          }
        }
      }));

    };
    window.enterBarcodeManually = () => {
      lifecycle.advance("enterManually");
    };
    window.goBack = () => {
      dispatch(goToPage("home"));
    };
    // remove these methods from the global scope when page unmounts
    return () => {
      delete window.setConfirmationCode;
      delete window.enterCodeManually;
      delete window.goBack;
      delete window.skipCodeScan;
    };
  }, []);

  useEffect(() => {
    const allowSkip = Boolean(claims?.returnsApp?.retailerID);
    dispatch(customerReset());
    openSwiftCameraScanner("confirmationCode", window.enterBarcodeManually, allowSkip);
  }, []);

  useEffect(() => {
    // if returns are set, validate them and perhaps advance to the next page
    if (returns != undefined && !isEmpty(returns)) {
      if (!returns.returning) {
        dispatch(showAlert(Alerts.fatalError))
      }
      // at least one item has not been returned, advance to next page
      else {
        logger.Info("Return is valid, advancing pages...");
        lifecycle.advance();
      }
    }
  }, [returns]);

  return (
    <$ScanExpressCode>
    </$ScanExpressCode>
  );
};

export default ScanExpressCode;
