import { SVG, SVGName } from "../svg";
import React from "react";
import $SVGButton from "./styles";
import { handleEnterKeyPressOnClickHandlers } from "../../utility";
import { DataCyStrings } from "../../types/DataCyStrings";

interface Props {
  onClick: () => void;
  label: string;
  svg: SVGName;
  dataCyString?: DataCyStrings;
  className?: string;
  disabled?: boolean;
}

const SVGButton = ({ onClick, label, svg, dataCyString, className, disabled }: Props) => {
  return (
    <$SVGButton
      onClick={onClick}
      className={className}
      role="button"
      tabIndex={0}
      aria-label={label}
      title={label}
      data-cy={dataCyString}
      disabled={disabled}
    >
      <SVG name={svg} />
    </$SVGButton>
  );
};

export default SVGButton;
