import styled from "styled-components";

export const $BlockedMessage = styled.div`
  width: 616px;
  border-radius: 12px;
  padding: 32px;
  background: white;

  .blocked-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .express-code {
    height: 26px;
    font-family: "sofia-pro";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #221F1F;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .message {
    height: 44px;
    font-family: "sofia-pro";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #221F1F;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .addBreak::after{
    content: "\\a";
    white-space: pre;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 157px;
    height: 48px;
    border-radius: 12px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }

  svg[name="circleError"] {
      width: 40px;
      height: 40px;
      fill: none;
  }
  @media (max-width: 758px) {
    font-size: 30px;
    background: white;
    padding: 0;
    width: 100%;
    padding: 24px;

.blocked-message-container {

    gap: 12px;
  }
    .addBreak::after{
      content: none;
    }

    button {
      margin-top: 28px;
    }
  }
`;

export default $BlockedMessage;
