// third-party imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// repo imports
import TextArea from "../../components/TextArea";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { setReturnNotesSelectedInstance } from "../../redux/customer/actions";
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import logger from "../../utility/logger/logger";

// local imports
import { $ReasonNotesModal, $ReasonNotesNonModal, $ReturnReasonFooter } from "./styles";
import {
  AnalyticCategories,
  AnalyticsPageRoutes,
  getCategoryFromPageName,
  ReturnReasonNoteModalActions
} from "../../types/Analytics";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";
import PageModal from "../../components/Modal/PageModal";
import { AppRuntimes } from "../../types/AppRuntimes";
import { WrapIf } from "../../components/Wrap";
import getTranslator from "../../utility/getTranslator";
import { getAdminMode } from "../../utility";

const useTranslation = getTranslator("ReasonNotes")

class ReasonNotesLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

interface ReturnReasonFooterProps {
  runtime: string,
  reasonLabel?: string
  disabled: boolean,
  onClick: () => void
}

/**
 * Allows user to add a note to a selected instance
 */
const ReasonNotes = ({page}: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const {customer, app} = useSelector((store: RootReducer) => store);

  const {currentModalPageName, locale, copies} = useSelector((store: RootReducer) => store.app)
  const [isAdminMode] = useState<boolean>(getAdminMode());
  const retailerName = copies?.retailerName

  const {runtime} = app
  const {t} = useTranslation()
  const isReturnPortal = runtime == AppRuntimes.returnPortal

  const [notes, setNotes] = useState(customer?.selectedInstance?.reasonNote || "");
  const lifecycle = new ReasonNotesLifecycle(page, dispatch, app);

  const reasonNotesClick = () => {
    const category = getCategoryFromPageName(currentModalPageName)
    ga.event({
      category: category,
      action: ReturnReasonNoteModalActions.ReasonNotesTextBoxClicked
    });
  }

  // go back to child return reasons, if the selected reason has child reasons
  const childReason = customer.selectedInstance?.childReason
  if (page.leftHeaderLink && childReason && childReason != '') {
    page = {...page, leftHeaderLink: {
      ...page.leftHeaderLink,
      destinationPage: "returnReasonsChildren"
    }}
  }

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    });
    ga.sendPageDetails(AnalyticsPageRoutes.ReturnReasonNote, AnalyticCategories.ReturnReasonNoteModal);
  }, []);
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  /**
   * takes the value of notes from local state
   * and saves to the selectedInstance.
   */
  const handleSaveNotes = (label) => {
    dispatch(setReturnNotesSelectedInstance(notes))
    lifecycle.advance();

    const category = getCategoryFromPageName(currentModalPageName)
    if (label === t('continue')) {
      ga.event({
        category: category,
        action: ReturnReasonNoteModalActions.SaveNotesClicked
      });
    } else if (label === t('skip')) {
      ga.event({
        category: category,
        action: ReturnReasonNoteModalActions.SkipNotesClicked
      });
    }
  }
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <WrapIf
      condition={isReturnPortal}
      wrapIf={children =>
        <PageModal page={page} dataCyString={DataCyStrings.reasonNotesModal}>
          <$ReasonNotesModal>
            <div className='submsg'>
              {t('canYouTellUsMoreAbout')}
            </div>
            {children}
            <PrimaryButton
              label={notes ? t('continue') : t('skip')}
              onButtonClick={() => { handleSaveNotes(notes ? t('continue') : t('skip')) }}
              dataCyString={DataCyStrings.reasonNotesNextButton}
            />
          </$ReasonNotesModal>
        </PageModal>
      }
      wrapElse={children =>
        <$ReasonNotesNonModal>
          {children}
          <ReturnReasonFooter
            runtime={runtime}
            reasonLabel={customer?.selectedInstance?.reasonInfo?.label}
            onClick={() => { handleSaveNotes("")}}
            disabled={notes?.length === 0}
          />
        </$ReasonNotesNonModal>
      }>
      <TextArea
        value={notes}
        onClick={() => reasonNotesClick()}
        onChange={(val) => setNotes(val)}
        width="100%"
        height="94px"
        placeholder={t('addANote')}
        maxLength={140}
        dataCyString={DataCyStrings.reasonNoteTextArea}
        disableNewline
        label={t('returnReasonNotes')}
      />
    </WrapIf>
  );
  //----------------------------------------------------------------------------
}

// Since ReturnReasonFooter is only used in Returnista, we don't localize the strings
const ReturnReasonFooter = ({ runtime, reasonLabel, disabled, onClick }: ReturnReasonFooterProps) => {
  return (
    <$ReturnReasonFooter className={runtime}>
      <div className="return-reason">
        {reasonLabel &&
          <>
            <div className="selected-return-reason">
              SELECTED RETURN REASON
            </div>
            <div className="reason-label">
              {reasonLabel}
            </div>
          </>
        }
      </div>
      <PrimaryButton
        width="98px"
        label="Next Step"
        onButtonClick={onClick}
        disabled={disabled}
        dataCyString={DataCyStrings.reasonNotesNextButton}
      />
    </$ReturnReasonFooter>
  )
}

export default ReasonNotes;
