import { LoadingAction, MultiStoreAction } from "../enums";

const loadingReducer = (state = { isLoading: false, message: "" }, action) => {
  switch (action.type) {
    case LoadingAction.Set:
      return { isLoading: true, message: action.message ?? "Loading..." };
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case LoadingAction.Unset:
      return { isLoading: false, message: "" };
    default:
      return state;
  }
};

export default loadingReducer;
