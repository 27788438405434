import React from "react";
import { $Header, $HeaderLogo, $BackButton } from "./styles";
import { SVG } from "../../components/svg";
import { useDispatch, useSelector } from "react-redux";
import { CurrentPage, CurrentPageAction, ModalAction, RuntimeType } from "../../redux/enums";
import { store, RootReducer } from "../../redux/returnista-store";
import { isUPS } from "../../utility";
import ga from "../../utility/GAEmitter";
import { TopLevelActions } from "../../types/ReturnistaGA";

const noStatePushPage = [CurrentPage.Initializer, CurrentPage.Login, CurrentPage.StartReturn];

export const onBackHandler = (dispatch, fromNativeEvent = false) => {
  const { currentPage, runtime, bags, currentBag } = store.getState();
  ga.pageEvent({ category: currentPage, action: TopLevelActions.BackButton });

  // If we got a native back event and we are past the start return page any
  // attempt to go back should have a history event put ahead of it so the next
  // native back event is still going through this handler
  if (fromNativeEvent && !noStatePushPage.includes(currentPage)) {
    history.pushState(null, "");
  }

  // If we are on the bag code scan page and there are other bags in the
  // return we should go back to the return summary page
  if (currentPage === CurrentPage.BagCodeScan && bags.allIds.length !== 0) {
    dispatch({ type: CurrentPageAction.BackToSummary });
  }

  // If we are on the item code scan page and the currently selected bag is
  // already a part of the return we should go back to the return summary page
  if (currentPage === CurrentPage.ItemCodeScan && bags.allIds.includes(currentBag)) {
    dispatch({ type: CurrentPageAction.BackToSummary });
  }

  // if we are on the item code scan page after coming from the return summary page,
  // route the user back to the return summary page
  if (currentPage === CurrentPage.ItemCodeScan && bags.allIds.length !== 0 && isUPS()) {
    dispatch({ type: CurrentPageAction.BackToSummary });
  }

  switch (currentPage) {
    case CurrentPage.BagCodeScan:
    case CurrentPage.ReturnAccepted:
      if (runtime === RuntimeType.Partner) {
        window.close();
        return;
      }
    // Intentionally allow fallthrough for non-Partner runtimes
    // eslint-disable-next-line no-fallthrough
    default:
      dispatch({ type: CurrentPageAction.Back });
      return;
  }
};

export const HeaderLogo = () => {
  return (
    <$HeaderLogo>
      <SVG name="hr-logo-paypal" alt="Happy Returns Logo" />
    </$HeaderLogo>
  );
};

type HeaderBackButtonProps = {
  label?: string;
};

export const HeaderBackButton = ({ label }: HeaderBackButtonProps) => {
  const dispatch = useDispatch();
  const { modal } = useSelector<RootReducer, RootReducer>((store) => store);

  const onClick = () => {
    if (modal.isOpen) {
      dispatch({ type: ModalAction.Unset });
    }
    onBackHandler(dispatch);
  };

  const handleKeyPress = (e) => {
    if (e.code === "Enter") {
      onClick();
    }
  };

  return (
    <$BackButton data-cy="back-button" onKeyDown={handleKeyPress} tabIndex={1} onClick={onClick}>
      <SVG name="backArrow" />
      <span>{label ?? "Back"}</span>
    </$BackButton>
  );
};

type HeaderProps = {
  left?: JSX.Element | string;
  center?: JSX.Element | string;
  right?: JSX.Element | string;
};

const Header = ({ left, center, right }: HeaderProps) => {
  return (
    <$Header>
      <div data-cy="header-left">{left}</div>
      <div data-cy="header-center">{center}</div>
      <div data-cy="header-right">{right}</div>
    </$Header>
  );
};

export default Header;
