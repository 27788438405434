import React from "react";

const HRLogoPaypal = (props) => (
  <>
    <svg
      {...props}
      width="233"
      height="48"
      viewBox="0 0 233 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_526_1009)">
        <path
          d="M12.4873 28.8388C8.08552 28.6994 3.85601 27.0973 0.470312 24.2877C-0.0708879 23.8546 -0.157014 23.0652 0.27711 22.5253C0.711233 21.9854 1.50267 21.8995 2.04387 22.3326C2.08227 22.3639 2.11952 22.3964 2.15443 22.4324C2.55713 22.783 11.9845 31.0841 22.2661 22.4324C22.7922 21.9912 23.5755 22.0516 24.0259 22.5706C24.4728 23.0803 24.4344 23.8512 23.9374 24.3132C20.8008 27.1623 16.7284 28.7726 12.4861 28.8399"
          fill="black"
        />
        <path
          d="M1.77734 0H4.29131V7.0089C5.0711 5.78638 6.46309 5.0921 7.91094 5.20356C9.18771 5.09559 10.4296 5.65635 11.1919 6.68266C11.7599 7.66138 12.0113 8.79102 11.9088 9.91718V16.4118H9.39488V10.7566C9.34483 8.88855 8.85484 7.52206 6.96937 7.52206C5.0839 7.52206 4.32971 8.88855 4.32971 10.7566V16.4118H1.81459L1.77734 0Z"
          fill="black"
        />
        <path
          d="M20.7077 9.94259C22.0334 9.94259 23.1088 8.86984 23.1088 7.54747C23.1088 6.2251 22.0334 5.15234 20.7077 5.15234C19.3914 5.15234 18.3206 6.20885 18.3066 7.52193C18.3066 8.84429 19.3821 9.91705 20.7077 9.91705"
          fill="black"
        />
        <path
          d="M53.3646 19.7983H44.8416V28.5011H41.4478V7.91211H44.8416V16.7647H53.3646V7.91211H56.7457V28.5011H53.3646V19.7983Z"
          fill="black"
        />
        <path
          d="M70.4469 21.6422C70.6227 19.4189 68.9595 17.4754 66.7307 17.2989C64.5019 17.1236 62.5536 18.7826 62.3766 21.0059C62.3604 21.2172 62.3604 21.4297 62.3766 21.6422C62.2009 23.8655 63.8641 25.809 66.0929 25.9854C68.3217 26.1607 70.27 24.5017 70.4469 22.2784C70.4632 22.0671 70.4632 21.8546 70.4469 21.6422ZM73.665 14.7958V28.5001H70.4469V26.6449C69.4704 28.0462 67.8585 28.8682 66.1476 28.8391C62.2742 28.8403 59.1318 25.7091 59.1306 21.8453C59.1306 21.778 59.1306 21.7095 59.1329 21.6422C59.0154 17.7865 62.0519 14.5659 65.9171 14.4475C66.0068 14.4452 66.0952 14.444 66.1848 14.444C67.8841 14.4301 69.4798 15.2567 70.4458 16.6511V14.7958H73.6639H73.665Z"
          fill="black"
        />
        <path
          d="M80.6785 21.6421C80.4632 23.8689 82.0972 25.8483 84.3284 26.0631C86.5607 26.2779 88.5451 24.6479 88.7604 22.4223C88.786 22.1634 88.786 21.9021 88.7604 21.6421C88.8849 19.4153 87.1764 17.5101 84.9441 17.3859C82.7118 17.2617 80.8019 18.966 80.6773 21.1928C80.6692 21.3425 80.6692 21.4923 80.6773 21.6421M80.6773 16.6266C81.6422 15.238 83.232 14.4126 84.9255 14.4196C88.7918 14.3975 91.9448 17.5055 91.968 21.3623C91.968 21.447 91.968 21.5318 91.9645 21.6165C92.0961 25.471 89.0712 28.7032 85.206 28.8344C85.1128 28.8379 85.0186 28.8391 84.9255 28.8379C83.2309 28.846 81.6422 28.0206 80.6773 26.6309V35.4079H77.4592V14.7957H80.6773V16.6266Z"
          fill="black"
        />
        <path
          d="M97.1576 21.6421C96.9423 23.8689 98.5764 25.8483 100.808 26.0631C103.04 26.2779 105.024 24.6479 105.24 22.4223C105.265 22.1634 105.265 21.9021 105.24 21.6421C105.364 19.4153 103.656 17.5101 101.423 17.3859C99.1909 17.2617 97.281 18.966 97.1565 21.1928C97.1483 21.3425 97.1483 21.4923 97.1565 21.6421M97.1565 16.6266C98.1213 15.238 99.7112 14.4126 101.405 14.4196C105.278 14.3975 108.437 17.5113 108.459 21.375C108.459 21.4552 108.459 21.5364 108.456 21.6165C108.581 25.4791 105.544 28.7102 101.672 28.8356C101.583 28.8379 101.494 28.8391 101.405 28.8391C99.71 28.8472 98.1213 28.0217 97.1565 26.632V35.4091H93.9512V14.7957H97.1565V16.6266Z"
          fill="black"
        />
        <path
          d="M117.308 35.6482C115.67 35.7201 114.057 35.2244 112.746 34.2434C111.503 33.1903 110.809 31.6311 110.86 30.0057H114.103C114.14 31.6671 115.52 32.9848 117.186 32.9477C117.239 32.9465 117.293 32.9442 117.346 32.9396C119.32 32.9396 120.703 32.2372 120.703 29.6795V26.5321C119.752 28.0657 118.032 28.9516 116.228 28.8389C114.622 28.9701 113.06 28.2701 112.093 26.9837C111.387 25.7519 111.08 24.3331 111.213 22.9214V14.7701H114.444V21.8173C114.444 24.1741 115.122 25.9678 117.423 25.9678C119.724 25.9678 120.729 24.1752 120.729 21.8173V14.7329H123.947V29.6795C124.069 31.3653 123.444 33.0197 122.237 34.2062C120.846 35.2952 119.099 35.8316 117.335 35.7109L117.309 35.6482H117.308Z"
          fill="black"
        />
        <path
          d="M146.847 15.1218C146.985 12.9519 145.335 11.0804 143.16 10.941C143.09 10.9364 143.02 10.9341 142.95 10.9329H138.941V19.2967H142.95C145.13 19.2735 146.877 17.4914 146.854 15.3168C146.854 15.2518 146.85 15.1868 146.847 15.1218ZM135.534 7.91202H142.938C146.838 7.7936 150.095 10.8505 150.214 14.741C150.218 14.8675 150.219 14.9952 150.215 15.1229C150.269 18.0242 148.546 20.6667 145.866 21.794L149.964 28.5022H146.104L142.333 22.3211H138.94V28.5022H135.546L135.533 7.91318L135.534 7.91202Z"
          fill="black"
        />
        <path
          d="M154.665 20.2372H161.502C161.63 18.4969 160.319 16.983 158.574 16.8552C158.44 16.846 158.305 16.8448 158.171 16.8518C156.401 16.6892 154.835 17.9884 154.673 19.7542C154.658 19.9145 154.655 20.0758 154.665 20.2372ZM154.665 22.6196C154.816 24.5886 155.67 26.1803 158.347 26.1803C159.623 26.3475 160.84 25.5987 161.264 24.3878H164.519C164.168 26.733 161.905 28.839 158.436 28.839C153.81 28.839 151.572 25.6672 151.572 21.6292C151.572 17.5913 154.011 14.4323 158.347 14.4323C161.768 14.3347 164.62 17.0236 164.717 20.4357C164.718 20.495 164.719 20.5542 164.719 20.6134C164.73 21.2856 164.68 21.9566 164.568 22.6196H154.663H154.665Z"
          fill="black"
        />
        <path
          d="M176.21 28.5014C175.42 28.6209 174.622 28.679 173.822 28.6767C172.264 28.6767 168.92 28.4758 168.92 23.8493V17.4673H166.582V14.797H168.92V10.6465H172.578V14.797H175.733V17.4673H172.578V23.1225C172.578 25.5048 173.319 25.7928 174.589 25.7928C175.131 25.7928 175.674 25.7545 176.21 25.6802V28.5014Z"
          fill="black"
        />
        <path
          d="M191.747 14.7959V28.5002H188.529V26.5939C187.565 28.1136 185.84 28.982 184.041 28.8509C182.437 28.9855 180.877 28.2831 179.918 26.9956C179.213 25.7684 178.906 24.3543 179.038 22.9461V14.7959H182.244V21.8803C182.244 24.2371 182.922 26.0308 185.223 26.0308C187.524 26.0308 188.529 24.2382 188.529 21.8803V14.7959H191.747Z"
          fill="black"
        />
        <path
          d="M203.927 17.768C203.517 17.6577 203.093 17.6066 202.67 17.6171C199.93 17.6171 198.421 19.1716 198.421 22.6325V28.5513H195.203V14.7958H198.421V17.0029C199.321 15.5203 200.933 14.6159 202.67 14.6205C203.098 14.6043 203.525 14.6716 203.927 14.8214V17.768Z"
          fill="black"
        />
        <path
          d="M206.44 14.7957H209.646V16.702C210.609 15.1823 212.334 14.3139 214.134 14.4451C215.736 14.315 217.294 15.0163 218.256 16.3003C218.965 17.531 219.277 18.9497 219.149 20.3626V28.5128H215.931V21.4157C215.931 19.0588 215.252 17.2651 212.951 17.2651C210.65 17.2651 209.646 19.0577 209.646 21.4157V28.5H206.44V14.7957Z"
          fill="black"
        />
        <path
          d="M232.749 24.677C232.749 27.5354 230.235 28.852 227.268 28.852C224.301 28.852 221.662 27.1221 221.662 23.8365H224.791C224.791 25.6291 225.91 26.2688 227.444 26.2688C228.701 26.2688 229.681 25.8671 229.681 24.6887C229.681 23.5103 228.298 23.3094 225.407 22.1809C223.496 21.4541 222.139 20.739 222.139 18.3566C222.139 15.7734 224.49 14.3942 227.292 14.3942C229.993 14.1968 232.341 16.2204 232.539 18.9139C232.541 18.9534 232.545 18.9928 232.546 19.0335H229.416C229.533 17.9735 228.765 17.0203 227.702 16.9042C227.537 16.8856 227.369 16.8891 227.204 16.9147C226.11 16.9147 225.23 17.4534 225.23 18.4193C225.23 19.5977 226.487 19.7486 229.001 20.7134C230.924 21.453 232.772 22.1298 232.772 24.626"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.918 39.101C176.719 40.3805 175.74 40.3805 174.79 40.3805H174.258L174.638 37.9935C174.656 37.8518 174.779 37.7462 174.923 37.7473H175.179C175.816 37.7473 176.432 37.7473 176.746 38.1072C176.94 38.3998 177.002 38.7609 176.917 39.1022L176.918 39.101ZM176.5 35.7574H172.929C172.687 35.7597 172.482 35.9362 172.445 36.1742L171.002 45.3158C170.975 45.476 171.084 45.627 171.245 45.6525C171.263 45.6548 171.279 45.656 171.296 45.656H173.129C173.299 45.6537 173.444 45.5295 173.472 45.3623L173.88 42.7767C173.913 42.5341 174.12 42.353 174.364 42.3507H175.494C177.85 42.3507 179.207 41.2233 179.568 38.9687C179.755 38.1723 179.589 37.3329 179.112 36.6665C178.422 35.9861 177.467 35.6401 176.5 35.7191V35.7574Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151.438 39.101C151.239 40.3805 150.26 40.3805 149.311 40.3805H148.779L149.149 37.9935C149.176 37.8518 149.299 37.7496 149.443 37.7473H149.69C150.336 37.7473 150.944 37.7473 151.257 38.1072C151.459 38.3963 151.526 38.7597 151.437 39.1022L151.438 39.101ZM151.02 35.7574H147.45C147.208 35.7597 147.003 35.9362 146.966 36.1742L145.513 45.3251C145.486 45.4853 145.596 45.6363 145.756 45.6618C145.774 45.6641 145.79 45.6653 145.808 45.6653H147.517C147.762 45.6653 147.97 45.4888 148.011 45.2485L148.401 42.786C148.433 42.5434 148.64 42.3623 148.885 42.3599H150.015C152.371 42.3599 153.728 41.2326 154.088 38.978C154.276 38.1815 154.109 37.3421 153.632 36.6757C152.942 35.9954 151.988 35.6494 151.02 35.7284V35.7562V35.7574Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M159.283 42.3891C159.149 43.3306 158.337 44.0272 157.383 44.0179C156.951 44.0435 156.528 43.8728 156.235 43.5535C155.979 43.2087 155.881 42.771 155.969 42.3507C156.099 41.4057 156.912 40.7033 157.869 40.7114C158.299 40.6882 158.717 40.8589 159.008 41.1758C159.276 41.5195 159.376 41.963 159.284 42.3879L159.283 42.3891ZM161.667 39.064H159.957C159.81 39.0582 159.682 39.165 159.662 39.3101L159.587 39.7931L159.463 39.6224C158.937 39.0826 158.19 38.8167 157.441 38.9026C155.499 38.9525 153.885 40.4096 153.642 42.3322C153.458 43.2575 153.691 44.2165 154.278 44.956C154.826 45.5586 155.62 45.8802 156.434 45.8279C157.321 45.8453 158.177 45.5028 158.808 44.8805L158.732 45.3542C158.706 45.5144 158.815 45.6654 158.976 45.6909C158.99 45.6932 159.004 45.6944 159.018 45.6944H160.565C160.808 45.6921 161.012 45.5156 161.05 45.2776L161.999 39.4332C162.026 39.273 161.917 39.122 161.756 39.0965C161.739 39.0942 161.722 39.093 161.705 39.093L161.667 39.0651V39.064Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.8 42.3891C184.666 43.3306 183.853 44.0272 182.9 44.0179C182.466 44.0481 182.042 43.8763 181.751 43.5535C181.487 43.2122 181.386 42.7722 181.476 42.3507C181.605 41.4057 182.418 40.7033 183.375 40.7114C183.806 40.6882 184.223 40.8589 184.514 41.1758C184.788 41.516 184.896 41.9607 184.809 42.3879H184.8V42.3891ZM187.183 39.064H185.473C185.327 39.0582 185.199 39.165 185.179 39.3101L185.103 39.7931L184.98 39.6224C184.454 39.0826 183.707 38.8167 182.957 38.9026C181.016 38.9525 179.402 40.4096 179.158 42.3322C178.98 43.2575 179.212 44.2141 179.795 44.956C180.343 45.5586 181.137 45.8802 181.95 45.8279C182.837 45.8453 183.694 45.5028 184.325 44.8805L184.249 45.3542C184.222 45.5144 184.332 45.6654 184.492 45.6909C184.51 45.6932 184.526 45.6944 184.544 45.6944H186.082C186.324 45.6921 186.529 45.5156 186.566 45.2776L187.516 39.4332C187.543 39.273 187.433 39.122 187.273 39.0965C187.255 39.0942 187.239 39.093 187.222 39.093L187.183 39.0651V39.064Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.812 39.064H169.093C168.93 39.0674 168.779 39.1487 168.685 39.2822L166.31 42.7687L165.303 39.4157C165.241 39.2068 165.047 39.064 164.829 39.0651H163.139C162.976 39.0674 162.845 39.1998 162.848 39.3623C162.848 39.3937 162.854 39.4239 162.864 39.4541L164.763 45.0048L162.978 47.5055C162.884 47.6437 162.92 47.8329 163.058 47.927C163.107 47.9606 163.165 47.9781 163.225 47.9792H164.981C165.144 47.9757 165.295 47.8945 165.39 47.7609L171.088 39.5295C171.182 39.3972 171.152 39.2137 171.019 39.1197C170.967 39.0825 170.905 39.064 170.841 39.0651"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M189.197 36.0127L187.772 45.325C187.745 45.4853 187.855 45.6362 188.015 45.6617C188.033 45.664 188.049 45.6652 188.067 45.6652H189.539C189.783 45.6652 189.992 45.4887 190.032 45.2484L191.476 36.1068C191.502 35.9466 191.393 35.7956 191.232 35.7701C191.215 35.7678 191.199 35.7666 191.181 35.7666H189.528C189.384 35.7689 189.261 35.8711 189.234 36.0127"
          fill="black"
        />
        <path
          d="M195.884 42.9312C195.884 44.2153 196.833 44.9456 198.035 44.9456C198.561 44.9456 199.021 44.8701 199.528 44.6553L199.378 45.5829C198.88 45.7989 198.43 45.8639 197.904 45.8639C196.147 45.8639 194.851 44.7772 194.851 42.9963C194.851 40.6534 196.729 39.0977 198.824 39.0977C199.426 39.0977 199.932 39.201 200.365 39.4355L200.215 40.3817C199.793 40.1193 199.313 40.0067 198.815 40.0067C197.237 40.0067 195.884 41.1213 195.884 42.9301V42.9312Z"
          fill="black"
        />
        <path
          d="M204.495 43.0334C204.495 44.5508 203.387 45.8628 201.921 45.8628C200.795 45.8628 199.854 45.0663 199.854 43.6789C199.854 42.1615 200.953 40.8496 202.418 40.8496C203.545 40.8496 204.495 41.646 204.495 43.0334ZM200.803 43.6615C200.803 44.5137 201.264 45.0292 201.986 45.0292C202.879 45.0292 203.537 44.1294 203.537 43.052C203.537 42.1998 203.086 41.6844 202.353 41.6844C201.47 41.6844 200.803 42.5841 200.803 43.6615Z"
          fill="black"
        />
        <path
          d="M204.655 45.7604L205.407 41.0468L206.393 40.8877L206.308 41.4311C206.656 41.1687 207.275 40.8506 207.951 40.8506C208.544 40.8506 208.948 41.0851 209.144 41.5065C209.557 41.2163 210.328 40.8506 211.032 40.8506C211.906 40.8506 212.375 41.3289 212.375 42.1718C212.375 42.303 212.366 42.462 212.338 42.6118L211.84 45.7604H210.873L211.352 42.7244C211.371 42.6118 211.38 42.4992 211.38 42.4063C211.38 41.957 211.173 41.6946 210.6 41.6946C210.111 41.6946 209.642 41.8908 209.267 42.1346V42.1718C209.267 42.303 209.257 42.462 209.229 42.6118L208.731 45.7604H207.764L208.243 42.7244C208.262 42.6118 208.271 42.4992 208.271 42.4063C208.271 41.957 208.064 41.6946 207.492 41.6946C206.993 41.6946 206.599 41.8537 206.204 42.0975L205.622 45.7616H204.655V45.7604Z"
          fill="black"
        />
        <path
          d="M212.359 47.8966L213.448 41.0468L214.434 40.8877L214.349 41.4404C214.668 41.178 215.214 40.8506 215.852 40.8506C216.904 40.8506 217.589 41.5913 217.589 42.8278C217.589 44.589 216.274 45.8637 214.79 45.8637C214.368 45.8637 213.964 45.7697 213.691 45.6106L213.325 47.8966H212.358H212.359ZM213.815 44.8421C214.041 45.0104 214.435 45.0952 214.726 45.0952C215.825 45.0952 216.595 44.1304 216.595 42.9113C216.595 42.1521 216.191 41.6842 215.478 41.6842C214.998 41.6842 214.557 41.8711 214.248 42.0963L213.816 44.8421H213.815Z"
          fill="black"
        />
        <path
          d="M222.644 40.897L221.883 45.6571L220.897 45.7976L220.982 45.2635C220.682 45.5445 220.165 45.8637 219.563 45.8637C218.539 45.8637 217.769 45.123 217.769 43.8866C217.769 42.1161 219.102 40.8506 220.577 40.8506C221.018 40.8506 221.451 40.9725 221.751 41.1594L222.644 40.897ZM221.517 41.8711C221.282 41.7028 220.945 41.6273 220.643 41.6273C219.563 41.6273 218.765 42.5828 218.765 43.8007C218.765 44.5785 219.226 45.0464 219.893 45.0464C220.363 45.0464 220.776 44.8502 221.086 44.6157L221.517 41.8699V41.8711Z"
          fill="black"
        />
        <path
          d="M224.528 40.8877L224.443 41.4404C224.81 41.178 225.449 40.8506 226.153 40.8506C227.064 40.8506 227.543 41.3289 227.543 42.1811C227.543 42.3123 227.533 42.462 227.515 42.6118L227.017 45.7604H226.04L226.52 42.7244C226.538 42.6211 226.548 42.5178 226.548 42.4249C226.548 41.9663 226.322 41.6935 225.731 41.6935C225.186 41.6935 224.754 41.8525 224.341 42.0963L223.759 45.7604H222.792L223.544 41.0468L224.529 40.8877H224.528Z"
          fill="black"
        />
        <path
          d="M227.815 40.9543H228.801L229.618 44.4024L231.694 40.9543H232.68L229.703 45.7143C228.697 47.3258 227.947 48.0003 227.017 48.0003C226.716 48.0003 226.453 47.9353 226.283 47.8227L226.406 47.0356C226.574 47.1482 226.8 47.2039 226.997 47.2039C227.57 47.2039 228.114 46.801 228.979 45.4044L227.814 40.9531L227.815 40.9543Z"
          fill="black"
        />
        <path
          d="M139.934 43.6336H137.481L136.431 45.6166H135.533L139.009 39.1011H139.816L141.235 45.6166H140.353L139.932 43.6336H139.934ZM137.901 42.8244H139.766L139.236 40.3015L137.901 42.8244Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_526_1009">
          <rect width="232.774" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default HRLogoPaypal;
