import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../globalConstants";

import { $DefaultPrivacyFooter, $GermanPrivacyFooter } from "../../components/PrivacyFooter/styles";
import { $ReturnStatusErrorCard } from "./ReturnStatusErrorCard/styles";

export const $ReturnStatus = styled.div`
  background: var(--background-white);

  display: flex;
  flex-direction: column;
  width: 100%;

  ${$DefaultPrivacyFooter}, ${$GermanPrivacyFooter} {
    margin-top: auto;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    :has(${$ReturnStatusErrorCard}) {
      background: #fff;
    }
  }
`;

export const $ReturnStatusCardContainer = styled.div`
  background: var(--background-white);
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    :has(${$ReturnStatusErrorCard}) {
      background: #fff;
    }
  }
`;

export const $ReturnStatusLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 32px;
  padding-left: 20px;
  width: 636px;
`;

export const $ReturnStatusRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 32px;
  margin-right: auto;
  padding-right: 20px;
  width: 420px;
`;

export const $ReturnStatusSingleColumn = styled.div`
  background: var(--background-white);

  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  width: 616px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 0;
    margin-bottom: 8px;
    width: 100%;

    :has(${$ReturnStatusErrorCard}) {
      background: #fff;
    }
  }
`;
