import styled, { css } from "styled-components";
import $NavigationCard from "../../components/NavigationCard/styles";

const $ReturnOptionsStyles = css`
  button.show-all-options {
    width: fit-content;
    height: 48px;
    padding: 0px 16px;
    margin-top: 6px;
    background: white;
    border: 1px solid #A2A2A2;
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;
    font-family: "sofia-pro",sans-serif;
    font-size: 15px;
    line-height: 16px;
    color: var(--text-black);
  }
`;

const $ReturnOptions = styled.div`
  ${$ReturnOptionsStyles}
  width: 100%;
  padding: 8px 24px 0 24px;
  & > div {
    margin: 16px 0;
  }
`;

export const $ReturnOptionsModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${$ReturnOptionsStyles}
  padding: 24px;

  /* navigation cards */
  & > ${$NavigationCard} {
    margin: 6px 0;
    min-height: 80px;
    & > .title {
      font-weight: normal;
    }
  }
  ${$NavigationCard}:first-child {
    border: 2px solid var(--text-black);
    padding: 0 15px;
    & > .title {
      font-weight: 800;
    }
  }
  @media (max-width: 768px) {
    padding: 16px;
  }
  .title {
    font-size: 15px;
    line-height: 22px;
  }
  .subtext {
    font-size: 14px;
    line-height: 20px;
  }
`;

export default $ReturnOptions
