import React, { Fragment, useMemo, useCallback, useState, useEffect } from "react";
import useSelector from "../../../utility/useTypedSelector";
import { useDispatch } from "react-redux";
import { getConfigurationAndInitialize } from "../../../redux/app/actions";
import { supportedCountryLocales, localeInsensitiveSearch } from "../../../i18n";
import { handleEnterKeyPressOnClickHandlers } from "../../../utility";

import {
  $LanguageModal
} from "./styles"
import PageModal from "../PageModal";
import { SVG } from "../../svg";
import SearchField from "../../../components/SearchField";
import getTranslator from "../../../utility/getTranslator";
import { DataCyStrings } from "../../../types/DataCyStrings";

const useTranslation = getTranslator("LanguageModal");

const LanguageItem = ({ localeCode, localeName, isActive, onPick }) => {
  return (<>
    <a key={localeCode} className={`lineitem${isActive ? ' active' : ''}`} aria-disabled={isActive} data-cy={`${localeCode}-language-button`} {...handleEnterKeyPressOnClickHandlers(() => onPick(localeCode))}>
      {localeName}
    </a>
  </>)
}

const LanguageModal = ({ locales, isOpen, onClose, onPick }) => {
  const { t } = useTranslation()
  const { locale: currentLocale } = useSelector(store => store.app)
  const [searchLanguage, setSearchLanguage] = useState("")

  useEffect(() => {
    setSearchLanguage("")
  }, [isOpen])

  // we use localeCompare here to make sure we find:
  // - cais in Français
  // - espan in Español
  // - etc..
  const filteredLocales = useMemo(() =>
    localeInsensitiveSearch(locales, searchLanguage)
    , [searchLanguage, currentLocale])


  const localeElements = useMemo(() => filteredLocales.map(([localeCode, localeName]) =>
    <LanguageItem
      key={localeCode}
      localeCode={localeCode}
      localeName={localeName}
      isActive={localeCode == currentLocale}
      onPick={onPick}
    />
  ), [filteredLocales, currentLocale, onPick])

  return (
    <PageModal page={{ type: "modal" }} isOpen={isOpen} noHeader={true} children={
      <$LanguageModal data-cy={DataCyStrings.languageSelectionModal}>
        <header data-cy={DataCyStrings.languageSelectionModalHeader}>{t('selectYourPreferred')}</header>
        <div className="lineitem">
          <SearchField value={searchLanguage} onSubmit={() => { }} onChange={setSearchLanguage} onClear={() => setSearchLanguage("")} inputDataCyString={DataCyStrings.languageSelectionInputField}></SearchField>
        </div>
        {localeElements.length
          ? localeElements
          : <div className="nonefound" data-cy={DataCyStrings.noMatchFound}>{t('noMatchFound')}</div>}
      </$LanguageModal>
    } onClose={onClose} />
  );
}

export default LanguageModal;
