import styled from "styled-components";

const $ReturnType = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;

  & > div {
    margin-bottom: 16px;
  }

  .email-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-header {
      font-size: 24px;
      line-height: 28px;
      color: #0B0B0B;
      margin-bottom: 16px;
    }
    form {
      width: 552px;
      position: relative;
      input {
        border: 1px solid var(--border-card);
        box-sizing: border-box;
        border-radius: 12px;
        height: 56px;
      }
      button {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 110px;
        height: 40px;
        border-radius: 12px;
        background-color: black;
        color: white;
        border: none;
        &:disabled {
          color: var(--header-grey);
          background-color: var(--disabled-grey);
        }
      }
    }
  }
`;

export default $ReturnType;