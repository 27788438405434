import React from "react";

const Exchange = (props) => (
  <>
    <svg
      {...props}
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7174 1.06478C19.0959 0.662546 19.7287 0.643276 20.131 1.02173C20.5332 1.40019 20.5525 2.03306 20.174 2.43529L15.3348 7.57849H26.7273C27.2795 7.57849 27.7273 8.0262 27.7273 8.57849C27.7273 9.13077 27.2795 9.57849 26.7273 9.57849H15.3878L20.1627 14.4896C20.5477 14.8856 20.5388 15.5187 20.1428 15.9036C19.7468 16.2886 19.1137 16.2797 18.7287 15.8838L12.3038 9.27558C11.9309 8.89201 11.9259 8.28286 12.2925 7.89323L18.7174 1.06478ZM9.28256 10.1281C8.9041 9.72585 8.27123 9.70658 7.869 10.085C7.46677 10.4635 7.4475 11.0964 7.82596 11.4986L12.6652 16.6418H1.27271C0.72042 16.6418 0.272705 17.0895 0.272705 17.6418C0.272705 18.1941 0.72042 18.6418 1.27271 18.6418H12.6121L7.83728 23.5529C7.45228 23.9489 7.46118 24.582 7.85716 24.967C8.25314 25.3519 8.88624 25.343 9.27124 24.9471L15.6961 18.3389C16.0691 17.9553 16.0741 17.3462 15.7075 16.9565L9.28256 10.1281Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Exchange;
