import React, { useState, useEffect, useRef } from "react";
import { isIE } from "react-device-detect";
import Button from "../Button";
import useOnClickOutside from "../../utility/useOutSideClick";
import { ScanBagsTextFieldProps } from "./type";
import {
  $TextFieldWithButton
}
from "./styles";
import { DataCyStrings } from "../../types/DataCyStrings";

function ScanBagsTextField({callback}:ScanBagsTextFieldProps) {
  const [isFocused, setIsFocused] = useState(true);
  const [text, setText] = useState("");
  const textInputRef =  useRef<any>(null);
  
  // detect when you click outside of the input to unfocus element
  // this is used to detect focus to change placeHolder text
  useOnClickOutside(textInputRef, () => {
    setIsFocused(false);
  });

  // focus on input when page load
  useEffect(() => {
    if (isFocused) {
      textInputRef.current?.focus && textInputRef.current.focus();
    }
  })
  
  const submitLogic = () => {
    // do nothing if text is empty
    if (text.length > 0) {
      callback(text);
      setText("");
      setIsFocused(true);
    }
    return;
  };

  const onSubmit = (e) => {
    e && e.preventDefault();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    submitLogic();
  };

  const onChange = (e) => {
    setText(e.target.value.toUpperCase());
  };

  return (
    <$TextFieldWithButton onSubmit={onSubmit}>
        <input
          ref={textInputRef}
          className="focus-within"
          type="search"
          placeholder={"Scan or enter code"}
          value={text}
          onChange={onChange}
          onClick={() => setIsFocused(true)}
        />
    </$TextFieldWithButton>
  )
}

interface BarcodeTextFieldProps {
  key: string,
  value: string,
  placeholder: string,
  shouldFocus: boolean,
  onChange: (val: string) => void,
  onSubmit: () => void,
  onFocus: () => void,
  dataCyString?: DataCyStrings
}

export const BarcodeTextField = ({ value, placeholder, shouldFocus, onChange, onSubmit, onFocus, dataCyString }: BarcodeTextFieldProps) => {
  const textInputRef =  useRef<any>(null);

  // focus on input when page load
  useEffect(() => {
    if (shouldFocus) {
      textInputRef?.current?.focus?.();
    }
  }, [shouldFocus])
  
  const submitLogic = () => {
    // do nothing if text is empty
    if (value.length > 0) {
      onSubmit();
    }
    return;
  };

  const onInputSubmit = (e) => {
    e && e.preventDefault();
    submitLogic();
  };

  return (
    <$TextFieldWithButton onSubmit={onInputSubmit}>
      <input
        ref={textInputRef}
        className="focus-within"
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => onFocus()}
        data-cy={dataCyString}
      />
    </$TextFieldWithButton>
  )
}

export default ScanBagsTextField;