import React from "react";
import { SVG } from "../svg";

import { $Alert } from "./styles";
import { ReturnistaAlertMessageProps } from "./types";

const ReturnistaAlertMessage = ({ message, dataCyString, type = "info", className }: ReturnistaAlertMessageProps) => {
  const colors = {
    info: "var(--info-background)",
    error: "var(--warning-soft)",
    success: "var(--success-soft)",
    message: "var(--soft-yellow)",
  };
  const textColors = {
    info: "var(--info-text)",
    error: "var(--input-error)",
    success: "var(--dark-green)",
    message: "var(--text-black)",
  };
  return (
    <$Alert color={colors[type]} textColor={textColors[type] || textColors.info} data-cy={dataCyString} className={className}>
      {type === "error" && (
        <div className="icon">
          <SVG name="exclamation" />
        </div>
      )}
      {type === "info" && <SVG fill="#2e6797" name="returnistaMegaphone" />}
      {type === "success" && <SVG fill="var(--dark-green)" name="returnistaCircleCheck" />}
      {(message instanceof Array) ? <p className="newline">{message.join("\n")}</p> : <p>{message}</p>}
    </$Alert>
  );
};

export default ReturnistaAlertMessage;
