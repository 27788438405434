import { FatalErrorAction, MultiStoreAction } from "../enums";

const fatalErrorReducer = (state = null, action) => {
  switch (action.type) {
    case FatalErrorAction.Set:
      return action.reason;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case FatalErrorAction.Unset:
      return null;
    default:
      return state;
  }
};

export default fatalErrorReducer;
