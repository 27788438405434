import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 16px 0px 0px;
  flex-direction: column;
  @media (min-width: ${tabletOrLargerDevices}) {
    padding: 40px 0px 0px;
  }
  @media (max-width: ${tabletOrLargerDevices}) {
    justify-content: space-between;
  }
`;

export const $Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 534px;
  margin: 72px 24px 32px;
  @media (min-width: ${tabletOrLargerDevices}) {
    margin: 0 24px 32px;
  }
  .feedback {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
    gap: 24px;
    @media (max-width: ${tabletOrLargerDevices}) {
      gap: 16px;
      margin-bottom: 24px;
    }
    p {
      color: var(--dark-green);
      font-size: 21px;
      line-height: 32px;
      text-align: center;
      width: 100%;
      margin: 0px;
    }
    &:after {
      background: var(--medium-grey);
      content: "";
      display: inline-flex;
      height: 1px;
      width: 80px;
    }
  }
  img {
    margin: 8px 16px;
    max-height: 348px;
    max-width: 312px;
    @media (min-width: ${tabletOrLargerDevices}) {
      margin: 0;
      max-width: 348px;
    }
  }
  .hint {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin: 0 0 32px 0;
    p {
      margin: 0px;
      span {
        font-size: 17px;
        display: inline-block;
        margin-bottom: 8px;
      }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:last-of-type > div {
        margin-bottom: 0;
    }

  }

  .text-center {
    text-align: center;
    font-size: 21px;
  }
  .coupon-reminder {
    border-radius: 8px;
    background: rgba(255, 222, 0, 0.5);
    font-size: 13px;
    margin: 16px;
    padding: 8px;
    @media (min-width: ${tabletOrLargerDevices}) {
      margin: 0 0 24px 0;
      width: 100%;
    }
  }
`;

export const $BottomButton = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 534px;
  width: 100%;
  margin-bottom: 46px;
  @media (max-width: ${tabletOrLargerDevices}) {
    position: sticky;
    bottom: 0;
    padding: 16px 24px;
    background: var(--primary-white);
    box-shadow: 1px 2px 12px 0px #221f1f29;
    margin-bottom: 0px;
  }
  .subtext {
    margin-top: 24px;
    align-self: center;
    .print-again {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const $IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const $RequestSupportButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  @media (min-width: ${tabletOrLargerDevices}) {
    flex-direction: row;
  }
  button {
    width: 100%;
    margin-bottom: 16px;
    @media (min-width: ${tabletOrLargerDevices}) {
      width: 190px;
      margin-bottom: 0;
      margin-right: 24px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  & > .requestSupportLink {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
  }
`;