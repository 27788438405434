import React from "react";

const Shirt = (props) => (
  <>
    <svg {...props} width="46.67" height="50" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7398 1.20996L12.0098 3.25996L17.1998 12.65L20.6598 9.19996L13.7398 1.20996Z"
        fill="white"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.12 4.00977C12.12 4.00977 6.42 6.35977 4.78 7.35977C2.84 8.54977 1.11 11.1398 1.11 14.1598C1.11 19.8798 1 36.9498 1 36.9498H6.07"
        fill="white"
      />
      <path
        d="M12.12 4.00977C12.12 4.00977 6.42 6.35977 4.78 7.35977C2.84 8.54977 1.11 11.1398 1.11 14.1598C1.11 19.8798 1 36.9498 1 36.9498H6.07"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.55 9.2002V39.6202C20.55 41.5902 19.28 43.1302 16.92 43.1302H6.70996V18.2802"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3905 1.20996L29.1205 3.25996L23.9305 12.65L20.4805 9.19996L27.3905 1.20996Z"
        fill="white"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0098 4.00977C29.0098 4.00977 34.7098 6.35977 36.3498 7.35977C38.2898 8.54977 40.0198 11.1398 40.0198 14.1598C40.0198 19.8798 40.1298 36.9498 40.1298 36.9498H35.0598"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5898 9.2002V39.6202C20.5898 41.5902 21.8598 43.1302 24.2198 43.1302H34.4198V18.2802"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27.3902 1.20996H20.9102H20.2202H13.7402" fill="white" />
      <path
        d="M27.3902 1.20996H20.9102H20.2202H13.7402"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9199 16.2998C22.9099 16.4198 22.8799 18.1098 22.8799 18.2298V19.9698C22.8799 21.5898 24.2099 22.9198 25.8299 22.9198H28.4899C30.1099 22.9198 31.4399 21.5898 31.4399 19.9698V18.2298C31.4399 18.1098 31.4199 16.4198 31.3999 16.2998H22.9099H22.9199Z"
        fill="white"
        stroke="#221F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23 18.4297H31" stroke="#221F1F" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </>
);

export default Shirt;
