import { getReturnistaJWTClaims } from ".";

export enum ReturnistaTimerResult {
  TokenExpired = "TOKEN_EXPIRED",
}

// A singleton function intended to handle the Returnista timeout logic
class ReturnistaTimer {
  sessionTimeoutId: number;
  sessionDeadline: number | null;
  promiseResolver: (ReturnistaTimerResult) => void;
  hadTimeoutError: boolean;

  constructor() {
    this.sessionTimeoutId = -1;
    this.sessionDeadline = null;
    this.promiseResolver = () => {};
    this.hadTimeoutError = false;

    // Set up window handlers so tests can access these methods
    window.triggerTokenExpiration = this.triggerTokenExpiration;
    document.addEventListener("visibilitychange", () => {
      this.checkDeadlines();
    });
  }

  /**
   * init
   *
   * Initialization for the ReturnistaTimer, it sets up timers to keep track of inactivity
   * and the time-to-live that was set in the user token
   *
   * @param   token: A JWT representing the user token, it should be encoded with a
   *          TTL that serves as the hard expiration for the app
   * @returns Promise<ReturnistaTimerResult> a promise that returns a ReturnistaTimerResult
   *          that describes why a timeout has been triggered (timeout or token expiry) and
   *          allows the caller to handle these timeouts via `.then`
   */
  init = (token: string) => {
    this.hadTimeoutError = false;
    // Set up the time to live timer
    this.refresh(token);

    // If the token is already expired we can immediately resolve
    if (this.sessionTimeoutId === -1) {
      this.hadTimeoutError = true;
      this.clearTimeout();
      return Promise.resolve(ReturnistaTimerResult.TokenExpired);
    }

    return new Promise((resolve) => {
      this.promiseResolver = resolve;
    });
  };

  /**
   * refresh
   *
   * Ingests a token which can redefine the timer's session timer
   *
   * @param   token: A JWT representing the user token, it should be encoded with a
   *          TTL that serves as the hard expiration for the app
   */
  refresh = (token: string) => {
    const claims = getReturnistaJWTClaims(token);
    const expiration = parseInt(claims.exp ?? "0");
    // Convert expiration from seconds to millis
    const ttl = expiration * 1000;
    const delta = ttl - Date.now();

    // if ttl is before .now we should automatically trigger token expiration
    if (delta <= 0) {
      this.sessionTimeoutId = -1;
      return;
    }

    // Clear any existing timeout and replace it with a new one based on the new delta
    window.clearTimeout(this.sessionTimeoutId);
    this.sessionTimeoutId = window.setTimeout(this.triggerTokenExpiration, delta);
    this.sessionDeadline = ttl;
  };

  /**
   * clear
   *
   * Clears out the timeouts and prepares the timer for reuse.
   */
  clear = () => {
    this.clearTimeout();
    this.promiseResolver = () => {};
  };

  checkDeadlines = () => {
    const now = Date.now();

    if (this.sessionDeadline == null || this.sessionDeadline <= now) {
      this.triggerTokenExpiration();
      return;
    }

    window.clearTimeout(this.sessionTimeoutId);
    this.sessionTimeoutId = window.setTimeout(this.triggerTokenExpiration, this.sessionDeadline - now);
  };

  experiencedTimeout = (): boolean => {
    return this.hadTimeoutError;
  };

  triggerTokenExpiration = () => {
    this.hadTimeoutError = true;
    this.clearTimeout();
    this.promiseResolver(ReturnistaTimerResult.TokenExpired);
  };

  clearTimeout = () => {
    window.clearTimeout(this.sessionTimeoutId);
    this.sessionTimeoutId = -1;
    this.sessionDeadline = null;
  };
}

export default new ReturnistaTimer();
