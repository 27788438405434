// Frontend logger for use on any page

import { LogClient, LogFunction } from "./logClient";
import { DatadogLogClient } from "./datadogLogClient";

// Intended for sole-usage by `Logger`
interface LoggerInterface {
  Debug: (message: string, messageContext?: object) => void;
  Error: (message: string, messageContext?: object) => void;
  Info: (message: string, messageContext?: object) => void;
  SetGlobalContextProperty: (key: string, value: any) => void;
  Warning: (message: string, messageContext?: object) => void;
}

class Logger implements LoggerInterface {
  logClient: LogClient;

  constructor(logClient: LogClient) {
    this.logClient = logClient;

    // Bind to set values regardless of how these functions are used
    this.Debug = this.Debug.bind(this);
    this.Error = this.Error.bind(this);
    this.Info = this.Info.bind(this);
    this.Warning = this.Warning.bind(this);
    this.log = this.log.bind(this);
    this.SetGlobalContextProperty = this.SetGlobalContextProperty.bind(this);
  }

  Debug(message: string, messageContext?: object): void {
    this.log((message, messageContext) => this.logClient.Debug(message, messageContext), message, messageContext);
  }

  Error(message: string, messageContext?: object): void {
    this.log((message, messageContext) => this.logClient.Error(message, messageContext), message, messageContext);
  }

  Info(message: string, messageContext?: object): void {
    this.log((message, messageContext) => this.logClient.Info(message, messageContext), message, messageContext);
  }

  Warning(message: string, messageContext?: object): void {
    this.log((message, messageContext) => this.logClient.Warning(message, messageContext), message, messageContext);
  }

  log = (logFunction: LogFunction, message: string, messageContext?: object) => {
    logFunction(message, messageContext);
  };

  SetGlobalContextProperty(key: string, value: any) {
    this.logClient.SetGlobalContextProperty(key, value);
  }
}

export default new Logger(DatadogLogClient);
