import React, { FunctionComponent, useRef } from "react";
import { $Button } from "./styles";
import { I$ButtonProps } from "./types";

const Button: FunctionComponent<I$ButtonProps> = ({
  text,
  height,
  width,
  disabled,
  disabledFontSize,
  fontSize,
  fontWeight,
  fontColor,
  bgColor,
  borderRadius,
  borderColor,
  hoverBgColor,
  hoverBorderColor,
  hoverFontColor,
  hoverFontSize,
  activeBgColor,
  activeBorder,
  activeFontColor,
  activeFontSize,
  onButtonClick,
  isActive,
  children,
  dataCyString,
  border
}) => {
  const wrapperRef = useRef(null);

  const handleButtonClick = (e) => {
    onButtonClick && onButtonClick();
  };

  return (
    <$Button
      ref={wrapperRef}
      disabled={disabled}
      disabledFontSize={disabledFontSize}
      isActive={isActive}
      onClick={handleButtonClick}
      height={height}
      width={width}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontColor={fontColor}
      bgColor={bgColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      hoverBgColor={hoverBgColor}
      hoverBorderColor={hoverBorderColor}
      hoverFontColor={hoverFontColor}
      hoverFontSize={hoverFontSize}
      activeBgColor={activeBgColor}
      activeBorder={activeBorder}
      activeFontColor={activeFontColor}
      activeFontSize={activeFontSize}
      data-cy={dataCyString}
      border={border}
    >
      {children ? children : text }
    </$Button>
  );
};

export default Button;
