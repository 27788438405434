import styled from "styled-components";
import { $Button } from "../Button/styles";

const FlexCenter = `
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
`;

export const $Container = styled.div`
  ${FlexCenter}
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    line-height: 40px;
    font-weight: 500;
    font-size: 34px;
    text-align: center;
    margin: 0 0 8px;
  }
  h2 {
    font-size: 20px !important;
    font-weight: 200 !important;
    line-height: 24px !important;
    color: var(--medium-emphasis-black);
    text-align: center;
    margin: 0px auto 24px !important;
  }
  .error-icon {
    height: 56px;
    width: 56px;
    background: var(--warning-soft);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }
  .error-message {
    margin-bottom: 24px;
  }
  ${$Button} {
    margin-bottom: 48px;
    padding: 16px;
    height: unset;
  }
  .questions {
    font-size: 16px !important;
    color: var(--text-black);
  }
  .link:visited {
    color: inherit;
  }
`;
