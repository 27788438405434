import axios from "axios"
import logger from "./logger/logger"

const sessionStorageLocalBundleKey = 'localBundleHash'

// getUpdatedBundleHash returns the updated javascript bundle hash from the server 
const getUpdatedBundleHash = async (locationID: string) => {
    const bundleHashEndpoint = '/javascriptbundlehash'
    try {
        const response = await axios.get(bundleHashEndpoint)
        return response.data
    } catch (error) {
        logger.Error(`locationID: ${locationID}. Error calling ${bundleHashEndpoint} endpoint: ${error}`)
        return ''
    }
  }

/* localBundleIsUpdated verifies if the local app is updated
   It uses session storage to store the local bundle hash to avoid verifying many times
    and to avoid infinite reloads
*/
const localBundleIsUpdated = (localBundleHash: string | null, updatedBundleHash: string, locationID: string) => {
    try {

        //if the app is updated return immediately
        if (localBundleHash === updatedBundleHash) {
            return true
        }

        sessionStorage.setItem(sessionStorageLocalBundleKey, updatedBundleHash)

        const filename = `returnsapp.${updatedBundleHash}.js`
        const scriptTags = document.querySelectorAll('script');

        const isUpdated = Array.from(scriptTags).some((script) => script.src.includes(filename))
        return isUpdated

    } catch (error) {
        logger.Error(`locationID: ${locationID}. Error to verify local javascript bundle hash: ${error}`)
        return false
    }
}

// updateLocalBundle reloads the app if it is not updated
export const updateLocalBundle = (locationID: string) => {
    try {
        getUpdatedBundleHash(locationID)
            .then((updatedBundleHash) => {
                
                // if the endpoint couldn't retrieve the bundle hash for some reason just return immediately
                if (updatedBundleHash === '') {
                    return
                }

                const localBundleHash = sessionStorage.getItem(sessionStorageLocalBundleKey)
                if (localBundleIsUpdated(localBundleHash, updatedBundleHash, locationID)) {
                    return
                }

                let logMessage = `locationID: ${locationID}. Updating local javascript bundle hash to ${updatedBundleHash}`
                if (localBundleHash) {
                    logMessage = `locationID: ${locationID}. Updating local javascript bundle hash from ${localBundleHash} to ${updatedBundleHash}`
                }

                logger.Warning(logMessage)
                
                // keeping this code commented to see the logs and observe the behavior before starting to reload the app
                // window.location.reload()
            })

    } catch (error) {
        logger.Error(`locationID: ${locationID}. Error to update local javascript bundle hash: ${error}`)
    }
}
