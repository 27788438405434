// third-party imports
import React from "react";

// repo imports
import { PageProps } from "../types";
import { FinalError } from "../../components/Final";
import getTranslator from "../../utility/getTranslator";

const useTranslation = getTranslator("NoReturnOptions")

/**
 * page used when an instance has no associated return options when starting a return from scratch
 * 
 * this page was created separately in order to ensure that the user 
 * is able to fall back to a valid page based on the runtime config
 */
const NoReturnOptions = ({ page }: PageProps) => {
  const {t} = useTranslation()
  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <FinalError 
      primaryMessage={t('noReturnOptionsAre')}
      subMessages={[t('pleaseContactHappy')]}
      hideButton
    />
  );
  //----------------------------------------------------------------------------
}

export default NoReturnOptions;