import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setToken } from '../../redux/app/actions';
import { reset as resetCustomer } from '../../redux/customer/actions';
import useSelector from '../../utility/useTypedSelector';

import { PageProps } from '../types';
import { PageLifecycle } from '../';

class SignOutLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

/**
 * 
 * as of now this "page" is a placeholder that will flush application state then redirect to the next page based on the config file
 */
const SignOut = ({ page }: PageProps) => {
  const dispatch = useDispatch();

  const { app } = useSelector(store => store);

  const lifecycle = new SignOutLifecycle(page, dispatch, app);

  useEffect(() => {
    if (window?.Android?.getAuthenticationMethod?.() == "MSAL"){
      window?.Android?.signOut()
    }
    dispatch(resetCustomer());
    dispatch(setToken(""));
    lifecycle.advance();
  }, []);
  return (
    <div>

    </div>
  )
}

export default SignOut;