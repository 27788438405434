import React from "react";
import axios from "axios";
import { BarcodeColumn, BarcodeKey } from "../../types/Config";
import { validateBagBarcode } from "../../utility";
import { $BarcodePrompt } from "./styles";
import { AppRuntimes } from "../../types/AppRuntimes";
import { clearLoadingIndicator, showAlert } from "../../redux/app/actions";
import { Alerts } from "../../types/LifeCycle";
import { Dispatch } from "redux";

export type BarcodeRow = Array<string>;
export type PublishedBarcodeRows = Array<BarcodeRow>;

export interface BarcodeAPIFunctions {
  [key: string]: (arg: BarcodeRow, barcodeKeys: BarcodeKey[], returnID, onSuccess: () => void, onFail: () => void, dispatch: Dispatch) => void
}

export interface BarcodePromptProps {
  barcodeIdx:             number,
  barcodeCopy:            string,
  barcodeCaption:         string,
  barcodeCaptionExample?: string,
  barcodeShowExampleCopy: string,
  showLetter?:            boolean
  onShowExampleClicked:   () => void
}

export const initBarcodeColumns = (barcodeColumns: Array<BarcodeColumn>) => {
  // in case config has not been initialized before this page loads,
  // don't allow app to unmount by returning a valid BarcodeColumn shape
  // in our catch block
  try {
    return barcodeColumns.map(() => []) as PublishedBarcodeRows;
  } catch(e) {
    return [['']];
  }
}

export const initBarcodeRow = (barcodeColumns: Array<BarcodeColumn>) => {
  // in case config has not been initialized before this page loads,
  // don't allow app to unmount, return a valid BarcodeRow shape
  try {
    return barcodeColumns.map(() => '') as BarcodeRow;
  } catch(e) {
    return [''];
  }
}

export const barcodeCombinationExists = (barcodeRow: BarcodeRow, publishedBarcodeRows: PublishedBarcodeRows): boolean => {
  for (const column of publishedBarcodeRows) {
    let foundMatch = true;
    // attempt to iterate and match the 2 arrays until you reach the end
    // if a non match is found, exit the column array and continue with the next
    for (let barcodeIdx = 0; barcodeIdx < barcodeRow.length; barcodeIdx += 1) {
      if (column[barcodeIdx] === barcodeRow[barcodeIdx]) {
        continue;
      } else {
        foundMatch = false;
        break;
      }
    }
    // if we're able to finish the loop without breaking, foundMatch should be true,
    // meaning that we're free end our function call and return true
    if (foundMatch){
      return true;
    }
  }
  // if we iterated through all of our columns without a break or return,
  // a match was not found
  return false;
}

/**
 * maps a barcodeRow to its associated keys
 * ex:
 * ["HRXXXXXXXXXX", "cc-XXXX-XX"], ["bagBarcode", "shippingTotebarcode"]
 * turns into
 * { "bagBarcode": "HRXXXXXXXXXX", "shippingToteBarcode": "cc-XXXX-XX" }
 */
const buildBarcodeRequest = (barcodeRow: BarcodeRow, barcodeKeys: BarcodeKey[]) => {
  if (barcodeRow.length != barcodeKeys.length) {
    console.error("Barcode row must match the same length as barcode keys in order to build a valid request.");
    return;
  }

  return barcodeKeys.reduce((req, key, idx) => {
    req[key] = barcodeRow[idx];
    return req;
  }, {});
}

export const barcodeValidatorsByKey: { [key: string]: (arg: string) => boolean } = {
  bagBarcode: validateBagBarcode
}

const defaultBarcodePost = async (barcodeRow: BarcodeRow, barcodeKeys: BarcodeKey[], returnID: string, onSuccess, onFail, dispatch) => {
  try {
    const bagBarcodeIdx = barcodeKeys.findIndex((key) => key === "bagBarcode");
    if (bagBarcodeIdx === -1) {
      console.error("Ensure that barcode keys are properly defined in the config json.");
      return;
    }
    // associate bag with return before associated a bag with a tote
    await axios.post("/returns/bags", {
        returnID,
        barcode: barcodeRow[bagBarcodeIdx]
    });
    onSuccess();
  } catch(e) {
    console.error(e);
    if (e.response.status === 409) {
      dispatch(showAlert(Alerts.bagAlreadyUsed))
    } else {
      console.log("error code = " + e.response.status)
      alert("Failed to post shipping-tote/bag combination.");
      onFail();
    }
  } finally {
    dispatch(clearLoadingIndicator());
  }
}

const defaultBarcodeDelete = async (barcodeRow: BarcodeRow, barcodeKeys: BarcodeKey[], returnID: string, onSuccess, onFail) => {
  try {
    const bagBarcodeIdx = barcodeKeys.findIndex((key) => key === "bagBarcode");
    if (bagBarcodeIdx === -1) {
      console.error("Ensure that barcode keys are properly defined in the config json.");
      return;
    }
    await axios.delete("/returns/bags", {
      data: {
        returnID,
        barcode: barcodeRow[bagBarcodeIdx]
      }
    });
    onSuccess();
  } catch(e) {
    console.error(e);
    alert("Failed to delete bag.");
    onFail();
  }
}

export const barcodePostByRuntime: BarcodeAPIFunctions = {
  [AppRuntimes.staples]: defaultBarcodePost,
};

export const barcodeDeleteByRuntime: BarcodeAPIFunctions = {
  [AppRuntimes.staples]: defaultBarcodeDelete,
}

export const BarcodePrompt = ({
  barcodeIdx,
  barcodeCopy,
  barcodeCaption,
  barcodeCaptionExample,
  barcodeShowExampleCopy,
  showLetter,
  onShowExampleClicked }: BarcodePromptProps) => {
  return (
    <$BarcodePrompt>
      {/* char code 65 represents A */}
      {`${showLetter ? (String.fromCharCode(65 + barcodeIdx) + ". ") : ""} ${barcodeCopy}`}
      <a onClick={() => onShowExampleClicked()}>{barcodeShowExampleCopy}</a>
      <div className="barcode-caption">
        {barcodeCaption}
        {barcodeCaptionExample &&
          <> <b>{barcodeCaptionExample}</b></>
        }
      </div>
    </$BarcodePrompt>
  )
}
