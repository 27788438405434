import styled from "styled-components";

interface AlertMessageProps {
  backgroundColor?: string,
}

const $AlertMessage = styled.div<AlertMessageProps>`
  display: flex;
  align-items: center;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "white" };
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  .icon {
    margin-left: 16px;
    svg {
      max-width: 16px;
      max-height: 16px;
      & > path {
        fill: ${props => props.color ? props.color : "grey" };
      }
    }
  }
  ul {
    margin: 0;
    padding-left: 24px;
  }
`;

const $Message = styled.div<AlertMessageProps>`
  margin: 16px;
  color: ${props => props.color ? props.color : "black" };
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export {
  $AlertMessage,
  $Message,
}
