import styled from 'styled-components';

export const $Success = styled.div`
  text-align: center;
  .title {
    margin-top: 56px;
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
  img {
    margin-bottom: 32px;
    height: 296px;
  }
  .detail {
    color: #454545;
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
  }

  button {
    width: 240px;
    height: 40px;
  }
`;