import React from "react";

const Two = (props) => (
  <>
    <svg {...props} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.22432 1.21349C-0.0696935 1.50526 -0.0696935 1.97831 0.22432 2.27008L4.98982 6.9992L0.222707 11.7299C-0.0713061 12.0217 -0.071306 12.4947 0.222707 12.7865C0.51672 13.0783 0.99341 13.0783 1.28742 12.7865L6.05453 8.05579L10.7203 12.686C11.0143 12.9777 11.491 12.9777 11.785 12.686C12.079 12.3942 12.079 11.9211 11.785 11.6294L7.11925 6.9992L11.7834 2.37064C12.0774 2.07887 12.0774 1.60582 11.7834 1.31405C11.4894 1.02228 11.0127 1.02228 10.7187 1.31405L6.05453 5.94261L1.28904 1.21349C0.995022 0.921717 0.518333 0.921717 0.22432 1.21349Z" fill="#221F1F"/>
    </svg>
  </>
);

export default Two;
