import styled from "styled-components";

const $Step = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const $StepImg = styled.div``;

const $StepTextContainer = styled.div`
  margin-left: 24px;
`;

const $Prompt = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  span: {
    text-decoration: underline;
    color: black;
    cursor: pointer;
  }
  .prompt2 {
    margin-bottom: 8px;
  }
`;

const $Detail = styled.div`
  font-size: 13px;
  line-height: 14.04px;
  color: #454545;
  font-weight: 400;
  margin-top: 8px;
`;

export {
  $Step,
  $StepImg,
  $StepTextContainer,
  $Prompt,
  $Detail,
}