import styled from "styled-components";
import $PageContentBox from "../../components/PageContentBox/styles";

const $ThankTheCustomer = styled($PageContentBox as any)`
  .main-container {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    width: 100%;
    overflow: auto;
  }
  .card {
    border: 1px solid var(--border-card);
    border-radius: 12px;
    width: 100%;
    height: fit-content;
    margin: 24px 0;
    padding: 24px 24px 0 24px;
  }
  .footer {
    width: 100%;
    margin-top: auto;
    button {
      margin-left: auto;
      padding: 16px;
    }
  }
`;

export default $ThankTheCustomer