import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import useSelector from "../../utility/useTypedSelector";
import { setToken, onFatalError, reset, goToPage } from "../../redux/app/actions";
import { fetchReturns } from "../../redux/customer/actions";
import { Store as CustomerStore } from "../../redux/customer/types";
import { PageProps } from "../types";
import { FatalErrors } from "../../types/LifeCycle";
import {
  initLocalStorage,
  hasBeenReturned,
  handleAxiosError,
  getReturnistaJWTClaims
} from "../../utility";
import { PageLifecycle } from "../";
import {
  $StartPartner,
} from "./styles";
import { AxiosError } from "axios";
import { BackendErrorPrefixes } from "../../types/API";
import ga from "../../utility/GAEmitter";
import logger from "../../utility/logger";

//==============================================================================
class StartPartnerLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }

  satisfiesPostconditions() {
    if (!this.customer.returns) return false
    if (!this.customer.returns.returning) return false
    // by the time we reach this check, we've already filtered out all the completed items
    // so if a return has been completed, the array should be empty
    if (isEmpty(this.customer.returns.returning)) return false

    return true
  }
}
//==============================================================================

//==============================================================================
const StartPartner = ({page}: PageProps) => {
  const dispatch = useDispatch();

  const token = Cookies.get("token");
  const [errorMessage, setErrorMessage] = useState(Cookies.get("errorMessage"));

  const { returnsApp } = getReturnistaJWTClaims(token);

  ga.setDimensions({
    user_properties: {
      location_id: returnsApp?.locationID,
      location_owner: returnsApp?.locationOwner,
    },
  });

  const { customer, app } = useSelector(store => store);

  const { returns } = customer;
  const { fatalErrorName } = app;

  const lifecycle = new StartPartnerLifecycle(page, dispatch, app, customer);
  //----------------------------------------------------------------------------
  // HOOKS
  //
  // on launch, check cookies to make sure everything's OK -- inform the user of
  // any errors -- if all's OK then fetch returns
  useEffect(() => {
    lifecycle.validatePreconditions()

    if (errorMessage &&
        (errorMessage.includes("ERROR_LOCATION_MISSING") ||
         errorMessage.includes("ERROR_LOCATION_INVALID"))) {
      lifecycle.advanceToFatalError(FatalErrors.location)
      return
    }
    if (errorMessage &&
        (errorMessage.includes("ERROR_RMA_MISSING") ||
         errorMessage.includes("ERROR_RMA_INVALID"))) {
      lifecycle.advanceToFatalError(FatalErrors.rma)
      return
    }
    if (errorMessage && errorMessage.includes("ERROR_RMA_UNASSOCIATED")) {
      lifecycle.advanceToFatalError(FatalErrors.rmaUnassociated)
      return
    }
    // on the backend, when there is an errorMessage set up in the cookie, we will not
    // receive a token. we very intentionally check for a token error after the other
    // errors, so that we can print them in the case of a missing token.
    if (!token ||
        errorMessage &&
        (errorMessage.includes("ERROR_TOKEN_MISSING") ||
         errorMessage.includes("ERROR_TOKEN_INVALID"))) {
      lifecycle.advanceToFatalError(FatalErrors.token)
      return
    }

    if (!token ||
      errorMessage &&
      errorMessage.includes("ERROR_RMA_EXPIRED")) {
      lifecycle.advanceToFatalError(FatalErrors.rmaExpired)
      return
    }

    // if the token is set, put it in the store and try to fetch returns
    dispatch(setToken(token))
    dispatch(fetchReturns({
      onSuccess: (response, dispatch) => {
        if (response.data.returning.every(hasBeenReturned)) {
          dispatch(onFatalError(FatalErrors.returnAlreadyComplete));
          return false;
        }
        return true;
      },
      onError: (error:AxiosError, dispatch) => {
        if (error?.response?.data === "error getting return from database: record not found\n") {
          dispatch(reset());
          dispatch(onFatalError(FatalErrors.rma));
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaUnassociated)) {
          lifecycle.advanceToFatalError(FatalErrors.rmaUnassociated);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaInvalid)) {
          lifecycle.advanceToFatalError(FatalErrors.rma);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaExpired)) {
          lifecycle.advanceToFatalError(FatalErrors.rmaExpired);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaPartialRefund)) {
          lifecycle.advanceToFatalError(FatalErrors.rmaPartialRefund);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaExpiredReturn)) {
          lifecycle.advanceToFatalError(FatalErrors.rmaExpiredReturn);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.locationInactive)) {
          lifecycle.advanceToFatalError(FatalErrors.inactiveLocation);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.returnBarIneligible)) {
          lifecycle.advanceToFatalError(FatalErrors.returnBarIneligible);
        }
        else {
          handleAxiosError(error, dispatch);
        }
      }
    }));
    // flush local storage when a return is started
    initLocalStorage();
  },[]);

  // whenever the returns change validate them -- inform the user of any errors
  // -- is all's OK then advance to next page
  useEffect(() => {
    // if returns are set, validate them and perhaps advance to the next page
    if (returns != undefined && !isEmpty(returns)) {
      if (!returns.returning) {
        lifecycle.advanceToFatalError(FatalErrors.unknown)
      } else {
        logger.Info("Return is valid, advancing pages...")
        dispatch(goToPage("countItemsPresent"))
      }
    }

  }, [returns]);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  //
  // this component never renders anything directly
  // (modals, loading indicators etc may appear based on some of the logic above)
  return (
    <$StartPartner>
    </$StartPartner>
  )
  //----------------------------------------------------------------------------
}
//==============================================================================

export default StartPartner;
