import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const $RequestSupportButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: ${tabletOrLargerDevices}) {
    flex-direction: row;
  }

  button {
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 190px;
      margin-bottom: 0;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  & > .returnistaTryAgainButton {
    flex: 1 0 0;
  }

  & > .requestSupportLink {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
  }
`;
