import React from "react";

const Close = (props) => (
  <>
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7046 0.295379C11.3108 -0.0984595 10.6723 -0.0984595 10.2786 0.295379L5.99994 4.57389L1.72147 0.295379C1.32772 -0.0984185 0.689237 -0.0984185 0.295317 0.295379C-0.098439 0.689217 -0.098439 1.3277 0.295317 1.72153L4.57378 6.00004L0.295481 10.2784C-0.0982749 10.6722 -0.0982749 11.3107 0.295481 11.7045C0.689237 12.0983 1.32788 12.0983 1.72164 11.7045L5.99994 7.42616L10.2784 11.7045C10.6722 12.0983 11.3108 12.0983 11.7046 11.7045C12.0983 11.3107 12.0983 10.6722 11.7046 10.2784L7.42609 6L11.7046 1.72153C12.0985 1.3277 12.0985 0.689176 11.7046 0.295379Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Close;
