export enum CurrentPage {
  Initializer = "initializer",
  Login = "login",
  StartReturn = "start-return",
  ReturnCodeScan = "return-code-scan",
  ReturnCodeComplete = "return-code-complete",
  BagCodeScan = "bag-code-scan",
  BagCodeComplete = "bag-code-complete",
  ItemCodeScan = "item-code-scan",
  ItemSelector = "item-selector",
  ItemDetails = "item-details",
  ReturnSummary = "return-summary",
  BagCount = "bag-count",
  PrintBagLabels = "print-bag-labels",
  ReturnAccepted = "return-accepted",
  FatalError = "fatal-error",
  SupportConfirmation = "support-confirmation",
}

export enum CurrentPageAction {
  Next = "NEXT",
  Back = "BACK",
  SelectItem = "SELECT_ITEM",
  NewItem = "NEW_ITEM",
  NewBag = "NEW_BAG",
  Restart = "RESTART",
  GoToLogin = "GO_TO_LOGIN",
  GoToBagScan = "GO_TO_BAG_SCAN",
  GoToBagCodeComplete = "GO_TO_BAG_CODE_COMPLETE",
  GoToBagCount = "GO_TO_BAG_COUNT",
  BackToSummary = "BACK_TO_SUMMARY",
  FatalError = "FATAL_ERROR",
  SupportConfirmation = "SUPPORT_CONFIRMATION",
}

export enum ReturnObjectAction {
  Set = "SET_RETURN_OBJECT",
  Unset = "UNSET_RETURN_OBJECT",
}

export enum LocationObjectAction {
  Set = "SET_LOCATION_OBJECT",
  Unset = "UNSET_LOCATION_OBJECT",
}

export enum currentBagAction {
  Set = "SET_CURRENT_BAG",
  Unset = "UNSET_CURRENT_BAG",
}

export enum BarcodeAction {
  Set = "SET_BARCODE",
  Unset = "UNSET_BARCODE",
}

export enum CurrentItemAction {
  Set = "SET_CURRENT_ITEM",
  Unset = "UNSET_CURRENT_ITEM",
}

export enum bagsAction {
  Add = "ADD_BAG_OBJECT",
  Remove = "REMOVE_BAG_OBJECT",
  GenerateLabels = "GENERATE_BAG_LABELS",
}

export enum FakeImagesAction {
  Add = "ADD_FAKE_IMAGE",
  Unset = "UNSET_FAKE_IMAGES",
}

export enum UserTokenAction {
  Set = "SET_TOKEN",
  Unset = "UNSET_TOKEN",
}

export enum LoadingAction {
  Set = "SET_LOADING",
  Unset = "UNSET_LOADING",
}

export enum RuntimeType {
  Returnista = "returnista",
  Partner = "partner",
}

export enum RuntimeAction {
  Set = "SET_APP_TYPE",
}

export enum ModalAction {
  Set = "SET_MODAL",
  Unset = "UNSET_MODAL",
}

export enum MultiStoreAction {
  AppLogout = "APP_LOGOUT",
  AppReset = "APP_RESET",
  AddItem = "ADD_ITEM",
  RemoveItem = "REMOVE_ITEM",
}

export enum FatalErrorAction {
  Set = "SET_FATAL_ERROR",
  Unset = "UNSET_FATAL_ERROR",
}

export enum TerminalReason {
  GenericError = "GENERIC_ERROR",
  ReturnComplete = "RETURN_COMPLETE",
  ReturnCancelled = "RETURN_CANCELLED",
  SessionTimeout = "SESSION_TIMEOUT",
  NotAssociated = "NOT_ASSOCIATED",
  RmaPartialRefund = "RMA_PARTIAL_REFUND",
  RmaExpiredReturn = "RMA_EXPIRED_RETURN",
  RmaInvalid = "RMA_INVALID",
  AlreadyComplete = "ALREADY_COMPLETE",
  TemporarilyUnavailable = "TEMPORARILY_UNAVAILABLE",
  InactiveLocation = "INACTIVE_LOCATION",
  ReturnBarIneligible = "RETURN_BAR_INELIGIBLE",
}

export enum NoMatchWarningAction {
  Set = "SET_NO_MATCH_WARNING",
  Unset = "UNSET_NO_MATCH_WARNING",
}

export enum PuzzleAttemptsAction {
  Increase = "Increase",
  Reset = "RESET",
}

export enum ScannerType {
  ConfirmationCode = "redesignConfirmationCode",
  BagCode = "redesignBagCode",
  ItemCode = "redesignItemCode",
}

export enum BARCODE_INCLUDES_RID {
  PRINCESS_POLLY = "loop-princesspollydev",
  FABLETICS = "fabletics",
  TRUE_CLASSIC_TEES = "trueclassictees",
  PARCELLAB_TCT = "parcellab-trueclassictees",
}

export enum RedesignCutOverReminder {
  Set = "SET_CUTOVER_REMINDER",
  Unset = "UNSET_CUTOVER_REMINDER",
}

export enum RedesignFeaturesModal {
  Set = "SET_NEW_FEATURES_MODAL",
  Unset = "UNSET_NEW_FEATURES_MODAL",
}

export enum InvalidItemCode {
  Add = "INVALID_ITEM_CODE_ADD",
}
export enum EventAction {
  Add = "ADD_EVENT",
  Reset = "RESET_EVENTS",
}

export enum FeatureFlagsAction {
  Set = "SET_FEATURE_FLAGS",
}
