import React from "react";
import ReturnistaPrimaryButton from "../../Button/ReturnistaPrimaryButton";
import ReturnistaFeedbackIcon from "../../ReturnistaFeedbackIcon";
import { openScanner } from "../../../utility";
import { ScannerType } from "../../../redux/enums";

type AlertModalBuilderProps = {
  primaryMessage: string;
  subMessage: string;
  buttonText: string;
  closeHandler: () => any;
  buttonHandler: () => any;
  scannerType?: ScannerType;
};

const AlertModalBuilder = ({
  primaryMessage,
  subMessage,
  buttonText,
  closeHandler,
  buttonHandler,
  scannerType,
}: AlertModalBuilderProps) => {
  const onClick = () => {
    if (scannerType) {
      // brings the user back to the proper scanner type when closing the modal
      openScanner(scannerType);
    }
    buttonHandler();
  };
  const onClose = () => {
    if (scannerType) {
      // brings the user back to the proper scanner type when closing the modal
      openScanner(scannerType);
    }
    closeHandler();
  };

  return {
    iconElement: (
      <div style={{ alignSelf: "center" }}>
        <ReturnistaFeedbackIcon status="error" />
      </div>
    ),
    onRequestClose: onClose,
    primaryMessage,
    subMessages: [subMessage],
    button: (
      <ReturnistaPrimaryButton onClick={onClick} dataCyString="codeScanTryAgain">
        {buttonText}
      </ReturnistaPrimaryButton>
    ),
  };
};

export default AlertModalBuilder;
