import React from "react";

import getTranslator from "../../../utility/getTranslator";

import { SVG } from "../../../components/svg";

import { $ReturnStatusErrorCard } from "./styles";

export const ReturnStatusErrorCard = () => {
  const { t } = getTranslator("ReturnStatusErrorCard")();

  return (
    <$ReturnStatusErrorCard>
      <SVG name={"circleError"} />
      <div>{t("errorMessage")}</div>
    </$ReturnStatusErrorCard>
  );
};
