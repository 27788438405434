import styled from "styled-components";

const $OptionCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 0 24px;
  border: 1px solid var(--border-card);
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    @media (min-width: 759px) {
      border: 1px solid var(--text-black);
    }
  }
  .title {
    font-weight: 500;
    font-size: 18px;
    color: --var(--text-black);
  }

  .arrow {

  }
`;

export default $OptionCard;
