import React, { useState, useEffect } from 'react'
import logger from "../../../utility/logger/logger";

import ModalContainer from '../ModalContainer'
import TextInput from '../../TextInput'
import PrimaryButton from '../../Button/PrimaryButton'
import { clearLoadingIndicator, handleReturnistaExpiredToken, showLoadingIndicator } from '../../../redux/app/actions'
import { useDispatch } from 'react-redux'
import axios from "axios"
import { defaultLoadingSymbol } from '../../LoadingIndicator';
import { AppRuntimes } from '../../../types/AppRuntimes';
import { DataCyStrings } from '../../../types/DataCyStrings';

// simple modal for requesting and validating user password, with a submit button and an X button in the corner
type ReturnOverrideModalTypes = {
  isOpen: boolean
  onCompletion: (result: boolean) => void,
  runtime: string
};
const ReturnOverrideModal = ({
  isOpen,
  onCompletion,
  runtime
}: ReturnOverrideModalTypes) => {

  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const attemptOverride = async () => {
    dispatch(showLoadingIndicator(defaultLoadingSymbol))
    // we can check location.protocol === 'https:'
    // our protocol should always be https
    // given the sensitivity of our application
    try {
      const response = await axios.post("/authOverride", {password})
      const {data: {authorized}} = response
      if (authorized === true){
        logger.Info("User authorized a return override")
        onCompletion(true)
      }else if (authorized === false){
        logger.Info("User entered invalid password for return override")
        setError("Invalid password. Please try again.")
      }else{
        throw new Error("Missing authorized field from auth-override response")
      }
    } catch (error) {
      // if our login token expired
      if (axios.isAxiosError(error) && error.response?.status === 401 && runtime === AppRuntimes.returnista) {
        dispatch(handleReturnistaExpiredToken());
      }else{
        logger.Warning("An issue has occurred while authorizing a return policy override", error)
        setError("Something has gone wrong. Please try again. If the issue persists, please contact customer service.")
      }
    } finally {
      dispatch(clearLoadingIndicator())
    }
  }

  //clear all state when visibility changes
  useEffect(() => {
    setPassword("")
    setError("")
  }, [isOpen])

  return (
    <ModalContainer
    isOpen={isOpen}
    onRequestClose={() => onCompletion(false)}
    contentLabel="make-exception"
    primaryMessage="Make an exception for this item?"
    subMessages={[
    `This item is non-returnable based on your store's return policy.
    You can make an exception by entering your password then pressing “Override return policy”.`
    ]}
    closeIcon={true}
    runtime={runtime}
    textInput={
      <form>
      <input type="text" autoComplete="username" hidden={true}/>
      <TextInput
      autocomplete="current-password"
      value={password}
      onChange={setPassword}
      onSubmit={attemptOverride}
      label="Password"
      type="password"
      />
      </form>
    }
    textInputError={error}
    button={
      <PrimaryButton
        onButtonClick={attemptOverride}
        label="Override return policy"
        disabled={!password}
      />
    }
    dataCyString={DataCyStrings.returnOverrideModal}
  />)
}

export default ReturnOverrideModal
