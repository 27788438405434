import React from "react";

const Lock = (props) => (
  <>
    <svg
      {...props}
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M6.19336 0C6.98901 0 7.75207 0.316071 8.31468 0.87868C8.87729 1.44129 9.19336 2.20435 9.19336 3V4H10.1934C10.7238 4 11.2325 4.21071 11.6076 4.58579C11.9826 4.96086 12.1934 5.46957 12.1934 6V13C12.1934 13.5304 11.9826 14.0391 11.6076 14.4142C11.2325 14.7893 10.7238 15 10.1934 15H2.19336C1.66293 15 1.15422 14.7893 0.779146 14.4142C0.404073 14.0391 0.193359 13.5304 0.193359 13V6C0.193359 5.46957 0.404073 4.96086 0.779146 4.58579C1.15422 4.21071 1.66293 4 2.19336 4H3.19336V3C3.19336 2.20435 3.50943 1.44129 4.07204 0.87868C4.63465 0.316071 5.39771 0 6.19336 0ZM6.19336 8.5C5.92814 8.5 5.67379 8.60536 5.48625 8.79289C5.29872 8.98043 5.19336 9.23478 5.19336 9.5C5.19336 9.76522 5.29872 10.0196 5.48625 10.2071C5.67379 10.3946 5.92814 10.5 6.19336 10.5C6.45858 10.5 6.71293 10.3946 6.90047 10.2071C7.088 10.0196 7.19336 9.76522 7.19336 9.5C7.19336 9.23478 7.088 8.98043 6.90047 8.79289C6.71293 8.60536 6.45858 8.5 6.19336 8.5ZM6.19336 2C5.92814 2 5.67379 2.10536 5.48625 2.29289C5.29872 2.48043 5.19336 2.73478 5.19336 3V4H7.19336V3C7.19336 2.73478 7.088 2.48043 6.90047 2.29289C6.71293 2.10536 6.45858 2 6.19336 2Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Lock;
