import { ModalAction, MultiStoreAction } from "../enums";

const modalReducer = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case ModalAction.Set:
      return { isOpen: true, ...action.modalProps };
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case ModalAction.Unset:
      return { isOpen: false };
    default:
      return state;
  }
};

export default modalReducer;
