import React, { KeyboardEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react";
import { DataCyStrings } from "../../types/DataCyStrings";
import $RadioCard from "./styles";

export interface OptionCardOption {
  value: string,
  isValid?: boolean,
  thumbnail?: string,
}

export interface OptionCardProps {
  title?: string,
  selected?: string,
  options: OptionCardOption[],
  onChange: (option: OptionCardOption) => void,
  labelledBy?: string,
  ariaLabel?: string,
  size?: 'small' | 'normal',
  align?: 'left' | 'center',
  dataCyString?: DataCyStrings
}

let uniqueId = 0;

/**
 * Displays a set of option values with a behavior similar to radio groups.
 * If a title is not provided then a labelledBy should be pointing to a label id.
 * @param title
 * @param selected
 * @param options
 * @param onChange callback function which is triggered when an option is selected.
 * @param labelledBy sets the aria-labelledby field
 * @param ariaLabel
 * @param size
 * @constructor
 */
const RadioCard = ({title, selected, options, onChange, labelledBy, ariaLabel, size, align, dataCyString}: OptionCardProps) => {
  const [focusedIndex, setFocusedIndex] = useState<number>(options.findIndex(option => option.value == selected));
  const elementRef = useRef<HTMLDivElement>();
  const elementId = ++uniqueId;

  useEffect(() => {
    if (focusedIndex >= options?.length) {
      setFocusedIndex((options.length) ? 0 : -1);
    }
  })

  /**
   * Handle changing selected option using the keyboard.
   * Arrow keys move the focus between the options.
   * Enter and space keys select the currently focused option.
   * The focus never leaves the $RadioCard element.
   * The options elements are highlighted based on focus and selection variables.
   * @param e
   */
  const onKeyUp: KeyboardEventHandler = (e) => {
    switch (e.key) {
      case "ArrowUp":
      case "ArrowLeft":
        setFocusedIndex((focusedIndex > 0) ? focusedIndex - 1 : options.length - 1);
        break;
      case "ArrowDown":
      case "ArrowRight":
        setFocusedIndex((focusedIndex < options.length - 1) ? focusedIndex + 1 : 0);
        break;
      case "Enter":
      case "Space":
      case " ":
        if (focusedIndex >= 0) {
          onChange(options[focusedIndex]);
        }
        break;
    }
    elementRef?.current?.focus();
  }

  /**
   * Generates a unique html id for each option.
   * @param index
   */
  const generateOptionId = (index: number): string | undefined => {
    if (index < 0) {
      return undefined;
    }
    return `radio-option-${elementId}-${index}`;
  }

  return (
    // @ts-ignore
    <$RadioCard role="radiogroup"
                aria-activedescendant={generateOptionId(focusedIndex)}
                tabIndex={0}
                onKeyUp={onKeyUp}
                // @ts-ignore
                ref={elementRef}
                aria-labelledby={labelledBy}
                aria-label={ariaLabel}
                className={size + " " + align}
                id={`radio-card-${elementId}`}>
      {!!title &&
      <label className="title">
        {title}
      </label>
      }
      {options.map((option, i) => {
        const isFocused = i === focusedIndex;

        const isSelected = option.value === selected;
        const className = [
          'option',
          isSelected ? 'selected' : '',
          isFocused ? 'focused' : '',
          !option.isValid ? 'invalid' : '',
          option.thumbnail ? 'thumbnail' : '',
        ];

        const onClick: MouseEventHandler = () => {
          setFocusedIndex(i);
          onChange(option);
        }

        return (
          // @ts-ignore
          <div className={className.join(' ')}
               id={generateOptionId(i)}
               tabIndex={-1}
               onClick={onClick}
               role="radio"
               aria-checked={isSelected}
               aria-invalid={!option.isValid}
               title={option.value}
               data-cy={dataCyString + `-${option.value}`}
               >
            {!!option.thumbnail ? (
              <img role="presentation" alt={option.value} src={option.thumbnail}/>
            ) : option.value}
          </div>
        )
      })}
    </$RadioCard>
  )
}

export default RadioCard;
