export const partialRefundUnacceptableStatusCode = 406;
export const inactiveLocationStatusCode = 412;
export const returnBarDropoffIneligibleCode = 422
export const codeNotAssociatedHappyReturns = "This code isn't associated with Happy Returns.";
export const tryAnotherCodeOrNewBeginReturn = "Please try another code or ask the customer to begin a return online.";
export const codeNotEligibleForBoxFreeReturnTitle = "This code is no longer eligible for a box free return";
export const codeNotEligibleForBoxFreeReturn = codeNotEligibleForBoxFreeReturnTitle + ".";
export const startNewReturnOrContactRetailer =
  "Please have the customer start a new return or contact the retailer directly for help with this return.";
export const returnAlreadyComplete = "This return is already complete.";
export const allItemsReturnedApproved =
  "All items associated with this QR code have been returned and approved. Please scan a different QR code.";
export const happyReturnsUnvailable = "Happy Returns app is temporarily unavailable";
export const issueLoadingHappyReturns =
  "We're experiencing an unanticipated issue preventing us from loading the Happy Returns app. We are working on a fix and will restore your access as quickly as possible. Please try again shortly.";
export const tryReentering = "Please try re-entering it.";
export const scanCustomerQRCode = "Scan customer's QR code.";
export const scannedHappyReturnsBag =
  "It looks like you scanned the Happy Returns bag. Please scan the customer’s QR code.";
export const unauthorizedTitle = "Unauthorized Access";
export const unauthorizedMessage =
  "Please log out of your account and log back in. If the issue persists, contact us at (877) 750-4888.";
export const genericErrorTitle = "Something went wrong";
export const genericErrorMessage =
  "The page didn't load correctly, please try again. If the issue persists, contact us at (877) 750-4888.";
export const returnCancelled = "The return is cancelled";
export const youMayCloseThisTab = "You may close this tab.";
export const inactiveLocationTitle = "Your location is temporarily inactive.";
export const inactiveLocationMessage =
  "For more details, please feel free to reach us at (877)-750-4888 or drop us an email at returnbar@happyreturns.com.";
export const sessionExpiredTitle = "Your session has expired";
export const returnComplete = "The return is complete";
export const boxFreeReturnNotAllowedTitle = "This code is not eligible for a box free return.";
export const boxFreeReturnNotAllowedMessage =
  "Please have the customer contact the retailer directly for directions on how to make their return.";
export const codeNotEligibleForReturnBarReturn = "This code is not eligible for a Return Bar return.";
