import { string } from 'prop-types';
import React from 'react';
import { SVG, SVGName } from "../svg"; 

import {
  $Step,
  $StepImg,
  $StepTextContainer,
  $Prompt,
  $Detail,
} from './styles';

interface StepProps {
  imgName: SVGName,
  prompt: any,
  prompt2?: any,
  detail?: string
}

function Step({imgName, prompt, prompt2, detail}:StepProps){
  return (
    <$Step>
      <$StepImg><SVG name={imgName} /></$StepImg>
      <$StepTextContainer>
        <$Prompt>
          {prompt}
        </$Prompt>
        {prompt2 ?
        <div className="prompt2">
          {prompt2}
        </div>
        : null
        }
        <$Detail>
         {detail}
        </$Detail>
      </$StepTextContainer>
    </$Step>
  )
}

export default Step;