import React from "react";
import { renderSubMessages } from "../../Final/FinalBase";
import { ModalCardProps } from "./type";
import { SVG } from "../../svg";

import {
  $Modal,
  $PrimaryMessage,
  $SubMessages,
  $TextInput,
  $ButtonGroup,
  $ExitButton,
  $ExitButtonWrapper,
  $Image,
} from "./styles";
import { handleEnterKeyPressOnClickHandlers } from "../../../utility";
import useSelector from "../../../utility/useTypedSelector";
import { AppRuntimes } from "../../../types/AppRuntimes";

function ModalCard({
  primaryMessage,
  subMessages,
  textInput,
  textInputError,
  successMessage,
  button,
  onClose,
  closeIcon,
  img,
  imgPosition,
  iconElement,
  runtime,
  dataCyString,
  hideCloseButton = false,
}: ModalCardProps) {
  const displayExitButton = () => {
    if (hideCloseButton) {
      return null;
    }
    const closeModalHandlers = handleEnterKeyPressOnClickHandlers(onClose);
    return (
      <$ExitButtonWrapper>
        <$ExitButton data-cy="close-modal-icon" {...closeModalHandlers}>
          <SVG name="close" />
        </$ExitButton>
      </$ExitButtonWrapper>
    );
  };

  // not a super fan of how I had to position the image depending on the modal and adjusting other things.
  // given more time it would probably be better to just built an alt modal

  // displayExitButton is no longer being displayed in returnista mocks.
  // I've commented them the button out, but I will leave the code in just in case.

  return (
    <$Modal data-cy={dataCyString}>
      {(runtime !== AppRuntimes.returnista || closeIcon) && displayExitButton()}
      {iconElement != null && iconElement}
      {img && imgPosition === "top" && <$Image imgPosition={imgPosition} src={img} />}
      <$PrimaryMessage data-cy="modal-primary-message" imgPosition={imgPosition} closeIcon={closeIcon}>
        {primaryMessage}
      </$PrimaryMessage>
      <$SubMessages data-cy="modal-sub-messages" imgPosition={imgPosition}>{renderSubMessages(subMessages)}</$SubMessages>
      {img && imgPosition === "bottom" && <$Image imgPosition={imgPosition} src={img} />}
      {textInputError && (
        <div className="error-banner">
          <div className="icon">
            <SVG name="exclamation" />
          </div>
          <div className="error-text">{textInputError}</div>
        </div>
      )}
      {successMessage && (
        <div className={"success-banner"}>
          <div className={"icon"}>
            <SVG name={"checkCircle"} />
          </div>
          <div className={"success-text"}>{successMessage}</div>
        </div>
      )}
      {textInput && <$TextInput>{textInput}</$TextInput>}
      <$ButtonGroup>{button}</$ButtonGroup>
    </$Modal>
  );
}

export default ModalCard;
