import React from "react";
import useSelector from "../../utility/useTypedSelector";

import $ContactInfo from "./styles";

/**
 * ContactInfo returns a div that forces itself to the bottom of the screen
 * and displays contact information to be used for support
 */
const ContactInfo = ({ email, phoneNumber, versionID }) => {
  return (
    <$ContactInfo>
      <b className="need-help">Need help?</b> <br />
      Call: {phoneNumber} <br />
      Email: {email}
      {versionID ? <div className="version-id">{`Happy Returns ${versionID}`}</div> : null}
    </$ContactInfo>
  );
};

export default ContactInfo;
