import React from "react";

const ExternalLink = (props) => (
  <>
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71471 0.977173C9.34652 0.977173 9.04805 1.27565 9.04805 1.64384C9.04805 2.01203 9.34652 2.31051 9.71471 2.31051H12.8528L5.44528 9.71864C5.18494 9.979 5.18495 10.4011 5.44531 10.6615C5.70567 10.9218 6.12778 10.9218 6.38812 10.6614L13.7956 3.2534V6.39172C13.7956 6.75991 14.094 7.05839 14.4622 7.05839C14.8304 7.05839 15.1289 6.75991 15.1289 6.39172V1.64384C15.1289 1.27565 14.8304 0.977173 14.4622 0.977173H9.71471ZM2.97705 1.92589C1.87248 1.92589 0.977051 2.82132 0.977051 3.92589V13.1289C0.977051 14.2335 1.87248 15.1289 2.97705 15.1289H12.1792C13.2838 15.1289 14.1792 14.2335 14.1792 13.1289V9.63395C14.1792 9.26576 13.8807 8.96728 13.5125 8.96728C13.1443 8.96728 12.8459 9.26576 12.8459 9.63395V13.1289C12.8459 13.4971 12.5474 13.7956 12.1792 13.7956H2.97705C2.60886 13.7956 2.31038 13.4971 2.31038 13.1289V3.92589C2.31038 3.5577 2.60886 3.25923 2.97705 3.25923H6.94642C7.31461 3.25923 7.61309 2.96075 7.61309 2.59256C7.61309 2.22437 7.31461 1.92589 6.94642 1.92589H2.97705Z"
        fill="white"
      />
    </svg>
  </>
);

export default ExternalLink;
