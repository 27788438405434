// third-party imports
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clone, isMatch } from "lodash";
import { enableMapSet } from 'immer';

enableMapSet();

// private imports
// repo imports
import AlertMessage from "../../components/AlertMessage";
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import {
  setSelectedInstanceExchange,
  setSelectedInstanceForCompletion
} from "../../redux/customer/actions"
import { Refund, ExchangeOption } from "../../types/Instance";
import PageModal from "../../components/Modal/PageModal";
import { StringMap } from "../../types/StringMap";
import RadioCard, { OptionCardOption } from "../../components/RadioCard";

// local imports
import $Exchange from "./styles";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { AnalyticCategories, AnalyticsPageRoutes, ExchangeModalActions } from "../../types/Analytics";
import getTranslator from "../../utility/getTranslator";
import { getAdminMode } from "../../utility";
import { DataCyStrings } from "../../types/DataCyStrings";

import ga from "../../utility/GAEmitter";
import NavigationCard from "../../components/NavigationCard";

const useTranslation = getTranslator("Exchange")

class ExchangeLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app)
  }
}

interface ExchangeAttributeValue {
  value: string,
  thumbnail?: string,
  isValid: boolean
}

interface FormattedExchangeAttribute {
  name: string,
  values: ExchangeAttributeValue[]
}

interface ExchangeProperties {
  exchangeAttributes: FormattedExchangeAttribute[],
  exchangeOptions: ExchangeOption[]
}

/**
 * Allows the user to select from a series of instance variants and sets the refund type to selected instance
 */
const Exchange = ({page}: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const { customer, app } = useSelector((store: RootReducer) => store);

  const {selectedInstance, selectedInstanceExchangeProperties, refundOptions } = customer;
  const {exchangeOptions, exchangeAttributes} = selectedInstanceExchangeProperties
  const lifecycle = new ExchangeLifecycle(page, dispatch, app);

  const [currentExchange, setCurrentExchange] = useState<StringMap>({});
  const [storeCreditOption, setStoreCreditOption] = useState<Refund>({
    id: "",
    label: "",
    optionID: "",
    sublabel: "",
  });
  const [hasSetStoreCreditOption, setHasSetStoreCreditOption] = useState(false);
  const [currentThumbnail, setCurrentThumbnail] = useState<string>(selectedInstance?.purchase?.images?.length ? selectedInstance.purchase.images[0] : "");
  const [error, setError] = useState("");

  const { currentModalPageName, locale, copies } = useSelector((store) => store.app);
  const [isAdminMode] = useState<boolean>(getAdminMode());
  const retailerName = copies?.retailerName

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS

  // check for reason notes here before we
  // mark the selected instance for completion
  const gaReasonNotes = () => {
    if (customer.selectedInstance?.reasonNote === "") {
      ga.event({
        category: AnalyticCategories.ExchangeModal,
        action: ExchangeModalActions.SkippedReturnReasonNotes,
      });
    }
  }

  /**
   * Determines if the user has made a selection for each option and there are no errors.
   */
  const canSubmitExchange = (): boolean => {
    return Object.keys(currentExchange).length === Object.keys(exchangeAttributes).length && !error;
  };

  /**
   * Determines if any item is in stock.
   * Uses the `quantity` value on the instance.
   *
   * @param items
   */
  const isInStock = (items: ExchangeOption[]): boolean => {
    return !!items.find((opt) => opt.quantity);
  };

  /**
   * Filters down the exchange options to just the instances that match a set of options.
   *
   * @param filterOptions the options the user has selected
   * ex: {"Size": "S"}
   * @param first if you only need the first match
   * @param outOfStock true if out of stock items should be included in results
   */
  const getFilteredExchangeOptions = (filterOptions: StringMap, first?: boolean, outOfStock: boolean = false): ExchangeOption[] => {
    if (Object.keys(filterOptions || {}).length === 0) {
      return clone(exchangeOptions);
    }

    const filteredOptions: ExchangeOption[] = [];
    for (const opt of exchangeOptions) {
      if (isMatch(opt.attributes, filterOptions) && (opt.quantity > 0 || outOfStock)) {
        filteredOptions.push(opt);

        if (first) {
          break;
        }
      }
    }
    return filteredOptions;
  };

  /**
   * Accepts a selection and checks if it is in stock.
   * If it is not in stock it will attempt to remove options to find a selection that is in stock.
   *
   * @param selectedOptions
   * @param preserveAttr an attribute that should not be deleted during the BFS.
   */
  const validateSelection = (selectedOptions: StringMap, preserveAttr?: string): StringMap => {
    let filteredItems = getFilteredExchangeOptions(selectedOptions);
    if (isInStock(filteredItems)) {
      return selectedOptions;
    }

    // If there is no stock of this selection, attempt to find a selection that is valid.
    // Perform a BFS to find a selection that is in stock.
    // Uses BFS to find the fewest selections to remove.
    // Remove options one by one until an in-stock selection is found.
    const visited = new Set<string>();
    const stack: StringMap[] = [selectedOptions];
    for (const currentOptions of stack) {
      filteredItems = getFilteredExchangeOptions(currentOptions);
      if (isInStock(filteredItems)) {
        return currentOptions;
      }

      // Add a copy of the selection with each option removed.
      // ex: {"a": "foo", "b": "bar", "c": "fizz"}
      // Creates a stack of:
      // {"b": "bar", "c": "fizz"} (removed "a")
      // {"a": "foo", "c": "fizz"} (removed "b")
      // {"a": "foo", "b": "bar"} (removed "c")
      // If none of those are in stock continue to:
      // {"a": "foo"} (removed "b" and "c")
      // {"b": "bar"} (removed "a" and "c")
      // {"c": "fizz"} (removed "a" and "b")
      for (let key in currentOptions) {
        if (key === preserveAttr) {
          // Don't unselect the option the user just added.
          continue;
        }

        let tryOptions = {...currentOptions};
        delete tryOptions[key];

        // Check if the option set is already in the stack or has been tried.
        // Use JSON.stringify to generate a consistent key because JS objects can't easily be compared.
        const visitedString = JSON.stringify(tryOptions);
        if (!visited.has(visitedString)) {
          visited.add(visitedString);
          stack.push(tryOptions);
        }

      }
    }

    // Nothing was in stock.
    // This typically will be a single option selection which is out of stock.
    return {};
  };

  /**
   * Takes in an attribute name and looks for the value within the selected instance.
   * Returns undefined if a value cannot be found.
   * @param attributeName
   */
  const getOriginalAttributeValue = (attributeName: string): string | undefined => {
    const displayObj = customer?.selectedInstance?.purchase?.display?.find(obj => {
      return obj.label === attributeName;
    })

    if (displayObj) {
      return displayObj.value;
    }
    return undefined;
  }

  /**
   * Indicates of the attribute should be displayed as thumbnails instead of a textual value.
   * Uses the attribute name/key to determine this.
   * @i18n This needs to pull from some list of localized names.
   * @param attributeName
   */
  const showImageForExchangeAttribute = (attributeName: string): boolean => {
    let cleanName = (attributeName || "").trim().toLowerCase();
    return cleanName.includes("color") || ["collection", "style", "title"].includes(cleanName);
  }

  /**
   * Gets the thumbnail or image from an option.
   * @param option
   */
  const getThumbnail = (option: ExchangeOption): string => {
    if (option?.purchase?.thumbnail) {
      return option.purchase.thumbnail;
    }
    if (option?.purchase?.images?.length) {
      return option.purchase.images[0];
    }
    return '';
  }

  /**
   * Takes in the selectedInstance exchange properties and formats the array of attributes into an array of objects.
   * This allows for easy lookup when determining if an attribute should be greyed out
   * Before: {name: "Color", values: ["red", "green"]
   * After: {name: "Color", values: [{value: "red", isValid: bool}, ...]}
   */
  const getFormattedSelectedExchangeProperties = (): ExchangeProperties => {

    const formattedExchangeAttributes = exchangeAttributes.map<FormattedExchangeAttribute>((attribute): FormattedExchangeAttribute => {

      // Convert the array of string values to an array of objects with extra metadata added to the values.
      const formattedValues = attribute.values.map<ExchangeAttributeValue>(value => {
        const valueObj = {value} as ExchangeAttributeValue;
        // We want to display the item thumbnail as opposed to the variant value for certain names.
        if (showImageForExchangeAttribute(attribute.name)) {
          const exchangeOptionWithThumbnail = getFilteredExchangeOptions({
            [attribute.name]: valueObj.value
          }, true, true);
          valueObj.thumbnail = getThumbnail(exchangeOptionWithThumbnail[0]);
        }

        valueObj.isValid = getFilteredExchangeOptions({
          ...currentExchange,
          [attribute.name]: value
        }, true).length > 0;

        return valueObj;
      });

      return {
        name: attribute.name,
        values: formattedValues
      };
    });

    return {
      exchangeOptions: exchangeOptions,
      exchangeAttributes: formattedExchangeAttributes
    };
  }

  /**
   * Memoize formatted exchange properties based on the redux store.
   * Only reset this var when the selectedInstanceExchange properties
   * in the redux customer store or the currentExchange are changed
   * (user input)
   */
  const formattedSelectedExchangeProperties = useMemo<ExchangeProperties>(
    getFormattedSelectedExchangeProperties, [customer.selectedInstanceExchangeProperties, currentExchange]
  );
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const onExchangeAttributeClicked = (attributeName: string, value: string) => {
    const newSelection = validateSelection({
      ...currentExchange,
      [attributeName]: value,
    }, attributeName);

    // update thumbnail based on newSelection
    const filteredOptions = getFilteredExchangeOptions(newSelection);
    let newThumbnail = "";
    for (const opt of filteredOptions) {
      newThumbnail = getThumbnail(opt);
      if (newThumbnail != "") break;
    }
    setCurrentThumbnail(newThumbnail);

    setCurrentExchange(newSelection);
  }

  const onStoreCreditClicked = (refund) => {
    dispatch(setSelectedInstanceForCompletion(refund));
    ga.event({
      category: AnalyticCategories.ExchangeModal,
      action: ExchangeModalActions.StoreCreditButton,
    })
    gaReasonNotes();
    lifecycle.advance();
  }

  const handleOptionClickedGAEvent = (attributeName: string, option: OptionCardOption) => {
    if (option.isValid) {
      ga.event({
        category: AnalyticCategories.ExchangeModal,
        action: ExchangeModalActions.Option,
        label: `${attributeName}:${option.value}`
      })
    } else {
      ga.event({
        category: AnalyticCategories.ExchangeModal,
        action: ExchangeModalActions.GreyedOutOption,
        label: `${attributeName}:${option.value}`
      })
    }
  }

  const handleSelectButtonGAEvent = () => {
    if (canSubmitExchange()) {
      ga.event({
        category: AnalyticCategories.ExchangeModal,
        action: ExchangeModalActions.SelectButton
      });
    } else {
      ga.event({
        category: AnalyticCategories.ExchangeModal,
        action: ExchangeModalActions.GreyedOutSelectButton,
      })
    }
  }
  const onSubmitExchange = () => {
    if (!canSubmitExchange()){
      return;
    }

    const exchangeOption = getFilteredExchangeOptions(currentExchange, true)[0];

    if (exchangeOption?.purchase) {
      dispatch(setSelectedInstanceExchange(exchangeOption?.purchase));
      lifecycle.advance();
    }
  }
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    const initialSelection = {};
    for (const attribute of getFormattedSelectedExchangeProperties().exchangeAttributes) {
      const validValues = attribute.values.filter((value) => value.isValid);
      if (validValues.length === 1) {
        initialSelection[attribute.name] = validValues[0].value;
      }
    }
    setCurrentExchange(initialSelection);
  }, []);

  useEffect(() => {
    if (!hasSetStoreCreditOption) {
      const storeCreditOptions = refundOptions?.find(e => e.id === "store-credit");
      if (storeCreditOptions && !app.returnShoppingEnabled) {
        // appending optionID since it isn't in the return option
        setStoreCreditOption({
          ...storeCreditOptions,
          optionID: "",
        })
      }
    }
    setHasSetStoreCreditOption(true);
  })

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    });
    ga.sendPageDetails(AnalyticsPageRoutes.Exchange, AnalyticCategories.ExchangeModal);
  }, []);
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  const BaseComponent = page.type === "modal" ?
    ExchangeModalWrapper :
    $Exchange

  const renderOriginalAttributes = () => {
    let purchased = formattedSelectedExchangeProperties.exchangeAttributes
      .map(attribute => getOriginalAttributeValue(attribute.name))
      .filter(Boolean)
      .join(" • ");

    if (purchased == '') {
      purchased = customer?.selectedInstance?.purchase?.name || '';
    }

    return (
      <div className="purchase-details">
        {t('purchased', {purchased})}
      </div>);
  }

  const renderExchangeAttributes = () => {
    return formattedSelectedExchangeProperties.exchangeAttributes.map((attribute, i) => {
      const {name} = attribute;
      const attributeId = `attribute-${i}`;
      return (
        <div className="exchange-attribute">
          <div className="attribute-name" id={attributeId}>
            {attribute.name}:
            <span className="attribute-value">{currentExchange[name] || ""}</span>
          </div>
          <RadioCard
            options={attribute.values}
            selected={currentExchange[name]}
            labelledBy={attributeId}
            dataCyString={DataCyStrings.exchangeOption}
            onChange={(option) => {
              onExchangeAttributeClicked(name, option.value);
              handleOptionClickedGAEvent(attribute.name, option);
            }}
          />
        </div>
      )
    })
  }

  const renderThumbnail = () => {
    return (
      <div className="instance-image">
      {!!currentThumbnail && <img alt={selectedInstance?.purchase?.name} role="presentation" src={currentThumbnail}/>}
      </div>
    );
  }

  return (
    <BaseComponent page={page} dataCyString={DataCyStrings.exchangePage}>
      {renderThumbnail()}
      <div className="exchange-selector">
        {error &&
        <div className="alert">
          <AlertMessage
            icon="exclamation"
            color="#C10D17"
            backgroundColor="var(--warning-soft)"
            message={error}
          />
        </div>
        }
        <div className="instance-name">
          {customer.selectedInstance?.purchase?.name}
        </div>
        {renderOriginalAttributes()}
        {renderExchangeAttributes()}
        <div className="disclaimer">
          {t('exchangesAreNotGuaranteed')}
        </div>
        <div className="submit-exchange-container" >
          {/* This wrapper is to enable clicks when the button is disabled */}
          <div className="submit-exchange-button-wrapper" style={{zIndex: canSubmitExchange() ? -1 : 9999}} onClick={() => {
            onSubmitExchange();
            handleSelectButtonGAEvent();
          }}> </div>
          <PrimaryButton
            disabled={!canSubmitExchange()}
            label={t('select')}
            dataCyString={DataCyStrings.exchangePageButton}
            onButtonClick={() => {
              onSubmitExchange();
              handleSelectButtonGAEvent();
            }}
          />
        </div>

        { storeCreditOption && storeCreditOption.id && (
          <>
            <div className="store-credit-text">
              {t("storeCreditMessage")}
            </div>
            <NavigationCard
              key={storeCreditOption.id}
              title={storeCreditOption.label}
              subtext={storeCreditOption.sublabel}
              iconName={"gift-card"}
              onClick={() => onStoreCreditClicked(storeCreditOption)}
              dataCyString={DataCyStrings.storeCreditButton}
            />
          </>
        ) }
      </div>
    </BaseComponent>
  );
  //----------------------------------------------------------------------------
}

const ExchangeModalWrapper = ({page, children, dataCyString}) => {
  return (
    <PageModal page={page} width="616px" dataCyString={dataCyString}>
      <$Exchange>
        {children}
      </$Exchange>
    </PageModal>
  )
}

export default Exchange;
