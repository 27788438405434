import React from "react";
import { useDispatch } from "react-redux";

import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import Header, { HeaderLogo } from "../../components/ReturnistaHeader";
import {
  CurrentPageAction,
} from "../../redux/enums";

import { $Container, $Content, $BottomButton } from "./styles";

import ReturnistaAlertMessage from "../../components/ReturnistaAlertMessage";


const ReturnistaRequestSupportConfirmation = () => {
  const dispatch = useDispatch();

  const handleBackToHome = () => {
    dispatch({ type: CurrentPageAction.Restart});
  }

  return (
    <>
      <Header center={<HeaderLogo />} />
      <$Container data-cy="return-success-feedback">
        <$Content>
          <div className="feedback">
            <ReturnistaFeedbackIcon status="success" />
            <p data-cy="return-success-feedback-title">Request submitted</p>
          </div>
          <div className="hint">
            <p className="text-center">
              Your Request has been recorded and a member of our team would reach out to your location with details of your order.
            </p>
          </div>
          <div className="hint">
            <ReturnistaAlertMessage
                type="message"
                message={["Tell the customer:", "\“We're out of supplies and can't process your return today. Please visit a different Happy Returns location to drop-off your return. There is a link in your return email that shows all the locations.\”"]}
                dataCyString="recording-message"
              />
          </div>
        </$Content>
        <$BottomButton>
          <ReturnistaPrimaryButton onClick={handleBackToHome} dataCyString="back-to-home">
            Back to home
          </ReturnistaPrimaryButton>
        </$BottomButton>
      </$Container>
    </>
  );
};

export default ReturnistaRequestSupportConfirmation;

