import styled from "styled-components";

const $ReturnComplete = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  .icon {
    margin-top: 48px;
  }

  .title {
    font-size: 24px;
    line-height: 28px;
    margin-top: 28px;
    color: #0B0B0B;
  }
  
  .prompt {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    color: var(--header-grey);
  }

  .home {
    margin-top: 40px;
    button {
      height: 56px;
    }
  }
  .android & {
    padding: 0 24px;
    .title {
      font-size: 18px;
    }
    .prompt {
      font-size: 15px;
    }
  }
`;

export default $ReturnComplete;