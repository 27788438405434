import axios from "axios";
import { MultiStoreAction, UserTokenAction } from "../enums";

const userTokenReducer = (state = null, action) => {
  switch (action.type) {
    case UserTokenAction.Set:
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.token}`;
      return action.token;
    case MultiStoreAction.AppLogout:
    case UserTokenAction.Unset:
      delete axios.defaults.headers.common["Authorization"];
      return null;
    default:
      return state;
  }
};

export default userTokenReducer;
