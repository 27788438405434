import styled from "styled-components";
import $SVGButton from "../../components/SVGButton/styles";
import { $AlertMessage } from "../../components/AlertMessage/styles";

const $CountItemsPresent = styled.article`
  width: min(86.11vw, 550px);
  padding: clamp(24px, 6.66vw, 80px) 24px;
  box-sizing: content-box;
  * {
    font-family: "sofia-pro", sans-serif;
  }

  &.error {
    form {
      input {
        color: var(--warning-bold);
      }
    }
  }
  .counter-container {
    width: 100%;
    height: min(46.66vw, 247px);
    margin-top: min(4.44vw, 48px);
    margin-bottom: min(4.44vw, 40px);
    border-radius: 12px;
    position: relative;
    border: 1px solid var(--border-card);
    box-sizing: border-box;
  }
  .flex-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: calc(100% - 55px);
  }
  button.confirm-items-counted {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 55px;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid var(--high-emphasis-black);
    border-radius: 0px 0px 12px 12px;
    background-color: var(--high-emphasis-black);
    color: white;
    cursor: pointer;
    user-select: none;
    &:disabled {
      border-top: 1px solid var(--border-card);
      border-left: none;
      border-right: none;
      border-bottom: none;
      color: var(--medium-emphasis-black);
      background-color: var(--disabled-grey);
    }
  }
  h1 {
    font-weight: 500;
    font-size: min(5vw, 34px);
    line-height: min(7.22vw, 40px);
    color: var(--high-emphasis-black);
    margin: 0;
  }
  h2 {
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    color: var(--medium-emphasis-black);
    margin-top: 8px;
  }
  h1,
  h2 {
    text-align: center;
    margin-top: 0;
  }
  form {
    user-select: none;
    margin-top: auto;
    margin-bottom: min(6.94vw, 46px);
    label {
      display: none;
    }
    input {
      width: min(27.28vw, 210px);
      height: min(17.78vw, 109px);
      text-align: center;
      font-weight: 500;
      font-size: min(16.66vw, 96px);
      outline: none;
      border-bottom: 1px solid var(--high-emphasis-black);
      border-top: none;
      border-left: none;
      border-right: none;
    }
    // REMOVES COUNTER BUTTONS FROM INPUT ELEMENT

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    /////////////////////////////////////////////
  }
  ${$SVGButton} {
    width: 88px;
    height: 50px;
    justify-content: center;
  }
  /* override styles to prevent regression */
  ${$AlertMessage} {
    .icon {
      margin-bottom: auto;
      padding-top: 16px;
    }
  }

  .android & {
    h1 {
      font-weight: 700;
    }
    form {
      input {
        font-weight: 500;
        line-height: 64px;
        border-bottom: 2px solid var(--high-emphasis-black);
      }
    }
  }
`;

export default $CountItemsPresent;
