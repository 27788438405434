import React from 'react';
import FinalBase from "./FinalBase";
import { FinalBaseProps } from './FinalBase/type';

// subMessages must be array of strings or it will not render
// each message with its own line break will get its own idx

interface FinalProps extends FinalBaseProps{}

function FinalSuccess({primaryMessage, subMessages}: FinalProps){
  return (
    <FinalBase
      iconName="check"
      primaryMessage={primaryMessage}
      subMessages={subMessages}
    />
  )
}


function FinalError({primaryMessage, subMessages, hideButton, showSupportCopy, supportCopyLowercaseFirstLetter}: FinalProps) {
  return (
    <FinalBase
      iconName="exclamation"
      primaryMessage={primaryMessage}
      subMessages={subMessages}
      hideButton={hideButton}
      showSupportCopy={showSupportCopy}
      supportCopyLowercaseFirstLetter={supportCopyLowercaseFirstLetter}
    />
  )
}

function FinalWarning({primaryMessage, subMessages}: FinalProps) {
  return (
    <FinalBase
      iconName="information"
      primaryMessage={primaryMessage}
      subMessages={subMessages}
    />
  )
}

export {
  FinalSuccess,
  FinalError,
  FinalWarning
}