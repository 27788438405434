// third-party imports
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";

// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";

// local imports
import $ShippingLabelRequestForm from "./styles";
import { mapURLQueryStringToObject } from "../../utility";
import { ReturnistaURLQueries } from "../../types/JWTClaims";
import axios, { AxiosResponse } from "axios";
import { setLabelCount, setToken } from "../../redux/app/actions";
import CounterInput from "../../components/CounterInput";
import { DataCyStrings } from "../../types/DataCyStrings";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import ErrorContainer, { ErrorTypes } from "../../components/ErrorContainer";

class ShippingLabelRequestFormLifecycle extends PageLifecycle {
  constructor(page, app, dispatch) {
    super(page, app, dispatch);
  }
}

/**
 * Handle form requests for the number of shipping labels needed at a return bar to ship out filled totes
 */
const ShippingLabelRequestForm = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { app } = useSelector((store: RootReducer) => store);
  const lifecycle = new ShippingLabelRequestFormLifecycle(page, dispatch, app);
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [decrementIsDisabled, setDecrementIsDisabled] = useState(true);
  const [incrementIsDisabled, setIncrementIsDisabled] = useState(true);
  const [invalidCount, setInvalidCount] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>();
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    document.title = "Shipping Labels";
    const { label } = mapURLQueryStringToObject() as ReturnistaURLQueries;
    (async () => {
      try {
        const resp = await axios.post<AxiosResponse>("/location_label/auth", { token: label });
        dispatch(setToken(resp.data));
      } catch (e) {
        if (e.response?.data.match(/link expired/)) {
          setErrorType(ErrorTypes.expired);
        } else {
          setErrorType(ErrorTypes.default);
        }
        console.error(e);
      }
      setLoadingMessage("");
    })();
  }, []);

  // disable buttons based on count
  useEffect(() => {
    if (count > 0 && count <= 10) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (count > 10) {
      setInvalidCount(true);
    } else {
      setInvalidCount(false);
    }

    if (count <= 0) {
      setDecrementIsDisabled(true);
    } else {
      setDecrementIsDisabled(false);
    }

    if (count >= 10) {
      setIncrementIsDisabled(true);
    } else {
      setIncrementIsDisabled(false);
    }
  }, [count]);

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoadingMessage("Generating shipping labels...");
    try {
      const path = `/location_label/count/${count}`;
      const resp = await axios.get(path, { responseType: "arraybuffer" });
      const contentTypeHeader = resp.headers["content-type"];
      // retrieve the shipping-labels-locationid.pdf filename
      const filename = contentTypeHeader.match(/\w+-\w+-\w+.pdf/g);

      // create the blob object with content-type "application/pdf"
      var blob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(blob, filename);
      dispatch(setLabelCount(count));
      lifecycle.advance();
    } catch (e) {
      console.error(e);
      setErrorType(ErrorTypes.default);
    }
    setLoadingMessage("");
  };

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <$ShippingLabelRequestForm className={invalidCount ? "error" : ""}>
      {!!loadingMessage ? (
        <LoadingIndicator message={loadingMessage} />
      ) : (
        <>
          {!!errorType ? (
            <ErrorContainer errorType={errorType}></ErrorContainer>
          ) : (
            <>
              <h1 className="title">How many totes are you shipping?</h1>
              <h2 className="subtitle">Each tote needs a unique label.</h2>
              <CounterInput
                value={count}
                id="labels-counter"
                onSubmit={handleSubmit}
                setInputValue={(val: number) => setCount(val)}
                increment={() => setCount(count + 1)}
                decrement={() => setCount(count - 1)}
                resetErrorState={() => {}}
                inputRef={inputRef}
                dataCy={DataCyStrings.labelRequestInput}
                disabled={isDisabled}
                buttonText="Download Shipping Labels"
                submitDataCy={DataCyStrings.labelRequestSubmitButton}
                item="items"
                separateButton={true}
                subtext="Maximum of 10 labels at a time."
                disableDecrement={decrementIsDisabled}
                disableIncrement={incrementIsDisabled}
              />
            </>
          )}
        </>
      )}
    </$ShippingLabelRequestForm>
  );
  //----------------------------------------------------------------------------
};

export default ShippingLabelRequestForm;
