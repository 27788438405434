import { useEffect, useState } from "react";

// Custom hook to get viewport width for use in component logic.
// Sourced from https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/

export const useViewport = (): { width: number } => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width };
};
