import { Page } from '../../types/Config';
import { SupportInfo } from "../../types/Config";
import { StringMap } from '../../types/StringMap';
import { FatalError, Alerts } from '../../types/LifeCycle';
import { AppRuntimes } from '../../types/AppRuntimes';

//-----------------------------------------------------------------------------
// app
//
// this slice of the store is used for general app management, such as
// navigation and authentication. it never stores customer-specific information
//
// please look here:
// https://happyreturns.atlassian.net/wiki/spaces/HRTW/pages/520552455/North+Star+Configuration+Format
// for documentation of the configuration object and page object
//-----------------------------------------------------------------------------
export interface Store {
  // true if the app is initialized
  // initialized means that everything in the store can be used to drive the app. If it's
  // not initialized, then we need to do various things (details in code below) to initialized
  // the app
  isInitialized: boolean,

  // authentication token, if any (some operations do not require auth)
  token: string,

  recaptchaToken?: string,

  // name of the runtime environment as defined here:
  // https://happyreturns.atlassian.net/wiki/spaces/HRTW/pages/520552455/North+Star+Configuration+Format
  // TODO: make this an enum or somehow constrain the legal values
  runtime: "" | AppRuntimes,

  // object containing page objects, keyed by page name, as described in the North Star page format
  // https://happyreturns.atlassian.net/wiki/spaces/HRTW/pages/520552455/North+Star+Configuration+Format
  pages: PageConfigurations,

  // object that stores key value pairs according to URL search params (?key=value...)
  queries: object,

  disableHeaderLinks: boolean,

  // name of the first page in the user experience.
  // must be the name of a page in the pages array.
  // if empty the app will not load beyond basic authentication
  initialPageName: string,

  // name of currently loaded page in the app.
  // must be a key in the pages object
  // if empty the app will try to load (or reload) itself.
  currentPageName: string,

  // name of the currently loaded modal page.
  // A modal page allows to maintain a linear flow
  // in our app config when an app requires
  // navigation between pages and modals while
  // keeping the current page in the background for a seamless
  // transition between page modals and regular pages
  currentModalPageName: string,
  // name of the fatal error that has occurred
  // if this is set, something bad has happened and the site cannot continue
  // currently the following error names are supported:
  fatalErrorName: FatalError,

  // whether or not the return portal is in a timeout state
  // which is set to indicate a timeout modal should be displayed,
  // and should be cleared when navigating away from the modal.
  returnPortalTimeout: boolean,

  // if non-empty the site is in a loading mode
  // (nonresponsive, the user is shown the provided message along with a spinning wheel of sorts)
  loadingMessage: string,

  // the name of the currently showing alert modal
  // (if empty, no alert is showing)
  alertName: Alerts | "",

  // used for when a page needs to handle an error in a unique way
  localErrorMessage: string,
  // used to determine which path was used to arrive at at the instanceList page
  // so we know which to go back to when pressing back
  scanPath: "typeExpressCode" | "scanExpressCode" | "",

  // used to determine which path was used to arrive at the typeBagBarcode page, so we know where to go back
  confirmationPath: "confirmation" | "confirmationScanTote" | "",

  // used to store information about the currently logged in user.
  user: object,

  // used to track the time taken for a user to complete a return
  returnStarted: Date,

  // a series of key value pairs to use for dynamic copies on a runtime basis
  copies: StringMap,

  links?: StringMap,

  colors?: StringMap,

  isWhiteLabel?: boolean,
  // used to build support info copies on a runtime basis
  supportInfo: SupportInfo,

  locale?: string,

  enableLocalization?: boolean,

  enabledLocales?: Record<string, boolean>,

  enableCustomerReturnNotes?: boolean,

  singleOrderReturns?: boolean,

  platform?: "android" | "",

  returnShoppingEnabled?: boolean,

  returnShoppingFeeWaiver?: boolean,

  returnShoppingDiscount?: number,

  returnShoppingURL?: string,

  labelCount?: string,

  expandMoreRefundOptions: boolean,
}

interface PageConfigurations {
	[index: string]: Page,
}

export const SET_TOKEN = "SET_TOKEN";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
export const GOTO_PAGE = "GOTO_PAGE";
export const ON_FATAL_ERROR = "ON_FATAL_ERROR";
export const SET_RETURN_PORTAL_TIMEOUT = "SET_RETURN_PORTAL_TIMEOUT";
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";
export const FINALIZE = "FINALIZE";
export const SET_ALERT_NAME = "SET_ALERT_NAME";
export const RESET_APP = "RESET_APP";
export const SET_QUERIES = "SET_QUERIES";
export const SET_USER = "SET_USER";
export const SET_LOCAL_ERROR_MESSAGE = "SET_LOCAL_ERROR_MESSAGE";
export const PORTAL_PURCHASES_ERROR = "PORTAL_PURCHASES_ERROR";
export const SET_SCAN_PATH = "SET_SCAN_PATH";
export const SET_CONFIRMATION_PATH = "SET_CONFIRMATION_PATH"
export const SET_EXPIRED_TOKEN_RETURNISTA = "SET_EXPIRED_TOKEN_RETURNISTA";
export const SET_RETURN_STARTED = "SET_RETURN_STARTED";
export const SET_LOCALE = "SET_LOCALE";
export const SET_PLATFORM = "SET_PLATFORM";
export const SET_RETURN_SHOPPING_ENABLED = "SET_RETURN_SHOPPING_ENABLED";
export const SET_LABEL_COUNT = "SET_LABEL_COUNT"
