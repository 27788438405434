import React from 'react';

import ModalContainer from "../ModalContainer";
import PrimaryButton from "../../Button/PrimaryButton";


// simple modal with only one button and an X button in the corner,
// both of which trigger the same callback
type AlertModalTypes = {
  // title of the modal
  title: string,

  // explanatory message
  message: string,

  // label for button
  buttonLabel: string,

  // callback, triggered when the user clicks the primary button
  // or the close button that appears on the modal
  onHide: () => void,

  primaryButtonOnClick?: Function,

  // determines runtime of application
  runtime: string,

  // returnista platform
  platform?: string
};
const AlertModal = ({
  title,
  message,
  buttonLabel,
  onHide,
  primaryButtonOnClick,
  runtime,
  platform
}: AlertModalTypes) => {

  return (
    <ModalContainer
      isOpen={true}
      onRequestClose={onHide}
      contentLabel={title}
      primaryMessage={title}
      subMessages={[message]}
      closeIcon={true}
      runtime={runtime}
      platform={platform}
      button={
        <PrimaryButton
          onButtonClick={primaryButtonOnClick ? primaryButtonOnClick : onHide}
          label={buttonLabel}
        />
      }
    />
  )
}

export default AlertModal;
