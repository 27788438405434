import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// helpers
import useSelector from '../../utility/useTypedSelector';
import { getReturnistaJWTClaims, mapURLQueryStringToObject } from '../../utility';
import { setLocalErrorMessage, startReturnReturnista } from '../../redux/app/actions';
import { reset as resetCustomer } from "../../redux/customer/actions";

// types
import { PageLifecycle } from '../';
import { ReturnistaURLQueries } from '../../types/JWTClaims';
import { FatalErrors } from '../../types/LifeCycle';

// components
import PrimaryButton from '../../components/Button/PrimaryButton';
import ContactInfo from '../../components/ContactInfo';
import { SVG } from '../../components/svg';
import { ErrorAlertMessage } from "../../components/AlertMessage"

import { $Home } from './styles';
import ga from '../../utility/GAEmitter';
import { DataCyStrings } from '../../types/DataCyStrings';


class HomeLifeCycle extends PageLifecycle {
  token: string;
  constructor(page, dispatch, app){
    super(page, dispatch, app)
    this.token = app.token;
  }
  // if the token isn't empty, return true
  satisfiesPreconditions() {
    return this.token != "";
  }
}

/**
* currently used in Returnista after a user logs in
*/
const Home = ({ page }) => {
  const app = useSelector(store => store.app);
  const { token, localErrorMessage } = app;

  const dispatch = useDispatch();

  const claims = getReturnistaJWTClaims(token);

  const urlQueries = mapURLQueryStringToObject() as ReturnistaURLQueries;

  const lifecycle = new HomeLifeCycle(page, dispatch, app);

  var startReturnButtonDisabled = false;

  const onStartReturn = () => {
    dispatch(setLocalErrorMessage(""));
    dispatch(startReturnReturnista());
    lifecycle.advance();
  }

  useEffect(() => {

    // flush the customer data when this page renders
    dispatch(resetCustomer());

    if (!lifecycle.satisfiesPreconditions()) {
      lifecycle.advanceToFatalError(FatalErrors.token);
    }
  }, []);

  var errorMessage = localErrorMessage
  var returnProcessingIssue = claims.returnsApp?.returnProcessingIssue ?? "";
  if (returnProcessingIssue != "") {
    startReturnButtonDisabled = true;
    errorMessage = returnProcessingIssue.replace('ERROR_LOCATION_INACTIVE: ', '')
  }

  return (
    <$Home>
      {errorMessage &&
        <div className="error">
          <ErrorAlertMessage message={errorMessage} />
        </div>
      }
      <div className="user-info">
        <SVG name="genericUser" />
        <div className="name" data-cy={DataCyStrings.homePageNameDisplay}>{claims.returnsApp?.representative?.name}</div>
        <div className="info" data-cy={DataCyStrings.homePageUserInfoDisplay}>
          Username: {claims.returnsApp?.representative?.login} <br />
          Location: {claims.returnsApp?.locationID}
          {claims.returnsApp?.retailerID &&
            <><br />Retailer: {claims.returnsApp.retailerID}</>
          }
        </div>
        <PrimaryButton disabled={startReturnButtonDisabled} width="100%" label="Start a Return" onButtonClick={onStartReturn} dataCyString={DataCyStrings.homePageStartReturnButton}/>
      </div>
      <ContactInfo email={"returnbar@happyreturns.com"} phoneNumber={"(877) 750-4888"} versionID={urlQueries.versionID} />
    </$Home>
  );
}






export default Home;
