"use strict";
import "core-js/stable";
import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./src/app";
import ReturnistaApp from "./src/returnista-app";
import { store, persistor } from "./src/redux/store";
import { store as returnistaStore } from "./src/redux/returnista-store";
import GlobalStyles from "./src/globalStyles";
import * as _ from "./global";
import { isReturnistaApp, mapURLQueryStringToObject } from "./src/utility";
import { ReturnistaURLQueries } from "./src/types/JWTClaims";
import axios from "axios";
import Cookies from "js-cookie";
import { RenderReturnistaProps } from "./src/returnista-app/types";
import logger from "./src/utility/logger/logger";
import ga from "./src/utility/GAEmitter";

async function getReturnistaRedesign() {
  let locationId: string | null = null;

  if (isReturnistaApp(window.location.hostname)) {
    const urlQueries = mapURLQueryStringToObject() as ReturnistaURLQueries;
    locationId = urlQueries.locationID;
  } else {
    let token = Cookies.get("token");
    if (token == null) {
      const { authorization } = mapURLQueryStringToObject();
      token = authorization;
    }
    if (token != null) {
      const [unusedHeader, payload] = token.split(".");
      const parsedPayload = JSON.parse(atob(payload));
      const appData = JSON.parse(parsedPayload.returnsApp);
      locationId = appData.locationID;
    }
  }

  // if we're not using returnista or a partner return we can exit early
  if (locationId == null || locationId == false) return false;

  // otherwise, call the API and determine if we should use the redesigned experience
  const { data } = await axios.get(`/use-returnista-redesign?locationID=${locationId}`);
  // retrieve feature flags from frontman.location_settings table
  const featureFlags = (await axios.get(`/location/settings/${locationId}`))?.data;

  return { ...data, featureFlags };
}

const RenderApps = (): ReactElement => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyles />
        <App />
      </PersistGate>
    </Provider>
  );
};

const RenderReturnista = ({ featureFlags }: RenderReturnistaProps): ReactElement => {
  ga.initialize("returnista");
  return (
    <Provider store={returnistaStore}>
      <GlobalStyles />
      <ReturnistaApp featureFlags={featureFlags} />
    </Provider>
  );
};

const RedesignAppHandler = (data: {
  useReturnistaRedesign: boolean | unknown;
  featureFlags: { [key: string]: boolean };
}): void => {
  const urlQueries = mapURLQueryStringToObject() as ReturnistaURLQueries;
  const { locationID } = urlQueries;

  if (data.useReturnistaRedesign) {
    logger.Info(`${locationID} is using the Returnista Redesign`);
    ReactDOM.render(<RenderReturnista featureFlags={data.featureFlags} />, document.getElementById("app"));
  } else {
    ReactDOM.render(<RenderApps />, document.getElementById("app"));
  }
};

getReturnistaRedesign()
  .then(RedesignAppHandler)
  .catch((_) => {
    ReactDOM.render(<RenderApps />, document.getElementById("app"));
  });
