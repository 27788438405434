import React, { FormEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentPageAction,
  ModalAction,
  MultiStoreAction,
  BarcodeAction,
  ScannerType,
  NoMatchWarningAction,
  BARCODE_INCLUDES_RID,
  InvalidItemCode,
  EventAction,
} from "../../redux/enums";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import { SVG } from "../../components/svg";
import { $ManualCodeLinkWrapper, $ManualInput, $Input, $NoBarCodeButtons, $IconWrapper } from "./styles";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import { RootReducer } from "../../redux/returnista-store";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import {
  errorTextOrModal,
  isBagCodeLike,
  isConfirmationCodeLike,
  isShippingLabelCodeLike,
  openScanner,
  normalizeMatchingString,
  removeSpecialCharacters,
  headerTextHandler,
  isUrlCodeLike,
} from "../../utility";
import ReturnistaAnalytics, { ReturnistaAnalyticsErrors } from "../../utility/ReturnistaAnalytics";
import Content from "../../components/ReturnistaContent";
import { ReturningType } from "../../redux/returnistaReducers/returnObject";
import logger from "../../utility/logger/logger";
import { ItemScanActions } from "../../types/ReturnistaGA";
import ga from "../../utility/GAEmitter";
import { setReturnistaEvent } from "../../utility/returnistaEvents";
import { eventLabels } from "../../redux/returnistaReducers/events";

// GA consts
const scan = "item code scan";
const entry = "item code entry";

type MatchData = {
  // A reference to the instance object that was matched against
  data: ReturningType | undefined;
  // The input value received from the user
  label: string;
  // The level of confidence in the input value matching the value on the instance field; 100 indicates
  // a certain match with lower values representing more ambiguity around match certainty
  confidence: number;
  // The field on the instance that was matched against, such as "barcode" or "sku", as a means of determining
  // what in particular we matched against from the instance
  matched: string | null;
};

export const getMatchData = (labelInput: string, retailerID: string, instanceList: Array<ReturningType>): MatchData => {
  let matchingItem: MatchData = {
    data: undefined,
    label: labelInput,
    confidence: 0,
    matched: null,
  };

  const matchList = instanceList.map((r) => ({
    id: r.id,
    barcode: r?.purchase?.barcode ?? r?.purchase?.details?.barcode ?? r?.label?.barcode,
    sku: r?.purchase?.sku ?? r?.purchase?.details?.sku ?? r?.label?.sku,
    upc: r?.purchase?.upc ?? r?.purchase?.details?.upc ?? r?.label?.upc,
    product_id: r?.purchase?.product_id ?? r?.purchase?.details?.product_id,
    purchase_id: r?.purchase?.id,
  }));

  // Remove special characters and convert input to upper case.
  const labelUpperCase = labelInput.toUpperCase();
  const labelInputNormalized = normalizeMatchingString(labelUpperCase);
  if (labelInputNormalized === "") return matchingItem;

  matchList.forEach((matchItem) => {
    Object.entries(matchItem).forEach(([matchField, value]) => {
      if (matchingItem.data != null || value == null) return;
      const valueUpperCase = value?.toString().toUpperCase();
      const stringToMatch = normalizeMatchingString(valueUpperCase);
      const { PRINCESS_POLLY, FABLETICS, TRUE_CLASSIC_TEES, PARCELLAB_TCT } = BARCODE_INCLUDES_RID;
      const item = instanceList.find((r) => r.id === matchItem.id);

      // If we don't need to fully normalize confidence is 100; otherwise confidence is 95
      let confidence = 100;
      if (
        removeSpecialCharacters(labelUpperCase) !== labelInputNormalized ||
        removeSpecialCharacters(valueUpperCase) !== stringToMatch
      ) {
        confidence = 95;
      }

      // TrueClassicTees case, match when values on both sides match TCT#### where # is any number
      // i.e. if both the input and the field start with "TCT1234" there should be a match
      if (retailerID === TRUE_CLASSIC_TEES || retailerID === PARCELLAB_TCT) {
        const matcher = new RegExp(/^TCT[0-9]{4}/, 'i');
        const bothPassValidation = matcher.test(stringToMatch) && matcher.test(labelInputNormalized);
        const bothHaveSamePrefix = stringToMatch.slice(0, 7) === labelInputNormalized.slice(0, 7);
        if (bothPassValidation && bothHaveSamePrefix) {
          matchingItem = { data: item, label: labelInput, confidence, matched: matchField };
          return;
        }
      }

      // Princess Polly case, match when values on the instance contain the barcode and are bigger
      // then 6 letters long
      // i.e. there should be a match when the input is "FOOOOO" and a field has a value of "FOOOOOBAR"
      if (retailerID === PRINCESS_POLLY) {
        if (stringToMatch.includes(labelInputNormalized) && labelInputNormalized.length >= 6) {
          matchingItem = { data: item, label: labelInput, confidence, matched: matchField };
          return;
        }
      }

      // Fabletics case, match when the scanned barcode contains values on the instance
      // i.e. there should be a match when the input is "FOOBAR" and a field has a value of "FOO"
      if (retailerID === FABLETICS) {
        if (labelInputNormalized.includes(stringToMatch)) {
          matchingItem = { data: item, label: labelInput, confidence, matched: matchField };
          return;
        }
      }

      // Fully normalized match; match when both fully normalized strings match
      if (labelInputNormalized === stringToMatch) {
        matchingItem = { data: item, label: labelInput, confidence, matched: matchField };
        return;
      }
    });
  });

  return matchingItem;
};

const ReturnistaItemCodeScan = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>("");
  const { returnObject, currentBag, items, invalidItemCodes, currentPage } = useSelector<RootReducer, RootReducer>(
    (state) => state
  );
  const { retailerID, returning } = returnObject;
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [isModalError, setIsModalError] = useState<boolean>(false);

  const confirmNobarCode = (): void => {
    ga.pageEvent({
      category: currentPage,
      action: ItemScanActions.ContinueWithoutBarcode,
    });

    const handleScanBarcode = (): void => {
      openScanner(ScannerType.ItemCode);
      dispatch({ type: ModalAction.Unset });
    };
    const goToSelectItem = (): void => {
      dispatch({
        type: EventAction.Add,
        event: setReturnistaEvent({
          returnID: returnObject.id,
          eventTypeLabel: eventLabels.ITEM_NO_BARCODE_FOUND,
        }),
      });
      dispatch({ type: CurrentPageAction.SelectItem });
      dispatch({ type: ModalAction.Unset });
      dispatch({ type: NoMatchWarningAction.Unset });
    };

    dispatch({
      type: ModalAction.Set,
      modalProps: {
        primaryMessage: "Have you searched for the barcode?",
        onRequestClose: () => dispatch({ type: ModalAction.Unset }),
        iconElement: (
          <$IconWrapper data-cy="barcode-warning-icon-modal">
            <ReturnistaFeedbackIcon status="warning" />
          </$IconWrapper>
        ),
        button: (
          <$NoBarCodeButtons>
            <ReturnistaPrimaryButton variant="outlined" onClick={handleScanBarcode} dataCyString="close-modal">
              Scan barcode
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton onClick={goToSelectItem} dataCyString="no-barcode-found">
              No barcode found
            </ReturnistaPrimaryButton>
          </$NoBarCodeButtons>
        ),
      },
    });
  };

  useEffect(() => {
    // Handle code event emitted from app wrappers
    window.enterCode = (code: string) => {
      itemScan(code, true);
    };
    // Handle continue without barcode from app wrappers
    window.noBarcode = () => {
      confirmNobarCode();
    };
    // Open the scanning screen for this page in thin client
    openScanner(ScannerType.ItemCode);
    return () => {
      // Reset handlers to report error if called after unmount
      window.enterCode = () => {
        ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedEnterCode);
      };
      window.noBarcode = () => {
        ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedNoBarcode);
      };
    };
  }, []);

  const inputRef = useRef<string>("");

  const handleChange = (e: FormEvent<HTMLInputElement>): void => {
    setError("");
    setDisableBtn(false);
    if (e.currentTarget.value.length === 0) {
      setDisableBtn(true);
    }
    inputRef.current = e.currentTarget.value.toUpperCase();
  };

  const itemScan = (barcode: string, usingScanner = false): void => {
    const invalidBarcode = [
      usingScanner
        ? "Invalid barcode scanned"
        : "That doesn't match any of our records. Enter a barcode on the item you're processing.",
      usingScanner ? "That doesn't match any of our records. Scan a barcode on the item you're processing." : "",
      usingScanner,
    ];
    const scannedConfirmationError = [
      usingScanner ? "Scan item's barcode." : "It looks like you scanned the customer's QR code.",
      usingScanner
        ? "It looks like you scanned the customer's QR code. Please scan the item's barcode."
        : "Please scan the item's barcode.",
      usingScanner,
    ];
    const scannedBagBarcode = [
      usingScanner ? "Scan item's barcode." : "It looks like you scanned the Happy Returns bag code",
      usingScanner
        ? "It looks like you scanned the Happy Returns bag code. Please scan the item's barcode."
        : "Please scan the item's barcode.",
      usingScanner,
    ];
    const scannedShippingLabelError = [
      usingScanner ? "Scan item's barcode." : "It looks like you scanned a shipping label.",
      usingScanner
        ? "It looks like you scanned a shipping label. Please scan the item's barcode."
        : "Please enter a item's barcode.",
      usingScanner,
    ];
    const alreadyUsedCode = [
      usingScanner
        ? "Repeated scan"
        : "You entered that barcode previously. Enter a barcode on the item you're processing.",
      usingScanner ? "You scanned that barcode previously. Scan a barcode on the item you're processing." : "",
      usingScanner,
    ];
    const scannedUrlError = [
      usingScanner
        ? "Website URL scanned"
        : "That barcode leads to a website. Try entering a different barcode on the item you're processing.",
      usingScanner
        ? "That barcode leads to a website. Try scanning a different barcode on the item you're processing."
        : "",
      usingScanner,
    ];

    const remainingInstances = returning.filter((r) => !items.allIds.includes(r.id) && !r.purchase.returnStarted);
    const matchingItem = getMatchData(barcode, retailerID, remainingInstances);

    // If a matching item was found, add the item to the current bag and advance
    if (matchingItem.data != null) {
      ReturnistaAnalytics.codeEntered(usingScanner, "item");
      dispatch({
        type: MultiStoreAction.AddItem,
        bag: currentBag,
        item: matchingItem,
      });
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.ScanSuccessful}`,
      });
      dispatch({ type: CurrentPageAction.Next });
    } else if (barcode.length < 6) {
      displayError.apply(this, invalidBarcode);
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.InvalidCode}`,
      });
    } else if (isBagCodeLike(barcode)) {
      displayError.apply(this, scannedBagBarcode);
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.BagCodeScanned}`,
      });
    } else if (isConfirmationCodeLike(barcode)) {
      displayError.apply(this, scannedConfirmationError);
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.ConfirmationCodeScanned}`,
      });
    } else if (isShippingLabelCodeLike(barcode)) {
      displayError.apply(this, scannedShippingLabelError);
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.ShippingLabelScanned}`,
      });
    } else if (invalidItemCodes.includes(barcode)) {
      logger.Error(`Invalid code ${barcode} was rescanned`);
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.ItemCodeAlreadyScanned}`,
      });
      ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.ReusedInvalidScan);
      displayError.apply(this, alreadyUsedCode);
    } else if (isUrlCodeLike(barcode)) {
      displayError.apply(this, scannedUrlError);
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.WebsiteUrlScanned}`,
      });
    } else {
      dispatch({
        type: EventAction.Add,
        event: setReturnistaEvent({
          returnID: returnObject.id,
          eventTypeLabel: eventLabels.ITEM_SCAN_NO_MATCH,
        }),
      });
      ga.pageEvent({
        category: currentPage,
        action: `${usingScanner ? scan : entry} ${ItemScanActions.NotAMatch}`,
      });
      dispatch({ type: CurrentPageAction.SelectItem });
      dispatch({ type: BarcodeAction.Set, barcode: barcode });
      dispatch({ type: NoMatchWarningAction.Set });
      dispatch({ type: InvalidItemCode.Add, itemCode: barcode });
    }
  };

  const displayError = (primaryMessage: string, subMessage = "", useModal = false) => {
    errorTextOrModal({
      primaryMessage,
      subMessage,
      useModal,
      scannerType: ScannerType.ItemCode,
      dispatch,
      errorSetter: setError,
      usingModalSetter: setIsModalError,
    });
  };

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
    itemScan(inputRef.current);
  };

  const headerMessages = ["Scan barcode on the item", "Enter barcode on the item"];

  useEffect(() => {
    if (error.length > 0) {
      setDisableBtn(true);
    }
  }, [error]);

  return (
    <>
      <Header
        left={<HeaderBackButton gaCategory={currentPage} gaAction={ItemScanActions.BackButton} />}
        center={headerTextHandler({ usingScanner: isModalError, headerMessages })}
      />
      <Content>
        <$ManualCodeLinkWrapper>
          <$ManualInput>
            <div className="icon">
              <SVG fill="#221F1F" stroke="#221F1F" name="returnistaBarCode" />
            </div>
            <p data-cy="barcode-title" className="title">
              Enter barcode on the item
            </p>
            <form onSubmit={handleSubmit} action="">
              <$Input
                data-cy="barcode-input"
                title="barcode manual input"
                autoFocus
                hasError={error.length > 0}
                onChange={handleChange}
                type="text"
              />
              {error && (
                <p data-cy="barcode-input-error" className="error">
                  {error}
                </p>
              )}
              <ReturnistaPrimaryButton
                disabled={disableBtn}
                className="submitQrCodeButton"
                dataCyString="submitBarcodeButton"
                type="submit"
              >
                Continue
              </ReturnistaPrimaryButton>
              <ReturnistaPrimaryButton
                onClick={confirmNobarCode}
                variant="outlined"
                className="continue-without-barcode"
                dataCyString="continue-without-barcode-button"
              >
                Continue without barcode
              </ReturnistaPrimaryButton>
            </form>
          </$ManualInput>
        </$ManualCodeLinkWrapper>
      </Content>
    </>
  );
};

export default ReturnistaItemCodeScan;
