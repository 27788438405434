import React, { useState } from "react";
import ModalContainer from "../Modal/ModalContainer";
import TextInput from "../TextInput";
import PrimaryButton from "../Button/PrimaryButton";
import useModalStyles from "../../utility/useModalStyles";
import { isValidEmail } from "../../utility";
import getTranslator from "../../utility/getTranslator"
import { DataCyStrings } from "../../types/DataCyStrings";
import { TFunctionResult } from "i18next";

const useTranslation = getTranslator("EmailModal")

interface SubmitHandlerArgs {
  email: string,
  setError: (string) => void,
  onSuccess: (string) => void,
}

interface EmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (string) => void;
  primaryMessage: string | TFunctionResult;
  subMessage: string | TFunctionResult;
  successMessage?: string | TFunctionResult;
  // onSubmit is an optional async handler that can be used to
  // send a request based on the client-validated email input and
  // set an error message
  onSubmit?: (arg0:SubmitHandlerArgs) => Promise<void>,
}

interface EmailModalState {
  email: string,
  error: string,
  isSuccess: boolean,
  submitting: boolean,
}

const EmailModal = ({isOpen, onClose, onSuccess, primaryMessage, subMessage, successMessage, onSubmit}:EmailModalProps) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const resetState = () => {
    setEmail("");
    setError("");
    setIsSuccess(false);
  }

  const onRequestClose = () => {
    if (!submitting) {
      resetState();
      onClose();
    }
  }

  const _onSubmit = onSubmit || (async ({email, onSuccess}) => {
    // by default, just pass trimmed and validated email through to onSuccess
    onSuccess(email)
  });

  const onSubmitClicked = async () => {
    setSubmitting(true);
    setError("");
    const value = (email || "").trim();
    if (!isValidEmail(value)) {
      setError(t('pleaseDoubleCheck'));
    } else {
      // Wrap original onSuccess, so that we can update appropriate states.
      const _onSuccess = (value) => {
        setIsSuccess(true);
        setSubmitting(false);
        setError("");
        setEmail("");
        onSuccess(value);
      }
      await _onSubmit({email, setError, onSuccess: _onSuccess});
    }
    setSubmitting(false);
  }

  const canSubmit = (val:string) => !submitting && !!val && val.trim().length >= 6;

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      primaryMessage={primaryMessage}
      subMessages={[subMessage]}
      closeIcon={true}
      styles={useModalStyles()}
      dataCyString={DataCyStrings.emailNeededModal}
      textInput={
        <TextInput
          label={t('emailAddress')}
          type="email"
          value={email}
          onChange={setEmail}
          dataCyString={DataCyStrings.emailNeededModalTextInput}
        />
      }
      textInputError={error}
      successMessage={isSuccess ? successMessage : ""}
      button={
        <PrimaryButton
          onButtonClick={onSubmitClicked}
          label={t('submit')}
          width="100%"
          disabled={!canSubmit(email)}
          dataCyString={DataCyStrings.emailNeededModalSubmitButton}
        />
      }
    />
  )
};

export default EmailModal;
