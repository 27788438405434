import React, { ReactElement } from "react"

type Wrapper = (children: React.ReactNode) => React.ReactNode

/*
  utility class for conditionally wrapping JSX markup
*/
export const WrapIf = ({condition, wrapIf, wrapElse, children } : {condition: boolean, wrapIf: Wrapper, wrapElse?: Wrapper, children: React.ReactNode }) : JSX.Element => {
  
  if (condition)
    return <>{wrapIf(children)}</>

  if (wrapElse)
    return <>{wrapElse(children)}</>
    
  return <>{children}</>
}

/*
  utility class for conditionally wrapping JSX markup in more than 2 ways using a lookup table
*/
// this should be uncommented and committed with unit tests at a later date when needed

// export const WrapSwitch = <T extends string | number | symbol>({value, wrapperMap, wrapDefault, children}: {value: T, wrapperMap: Record<T, Wrapper>, wrapDefault?: Wrapper, children: React.ReactFragment}) : JSX.Element => {
//   const matchedWrapper = wrapperMap[value]
//   if (matchedWrapper)
//     return <>{matchedWrapper(children)}</>
  
//   if (wrapDefault)
//     return <>{wrapDefault(children)}</>
 
//   return <>{children}</>
// }
