import { MultiStoreAction, ReturnObjectAction } from "../enums";

export type ReducerState = {
  id: string;
  confirmationCode: string;
  retailerID: string;
  retailer: RetailerType;
  returning: Array<ReturningType>;
};

type RetailerType = {
  id: string;
  name: string;
};

export type ReturningType = {
  id: string;
  purchase: PurchaseType;
  label?: {
    sku?: string;
    barcode?: string;
    upc?: string;
  };
};

type PurchaseType = {
  id: string;
  name: string;
  returnStarted: boolean;
  display: Array<{ label: string; value: string }>;
  thumbnail: string;
  images: Array<string>;
  vendor: string;
  sku?: string;
  barcode?: string;
  upc?: string;
  product_id?: string;
  details: {
    sku?: string;
    barcode?: string;
    upc?: string;
    product_id?: string | number;
  };
};

const returnObjectReducer = (state = null, action) => {
  switch (action.type) {
    case ReturnObjectAction.Set:
      return action.returnObject;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case ReturnObjectAction.Unset:
      return null;
    default:
      return state;
  }
};

export default returnObjectReducer;
