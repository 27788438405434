import styled from "styled-components";

export const $StatusBarContainer = styled.div`
  height: 50px;
  position: relative;
  margin-top: 24px;
  width: 100%;
`;

export const $StatusBarBackground = styled.div`
  background-color: #a2a2a2;
  border-radius: 24px;
  height: 8px;
  left: 12px;
  position: absolute;
  top: 8px;
  width: calc(100% - 24px);
`;

export const $ProgressBarContainer = styled.div`
  border-radius: 24px 0 0 24px;
  height: 8px;
  left: 12px;
  position: absolute;
  top: 8px;
  width: calc(100% - 24px);

  display: flex;
  flex-direction: row;

  div {
    flex-grow: 1;
  }
`;

export const $ActiveProgressBar = styled.div<{ borderRadius?: string }>`
  background-color: var(--color-primary);

  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
`;

export const $StatusNodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
`;

export const $StatusNodeTextContainer = styled.div<{ alignItems?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;

  align-items: ${(props) => (props.alignItems ? props.alignItems : "")};

  span {
    position: absolute;
    top: 28px;

    color: var(--black-900-primary, #221f1f);
    font-family: "sofia-pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`;

export const $StatusNode = styled.div<{ checkColor?: string }>`
  background-color: #a2a2a2;
  border-radius: 50%;
  height: 24px;
  position: relative;
  width: 24px;

  svg {
    bottom: 4px;
    position: relative;
    width: 24px;

    #backgroundCircleColor {
      fill: var(--color-primary);
    }
    #checkColor {
      fill: ${(props) => props.checkColor};
    }
  }
`;
