import { BarcodeAction, MultiStoreAction } from "../enums";

const barcodeReducer = (state = null, action) => {
  switch (action.type) {
    case BarcodeAction.Set:
      return action.barcode;
    case BarcodeAction.Unset:
      return null;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    default:
      return state;
  }
};

export default barcodeReducer;
