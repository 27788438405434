// third-party imports
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import QRCode from "qrcode";
import DOMPurify from "dompurify";
import { Trans } from "react-i18next";
import { enableMapSet } from "immer";
import DeviceDetector from "device-detector-js";

// repo imports
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";
import { SVG } from "../../components/svg";
import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { ReturnPortalBaseWithPage } from "../../components/ReturnPortalBase";
import { clearLoadingIndicator, showLoadingIndicator, goToPage, setToken } from "../../redux/app/actions";
import { addFeatureStudyID, reset } from "../../redux/customer/actions";
import {
  DropoffMethodIDs,
  Returns,
  ReturnShoppingItem
} from "../../redux/customer/types";
import { ExchangeProperties, Refund, Return, ReturnExpiration } from "../../types/Instance";
import { FeatureStudyIDs } from "../../types/FeatureStudyIDs";
import {
  prepareReturn,
  getReturnistaJWTClaims,
  handleEnterKeyPressOnClickHandlers,
  getSavedRBLocations, getAdminMode,
} from "../../utility";
import {
  AnalyticCategories,
  AnalyticsPageRoutes,
  PreviewPageActions,
  ReturnStartedAndReturnStatusPageActions
} from "../../types/Analytics";

// local imports
import $ReturnStarted from "./styles";
import EmailModal from "../../components/EmailModal";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";

import getTranslator from "../../utility/getTranslator";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";
import ExpressCodeApplePassButton from "../../components/ExpressCodeApplePassButton";
import { ExpressCodeApplePassData } from "../../types/ExpressCodeApplePassData";
import logger from "../../utility/logger/logger";
import { Retailer } from "../../types/Retailer";

const useTranslation = getTranslator("ReturnStarted");
const returnExpMsgSplit = 0.5;
const returnStatusOnSplit = 1.0;
const localeUS = 'en-US'
enableMapSet();

export const SendToAnotherEmailModal = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <EmailModal
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={() => {}}
      primaryMessage={t("sendConfirmationTo")}
      subMessage={t("provideAnEmailAddress")}
      successMessage={t("aConfirmationHasBeen")}
      onSubmit={onSubmit}
    />
  );
};

/**
 * Return started confirmation page
 */
const ReturnStarted = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { app, customer } = useSelector((store: RootReducer) => store);
  const {
    copies,
    locale
  } = useSelector((store) => store.app);
  const retailerName = copies?.retailerName
  const [isAdminMode, setIsAdminMode] = useState<boolean>(getAdminMode());
  const { t } = useTranslation();
  const { links, returnShoppingDiscount, returnShoppingFeeWaiver } = app;
  const {
    itemsMarkedForCompletion,
    returns,
    selectedDropoffMethod,
    returnType,
    newReturnEmail,
    changeDropoffReturnID,
    query,
    purchasing,
    returnSessionID,
    retailer,
  } = customer;
  const [isLoading, setIsLoading] = useState(true);
  const [startedReturn, setStartedReturn] = useState<Return | Returns>();
  const [startReturnError, setStartReturnError] = useState("");
  const [confirmationCodeQR, setConfirmationCodeQR] = useState("");
  const [retailerID, setRetailerID] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [qrCodeType, setQrCodeType] = useState("");
  const [returnExpirationMessage, setReturnExpirationMessage] = useState("");
  const [expressCodeApplePassData, setExpressCodeApplePassData] = useState<ExpressCodeApplePassData>({
    confirmationCode: "",
    expirationDate: new Date(),
    retailerName: "",
    returnID: "",
    numItemsInReturn: 0,
  });
  const [customRetailerCopy] = useState(
    app.copies["confirmationNextSteps"] ||
      (app.returnShoppingEnabled && t("exchangesAndNewOrders")) ||
      t("exchangesAreNotGuaranteed")
  );
  // Send Another Email modal
  const [displayEmailModal, setDisplayEmailModal] = useState(false);
  // Return Shopping Items
  const [returnShoppingItems, setReturnShoppingItems] = useState<ReturnShoppingItem[]>([]);

  interface ReturnStartedResponse {
    return: Return
    returnStatusToken: string
  }

  //----------------------------------------------------------------------------
  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    startReturn();
  }, []);

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale
        //DO NOT SEND change_dropoff as we want to track if the customer came after clicking on change dropoff (should be set in StartReturnPortal)
      }
    })
    ga.sendPageDetails(AnalyticsPageRoutes.ReturnStarted, AnalyticCategories.ReturnStartedPage);
  }, []);

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS
  const startReturn = async () => {
    const showReturnStatus = true

    if (showReturnStatus) {
      // TODO CF-732, CF-199 replace with `"featureStudies=[<id>, <id>, ...]" or some other way to indicate which
      // feature studies are being applied
      document.cookie = "featureStudyReturnStatus=true"; // used to tell Cypress which validations to run
    }

    dispatch(showLoadingIndicator(defaultLoadingSymbol));
    setIsLoading(true);
    if (changeDropoffReturnID) {
      setStartedReturn(returns);
      setReturnShoppingItems(purchasing?.items);
      await generateQR(returns?.confirmationCode);
      if (isByMailQrCode()) {
        try {
          dispatch(showLoadingIndicator(defaultLoadingSymbol));
          await waitForQrCode(returns?.id);
        } catch (e) {
          logger.Error("Error starting return", e);
        }
      }

      dispatch(clearLoadingIndicator());
      setIsLoading(false);
    } else {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      try {
        const newReturns = prepareReturn(
          returns,
          returnType,
          itemsMarkedForCompletion,
          query,
          newReturnEmail,
          selectedDropoffMethod,
          purchasing,
          returnSessionID,
          getSavedRBLocations()
        );
        if (newReturns) {
          // Add the identity back into the return
          const claims = getReturnistaJWTClaims(app.token);

          // Add an email if the order did not contain an email
          // The newReturnEmail is populated on the preview page if missing
          if (claims?.Identity && claims?.Identity.email === undefined) {
            claims.Identity.email = newReturnEmail;
          }

          newReturns.customerIdentity = claims.Identity;

          if (claims?.returnsApp?.adminMode === true) {
            newReturns.session = {"adminUserID": claims.returnsApp.adminUserID};
          }
        }

        // Using a dummy url to initialize URL(). We really only care about the query parameters.
        const queryParams = new URL("https://www.example.com");
        const returnExpirationFeatureStudy = getReturnExpirationFeatureStudyID();

        let featureStudyString = returnExpirationFeatureStudy;
        if (customer.featureStudyIDs) {
          const featureStudyIDs = new Set(customer.featureStudyIDs);
          featureStudyIDs.add(returnExpirationFeatureStudy);
          featureStudyString = Array.from(featureStudyIDs).toString();
        }

        queryParams.searchParams.append("feature_study_ids", featureStudyString);
        const response = await axios.post<ReturnStartedResponse>("/startreturn" + queryParams.search, newReturns);

        const retrn = response.data.return;
        const returnStatusToken = response.data.returnStatusToken;

        setExpressCodeApplePassData((prev) => ({
          ...prev,
          retailerName: retrn.retailer.name,
          numItemsInReturn: retrn.returning ? retrn.returning.length : 0,
          returnID: retrn.id,
          confirmationCode: retrn.confirmationCode,
        }));

        setStartedReturn(retrn);
        // set the return expiration info
        if (retrn.retailer) {
          setRetailerID(retrn.retailer.id);

          if (!retrn.returnlessRefund) {
            await setReturnExpirationInfo(retrn.id, returnExpirationFeatureStudy);
          }
        }

        setReturnShoppingItems(purchasing?.items);
        await generateQR(retrn.confirmationCode);
        if (isByMailQrCode()) await waitForQrCode(retrn.id);

        if (showReturnStatus) {
          openReturnStatusPage(retrn.id, returnStatusToken, locale)
        }
      } catch (e) {
        logger.Error("Error starting return", e);
        setStartReturnError(e);
        dispatch(clearLoadingIndicator());
        setIsLoading(false);
      } finally {
        if (!showReturnStatus) {
          dispatch(clearLoadingIndicator());
          setIsLoading(false);
        }
      }
    }
  };

  const setReturnExpirationInfo = async (returnID, featureStudyID) => {
    try {
      const response = await axios.get<ReturnExpiration>(`/returnexpiration/${returnID}`);
      if (!response.data.expiryDate) {
        return;
      }

      const expiryDate = new Date(response.data.expiryDate);
      setExpressCodeApplePassData((prev?) => ({
        ...prev,
        expirationDate: expiryDate,
      }));

      // A/B testing of which return expiration message we want to display
      // The first is "return by {{month}} {{day}} {{year}}"
      // The second is "return expires in {{X}} days"
      if (featureStudyID == FeatureStudyIDs.returnExpMsgDateOfExp) {
        setReturnExpirationMessage(
          t("returnByDate", {
            month: t(`month.${expiryDate.getMonth()}`),
            day: expiryDate.getDate(),
            year: expiryDate.getFullYear(),
          })
        );
      } else {
        const today = new Date();
        setReturnExpirationMessage(t("returnWithinDays", { numDays: getDaysDiff(expiryDate, today) }));
      }

      return featureStudyID;
    } catch (e) {
      // swallow error since it's not essential
      console.error("error setting return expiration message", e);
      return "";
    }
  };

  const openReturnStatusPage = (returnID, returnStatusToken, locale) => {
    window.open(`/?returnStatusReturnID=${returnID}&locale=${locale}&authorization=${returnStatusToken}`, "_self");
  }

  const getDaysDiff = (date1, date2) => {
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    return Math.ceil(Math.abs(utc1 - utc2) / MS_PER_DAY);
  };

  const getReturnExpirationFeatureStudyID = (): string => {
    if (customer.featureStudyIDs && FeatureStudyIDs.returnExpMsgDateOfExp in customer.featureStudyIDs) {
      return FeatureStudyIDs.returnExpMsgDateOfExp;
    }

    if (customer.featureStudyIDs && FeatureStudyIDs.returnExpMsgDaysToComplete in customer.featureStudyIDs) {
      return FeatureStudyIDs.returnExpMsgDaysToComplete;
    }

    if (Math.random() < returnExpMsgSplit) {
      dispatch(addFeatureStudyID(FeatureStudyIDs.returnExpMsgDateOfExp));
      return FeatureStudyIDs.returnExpMsgDateOfExp;
    } else {
      dispatch(addFeatureStudyID(FeatureStudyIDs.returnExpMsgDaysToComplete));
      return FeatureStudyIDs.returnExpMsgDaysToComplete;
    }
  };

  const showReturnStatusConfirmationPage = (retailer?: Retailer): {showReturnStatus : boolean, returnStatusFeatureStudyID : FeatureStudyIDs} => {
    if (!retailer) {
      dispatch(addFeatureStudyID(FeatureStudyIDs.returnStatusRedirectOff));
      return {
        showReturnStatus: false,
        returnStatusFeatureStudyID:FeatureStudyIDs.returnStatusRedirectOff,
      };
    }

    // TODO - possible addition `hr-plus-rs`
    const allowedSandboxRetailers: string[] = ["happy-returns-flow", "hr-plus", "happy-returns-integrations"];

    if (retailer.sandbox && !allowedSandboxRetailers.includes(retailer.id)) {
      dispatch(addFeatureStudyID(FeatureStudyIDs.returnStatusRedirectOff));
      return {
        showReturnStatus: false,
        returnStatusFeatureStudyID:FeatureStudyIDs.returnStatusRedirectOff,
      };
    }

    if (customer.featureStudyIDs?.has(FeatureStudyIDs.returnStatusRedirectOn)){
      return {
        showReturnStatus:true,
        returnStatusFeatureStudyID:FeatureStudyIDs.returnStatusRedirectOn,
      };
    }

    if (customer.featureStudyIDs?.has(FeatureStudyIDs.returnStatusRedirectOff)){
      return {
        showReturnStatus: false,
        returnStatusFeatureStudyID:FeatureStudyIDs.returnStatusRedirectOff,
      };
    }

    const abSplitRandom = Math.random()
    if (abSplitRandom < returnStatusOnSplit) {
      // 20% - show new return status page
      dispatch(addFeatureStudyID(FeatureStudyIDs.returnStatusRedirectOn));
      return {
        showReturnStatus:true,
        returnStatusFeatureStudyID:FeatureStudyIDs.returnStatusRedirectOn,
      };
    } else {
      // 80% - existing confirmation page
      dispatch(addFeatureStudyID(FeatureStudyIDs.returnStatusRedirectOff));
      return {
        showReturnStatus: false,
        returnStatusFeatureStudyID:FeatureStudyIDs.returnStatusRedirectOff,
      };
    }
  };

  const isAppleDevice = () => {
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);

    return device.device?.brand === "Apple";
  };

  const isReturnless = () => {
    return !startReturnError && startedReturn?.returnlessRefund;
  };
  const isReturnBar = () => {
    return !isReturnless() && !startReturnError && selectedDropoffMethod?.id == DropoffMethodIDs.returnBar;
  };
  const isInStore = () => {
    return !isReturnless() && !startReturnError && selectedDropoffMethod?.id == DropoffMethodIDs.retailerStore;
  };
  const isByMailQrCode = () => {
    return isByMail() && selectedDropoffMethod?.selectedLabelOption === "QR Code";
  };
  const isByMail = () => {
    return !isReturnless() && !startReturnError && selectedDropoffMethod?.id == DropoffMethodIDs.mail;
  };
  const isByMailShopperProvided = () => {
    return !isReturnless() && !startReturnError && selectedDropoffMethod?.id === DropoffMethodIDs.mailShopperProvided;
  };
  const isReturnShopping = () => {
    return !!startedReturn?.invoiceURL;
  };
  const storeLocatorURL = links && links["storeLocatorURL"] ? links["storeLocatorURL"].trim() : "";
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const openShippingLabel = async () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.DownloadShippingLabel
    })
    window.open(`/shippinglabel/${startedReturn?.id}`, "_blank");
  };
  const openPackingSlip = async () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.DownloadPackingSlip
    })
    window.open(`/packingslip/${startedReturn?.id}`, "_blank");
  };
  const openInvoiceInNewTab = async () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingCheckoutNow
    })
    window.open(startedReturn?.invoiceURL, "_blank");
  };

  // downloadShippingLabel is currently not used until a better solution is found to
  // download the shipping label for desktop and open in a new tab for mobile
  const downloadShippingLabel = async () => {
    try {
      const resp = await axios(`/shippinglabel/${startedReturn?.id}`, {
        method: "get",
        responseType: "blob",
      });

      // GENERATES A LINK TO THE PDF FROM RESP IF SUCCESSFUL AND AUTOMATICALLY DOWNLOADS IT
      // this makes error handling much easier and prevents garbage data from being downloaded
      // when an error occurs in the backend
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${startedReturn?.id}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      if (!e.response) {
        logger.Error("An error occured when fetching the shipping label", e);
      }
      alert(t("errorDownloadingShipping"));
    }
  };
  // downloadPackingSlip is currently not used until a better solution is found to
  // download the packing slip for desktop and open in a new tab for mobile
  const downloadPackingSlip = async () => {
    try {
      const resp = await axios(`/packingslip/${startedReturn?.id}`, {
        method: "get",
        responseType: "blob",
      });

      // GENERATES A LINK TO THE PDF FROM RESP IF SUCCESSFUL AND AUTOMATICALLY DOWNLOADS IT
      // this makes error handling much easier and prevents garbage data from being downloaded
      // when an error occurs in the backend
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${startedReturn?.id}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      if (!e.response) {
        logger.Error("An error occured when fetching the packing slip", e);
      }
      alert(t("errorDownloadingPacking"));
    }
  };

  const onEmailModalClose = () => setDisplayEmailModal(false);
  const onEmailSubmit = async ({ email, setError, onSuccess }) => {
    dispatch(showLoadingIndicator(defaultLoadingSymbol));
    try {
      if (changeDropoffReturnID) {
        const payload = { email: email };
        await axios.post(`return/${changeDropoffReturnID}/email/confirmation`, payload);
      } else {
        const payload = {
          returnID: startedReturn?.id,
          email: email,
        };
        await axios.post("email/confirmation", payload);
      }
      onSuccess(email);
    } catch (e) {
      logger.Error("Error sending confirmation email", e);
      setError(t("errorSendingConfirmation"));
    }
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.SendToAnotherEmailSubmit
    });
    dispatch(clearLoadingIndicator());
  };

  const generateQR = async (text) => {
    try {
      const qr = await QRCode.toDataURL(text);
      setConfirmationCodeQR(qr);
    } catch (err) {
      console.error(err);
    }
  };

  const signUserOut = (e?) => {
    dispatch(reset());
    dispatch(setToken(""));
    dispatch(goToPage("loginReturnPortal"));
  };

  // TODO: update request to HEAD and update backend to accept HEAD
  const waitForQrCode = async (id) => {
    const endpoint = `/qrcode/${id}`;
    const maxRetries = 5;
    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await axios.get(endpoint);
        setQrCodeUrl(DOMPurify.sanitize(endpoint));
        setQrCodeType(response.headers["content-type"]);
        return;
      } catch (err) {
        logger.Error("Error getting QR code", err);
        await sleep(1000 * i);
      }
    }
  };

  const createReturnFeatureStudy = async (returnID, featureStudyIDs) => {
    if (featureStudyIDs) {
      const featureStudies = Array.from(featureStudyIDs);
      await Promise.all(
        featureStudies.map(async (featureStudyID) => {
          try {
            const payload = {
              returnID: returnID,
              featureStudyID: featureStudyID,
            };
            await axios.post("/returnfeaturestudy", payload);
          } catch (err) {
            // error being swallowed since it is not critical to the workflow
            console.error(err);
          }
        })
      );
    } else {
      console.error("no customer.featureStudyID object");
    }
  };
  //---------------------------------------------------------------------------
  const dropoffMethodNextStepsCopy: Record<DropoffMethodIDs, JSX.Element> = {
    [DropoffMethodIDs.returnBar]: <>{t("returnBarNextSteps")}</>,
    [DropoffMethodIDs.retailerStore]: <>{t("storeNextSteps")}</>,
    [DropoffMethodIDs.mail]: <>{t("mailShopperProvidedNextSteps")}</>,
    [DropoffMethodIDs.mailShopperProvided]: <>{t("mailShopperProvidedNextSteps")}</>,
    [DropoffMethodIDs.returnless]: <>{t("returnlessNextSteps")}</>,
  };
  const nextStepsCopy = () => {
    if (startReturnError) {
      return <div>{t("pleaseLogInAndTry")}</div>;
    }

    // Check for returnless as it is not a dropoff method, but rather a channel
    if (isReturnless()) {
      return dropoffMethodNextStepsCopy[DropoffMethodIDs.returnless];
    }

    // Get copy for dropoff method
    if (selectedDropoffMethod?.id) {
      return dropoffMethodNextStepsCopy[selectedDropoffMethod?.id];
    }

    return <></>;
  };
  const onReturnShoppingThumbnailClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingProductImage
    })
  }

  const onReturnShoppingMoreIconClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingMoreIcon
    })
  }

  const images = returnShoppingItems?.map((item) => (
    // blank image URL if image isn't available
    // better than a broken image
    <img
      src={item.image || "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="}
      alt={item.title}
      className="return-shopping-images"
      onClick={onReturnShoppingThumbnailClick}
    />
  ));

  const onClickSeeAllLocations = () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.SeeAllLocations
    })
  }

  const onClickReturnShoppingWaiveReturnProcessingFee = () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingWaiveReturnProcessingFee
    })
  }

  const onClickReturnShoppingDiscount = () => {
    ga.event({
      category: AnalyticCategories.ReturnStartedPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingDiscount
    })
  }

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <ReturnPortalBaseWithPage page={page} disableHeader={true} shrinkToFit>
      <>
        {!isLoading && (
          <$ReturnStarted>
            {isReturnShopping() && (
              <>
                <div className="return-shopping-container" data-cy={DataCyStrings.returnShoppingContainer}>
                  <div className="return-shopping-cart">
                    <div className="return-shopping-icon">
                      <SVG name="shopping-cart-black" height="16px" />
                    </div>
                    <div className="complete-your-new-order">{t("payInvoice")}</div>
                  </div>
                  <div className="return-shopping-divider"></div>
                  {images && (
                    <div
                      className="return-shopping-image-container"
                      data-cy={DataCyStrings.returnShoppingImageContainer}
                    >
                      {images.slice(0, 3)}
                      {images.length > 3 && (
                        <div className="more" data-cy={DataCyStrings.moreIcon} onClick={onReturnShoppingMoreIconClick}>
                          {"+\n"}
                          {t("more")}
                        </div>
                      )}
                    </div>
                  )}
                  {!!returnShoppingFeeWaiver &&
                    selectedDropoffMethod?.fee &&
                    selectedDropoffMethod.fee.amount !== "0.00" && (
                      <div className="return-shopping-discount" onClick={onClickReturnShoppingWaiveReturnProcessingFee}>
                        <div className="icon">
                          <SVG name="tag" />
                        </div>
                        {t("waiveYourReturnProcessingFee")}
                      </div>
                    )}
                  {!!returnShoppingDiscount && (
                    <div className="return-shopping-discount" onClick={onClickReturnShoppingDiscount}>
                      <div className="icon">
                        <SVG name="tag" />
                      </div>
                      {t("discountIsApplied", { discount: returnShoppingDiscount })}
                    </div>
                  )}
                  <div className="pay-invoice-button">
                    <PrimaryButton
                      dataCyString={DataCyStrings.payInvoiceButton}
                      label={t("checkoutNow")}
                      width="100%"
                      onButtonClick={openInvoiceInNewTab}
                    ></PrimaryButton>
                  </div>
                </div>

                <div
                  className="return-shopping-return-submitted-container"
                  data-cy={DataCyStrings.returnShoppingReturnSubmittedContainer}
                >
                  <div className="return-shopping-return-submitted-row">
                    <div className="return-shopping-return-submitted-col1">
                      <div className="return-shopping-icon-container">
                        {!startReturnError && (
                          <SVG name="checkCircle" height="25.6" data-cy={DataCyStrings.confirmationCheckMark} />
                        )}
                        {startReturnError && <SVG name="exclamation" height="25.6" />}
                      </div>
                      <div className="return-shopping-return-submitted-text">{t("returnSubmitted")}</div>
                    </div>
                  </div>
                  <div className="return-shopping-divider"></div>
                  <div className="return-shopping-next-steps-section">
                    <div className="returns-shopping-next-steps" data-cy="next-steps">
                      {nextStepsCopy()}
                      {isReturnBar() && locale == localeUS && (
                        <div className="return-shopping-next-steps-description">
                          {t("returnBarNextStepsDescription")}
                        </div>
                      )}
                      <div className="returns-shopping-return-code-label">
                        <div className="return-shopping-express-code" data-cy={DataCyStrings.expressCode}>
                          {!startReturnError && <>{t("expressCode", { code: startedReturn?.confirmationCode })}</>}
                          {startReturnError && <>{t("somethingHasGoneWrong")}</>}
                        </div>
                        {!isReturnless() && (
                          <div
                            className="return-shopping-return-expiration-message"
                            data-cy={DataCyStrings.returnExpirationMessage}
                          >
                            {returnExpirationMessage && <>{returnExpirationMessage}</>}
                          </div>
                        )}
                        {(isReturnBar() || isInStore()) && (
                          <div className="returns-shopping-qrcode-image">
                            <img
                              data-cy={DataCyStrings.qrCodeImage}
                              width="96"
                              height="96"
                              src={confirmationCodeQR}
                              alt={t("happyReturnsExpress", { code: startedReturn?.confirmationCode })}
                            ></img>
                          </div>
                        )}
                        {isByMailQrCode() && qrCodeUrl && (
                          <div className="returns-shopping-qrcode-image">
                            {qrCodeType.includes("image") ? (
                              <img
                                data-cy={DataCyStrings.qrCodeImage}
                                height="150px"
                                src={qrCodeUrl}
                                alt={t("qrCode")}
                              ></img>
                            ) : (
                              <object data-cy={DataCyStrings.qrCodeImage} data={qrCodeUrl}></object>
                            )}
                          </div>
                        )}
                        {((!isByMailQrCode() && isByMail())) && (
                          <div className="returns-shopping-download-button">
                            <SecondaryButton
                              dataCyString={DataCyStrings.downloadShippingLabelButton}
                              label={t("downloadShippingLabel")}
                              width="100%"
                              height="48px"
                              onButtonClick={openShippingLabel}
                            ></SecondaryButton>
                          </div>
                        )}
                        {isByMailShopperProvided() && (
                          <div className="returns-shopping-download-button">
                            <SecondaryButton
                              dataCyString={DataCyStrings.downloadPackingSlipButton}
                              label={t("downloadPackingSlip")}
                              width="100%"
                              height="48px"
                              onButtonClick={openPackingSlip}
                            ></SecondaryButton>
                          </div>
                        )}

                        {isReturnBar() && isAppleDevice() &&  (
                          <div className="return-shopping-apple-pass-container">
                            <ExpressCodeApplePassButton
                              expirationDate={expressCodeApplePassData.expirationDate}
                              confirmationCode={expressCodeApplePassData.confirmationCode}
                              returnID={expressCodeApplePassData.returnID}
                              numItemsInReturn={expressCodeApplePassData.numItemsInReturn}
                              retailerName={expressCodeApplePassData.retailerName}
                              changeDropoffMethodReturnID={changeDropoffReturnID}
                            />
                          </div>
                        )}
                        {isInStore() && storeLocatorURL && (
                          <div className="returns-shopping-locations-link">
                            <a data-cy={DataCyStrings.seeAllLocationsLink} target="_blank" href={storeLocatorURL} onClick={onClickSeeAllLocations} rel="noreferrer">
                              {t("seeAllLocations")}
                            </a>
                          </div>
                        )}
                        {startReturnError && (
                          <PrimaryButton onButtonClick={signUserOut} width="150px" label={t("goToLoginPage")} />
                        )}
                        {(isReturnBar() || isInStore()) && (
                          <div className="returns-shopping-locations-copy">{t("showThisCodeWhenYou")}</div>
                        )}
                        {isReturnBar() && (
                          <div className="returns-shopping-locations-link">
                            <a
                              data-cy={DataCyStrings.seeAllLocationsLink}
                              target="_blank"
                              href="https://locations.happyreturns.com"
                              onClick={onClickSeeAllLocations} rel="noreferrer"
                            >
                              {t("seeAllLocations")}
                            </a>
                          </div>
                        )}
                        {isByMailQrCode() && qrCodeUrl && (
                          <div className="returns-shopping-locations-copy">
                            {selectedDropoffMethod?.selectedCarrierName
                              ? t("showThisCodeBleckmann", { name: selectedDropoffMethod?.selectedCarrierName })
                              : t("showThisCodeWhenYou")}
                            <div>
                              <Trans t={t} i18nKey="problemsViewingQrCode">
                                Problems viewing the QR code?
                                <a
                                  data-cy={DataCyStrings.downloadQRCodeLink}
                                  className="returns-shopping-download-link"
                                  target="_blank"
                                  href={DOMPurify.sanitize(qrCodeUrl)} rel="noreferrer"
                                >
                                  Click here to download
                                </a>
                              </Trans>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {!isReturnShopping() && (
              <>
                <div className="icon-container">
                  {!startReturnError && (
                    <SVG name="checkCircle" height="32" data-cy={DataCyStrings.confirmationCheckMark} />
                  )}
                  {startReturnError && <SVG name="exclamation" height="32" />}
                </div>
                <div className="express-code" data-cy={DataCyStrings.expressCode}>
                  {!startReturnError && <>{t("expressCode", { code: startedReturn?.confirmationCode })}</>}
                  {startReturnError && <>{t("somethingHasGoneWrong")}</>}
                </div>
                {!isReturnless() && (
                  <div className="return-expiration-message" data-cy={DataCyStrings.returnExpirationMessage}>
                    {returnExpirationMessage && <>{returnExpirationMessage}</>}
                  </div>
                )}
                <div className="next-steps" data-cy="next-steps">
                  {nextStepsCopy()}
                  {isReturnBar() && locale == localeUS && (
                    <div className="return-bar-next-steps-description">
                      {t("returnBarNextStepsDescription")}
                    </div>
                  )}
                  {startReturnError && (
                    <PrimaryButton onButtonClick={signUserOut} width="150px" label={t("goToLoginPage")} />
                  )}
                </div>
                {(isReturnBar() || isInStore()) && (
                  <div className="qrcode-image">
                    <img
                      data-cy={DataCyStrings.qrCodeImage}
                      width="96"
                      height="96"
                      src={confirmationCodeQR}
                      alt={t("happyReturnsExpress", { code: startedReturn?.confirmationCode })}
                    ></img>
                  </div>
                )}

                {isReturnBar() && isAppleDevice() && (
                  <div className="apple-pass-container">
                    <ExpressCodeApplePassButton
                      expirationDate={expressCodeApplePassData.expirationDate}
                      confirmationCode={expressCodeApplePassData.confirmationCode}
                      returnID={expressCodeApplePassData.returnID}
                      numItemsInReturn={expressCodeApplePassData.numItemsInReturn}
                      retailerName={expressCodeApplePassData.retailerName}
                      changeDropoffMethodReturnID={changeDropoffReturnID}
                    />
                  </div>
                )}

                {isByMailQrCode() && qrCodeUrl && (
                  <div className="qrcode-image">
                    {qrCodeType.includes("image") ? (
                      <img
                        data-cy={DataCyStrings.qrCodeImage}
                        width="96"
                        height="96"
                        src={qrCodeUrl}
                        alt={t("qrCode")}
                      ></img>
                    ) : (
                      <object data-cy={DataCyStrings.qrCodeImage} data={qrCodeUrl}></object>
                    )}
                  </div>
                )}
                {((!isByMailQrCode() && isByMail())) && (
                  <div className="download-button">
                    <PrimaryButton
                      dataCyString={DataCyStrings.downloadShippingLabelButton}
                      label={t("downloadShippingLabel")}
                      width="220px"
                      onButtonClick={openShippingLabel}
                    ></PrimaryButton>
                  </div>
                )}
                {isByMailShopperProvided() && (
                  <div className="download-button">
                    <PrimaryButton
                      dataCyString={DataCyStrings.downloadPackingSlipButton}
                      label={t("downloadPackingSlip")}
                      width="190px"
                      onButtonClick={openPackingSlip}
                    ></PrimaryButton>
                  </div>
                )}
                {(isReturnBar() || isInStore()) && <div className="locations-copy">{t("showThisCodeWhenYou")}</div>}
                {isByMailQrCode() && qrCodeUrl && (
                  <div className="locations-copy">
                    {selectedDropoffMethod?.selectedCarrierName
                      ? t("showThisCodeBleckmann", { name: selectedDropoffMethod?.selectedCarrierName })
                      : t("showThisCodeWhenYou")}
                    <div>
                      <Trans t={t} i18nKey="problemsViewingQrCode">
                        Problems viewing the QR code?
                        <a
                          data-cy={DataCyStrings.downloadQRCodeLink}
                          className="download-link"
                          target="_blank"
                          href={DOMPurify.sanitize(qrCodeUrl)} rel="noreferrer"
                        >
                          Click here to download
                        </a>
                      </Trans>
                    </div>
                  </div>
                )}
                {isReturnBar() && (
                  <div className="locations-link">
                    <a
                      data-cy={DataCyStrings.seeAllLocationsLink}
                      target="_blank"
                      href="https://locations.happyreturns.com"
                      onClick={onClickSeeAllLocations} rel="noreferrer"
                    >
                      {t("seeAllLocations")}
                    </a>
                  </div>
                )}
              </>
            )}

            {!startReturnError && (
              <div className="footer-container">
                <div className="mobile-divider-container">
                  <div className="mobile-divider" />
                </div>
                <div className="custom-retailer-copy" data-cy= {DataCyStrings.confirmationNextSteps}>{customRetailerCopy}</div>
                <div className="divider-container">
                  <div className="divider" />
                </div>
                <div className="confirmation-email">
                  <div>
                    {t("aConfirmationWasSent", { email: newReturnEmail })}
                    <span className="confirmation-email-spacer">-</span>
                    <div className="confirmation-email-mobile-break"></div>
                    <a
                      className="send-email-link"
                      {...handleEnterKeyPressOnClickHandlers(() => {
                        ga.event({
                          category: AnalyticCategories.ReturnStartedPage,
                          action: ReturnStartedAndReturnStatusPageActions.SendToAnotherEmail
                        });
                        setDisplayEmailModal(true)
                      })}
                    >
                      {t("sendToAnotherEmail")}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </$ReturnStarted>
        )}
        <SendToAnotherEmailModal isOpen={displayEmailModal} onClose={onEmailModalClose} onSubmit={onEmailSubmit} />
      </>
    </ReturnPortalBaseWithPage>
  );
  //----------------------------------------------------------------------------
};

export default ReturnStarted;
