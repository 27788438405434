import React from "react";

const Plus = (props) => (
  <>
    <svg
      {...props}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.5C15 11.0523 14.5523 11.5 14 11.5H11V14.5C11 15.0523 10.5523 15.5 10 15.5C9.44772 15.5 9 15.0523 9 14.5V11.5H6C5.44772 11.5 5 11.0523 5 10.5C5 9.94772 5.44772 9.5 6 9.5H9V6.5C9 5.94772 9.44772 5.5 10 5.5C10.5523 5.5 11 5.94772 11 6.5V9.5H14C14.5523 9.5 15 9.94772 15 10.5ZM10 2.5C5.582 2.5 2 6.082 2 10.5C2 14.918 5.582 18.5 10 18.5C14.418 18.5 18 14.918 18 10.5C18 6.082 14.418 2.5 10 2.5Z"
        fill="#221F1F"
      />
    </svg>
  </>
);

export default Plus;
