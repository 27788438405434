import { useEffect, useMemo, useState } from "react";
import { modalZIndex } from ".";
import { cloneDeep } from "lodash";
import { largeBrowserWidthMin } from "../globalConstants";
export interface ModalStyles {
  content?: {
    [key: string]: any
  },
  overlay?: {
    [key: string]: any
  },
  display?: string | "standard" | "mobile",
}
export interface UseModalStylesProps {
  mobileModalStyles?:  ModalStyles,
  desktopModalStyles?: ModalStyles,
  // XXX -  keep to prevent regressions
  width?: string
  // used to override low end width that triggers "mobile" media query
  maxLowEndWidth?: string
}


const desktopModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.48)",
    zIndex: modalZIndex,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "72px 0 40px 0",
    overflow: "auto",
  },
  content: {
    position: "initial",
    width: "456px",
    overflow: "auto",
    padding: "0",
    borderRadius: "12px",
    zIndex: modalZIndex + 1,
  },
  display: "standard"
}

const mobileModalStyles = {
  overlay: {
    backgroundColor: "none",
    zIndex: modalZIndex,
    display: "flex",
    justifyContent: "center",
    padding: "0px",
    overflow: "auto"
  },
  content: {
    position: "initial",
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: "0 16px",
    borderRadius: "0",
    zIndex: modalZIndex + 1
  },
  display: "mobile"
}


const useModalStyles = (props?: UseModalStylesProps) => {
  const [modalStyles, setModalStyles] = useState<ModalStyles>(desktopModalStyles);
  const maxLowEndWidth = props?.maxLowEndWidth || largeBrowserWidthMin;
  const mql = window?.matchMedia?.(`(max-width: ${maxLowEndWidth})`);
  // styles with overriden values
  const desktopStyles = useMemo(() => overrideModalStyles(desktopModalStyles, props?.desktopModalStyles), []);
  const mobileStyles = useMemo(() => overrideModalStyles(mobileModalStyles, props?.mobileModalStyles), []);
    // set styles through JS due to how react-modal is handled
    useEffect(() => {
      const handleStyles = (e) => {
        if (e?.matches) { // mobile/tablet
          setModalStyles(mobileStyles);
        } else {
          const styles = cloneDeep(desktopStyles);
          if (props?.width) {
            styles.content.width = props.width;
          }
          setModalStyles(styles);
        }
      }
      if (mql) {
        mql.onchange = handleStyles;
        // check styles on mount as well
        mql && handleStyles(mql);
      }
    }, []);

    return modalStyles;
}

/**
 * Overrides existing styles to allow modal flexibility
 * @param initialStyles 
 * @param newStyles 
 * @returns 
 */
const overrideModalStyles = (initialStyles: ModalStyles, newStyles?: ModalStyles) => {
  const newClone = cloneDeep(initialStyles) as ModalStyles;
  if (newStyles?.content) {
    Object.keys(newStyles.content).forEach(key => {
      if (newStyles.content && newClone.content)
        newClone.content[key] = newStyles.content[key];
    })
  }
  if (newStyles?.overlay) {
    Object.keys(newStyles.overlay).forEach(key => {
      if (newStyles?.overlay && newClone?.overlay)
        newClone.overlay[key] = newStyles.overlay[key];
    })
  }
  return newClone;
}

export default useModalStyles;
