/* eslint-disable no-fallthrough */
import { Reducer } from "react";
import { EventAction, MultiStoreAction } from "../enums";

export enum eventLabels {
  ITEM_CONFIRMATION_NO = "item_confirmation_no",
  ITEM_NO_BARCODE_FOUND = "item_no_barcode_found",
  ITEM_PUZZLE_CORRECT_SELECTION = "item_puzzle_correct_selection",
  ITEM_PUZZLE_INCORRECT_SELECTION = "item_puzzle_incorrect_selection",
  ITEM_SCAN_NO_MATCH = "item_scan_no_match",
}

export type eventType = {
  returnID: string;
  eventTypeLabel: eventLabels;
  eventData?: {
    itemPuzzleID?: string;
    imagePuzzleURL?: string;
  };
  eventTimestamp: string;
};

const initialState = [];

type eventActions = {
  type: EventAction.Add | EventAction.Reset | MultiStoreAction.AppLogout | MultiStoreAction.AppReset;
  event: eventType;
};

const eventsReducer: Reducer<Array<eventType>, eventActions> = (state = initialState, action) => {
  switch (action.type) {
    case EventAction.Add:
      return [...state, action.event];
    case EventAction.Reset:
      return initialState;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
      return initialState;
    default:
      return state;
  }
};

export default eventsReducer;
