// third-party imports
import React from "react";
import { useDispatch } from "react-redux";

// local imports
import { setReturnPortalTimeout, goToPage } from "../../../redux/app/actions";
import $TimeoutModalContents from "./styles";
import PrimaryButton from "../../Button/PrimaryButton";
import SVG from "../../svg/Exclamation";
import ReturnPortalBase from "../../ReturnPortalBase"
import getTranslator from "../../../utility/getTranslator";
import { PrivacyFooter } from "../../../components/PrivacyFooter";
import { DataCyStrings } from "../../../types/DataCyStrings";
const useTranslation = getTranslator("TimeoutModal")

export const TimeoutModal = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();

  const closeModal = () => { 
    dispatch(setReturnPortalTimeout(false));
    dispatch(goToPage("loginReturnPortal"));
  }
    
  return (
    <>
    <ReturnPortalBase>
      <$TimeoutModalContents>
        <div><SVG name="exclamation"/></div>
        <div className="header" data-cy={DataCyStrings.headerTimeoutModal}>{t('yourSessionHasTimed')}</div>
        <div className="message" data-cy={DataCyStrings.messageTimeoutModal}>{t('pleaseLogInAgainTo')}</div>
        <PrimaryButton width="174" onButtonClick={closeModal} label={t('goToLoginPage')} />
      </$TimeoutModalContents>
    </ReturnPortalBase>
    <PrivacyFooter />
    </>
    
  )
}
  
export default TimeoutModal;
