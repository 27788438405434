import React from "react";
import { ReturnistaToasterProps } from "./types";

import { $Wrapper } from "./styles";
import { SVG } from "../svg";

const icons = {
  success: {
    name: "returnistaCircleCheck",
    fill: "#004E22",
  },
  error: {
    name: "returnistaCircleError",
    fill: "#9E0008",
  },
};

const ReturnistaToaster = ({ message, show, type = "success" }: ReturnistaToasterProps) => {
  return show ? (
    <$Wrapper type={type}>
      <SVG name={icons[type].name} fill={icons[type].fill} />
      <div>{message}</div>
    </$Wrapper>
  ) : null;
};

export default ReturnistaToaster;
