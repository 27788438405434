import React from "react";

import { SVG } from "../../components/svg";
import {
  $BarcodeWidget,
  $BarcodeWidgetContainer,
  $CancelBag,
} from "./styles";
import { DataCyStrings } from "../../types/DataCyStrings";
import logger from "../../utility/logger";

interface BarcodeRowProps {
  barcodeRow: string[],
  onDeleteRow: () => void
}

function BarcodeWidget({barcodes, barcode, removeReturnBagBarcode}) {
  return (
    <$BarcodeWidgetContainer >
      <$BarcodeWidget>
        <span>{barcode}</span>
      </$BarcodeWidget>
      <$CancelBag onClick={() => {
        // remove only the first instance we see of that barcode, in case there were duplicates
        const index = barcodes.indexOf(barcode);
        // if we found it, remove it from our barcodes array and notify backend
        if (index > -1) {    
          removeReturnBagBarcode(barcode);
        } else {
          logger.Error("User is trying to remove a barcode " + barcode + " that does not exist in list of barcodes", barcodes)
        }
      }}>
        <SVG name="close" />
      </$CancelBag>
    </$BarcodeWidgetContainer>
  )
}

/**
 * Takes in barcode combination already saved in the DB and a remove callback
 */
export function BarcodeRow({ barcodeRow, onDeleteRow }: BarcodeRowProps) {
  const renderBarcodes = () => {
    return barcodeRow.map(barcode => (
      <span key={barcode} data-cy={DataCyStrings.bagBarcode}>
        {barcode}
      </span>
    ));
  }
  return (
    <$BarcodeWidgetContainer>
      <$BarcodeWidget>{renderBarcodes()}</$BarcodeWidget>
      <$CancelBag onClick={() => onDeleteRow()} data-cy={DataCyStrings.deleteBag}>
        <SVG name="close" />
      </$CancelBag>
    </$BarcodeWidgetContainer>
  );
}

export default BarcodeWidget;