import styled from 'styled-components';


const $LanguageModal = styled.div`
  padding: 0 1em;
  > header {
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #221F1F;
  }

  > .lineitem {
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 1em 0;
    display: block;

    > div {
      border-bottom: 0;
    }
  }

  > div.lineitem.active {
    border: 2px solid #000000;
  }

  > a.lineitem {
    padding: 12px;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:not(.active){
      cursor: pointer;
    }

    &.active {
      border: 2px solid #221F1F;
      padding: 11px;
      font-weight: 800;
    }
  }

  > .nonefound {
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #737373;
    margin-bottom: 24px;
  }
`

export {
  $LanguageModal
}
