import styled, { css } from "styled-components";
import { InputProps } from "./types";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $ManualCodeLinkWrapper = styled.div`
  .no-barcode {
    width: 235px;
    margin-left: 24px;
  }

  .activate-manual-input {
    background: none;
    border: none;
    font: normal 500 16px "sofia-pro";
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const $ManualInput = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  .icon {
    display: none;
    margin-top: 5px;

    @media (min-width: ${tabletOrLargerDevices}) {
      display: inherit;
    }
  }

  .title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: normal 400 21px "sofia-pro";
    font-smooth: never;
    margin: 0;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin: 0 0 0 26px;
    }

    span {
      color: var(--soft-black);
      font-size: 16px;
    }
  }

  form {
    width: 100%;
    margin-top: 16px;
    padding: 0;

    @media (min-width: ${tabletOrLargerDevices}) {
      padding: 0 45px;
    }

    .error {
      color: var(--input-error);
      font: normal 400 12px "sofia-pro";
      line-height: 18px;
      margin: 8px 0 0 0;
    }

    .submitQrCodeButton {
      margin: 16px 0;
    }
  }
`;

export const $Input = styled.input<InputProps>`
  border: ${({ hasError }) => (hasError ? "2px solid var(--input-error)" : "2px solid var(--text-black)")};
  border-radius: 8px;
  color: var(--text-black);
  font: normal 400 16px "sofia-pro";
  padding: 16px;
  text-transform: uppercase;
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css({
      background: "var(--warning-soft)",
    })}

  &:focus {
    outline: none;
  }
`;

export const $IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const $NoBarCodeButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: ${tabletOrLargerDevices}) {
    flex-direction: row;
  }

  button {
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      width: 190px;
      margin-bottom: 0;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
