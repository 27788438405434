import styled from "styled-components";

export const $TextAreaWrapper = styled.div`
  /* gets rid of vertical space taken by charcount which still occupies space beneath the textarea after being repositioned  */
 
  label {
    display: block;
    font-size: 13px;
    line-height: 13px;
    color: #454545;
    margin-bottom: 8px;
  }
  .text-area-error {
    background: #FEE8E3;
    border: 1px solid #DF3719;
    color: #DF3719;
  }
`
export const $TextArea = styled.textarea<{
  width?: string, 
  height?: string
}>`
  height: 48px;
  width: ${({ width }) => width || "392px"};
  height: ${({ height }) => height || "94px"};
  padding: 12px 22px 12px 16px;
  line-height: 20px;
  font-size: 14px;
  font-family: "sofia-pro",sans-serif;
  resize: none;
  overflow-y: hidden;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  color: #333333;
  &:focus, &:hover {
    /* take away from padding so children don't shift when border changes */
    padding: 11px 21px 11px 15px;
  }
  &:focus {
    outline: none;
    border: 2px solid black !important;
  }
  &:hover {
    border: 2px solid #DBDBDB;
  }
`;

export const $CharCount = styled.div<{
  width?: string
}>`
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 22px;
    top: -22px;
    font-size: 10px;
    line-height: 10px;
    color: #737373;
    height: 0;
    overflow: visible;
    left: ${({ width }) => width ? `calc(${width} - 31px)` : "361px"};
`;