import React from "react";

const Information = (props) => (
  <>
    <svg
      {...props}
      wwidth="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28ZM51.8018 28.0001C51.8018 41.1445 41.1462 51.8001 28.0018 51.8001C14.8574 51.8001 4.20182 41.1445 4.20182 28.0001C4.20182 14.8557 14.8574 4.20011 28.0018 4.20011C41.1462 4.20011 51.8018 14.8557 51.8018 28.0001ZM30.0991 16.1C30.0991 17.2598 29.1589 18.2 27.9991 18.2C26.8393 18.2 25.8991 17.2598 25.8991 16.1C25.8991 14.9402 26.8393 14 27.9991 14C29.1589 14 30.0991 14.9402 30.0991 16.1ZM25.8997 24.5002C25.8997 23.3404 26.8399 22.4002 27.9997 22.4002C29.1595 22.4002 30.0997 23.3404 30.0997 24.5002V39.9002C30.0997 41.06 29.1595 42.0002 27.9997 42.0002C26.8399 42.0002 25.8997 41.06 25.8997 39.9002V24.5002Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Information;
