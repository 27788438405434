import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $Container = styled.div`
  border: 1px solid var(--border-card);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  width: 100%;
  max-width: 534px;

  @media (min-width: ${tabletOrLargerDevices}) {
    padding: 40px 24px;
  }
`;

export const $Title = styled.div`
  text-align: center;
  width: 100%;

  p {
    color: var(--dark-green);
    font: normal 400 21px "sofia-pro";
    line-height: 32px;
    margin: 16px 0 0;
    text-align: center;
  }
  span {
    color: var(--soft-black);
    display: inline-block;
    font: normal 400 16px "sofia-pro";
    line-height: 24px;
    margin: 8px 0 16px;
    text-align: center;

    @media (min-width: ${tabletOrLargerDevices}) {
      margin: 16px 0 48px;
    }
  }

  &:after {
    background-color: var(--medium-grey);
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto;
    width: 80px;
  }
`;

export const $FollowUp = styled.p`
  font-size: 21px;
  margin: 24px auto 16px;
  text-align: center;

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 27px;
    margin: 48px auto 40px;
  }
`;

export const $BottomButton = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 534px;
  width: 100%;
  margin-bottom: 46px;
  gap: 16px;

  @media (max-width: ${tabletOrLargerDevices}) {
    position: sticky;
    bottom: 0;
    padding: 16px 24px;
    background: var(--primary-white);
    margin-bottom: 0px;
  }

  .subtext {
    margin-top: 8px;
    align-self: center;

    .print-again {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;