import React from "react";

const Down = (props) => (
  <>
    <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.05569 0.888326C9.15163 0.989156 9.20361 1.12396 9.20019 1.26309C9.19678 1.40223 9.13826 1.53432 9.03749 1.63033L5.36249 5.13103C5.26485 5.2241 5.13513 5.27603 5.00024 5.27603C4.86535 5.27603 4.73563 5.2241 4.63799 5.13103L0.962989 1.63103C0.862088 1.53504 0.803448 1.40291 0.799969 1.26369C0.79649 1.12448 0.848458 0.989578 0.94444 0.888676C1.04042 0.787775 1.17256 0.729134 1.31177 0.725656C1.45099 0.722177 1.58589 0.774144 1.68679 0.870126L5.00059 4.02643L8.31439 0.869426C8.41531 0.773576 8.55016 0.721724 8.6893 0.725268C8.82844 0.728812 8.96048 0.787462 9.05639 0.888326H9.05569Z" fill="#737373"/>
    </svg>
  </>
);

export default Down;
