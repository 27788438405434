// Dynamic footer shown at the base of the main Return Portal cards.

import styled from "styled-components";

const $FooterBase = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -4px 16px #e7e7e7;
  border-radius: 0 0 12px 12px;
  background-color: white;
  padding: 24px 24px;
  width: 100%;
  box-sizing: border-box;
`;

export default $FooterBase;
