import React from "react";

const One = (props) => (
  <>
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.25" stroke="black" strokeWidth="1.5" />
      <path
        d="M17.914 8.99988L13 11.1629V13.1369L15.961 11.9609V22.9019H18.019V8.99988H17.914Z"
        fill="black"
      />
    </svg>
  </>
);

export default One;
