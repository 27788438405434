import React from "react";

const YellowClock = (props) => (
  <>
    <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#FFF5B3"/>
      <path d="M32 12.5599L26.48 7.87988L24.92 9.67988L30.44 14.3599L32 12.5599ZM15.08 9.79988L13.52 7.99988L8 12.5599L9.56 14.3599L15.08 9.79988ZM20.6 15.3199H18.8V22.5199L24.44 25.9999L25.4 24.5599L20.6 21.6799V15.3199ZM20 10.5199C14 10.5199 9.2 15.3199 9.2 21.3199C9.2 27.3199 14 32.1199 20 32.1199C26 32.1199 30.8 27.3199 30.8 21.3199C30.8 15.3199 26 10.5199 20 10.5199ZM20 29.7199C15.32 29.7199 11.6 25.9999 11.6 21.3199C11.6 16.6399 15.32 12.9199 20 12.9199C24.68 12.9199 28.4 16.6399 28.4 21.3199C28.4 25.9999 24.68 29.7199 20 29.7199Z" fill="#433B03"/>
    </svg>
  </>
);

export default YellowClock;
