// third-party imports
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// private imports

// repo imports
import { PageLifecycle } from "../";
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";

// local imports
import { ReturnPortalBaseWithPage } from "../../components/ReturnPortalBase";
import { changeDropoffReturnIDQueryKey, returnStatusReturnIDQueryKey, getAdminMode, mapURLQueryStringToObject } from "../../utility";
import {
  reset,
  setChangeDropoffReturnID,
  setCustomerStore,
  setPurchasing,
  setReturnSessionID,
  setReturnStatusReturnID,
} from "../../redux/customer/actions";
import { clearLoadingIndicator, goToPage, setConfiguration, setToken, showLoadingIndicator } from "../../redux/app/actions";
import ga from "../../utility/GAEmitter";
import { AnalyticCategories, DropoffMethodsActions, ReturnStatusPageActions } from "../../types/Analytics";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import { Store as AppStore } from "../../redux/app/types";
import { Store as CustomerStore } from "../../redux/customer/types";

const ReturnStatusPage = "returnStatus"
const DropoffMethodsPage = "dropoffMethods"
const RefundOptionsPage = "refundOptions"

class StartReturnPortalLifecycle extends PageLifecycle {
  constructor(page, app, dispatch) {
    super(page, app, dispatch);
  }
}

interface ReturnSession {
  createdAt: string;
  id: string;
  sessionData: {
    app: AppStore;
    customer: CustomerStore;
  };
  purchasing: {
    items: Array<any>
  }
  updatedAt: string;
}

interface LoginResponse {
  token: string
}

/**
 * Return Portal
 * Currently does nothing but will be used to setup
 * the runtime manually (config, localstorage, etc)
 */
const StartReturnPortal = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { app } = useSelector((store: RootReducer) => store);
  const lifecycle = new StartReturnPortalLifecycle(page, dispatch, app);
  const {
    copies,
    locale
  } = useSelector((store) => store.app);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  const [isAdminMode, setIsAdminMode] = useState<boolean>(getAdminMode());
  const [returnShoppingHash, setReturnShoppingHash] = useState<string | undefined>("");
  const retailerName = copies?.retailerName
  useEffect(() => {
    if (app.returnShoppingEnabled){
      const preloadLink = document.createElement("link")
      preloadLink.href = "https://unpkg.com/@happyreturns/happyreturns-script-tag"
      preloadLink.rel = "preload"
      preloadLink.as = "script"
      document.head.appendChild(preloadLink)
    }
    const queries = mapURLQueryStringToObject();
    const hash = window.location.href.match(/#hr_rsz=([^]+)$/)?.[1];
    setReturnShoppingHash(hash);
    if (app.links?.favicon) {
      let headTitle = document?.querySelector("head");
      let favicon = headTitle?.querySelector('link[rel="icon"]');
      favicon?.setAttribute("href", app.links.favicon);
    }
    // if changeDropoffReturnID URL query is present, skip login and go straight to dropoffMethods.
    const changeDropoffReturnID = queries[changeDropoffReturnIDQueryKey];
    const returnStatusReturnID = queries[returnStatusReturnIDQueryKey];
    if (changeDropoffReturnID) {
      dispatch(setChangeDropoffReturnID(changeDropoffReturnID));
      dispatch(goToPage(DropoffMethodsPage));
      ga.setDimensions({
        user_properties: {
          admin_mode: isAdminMode,
          retailer_name: retailerName,
          locale: locale,
          change_dropoff: true
        }
      });
      ga.event({
        category: AnalyticCategories.DropoffMethodsPage,
        action: DropoffMethodsActions.ChangeDropoffMethod
      });

      return;
    } else if (returnStatusReturnID){
      dispatch(setReturnStatusReturnID(returnStatusReturnID));
      dispatch(goToPage(ReturnStatusPage));

      ga.setDimensions({
        user_properties: {
          admin_mode: isAdminMode,
          retailer_name: retailerName,
          locale: locale,
        }
      });
      ga.event({
        category: AnalyticCategories.ReturnStatusPage,
        action: ReturnStatusPageActions.ReturnStatusLoaded
      });

      return;
    } else if (hash) {
      const { id: returnShoppingID, jwt } = JSON.parse(decodeURIComponent(hash));
      // clear the url hash parameters
      window.history.replaceState(null, document.title, window.location.pathname + window.location.search);
      if (app.returnShoppingEnabled) {
        try {
          dispatch(setToken(jwt));
          retrieveSession(returnShoppingID);
          return;
        } catch (e) {
          console.error(e);
          lifecycle.advance();
        }
      }
    }
    lifecycle.advance();
  }, []);

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS

  const retrieveSession = async (id: string) => {
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      const resp = await axios.get<ReturnSession>(`/returnsession/${id}`);
      const { sessionData, purchasing, id: returnSessionID } = resp.data;
      dispatch(setConfiguration(sessionData.app));
      sessionData.customer.featureStudyIDs = new Set<string>();
      dispatch(setCustomerStore(sessionData.customer));
      dispatch(setReturnSessionID(returnSessionID));
      dispatch(setToken(sessionData.app.token));
      if (purchasing?.items.length) {
        dispatch(setPurchasing(purchasing));
        dispatch(goToPage(DropoffMethodsPage));
      } else {
        // if their cart is empty, route them to the refund options page
        dispatch(goToPage(RefundOptionsPage));
      }
    } catch (e) {
      console.error(e);
      dispatch(reset());
      lifecycle.advance();
    } finally {
      dispatch(clearLoadingIndicator());
    }
  };

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <ReturnPortalBaseWithPage page={page}>
      <></>
    </ReturnPortalBaseWithPage>
  );
  //----------------------------------------------------------------------------
};

export default StartReturnPortal;
