import React, { useRef } from "react";
import { DataCyStrings } from "../../types/DataCyStrings";
import { $CharCount, $TextArea, $TextAreaWrapper } from "./styles";

interface TextAreaProps {
  value: string,
  placeholder?: string,
  onClick: Function,
  onChange: Function,
  maxLength: number,
  width: string,
  height: string,
  disableNewline?: boolean,
  dataCyString?: DataCyStrings,
  label: string
}

//--------------------------------------------------------------------------------------------------
// TextArea (Multi Line)
//--------------------------------------------------------------------------------------------------
/**
 * XXX - this is taken directly from the retailer dashboard, once
 * our new shared component package is created, this should be removed
 * from returns-app and retailer-dashboard and replcaed with the shared
 * package version
 *
 * Input field that allows multiline inputs while displaying the char count
 * @props value - stateful string passed to props (should be tied to onChange)
 * @props placeholder - placeholder string in case value is empty
 * @props onChange - disapatch/state function used to change the value prop
 * @props maxLength - amount of characters allowed for the user to input
 * @props width - the width of the actual textarea component (not the wrapper)
 * @props disableNewline - when enabled via props, remove linebreaks from user input
 */
const TextArea = ({value, placeholder, onClick, onChange, maxLength, width, height, disableNewline, dataCyString, label }:TextAreaProps) => {
  const handleChange = (e) => {
    let { value } = e?.target;
    if (disableNewline) {
      value = value.replace(/(\r\n|\r|\n)/g, "");
    }
    onChange(value);
  }
  const handleClick = (e) => {
    onClick(e);
  }

  return (
      <$TextAreaWrapper>
        <$TextArea
          placeholder={value==="" ? placeholder : ""}
          value={value}
          onClick={handleClick}
          onChange={handleChange}
          onPaste={handleChange}
          onCut={handleChange}
          width={width}
          height={height}
          maxLength={maxLength}
          data-cy={dataCyString}
          aria-label={label}
        />
        <$CharCount width={width}>{!isNaN(maxLength) ? maxLength - value.length : ""}</$CharCount>
      </$TextAreaWrapper>
  );
}

export default TextArea;
