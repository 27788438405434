import styled from "styled-components";

const $ItemPresentCounter = styled.div`
  width: 41.66vw;
  height: 13.33vw;
  max-width: 200px;
  max-height: 55px;
  min-width: 150px;
  min-height: 48px;
  display: flex;
  &.no-items-present-count {
    .items-present-count {
      border-radius: 8px;
    }
  }
  .items-present, .items-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    border: 1px solid var(--high-emphasis-black);
    padding: 4px;
    .count {
      font-weight: 500;
      font-size: min(8.33vw, 34px);
    }
    .count-label {
      color: var(--medium-emphasis-black);
      font-weight: 700;
      font-size: min(2.77778vw, 12px);
    }
  }
  .items-present {
    border-radius: 8px 0 0 8px;
    margin-right: -1px;
  }
  .items-selected {
    border-radius: 0 8px 8px 0;
    &.too-many-items {
      background-color: var(--warning-soft);
      .count, .count-label {
        color: var(--warning-bold);
      }
    }
  }
`;

export default $ItemPresentCounter;