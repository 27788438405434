import { sha256 } from 'js-sha256';
// Convert the number of items marked for completion to words
// Only spell out numbers if the count is less than 10
export const convertNumberToWords = (num: number) => {
  const words = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];
  const word = words[num] || num
  const noun = `item${num == 1 ? '' : 's'}`
  return `${word} ${noun}`
};

// Hash a string using the SHA-256 algorithm, returning the result as a hex string.
export const hashString = (subject: string | undefined):string => {
	if (typeof subject !== 'string')
		throw new TypeError('Expected a String');

	if (subject === "" || subject === undefined)
		return "";

	return sha256.create().update(subject).hex();
}