import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  bagsAction,
  currentBagAction,
  CurrentPageAction,
  LoadingAction,
  ModalAction,
  MultiStoreAction,
} from "../../redux/enums";
import ReturnistaPrimaryButton from "../Button/ReturnistaPrimaryButton";
import { RootStateOrAny, useSelector } from "react-redux";

import { SVG } from "../svg";
import { $Bag, $BagItem, $NewBagLink, $IconWrapper, $ModalButtonWrapper } from "./styles";
import { Bag, BagItem, ReturnistaBagListProps } from "./types";
import ReturnistaFeedbackIcon from "../ReturnistaFeedbackIcon";
import axios from "axios";
import ReturnistaAnalytics from "../../utility/ReturnistaAnalytics";
import ReturnistaImageManager from "../../utility/ReturnistaImageManager";
import { isUPS } from "../../utility";
import ga from "../../utility/GAEmitter";
import { ReturnSummaryActions } from "../../types/ReturnistaGA";
import { store } from "../../redux/returnista-store";

const ReturnistaBagList = ({ setToasterFeedback }: ReturnistaBagListProps) => {
  const {
    items: ItemState,
    bags: BagState,
    returnObject,
    userToken,
    currentPage,
  } = useSelector((state: RootStateOrAny) => state);
  const dispatch = useDispatch();
  const [noItemsInReturn, setNoItemsInReturn] = useState<boolean>(false);

  const bags = Object.entries(BagState.byId).sort((a: Bag, b: Bag) => {
    return b[1].timestamp - a[1].timestamp;
  });

  const returningItems: string[] = returnObject.returning.map((i) => i.id);
  const remainingItems: string[] = returningItems.filter((i) => {
    return !ItemState.allIds.includes(i);
  });

  const addNewItem = (bag: string) => (): void => {
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.AddItemClicked });
    dispatch({ type: CurrentPageAction.NewItem });
    dispatch({ type: currentBagAction.Set, bag });
  };

  const onRequestClose = (): void => {
    const { modal } = store.getState();
    ga.pageEvent({ category: modal.name || "undefined modal name", action: ReturnSummaryActions.ModalClosed });
    dispatch({ type: ModalAction.Unset });
  };

  const removeBagModalName = "remove-bag-modal";
  const displayBagRemovalModal = (bag: string, removeBagAndItems: () => void): void => {
    dispatch({
      type: ModalAction.Set,
      modalProps: {
        name: removeBagModalName,
        dataCyString: removeBagModalName,
        iconElement: (
          <$IconWrapper>
            <ReturnistaFeedbackIcon status="warning" />
          </$IconWrapper>
        ),
        primaryMessage: "Remove the bag",
        subMessages: [`Do you want to remove bag ${bag}? All items in the bag will also be removed.`],
        onRequestClose,
        button: (
          <$ModalButtonWrapper>
            <ReturnistaPrimaryButton variant="outlined" onClick={onRequestClose} dataCyString="close-bag-removal-modal">
              Cancel
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton
              onClick={() => {
                ga.pageEvent({ category: removeBagModalName, action: ReturnSummaryActions.ConfirmClicked });
                removeBagAndItems();
              }}
              dataCyString="confirm-bag-removal"
            >
              Confirm
            </ReturnistaPrimaryButton>
          </$ModalButtonWrapper>
        ),
      },
    });
  };

  const removeItemModalName = "remove-item-modal";
  const displayItemRemovalModal = (item: BagItem, removeItem: () => void): void => {
    dispatch({
      type: ModalAction.Set,
      modalProps: {
        name: removeItemModalName,
        dataCyString: removeItemModalName,
        iconElement: (
          <$IconWrapper>
            <ReturnistaFeedbackIcon status="warning" />
          </$IconWrapper>
        ),
        primaryMessage: "Remove the item",
        subMessages: [`Do you want to remove ${item.data.purchase.name}?`],
        onRequestClose,
        button: (
          <$ModalButtonWrapper>
            <ReturnistaPrimaryButton
              variant="outlined"
              onClick={onRequestClose}
              dataCyString="close-item-removal-modal"
            >
              Cancel
            </ReturnistaPrimaryButton>
            <ReturnistaPrimaryButton
              onClick={() => {
                ga.pageEvent({ category: removeItemModalName, action: ReturnSummaryActions.ConfirmClicked });
                removeItem();
              }}
              dataCyString="confirm-item-removal"
            >
              Confirm
            </ReturnistaPrimaryButton>
          </$ModalButtonWrapper>
        ),
      },
    });
  };

  const removeBag =
    (bag: string, items: BagItem[]) =>
    (fromItems = false, item?: BagItem): void => {
      ReturnistaAnalytics.summaryRemoval("bag");
      !fromItems && ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.RemoveBagClicked });
      const removeBagAndItems = async () => {
        dispatch({ type: LoadingAction.Set });
        try {
          await axios.delete("/returns/bags", {
            headers: {
              Authorization: `Bearer ${userToken}`, // TODO: remove this header
            },
            data: { barCode: bag, returnID: returnObject.id },
          });
          dispatch({ type: LoadingAction.Unset });
          items.forEach((item) => {
            dispatch({ type: MultiStoreAction.RemoveItem, bag, item });
          });

          dispatch({ type: bagsAction.Remove, bag });

          onRequestClose();

          if (fromItems && item) {
            setToasterFeedback({
              message: `Removed ${item.data.purchase.name} and the bag ${bag}.`,
              type: "success",
              show: true,
            });
            return;
          }

          setToasterFeedback({
            message: `Removed the bag ${bag} and all items in the bag.`,
            type: "success",
            show: true,
          });
        } catch (error) {
          dispatch({ type: LoadingAction.Unset });
          onRequestClose();
          throw new Error(error);
        }
      };

      displayBagRemovalModal(bag, removeBagAndItems);
    };

  const removeItem = (bag: string, item: BagItem) => (): void => {
    ReturnistaAnalytics.summaryRemoval("item");
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.RemoveItemClicked });
    if (BagState.byId[bag].items.length === 1 && !isUPS()) {
      removeBag(bag, [item])(true, item);

      return;
    }

    displayItemRemovalModal(item, () => {
      dispatch({ type: MultiStoreAction.RemoveItem, bag, item });

      setToasterFeedback({
        message: `Removed ${item.data.purchase.name}`,
        type: "success",
        show: true,
      });

      onRequestClose();
    });
  };

  const addNewBag = () => {
    ga.pageEvent({ category: currentPage, action: ReturnSummaryActions.AddBagClicked });
    dispatch({ type: CurrentPageAction.NewBag });
  };

  const getBagItems = (b: Bag, idx: number): ReactNode => {
    const items = b[1].items
      .map((i) => ItemState.byId[i])
      .sort((a: BagItem, b: BagItem) => {
        return b.timestamp - a.timestamp;
      });

    const bagItem = (i: BagItem): ReactNode => {
      const imageUrl = i.data?.purchase?.images?.[0] ?? i.data?.purchase?.thumbnail;
      const imageData = ReturnistaImageManager.getImage(imageUrl);
      return (
        <$BagItem key={i.data.id}>
          <div className="image">
            <img src={ReturnistaImageManager.getImage(imageData)} alt="purchase image" />
          </div>
          <div data-cy="product-in-bag-info" className="product-info">
            <p data-cy="product-in-bag-name" className="name">
              {i.data?.purchase?.name ?? ""}
            </p>
            <div className="display">
              {i.data?.purchase.display
                .filter((d) => d.label !== "Vendor")
                .map((d) => (
                  <p key={d.label}>{d.value}</p>
                ))}
            </div>
            <ReturnistaPrimaryButton onClick={removeItem(b[0], i)} dataCyString="remove-item" variant="link">
              Remove
            </ReturnistaPrimaryButton>
          </div>
        </$BagItem>
      );
    };

    return (
      <$Bag data-cy={`bag-${b[0]}`} key={b[0]}>
        {!isUPS() && (
          <div className="header">
            <div>
              <p>Bag ID: {b[0]}</p>
              <span>
                {b[1].items.length} {b[1].items.length > 1 ? "items" : "item"}
              </span>
            </div>
            <button aria-label="remove bag" onClick={() => removeBag(b[0], items)(false)}>
              <SVG name="x" />
            </button>
          </div>
        )}
        {remainingItems.length > 0 && idx === 0 && (
          <div className="itemAction">
            <ReturnistaPrimaryButton onClick={addNewItem(b[0])} dataCyString="add-item-to-bag">
              <SVG name="returnistaAddIcon" fill="#fff" />
              <span className="text">Add another item</span>
            </ReturnistaPrimaryButton>
            {remainingItems.length > 0 && !noItemsInReturn && !isUPS() && (
              <$NewBagLink>
                <ReturnistaPrimaryButton onClick={addNewBag} dataCyString="new-bag" variant="link">
                  This bag is full, start a new bag
                </ReturnistaPrimaryButton>
              </$NewBagLink>
            )}
          </div>
        )}
        {items.map(bagItem)}
      </$Bag>
    );
  };

  useEffect(() => {
    if (ItemState.allIds.length === 0 && BagState.allIds.length === 0) {
      setNoItemsInReturn(true);
    }
  }, [ItemState, BagState]);

  return <>{bags.map(getBagItems)} </>;
};

export default ReturnistaBagList;
