// third-party imports
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// repo imports
import { clearLoadingIndicator, goToPage, showLoadingIndicator } from "../../redux/app/actions";
import { setReturnReasonChildSelectedInstance } from "../../redux/customer/actions";
import { PageLifecycle } from '../';
import OptionCard from "../../components/OptionCard";
import { PageProps } from "../types";
import { Reason } from "../../types/Instance";
import { RootReducer } from "../../redux/reducers";
import ModalContainer from "../../components/Modal/ModalContainer";
import useSelector from "../../utility/useTypedSelector";
import logger from "../../utility/logger/logger";

// local imports
import $ReturnReasonsChildren, { $ReturnReasonsChildrenModal } from "./styles";
import PageModal from "../../components/Modal/PageModal";
import { ErrorAlertMessage } from "../../components/AlertMessage";
import {
  AnalyticCategories,
  AnalyticsPageRoutes,
  getCategoryFromPageOrModalName
} from "../../types/Analytics";
import { getAdminMode } from "../../utility";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import getTranslator from "../../utility/getTranslator";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";

const useTranslation = getTranslator("ReturnReasons");

class ReturnReasonsChildrenLifecycle extends PageLifecycle {
    constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

/**
 * Used to choose the return reasons for the selected instance
 */
const ReturnReasonsChildren = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const { customer, app } = useSelector((store: RootReducer) => store);

  const { currentPageName, currentModalPageName, locale, copies } = useSelector((store) => store.app);
  const [isAdminMode] = useState<boolean>(getAdminMode());
  const retailerName = copies?.retailerName

  const lifecycle = new ReturnReasonsChildrenLifecycle(page, dispatch, app);
  const [returnReasonsChildren, setReturnReasonsChildren] = useState<Reason[]>([]);
  const [displayNoOptionsModal, setDisplayNoOptionsModal] = useState(false);
  const [error, setError] = useState("");
  const returnReasonsErrorCopies = {
    noReasons: t('noReasonsErrorCopy')
  }

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    if (customer.selectedInstance) {
      getReturnReasonsChildren();
    }
  },[customer.selectedInstance])

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    });
    ga.sendPageDetails(AnalyticsPageRoutes.ReturnReasons, AnalyticCategories.ReturnReasonsModal);
  }, []);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS
  const getReturnReasonsChildren = async () => {
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      var childReasonsLength = customer.selectedInstance?.reasonInfo?.child_reasons?.length
      if(childReasonsLength === undefined || childReasonsLength === 0) {
        switch (app.runtime) {
          case "return-portal":
            setError(returnReasonsErrorCopies.noReasons);
            break;
          default:
            setDisplayNoOptionsModal(true);
        }
      } else {
        setReturnReasonsChildren(customer.selectedInstance?.reasonInfo?.child_reasons || []);
      }
    } catch(e) {
      console.error(e);
      logger.Info("Failed to fetch children return reasons");
      alert(t('returnReasonsCatchString'));
    } finally {
      dispatch(clearLoadingIndicator());
    }
  }

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const onReturnReasonChildrenClicked = (reasonInfo: Reason, idx) => {
    const category = getCategoryFromPageOrModalName(currentPageName, currentModalPageName)
    // label the index of the selected reason starting with 0
    ga.event({
      category: category,
      action: `Selected child return reason ${reasonInfo.id}`,
      label: `reason position: ${idx}`
    });
    dispatch(setReturnReasonChildSelectedInstance(reasonInfo));

    // advance to reason notes
    lifecycle.advance(app.enableCustomerReturnNotes ? "reasonNotes" : "returnOptions");
  }
  //---------------------------------------------------------------------------
  const renderReturnReasonsChildren = () => {
    return returnReasonsChildren.map((reason, idx) => (
      <OptionCard key={reason.pkey} title={reason.label} dataCyString={`${DataCyStrings.returnReasonButton}-${reason.id}`} onClick={() => onReturnReasonChildrenClicked(reason, idx)}/>
    ));
  }
  const renderNoOptionsModal = () => {
    return (
      <ModalContainer
        isOpen={displayNoOptionsModal}
        onRequestClose={() => {dispatch(goToPage("orderList"))}}
        primaryMessage={t('noReasonsAvailable')}
        subMessages={[t('noReasonsSubMesssage')]}
        closeIcon={true}
        height={"442px"}
        width={"456px"}
      />
    )
  }

  //----------------------------------------------------------------------------
  // RENDERING
  const baseComponentsByRuntime = {
    "return-portal": ReturnReasonsChildrenModalWrapper
  }

  const BaseComponent = baseComponentsByRuntime[app?.runtime] ?
    baseComponentsByRuntime[app.runtime] :
    $ReturnReasonsChildren

  return (
    <BaseComponent page={page} error={error}>
      {renderReturnReasonsChildren()}
      {renderNoOptionsModal()}
    </BaseComponent>
  );
  //----------------------------------------------------------------------------
}

// the page itself lives as a modal for return-portal but contains
// the same contents with the exception of showing the selected
// instance on this page
const ReturnReasonsChildrenModalWrapper = ({ page, children, error }) => {
  const { selectedInstance } = useSelector(store => store.customer);
  const itemSKU = selectedInstance?.purchase.sku;
  const itemUPC = selectedInstance?.purchase.upc;
  const itemPrice = selectedInstance?.purchase.price;
  const detailsWithoutVendor = selectedInstance?.purchase?.display?.filter(item => item?.label != "Vendor");
  const detailsStr = detailsWithoutVendor?.reduce((str, detail, idx) => {
    str += detail.value;
    if (idx != detailsWithoutVendor.length - 1) {
      str += " • ";
    }
    return str;
  }, "");

  return (
    <PageModal page={page} dataCyString={DataCyStrings.returnReasonsModal}>
      <$ReturnReasonsChildrenModal>
        <div className="selected-instance">
          <div className="purchase-thumbnail">
            <img src={selectedInstance?.purchase?.thumbnail} alt=""/>
          </div>
          <div className="purchase-info">
            <div className="purchase-name">
              {selectedInstance?.purchase?.name}
            </div>
            <div className="purchase-details">
              {detailsStr}
            </div>
            <div className="purchase-details" data-cy={DataCyStrings.itemPrice}>
              {itemPrice && itemPrice}
            </div>
            <div className="purchase-details">
              {itemSKU && `SKU: ${itemSKU}`}
              {!itemSKU && itemUPC && `UPC: ${itemUPC}`}
            </div>
          </div>
        </div>
        {error && <ErrorAlertMessage message={error}/>}
        {children}
      </$ReturnReasonsChildrenModal>
    </PageModal>
  )
}

export default ReturnReasonsChildren;
