import React from "react";


const GiftCard = (props) => (
  <>
    <svg
      {...props}
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="26.5"
        height="18.5"
        rx="2.25"
        stroke="#737373"
        strokeWidth="1.5"
      />
      <rect y="8.99976" width="28" height="2" fill="#737373" />
      <rect x="16" y="1" width="2" height="18" fill="#737373" />
      <path
        d="M19.374 4.54805L19.374 4.54805C19.815 4.10703 20.3939 3.92664 20.9856 3.95558C21.596 3.98544 22.1392 4.23547 22.4516 4.54794C22.8162 4.91256 23.1173 5.47 23.1894 6.04553C23.257 6.58607 23.1273 7.13972 22.6335 7.63357C22.4467 7.82038 22.1167 7.994 21.6201 8.11768C21.135 8.23848 20.5751 8.29087 20.0224 8.3006C19.5462 8.30899 19.0981 8.28543 18.7423 8.25573C18.7149 7.923 18.6941 7.5098 18.7029 7.07062C18.7135 6.54273 18.7663 6.0074 18.8866 5.54106C19.0091 5.06583 19.1815 4.7405 19.374 4.54805Z"
        stroke="#737373"
        strokeWidth="2"
      />
      <path
        d="M11.5478 7.72052L11.5478 7.72052C11.1068 7.2795 10.9264 6.70057 10.9554 6.10893C10.9852 5.49848 11.2353 4.95532 11.5477 4.64285C11.9123 4.27824 12.4698 3.97718 13.0453 3.90513C13.5859 3.83746 14.1395 3.96715 14.6334 4.461C14.8202 4.64781 14.9938 4.97775 15.1175 5.47442C15.2383 5.95952 15.2907 6.51937 15.3004 7.07211C15.3088 7.5483 15.2852 7.99643 15.2555 8.35219C14.9228 8.37961 14.5096 8.40038 14.0704 8.39158C13.5425 8.381 13.0072 8.32814 12.5408 8.20791C12.0656 8.08538 11.7403 7.91297 11.5478 7.72052Z"
        stroke="#737373"
        strokeWidth="2"
      />
      <rect
        x="16.1213"
        y="10"
        width="2"
        height="5.20829"
        rx="1"
        transform="rotate(45 16.1213 10)"
        fill="#737373"
      />
      <rect
        x="17"
        y="10.7071"
        width="2"
        height="5.16943"
        rx="1"
        transform="rotate(-45 17 10.7071)"
        fill="#737373"
      />
    </svg>
  </>
);

export default GiftCard;
