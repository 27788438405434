import React from "react";

const ReturnistaInfo = (props) => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 22.375C20.5667 22.375 21.0417 22.1833 21.425 21.8C21.8083 21.4167 22 20.9417 22 20.375V12.325C22 11.7583 21.8083 11.2917 21.425 10.925C21.0417 10.5583 20.5667 10.375 20 10.375C19.4333 10.375 18.9583 10.5667 18.575 10.95C18.1917 11.3333 18 11.8083 18 12.375V20.425C18 20.9917 18.1917 21.4583 18.575 21.825C18.9583 22.1917 19.4333 22.375 20 22.375ZM20 30.375C20.5667 30.375 21.0417 30.1833 21.425 29.8C21.8083 29.4167 22 28.9417 22 28.375C22 27.8083 21.8083 27.3333 21.425 26.95C21.0417 26.5667 20.5667 26.375 20 26.375C19.4333 26.375 18.9583 26.5667 18.575 26.95C18.1917 27.3333 18 27.8083 18 28.375C18 28.9417 18.1917 29.4167 18.575 29.8C18.9583 30.1833 19.4333 30.375 20 30.375ZM20 40.375C17.2333 40.375 14.6333 39.85 12.2 38.8C9.76667 37.75 7.65 36.325 5.85 34.525C4.05 32.725 2.625 30.6083 1.575 28.175C0.525 25.7417 0 23.1417 0 20.375C0 17.6083 0.525 15.0083 1.575 12.575C2.625 10.1417 4.05 8.025 5.85 6.225C7.65 4.425 9.76667 3 12.2 1.95C14.6333 0.9 17.2333 0.375 20 0.375C22.7667 0.375 25.3667 0.9 27.8 1.95C30.2333 3 32.35 4.425 34.15 6.225C35.95 8.025 37.375 10.1417 38.425 12.575C39.475 15.0083 40 17.6083 40 20.375C40 23.1417 39.475 25.7417 38.425 28.175C37.375 30.6083 35.95 32.725 34.15 34.525C32.35 36.325 30.2333 37.75 27.8 38.8C25.3667 39.85 22.7667 40.375 20 40.375ZM20 36.375C24.4333 36.375 28.2083 34.8167 31.325 31.7C34.4417 28.5833 36 24.8083 36 20.375C36 15.9417 34.4417 12.1667 31.325 9.05C28.2083 5.93333 24.4333 4.375 20 4.375C15.5667 4.375 11.7917 5.93333 8.675 9.05C5.55833 12.1667 4 15.9417 4 20.375C4 24.8083 5.55833 28.5833 8.675 31.7C11.7917 34.8167 15.5667 36.375 20 36.375Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default ReturnistaInfo;
