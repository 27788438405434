import React from "react";

const RightArrow = (props) => (
  <>
    <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.82752 0.827505C5.75007 0.904828 5.68862 0.996665 5.64669 1.09776C5.60477 1.19886 5.58319 1.30723 5.58319 1.41667C5.58319 1.52612 5.60477 1.63449 5.64669 1.73558C5.68862 1.83668 5.75007 1.92851 5.82752 2.00584L8.98836 5.16667H1.00002C0.779007 5.16667 0.567045 5.25447 0.410765 5.41075C0.254485 5.56703 0.166687 5.77899 0.166687 6C0.166687 6.22102 0.254485 6.43298 0.410765 6.58926C0.567045 6.74554 0.779007 6.83334 1.00002 6.83334H8.98836L5.82752 9.99417C5.75007 10.0715 5.68862 10.1633 5.64669 10.2644C5.60477 10.3655 5.58319 10.4739 5.58319 10.5833C5.58319 10.6928 5.60477 10.8012 5.64669 10.9022C5.68862 11.0033 5.75007 11.0952 5.82752 11.1725C6.15252 11.4975 6.68085 11.4975 7.00669 11.1725L11.5892 6.58917C11.7401 6.43884 11.8275 6.23628 11.8334 6.02334V5.97584C11.8274 5.76317 11.74 5.56092 11.5892 5.41084L7.00669 0.827505C6.92929 0.750025 6.83739 0.688559 6.73622 0.646622C6.63506 0.604685 6.52662 0.583099 6.4171 0.583099C6.30759 0.583099 6.19915 0.604685 6.09799 0.646622C5.99682 0.688559 5.90492 0.750025 5.82752 0.827505V0.827505Z"
        fill="#221F1F"
      />
    </svg>
  </>
);

export default RightArrow;
