import React from "react";

const EditLocation = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask
      id="mask0_4163_9456"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4163_9456)">
      <path
        d="M7.99996 14.6493C6.21107 13.1271 4.87496 11.7132 3.99163 10.4077C3.10829 9.10213 2.66663 7.89379 2.66663 6.78268C2.66663 5.11602 3.20274 3.78824 4.27496 2.79935C5.34718 1.81046 6.58885 1.31602 7.99996 1.31602C8.29996 1.31602 8.59718 1.34102 8.89163 1.39102C9.18607 1.44102 9.47218 1.51046 9.74996 1.59935L8.66663 2.69935C8.55551 2.67713 8.44718 2.66324 8.34163 2.65768C8.23607 2.65213 8.12218 2.64935 7.99996 2.64935C6.87774 2.64935 5.93051 3.03546 5.15829 3.80768C4.38607 4.5799 3.99996 5.57157 3.99996 6.78268C3.99996 7.57157 4.32774 8.47435 4.98329 9.49102C5.63885 10.5077 6.6444 11.6382 7.99996 12.8827C9.35551 11.6382 10.3611 10.5077 11.0166 9.49102C11.6722 8.47435 12 7.57157 12 6.78268C12 6.64935 11.9944 6.51602 11.9833 6.38268C11.9722 6.24935 11.9555 6.12157 11.9333 5.99935L13.0333 4.89935C13.1333 5.18824 13.2083 5.48824 13.2583 5.79935C13.3083 6.11046 13.3333 6.43824 13.3333 6.78268C13.3333 7.89379 12.8916 9.10213 12.0083 10.4077C11.125 11.7132 9.78885 13.1271 7.99996 14.6493ZM11.4666 1.78268L7.33329 5.91602V7.31602H8.73329L12.8666 3.18268L11.4666 1.78268ZM13.3333 2.71602L13.8 2.24935C13.9222 2.12713 13.9833 1.97157 13.9833 1.78268C13.9833 1.59379 13.9222 1.43824 13.8 1.31602L13.3333 0.849349C13.2111 0.727127 13.0555 0.666016 12.8666 0.666016C12.6777 0.666016 12.5222 0.727127 12.4 0.849349L11.9333 1.31602L13.3333 2.71602Z"
        fill="#616161"
      />
    </g>
  </svg>
);

export default EditLocation;
