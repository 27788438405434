import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header, { HeaderLogo } from "../../components/ReturnistaHeader";
import Content from "../../components/ReturnistaContent";
import { getReturnistaJWTClaims, mapURLQueryStringToObject } from "../../utility";
import { RootReducer } from "../../redux/returnista-store";
import { SVG } from "../../components/svg";
import ContactInfo from "../../components/ContactInfo";
import { $StartReturn, $SignOutButton } from "./styles";
import { CurrentPageAction, MultiStoreAction } from "../../redux/enums";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import ReturnistaAlertMessage from "../../components/ReturnistaAlertMessage";
import ReturnistaAnalytics from "../../utility/ReturnistaAnalytics";
import { ReturnistaURLQueries } from "../../types/JWTClaims";
import ga from "../../utility/GAEmitter";
import { StartReturnActions } from "../../types/ReturnistaGA";

const StartReturn = () => {
  const dispatch = useDispatch();
  const { userToken, currentPage } = useSelector<RootReducer, RootReducer>((store) => store);
  const claims = getReturnistaJWTClaims(userToken ?? "");
  const urlQueries = mapURLQueryStringToObject() as ReturnistaURLQueries;
  const { versionID, androidVersionID } = urlQueries;
  const displayVersion = versionID ?? androidVersionID ?? "";

  const onStartReturn = () => {
    ga.pageEvent({category: currentPage, action: StartReturnActions.StartedReturn});
    dispatch({ type: CurrentPageAction.Next });
  }

  const onSignOut = () => {
    ga.pageEvent({category: currentPage, action: StartReturnActions.SignedOut});
    dispatch({ type: MultiStoreAction.AppLogout })
  }

  // On mount
  useEffect(() => {
    // Reset any app state that may be present when this page loads
    dispatch({ type: MultiStoreAction.AppReset });
    // Start recording unless we are using an iPad device to reduce load on cell network
    const hasIpadUserAgent = window.navigator.userAgent.toLowerCase().includes("ipad");
    if (!hasIpadUserAgent) {
      ReturnistaAnalytics.startReturn();
    }
  }, []);

  const SignOut = () => (
    <$SignOutButton onClick={onSignOut}>Sign Out</$SignOutButton>
  );

  return (
    <>
      <Header center={<HeaderLogo />} right={<SignOut />} />
      <Content>
        {claims?.returnsApp?.returnProcessingIssue ? (
          <ReturnistaAlertMessage
            type="error"
            message="Your location is temporarily inactive."
            dataCyString="recording-message"
          />
        ) : (
          <ReturnistaAlertMessage
            message="Your session might be recorded for monitoring and training purposes."
            dataCyString="recording-message"
          />
        )}
        <$StartReturn>
          <div className="user-info">
            <SVG name="genericUser" />
            <div className="name">{claims.returnsApp?.representative?.name}</div>
            <div className="info">
              <p>
                Username: <span data-cy="username">{claims.returnsApp?.representative?.login}</span>
              </p>
              <p>
                Location: <span data-cy="locationId">{claims.returnsApp?.locationID}</span>
              </p>
              {claims.returnsApp?.retailerID && (
                <>
                  <br />
                  Retailer: {claims.returnsApp.retailerID}
                </>
              )}
            </div>
            <ReturnistaPrimaryButton
              disabled={Boolean(claims?.returnsApp?.returnProcessingIssue)}
              onClick={onStartReturn}
              dataCyString="start-return"
            >
              Start a Return
            </ReturnistaPrimaryButton>
          </div>
          <ContactInfo email={"returnbar@happyreturns.com"} phoneNumber={"(877) 750-4888"} versionID={displayVersion} />
        </$StartReturn>
      </Content>
    </>
  );
};

export default StartReturn;
