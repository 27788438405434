import styled from "styled-components";

import { modalZIndex } from "../../utility";
import { MOBILE_BREAKPOINT } from "../../globalConstants";

interface ReturnPortalBaseProps {
  shrinkToFit?: boolean;
}

const $ReturnPortalBase = styled.div<ReturnPortalBaseProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: var(--image-background);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-size: 10px;
  padding: 16px 24px 0 24px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 30px;
    background: white;
    padding: 0;
  }

  .page-base {
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 616px;
    margin-bottom: 16px;
    overflow: hidden;
    width: 100%;
    z-index: ${modalZIndex - 3};
    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      height: ${({ shrinkToFit }) => (shrinkToFit ? "unset" : "100%")};
    }
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-bottom: 0px;
      .page-scroll-wrapper > .page-content {
        padding: 16px 16px 70px 16px;
        min-height: 100%;
      }
    }
    .page-scroll-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      width: 100%;

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: inline-block;
      }
    }
    .page-content {
      padding: 24px;
      z-index: ${modalZIndex - 2};
    }
    .page-header {
      width: 100%;
      height: 70px;
      position: sticky;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: 0;
      background-color: white;
      font-weight: 800;
      font-size: 18px;
      line-height: 26px;
      padding: 20px 12px 18px 12px;
      text-align: center;
      border-bottom: 1px solid var(--border-grey);

      .left-header,
      .right-header {
        display: flex;
        min-width: 30px;
      }

      .middle-title {
        font-size: 18px;
        line-height: 24px;
      }

      .middle-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--header-grey);
      }
      @media (max-width: 480px) {
        flex-shrink: 0;
        height: 48px;
        padding: 22px 0px;
        .middle-title {
          font-size: 16px;
          line-height: 24px;
        }
        .middle-subtitle {
          display: none;
        }
      }
      z-index: ${modalZIndex - 1};
    }
  }
  .footer {
    .dropoff {
      display: block;
    }
    .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    & > div {
      .return-count-group {
        font-size: 15px;
        line-height: 22px;
        color: #737373;
      }
      button {
        width: auto;
        height: auto;
        min-height: 48px;
        padding: 16px;
        max-width: 50%;
        word-wrap: break-word;
        box-sizing: border-box;
      }
      .deactivated {
        button {
          background-color: var(--disabled-grey);
          color: var(--header-grey);
          &:hover {
            cursor: not-allowed;
          }
        }
      }
      @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        z-index: ${modalZIndex};
        width: 100vw;
        bottom: 0;
        left: 0;

        .return-count-group {
          span {
            font-size: 16px;
          }
          width: 100vw;
        }
      }
    }
    .disclaimer {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #737373;
      word-wrap: break-word;
      width: 100%;
      margin-top: 12px;

      a {
        color: #221f1f;
      }
    }
    &.return-shopping {
      button {
        display: flex;
        max-width: none;
        margin-left: auto;
        svg {
          margin-left: 12px;
        }
      }
    }
  }

  .return-preview-custom-message {
    padding: 0 0 15px 0;
  }
`;

export default $ReturnPortalBase;
