import React from "react";

const BackArrow = (props) => (
  <>
    <svg {...props} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.01814 11.7941C6.8741 11.9312 6.68153 12.0054 6.48276 12.0006C6.28399 11.9957 6.09529 11.9121 5.95814 11.7681L0.957143 6.51812C0.824176 6.37864 0.75 6.19333 0.75 6.00062C0.75 5.80792 0.824176 5.6226 0.957143 5.48312L5.95714 0.233121C6.09426 0.0889758 6.28302 0.00520407 6.4819 0.000234362C6.68079 -0.00473535 6.8735 0.0695042 7.01764 0.206621C7.16179 0.343738 7.24556 0.532501 7.25053 0.731383C7.2555 0.930265 7.18126 1.12298 7.04414 1.26712L2.53514 6.00112L7.04514 10.7351C7.18207 10.8793 7.25615 11.0719 7.25108 11.2707C7.24602 11.4695 7.16223 11.6571 7.01814 11.7941Z" fill="#737373"/>
    </svg>
  </>
)

export default BackArrow;