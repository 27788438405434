export enum FatalErrors {
  unknown               = "fatalErrorUnknown",
  rma                   = "fatalErrorRma",
  rmaUnassociated       = "fatalErrorRmaUnassociated",
  rmaExpired            = "fatalErrorRmaExpired",
  token                 = "fatalErrorToken",
  returnAlreadyComplete = "fatalErrorReturnAlreadyComplete",
  location              = "fatalErrorLocation",
  timeout               = "fatalErrorTimeout",
  sessionExpired        = "fatalErrorSessionExpired",
  connection            = "fatalErrorConnection",
  pageNotFound          = "fatalErrorPageNotFound",
  rmaPartialRefund      = "fatalErrorRmaPartialRefund",
  rmaExpiredReturn      = "fatalErrorRmaExpiredReturn",
  inactiveLocation      = "fatalErrorInactiveLocation",
  returnBarIneligible   = "fatalErrorReturnBarIneligible",
}

export type FatalError = FatalErrors | "";

export enum Alerts {
  bagAlreadyScanned  = "bagAlreadyScanned",
  barcodeAlreadyUsed = "barcodeAlreadyUsed",
  bagAlreadyUsed     = "bagAlreadyUsed",
  fatalError         = "fatalError",
  invalidBagBarcode  = "invalidBagBarcode",
  invalidRMA         = "invalidRMA",
  invalidToteBarcode = "invalidToteBarcode",
  locationNotAllowed = "locationNotAllowed",
  returnComplete     = "returnAlreadyComplete",
  returnIncomplete   = "returnIncomplete",
  userInactive       = "userInactive",
  partialRefundRMA   = "partialRefundRMA",
  expiredReturnRMA   = "expiredReturnRMA",
  returnBarIneligible = "returnBarIneligible",
}
