// third-party imports
import React from "react";

// repo imports
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";
import { ReturnPortalBase } from "../../components/ReturnPortalBase";

import getTranslator from "../../utility/getTranslator";

import BlockedMessage from "../../components/BlockedMessage";

const useTranslation = getTranslator("ReturnExpired")


/**
 * Return Expired Information page
 */
const ExpiredReturn = () => {
  const { customer } = useSelector((store: RootReducer) => store);
  const { t } = useTranslation();
  const { returns } = customer;
  // RENDERING
  return (
    <ReturnPortalBase >
      <BlockedMessage blockedErrorIcon={'error'} expressCode={returns?.confirmationCode} errorMessage={t('cannotChangeDropoff')} subMessage={t('startNewReturnText')} />
    </ReturnPortalBase>
  );
}

export default ExpiredReturn;
