import React from "react";

const ReturnistaCircleCheck = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.06659 7.99167L4.61659 6.54167C4.49436 6.41944 4.34159 6.36111 4.15825 6.36667C3.97492 6.37222 3.82214 6.43611 3.69992 6.55833C3.5777 6.68056 3.51659 6.83611 3.51659 7.025C3.51659 7.21389 3.5777 7.36944 3.69992 7.49167L5.59992 9.39167C5.72214 9.51389 5.8777 9.575 6.06659 9.575C6.25547 9.575 6.41103 9.51389 6.53325 9.39167L10.3166 5.60833C10.4388 5.48611 10.4971 5.33333 10.4916 5.15C10.486 4.96667 10.4221 4.81389 10.2999 4.69167C10.1777 4.56944 10.0221 4.50833 9.83325 4.50833C9.64436 4.50833 9.48881 4.56944 9.36659 4.69167L6.06659 7.99167ZM6.99992 13.4583C6.0777 13.4583 5.21103 13.2833 4.39992 12.9333C3.58881 12.5833 2.88325 12.1083 2.28325 11.5083C1.68325 10.9083 1.20825 10.2028 0.858252 9.39167C0.508252 8.58056 0.333252 7.71389 0.333252 6.79167C0.333252 5.86944 0.508252 5.00278 0.858252 4.19167C1.20825 3.38056 1.68325 2.675 2.28325 2.075C2.88325 1.475 3.58881 1 4.39992 0.65C5.21103 0.3 6.0777 0.125 6.99992 0.125C7.92214 0.125 8.78881 0.3 9.59992 0.65C10.411 1 11.1166 1.475 11.7166 2.075C12.3166 2.675 12.7916 3.38056 13.1416 4.19167C13.4916 5.00278 13.6666 5.86944 13.6666 6.79167C13.6666 7.71389 13.4916 8.58056 13.1416 9.39167C12.7916 10.2028 12.3166 10.9083 11.7166 11.5083C11.1166 12.1083 10.411 12.5833 9.59992 12.9333C8.78881 13.2833 7.92214 13.4583 6.99992 13.4583ZM6.99992 12.125C8.4777 12.125 9.73603 11.6056 10.7749 10.5667C11.8138 9.52778 12.3333 8.26944 12.3333 6.79167C12.3333 5.31389 11.8138 4.05556 10.7749 3.01667C9.73603 1.97778 8.4777 1.45833 6.99992 1.45833C5.52214 1.45833 4.26381 1.97778 3.22492 3.01667C2.18603 4.05556 1.66659 5.31389 1.66659 6.79167C1.66659 8.26944 2.18603 9.52778 3.22492 10.5667C4.26381 11.6056 5.52214 12.125 6.99992 12.125Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default ReturnistaCircleCheck;
