import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { $TypeBagBarcode }  from './styles';

import useSelector from '../../utility/useTypedSelector';
import { addReturnBagBarcode } from '../../redux/customer/actions';
import { PageProps } from "../types";
import { ErrorAlertMessage } from '../../components/AlertMessage';
import SearchBar from '../../components/SearchField';
import { goToPage, setLocalErrorMessage } from '../../redux/app/actions';
import { validateBagBarcode, removeSpecialCharacter } from '../../utility';
import { DataCyStrings } from '../../types/DataCyStrings';

export const TypeBagBarcodeInvalidCodeErrorMessage =
  "This code is not associated to Happy Returns. Please try another code. If the problem persists, scan another bag to continue.";

const TypeBagBarcode = ({ page }: PageProps) => {
  const dispatch = useDispatch();
  const { customer, app } = useSelector(store => store);

  const { barcodes } = customer;
  const { localErrorMessage, runtime, currentPageName, loadingMessage, token } = app;

  const [query, setQuery] = useState("");

  // HELPER FUNCTIONS ////////////////////////////////////////
  const handleSubmit = () => {
    if (loadingMessage) {
      return;
    }
    if (validateBagBarcode(query)) {
      if (barcodes.find(x => x === query)) {
        dispatch(setLocalErrorMessage("This bag is already associated with this return. Please use a different bag."))
        return;
      }
      dispatch(addReturnBagBarcode(query, runtime, currentPageName));
    } else {
      dispatch(setLocalErrorMessage(TypeBagBarcodeInvalidCodeErrorMessage));
    }
  }
  ////////////////////////////////////////////////////////////

  // HOOKS ///////////////////////////////////////////////////
  useEffect(() => {
    dispatch(setLocalErrorMessage(""));

    window.goBack = () => {
      dispatch(goToPage("confirmation"));
    }
  }, [])

  ////////////////////////////////////////////////////////////
  return (
    <$TypeBagBarcode>
      <SearchBar
        value={query}
        onChange={(val) => {
          dispatch(setLocalErrorMessage(""));
          let queryToUpperCase = removeSpecialCharacter(val.toUpperCase());

          setQuery(queryToUpperCase)
        }}
        onClear={() => { dispatch(setLocalErrorMessage("")); setQuery(""); }}
        onSubmit={handleSubmit}
        placeholder="Enter barcode/bag ID number"
        inputDataCyString={DataCyStrings.scanBagCodeInput}
      />
      {localErrorMessage != "" &&
        <div className="error-message-container">
          <ErrorAlertMessage message={localErrorMessage} />
        </div>
      }
    </$TypeBagBarcode>
  )
}

export default TypeBagBarcode;
