import React, { MutableRefObject } from "react";
import { DataCyStrings } from "../../types/DataCyStrings";
import { hideOnSmallBrowserWidthClass } from "../../globalConstants";
import SVGButton from "../SVGButton";
import $CounterInput from "./styles";

interface CounterInputProps {
  value: number;
  id: string;
  onSubmit: (e: any) => void;
  setInputValue: (val: number) => void;
  increment: () => void;
  decrement: () => void;
  resetErrorState: () => void;
  inputRef?: MutableRefObject<any>;
  dataCy?: DataCyStrings;
  disabled?: boolean;
  item: string; // items being counted (ie items, labels, totes, etc.)
  buttonText: string;
  submitDataCy?: string;
  subtext?: string;
  separateButton?: boolean;
  disableDecrement?: boolean;
  disableIncrement?: boolean;
  maximum?: number;
  minimum?: number;
}

const CounterInput = ({
  value,
  id,
  onSubmit,
  setInputValue,
  increment,
  decrement,
  resetErrorState,
  inputRef,
  dataCy,
  disabled,
  item,
  buttonText,
  submitDataCy,
  subtext,
  separateButton,
  disableDecrement,
  disableIncrement,
  minimum = 0,
  maximum = 999,
}: CounterInputProps) => {
  const handleNumberInputKeyDown = (e: React.KeyboardEvent) => {
    const isValidNumber = "0" <= e.key && e.key <= "9";
    const isValidKey = () => {
      if (isValidNumber) return true;
      switch (e.key) {
        case "Backspace":
        case "Enter":
        case "Left":
        case "ArrowLeft":
        case "Right":
        case "ArrowRight":
        case "Tab":
          return true;
        default:
          return false;
      }
    };

    if (!isValidKey()) {
      e.preventDefault();
    }
  };

  const handleNumberIncrementOnClick = () => {
    if (!disableIncrement) {
      resetErrorState();
      inputRef?.current?.blur?.();
      increment();
    }
  };

  const handleNumberDecrementOnClick = () => {
    if (!disableDecrement) {
      resetErrorState();
      inputRef?.current?.blur?.();
      decrement();
    }
  };

  const handleNumberInputOnChange = (e) => {
    // don't fire state change if number is negative
    if (parseInt(e.target.value) < 0) return;
    resetErrorState();
    // parse the value string to an integer, setting it to 0 if parseInt fails ("" evaluates to NaN)
    const val = parseInt(e.target.value) || 0;
    e.target.value = val.toString();
    setInputValue(val);
  };

  // selects the entire input field when the element is clicked/focused
  const handleInputFocus = (e) => e.target.select();

  return (
    <$CounterInput separateButton={separateButton}>
      <main>
        <div className="counter-container">
          <div className="flex-wrapper">
            <div className={hideOnSmallBrowserWidthClass}>
              <SVGButton
                svg="decrement"
                label={`decrease ${item} count`}
                onClick={handleNumberDecrementOnClick}
                disabled={disableDecrement || value <= minimum}
                dataCyString={DataCyStrings.decrementButton}
              />
            </div>
            <form onSubmit={onSubmit} id={id}>
              <label htmlFor="counter">counter</label>
              <input
                ref={inputRef}
                id="counter"
                data-cy={dataCy}
                value={value}
                type="number"
                step={1}
                onKeyDown={handleNumberInputKeyDown}
                onChange={handleNumberInputOnChange}
                onFocus={handleInputFocus}
              />
              {!separateButton && (
                <button
                  className="confirm-items-counted"
                  type="submit"
                  data-cy={submitDataCy}
                  form={id}
                  disabled={disabled}
                >
                  {buttonText}
                </button>
              )}
            </form>
            <div className={hideOnSmallBrowserWidthClass}>
              <SVGButton
                svg="increment"
                label={`increase ${item} count`}
                onClick={handleNumberIncrementOnClick}
                disabled={disableIncrement || value >= maximum}
                dataCyString={DataCyStrings.incrementButton}
              />
            </div>
          </div>
        </div>
        {separateButton && (
          <>
            <div className="subtext">{subtext}</div>
            <button
              className="confirm-items-counted separate"
              type="submit"
              data-cy={submitDataCy}
              form={id}
              disabled={disabled}
            >
              {buttonText}
            </button>
          </>
        )}
      </main>
    </$CounterInput>
  );
};

export default CounterInput;
