import styled, { css } from 'styled-components';
import { I$ButtonProps } from './types';

const $Button = styled.button<I$ButtonProps>`
  padding-top: 0;
  padding-bottom: 0;
  height: ${({ height }) => (height ? height : "44px")};
  width: ${({ width }) => (width ? width : "117px")};
  max-width: 100%;
  cursor: pointer;
  text-align: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-family: "sofia-pro", sans-serif;
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : "500")};
  color: ${({fontColor}) => (fontColor ? fontColor : "#737373")};
  background: ${({bgColor}) => (bgColor ? bgColor : "#F1F1F1")};
  border: ${({border}) => (border ? border : "0")};
  flex-shrink: 0;
  border-radius: ${({borderRadius}) => (borderRadius ? borderRadius : "0")};
  ${(props) =>
    props.isActive &&
    css<I$ButtonProps>`
      background: ${({activeBgColor}) =>
        activeBgColor ? activeBgColor : "#000000"};
      border: 0px;
      color: ${({activeFontColor}) =>
        activeFontColor ? activeFontColor : "#FFFFFF"};
      font-size: ${({activeFontSize}) =>
        activeFontSize ? activeFontSize : "16px"};
      outline: 0;
    `}

    &:active {
      outline: 0;
    }

    &:disabled {
      color: var(--header-grey);
      background-color: var(--disabled-grey);
    }

  ${(props) =>
    props.disabled ?
    css<I$ButtonProps>`
      font-size: ${({disabledFontSize}) =>
        disabledFontSize ? disabledFontSize : "16px"};
      color:  #737373;
      background: #F1F1F1;
      border: 1px solid #F1F1F1;
      cursor: not-allowed;
    `
    :
    css<I$ButtonProps>`&:hover {
      @media (hover: hover) {
        background: ${({hoverBgColor}) =>
          hoverBgColor ? hoverBgColor : "#333333"};
        border: ${({border}) => (border ? border : "0")};
        color: ${({hoverFontColor}) =>
          hoverFontColor ? hoverFontColor : "#FFFFFF"};
        font-size: ${({hoverFontSize}) =>
          hoverFontSize ? hoverFontSize : "16px"};
      }
      `
    }

`;

export {
  $Button,
}

