import { useTranslation} from "react-i18next";

// namespaces are different in i118n-next
// we use prefixes instead to avoid MANY issues
// - it is near impossible to load all namespaces at once from a single http request
// - all namespaces must be specified in the request
// - the backends for multiloading are old and poorly documented/supported
// - much much more
// this prefix solution solves a lot of the above issues

/**
 * gets the useTranslation hook for strings starting with a specific module name
 * @param  {string} prefix - the name of the module, ie. LoginReturnPortal
 */
const getTranslator = (prefix: string) => () => {
    const {t, i18n} = useTranslation()
    return {
        t: (key, ...args) => t(`${prefix}.${key}`, ...args) as string,
        i18n
    }
}
export default getTranslator
