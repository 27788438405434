import { FakeImagesAction, MultiStoreAction } from "../enums";

const fakeImagesReducer = (state = [], action) => {
  switch (action.type) {
    case FakeImagesAction.Add:
      return [...state, action.fakeImage];
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case FakeImagesAction.Unset:
      return [];
    default:
      return state;
  }
};

export default fakeImagesReducer;
