import React from "react";

import { SVG } from "../../components/svg";
import { handleEnterKeyPressOnClickHandlers } from "../../utility";

import $OptionCard from "./styles";

interface OptionCardProps {
  title?: string,
  onClick?: (e?) => void,
  dataCyString?: string
}

const OptionCard = ({ title, onClick, dataCyString }: OptionCardProps) => {
  const optionCardHandlers = handleEnterKeyPressOnClickHandlers((e) => onClick?.(e));
  return (
    <$OptionCard {...optionCardHandlers} data-cy={dataCyString}>
      <div className="title">
        {title}
      </div>
      <div className="arrow">
        <SVG name="arrow"/>
      </div>
    </$OptionCard>
  )
}

export default OptionCard;