import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $Container = styled.div`
  border: 1px solid var(--border-card);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  width: 100%;
  max-width: 534px;

  @media (min-width: ${tabletOrLargerDevices}) {
    padding: 40px 24px;
  }
`;

export const $Title = styled.div`
  text-align: center;
  width: 100%;

  p {
    color: var(--dark-green);
    font: normal 400 21px "sofia-pro";
    line-height: 32px;
    margin: 16px 0 48px;
    text-align: center;
  }

  &:after {
    background-color: var(--medium-grey);
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto;
    width: 80px;
  }
`;

export const $FollowUp = styled.p`
  font-size: 21px;
  margin: 24px auto 16px;
  text-align: center;

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 27px;
    margin: 48px auto 40px;
  }
`;

export const $IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
