import React, { ReactNode, useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { SVG } from "../svg";
import { MOBILE_BREAKPOINT } from "../../globalConstants";
import { Order } from "../../types/Instance";
import getTranslator from "../../utility/getTranslator";
import { useViewport } from "../../utility/useViewport";
import useSelector from "../../utility/useTypedSelector";
import { DataCyStrings } from "../../types/DataCyStrings";

import {
  $DefaultPrivacyFooter,
  $GermanPrivacyFooter,
  $Impressum,
  $PoweredByHappyReturns,
  $PrivacyPolicy,
} from "./styles";
import ga from "../../utility/GAEmitter";
import { AnalyticCategories, CommonPageActions, PageToCategoryMap } from "../../types/Analytics";

// TODO rename translation file to PrivacyFooter, submit new translation job
const useTranslation = getTranslator("BaseFooter");

interface ImpressumProps {
  impressumURL: string;
}


const Impressum = ({ impressumURL }: ImpressumProps): JSX.Element => {
  const { currentPageName } = useSelector((store) => store.app);
  const onImpressumClick = () => {
    //default to Login Page
    let category = PageToCategoryMap.get(currentPageName)
    if (!category) {
      category = AnalyticCategories.LoginPage
    }
    ga.event({
      category: category,
      action: CommonPageActions.Impressum
    })
  };

  return (
    <$Impressum>
      {" | "}
      <a onClick={onImpressumClick} href={impressumURL} target="_blank" rel="noreferrer">
        {"Impressum"}
      </a>
    </$Impressum>
  );
};

interface PoweredByHappyReturnsProps {
  isWhiteLabel?: boolean;
}



const PoweredByHappyReturns = ({ isWhiteLabel }: PoweredByHappyReturnsProps): null | JSX.Element => {
  if (isWhiteLabel) {
    return null;
  }

  const { t } = useTranslation();
  const poweredByText = t("poweredBy", { name: "Happy Returns" });
  const { currentPageName } = useSelector((store) => store.app);
  const onPoweredByHappyReturnsClick = () => {
    //default to Login Page
    let category = PageToCategoryMap.get(currentPageName)
    if (!category) {
      category = AnalyticCategories.LoginPage
    }
    ga.event({
      category: category,
      action: CommonPageActions.PoweredByHappyReturnsLogo
    })
  };
  return (
    <$PoweredByHappyReturns>
      <a onClick={onPoweredByHappyReturnsClick} href="https://happyreturns.com/" target="_blank" rel="noreferrer">
        <SVG name="hr-icon" alt={poweredByText} />
        {poweredByText}
      </a>
    </$PoweredByHappyReturns>
  );
};

interface PrivacyPolicyProps {
  url: string;
  children?: ReactNode;
}

const PrivacyPolicy = ({ url, children }: PrivacyPolicyProps): JSX.Element => {
  const { t } = useTranslation();
  const { width } = useViewport();
  const { currentPageName } = useSelector((store) => store.app);

  const onFooterPrivacyPolicyClick = () => {
    //default to Login Page
    let category = PageToCategoryMap.get(currentPageName)
    if (!category) {
      category = AnalyticCategories.LoginPage
    }
    ga.event({
      category: category,
      action: CommonPageActions.PrivacyPolicyFooter
    })
  };

  //For mobile viewports, we exclude the `Happy Returns’` copy prior to `Privacy Policy` due to screen size constraints.
  if (width < MOBILE_BREAKPOINT) {
    return (
      <$PrivacyPolicy>
        <Trans t={t} i18nKey="privacyPolicy">
          <a target="_blank" onClick={onFooterPrivacyPolicyClick} href={url} rel="noreferrer" >
            Privacy Policy
          </a>
        </Trans>
        {children}
      </$PrivacyPolicy>
    );
  }

  return (
    <$PrivacyPolicy>
      <Trans t={t} i18nKey="happyReturnsPrivacyPolicy">
        Happy Returns’{" "}
        <a target="_blank" onClick={onFooterPrivacyPolicyClick} href={url} rel="noreferrer">
          Privacy Policy
        </a>
      </Trans>
      {children}
    </$PrivacyPolicy>
  );
};

interface DefaultPrivacyFooterProps {
  isWhiteLabel?: boolean;
  privacyPolicyURL: string;
  marginTop?: string;
  dataCyString?: DataCyStrings;
}

const DefaultPrivacyFooter = ({
  isWhiteLabel,
  privacyPolicyURL,
  marginTop = "",
  dataCyString,
}: DefaultPrivacyFooterProps): JSX.Element => {
  return (
    <$DefaultPrivacyFooter marginTop={marginTop} data-cy={dataCyString}>
      <PrivacyPolicy url={privacyPolicyURL} />
      <PoweredByHappyReturns isWhiteLabel={isWhiteLabel} />
    </$DefaultPrivacyFooter>
  );
};

interface GermanPrivacyFooterProps {
  isWhiteLabel?: boolean;
  impressumURL: string;
  privacyPolicyURL: string;
  marginTop?: string;
  dataCyString?: DataCyStrings;
}

// A dedicated footer for German shoppers is required in order to allow for dynamic content positioning for mobile
// viewports.
const GermanPrivacyFooter = ({
  impressumURL,
  isWhiteLabel,
  privacyPolicyURL,
  marginTop = "",
  dataCyString,
}: GermanPrivacyFooterProps): JSX.Element => {
  const { width } = useViewport();

  // For mobile viewports, the `Powered by Happy Returns` element appears semantically before the Privacy Policy and
  // Impressum elements
  if (width < MOBILE_BREAKPOINT) {
    return (
      <$GermanPrivacyFooter marginTop={marginTop} data-cy={dataCyString}>
        <PoweredByHappyReturns isWhiteLabel={isWhiteLabel} />
        <PrivacyPolicy url={privacyPolicyURL}>
          <Impressum impressumURL={impressumURL} />
        </PrivacyPolicy>
      </$GermanPrivacyFooter>
    );
  }

  return (
    <$GermanPrivacyFooter marginTop={marginTop} data-cy={dataCyString}>
      <PrivacyPolicy url={privacyPolicyURL}>
        <Impressum impressumURL={impressumURL} />
      </PrivacyPolicy>
      <PoweredByHappyReturns isWhiteLabel={isWhiteLabel} />
    </$GermanPrivacyFooter>
  );
};

interface PrivacyFooterProps {
  marginTop?: string;
}

export const PrivacyFooter = ({ marginTop = "" }: PrivacyFooterProps): JSX.Element => {
  const { app } = useSelector((store) => store);
  const { isWhiteLabel, links, locale } = app;
  const { customer } = useSelector((store) => store);
  const { orders } = customer;

  const basePrivacyPolicyURL = "https://privacypolicy.happyreturns.com/";
  const [impressumURL, setImpressumURL] = useState("");
  const [privacyPolicyURL, setPrivacyPolicyURL] = useState(basePrivacyPolicyURL);

  const isGerman = (orders: Order[]) => {
    for (let i = 0; i < orders?.length; i++) {
      const order = orders[i];
      for (let j = 0; j < order.purchases?.length; j++) {
        const purchase = order.purchases[j];
        // TODO cleanup types
        const countryIdentifier = purchase?.purchase?.shippingAddress?.country || "";
        if (isGermanCountryIdentifier(countryIdentifier)) {
          return true;
        }
      }
    }

    return false;
  };

  const isGermanCountryIdentifier = (countryIdentifier: string) => {
    if (!countryIdentifier) return false;
    const germanCountryIdentifiers = ["germany", "de", "deutschland"];
    return germanCountryIdentifiers.includes(countryIdentifier.toLowerCase());
  };

  const getImpressumURL = (links) => {
    let impressumURL = "";

    if (!impressumURL) {
      if (links?.homeURL) {
        impressumURL = links.homeURL;
      }
      if (links?.impressumURL) {
        impressumURL = links.impressumURL;
      }
    }

    return impressumURL;
  };

  useEffect(() => {
    setPrivacyPolicyURL(`${basePrivacyPolicyURL}${locale?.toLowerCase()}`);
    if (locale != "de-DE") {
      setImpressumURL("");
    }
    // avoid an unnecessary function invocation if `impressumURL` is already set for German users
    if (locale == "de-DE" && impressumURL == "") {
      setImpressumURL(getImpressumURL(links));
    }
  }, [locale]);

  useEffect(() => {
    if (isGerman(orders) && impressumURL == "") {
      setImpressumURL(getImpressumURL(links));
    }
  }, [orders]);

  if (impressumURL) {
    return (
      <GermanPrivacyFooter
        impressumURL={impressumURL}
        isWhiteLabel={isWhiteLabel}
        privacyPolicyURL={privacyPolicyURL}
        marginTop={marginTop}
        dataCyString={DataCyStrings.privacyPolicyFooterGerman}
      />
    );
  }

  return <DefaultPrivacyFooter isWhiteLabel={isWhiteLabel} privacyPolicyURL={privacyPolicyURL} marginTop={marginTop} dataCyString={DataCyStrings.privacyPolicyFooter} />;
};
