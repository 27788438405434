
import React from 'react';

/** paper airplane */
const Suggestion = (props) => {
  return (
    <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.2432 4.35026L22.6626 3.92431L3.83391 10.9759L11.4615 15.0121L22.2432 4.35026ZM10.7966 16.923L0.932566 11.7034C0.584644 11.5193 0.377092 11.1482 0.402337 10.7554C0.427582 10.3626 0.680927 10.0211 1.04955 9.88306L26.2006 0.463617C26.5852 0.319606 27.0121 0.426503 27.2839 0.719233C27.5715 0.988767 27.6781 1.40915 27.5395 1.79037L18.3936 26.9418C18.2604 27.3081 17.9264 27.5637 17.538 27.5965C17.1496 27.6293 16.7775 27.4334 16.5847 27.0946L10.7966 16.923ZM12.9413 16.6482L17.2532 24.2255L24.1396 5.28775L23.659 5.76302L12.9413 16.6482Z" fill="#737373"/>
    </svg>
  )
}

export default Suggestion;