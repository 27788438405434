import React from "react";

const Return = (props) => (
  <>
    <svg {...props} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1111_2583)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25914 0.349216C5.61857 0.768537 5.57002 1.39984 5.1507 1.75927L3.7032 3.00001H12.4969C16.4755 3.00001 19.8393 6.23448 19.9944 10.211C20.1583 14.4144 16.6997 18 12.4969 18H3.9992C3.44691 18 2.9992 17.5523 2.9992 17C2.9992 16.4477 3.44691 16 3.9992 16H12.4969C15.5674 16 18.1155 13.3552 17.9959 10.289C17.8827 7.38595 15.4013 5.00001 12.4969 5.00001H3.41417L5.20701 6.79291C5.59753 7.18344 5.59752 7.81661 5.20699 8.20713C4.81646 8.59765 4.1833 8.59764 3.79278 8.20711L0.292884 4.70711C0.0959301 4.51015 -0.00995181 4.23997 0.000737632 3.96164C0.011427 3.6833 0.137719 3.42204 0.349199 3.24077L3.84909 0.240771C4.26842 -0.118657 4.89972 -0.0701047 5.25914 0.349216Z"
          fill="#737373"
        />
      </g>
      <defs>
        <clipPath id="clip0_1111_2583">
          <rect width="20" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default Return;
