import styled, { css } from "styled-components";
import $InstanceListBase from "../../components/InstanceListBase/styles";


const $OrderListStyles = css`
  @media screen and (max-width: 768px){
    margin: 0;
  }
  margin: 24px 0;
  .order-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    .retailer-name, .order-number {
      font-weight: bold;
      color: #221F1F;
    }
    .express-code, .order-date {
      font-weight: 500;
      color: #737373;
      font-size: 14px;
    }
  }
  .order-list-header {
    display: flex;
    flex-direction: column;
    padding: 8px 25px;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid var(--border-grey);
    .description {
      font-size: 12px;
      font-weight: bold;
      color: var(--header-grey);
      margin: 8px 0 4px 0;
    }
    .query {
      font-size: 18px;
      font-weight: bold;
      color: var(--text-black);
      margin: 4px 0 8px 0;
    }
  }
    .orders {
      margin-bottom: auto;
      width: 100%;
      overflow: auto;
      .order {
        margin-bottom: 32px;
        flex-basis: 100%;
        &.first {
          margin-top: 24px;
        }
      }
      .list {
        flex-shrink: 1;
      }
      .order:first-child() {
        margin-top: 24px;
      }
    }
  .footer {
    width: 100%;
    & > div {
      @media screen and (max-width: 768px){
        &.ios {
          height: 88px;
          position: absolute;
          bottom: 0;
          left: 0;
          box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.16);
          margin-bottom: 0px;
        }
      }
    }
    button {
      height: 56px;
    }
  }
`;

const $OrderList = styled($InstanceListBase as any)`
  ${$OrderListStyles}
  .reminder {
    margin: 24px 0;
  }
`;

export const $OrderListReturnPortal = styled.div`
  ${$OrderListStyles}
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  box-shadow: none;
  .order {
    border: none;
    box-shadow: none;
  }
  .orders {
    margin-top: 12px;
    padding: 0;
    box-shadow: none;
  }
  .order-info {
    font-size: 14px;
    line-height: 22px;
  }
  width: 100%;
`;

export default $OrderList