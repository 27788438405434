import styled from "styled-components";
import { ReturnistaFeedbackIconProps } from "./types";
import { tabletOrLargerDevices } from "../../globalConstants";

type IconWrapperProps = Pick<ReturnistaFeedbackIconProps, "status">;

const colors = {
  success: " var(--success-soft) ",
  error: "var(--warning-soft)",
  warning: "var(--soft-yellow)",
  info: "var(--info-background)"
};

export const $IconWrapper = styled.div<IconWrapperProps>`
  align-items: center;
  background: ${({ status }) => colors[status]};
  border-radius: 72px;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;

  svg {
    height: 32px;
    width: 32px;

    @media (min-width: ${tabletOrLargerDevices}) {
      height: auto;
      width: auto;
    }
  }

  @media (min-width: ${tabletOrLargerDevices}) {
    height: 72px;
    width: 72px;
  }
`;
