// third-party imports
import React from "react";

// repo imports
import { PageProps } from "../types";
import { FinalError } from "../../components/Final";
import { ReturnPortalBase} from "../../components/ReturnPortalBase";
import { $UninstalledInner } from "./styles";
import { PageLifecycle } from "..";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../redux/reducers";

class UninstalledLifecycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

/**
 * page used when retailer.uninstalled_at is set
 */
const Uninstalled = ({ page, page: {title, subtitle} }: PageProps) => {
  const dispatch = useDispatch();

  const { app } = useSelector((store: RootReducer) => store);

  const lifecycle = new UninstalledLifecycle(page, dispatch, app);
  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <ReturnPortalBase shrinkToFit={true}>
      <$UninstalledInner>
        <FinalError 
          primaryMessage={title}
          subMessages={[subtitle]}
        />
      </$UninstalledInner>
    </ReturnPortalBase>
  );
  //----------------------------------------------------------------------------
}

export default Uninstalled;