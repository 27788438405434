import styled from "styled-components";

// language=SCSS prefix=*{ suffix=}
const $RadioCard = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: -6px;

  &:focus {
    .option {
      &.focused {
        outline: 2px solid var(--neutral-grey);
        outline-offset: 2px;
      }
    }
  }

  .option {
    display: flex;
    align-items: center;
    height: auto;
    padding: 8px 16px;
    border: 1px solid var(--border-card);
    box-sizing: border-box;
    border-radius: 12px;
    margin: 6px;
    cursor: pointer;
    user-select: none;

    &.selected {
      border: 2px solid var(--text-black);
    }

    &.invalid {
      color: #B4B0AC;
      background: var(--disabled-grey);
      position: relative;
    }

    &.thumbnail {
      width: 72px;
      height: 72px;
      position: relative;
      padding: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        /* IE11 polyfill */
        font-family: 'object-fit: contain;';
      }

      &.invalid {

        img {
          opacity: 30%;
        }
      }

    }

  }

  &.small {

    .option {
      width: 52px;
      height: 52px;
    }

  }

  &.center {
    justify-content: center;
  }
`;

export default $RadioCard;
