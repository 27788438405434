import { Reducer } from "react";
import { MultiStoreAction } from "../enums";
import { omit } from "lodash";

export type ReducerState = {
  byId: {
    [key: string]: {
      id: string;
      items: string[];
    };
  };
  allIds: string[];
};

type Action = {
  type: string;
  item: any;
};

const itemsReducer: Reducer<ReducerState, Action> = (state = { byId: {}, allIds: [] }, action) => {
  switch (action.type) {
    case MultiStoreAction.AddItem:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.item.data.id]: {
            ...state.byId[action.item.data.id],
            ...action.item,
            timestamp: Date.now()
          },
        },
        allIds: [...state.allIds, action.item.data.id],
      };
    case MultiStoreAction.RemoveItem:
      const byId = omit(state.byId, action.item.data.id);
      return {
        ...state,
        byId,
        allIds: state.allIds.filter((i) => i !== action.item.data.id),
      };
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
      return { byId: {}, allIds: [] };
    default:
      return state;
  }
};

export default itemsReducer;
