import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $LoginBackground = styled.div`
  align-items: center;
  background-color: var(--hr-yellow);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
`;

export const $LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.16);
  background-color: var(--primary-white);

  @media (min-width: ${tabletOrLargerDevices}) {
    margin-top: 72px;
    width: 456px;
  }

  & .error-banner {
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background-color: var(--warning-soft);
    line-height: 20px;
    margin-bottom: 12px;
  }

  & .icon,
  & .icon > svg {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  & .icon > svg > path {
    fill: var(--warning-bold);
  }

  & .error-text {
    font-weight: 500;
    font-size: 13px;
    color: var(--warning-bold);
  }

  & > form input {
    margin-bottom: 24px;
  }

  & > form > input[type="submit"] {
    width: 100%;
    height: 44px;
    background-color: var(--high-emphasis-black);
    color: var(--primary-white);
    border: none;
    border-radius: 12px;
    margin-bottom: 0;
    font-size: 16px;
    font-family: "sofia-pro", sans-serif;
    cursor: pointer;
  }

  & > form > input[type="submit"]:disabled {
    background-color: var(--disabled-grey);
    color: var(--header-grey);
    cursor: default;
  }

  .sso-link {
    margin: auto;
    width: 75%;
    padding: 32px 0;
  }
`;

export const $LoginLogo = styled.div`
  width: 200px;
  height: 40px;
  margin: 0 auto 12px auto;
  object-fit: contain;

  & > svg {
    width: 200px;
    height: 40px;
    object-fit: contain;
  }
`;
