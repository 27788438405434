import { Reducer } from "react";
import { LocationObjectAction } from "../enums";

export type LocationObjectState = {
  id: string;
  owner?: {
    id: number,
    name: string,
    promoImageURL: string,
    couponEnabled: boolean,
    promoText: string,
  }
}

type LocationObjectActionType = {
  type: LocationObjectAction.Set | LocationObjectAction.Unset;
  location: LocationObjectState;
}

const locationReducer: Reducer<LocationObjectState, LocationObjectActionType> = (
  state = { id: "" }, action
) => {
  switch (action.type) {
    case LocationObjectAction.Set:
      return action.location;
    case LocationObjectAction.Unset:
      return { id: "" };
    default:
      return state;
  }
};

export default locationReducer;
