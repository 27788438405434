import React from "react";
const Up = (props) => (
  <>
    <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.944466 5.1128C0.848526 5.01197 0.79655 4.87717 0.799963 4.73803C0.803376 4.59889 0.861898 4.4668 0.962666 4.3708L4.63767 0.870098C4.7353 0.777021 4.86502 0.725098 4.99992 0.725098C5.13481 0.725098 5.26453 0.777021 5.36217 0.870098L9.03717 4.3701C9.13807 4.46608 9.19671 4.59821 9.20019 4.73743C9.20366 4.87665 9.1517 5.01155 9.05572 5.11245C8.95973 5.21335 8.8276 5.27199 8.68838 5.27547C8.54916 5.27895 8.41427 5.22698 8.31337 5.131L4.99957 1.9747L1.68577 5.1317C1.58485 5.22755 1.44999 5.2794 1.31085 5.27586C1.17171 5.27231 1.04038 5.21366 0.944466 5.1128Z" fill="#737373"/>
    </svg>
  </>
);

export default Up;
