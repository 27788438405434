import styled from "styled-components";
import { $Background, $Container } from "../../components/LoadingIndicator/styles";

const centerContent = `
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const $ShippingLabelSuccessPage = styled.div`
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  color: var(--text-black);
  .success-icon {
    ${centerContent}
    min-height: 56px;
    width: 56px;
    background-color: var(--success-soft);
    border-radius: 50%;
    margin-bottom: 24px;
  }
  h1 {
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    margin: 0;
  }
  h2 {
    font-size: 20px;
    font-weight: 200;
    line-height: 24px;
    color: var(--medium-emphasis-black);
    margin: 8px 0 0;
  }
  .pickup-container {
    ${centerContent}
    width: 100%;
    background-color: #ffee7f;
    padding: 16px 24px;
    line-height: 24px;
    border-radius: 12px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin: 24px;
  }
  .instructions-container {
    border: 1px solid var(--border-card);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 32px;
  }
  .instruction {
    display: flex;
    align-items: center;
    margin: 24px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 32px;
    }
  }
  .instruction-icon {
    ${centerContent}
    height: 48px;
    min-width: 48px;
    border-radius: 50%;
    background-color: var(--background-white);
    margin-right: 32px;
  }
  .disclaimer {
    background: var(--background-white);
    border-radius: 8px;
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
  }
  .troubleshooting {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
  }
  .download-button {
    width: 100%;
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 12px;
    border: 1px solid var(--border-card);
    background-color: transparent;
    font-family: inherit;
    color: var(--text-black);
    margin: 8px 0 32px;
    cursor: pointer;
  }
  .support-link {
    color: var(--text-black);
  }

  // override loading indicator styles
  .MuiCircularProgress-colorPrimary {
    color: var(--high-emphasis-black) !important;
  }
  ${$Background} {
    background-color: transparent;
    justify-content: unset;
    margin-top: 192px !important;
  }
  ${$Container} {
    color: var(--high-emphasis-black);
    font-weight: 400;
  }
`;

export default $ShippingLabelSuccessPage;
