import { Reducer } from "react";
import { bagsAction, MultiStoreAction } from "../enums";
import { omit } from "lodash";

export type ReducerState = {
  byId: {
    [key: string]: {
      id: string;
      items: string[];
      timestamp: number
    };
  };
  allIds: string[];
  labels?: string[]
};

type Action = {
  type: string;
  bag: string;
  item: any;
  labels: string[]
};

const bagsReducer: Reducer<ReducerState, Action> = (state = { byId: {}, allIds: [], labels: [] }, action) => {
  switch (action.type) {
    case bagsAction.Remove:
      const byId = omit(state.byId, action.bag);
      return {
        byId,
        allIds: state.allIds.filter((id) => id !== action.bag),
      };
    case bagsAction.GenerateLabels:
      return {
        ...state,
        labels: action.labels,
      }
    case MultiStoreAction.AddItem:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.bag]: {
            ...state.byId[action.bag],
            items: [...(state.byId?.[action.bag]?.items ?? []), action.item.data.id],
            timestamp: Date.now(),
          },
        },
        allIds: state.allIds.includes(action.bag) ? state.allIds : [...state.allIds, action.bag],
      };
    case MultiStoreAction.RemoveItem:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.bag]: {
            ...state.byId[action.bag],
            items: state.byId[action.bag].items.filter((i: any) => {
              return i !== action.item.data.id;
            }),
          },
        },
      };
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
      return { byId: {}, allIds: [], labels: [] };
    default:
      return state;
  }
};

export default bagsReducer;
