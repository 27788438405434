import { InvalidItemCode } from "../enums";

const invalidItemCodesReducer = (state = [], action) => {
  switch (action.type) {
    case InvalidItemCode.Add:
      return state.concat(action.itemCode);
    default:
      return state;
  }
};

export default invalidItemCodesReducer;
