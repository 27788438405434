import React from "react";

const Decrement = (props) => (
  <>
  <svg {...props} width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="6" width="6" height="24" rx="3" transform="rotate(-90 0 6)" fill="#221F1F"/>
  </svg>
  </>
);

export default Decrement;
