import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  CurrentPageAction,
  FakeImagesAction,
  FatalErrorAction,
  LoadingAction,
  ReturnObjectAction,
  RuntimeAction,
  RuntimeType,
  TerminalReason,
  UserTokenAction,
} from "../../redux/enums";
import axios from "axios";
import * as messages from "../../messages";
import {
  filterReturnedItems,
  getReturnistaJWTClaims,
  isReturnLoop,
  isReturnistaApp,
  isUPS,
  mapURLQueryStringToObject,
} from "../../utility";
import ReturnistaTimer from "../../utility/ReturnistaTimer";
import { BackendErrorPrefixes } from "../../types/API";
import ReturnistaImageManager from "../../utility/ReturnistaImageManager";
import ReturnistaAnalytics, { ReturnistaAnalyticsErrors } from "../../utility/ReturnistaAnalytics";
import { store } from "../../redux/returnista-store";
import ga from "../../utility/GAEmitter";
import { LoginActions } from "../../types/ReturnistaGA";

const ReturnistaInitializer = () => {
  const dispatch = useDispatch();
  const { currentPage } = store.getState();
  let token = Cookies.get("token");
  if (token == null) {
    const { authorization } = mapURLQueryStringToObject();
    token = authorization;
  }

  const errorMessage = Cookies.get("errorMessage");

  // ComponentDidMount
  useEffect(() => {
    // If we are in a non-partner environment we can proceed to the Login page
    if (isReturnistaApp(window.location.hostname)) {
      dispatch({ type: CurrentPageAction.GoToLogin });
      return;
    }

    // If we are in a partner environment we can get the return object and go
    // to the bag scan page immediately
    if (token != null) {
      dispatch({ type: LoadingAction.Set });
      dispatch({ type: RuntimeAction.Set, runtime: RuntimeType.Partner });
      dispatch({ type: UserTokenAction.Set, token });
      ReturnistaTimer.init(token).then(() => {
        const { currentPage } = store.getState();
        if (isReturnLoop(currentPage)) {
          ReturnistaAnalytics.collectError(ReturnistaAnalyticsErrors.UnexpectedTimeout);
        }
        displayError(TerminalReason.SessionTimeout);
      });
      axios
        .get("/returns")
        .then((response) => {
          dispatch({ type: LoadingAction.Unset });
          const { data: returnObject } = response;
          const retailerId = returnObject?.retailerID ?? returnObject?.retailer?.id;
          if (returnObject.id === "" || retailerId == null) {
            displayError(TerminalReason.NotAssociated);
            return;
          }
          const filteredReturning = filterReturnedItems(returnObject?.returning ?? []);
          if (filteredReturning.length === 0) {
            displayError(TerminalReason.AlreadyComplete);
            return;
          }
          returnObject.returning = filteredReturning;

          ReturnistaImageManager.loadImages(filteredReturning, retailerId, returnObject.confirmationCode).then(
            (fakeImages) => {
              if (fakeImages.length === 0) {
                ReturnistaAnalytics.fakeImagesNotLoaded();
                return;
              }
              ReturnistaAnalytics.fakeImagesLoaded(fakeImages.length);      
              fakeImages.forEach((image) => dispatch({ type: FakeImagesAction.Add, fakeImage: image }));
            }
          );

          // Add an entry to the history state list; this is what we will
          // leverage to capture browser back events
          history.pushState(null, "");
          // Start recording
          ReturnistaAnalytics.startReturn();
          ReturnistaAnalytics.setReturnObject(returnObject);
          ReturnistaAnalytics.setLocationIdFromToken(token);
          const { returnsApp } = getReturnistaJWTClaims(token);
          ga.setDimensions({
            user_properties: {
              location_id: returnsApp?.locationID,
              location_owner: returnsApp?.locationOwner,
            },
          });
          ga.pageEvent({ category: currentPage, action: LoginActions.LoginSuccessful });
          // Set the return object and go to the bag scan page
          dispatch({ type: LoadingAction.Unset });
          dispatch({ type: ReturnObjectAction.Set, returnObject });
          if (isUPS()) {
            dispatch({ type: CurrentPageAction.GoToBagCodeComplete });
          } else {
            dispatch({ type: CurrentPageAction.GoToBagScan });
          }
        })
        .catch((err) => {
          ga.pageEvent({ category: currentPage, action: LoginActions.LoginFailed });
          dispatch({ type: LoadingAction.Unset });
          if (err.response.status === messages.inactiveLocationStatusCode) {
            displayError(TerminalReason.InactiveLocation);
            return;
          }
          if (err.response.status === messages.partialRefundUnacceptableStatusCode) {
            displayError(TerminalReason.RmaPartialRefund);
            return;
          }
          if (err?.response?.data.includes(BackendErrorPrefixes.rmaExpiredReturn)) {
            displayError(TerminalReason.RmaExpiredReturn);
            return;
          }
          if (err.response?.data.includes(BackendErrorPrefixes.rmaInvalid)) {
            displayError(TerminalReason.RmaInvalid);
            return;
          }
          if (err?.response?.status === messages.returnBarDropoffIneligibleCode) {
            displayError(TerminalReason.ReturnBarIneligible);
            return;
          }
          displayError(TerminalReason.TemporarilyUnavailable);
        });
      return;
    }

    if (errorMessage) {
      displayError(TerminalReason.TemporarilyUnavailable);
      return;
    }

    displayError(TerminalReason.GenericError);
  }, []);

  const displayError = (reason: TerminalReason) => {
    dispatch({ type: FatalErrorAction.Set, reason });
    dispatch({ type: CurrentPageAction.FatalError });
  };

  return null;
};

export default ReturnistaInitializer;
