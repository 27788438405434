import React from "react";

const Tote = (props) => (
  <>
    <svg {...props} width="47.82" height="50" viewBox="0 0 49 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.16594 12.79H48.1759H1.16594ZM24.6659 12.79V1V12.79ZM47.4459 9.73C47.9259 11.06 48.1959 12.45 48.2359 13.86V48.16C48.2359 49.34 47.2759 50.3 46.0959 50.3H3.22595C2.04595 50.3 1.08594 49.34 1.08594 48.16V13.86C1.13594 12.45 1.39594 11.05 1.88594 9.73L4.58594 2.99C5.11594 1.83 6.25594 1.07 7.52594 1H41.8259C43.0959 1.07 44.2259 1.83 44.7659 2.99L47.4659 9.73H47.4459Z"
        fill="white"
        stroke="#221F1F"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.396 39.0403C22.006 39.0803 19.506 36.7903 19.466 36.7503C19.216 36.5103 19.186 36.1003 19.416 35.8303C19.646 35.5603 20.036 35.5403 20.286 35.7803C20.486 35.9603 25.166 40.2203 30.176 35.6403C30.436 35.4003 30.826 35.4303 31.046 35.7103C31.266 35.9803 31.236 36.3903 30.986 36.6303C29.036 38.4103 27.106 39.0203 25.406 39.0403H25.396Z"
        fill="#221F1F"
      />
      <path
        d="M19.9756 24.1098L21.2356 24.0898L21.2756 27.7398C21.6056 27.1298 22.2256 26.7898 23.0156 26.7798C23.8056 26.7698 24.3356 27.0598 24.6356 27.5298C24.9056 27.9498 24.9956 28.4298 24.9956 29.2098L25.0356 32.5898L23.7756 32.6098L23.7456 29.6698C23.7356 28.6898 23.4656 27.9498 22.5656 27.9598C21.6656 27.9698 21.2756 28.7198 21.2956 29.6998L21.3256 32.6398L20.0656 32.6598L19.9756 24.1198V24.1098Z"
        fill="#221F1F"
      />
      <path
        d="M29.3257 29.1698C29.9757 29.1698 30.4957 28.5998 30.4957 27.9098C30.4957 27.2198 29.9557 26.6698 29.3057 26.6798C28.6557 26.6898 28.1357 27.2498 28.1357 27.9398C28.1357 28.6298 28.6757 29.1798 29.3257 29.1698Z"
        fill="#221F1F"
      />
    </svg>
  </>
);

export default Tote;
