import styled from "styled-components";
import $PageContentBox from "../../components/PageContentBox/styles";

const $ThankTheCustomerScanTote = styled($PageContentBox)`
  --bold: 500; // normal bold (700) is TOO bold

  font-family: "sofia-pro", sans-serif;
  font-weight: 100;
  line-height: 1.5rem;
  padding: 0 24px;
`;

export const $Card = styled.div`
  border: 1px solid var(--border-card);
  border-radius: 12px;
  margin: 24px 0;
  padding: 24px;
`;

export const $CardHeader = styled.div`
  display: flex;
  margin-bottom: 18px;

  svg {
    width: 32px;
    height: 32px;
  }

  div {
    align-self: center;
    // align-self: center should vertically center the text, but something funky happens when the font-* rules from
    // Figma are added
    // TODO re-evaluate rules during/after completion of RF-425
    margin-top: 5.5px;
    margin-left: 16px;

    font-size: 1.1rem;
    font-weight: var(--bold);
  }
`;

export const $Divider = styled.div`
  border: 1px solid var(--border-grey);
`;

export const $Prompt = styled.div`
  margin-top: 18px;
  margin-left: 48px;

  span {
    font-weight: var(--bold);
  }
`;

export const $CouponReminder = styled.div`
  background: #ffde0080;
  border-radius: 8px;
  margin-top: 12px;
  padding: 8px 16px;
  width: fit-content;
`;

export const $Reminder = styled.div`
  margin-top: 56px;

  font-size: 1.3rem;
  line-height: 32px;
`;

export const $WorkflowIllustration = styled.div`
  align-items: center;
  column-gap: 20px;
  display: flex;
  margin-top: 26px;
`;

export const $FinishReturnButtonWrapper = styled.div`
  button {
    border-radius: 12px;
    height: 56px;
    margin-top: 48px;

    font-weight: 100;
  }
`;

export default $ThankTheCustomerScanTote;
