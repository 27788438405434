import React from "react";

/* envelope */
const Email = (props) => (
  <>
    <svg
      {...props}
      width="56"
      height="38"
      viewBox="0 0 56 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.1385 0.333252H50.4C53.4928 0.333252 56 2.90474 56 6.07684V31.923C56 35.0951 53.4928 37.6666 50.4 37.6666H5.6C2.50721 37.6666 0 35.0951 0 31.923V6.07684C0 2.90474 2.50721 0.333252 5.6 0.333252H9.49999H46.1385ZM5.6 10.228V31.923H50.4V9.83781C50.0115 10.2878 49.535 10.6933 48.9654 11.0349L30.4954 22.1128C29.669 22.6085 28.6529 22.6367 27.8015 22.1877L6.93908 11.1847C6.42452 10.9133 5.97917 10.5901 5.6 10.228ZM46.1385 6.07683L28.9954 16.3589L9.49999 6.07684L46.1385 6.07683Z"
        fill="#A2A2A2"
      />
    </svg>
  </>
);

export default Email;
