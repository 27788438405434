import styled, { css }from "styled-components";
import $PageContentBox from "../../components/PageContentBox/styles";
import { $CancelBag, $BarcodeWidget } from "../../components/BarcodeWidget/styles";
import { BarcodeColumn } from "../../types/Config";

const $PartnerConfirmation = styled($PageContentBox as any)`
  position:       absolute;
  display:        flex;
  flex-direction: column;
  width:          100%;
  position:       relative;
`;

const $Title = styled.div`
  padding-top:    23px;
  padding-bottom: 22px;
  font-weight:    700;
  font-size:      18px;
  margin-left:    auto;
  margin-right:   auto;
`;

const $Directions = styled.div`
  width:         100%;
  padding-left:  32px;
  padding-right: 32px;
  overflow:      auto;

  .use-coupon-banner {
    margin: 24px 0;
    background: #FFDE00;
    border-radius: 8px;
    padding: 20px;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
  }
`;

const $Body = styled.div`
  width:         100%;
  padding-left:  88px;
  padding-right: 32px;
  flex-grow:     1;
`;

const singleColumnInputContainer = css`
  form {
    input[type=search] {
      width: 362px;
    }
  }
`;

const multipleColumnInputContainer = css`
  form {
    flex-grow: 1;
    input[type=search] {
      width:  100%;
    }
  }
`;

const $ScanInputContainer = styled.div<BarcodeListProps>`
  margin-top:    24px;
  margin-bottom: 16px;
  padding-right: 24px;
  width:         100%;
  display:       flex;
  form {
    input[type=search] {
      height: 48px;
    }
  }
  ${props => props.barcodeColumns.length > 1 ? multipleColumnInputContainer : singleColumnInputContainer}
`;
const $BarcodeSection = styled.div`
  min-height: 0;
`;

const singleColumnBarcodeList = css`
  ${$BarcodeWidget} {
      display:    flex;
      background: none;
      height:     44px;
      span {
        width: 362px;
      }
  }
`;

const multipleColumnBarcodeList = css`
  ${$BarcodeWidget} {
    width: 100%;
    span {
      flex: 1;
    }
    span:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    span:last-child{
      border-radius: 0px 8px 8px 0px !important;
    }
    span:not(span:first-child) {
      border-radius: 0px;
    }
  }
  ${$CancelBag} {
    flex-grow: 0;
  }
`;

interface BarcodeListProps {
  barcodeColumns: BarcodeColumn[]
}

const $BarcodeList = styled.div<BarcodeListProps>`
  ${$BarcodeWidget} {
    display:    flex;
    background: none;
    height:     44px;
    span {
      background:    var(--disabled-grey);
      border-radius: 8px;
      height:        48px;
      font-size:     16px;
      font-weight:   500;
      display:       flex;
      align-items:   center;
    }
  }
  ${props => props.barcodeColumns.length > 1 ? multipleColumnBarcodeList : singleColumnBarcodeList}
`;

const $Footer = styled.div`
  width:           100%;
  display:         flex;
  justify-content: center;
  margin-bottom:   16px;
  bottom:          16px;
`;

const $StepBorder = styled.div`
  border:        1px solid #DBDBDB;
  box-sizing:    border-box;
  border-radius: 12px;
  padding-left:  24px;
  padding-right: 24px;
  padding-top:   28px;
  margin-bottom: 16px;
  .scan-container {
    margin-left:   56px;
    margin-bottom: 24px;
  }
`;

export const $BarcodePrompt = styled.div`
  color:       var(--text-black);
  font-weight: 500;
  font-size:   16px;
  a {
    color:           var(--link-blue);
    text-decoration: underline;
    cursor:          pointer;
  }
  .barcode-caption {
    font-weight: 500;
    font-size:   12px;
    line-height: 16px;
    color:       var(--header-grey);
  }
`;

export {
  $PartnerConfirmation,
  $Title,
  $Directions,
  $ScanInputContainer,
  $BarcodeSection,
  $BarcodeList,
  $Body,
  $StepBorder,
  $Footer,
}
