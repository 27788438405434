import styled from "styled-components";

const $ExpressCodeApplePassButton = styled.div`
  .apple-pass-button-container {
    display: flex;
    justify-content: center;
    height: 48px;
    width: 173px;
    margin: auto;
  }
  .apple-pass-image {
    max-width: 100%;
    cursor: pointer;
  }

  div.error-banner {
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background-color: var(--warning-soft);
    line-height: 20px;
    margin-top: 24px;
    .icon {
      margin-right: 16px;
      &,
      svg {
        width: 16px;
        height: 16px;
      }
      & > svg > path {
        fill: var(--warning-bold);
      }
    }
    div.error-text {
      font-weight: 500;
      font-size: 13px;
      color: var(--warning-bold);
    }
  }
`;

export default $ExpressCodeApplePassButton;
