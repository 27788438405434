import React from "react";

import { ReturnStatusComposite } from "../index";

import { ReturnDetailsCard } from "./ReturnDetailsCard";
import { ReturnStatus } from "../../../types/ReturnStatus";

export const ReturnDetailsCards = (returnStatusComposite: ReturnStatusComposite) => {
  if (!returnStatusComposite?.returnDetails || returnStatusComposite.returnDetails.length == 0 ){
    return (
      <></>
    );
  }


  return (
    <>
      {
        returnStatusComposite.returnDetails.map((returnDetails, index) => {
          const props = {
            index: index,
            ...returnStatusComposite
          }

          if (returnStatusComposite.returnStatus === ReturnStatus.partial && !returnDetails.refundedAt) {
            return <></>
          }

          return <ReturnDetailsCard {...props} key={`return-details-card-${index}`}/>
        })
      }
    </>
  )
};
