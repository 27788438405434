import React from "react";

const Exclamation = (props) => (
  <>
    <svg {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56C43.464 56 56 43.464 56 28ZM51.8018 27.9999C51.8018 14.8555 41.1462 4.19989 28.0018 4.19989C14.8574 4.19989 4.20182 14.8555 4.20182 27.9999C4.20182 41.1443 14.8574 51.7999 28.0018 51.7999C41.1462 51.7999 51.8018 41.1443 51.8018 27.9999ZM30.0991 39.9C30.0991 38.7402 29.1589 37.8 27.9991 37.8C26.8393 37.8 25.8991 38.7402 25.8991 39.9C25.8991 41.0598 26.8393 42 27.9991 42C29.1589 42 30.0991 41.0598 30.0991 39.9ZM25.8997 31.4998C25.8997 32.6596 26.8399 33.5998 27.9997 33.5998C29.1595 33.5998 30.0997 32.6596 30.0997 31.4998V16.0998C30.0997 14.94 29.1595 13.9998 27.9997 13.9998C26.8399 13.9998 25.8997 14.94 25.8997 16.0998V31.4998Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Exclamation;
