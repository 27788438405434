import styled, { css } from "styled-components";
import { MOBILE_BREAKPOINT } from "../../globalConstants";
import { $ItemDetails } from "../../components/ItemDetails/styles";
import $PageContentBox from "../../components/PageContentBox/styles";

// language=SCSS prefix=*{ suffix=}
export const $PreviewContentBox = styled($PageContentBox as any)`
  @media screen and (max-width: 768px) {
    &.ios {
      max-width: 100%;
    }
    .banner {
      padding: 4px 32px;
    }
  }

  @media (min-width: 759px) {
    .hide-on-desktop {
      display: none;
    }
    .banner {
      margin-top: 32px;
      padding: 0 32px;
    }
  }

  .return-preview-custom-message {
    padding: 0 0 15px 0;
  }

  .header {
    font-size: 18px;
    line-height: 18.02px;
    margin: 23px 0 23px 0;
    text-align: center;
    font-weight: 700;
  }

  .banner {
    width: 100%;
  }

  .list {
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    padding: 12px 32px;
  }

  .android & {
    .list {
      padding-bottom: 90px;
    }
  }
`;

const $ReturnPreviewCardStyles = css`
  @media (min-width: 759px) {
    .hide-on-desktop {
      display: none;
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  margin-bottom: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 90vw;
  }

  .refund-block {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 16px;
    border-bottom: 1px solid var(--border-grey);
    display: flex;
    padding-bottom: 16px;
  }
  .refund-header {
    font-weight: 700;
    font-size: 18px;
    width: 306px;
    height: 13px;
    line-height: 20px;
  }
  .toggle-details {
    height: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #737373;
    width: 87px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex: none;
    order: 1;
  }
  .return-type {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  .returning-row,
  .getting-row,
  .purchasing-items {
    font-weight: 800;
    font-size: 11px;
    line-height: 16px;
    color: #737373;
  }
  @media (max-width: 480px) {
    padding: 12px;
    .exchange-row {
      display: flex;
      flex-direction: column;
      & > .returning,
      .getting {
        width: 100%;
      }
    }
    .returning-row,
    .getting-row,
    .exchange-row {
      width: 100%;
    }
  }

  .exchange-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & > div {
      width: 50%;
    }
  }

  .row {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    color: var(--header-grey);
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &.highlighted {
      background: none;
      font-weight: 800;
      font-size: 15px;
      line-height: 22px;
      color: var(--text-black);
    }
    &.distribution {
      color: black;
    }
    &.header {
      font-size: 11px;
      line-height: 16px;
      font-weight: 800;
      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    .toggle-details {
      margin-left: 12px;
      display: inline;
      text-decoration: underline;
      svg {
        margin-left: 4.8px;
      }
    }
  }

  .exchange-row {
    border-top: 1px solid #e7e7e7;

    &.first {
      border-top: none;
    }

    &:not(:first-child) {
      padding-top: 10px;
    }
  }
  .android & {
    .return-type {
      font-size: 15px;
      margin-bottom: 0px;
    }
    .item-name {
      font-size: 12px !important;
      line-height: 18px;
    }
    .exchange-row {
      &:not(:first-child) {
        margin-top: 5px;
      }
      &.first {
        margin-top: 0px;
      }
    }
  }
  &.return-shopping-wrapper {
    ${$ItemDetails} {
      margin: 12px 0;
    }
    .exchange-preview-card {
      padding-top: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--border-grey);
    }
    .returning-refund,
    .summary,
    .refunding-to,
    .label {
      font-weight: 800;
      font-size: 11px;
      line-height: 16px;
      color: var(--medium-emphasis-black);
      padding-top: 10px;
      margin-bottom: 4px;
    }
    .return-preview-card:not(:first-child) {
      padding-top: 24px;
    }
    .dropoff-method {
      font-size: 15px;
    }
    .disclaimerBlock {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--border-grey);
    }
    .disclaimer {
      display: flex;
      align-items: center;
      background: #fcf1cd;
      border-radius: 12px;
      padding: 12px;
      font-size: 14px;
      color: var(--text-black);
      .icon {
        margin-right: 12px;
      }
    }
  }
`;

// language=SCSS prefix=*{ suffix=}
export const $ReturnPreviewCard = styled.div`
  ${$ReturnPreviewCardStyles}
`;

export const $ReturnShoppingPreviewCard = styled.div`
  ${$ReturnPreviewCardStyles}
  .return-type {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 90vw;
  }

  .in-your-cart {
    margin-bottom: 0;
    justify-content: space-between;
  }
  .in-your-cart-header {
    font-weight: 700;
    font-size: 18px;
    width: 306px;
    height: 13px;
    line-height: 20px;
  }
  .in-your-cart-divider {
    padding-top: 16px;
    border-bottom: 1px solid var(--border-grey);
  }
  .return-shopping-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background: var(--background-white);
  }
  .return-shopping-header {
    font-weight: 800;
    font-size: 11px;
    line-height: 16px;
    color: var(--medium-emphasis-black);
    padding-top: 16px;
  }
  .return-shopping-image-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .return-shopping-images {
    object-fit: cover;
    height: min(20vw, 128px);
    width: min(20vw, 128px);
    margin-right: min(3vw, 16px);
    border: 1px solid var(--border-card);
    border-radius: 12px;
  }
  .more {
    height: min(10vw, 64px);
    width: min(10vw, 64px);
    min-height: 48px;
    min-width: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    white-space: pre-line;
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    color: var(--medium-emphasis-black);
  }
  .return-shopping-discount {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-size: 14px;
    line-height: 10px;
    svg {
      margin-right: 10px;
    }
  }
`;

// language=SCSS prefix=*{ suffix=}
export const $ReturnTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  font-size: 14px;
  line-height: 14px;
  color: #454545;
  & {
    border-top: none;
  }

  padding-bottom: 0;
  .row {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    color: var(--header-grey);
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &.highlighted {
      background: none;
      font-weight: 800;
      font-size: 15px;
      line-height: 22px;
      color: var(--text-black);
    }
    &.distribution {
      color: black;
    }
    &.header {
      font-size: 11px;
      line-height: 16px;
      font-weight: 800;
      &:not(:first-child) {
        margin-top: 4px;
      }
    }
  }

  .refunding-to {
    margin-right: auto;
    padding-top: 12px;
    border-top: 1px solid var(--border-grey);
    font-weight: bold;
    color: var(--header-grey);
    margin-top: 4px;
    margin-bottom: 12px;
    width: 100%;
    & > div {
      font-weight: 800;
      font-size: 11px;
      line-height: 16px;
    }
  }

  .line-through {
    text-decoration: line-through;
    .line-item {
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
      }
    }
  }
`;
