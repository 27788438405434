import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchReturns } from '../../redux/customer/actions';

import { PageProps } from "../types";

import useSelector from '../../utility/useTypedSelector';
import { hasBeenReturned } from "../../utility"
import { setScanPath } from '../../redux/app/actions';
import { BackendErrorPrefixes } from "../../types/API";
import { ErrorAlertMessage } from '../../components/AlertMessage';
import { FatalErrors } from "../../types/LifeCycle";
import SearchBar from '../../components/SearchField';
import { PageLifecycle } from '..';

import { $TypeExpressCode } from './styles';
import { reset } from '../../redux/customer/actions';
import { AxiosError } from 'axios';
import { DataCyStrings } from '../../types/DataCyStrings';
import * as messages from "../../messages";


class TypeExpressCodeLifeCycle extends PageLifecycle {
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
  }
}

const fetchReturnsCopies = {
  [BackendErrorPrefixes.locationNotAllowed]: "Code assigned to another retailer. Please let the customer know they can return their items to Happy Returns Return Bars or check their email for instructions.",
  [BackendErrorPrefixes.rmaInvalid]: "Invalid code. Please try re-entering it or ask the customer to begin a return online.",
  [BackendErrorPrefixes.rmaUnassociated]: "This code isn’t associated with Happy Returns. Please try re-entering it or ask the customer to begin a return online.",
  [BackendErrorPrefixes.rmaExpired]: "This retailer is no longer working with Happy Returns, please contact their customer service directly.",
  unauthorized: "This code is not eligible for a box free return. Please have the customer contact the retailer directly for directions on how to make their return.",
  complete: "All items associated with this express code have been returned and approved. If the customer has another express code, please try scanning it to continue.",
  [BackendErrorPrefixes.rmaPartialRefund]: "This code is no longer eligible for a box free return. Please have the customer start a new return or contact the retailer directly for help with this return.",
  [BackendErrorPrefixes.rmaExpiredReturn]: "This code is no longer eligible for a box free return. Please have the customer start a new return or contact the retailer directly for help with this return.",
  [BackendErrorPrefixes.returnBarIneligible]: "This code is not eligible for a Return Bar return. Please have the customer contact the retailer directly for directions on how to make their return.",
}

const TypeExpressCode = ({ page }: PageProps) => {
  const dispatch = useDispatch();
  const { app } = useSelector(store => store);
  const { currentPageName } = app;

  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const lifecycle = new TypeExpressCodeLifeCycle(page, dispatch, app);

  const [query, setQuery] = useState("");

  const nextPage = "countItemsPresent"

  // HELPER FUNCTIONS ////////////////////////////////////////
  const handleSubmit = () => {
    let capitalizedQuery = query.toUpperCase();
    dispatch(fetchReturns({
      query: capitalizedQuery,
      nextPage,
      onSuccess: (response, dispatch) => {
        if (response.data.returning.every(hasBeenReturned)) {
          dispatch(setLocalErrorMessage(fetchReturnsCopies.complete));
          return false;
        }
        dispatch(setScanPath(currentPageName));
        return true;
      },
      onError: (error: AxiosError, dispatch) => {
        if (error?.response?.status === 403) {
          dispatch(setLocalErrorMessage(fetchReturnsCopies["unauthorized"]));
        } else if (error?.response?.status === messages.returnBarDropoffIneligibleCode) {
          dispatch(setLocalErrorMessage(fetchReturnsCopies[BackendErrorPrefixes.returnBarIneligible]));
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaUnassociated)) {
          setLocalErrorMessage(fetchReturnsCopies[BackendErrorPrefixes.rmaUnassociated]);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaInvalid)) {
          setLocalErrorMessage(fetchReturnsCopies[BackendErrorPrefixes.rmaInvalid]);
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.locationNotAllowed)) {
          setLocalErrorMessage(fetchReturnsCopies[BackendErrorPrefixes.locationNotAllowed])
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaExpired)) {
          setLocalErrorMessage(fetchReturnsCopies[BackendErrorPrefixes.rmaExpired])
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaPartialRefund)) {
          setLocalErrorMessage(fetchReturnsCopies[BackendErrorPrefixes.rmaPartialRefund])
        } else if (error?.response?.data?.includes(BackendErrorPrefixes.rmaExpiredReturn)) {
          setLocalErrorMessage(fetchReturnsCopies[BackendErrorPrefixes.rmaExpiredReturn])
        }
      }
    }));
  }
  ////////////////////////////////////////////////////////////

  // HOOKS ///////////////////////////////////////////////////
  useEffect(() => {
    dispatch(reset());
    lifecycle.validatePreconditions();
    if (!lifecycle.satisfiesPreconditions()) {
      lifecycle.advanceToFatalError(FatalErrors.unknown);
    }
  }, [])

  ////////////////////////////////////////////////////////////
  return (
    <$TypeExpressCode>
      <SearchBar
        value={query}
        onChange={(val) => { setLocalErrorMessage(""); setQuery(val); }}
        onClear={() => { setLocalErrorMessage(""); setQuery(""); }}
        onSubmit={handleSubmit}
        placeholder="Enter QR code"
        inputDataCyString={DataCyStrings.confirmationCodeInput}
      />
      {localErrorMessage != "" &&
        <div className="error-message-container">
          <ErrorAlertMessage message={localErrorMessage} />
        </div>
      }
    </$TypeExpressCode>
  )
}

export default TypeExpressCode;
