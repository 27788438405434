import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import produce from "immer";
import axios from "axios";
import { updateLocalBundle } from "../../utility/ReturnistaBundle";

// helpers
import useSelector from "../../utility/useTypedSelector";
import {
  setToken,
  setLocalErrorMessage,
  showLoadingIndicator,
  clearLoadingIndicator,
  goToPage,
} from "../../redux/app/actions";
import { getReturnistaJWTClaims, mapURLQueryStringToObject } from "../../utility";

// types
import { ReturnistaURLQueries } from "../../types/JWTClaims";
import { PageProps } from "../types";

// components
import { PageLifecycle } from "..";
import { SVG } from "../../components/svg";
import TextInput from "../../components/TextInput";
import ContactInfo from "../../components/ContactInfo";

import { $Login, $AdminModeBadge } from "./styles";
import RetailerLogo from "../../components/RetailerLogo";
import { AnalyticCategories, CommonPageActions, LoginPageActions } from "../../types/Analytics";

import getTranslator from "../../utility/getTranslator";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import logger from "../../utility/logger/logger";

const useTranslation = getTranslator("LogIn");
export type LoginDetails = {
  login: string;
  password: string;
};

export type UseSSOResponse = {
  ssoEnabled: boolean;
  ssoRedirectURL: string;
};

enum LoginKeys {
  login = "login",
  password = "password",
}

export class LoginLifeCycle extends PageLifecycle {
  token: string;
  constructor(page, dispatch, app) {
    super(page, dispatch, app);
    this.token = app.token;
  }
  satisfiesPreconditions() {
    const urlQueries = mapURLQueryStringToObject() as ReturnistaURLQueries;
    if (urlQueries.locationID === undefined) {
      return false;
    }
    return true;
  }
  satisfiesPostconditions() {
    if (this.token == "" || !this.token) return false;

    return true;
  }
}

/**
 * Login component currently being used in Returnista
 */
const LogIn = ({ page }: PageProps) => {
  const { t } = useTranslation();
  const loginErrorCopies = {
    userNotFound: t("weDontHaveARecord"),
    internal: t("anErrorOccurredWhile"),
    samlError: t("unableToSignIn"),
    expired: t("yourSessionHasExpired"),
  };
  const dispatch = useDispatch();
  const { app } = useSelector((store) => store);
  const { token, localErrorMessage } = app;
  const urlQueries = mapURLQueryStringToObject() as ReturnistaURLQueries;
  const { locationID } = urlQueries;

  const [loginDetails, setLoginDetails] = useState<LoginDetails>({
    login: "",
    password: "",
  });

  const [loginError, setLoginError] = useState(localErrorMessage ? localErrorMessage : "");

  const [fatalLoginError, setFatalLoginError] = useState(false);

  const [versionID, setVersionID] = useState<string | undefined>(urlQueries?.versionID);

  const [loginToken, setLoginToken] = useState<string>("");

  const [ssoRedirectLink, setSSORedirectLink] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const lifecycle = new LoginLifeCycle(page, dispatch, app);

  // HELPER FUNCTIONS ///////////////////////////////////////
  const handleSetLoginDetails = (key, value) => {
    setLoginError("");
    setLoginDetails(
      produce(loginDetails, (draft) => {
        draft[key] = value;
        return draft;
      })
    );
  };

  const handleSignIn = async (e?: Event) => {
    e?.preventDefault();
    const { locationID } = urlQueries;
    const req: Record<string, any> = { ...loginDetails, locationID };
    if (loginToken) req.token = loginToken;
    try {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      const resp = await axios.post("/login", req);
      dispatch(setLocalErrorMessage(""));
      dispatch(setToken(resp.data));
      const { returnsApp } = getReturnistaJWTClaims(resp.data);
      if (returnsApp?.analyticsToken) {
        ga.setAnalyticsToken(returnsApp?.analyticsToken);
      }
      ga.setDimensions({
        user_properties: {
          location_id: returnsApp?.locationID,
          location_owner: returnsApp?.locationOwner,
        },
      });
    } catch (e) {
      setLoginDetails({
        ...loginDetails,
        password: "",
      });
      if (e!.response?.status === 401 || e!.response?.status === 403) {
        setLoginError(loginErrorCopies.userNotFound);
      } else {
        setLoginError(loginErrorCopies.internal);
      }
    }
    dispatch(clearLoadingIndicator());
  };

  const getUseSSO = async () => {
    dispatch(showLoadingIndicator(defaultLoadingSymbol));
    setIsLoading(true);
    const { locationID } = urlQueries;
    try {
      const { ssoEnabled, ssoRedirectURL }: UseSSOResponse = (await axios.get("/use-sso", { params: { locationID } }))
        .data;
      // if sso is enabled and we have a url value, set the redirect url
      if (ssoEnabled && ssoRedirectURL) {
        setSSORedirectLink(ssoRedirectURL);
      }
    } catch (e) {
      if (axios.isAxiosError(e) && (e.response?.status == 400 || e.response?.status == 404)) {
        logger.Warning(`unable to get sso values: ${e.response.data}`);
      } else {
        logger.Error(`error getting sso values: ${e}`);
      }
    }
    dispatch(clearLoadingIndicator());
    setIsLoading(false);
  };

  const captureAuth = async (token: string) => {
    try {
      await axios.get("/login/saml/capture-auth", { headers: { Authorization: `Bearer ${token}` } });
      dispatch(clearLoadingIndicator());
      dispatch(setToken(token));
      const { returnsApp } = getReturnistaJWTClaims(token);
      ga.setDimensions({
        user_properties: {
          location_id: returnsApp?.locationID,
          location_owner: returnsApp?.locationOwner,
        },
      });
    } catch (e) {
      dispatch(clearLoadingIndicator());
      if (axios.isAxiosError(e) && e.response?.data.includes("expired")) {
        logger.Warning("sso login - expired happy returns auth token");
        setLoginError(loginErrorCopies.expired);
      } else {
        logger.Error("sso login - unable to capture auth: ", e.response?.data);
        setLoginError(loginErrorCopies.internal);
      }
      // give the ability to retry SSO
      getUseSSO();
    } finally {
      // we want to strip the token from the url so the token string doesn't linger and cause
      // unwanted side effects later on in the user flow
      stripTokenFromURL();
    }
  };

  const stripTokenFromURL = () => {
    // regexp that matches the token query param and the string that follows
    const tokenRegex = new RegExp(/&token=.+/);
    const newURL = window.location.href.replace(tokenRegex, "");
    // update the url, clearing the token string
    history.replaceState(null, "", newURL);
  };

  // HOOKS ///////////////////////////////////////////////////
  useEffect(() => {
    if (!lifecycle.satisfiesPreconditions()) {
      // lifecycle.advanceToFatalError(FatalErrors.location);
    }
    // if the user is already logged in, skip this page
    if (lifecycle.satisfiesPostconditions()) {
      lifecycle.advance();
    }
    // save the versionID from URL even if undefined
    const queries = mapURLQueryStringToObject() as ReturnistaURLQueries;
    setVersionID(queries.versionID);

    window.setLoginToken = (token: string) => {
      setLoginToken(token);
    };

    // if there is a label parameter in the query string then
    // route user to the shippingLabelRequestForm
    if (queries.label) {
      dispatch(goToPage("shippingLabelRequestForm"));
    }

    // clean up global fns once we unmount
    return () => {
      delete window.setLoginToken;
    };
  }, []);

  useEffect(() => {
    const { token, samlError, locationID } = urlQueries;
    if (token) {
      dispatch(showLoadingIndicator(defaultLoadingSymbol));
      captureAuth(token);
      return;
    }
    if (samlError) {
      setFatalLoginError(true);
      setLoginError(loginErrorCopies.samlError);
      return;
    }
    if (locationID) {
      getUseSSO();
    }
  }, []);

  useEffect(() => {
    if (loginToken) handleSignIn();
  }, [loginToken]);

  useEffect(() => {
    if (lifecycle.satisfiesPostconditions()) {
      lifecycle.advance();
    }
  }, [token]);

  useEffect(() => {
    const setErrorForEmptyLocationID = () => setLoginError("Location ID is required to log in. Contact Happy Returns support if you’re not sure what to do.")

    if (locationID == null || locationID === '') {
      setErrorForEmptyLocationID()
    }

    //reload the app if the local javascript bundle is not updated
    updateLocalBundle(locationID);

  }, [locationID])
  ////////////////////////////////////////////////////////////

  return (
    <$Login>
      {!isLoading && (
        <LoginBase loginError={loginError}>
          <>
            {loginError ? (
              <div className="error-banner">
                <div className="icon">
                  <SVG name="exclamation" />
                </div>
                <div className="error-text" data-cy={DataCyStrings.loginPageErrorText}>
                  {loginError}
                </div>
              </div>
            ) : null}
            {!fatalLoginError &&
              (window?.Android?.getAuthenticationMethod?.() == "MSAL" ? (
                <div className="sso">
                  <PrimaryButton
                    width="75%"
                    label={t("signIn")}
                    onButtonClick={() => {
                      window.Android.signIn();
                    }}
                    dataCyString={DataCyStrings.loginPageSingleSignInButton}
                  />
                </div>
              ) : ssoRedirectLink !== "" ? (
                <a href={ssoRedirectLink} className="sso-link" data-cy={DataCyStrings.loginPageSSOLink}>
                  <PrimaryButton
                    width="100%"
                    label={t("signInWithSSO")}
                    dataCyString={DataCyStrings.loginPageSingleSignInButton}
                  />
                </a>
              ) : (
                <ReturnistaLoginForm
                  handleSignIn={handleSignIn}
                  loginDetails={loginDetails}
                  handleSetLoginDetails={handleSetLoginDetails}
                  locationID={locationID}
                />
              ))}
          </>
        </LoginBase>
      )}
      <ContactInfo email={"returnbar@happyreturns.com"} phoneNumber={"(877) 750-4888"} versionID={versionID} />
    </$Login>
  );
};

const ReturnistaLoginForm = ({ handleSignIn, loginDetails, handleSetLoginDetails, locationID }) => {
  return (
    <form className="login-form" onSubmit={handleSignIn} data-cy={DataCyStrings.loginPageAuthForm}>
      {locationID && (
        <>
          <TextInput
            label="Username"
            value={loginDetails.login}
            onChange={(val) => handleSetLoginDetails(LoginKeys.login, val)}
            dataCyString={DataCyStrings.loginPageUsernameInput}
          />
          <TextInput
            label="Password"
            value={loginDetails.password}
            onChange={(val) => handleSetLoginDetails(LoginKeys.password, val)}
            type="password"
            dataCyString={DataCyStrings.loginPagePasswordInput}
          />
        </>
      )}
      <input
        data-cy={DataCyStrings.loginPageSignInButton}
        type="submit"
        value="Sign In"
        disabled={!loginDetails.login || !loginDetails.password}
      />
    </form>
  );
};

interface LoginBaseProps {
  loginError?: string;
  logoURL?: string;
  homeURL?: string;
  header?: string;
  retailerName?: string;
  Banner?: JSX.Element;
  children?: JSX.Element;
  isAdminMode?: boolean;
}

const getHeaderLinkAndText = (str): { text: string; url: string } | null => {
  const headerLinkText = str.match(/\[.*\]\(.*\)/);
  if (headerLinkText !== null) {
    const linkText = headerLinkText[0].match(/\[.*\]/);
    const linkTextWithoutBrackets = linkText !== null ? linkText[0].substr(1, linkText[0].length - 2) : "";
    const url = headerLinkText[0].match(/\(.*\)/);
    const urlWithoutParens = url !== null ? url[0].substr(1, url[0].length - 2) : "";

    return {
      text: linkTextWithoutBrackets,
      url: urlWithoutParens,
    };
  }

  // return null if a hyperlink cannot be found
  return null;
};

export const LoginBase = ({ logoURL, homeURL, retailerName, header, children, isAdminMode }: LoginBaseProps) => {
  let headerText = header?.split(/(\[.*\]\(.*\))/);
  const { t } = useTranslation();
  const retailerLogoAltText = retailerName ? t("retailerNameLogo", { retailerName }) : t("retailerLogo");

  // if no result was found from splitting based on "markdown" links,
  // attempt to find a raw URL to turn into a hyperlink and format it as
  // [URL](URL)
  if (headerText?.length === 1) {
    headerText = header
      ? header
        .replace(/https?:\/\/[^\s]+/, (match) => {
          return `[${match}](${match})`;
        })
        ?.split(/(\[.*\]\(.*\))/)
      : [];
  }

  const renderHeaderComponents = () => {
    return headerText?.map((item) => {
      const headerLinkAndText = getHeaderLinkAndText(item);
      if (headerLinkAndText) {
        return (
          <HeaderLink
            key={headerLinkAndText.url}
            headerLinkText={headerLinkAndText.text}
            headerLinkURL={headerLinkAndText.url}
          />
        );
      } else {
        return item;
      }
    });
  };
  const onRetailerLogoClicked = () => {
    if (logoURL) {
      ga.event({
        category: AnalyticCategories.LoginPage,
        action: CommonPageActions.MerchantLogo,
      });
    }
  };
  return (
    <div className="login-container">
      {isAdminMode && <$AdminModeBadge data-cy={DataCyStrings.adminModeBadge}>ADMIN MODE</$AdminModeBadge>}
      <div className="logo">
        {logoURL ? (
          <RetailerLogo onClick={onRetailerLogoClicked} logoURL={logoURL} homeURL={homeURL} alt={retailerLogoAltText} />
        ) : (
          <SVG name="hr-logo-paypal" alt="Happy Returns Logo" data-cy={DataCyStrings.logo} />
        )}
      </div>
      <div className="header" data-cy={DataCyStrings.retailerMessage}>{renderHeaderComponents()}</div>
      {children}
    </div>
  );
};

const HeaderLink = ({ headerLinkText, headerLinkURL }) => {
  const onCustomLogoClick = () => {
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: LoginPageActions.CustomLinkLogin,
    });
  };
  return (
    <>
      {headerLinkText && headerLinkURL && (
        <a target="_blank" onClick={onCustomLogoClick} href={headerLinkURL}>
          {headerLinkText}
        </a>
      )}
    </>
  );
};

export default LogIn;
