import React from "react";

/** credit card */
const Refund = (props) => (
  <>
    <svg
      {...props}
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="26.5"
        height="18.5"
        rx="2.25"
        stroke="#737373"
        strokeWidth="1.5"
      />
      <rect y="3.99976" width="28" height="3.6" fill="#737373" />
      <path
        d="M3.54102 12.3999H9.54102"
        stroke="#737373"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M3.54102 15.2H11.941"
        stroke="#737373"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  </>
);

export default Refund;
