import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appFlattenedReducer from "./app/reducers";
import customerFlattenedReducer from "./customer/reducers";
import type { AppFlattenedStore } from "./app/reducers";
import type { CustomerFlattenedStore } from "./customer/reducers";

export interface RootReducer {
  customer: CustomerFlattenedStore;
  app: AppFlattenedStore;
}

export type GetState = () => RootReducer;

const appPersistConfig = {
  key: "app",
  storage,

  // we want to clear the fatal error when the page refreshes so blacklist that key
  blacklist: ["fatalErrorName", "alertName"],
};

// XXX - temporarily clear localstorage before redux loads
// will be replaced with cache invalidation to ensure that
// data doesn't persist between releases
localStorage.clear();

const reducers = combineReducers({
  app: persistReducer(appPersistConfig, appFlattenedReducer),
  customer: customerFlattenedReducer,
});

const rootReducer = (state, action) => {
  return reducers(state, action);
};

export default rootReducer;
