import styled, { css } from "styled-components";

// language=SCSS prefix=*{ suffix=}
export const $LoginBase = css`
  .login-container {
    width: 456px;
    padding: 32px ;
    border-radius: 12px;
    box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.16);
    margin-top: 72px;
    background-color: white;

    .logo {
      margin: 0 auto;
      &, svg, img {
        width: 200px;
        height: 40px;
        object-fit: contain;
      }
      img.hover {
        cursor: pointer;
      }
    }
    .header {
      color: var(--header-grey);
      font-size: 15px;
      line-height: 23px;
      width: 200px;
      margin-bottom: 12px;
      text-align: center;
      white-space: pre-line;
      word-break: break-word;
    }

    div.error-banner {
      display: flex;
      width: 100%;
      padding: 16px;
      border-radius: 12px;
      background-color: var(--warning-soft);
      line-height: 20px;
      .icon {
        margin-right: 16px;
        &, svg {
          width: 16px;
          height: 16px;
        }
        & > svg > path {
            fill: var(--warning-bold);
        }
      }
      div.error-text {
        font-weight: 500;
        font-size: 13px;
        color: var(--warning-bold);
      }
    }
    form.login-form {
      margin-top: 12px;
      input, input[type=submit] {
        width: 100%;
        height: 44px;
        border-radius: 8px;
        margin-bottom: 24px;
        -webkit-appearance: none;
        font-family: "sofia-pro", sans-serif;
      }
      input[type=submit] {
        width: 100%;
        height: 44px;
        background-color: black;
        color: white;
        border: none;
        border-radius: 12px;
        margin-bottom: 0;
        font-size: 16px;
        &:disabled {
          background-color: var(--disabled-grey);
          color: var(--header-grey);
        }
      }
    }
    .sso-link {
      margin: 0 auto;
      width: 75%;
      display: flex;
      text-decoration: none;
      padding: 32px 0;
    }
    .android & {
      max-width: 456px;
      width: 100%;
      margin: 0 24px 24px;
      .logo {
        &, svg, img {
          max-width: 200px;
          width: 100%;
        }
      }
      label {
        font-weight: 400;
      }
      .sso {
        text-align: center;
        padding: 2em;
      }
    }
  }
`;

export const $Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--hr-yellow);
  padding: 24px;
  ${$LoginBase}

  .android & {
    min-height: 100%;
    height: fit-content;
  }
`;

export const $AdminModeBadge = styled.div`
  margin: auto;
  width: 103px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
  background-color: #FEE8E3;
  font-weight: bold;
  font-size: 11px;
  color: rgb(193, 13, 23);
  text-align: center;
  vertical-align: middle;
`;

export default $Login;
