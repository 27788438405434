import React, { FormEvent, useRef, useState } from "react";
import Header, { HeaderBackButton } from "../../components/ReturnistaHeader";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ReturnistaPrimaryButton from "../../components/Button/ReturnistaPrimaryButton";
import ReturnistaFeedbackIcon from "../../components/ReturnistaFeedbackIcon";
import {
  CurrentPageAction,
  EventAction,
  LoadingAction,
  LocationObjectAction,
  ModalAction,
  bagsAction,
} from "../../redux/enums";
import { $IconWrapper } from "../ReturnistaItemCodeScan/styles";
import CounterInput from "../../components/CounterInput";
import { DataCyStrings } from "../../types/DataCyStrings";
import { $BagCountContainer } from "./styles";
import { getReturnistaJWTClaims, mapURLQueryStringToObject } from "../../utility";
import axios from "axios";
import ReturnistaAnalytics from "../../utility/ReturnistaAnalytics";
import logger from "../../utility/logger/logger";
import generateZPLCode from "../../utility/formatZPLCode";
import ga from "../../utility/GAEmitter";
import { BagCountActions } from "../../types/ReturnistaGA";

export const generateUPSBagIDs = (count = 0, rma = "", locationID = ""): string[] => {
  const funcName = "generateUPSBagIDs";
  const result: string[] = [];
  if (count === 0) {
    throw new Error(`${funcName} invalid bag count [${count}]`);
  }
  if (rma === "") {
    throw new Error(`${funcName} invalid rma [${rma}]`);
  }
  if (locationID === "") {
    throw new Error(`${funcName} invalid locationID [${locationID}]`);
  }
  for (let i = 1; i <= count; i++) {
    result.push(`${rma}-${locationID}-${i}`.toUpperCase());
  }
  return result;
};

const ReturnistaBagCount = () => {
  const { items, returnObject, currentPage, events } = useSelector((state: RootStateOrAny) => state);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const { authorization } = mapURLQueryStringToObject();
  const { returnsApp } = getReturnistaJWTClaims(authorization);

  const locationID = returnsApp?.locationID || "";
  const rma = returnsApp?.query?.value || "";

  const handleChange = (val: number) => {
    if (val <= 9) {
      setCount(val);
    }
  };

  const getReturningItems = (itemId: string) => {
    const returnItem = returnObject.returning.find((item) => item.id === itemId);
    const { label, confidence, matched } = items.byId[itemId];
    returnItem.scannedLabelReturnista = { label, confidence, matched };
    return returnItem;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    ga.pageEvent({ category: currentPage, action: BagCountActions.SubmitReturnClicked });
    dispatch({ type: LoadingAction.Set, message: "Submitting" });
    let bagIDs: string[] = [];
    try {
      bagIDs = generateUPSBagIDs(count, rma, locationID);
      const { data } = await axios.post("/receive", {
        ...returnObject,
        returning: items.allIds.map(getReturningItems),
      });
      const { location } = data;
      if (location) {
        dispatch({ type: LocationObjectAction.Set, location });
      }
      await axios.post("/returns/bagsList", { returnID: returnObject.id, barcodes: bagIDs });
      ReturnistaAnalytics.bagsWereAdded(bagIDs);
      dispatch({ type: LoadingAction.Unset });
      dispatch({ type: CurrentPageAction.Next });
      dispatch({
        type: bagsAction.GenerateLabels,
        labels: generateZPLCode({ barCodes: bagIDs }),
      });
      if (events.length > 0) {
        axios.post("/returnistaevents", events).then(() => {
          dispatch({
            type: EventAction.Reset,
          });
        });
      }
    } catch (e) {
      ReturnistaAnalytics.bagsMayNotBeAdded(bagIDs);
      logger.Warning("unable to submit return", {
        err: e,
        returnID: returnObject.id,
        barcodes: bagIDs,
      });
      dispatch({ type: LoadingAction.Unset });
      dispatch({
        type: ModalAction.Set,
        modalProps: {
          primaryMessage: "Something went wrong",
          subMessages: [
            "The page didn't load correctly, please try again.",
            "If the issue persists, contact Help Desk.",
          ],
          onRequestClose: () => dispatch({ type: ModalAction.Unset }),
          button: (
            <ReturnistaPrimaryButton onClick={() => dispatch({ type: ModalAction.Unset })} dataCyString="close-modal">
              Try again
            </ReturnistaPrimaryButton>
          ),
          iconElement: (
            <$IconWrapper>
              <ReturnistaFeedbackIcon status="error" />
            </$IconWrapper>
          ),
        },
      });
    }
  };

  return (
    <>
      <Header left={<HeaderBackButton />} center="Submit return" />
      <$BagCountContainer>
        <h1>How many POLY BAGS did you use?</h1>
        <h2>Consolidate items into as few bags as possible.</h2>
        <CounterInput
          value={count}
          id="bag-count"
          disabled={!count}
          onSubmit={handleSubmit}
          setInputValue={handleChange}
          increment={() => setCount(count + 1)}
          decrement={() => setCount(count - 1)}
          resetErrorState={() => null}
          inputRef={inputRef}
          dataCy={DataCyStrings.bagCountInput}
          buttonText="Submit return"
          submitDataCy={DataCyStrings.submitReturnButton}
          item="bags"
          separateButton={true}
          minimum={1}
          maximum={9}
        />
      </$BagCountContainer>
    </>
  );
};

export default ReturnistaBagCount;
