import { currentBagAction, MultiStoreAction } from "../enums";

const currentBagReducer = (state = null, action) => {
  switch (action.type) {
    case currentBagAction.Set:
      return action.bag;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case currentBagAction.Unset:
      return null;
    default:
      return state;
  }
};

export default currentBagReducer;
