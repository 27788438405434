// third-party imports
import React, { useEffect, useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";

// repo imports
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";

// local imports
import $ShippingLabelSuccessPage from "./styles";
import DownloadSuccess from "../../components/svg/DownloadSuccess";
import { SVG, SVGName } from "../../components/svg";
import { DataCyStrings } from "../../types/DataCyStrings";
import { mapURLQueryStringToObject } from "../../utility";
import { ReturnistaURLQueries } from "../../types/JWTClaims";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import ErrorContainer, { ErrorTypes } from "../../components/ErrorContainer";
import { returnBarSupportLink } from "../../globalConstants";
import logger from "../../utility/logger/logger";

/**
 * success landing page for shipping label request
 */
const ShippingLabelSuccessPage = () => {
  //----------------------------------------------------------------------------
  // STATE
  const { app } = useSelector((store: RootReducer) => store);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [errorState, setErrorState] = useState<ErrorTypes>();
  const [usePrelabeledTotes, setUsePrelabeledTotes] = useState(false);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    const dateFromToken = getPickupDate();
    setPickupDate(dateFromToken);

    const { prelabeled } = mapURLQueryStringToObject() as ReturnistaURLQueries;
    if (prelabeled == "true") {
      setUsePrelabeledTotes(true)
    }
  }, []);

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS
  const renderInstructions = () => {
    type Instruction = {
      iconName: SVGName;
      copy: string;
    };
    const instructionSet: Instruction[] = usePrelabeledTotes ? [
      {
        iconName: "file-copy",
        copy: "Do not make copies or share labels.",
      },
      {
        iconName: "calendar",
        copy: "Do not pre-print labels for future use.",
      },
    ] : [
      {
        iconName: "file-copy",
        copy: "Do not make copies or share labels.",
      },
      {
        iconName: "calendar",
        copy: "Do not pre-print labels for future use.",
      },
      {
        iconName: "replace",
        copy: "If your tote came with a label inserted, replace it with a new label.",
      },
    ];
    return instructionSet.map((instruction) => (
      <div className="instruction" key={instruction.iconName}>
        <div className="instruction-icon">
          <SVG name={instruction.iconName} />
        </div>
        <div>{instruction.copy}</div>
      </div>
    ));
  };

  // parse the "label" query for the pickupTime
  const getPickupDate = () => {
    const { label } = mapURLQueryStringToObject() as ReturnistaURLQueries;
    if (label) {
      const jsonData = atob(label);
      const data = JSON.parse(jsonData);
      return data.pickupTime;
    }
  };
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const handleDownloadButtonClick = async () => {
    setLoadingMessage("Generating shipping labels...");
    try {
      const path = `/location_label/count/${app.labelCount}`;
      const resp = await axios.get(path, { responseType: "arraybuffer" });
      const contentTypeHeader = resp.headers["content-type"];
      // retrieve the shipping-labels-locationid.pdf filename
      const filename = contentTypeHeader.match(/\w+-\w+-\w+.pdf/g);
      // create the blob object with content-type "application/pdf"
      var blob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(blob, filename);
    } catch (e) {
      logger.Error("Error generating shipping labels", e)
      setErrorState(ErrorTypes.default);
    }
    setLoadingMessage("");
  };

  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  return (
    <$ShippingLabelSuccessPage>
      {!!loadingMessage ? (
        <LoadingIndicator message={loadingMessage} />
      ) : (
        <>
          {!!errorState ? (
            <ErrorContainer errorType={ErrorTypes[errorState]} />
          ) : (
            <>
              <div className="success-icon">
                <DownloadSuccess />
              </div>
              <h1>Ready to print.</h1>
              <h2>Labels are in your downloads folder.</h2>
              <div className="pickup-container" data-cy={DataCyStrings.pickupDate}>
                Scheduled Pickup Date:
                <br />
                {pickupDate}
              </div>
              <div className="instructions-container">
                <div className="instructions-list">{renderInstructions()}</div>
                <div className="disclaimer">
                  Failure to follow these steps will have a negative impact on your store's supplies.
                </div>
              </div>
              <div className="troubleshooting">
                Can't find them?
                <button
                  className="download-button"
                  data-cy={DataCyStrings.downloadAgainButton}
                  onClick={handleDownloadButtonClick}
                >
                  Download Again
                </button>
                <div>
                  More questions?{" "}
                  <a
                    className="support-link"
                    target="_blank"
                    href={returnBarSupportLink}
                  >
                    Contact customer support
                  </a>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </$ShippingLabelSuccessPage>
  );
  //----------------------------------------------------------------------------
};

export default ShippingLabelSuccessPage;
