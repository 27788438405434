import React from "react";

const Two = (props) => (
  <>
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.25" stroke="black" strokeWidth="1.5" />
      <path
        d="M15.028 20.928L18.64 16.623C19.543 15.573 20.215 14.208 20.215 13.074C20.215 10.638 18.493 9 15.847 9C13.411 9 11.773 10.449 11.5 12.633L13.348 13.011C13.474 11.772 14.419 10.953 15.826 10.953C17.233 10.953 18.157 11.793 18.157 13.074C18.157 13.662 17.611 14.691 16.792 15.678L11.626 21.936V22.902H20.425V20.928H15.028Z"
        fill="black"
      />
    </svg>
  </>
);

export default Two;
