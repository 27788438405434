import React from "react";

const Clock = (props) => (
  <>
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0ZM8 1C7.08075 1 6.1705 1.18106 5.32122 1.53284C4.47194 1.88463 3.70026 2.40024 3.05025 3.05025C2.40024 3.70026 1.88463 4.47194 1.53284 5.32122C1.18106 6.1705 1 7.08075 1 8C1 8.91925 1.18106 9.8295 1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7.5 3C7.61703 2.99996 7.73036 3.04097 7.82026 3.11589C7.91016 3.19081 7.97093 3.29489 7.992 3.41L8 3.5V8H10.5C10.6249 7.99977 10.7455 8.04633 10.8378 8.13051C10.9301 8.21469 10.9876 8.33039 10.9989 8.45482C11.0102 8.57926 10.9745 8.70341 10.8988 8.80283C10.8231 8.90225 10.7129 8.96974 10.59 8.992L10.5 9H7.5C7.38297 9.00004 7.26964 8.95903 7.17974 8.88411C7.08984 8.80919 7.02907 8.70511 7.008 8.59L7 8.5V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
        fill="#737373"
      />
    </svg>
  </>
);

export default Clock;
