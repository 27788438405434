import React, { ReactElement } from "react";
import { returnBarSupportLink } from "../../globalConstants";
import { DataCyStrings } from "../../types/DataCyStrings";
import PrimaryButton from "../Button/PrimaryButton";
import { SVGName, SVG } from "../svg";
import { $Container } from "./styles";

export enum ErrorTypes {
  expired = "expired-link",
  applePassGeneration = "apple-pass-generation",
  default = "default",
}

type ErrorDisplay = {
  icon: SVGName;
  message: string;
  details?: string;
  link?: ReactElement;
  buttonText?: string;
  onClick?: () => void;
};

export const errorStates: { [key in ErrorTypes]: ErrorDisplay } = {
  [ErrorTypes.expired]: {
    icon: "expired",
    message: "The link has expired",
    details:
      "Shipping label generation link expires after 48 hours. If you have totes ready to ship, check your inbox for the latest Ship Out email from Happy Returns.",
    link: (
      <h2>
        For additional help, reference{" "}
        <a className="link" target="_blank" href={returnBarSupportLink}>
          Return Bar Support
        </a>
        .
      </h2>
    ),
  },
  [ErrorTypes.applePassGeneration]: {
    icon: "error",
    message: "There was an issue generating your Apple Wallet pass.",
  },
  [ErrorTypes.default]: {
    icon: "error",
    message: "Something went wrong...",
    details: "Sorry, something went wrong there, try again.",
    buttonText: "Try Again",
    onClick: () => location.reload(),
    link: (
      <h2 className="questions">
        More questions?{" "}
        <a className="link" target="_blank" href={returnBarSupportLink}>
          Contact customer support
        </a>
        .
      </h2>
    ),
  },
};

type ErrorContainerProps = {
  errorType: ErrorTypes;
};

const ErrorContainer = ({ errorType }: ErrorContainerProps) => {
  const { icon, message, details, buttonText, onClick, link } = errorStates[errorType];
  return (
    <$Container>
      <div className="error-icon">
        <SVG name={icon} />
      </div>
      <h1 className="error-message" data-cy={DataCyStrings.errorMessage}>
        {message}
      </h1>
      <h2>{details}</h2>
      {buttonText && onClick && <PrimaryButton onButtonClick={onClick}>{buttonText}</PrimaryButton>}
      {link}
    </$Container>
  );
};

export default ErrorContainer;
