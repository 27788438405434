import React from 'react';

const GenericUser = (props) => {
  return (
    <svg {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#FFDE00"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28 28C31.3129 28 34 25.3088 34 21.9908C34 18.6728 31.3129 16 28 16C24.6871 16 22 18.6912 22 21.9908C22 25.2903 24.6871 28 28 28ZM28 18C30.2 18 32 19.8 32 22C32 24.2 30.2 26 28 26C25.8 26 24 24.2 24 22C24 19.8 25.8 18 28 18ZM16 39.0487C16 39.5796 16.3744 40 16.8473 40H39.1527C39.6256 40 40 39.5796 40 39.0487C40 34.0708 36.3941 30 31.9409 30H24.0591C19.6256 30 16 34.0487 16 39.0487ZM18 38C18.4038 34.6357 21 32 24.1538 32H31.8462C35 32 37.5962 34.6143 38 38H18Z" fill="black"/>
    </svg>
  )
}

export default GenericUser;