import React from "react";

const HRIcon = (props) => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1867_2614" maskUnits="userSpaceOnUse" x="0" y="11" width="12" height="4">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 11.0225H12V14.0984H0V11.0225Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1867_2614)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.14018 14.0984C2.73038 14.0984 0.239592 12.0513 0.202572 12.0199C-0.0508738 11.807 -0.0692521 11.4441 0.161352 11.21C0.391607 10.9761 0.783153 10.9588 1.03651 11.171C1.2322 11.3346 5.89233 15.1302 10.9795 11.1577C11.2411 10.9533 11.6325 10.9842 11.8534 11.2259C12.0744 11.468 12.0415 11.8298 11.7796 12.0341C9.79756 13.5818 7.85295 14.0984 6.14018 14.0984Z"
        fill="#221F1F"
      />
    </g>
    <mask id="mask1_1867_2614" maskUnits="userSpaceOnUse" x="0" y="0" width="6" height="9">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.847656 0.901855H5.85164V8.41711H0.847656V0.901855Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1867_2614)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.847656 0.901367H2.11322V4.11149C2.44999 3.5746 3.07677 3.2848 3.87789 3.2848C4.65582 3.2848 5.2014 3.54248 5.50324 3.9612C5.77025 4.33687 5.85164 4.7556 5.85164 5.44268V8.41665H4.58607V5.82928C4.58607 4.9703 4.31906 4.31551 3.41345 4.31551C2.53111 4.31551 2.11322 4.9703 2.11322 5.82928V8.41665H0.847656V0.901367Z"
        fill="#221F1F"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1991 5.45244C10.8543 5.45244 11.3851 4.96137 11.3851 4.35553C11.3851 3.74995 10.8543 3.25879 10.1991 3.25879C9.54406 3.25879 9.01318 3.74995 9.01318 4.35553C9.01318 4.96137 9.54406 5.45244 10.1991 5.45244Z"
      fill="#221F1F"
    />
  </svg>
);

export default HRIcon;
