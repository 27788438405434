import React from "react";

const Shop = (props) => (
  <>
    <svg
      {...props}
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55737 9.18848V20.2329C3.55737 20.4916 3.66222 20.7397 3.84885 20.9226C4.03547 21.1056 4.28859 21.2083 4.55253 21.2083H21.2867C21.5506 21.2083 21.8037 21.1056 21.9904 20.9226C22.177 20.7397 22.2818 20.4916 22.2818 20.2329V9.18848"
        stroke="#737373"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2819 0.791504H3.55744C3.55744 0.791504 1.33325 5.50685 1.33325 6.27101C1.33387 6.97726 1.59035 7.66035 2.05697 8.1985C2.5236 8.73664 3.17004 9.09487 3.88105 9.20931C4.59206 9.32376 5.32142 9.18698 5.93912 8.82337C6.55682 8.45976 7.02271 7.89294 7.25372 7.22397C7.45382 7.81588 7.83912 8.33095 8.35488 8.69601C8.87064 9.06106 9.49064 9.25754 10.1268 9.25754C10.763 9.25754 11.383 9.06106 11.8988 8.69601C12.4145 8.33095 12.7998 7.81588 12.9999 7.22397C13.2 7.81588 13.5853 8.33095 14.1011 8.69601C14.6168 9.06106 15.2368 9.25754 15.873 9.25754C16.5092 9.25754 17.1292 9.06106 17.645 8.69601C18.1607 8.33095 18.546 7.81588 18.7461 7.22397C18.9771 7.89294 19.443 8.45976 20.0607 8.82337C20.6784 9.18698 21.4078 9.32376 22.1188 9.20931C22.8298 9.09487 23.4762 8.73664 23.9429 8.1985C24.4095 7.66035 24.666 6.97726 24.6666 6.27101C24.6666 5.44391 22.2819 0.791504 22.2819 0.791504Z"
        stroke="#737373"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0864 20.9968V12.2134H20.1676V21.0327L15.0864 20.9968ZM12.454 18.2368H6.17584C6.04205 18.2368 5.91374 18.1847 5.81914 18.092C5.72453 17.9992 5.67139 17.8735 5.67139 17.7423V12.7303C5.67138 12.6656 5.68446 12.6015 5.70987 12.5418C5.73528 12.482 5.77251 12.4278 5.81941 12.3823C5.86632 12.3367 5.92196 12.3007 5.98314 12.2763C6.04432 12.252 6.10981 12.2398 6.17584 12.2403H12.454C12.52 12.2398 12.5855 12.252 12.6467 12.2763C12.7079 12.3007 12.7635 12.3367 12.8104 12.3823C12.8573 12.4278 12.8946 12.482 12.92 12.5418C12.9454 12.6015 12.9585 12.6656 12.9585 12.7303V17.7423C12.9585 17.8735 12.9053 17.9992 12.8107 18.092C12.7161 18.1847 12.5878 18.2368 12.454 18.2368Z"
        stroke="#737373"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export default Shop;
