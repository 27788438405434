import styled from "styled-components";
import { tabletOrLargerDevices } from "../../globalConstants";

export const $HeaderText = styled.div`
  font-size: 24px;
  margin-bottom: 16px;

  @media (min-width: ${tabletOrLargerDevices}) {
    font-size: 27px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

type ActiveImageProps = {
  imageURI: string;
};

export const $ItemDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: min(calc(95vh - 160px), 720px);
  border-radius: 8px;
  border: 1px solid var(--border-grey);
`;

export const $ActiveImage = styled.div<ActiveImageProps>`
  background-image: url(${({ imageURI }) => imageURI});
  background-color: var(--disabled-grey);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 8px;
  width: 100%;
  height: 222px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  position: relative;

  @media (min-width: ${tabletOrLargerDevices}) {
    height: 500px;
  }
`;

export const $ImageThumbnailGroup = styled.div`
  height: 80px;
  position: absolute;
  bottom: 0;
  display: flex;

  @media (min-width: ${tabletOrLargerDevices}) {
    height: auto;
  }
`;

export const $NavigateOffsetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 48px;
  margin: 24px 8px;
  cursor: pointer;
`;

type NavigateOffsetButtonProps = {
  flipped?: boolean;
};

export const $NavigateOffsetButton = styled.div<NavigateOffsetButtonProps>`
  height: 48px;
  width: 48px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ flipped = false }) => (flipped ? "rotate(180deg)" : "none")};

  & .navigate-offset path {
    fill: #221f1f;
  }
`;

type ImageThumbnailProps = {
  imageURI: string;
  isActive: boolean;
};

export const $ImageThumbnail = styled.div<ImageThumbnailProps>`
  background-image: url(${({ imageURI }) => imageURI});
  background-color: var(--disabled-grey);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  border-color: ${({ isActive }) => (isActive ? "black" : "white")};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  height: 44px;
  width: 44px;
  margin: 24px 8px;
  cursor: pointer;

  @media (min-width: ${tabletOrLargerDevices}) {
    height: 88px;
    width: 88px;
  }
`;

export const $ThumbnailPlaceholder = styled.div`
  height: 44px;
  width: 44px;
  margin: 24px 8px;

  @media (min-width: ${tabletOrLargerDevices}) {
    height: 88px;
    width: 88px;
  }
`;

export const $DetailsPanel = styled.div`
  padding: 24px 16px 16px;

  @media (min-width: ${tabletOrLargerDevices}) {
    padding: 24px;
  }

  .vendor-name {
    font-size: 15px;
    line-hegiht: 24px;
    color: var(--medium-emphasis-black);
    margin-bottom: 8px;

    @media (min-width: ${tabletOrLargerDevices}) {
      font-size: 16px;
    }
  }

  .item-name {
    font-size: 16px;
    line-height: 24px;

    @media (min-width: ${tabletOrLargerDevices}) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  .detail-tab-container {
    margin: 8px 0;
  }

  .detail-tab {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 15px;
    background-color: var(--disabled-grey);
    margin-right: 16px;

    @media (min-width: ${tabletOrLargerDevices}) {
      font-size: 16px;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }

  .button {
    font-size: 14px;
    width: 47%;

    @media (min-width: ${tabletOrLargerDevices}) {
      font-size: 16px;
      width: 49%;
    }
  }
`;
