import React from 'react';

const ClearSearch = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#E7E7E7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.59599 6.28543C6.21228 6.66601 6.21228 7.28304 6.59599 7.66361L10.814 11.8471L6.28778 16.3364C5.90407 16.717 5.90407 17.334 6.28778 17.7146C6.67149 18.0951 7.2936 18.0951 7.67731 17.7146L12.2035 13.2253L16.3227 17.3109C16.7064 17.6915 17.3285 17.6915 17.7122 17.3109C18.0959 16.9303 18.0959 16.3133 17.7122 15.9327L13.593 11.8471L17.404 8.06728C17.7877 7.6867 17.7877 7.06967 17.404 6.68909C17.0203 6.30852 16.3982 6.30852 16.0145 6.68909L12.2035 10.469L7.98552 6.28543C7.60181 5.90486 6.9797 5.90486 6.59599 6.28543Z" fill="#454545"/>
    </svg>
  )
}

export default ClearSearch;