import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../../globalConstants";

export const $ReturnStatusErrorCard = styled.div`
  align-items: center;
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 16px;
  padding: 32px;
  width: 100%;

  color: #221f1f;
  font-family: "sofia-pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;

  svg {
    margin-bottom: 24px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-radius: 0;
    border: 0;
    border-top: 1px solid var(--border-card);
    width: 100%;
  }
`;
