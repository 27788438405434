import React from "react";

const Zoom = (props) => (
  <>
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.7" width="22" height="22" rx="5" fill="#737373" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z"
        stroke="white"
      />
      <path
        d="M13 13L16.5454 16.5454"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export default Zoom;
