import { CurrentItemAction, MultiStoreAction } from "../enums";

const currentItemReducer = (state = null, action) => {
  switch (action.type) {
    case CurrentItemAction.Set:
      return action.itemId;
    case MultiStoreAction.AppReset:
    case MultiStoreAction.AppLogout:
    case CurrentItemAction.Unset:
      return null;
    default:
      return state;
  }
};

export default currentItemReducer;
